import { Component, Input, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { Setting, Userinfo } from 'libs/shared/src/lib/entities';
import { DialogService } from 'libs/shared/src/lib/services/dialog.service';
import { Mailservice } from '../../services/mail.service';

@Component({
  selector: 'app-mailtest',
  templateUrl: './mailtest.component.html',
  styleUrls: ['./mailtest.component.scss'],
})
export class MailtestComponent implements OnInit {
  testAdress = '';
  @Input() userInfo: Userinfo;
  @Input() settings: Setting;

  constructor(private dialogService: DialogService, private mailService: Mailservice) {}

  ngOnInit(): void {
    if (isNullOrUndefined(this.userInfo))
      throw new Error('Userinfo muss zur Verwendung des Mailtests als Inputproperty übergeben werden');
  }

  async sendTestMail(userInfo: Userinfo, settings: Setting): Promise<void> {
    const recipient = this.testAdress;
    void this.dialogService.openLoadingDialog('E-Mail Test', '...Teste den Versand der E-Mail...'); // Kein Close Loadingdialog - der Mailservice macht die nächste Nachricht
    await this.mailService.sendTestMail(userInfo, settings, recipient);
  }
}
