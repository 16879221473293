import { IndexedDBTypes } from './dbType';
import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { HWAddress } from './HWAddress';
import { HWDatenblatt } from './HWDatenblatt';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { Anlagendaten } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Anlagendaten';
import { Anlagenstandort } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Anlagenstandort';
import { Anlagentermin } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Anlagentermin';
import { Technischedaten } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Technischedaten';
import {
  Messwertsatz,
  MesswertTabellenEintrag,
  Messwerttabelle,
  MessungenFromHandwerk,
} from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Messwertsatz';
import { convertToPlain } from 'apps/handwerkPWA/src/app/helper/entities/HWAnlage/convertHelper';

export class HWAnlage extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWAnlage') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') ANLAGE: string = null; // Anlagennummer
  @IndexedDBTypes.DataField('string') PROJEKT: string = null; // Projekt-"Name" abweichend von der Nummer
  @IndexedDBTypes.DataField('string') Anlagenbeschreibung: string = null; // Anlagenbeschreibung - rtf konvertiert zu klartext
  @IndexedDBTypes.DataField('string') ABESCHR: string = null; // Arbeitsbeschreibung-Reiter im handwerk
  @IndexedDBTypes.DataField('string') BESCHR: string = null; // Bemerkung-Reiter im Handwerk
  @IndexedDBTypes.IndexField('string') UUID: string = null;
  @IndexedDBTypes.DataField('string') RTFDATA: string = null; // Anlagenbeschreibung - in rtf
  @IndexedDBTypes.DataField('Anlagendaten') Anlagendaten: Anlagendaten;
  @IndexedDBTypes.DataField('Anlagenstandort') Anlagenstandort: Anlagenstandort;
  @IndexedDBTypes.DataField('Anlagentermin') Anlagentermin: Anlagentermin;
  @IndexedDBTypes.DataField('TechnischeDaten') TechnischeDaten: Technischedaten;
  @IndexedDBTypes.DataField('HWDatenblatt') Datenblatt: HWDatenblatt; // enthält die felder die im handwerk unter "technische daten" stehen
  @IndexedDBTypes.DataField('Messwertsatz') Messwertsatz: Messwertsatz;
  Anlagenmessungen: MessungenFromHandwerk[] = null; // Temporäres Feld um Messungen aus dem Webservice zwischenzulagern bevor es dem Messwertsatzobjekt zugeordnet wird

  // Folgend Werte, die aus dem Endpunkt kommen, aber bisher nicht zugeordnet werden konnten

  // ALTLW: null
  // BEZ: "Heizkessel Öl"
  // CHILD: null
  // GEAENDERT: 44054.59843388889
  // NACHRICHT: 1
  // NEULW: null
  // ORIGIN: null
  // PARENT: null
  // RTFDATA: null
  // SOLLWERTE: (18) ["15,4", "24,3", "171,5", "2,6", "50,0", "13,5", "6,5", "93,5", "4", "1,14", "-0,29", "", "", "", "", "", "", ""]
  // TODO: false
  // TXDATA: null
  // TXFIELDS: null
  // USELWTONW: false
  // USER: 3
  // USERTTONW: false
  // VK: 144.73

  public getAutoKey(): string {
    return this.AutoKey;
  }

  constructor(data: any, kunden: HWAddress[], datenblaetter: HWDatenblatt[]) {
    super();
    assignIfPropertyExsits(this, data);
    this.Anlagendaten = new Anlagendaten(kunden, datenblaetter, data);
    this.Anlagenstandort = new Anlagenstandort(data);
    this.Anlagentermin = new Anlagentermin(data);
    this.TechnischeDaten = data ? new Technischedaten(data['ZUSAETZE']) : new Technischedaten(null);
    if (this.RTFDATA) {
      this.Anlagenbeschreibung = convertToPlain(this.RTFDATA);
    }
  }
  getUuid(): string {
    return this.UUID;
  }

  private addDatenblattAndZusatzbeschreibung(datenblaetter: HWDatenblatt[]) {
    const datenblatt = datenblaetter.find(blatt => blatt.NR === this.Anlagendaten.DATENBLNR);
    this.Datenblatt = datenblatt;
  }

  private addTechnischeDatenInfos(datenblatt: HWDatenblatt) {
    this.TechnischeDaten.Bezeichnungen = datenblatt.ZUSAETZE;
    this.TechnischeDaten.BezeichnungenExtended = this.getExtendedBezeichnungen(datenblatt.ZUSAETZE);
  }
  private getExtendedBezeichnungen(ZUSAETZE: string[]): string[] {
    const zusaetzeExtended = [];
    let index = 0;
    for (const zusatz of ZUSAETZE) {
      if (zusatz === '') {
        zusaetzeExtended.push('Zusatz ' + (index + 1));
      } else {
        zusaetzeExtended.push(zusatz);
      }
      index++;
    }
    return zusaetzeExtended;
  }

  private addMesswertsatz(anlagendaten: Anlagendaten, datenblatt: HWDatenblatt) {
    const messwertSatz = new Messwertsatz(anlagendaten);
    messwertSatz.Sollwerttabelle = new Messwerttabelle(anlagendaten);
    messwertSatz.Sollwerttabelle.Beschreibung = 'XYZSTandard';
    if (!datenblatt) {
      return;
    }
    const feldanzahl = datenblatt.FELDER.length;
    const beschreibungen = datenblatt.FELDER;
    const messwerte = datenblatt.VORGABEN;
    const einheiten = datenblatt.EINHEITEN;
    for (let index = 0; index < feldanzahl; index++) {
      const beschreibung = beschreibungen[index];
      const messwert = messwerte[index];
      const einheit = einheiten[index];
      if (einheit === '' && messwert === '' && beschreibung === '') {
        continue;
      }
      const messwertTabellenEintrag = new MesswertTabellenEintrag(beschreibung, messwert, einheit);
      messwertSatz.Sollwerttabelle.MesswertTabellenEintraege.push(messwertTabellenEintrag);
    }
    this.Messwertsatz = messwertSatz;
  }

  /**@description Fügt den Anlagedaten Datenblatt, Zusatzbeschreibungen, TechnischeDaten und Messwertsätze hinzu */
  addAdditionalAnlagendata(datenblaetter: HWDatenblatt[]): void {
    this.addDatenblattAndZusatzbeschreibung(datenblaetter);
    const datenblatt = this.Datenblatt;
    if (!datenblatt) {
      return;
    }
    this.addTechnischeDatenInfos(datenblatt);
    const anlagenDaten = this.Anlagendaten;
    this.addMesswertsatz(anlagenDaten, datenblatt);
    const Sollwerttabelle = this.Messwertsatz.Sollwerttabelle;
    this.sortAnlagenmessungenIntoObject(anlagenDaten, Sollwerttabelle, this.Anlagenmessungen);
  }

  private sortAnlagenmessungenIntoObject(
    anlagenDaten: Anlagendaten,
    Sollwerttabelle: Messwerttabelle,
    inputAnlagenmessungen: MessungenFromHandwerk[]
  ) {
    if (isNullOrUndefined(inputAnlagenmessungen)) {
      return;
    }
    for (const messung of inputAnlagenmessungen) {
      const vorlage = new Messwerttabelle(anlagenDaten, Sollwerttabelle);
      const messwertTabelle = messungFromhandwerkToMesswertabelle(vorlage, messung, Sollwerttabelle);
      this.Messwertsatz.Istwerttabellen.push(messwertTabelle);
    }
  }
  static toString(): string {
    return 'HWAnlage';
  }
}

export function messungFromhandwerkToMesswertabelle(
  vorlage: Messwerttabelle,
  inputAnlagenmessungen: MessungenFromHandwerk,
  Sollwerttabelle: Messwerttabelle
): Messwerttabelle {
  vorlage.Beschreibung = inputAnlagenmessungen.Beschreibung;
  vorlage.Messdatum = inputAnlagenmessungen.Messdatum.substr(0, 10);
  const vorlagenMessfelder = vorlage.MesswertTabellenEintraege;
  let index = 0;
  for (const feld of vorlagenMessfelder) {
    feld.Messwert = inputAnlagenmessungen.messwerte[index];
    index++;
  }
  return vorlage;
}
