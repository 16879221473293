import { Component, OnInit } from '@angular/core';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { HWAddress, HWObjectaddress } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { ObjectaddressService } from 'apps/handwerkPWA/src/app/services/dataServices/objectAddress.service';
import {
  getAddressesFullType,
  getShortTypeFromType,
} from 'apps/handwerkPWA/src/app/helper/entities/HWAddress/addressTypeHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { AddressLongTypes } from '../../../config/Konstanten';

@Component({
  selector: 'app-addresses-index',
  templateUrl: './addresses-index.component.html',
  styleUrls: ['./addresses-index.component.scss'],
})
export class AddressesIndexComponent implements OnInit {
  addressTypes = ['Alle', 'Kunden', 'Mitarbeiter', 'Lieferanten', 'Freie Adressen', 'Objektadressen'];
  allAddresses: (HWAddress | HWObjectaddress)[];
  filteredAddresses: (HWAddress | HWObjectaddress)[];
  adressType = this.addressTypes[0];
  contactInformationArray = ['TEL', 'FUNK_PRIV', 'GLOBEMAIL', 'Anschrift', 'WWW'];
  searchExpressions = ['FA_TITLE', 'SUCH', 'Name', 'NAME', 'NAME2', 'STRASSE', 'PLZ', 'ORT', 'KU_NR'];

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    const hwAddresses = await this.addressService.getAllDisplayable();
    this.allAddresses = hwAddresses.filter(address => address.LeaveDate == null || address.LeaveDate >= new Date());
    this.allAddresses = this.allAddresses.concat(await this.objectAddressService.getAll());
    this.filteredAddresses = this.allAddresses;
    const lastAdressType = this.globalSettingService.lastAddressSelectionType;
    if (lastAdressType) {
      this.adressType = getAddressesFullType(lastAdressType);
      this.filterAddressesByType(lastAdressType);
    }
  }

  private filterAddressesByType(filterType: string) {
    this.filteredAddresses = this.allAddresses.filter(address => address.ADRTYP === filterType);
  }

  /**@description Wenn die AdressenSelection geändert wurde */
  filterChanged(adressType: AddressLongTypes): void {
    const shortType = getShortTypeFromType(adressType);
    this.globalSettingService.lastAddressSelectionType = shortType;
    if (isNullOrUndefined(shortType)) {
      this.filteredAddresses = this.allAddresses;
      return;
    }
    this.filterAddressesByType(shortType);
  }

  async newAddress(): Promise<void> {
    await this.routingService.navigateTo('adresse/neu');
  }
}
