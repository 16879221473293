import { HWRepairOrderItem } from 'apps/handwerkPWA/src/app/entities/repository/HWRepairOrderItem';
import { Textposition } from './Textposition';

export type manualPositionType = 'Artikel' | 'Lohn' | 'Textposition' | 'Leistung';

export class ManuellePosition {
  type: manualPositionType = 'Artikel';
  Bezeichnung: string;
  PriceVK = 0;
  PriceEK = 0;
  LangText: string;
  Nummer: string;
  Menge = 1;
  MengenEinheit = 'Stk.';
  Zeit = 0;
  isAutolohnActive = false;
  Ident = 'M';
  UstS = 16;
  constructor() {}
  changeType(type: manualPositionType): void {
    this.type = type;
    this.Zeit = 0;
    this.Menge = 1;
    switch (type) {
      case 'Artikel': {
        this.Ident = 'M';
        this.MengenEinheit = 'Stk.';
        break;
      }
      case 'Lohn': {
        this.Ident = 'S';
        this.MengenEinheit = 'STD';
        this.Zeit = 60;
        break;
      }
      case 'Textposition': {
        this.Ident = 'T';
        this.MengenEinheit = '';
        break;
      }
      case 'Leistung': {
        this.Ident = 'L';
        this.MengenEinheit = '';
        break;
      }
    }
  }
}

/**@param steuerSatz Prozentualer standard Steuersatz, übertgeben mit dem monteur endpunkt */
export function parseRepairOrderItem(manuellePosition: ManuellePosition): HWRepairOrderItem {
  let item = new HWRepairOrderItem(manuellePosition);
  if (manuellePosition.Ident === 'T') {
    item = new Textposition(manuellePosition);
  }
  const vkPrice = manuellePosition.PriceVK;
  const ekPrice = manuellePosition.PriceEK;
  const Ident = manuellePosition.Ident;
  const bezeichnung = manuellePosition.Bezeichnung || '';
  item.Bezeichnung = bezeichnung;
  item.KurzText = bezeichnung;
  item.LangText = bezeichnung;
  item.UstSchl = '01';
  switch (Ident) {
    case 'M':
    case 'm': {
      // normale Artikel
      item.VK = vkPrice;
      item.EUMATVK1 = vkPrice;
      item.EUMATVK2 = vkPrice;
      item.EUMATVK3 = vkPrice;
      item.EUMATVK4 = vkPrice;
      item.EUMATVK5 = vkPrice;
      item.EUMATVK6 = vkPrice;
      item.EUMATVK7 = vkPrice;
      item.EUMATVK8 = vkPrice;
      item.EUMATVK9 = vkPrice;
      item.EUMATVK10 = vkPrice;
      item.EUMATVK = vkPrice;
      item.EK = ekPrice;
      return item;
    }
    case 'S':
    case 's': {
      // Lohn
      item.EULOHNS1 = vkPrice;
      item.EULOHNS2 = vkPrice;
      item.EULOHNS3 = vkPrice;
      item.EULOHNS4 = vkPrice;
      item.EULOHNS5 = vkPrice;
      item.EULOHNS6 = vkPrice;
      item.EULOHNS7 = vkPrice;
      item.EULOHNS8 = vkPrice;
      item.EULOHNS9 = vkPrice;
      item.EULOHNS10 = vkPrice;
      item.EULOHNVK1 = vkPrice;
      item.EULOHNVK2 = vkPrice;
      item.EULOHNVK3 = vkPrice;
      item.EULOHNVK4 = vkPrice;
      item.EULOHNVK5 = vkPrice;
      item.EULOHNVK6 = vkPrice;
      item.EULOHNVK7 = vkPrice;
      item.EULOHNVK8 = vkPrice;
      item.EULOHNVK9 = vkPrice;
      item.EULOHNVK10 = vkPrice;
      item.LohnVK = vkPrice;
      item.LohnEK = ekPrice;
      item.KOSTSATZ = ekPrice;
      return item;
    }
  }
  return item;
}
