import { base64To64Raw } from 'libs/shared/src/lib/helper/globalHelper';
import { Raumbuch } from '../../repository/Raumbuch';
import { RaumbPos } from './RaumbPos';

export class Raum {
  /**alle flächen dieses raumes werden zuvor gelöscht */
  clearRoom = false;
  RaumbuchEntries: Raumbuch[] = [];
  pictureDataBase64: string = null;
  raumbposUuid: string = null;

  constructor(entries: Raumbuch[], pictureDataBase64: string, raumbPos: RaumbPos, clearRoom = false) {
    this.RaumbuchEntries = entries;
    this.pictureDataBase64 = base64To64Raw(pictureDataBase64);
    this.clearRoom = clearRoom;
    this.raumbposUuid = raumbPos.Uuid;
  }
}
