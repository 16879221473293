import { Component, OnInit, Input } from '@angular/core';
import { Anschrift } from 'apps/handwerkPWA/src/app/entities';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-bss-contact-bar',
  templateUrl: './bss-contact-bar.component.html',
  styleUrls: ['./bss-contact-bar.component.scss'],
})
export class BssContactBarComponent implements OnInit {
  @Input() contactInformationArray = [];
  /**@description Das DatenObjekt dessen Kontaktfelder genommen werden sollen */
  @Input() dataObject;
  @Input() telefonDescription = false;
  contactIconsClass = 'contactIcons4';

  constructor() {}

  ngOnInit() {
    this.setCorrectCss(this.contactInformationArray);
  }

  /**@description Setzt anhand der anzahl der gefundenen Kontaktbuttons das richtige css set */
  private setCorrectCss(contactInformationArray: string[]) {
    if (isNullOrUndefined(contactInformationArray)) {
      return;
    }
    const undefineds = contactInformationArray.filter(item => item === undefined || item === null);
    const undefinedCount = undefineds.length;
    const contactInfoLength = contactInformationArray.length;
    const realAmount = contactInfoLength - undefinedCount;
    this.contactIconsClass = 'contactIcons' + realAmount;
  }

  /**@description Guckt ob die jeweilige Kontaktart an der jeweiligen stelle vorhanden ist */
  contactArtAvailable(contactInformationArray: string[], caseName: string, dataObject) {
    const caseNumber = this.getCasenumber(caseName);
    if (isNullOrUndefined(contactInformationArray[caseNumber])) {
      return false;
    }
    const propertyName = contactInformationArray[caseNumber];
    if (isNullOrUndefined(dataObject[propertyName]) || dataObject[propertyName] === '') {
      return false;
    }
    const contactValue = dataObject[propertyName];
    if (caseName !== 'navi') {
      return true;
    }
    // value ist eine Anschrift
    const anschrift = contactValue as Anschrift;
    const keinOrt = isNullOrUndefined(anschrift.Ort) || anschrift.Ort === '';
    if (keinOrt && isNullOrUndefined(anschrift.FallbackSearchstring)) {
      return false;
    }
    return true;
  }

  getCasenumber(caseName: string): number {
    switch (caseName) {
      case 'web': {
        return 4;
      }
      case 'navi': {
        return 3;
      }
      case 'email': {
        return 2;
      }
      case 'mobile': {
        return 1;
      }
      case 'phone': {
        return 0;
      }
    }
    return -1;
  }

  /**@description Weiterleitung des jeweiligen Element Clicks */
  contact(contactInformationArray: string[], caseName: string, dataObject) {
    const isAvailable = this.contactArtAvailable(contactInformationArray, caseName, dataObject);
    if (isNullOrUndefined(isAvailable)) {
      return;
    }
    const caseNumber = this.getCasenumber(caseName);
    const propertyName = contactInformationArray[caseNumber];
    const contactValue = dataObject[propertyName];
    if (caseName === 'email') {
      // mail
      this.mailTo(contactValue);
      return;
    }
    if (caseName === 'navi') {
      // navi
      const anschrift = contactValue as Anschrift;
      void this.openMaps(anschrift);
      return;
    }
    if (caseName === 'web') {
      // web
      this.goToHomepage(contactValue);
      return;
    }
    // Anruf
    this.callPhone(contactValue);
  }

  async openMaps(item: Anschrift) {
    const ort = item.Ort;
    const strasse = item.Strasse;
    if (!isNullOrUndefined(ort) && ort != '') {
      window.open('https://www.google.com/maps/search/?api=1&query=' + strasse + '%2C+' + ort, '_blank');
      return;
    }
    const mapsPrefix = 'https://www.google.de/maps/place/';
    window.open(mapsPrefix + item.FallbackSearchstring);
  }

  callPhone(telNumber: string) {
    if (isNullOrUndefined(telNumber) || telNumber === '') {
      return;
    }
    window.open('tel:' + telNumber);
  }

  mailTo(mailAdress: string) {
    if (isNullOrUndefined(mailAdress) || mailAdress === '') {
      return;
    }
    window.open('mailto:' + mailAdress);
  }

  goToHomepage(homepage: string) {
    if (isNullOrUndefined(homepage) || homepage === '') {
      return;
    }
    if (homepage.startsWith('https://')) {
      window.open(homepage, '_blank');
      return;
    }
    const prefixedHomepage = 'https://' + homepage;
    window.open(prefixedHomepage, '_blank');
  }
}
