import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { HWAddress } from 'apps/handwerkPWA/src/app/entities/repository/HWAddress';
import { Anschrift } from '../Anschrift';

export class Anlagenstandort {
  // Name, straße , plz und ort kommen direkt aus dem kunden und sind nicht änderbar,ohne den kunden zu ändern
  NAME: string = null; // Name des Kunden vom Standort
  STRASSE: string = null; // Strasse des Kunden
  PLZ: string = null; // OLZ des Kunden
  ORT: string = null; // ORT des Kunden
  STANDORT1: string = null; // standort 1
  STANDORT2: string = null; // standort 2
  TEL: string = null; // Telefon
  MONTEUR: string = null; // Personalnummer des monteurs
  MONTEUR2: string = null; // Personalnummer des monteurs 2
  ANFAHRTKM: string = null; // Anfahrt in km
  ANFAHRT: number = null; // Anfahrtszeit in minuten
  WDAUER: number = null; // Wartungsdauer: string = null ;
  StandortAnschrift: Anschrift = null; // Wartungsdauer: string = null ;

  constructor(data: any) {
    assignIfPropertyExsits(this, data);
    this.StandortAnschrift = new Anschrift(this.STRASSE, this.PLZ, this.ORT);
  }

  /**@description Gibt dem PrintService die zu druckenden Angaben, jeder Array Eintrag wird in eine eigene Zeile gedruckt */
  getAnlageninformationenForPdfPrint(): string[] {
    const Information: string[] = [];
    if (this.NAME) Information.push(this.NAME);
    if (this.STRASSE) Information.push(this.STRASSE);
    if (this.PLZ || this.ORT) Information.push(this.PLZ + ' ' + this.ORT);
    if (this.STANDORT1) Information.push('Standort1: ' + this.STANDORT1);
    if (this.STANDORT2) Information.push('Standort2: ' + this.STANDORT2);
    if (this.TEL) Information.push('Telefon: ' + this.TEL);
    if (this.ANFAHRTKM) Information.push('Anfahrt: ' + this.ANFAHRTKM + 'km');
    return Information;
  }
}
