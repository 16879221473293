<div
  class="card message-card"
  (click)="reactOnNachrichtClick(userInfo, message)"
  [ngStyle]="{ 'border-left': message.Gelesen ? '1px black solid' : '6px green solid' }"
>
  <div class="flexContainer">
    <div class="flexContainerItem">
      <p>
        <b>{{ message.Betreff }}</b>
      </p>
      <p style="font-size: 12px; color: #aaa">am {{ message.SendDatum }} {{ message.SendZeit }}</p>
      <p *ngIf="message.Auftrag">{{ message.Auftrag.Nummer }} - {{ message.Auftrag.KundenName }}</p>
    </div>
    <div class="message-delete" *ngIf="this.message.TYP !== HWMessageType.Incoming">
      <dx-button
        [disabled]="deleteDisabled"
        [icon]="'trash'"
        (click)="deleteNachricht(message); $event.stopPropagation()"
      ></dx-button>
    </div>
  </div>
  <div class="message-text" *ngIf="message.offenInView">
    <hr />
    Nachricht: <br />
    {{ message.Text }}
  </div>
</div>
