import { Injectable } from '@angular/core';
import { RVorlage } from '../../entities/repository/RVorlage';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RvorlageService {
  constructor(private baseService: BaseService, private controllerService: ControllerService) {}

  async getAll(): Promise<RVorlage[]> {
    return await this.baseService.getAll(RVorlage);
  }

  async getAllBy(selector: string, value: any): Promise<RVorlage[]> {
    return await this.baseService.getAllBy(RVorlage, selector, value);
  }

  async findOneBy(selector: string, value: any): Promise<RVorlage> {
    return await this.baseService.findOneBy(RVorlage, selector, value);
  }

  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(RVorlage, selector, value);
  }

  async overrideLocal(raumbuecher: RVorlage[]): Promise<void> {
    await this.controllerService.clearStore('RVorlage');
    await this.controllerService.setData('RVorlage', raumbuecher);
  }

  async getAllRaumbuchvorlagen(): Promise<RVorlage[]> {
    const allVorlagen = await this.getAll();
    return allVorlagen?.filter(vorlage => vorlage.isRaumbuchVorlage());
  }
}
