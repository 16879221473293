import { convertToRoundedDisplayString } from 'libs/shared/src/lib/helper/globalHelper';
import { HWMonteur, getSteuerBezeichnungOfUsedSteuern } from '..';
import { BaseAuftrag, BaseDocumentPosition } from '../../interfaces';

export class PrintPosition {
  posNr: string;
  menge: string;
  einheit: string;
  bezeichnung: string;
  epreis: string;
  gpreis: string;
  belongsToPosnr?: string;

  constructor(item: BaseDocumentPosition, showPrice: boolean, priceGroup: string) {
    if (!item) return;
    this.bezeichnung = item.getBezeichnung();
    if (item.getLongtype() === 'Textposition') return;
    this.posNr = item.getPosNr();
    this.menge = convertToRoundedDisplayString(item.getMengeOrStunden());
    this.einheit = item.getMengenEinheit();
    if (!showPrice) return;
    this.epreis = convertToRoundedDisplayString(item.getEinzelDisplayprice(priceGroup)) + ' €';
    this.gpreis = convertToRoundedDisplayString(item.getGesamtDisplayPrice(priceGroup)) + ' €';
  }
}

export function createSummenBlockAsPrintPositions(
  monteur: HWMonteur,
  order: BaseAuftrag,
  items: BaseDocumentPosition[],
  priceGroup: string
): PrintPosition[] {
  const steuern = monteur.Steuern;
  const itemSums = order.calculateItemSums(steuern);
  const nettoSum = itemSums.NettoSum;
  const taxedSum = itemSums.TaxedSum;
  const completeSum = itemSums.CompleteSum;
  const steuerLine = getSteuerBezeichnungOfUsedSteuern(steuern, items);
  const emptyLine = new PrintPosition(null, false, priceGroup);
  const nettoSumme = new PrintPosition(null, false, priceGroup);
  nettoSumme.bezeichnung = 'Nettosumme';
  nettoSumme.gpreis = nettoSum + ' €';
  const steuerSum = new PrintPosition(null, false, priceGroup);
  steuerSum.bezeichnung = steuerLine;
  steuerSum.gpreis = taxedSum + ' €';
  const gesamtSumme = new PrintPosition(null, false, priceGroup);
  gesamtSumme.bezeichnung = 'Gesamtsumme';
  gesamtSumme.gpreis = completeSum + ' €';
  return [emptyLine, nettoSumme, steuerSum, gesamtSumme];
}

export function addUnterpositionenLines(
  printItems: PrintPosition[],
  items: BaseDocumentPosition[],
  order: BaseAuftrag,
  priceGroup: string
): PrintPosition[] {
  const unterpositionLines: PrintPosition[] = [];
  for (const item of printItems) {
    const orderItem = items.find(searchItem => searchItem.getPosNr() === item.posNr);
    if (
      orderItem &&
      orderItem.getPrintStueckliste() === true &&
      order.getUnterpositionenOfLeistung(orderItem)?.length > 0
    ) {
      const unterpositionLine = new PrintPosition(null, false, priceGroup);
      unterpositionLine.bezeichnung = `Darin enthalten je ${item.einheit}:`;
      unterpositionLine.belongsToPosnr = orderItem.getPosNr();
      unterpositionLines.push(unterpositionLine);
    }
  }
  for (const unterPosLine of unterpositionLines) {
    const itemIndex = printItems.findIndex(printItem => printItem.posNr === unterPosLine.belongsToPosnr);
    printItems.splice(itemIndex + 1, 0, unterPosLine);
  }
  return printItems;
}
