<div class="flexContainer card repair-order-card" *ngIf="auftrag" [ngClass]="auftrag?.StatusObject.stateClass">
  <div class="flexContainerItem" style="display: flex">
    <div class="flexContainerItem" (click)="goToRepairOrderDetail()">
      <b>{{ auftrag.Nummer }} - {{ auftrag.Termin }}</b
      ><br />
      <b>Betreff: {{ auftrag.Betreff }}</b> <br />
      <p>{{ auftrag.AusfAnspr }}</p>
      <div *ngIf="auftrag.Kunde.ArbeitsortAnschrift">
        <p *ngIf="auftrag.Kunde.ArbeitsortAnschrift.FallbackDisplayString">
          {{ auftrag.Kunde.ArbeitsortAnschrift.FallbackDisplayString }}
        </p>
        <p *ngIf="auftrag.Kunde.ArbeitsortAnschrift.Strasse">{{ auftrag.Kunde.ArbeitsortAnschrift.Strasse }}</p>
        <p *ngIf="auftrag.Kunde.ArbeitsortAnschrift.Ort">
          {{ auftrag.Kunde.ArbeitsortAnschrift.Postleitzahl }} {{ auftrag.Kunde.ArbeitsortAnschrift.Ort }}
        </p>
      </div>
    </div>
    <div (click)="toggleContactBar()" style="padding: 0 15px">
      <i class="dx-icon dx-icon-chevrondown"></i>
    </div>
  </div>
  <div *ngIf="showContactBar" class="flexContainerItem">
    <app-bss-contact-bar
      [contactInformationArray]="contactInformationArrayRepairOrder"
      [dataObject]="auftrag.Kunde"
    ></app-bss-contact-bar>
  </div>
</div>
