import { Component, OnInit } from '@angular/core';
import { AppointmentService } from '../../services/dataServices/appointment.service';
import { delay } from 'q';
import { HWGolbalsettingService } from '../../services/globalServices/hwgolbalsetting.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { MonteurService } from 'apps/handwerkPWA/src/app/services/dataServices/monteur.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { isNullOrUndefined, isDate } from 'libs/shared/src/lib/helper/globalHelper';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import { HWTermin, HWAddress, HWMonteur } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { isUserAgentIOS } from 'libs/shared/src/lib/helper/deviceHelper';
import { getEmployeeNamesFromTermine, termineToMoreSpecificDatasets } from '../../helper/services/terminHelper';
import { RepairOrderService } from '../../services/dataServices/repairOrder.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  constructor(
    private appointmentService: AppointmentService,
    private globalSettingService: HWGolbalsettingService,
    private rightsService: RechteService,
    private employeeService: MonteurService,
    private routingService: RoutingService,
    private repairOrderService: RepairOrderService,
    private addressService: AddressService
  ) {}
  currentValue: Date = new Date();
  cellTemplate = 'cell';
  currentFilteredAppointments: HWTermin[];
  selectedAppointment: HWTermin;
  calendarFilter = ['Nur meine Termine'];
  allAppointments: HWTermin[] = [];
  today: Date = new Date();
  isiOS = false;
  selectedFilter = 'Nur meine Termine';
  selectedDate = new Date();
  ownCustomerNumber: string;
  AllEmployees: HWAddress[];

  async ngOnInit(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserinfo();
    this.isiOS = isUserAgentIOS();
    const loggedInEmployee = await this.employeeService.getMonteurFromIDB();
    this.ownCustomerNumber = loggedInEmployee.AdrNummer;
    const repairOrders = await this.repairOrderService.getAllRepairOrdersFromIDB();
    this.allAppointments = await this.appointmentService.getAllTermineFromIDB(userInfo, null, null, true, repairOrders);
    this.AllEmployees = await this.addressService.getAllBy('ADRTYP', 'M');

    await this.addCalenderFilters(loggedInEmployee);

    await delay(500); // Initial damit html element gefunden werden kann
    this.filterChange(this.selectedFilter, this.today);
  }

  async addCalenderFilters(loggedInUser: HWMonteur): Promise<void> {
    if (!this.rightsService.getCurrentRight().Employeerights.Terminrights.showAll) return;
    this.calendarFilter.push('Alle Termine');

    const loggedInAddress = await this.addressService.findOneBy('KU_NR', loggedInUser.AdrNummer);
    const employeeNames = getEmployeeNamesFromTermine(this.allAppointments).filter(
      name => name !== loggedInAddress.NAME
    );
    this.calendarFilter = this.calendarFilter?.concat(employeeNames);
  }

  /**@description Wird vom Filterwechsel aufgerufen und manuell, wenn sich das Date Änderte */
  filterChange(selectedValue: string, selectedDate: Date): void {
    this.selectedFilter = selectedValue || this.selectedFilter;
    const filterDate = dateToDatabaseDate(selectedDate, false, false);
    const employeeFilteredAppointments = this.getAppointmentsForEmployeesFilter(this.selectedFilter);
    const currentFilteredRaw = employeeFilteredAppointments.filter(termin => termin.indexDate === filterDate);
    this.currentFilteredAppointments = termineToMoreSpecificDatasets(
      currentFilteredRaw,
      this.rightsService.getCurrentRight()
    );
    if (!isNullOrUndefined(selectedValue))
      // mitarbeiterfilter hat sich geändert => tage neu einfärben
      void this.recolorAppointments(employeeFilteredAppointments);
  }

  /**@description Färbt alle vorhandenen Termine farblich ein */
  private async recolorAppointments(filteredTermineByMitarbeiter: HWTermin[]) {
    await delay(100);
    const cells = document.getElementsByClassName('dx-calendar-cell');
    const cellAmount = cells.length;
    for (let i = 0; i < cellAmount; i++) {
      const cell = cells[i];
      const dataSet = cell['dataset'];
      const date = dataSet.value as string;
      const splitDate = date.split('/');
      const day = splitDate[2];
      const month = splitDate[1];
      const year = splitDate[0];
      const germanDate = day + '.' + month + '.' + year;
      const AppointmentOnDay = filteredTermineByMitarbeiter?.some(termin => termin.indexDate === germanDate);
      if (AppointmentOnDay) cell.setAttribute('style', 'background-color: #dadada');
      else cell.setAttribute('style', 'background-color: white;');
    }
  }

  /**
   * @description Wird aufgerufen sobald ein Date angeklickt wird. setzt das Date als ausgewählt und baut einen neuen default Termin für diesen Tag und
   * belegt ihn vor.
   */
  onValueChange(clickedDate: Date): void {
    this.selectedDate = clickedDate;
    this.filterChange(null, clickedDate);
  }

  private getAppointmentsForEmployeesFilter(selectedEmployeesFilter: string): HWTermin[] {
    switch (selectedEmployeesFilter) {
      case 'Alle Termine':
        return this.allAppointments;
      case 'Nur meine Termine':
        return this.allAppointments?.filter(
          filterTermin =>
            filterTermin.mitarbeiter === this.ownCustomerNumber ||
            filterTermin.MA_ID_LIST?.includes(this.ownCustomerNumber)
        );
      default: {
        const employeeName = this.selectedFilter;
        const employeeNumber = this.AllEmployees.find(e => e.NAME === employeeName).getKundennummer();
        return this.allAppointments?.filter(filterTermin => filterTermin.MA_ID_LIST?.includes(employeeNumber));
      }
    }
  }

  /**@description Wird aufgerufen wenn der Nutzer die ein Kalenderfeld anklickt - prüft dann auf einen Monatswechsel */
  onOptionChanged(eventName: string, time: Date, prevTime: Date): void {
    if (eventName === 'isActive' || eventName === 'value')
      // triggert sonst auch bei normalen FeldClick
      return;
    const currentMitarbeiterFilter = this.getAppointmentsForEmployeesFilter(this.selectedFilter);
    void this.recolorAppointments(currentMitarbeiterFilter);
    if (isNullOrUndefined(time) || !isDate(time) || isNullOrUndefined(prevTime)) return;
    if (time.getMonth() !== this.today.getMonth() && time.getMonth() !== prevTime.getMonth()) {
      // Monatswechsel
      time.setDate(1);
      this.currentValue = time;
    }
  }

  goToToday(): void {
    const todayButton = document.getElementsByClassName('dx-calendar-today-button')[0] as HTMLElement;
    todayButton.click();
  }

  /**@description Nachdem man auf einen termin in der suche geklickt hat wird dieses event ausgeführt */
  async onAppointmentInSearchSelected(termin: HWTermin): Promise<void> {
    if (isNullOrUndefined(termin)) {
      return;
    }

    await this.routingService.navigateTo('termin/' + termin.id);
  }

  async navigateTo(path: string): Promise<void> {
    const dateInMs = this.selectedDate.getTime();
    await this.routingService.navigateTo(`${path}/${dateInMs}`);
  }
}
