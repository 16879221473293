import { Component, NgModule, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { noBackAvailable, syncButtonRoutes } from '../../../config/Konstanten';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { SyncService } from '../../../services/globalServices/sync.service';
import { urlToSyncserviceName } from 'libs/shared/src/lib/helper/globalHelper';
import { DialogService } from '@handwerk-pwa/shared';
import { ControllerService } from '../../../services/globalServices/controller.service';
import { LocalstorageService } from '../../../services/globalServices/localstorage.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;
  backExists = true;
  routingSubscription: Subscription;
  syncExists = true;

  hiddenFeatureCounter = 0;

  newThings: BehaviorSubject<boolean>;

  constructor(
    private routingService: RoutingService,
    private globalSettingService: HWGolbalsettingService,
    private syncService: SyncService,
    private dialogService: DialogService,
    private controllerService: ControllerService,
    private localStorageService: LocalstorageService
  ) {
    this.newThings = this.syncService.newThingsDiscovered;
  }

  ngOnDestroy(): void {
    this.routingSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.routingSubscription = this.routingService.currentRoute.subscribe(newRoute => this.reactOnNewRoute(newRoute));
  }

  /**@description Reagiert auf eine neue Route indem anschließend geguckt wird, ob Back und Sync gezeigt werden sollen */
  reactOnNewRoute(newRoute: string): void {
    if (newRoute.startsWith('/')) newRoute = newRoute.substring(1);
    this.backExists = !noBackAvailable.includes(newRoute);
    this.syncExists = syncButtonRoutes.includes(newRoute);
  }

  toggleMenu = (): void => {
    const currentMenuState = this.routingService.menuOpen.value;
    this.routingService.menuOpen.next(!currentMenuState);
    this.menuToggle.emit(!currentMenuState);
  };

  routeBack = (): void => {
    void this.routingService.routeBack();
  };

  synchronize = async (): Promise<void> => {
    this.routingService.nextMenuState(false);
    const userInfo = await this.globalSettingService.getUserinfo();
    const currentRoute = this.routingService.lastRoutes[0];
    const service = urlToSyncserviceName(currentRoute);
    if (service === 'belegservice') {
      this.routingService.reload(); // sonderfall, die belege seite lädt selbst
      return;
    }
    await this.syncService.getSpecificDataFromWebService(userInfo, service, false);
    this.routingService.reload();
  };

  /**@description Wird 5 mal in Folge auf header geklickt, kann damit die Datenbank zurückgesetzt werden */
  async hiddenFeature(): Promise<void> {
    const currentRoute = this.routingService.lastRoutes[0];
    if (currentRoute !== '/Login') {
      this.hiddenFeatureCounter = 0;
      return;
    }
    this.hiddenFeatureCounter++;
    if (this.hiddenFeatureCounter < 5) {
      return;
    }
    const responseDelete = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Mit dieser Funktion löschen Sie alle mobilen Daten. ' +
        'Nicht synchronisierte Daten gehen unwiederbringlich verloren.',
      'Datenbank löschen',
      'Nicht löschen',
      false
    );
    if (responseDelete) {
      this.hiddenFeatureCounter = 0;
      await this.controllerService.upgradeIndexedDB();
      await this.routingService.navigateTo('/Reload' + '/Login');
      this.localStorageService.clearLocalSessionData();
    }
  }
}

@NgModule({
  imports: [CommonModule, DxButtonModule, DxToolbarModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
