<div class="content-block bottom-padding">
  <div class="form-block">
    <h2>Neue Messung</h2>

    <div class="dx-card responsive-paddings messungen">
      <div class="messungenBox">
        <div class="messungenLabel">Beschr.</div>
        <div class="messungenField">Sollwert</div>
        <div class="messungenFieldDoubleWidth messungenBox">
          <div class="messungenField">Istwert</div>
          <div class="messungenField" style="text-align: right">Einheit</div>
        </div>
      </div>

      <div style="padding-bottom: 10px">
        <div *ngFor="let sollwert of Sollwerteintraege; let i = index">
          <app-bss-measuring-field
            (keyboardOpen)="openKeyboard = $event"
            [fieldIndex]="i"
            [noSollwert]="false"
            *ngIf="Messwerteintraege"
            [label]="sollwert.Beschreibung"
            [sollwert]="sollwert.Messwert"
            [(messwert)]="Messwerteintraege[i].Messwert"
            [unit]="sollwert.Einheit"
          >
          </app-bss-measuring-field>
        </div>
      </div>

      <app-bss-toggle-textarea
        *ngIf="Sollwersatz"
        [buttonText]="'Beschreibung Sollwert'"
        [(value)]="Sollwersatz.Beschreibung"
        [disabled]="true"
      ></app-bss-toggle-textarea>
      <app-bss-toggle-textarea
        *ngIf="Messung"
        [buttonText]="'Bemerkung Istwert'"
        [(value)]="Messung.Beschreibung"
      ></app-bss-toggle-textarea>
    </div>
  </div>
</div>

<dx-speed-dial-action
  *ngIf="!openKeyboard"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (onClick)="addMessung()"
></dx-speed-dial-action>
