<h2 class="content-block">Termine</h2>

<div class="content-block bottom-padding">
  <app-bss-list
    [id]="'calender'"
    [inputDatasource]="appointmentsForCustomer"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine Termine vorhanden'"
  ></app-bss-list>
</div>

<dx-speed-dial-action
  icon="add"
  [index]="1"
  label="Neuer Termin"
  (onClick)="navigateTo('termin/neu')"
></dx-speed-dial-action>
