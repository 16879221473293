import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import { Anlagendaten } from '../../models/wartung-und-service/Anlagendaten';
import { UUID } from 'angular2-uuid';

export class MessungenFromHandwerk {
  Anlage: string;
  Anlagentyp: string;
  Beschreibung: string;
  Messdatum: string;
  Uuid: string;
  messwerte: string[];
  constructor(data: any) {
    Object.assign(this, data);
    this.Messdatum = this.Messdatum.substr(0, 10);
  }
}
export class MesswertTabellenEintrag {
  Beschreibung: string;
  Messwert: string;
  Einheit: string;

  constructor(beschreibung: string, messwert: string, einheit: string) {
    this.Beschreibung = beschreibung;
    this.Messwert = messwert;
    this.Einheit = einheit;
  }
}

export class Messwerttabelle {
  Anlagentyp: string; // nummer des anlagentys
  Anlage: string; // anlagen nummer
  MesswertTabellenEintraege: MesswertTabellenEintrag[] = [];
  Beschreibung = '';
  UUID = UUID.UUID();
  Messdatum = dateToDatabaseDate(new Date(), false, false);
  notTransferred: boolean;

  constructor(anlagendaten: Anlagendaten, vorlage?: Messwerttabelle, clean?: boolean) {
    this.Anlagentyp = anlagendaten.DATENBLNR;
    this.Anlage = anlagendaten.ANLAGE;
    if (isNullOrUndefined(vorlage)) {
      return;
    }
    for (const eintrag of vorlage.MesswertTabellenEintraege) {
      let value = eintrag.Messwert;
      if (clean) {
        value = '';
      }
      const newEintrag = new MesswertTabellenEintrag(eintrag.Beschreibung, value, eintrag.Einheit);
      this.MesswertTabellenEintraege.push(newEintrag);
    }
  }
}

export class Messwertsatz {
  Sollwerttabelle: Messwerttabelle;
  Istwerttabellen: Messwerttabelle[] = [];

  constructor(anlagendaten: Anlagendaten) {
    this.Sollwerttabelle = new Messwerttabelle(anlagendaten);
  }
}
