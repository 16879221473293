import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getEtagen, getWohnungen, RoomEntityName } from 'libs/shared/src/lib/helper/aufmassHelper';
import { Aufmass, Raumname } from '../../../entities';
import { RaumbPos } from '../../../entities/models/aufmass/RaumbPos';

@Component({
  selector: 'app-raumvorlage',
  templateUrl: './raumvorlage.component.html',
  styleUrls: ['./raumvorlage.component.scss'],
})
export class RaumvorlageComponent implements OnInit {
  raumbuchPosition: RaumbPos;
  @Input() raumNamen: Raumname[];
  @Input() parentRoom: RaumbPos;
  @Input() parentIsRaumbuch: boolean;
  @Input() raumbuchPositionen: RaumbPos[];
  @Input() aufmass: Aufmass;
  @Output() selectedRaumbuchposition = new EventEmitter<RaumbPos>();
  etagen: RaumbPos[];
  wohnungen: RaumbPos[];
  etage: RaumbPos;
  wohnung: RaumbPos;
  roomEntityType: RoomEntityName = 'Raum oder Wohnung';
  constructor() {}

  ngOnInit(): void {
    const newRoom = new RaumbPos(null);
    newRoom.fillNewFromRelative(this.parentRoom, this.parentIsRaumbuch, this.aufmass);
    this.raumbuchPosition = newRoom;
    this.etagen = getEtagen(this.raumbuchPositionen);
    this.wohnungen = getWohnungen(this.raumbuchPositionen);
    this.etage = newRoom.findAccordingEtage(this.raumbuchPositionen);
    this.wohnung = newRoom.findAccordingWohnung(this.raumbuchPositionen);
    this.roomEntityType = this.getRoomEntityType(this.raumbuchPosition);
  }

  getRoomEntityType(raumbuchPosition: RaumbPos): RoomEntityName {
    if (raumbuchPosition.isEtage()) return 'Etage';
    if (raumbuchPosition.level === 1) return 'Raum';
    return 'Raum oder Wohnung';
  }

  save(raumbuchPosition: RaumbPos): void {
    this.selectedRaumbuchposition.emit(raumbuchPosition);
  }
}
