import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  base64UnterschriftMock,
  Punkt,
  AufmassStrecke,
  Beschriftung,
  Bauelement,
  Aufmass,
  AufmassKonstrukt,
  AppOnlySettings,
} from 'apps/handwerkPWA/src/app/entities';
import { HWFile } from 'apps/handwerkPWA/src/app/entities';
import { HTMLInputEvent, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { RaumbPos } from '../../../entities/models/aufmass/RaumbPos';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { AufmassService } from '../../../services/dataServices/aufmass.service';
import { DialogService } from '@handwerk-pwa/shared';
import { isStreckeClosed } from '../../../entities/models/aufmass/AufmassStrecke';
import {
  cutoutOfCanvas,
  getExistingPointIfClose,
  getAufmasspictureBoundaries,
} from 'libs/shared/src/lib/helper/aufmassHelper';
import { BauelementVorlage } from '../../../entities/models/aufmass/BauelementVorlage';
import { BauelementVorlagen } from '../../../config/BauelementVorlagen';
import { AufmassDrawStackelement, inputMode } from '../../../entities/models/aufmass/AufmassDrawStackelement';
import { Form, generateBauplan } from '../../../entities/models/aufmass/Form';
import { Formen } from '../../../config/AufmassFormen';
import { createHWFile } from '../../../entities/repository/HWFile';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { AufmassDrawStack } from '../../../entities/models/aufmass/AufmassDrawStack';
import { delay } from 'q';
import { Subscription } from 'rxjs';

const iconSize = 40;
let height: number;
let width: number;
let clientHeight: number;
let context: CanvasRenderingContext2D;
@Component({
  selector: 'app-aufmass-draw',
  templateUrl: './aufmass-draw.component.html',
  styleUrls: ['./aufmass-draw.component.scss'],
})
export class AufmassDrawComponent implements OnInit, OnDestroy {
  @ViewChild('bssCanvas', { static: true }) bssCanvas: ElementRef<HTMLCanvasElement>;
  imageUrl = base64UnterschriftMock;
  aufmassPunkte: Punkt[] = [];
  bauelementePunkte: Punkt[] = [];
  grid: AufmassStrecke[] = [];
  aufmassStrecken: AufmassStrecke[] = [];
  bauelementeStrecken: Bauelement[] = [];
  beschriftungen: Beschriftung[] = [];
  gridEnabled = false;
  beschriftung: string;
  currentMode: inputMode = 'none';
  textInput = '';
  raum: RaumbPos;
  aufmass: Aufmass;
  bauelemente = BauelementVorlagen;
  currentBauelement: BauelementVorlage;
  streckeClosed = false;
  drawStackElements: AufmassDrawStackelement[] = [];
  formen: Form[] = Formen;
  appOnlySettings: AppOnlySettings;
  userFactor: number;
  saveSubscription: Subscription;

  constructor(
    private routingService: RoutingService,
    private aufmassService: AufmassService,
    private dialogService: DialogService,
    private globalSettingService: HWGolbalsettingService
  ) {}

  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.routingService.dataChanged.next(true);
    const stack = await this.loadRaum();
    this.appOnlySettings = await this.globalSettingService.getAppOnlySettings();
    this.setSizes();
    this.drawBackground('white');
    this.saveSubscription = this.routingService.save.subscribe(
      () => void this.saveCurrentLocally(this.aufmass, this.raum, false)
    );
    if (!stack || !(this.drawStackElements.length > 0)) return this.createGridOnUserSetting(this.appOnlySettings);
    this.rebuildDrawingFromStack(false);
    if (stack?.gridEnabled) this.drawGrid();
  }

  private createGridOnUserSetting(settings: AppOnlySettings): void {
    if (settings.aufmassGridOnStart) this.drawGrid(false);
  }

  /**@description Lädt die Raum Entität */
  private async loadRaum(): Promise<AufmassDrawStack> {
    const uuid = this.routingService.getRouteParam('aufmassid');
    const raumid = this.routingService.getRouteParam('raumid');
    this.aufmass = await this.aufmassService.findOneBy('Uuid', uuid);
    const raumbuchPositionen = this.aufmass.getRaumbuchpositionen();
    this.raum = raumbuchPositionen.find(position => position.Uuid === raumid);
    const drawStack = this.aufmass.drawStack.find(stack => stack.raumUuid === raumid);
    if (drawStack) {
      this.drawStackElements = drawStack.AufmassDrawStackElements;
      return drawStack;
    }
    return null;
  }

  /**@description Speichert die größen für Canvas/Berechnungen */
  private setSizes() {
    height = window.innerHeight;
    width = window.innerWidth;
    clientHeight = this.bssCanvas.nativeElement.clientHeight;
    this.bssCanvas.nativeElement.height = this.bssCanvas.nativeElement.clientHeight;
    this.bssCanvas.nativeElement.width = this.bssCanvas.nativeElement.clientWidth;
    context = this.bssCanvas.nativeElement.getContext('2d');
  }

  /**@description Färbt den Canvas */
  private drawBackground(color: string): void {
    context.beginPath();
    context.rect(0, 0, width, clientHeight);
    context.fillStyle = color;
    context.fill();
  }

  /**@description Ersetzt den hintergrund durch ein Bild */
  private setBackgroundPicture(parsedFile: HWFile) {
    const image = new Image(width, clientHeight);
    image.onload = function () {
      context.drawImage(image, 0, 0, width, clientHeight);
    };
    image.src = parsedFile.Data;
  }

  private drawWandFreeOrOnGrid(punkt: Punkt, free: boolean) {
    const drawnPunkt = free
      ? this.drawRectangle(punkt, this.aufmassPunkte)
      : this.drawRectangleOnClosestIntersection(punkt, this.grid);
    this.aufmassPunkte.push(drawnPunkt);
    this.drawAufmassstrecke(this.aufmassPunkte);
  }

  private setTextToCanvas(punkt: Punkt) {
    const textInput = document.getElementById('textinput') as HTMLInputElement;
    textInput.style.left = punkt.xKoordinate + 'px';
    textInput.style.top = punkt.yKoordinate + 'px';
    textInput.style.display = 'block';
  }

  /**@description Malt auf einer bestehnden AufmassStrecke einen Punkt - gibt die aufmaßstrecke zurück,auf der der punkt gemalt wurde */
  private placeBauelementOnAufmassstrecke(punkt: Punkt, aufmassStrecken: AufmassStrecke[]): AufmassStrecke {
    const streckeForBauelement = aufmassStrecken.find(strecke => strecke.isPointOnAufmassStrecken(punkt, 20));
    if (!streckeForBauelement) return null;
    const pointOnStrecke = streckeForBauelement.getPointOnStrecke(punkt);
    this.drawRectangle(pointOnStrecke, null, true, 'blue');
    this.bauelementePunkte.push(pointOnStrecke);
    return streckeForBauelement;
  }

  /**@description Verbindet die letzten beiden Punkte zu einer Strecke und fügt sie den AufmassStrecken hinzu */
  private drawAufmassstrecke(punkte: Punkt[], isBauelement?: boolean, abzugFromUuid?: string): void {
    const length = punkte.length;
    if (length < 2)
      // noch keine Strecke
      return;
    const startPoint = punkte[length - 2];
    const endPoint = punkte[length - 1];

    if (isBauelement) return this.drawBauelement(startPoint, endPoint, abzugFromUuid);
    this.drawWand(length, startPoint, endPoint);
  }

  private drawWand(length: number, startPoint: Punkt, endPoint: Punkt) {
    const index = length - 1;
    const color = 'red';
    const aufmassStrecke = new AufmassStrecke(startPoint, endPoint, index);
    this.drawLine(aufmassStrecke, color);
    this.addBeschriftung(aufmassStrecke.mittelPunkt, aufmassStrecke.bezeichnung, color);
    this.aufmassStrecken.push(aufmassStrecke);
    this.streckeClosed = isStreckeClosed(this.aufmassStrecken);
  }

  private drawBauelement(startPoint: Punkt, endPoint: Punkt, abzugFromUuid: string) {
    const vorlage = this.currentBauelement;
    const bauelementIndex = this.bauelementeStrecken.length + 1;
    const bauElementeColor = 'blue';
    this.bauelementePunkte = [];
    const elementIndex = this.bauelementeStrecken?.filter(bauElementEntry =>
      bauElementEntry.bezeichnungLang?.startsWith(vorlage.bezeichnung)
    )?.length;
    const alternatingIndex = elementIndex ? elementIndex + 1 : 1;
    const bauElement = new Bauelement(startPoint, endPoint, vorlage, bauelementIndex, abzugFromUuid, alternatingIndex);
    this.drawLine(bauElement, bauElementeColor);
    this.addBeschriftung(bauElement.mittelPunkt, bauElement.bezeichnung, bauElementeColor);
    void this.drawIcon(bauElement.mittelPunkt, bauElement.vorlage.iconPath);
    this.bauelementeStrecken.push(bauElement);
    this.setCurrentMode('none');
  }

  /**@description Malt am punkt ein icon */
  private async drawIcon(mittelPunkt: Punkt, iconPath: string) {
    const size = iconSize;
    const image = new Image(width, clientHeight);
    image.src = iconPath;
    await delay(25);
    context.drawImage(image, mittelPunkt.xKoordinate, mittelPunkt.yKoordinate, size, size);
  }

  /**@description Malt einen Punkt - prüft ob es nahe an einem bestehenden ist und interpretiert den punkt dann als verbindung mit bereits bestehendem */
  private drawRectangle(punkt: Punkt, aufmassPunkte: Punkt[], bypassCheck?: boolean, color = 'red'): Punkt {
    context.fillStyle = color;
    const thickNess = 10;
    const offSet = thickNess / 2;
    if (bypassCheck) {
      context.fillRect(punkt.xKoordinate - offSet, punkt.yKoordinate - offSet, thickNess, thickNess);
      return punkt;
    }
    const pointToDraw = getExistingPointIfClose(punkt, aufmassPunkte);
    context.fillRect(pointToDraw.xKoordinate - offSet, pointToDraw.yKoordinate - offSet, thickNess, thickNess);
    return pointToDraw;
  }

  /**@description Schreibt einen Text in das Bild */
  private addBeschriftung(punkt: Punkt, beschriftung: string, color = 'red'): void {
    context.font = '20px Arial';
    context.fillStyle = color;
    context.fillText(beschriftung, punkt.xKoordinate, punkt.yKoordinate);
  }

  /**@description Malt eine AufmassStrecke */
  private drawLine(aufmassStrecke: AufmassStrecke, color = 'black'): void {
    const lineStart = aufmassStrecke.startPunkt;
    const lineEnd = aufmassStrecke.endPunkt;
    context.fillStyle = color;
    context.strokeStyle = color;
    context.beginPath();
    const xPointFrom = lineStart.xKoordinate;
    const yPointFrom = lineStart.yKoordinate;
    const xPointTo = lineEnd.xKoordinate;
    const yPointTo = lineEnd.yKoordinate;
    context.moveTo(xPointFrom, yPointFrom);
    context.lineTo(xPointTo, yPointTo);
    context.stroke();
    context.closePath();
    context.restore();
  }

  /**@description Interpretiert den Punkt so, dass jeweils die nächstgelegene Grid Kruezung als Punkt genommen wird */
  private drawRectangleOnClosestIntersection(punkt: Punkt, grid: AufmassStrecke[]): Punkt {
    let shortesXDistance = width;
    let shortesYDistance = height;
    let shortesXPoint = new Punkt(width, height);
    let shortesYPoint = new Punkt(width, height);
    for (const line of grid) {
      const xDistance = Math.abs(punkt.xKoordinate - line.startPunkt.xKoordinate);
      if (xDistance < shortesXDistance) {
        shortesXDistance = xDistance;
        shortesXPoint = line.startPunkt;
      }
      const yDistance = Math.abs(punkt.yKoordinate - line.startPunkt.yKoordinate);
      if (yDistance < shortesYDistance) {
        shortesYDistance = yDistance;
        shortesYPoint = line.startPunkt;
      }
    }

    const closestIntersection = new Punkt(shortesXPoint.xKoordinate, shortesYPoint.yKoordinate);
    this.drawRectangle(closestIntersection, null, true);
    return closestIntersection;
  }

  private placeBauelementInWall(punkt: Punkt) {
    const aufmassstreckeCorrespondingToBauelement = this.placeBauelementOnAufmassstrecke(punkt, this.aufmassStrecken);
    if (aufmassstreckeCorrespondingToBauelement)
      this.drawAufmassstrecke(this.bauelementePunkte, true, aufmassstreckeCorrespondingToBauelement.uuid);
  }

  private placeBauelementInRoom(punkt: Punkt) {
    this.bauelementePunkte.push(punkt);
    const mockSecondPointForMockStrecke = new Punkt(punkt.xKoordinate + 1, punkt.yKoordinate + 1);
    this.bauelementePunkte.push(mockSecondPointForMockStrecke);
    const bodenDecke = this.currentBauelement.inBoden ? 'boden' : 'decke';
    this.drawAufmassstrecke(this.bauelementePunkte, true, bodenDecke);
  }

  // #################################################################################################################################################################################
  // Button Functions
  // #################################################################################################################################################################################

  setCurrentMode(mode: inputMode, vorlage?: BauelementVorlage): void {
    this.currentMode = mode;
    this.currentBauelement = vorlage;
  }

  /**@description Fügt dem Canvas ein Bild hinzu */
  async putImageIntoCanvas(input: HTMLInputEvent | Event): Promise<void> {
    const files = (input as HTMLInputEvent).target.files[0];
    const parsedFile = await createHWFile(files, files.name);
    this.setBackgroundPicture(parsedFile[0]);
  }

  async askForNew(): Promise<void> {
    const confirm = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Soll die Grundriss-Skizze endgültig verworfen werden?',
      'Ja',
      'Nein'
    );
    if (confirm) this.resetAufmassZeichnung();
  }

  /**@description Setzt alles zurück NEU*/
  private resetAufmassZeichnung(gridToggle = false): void {
    this.gridEnabled = false;
    this.aufmassStrecken = [];
    this.aufmassPunkte = [];
    this.beschriftungen = [];
    this.bauelementeStrecken = [];
    this.streckeClosed = false;
    this.drawStackElements = [];
    context.clearRect(0, 0, this.bssCanvas.nativeElement.width, this.bssCanvas.nativeElement.height);
    this.drawBackground('white');
    this.setCurrentMode('none');
    if (!gridToggle) void this.createGridOnUserSetting(this.appOnlySettings);
  }

  drawGrid(inRedrawRoutine = false): void {
    if (!inRedrawRoutine && this.gridEnabled) return this.undrawGrid();
    this.gridEnabled = true;
    const heightBiggerWidth = height > width;
    this.userFactor = isNullOrUndefined(this.aufmass.usedGridFactor)
      ? this.appOnlySettings.aufmassRasterFactor
      : this.aufmass.usedGridFactor;
    const step = heightBiggerWidth
      ? Math.round(height / 40) * (this.userFactor / 50)
      : Math.round(width / 40) * (this.userFactor / 50);
    const iterationLevel = heightBiggerWidth ? height : width;
    let steps = 0;
    for (let heightPosition = 0; heightPosition < iterationLevel; heightPosition = heightPosition + step) {
      const linieXStart = new Punkt(0, heightPosition);
      const linieXEnde = new Punkt(width, heightPosition);
      const verticalLine = new AufmassStrecke(linieXStart, linieXEnde, 99);
      const linieYStart = new Punkt(step * steps, 0);
      const linieYEnde = new Punkt(step * steps, height);
      const horizontalLine = new AufmassStrecke(linieYStart, linieYEnde, 99);
      this.grid.push(verticalLine);
      this.grid.push(horizontalLine);
      this.drawLine(verticalLine, 'lightgrey');
      this.drawLine(horizontalLine, 'lightgrey');
      steps++;
    }
  }

  private undrawGrid() {
    this.gridEnabled = false;
    this.rebuildDrawingFromStack(false, true);
  }

  async createAufmassKonstrukt(aufmass: Aufmass, raum: RaumbPos): Promise<void> {
    if (!this.streckeClosed) {
      void this.dialogService.openErrorMessage(
        'Strecke nicht abgeschlossen',
        'Der Grundriss ist nicht abgeschlossen, eine Messung wäre nicht sinnvoll.'
      );
      return;
    }
    await this.saveCurrentLocally(aufmass, raum, true);
  }

  private async saveCurrentLocally(aufmass: Aufmass, raum: RaumbPos, gotoMessung: boolean) {
    const canvas = this.bssCanvas.nativeElement;
    const resize = getAufmasspictureBoundaries(this.aufmassPunkte, iconSize);
    const pictureDataBase64 = await cutoutOfCanvas(
      canvas.toDataURL('image/jpeg'),
      resize.boundaryX,
      resize.boundaryY,
      resize.width,
      resize.height
    );
    const aufmassKonstrukt = new AufmassKonstrukt(
      pictureDataBase64,
      this.aufmassStrecken,
      this.bauelementeStrecken,
      this.beschriftungen
    );
    aufmassKonstrukt.addRaumhoeheAndBodenAndDeckenflaeche();
    aufmass.usedGridFactor = this.userFactor;
    aufmass.assignAufmassKonstruktToCorrectRaum(raum, aufmassKonstrukt);
    aufmass.assignDrawstackElements(raum.Uuid, this.drawStackElements, this.gridEnabled);
    await this.aufmassService.overrideOneLocal(aufmass);
    this.routingService.dataChanged.next(false);
    if (gotoMessung) void this.routingService.navigateTo(`aufmass/messung/${aufmass.Uuid}/${raum.Uuid}`);
    else void this.routingService.routeBack();
  }

  /**@description Schreibt einen Text aus der Textinput Funktion in das Bild */
  addTextToCanvas(textInput: string): void {
    const textInputField = document.getElementById('textinput') as HTMLInputElement;
    const xCoordinatePx = textInputField.style.left;
    const xCoordinate = parseInt(xCoordinatePx.replace('px', ''), 10);
    const yCoordinatePx = textInputField.style.top;
    const yCoordinate = parseInt(yCoordinatePx.replace('px', ''), 10);
    const inputPunkt = new Punkt(xCoordinate, yCoordinate);
    const beschriftung = new Beschriftung(inputPunkt, textInput);
    this.beschriftungen.push(beschriftung);
    this.addBeschriftung(inputPunkt, textInput, 'green');
    this.setCurrentMode('none');
    textInputField.style.display = 'none';
    this.textInput = '';
  }

  /**@description Event wenn auf den Canvas geklickt wird - malt je nach Modus punkte auf ihn */
  clickOnCanvas(
    eventOrPunkt: MouseEvent | Punkt,
    gridEnabled: boolean,
    currentMode: inputMode,
    currentBauelement?: BauelementVorlage
  ): void {
    const punkt = eventOrPunkt instanceof MouseEvent ? new Punkt(eventOrPunkt.x, eventOrPunkt.y) : eventOrPunkt;
    const stackElement = new AufmassDrawStackelement(punkt, gridEnabled, currentMode, currentBauelement);
    if (currentMode === 'drawpoints' && this.streckeClosed) return;
    currentMode = this.getCorrectDrawMode(gridEnabled, currentMode, currentBauelement);
    if (currentMode !== 'none') this.drawStackElements.push(stackElement);
    switch (currentMode) {
      case 'textinput':
        return this.setTextToCanvas(punkt);
      case 'drawpoints':
        return this.drawWandFreeOrOnGrid(punkt, true);
      case 'wandGrid':
        return this.drawWandFreeOrOnGrid(punkt, false);
      case 'bauelementRaum':
        return this.placeBauelementInRoom(punkt);
      case 'bauelementWand':
        return this.placeBauelementInWall(punkt);
    }
  }

  private getCorrectDrawMode(
    gridEnabled: boolean,
    currentMode: inputMode,
    currentBauelement: BauelementVorlage
  ): inputMode {
    if (gridEnabled && currentMode === 'drawpoints') currentMode = 'wandGrid';
    if (currentMode === 'bauelement' && currentBauelement.inRaum) currentMode = 'bauelementRaum';
    if (currentMode === 'bauelement' && !currentBauelement.inRaum) currentMode = 'bauelementWand';
    return currentMode;
  }

  /**@description Setzt das zuletzt getätigte zurück indem es den Stack bis zum letzten Element nachbaut */
  rebuildDrawingFromStack(undoLastStep = true, toggleGrid = false): void {
    const currentMode = this.currentMode;
    const gridEnabled = this.gridEnabled;
    if (undoLastStep) this.removeItemsFromDrawstack();
    const correctStack = this.drawStackElements.slice();
    this.resetAufmassZeichnung(toggleGrid);
    this.gridEnabled = gridEnabled;
    if (gridEnabled) this.drawGrid(undoLastStep);
    for (const stackElement of correctStack) {
      const stackElementMode = stackElement.currentMode;
      if (stackElementMode === 'bauelement') this.currentBauelement = stackElement.currentBauelement;
      this.clickOnCanvas(
        stackElement.event,
        stackElement.gridEnabled,
        stackElementMode,
        stackElement.currentBauelement
      );
    }
    this.drawStackElements = correctStack;
    this.setCurrentMode(currentMode);
  }

  private removeItemsFromDrawstack() {
    const lastItem = this.drawStackElements[this.drawStackElements.length - 1];
    const currentBauelement = lastItem.currentBauelement;
    if (currentBauelement && !currentBauelement.inRaum) {
      // Bauelemente in Wand, 2 Schritte
      this.drawStackElements.pop();
      this.drawStackElements.pop();
    } // Wände und Bauelemente im Raum, 1 Schritt
    else this.drawStackElements.pop();
  }

  /**@description Malt anhand einer Formvorlage */
  drawFromForm(form: Form): void {
    const offsetY = 80;
    const scaleSide = screen.height > screen.width ? screen.width : screen.height;
    const scaleFactor = scaleSide * 0.25;
    const bauplan = generateBauplan(form, scaleFactor, scaleFactor, 0, offsetY);
    for (const punkt of bauplan) this.clickOnCanvas(punkt, this.gridEnabled, 'drawpoints');
  }
}
