import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';

export class Employeesettings {
  autoLohn = 'Aus';
  showPrices = false;
  printPrices = false;
  pdfPreview = false;
  sendRepairOrderMail = false;
  sumUp = false;
  addWorkingHoursToOrder = true;
  addWorkingHoursToPDF = true;

  constructor(employeeSettings: Employeesettings) {
    assignIfPropertyExsits(this, employeeSettings);
  }
}
