import { AufmassStrecke } from './AufmassStrecke';
import { Punkt } from './Punkt';

export class Form {
  Bezeichner: string = null;
  PunktStrecke: Punkt[] = [];
  iconPath?: string = null;
  FormelId?: number = null;
}

export function generateBauplan(
  form: Form,
  scaleFactorX: number,
  scaleFactorY: number,
  offsetX: number,
  offsetY: number
): Punkt[] {
  const bauplan: Punkt[] = [];
  const punktStrecke = form.PunktStrecke.slice();
  for (const punkt of punktStrecke) {
    const newPoint = new Punkt(punkt.xKoordinate * scaleFactorX + offsetX, punkt.yKoordinate * scaleFactorY + offsetY);
    bauplan.push(newPoint);
  }
  return bauplan;
}
