import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { AufmassGrunddaten } from '../../entities/models/aufmass/AufmassGrunddaten';
import { RaumbPos } from '../../entities/models/aufmass/RaumbPos';
import { Aufmass } from '../../entities/repository/Aufmass';
import { compareAufmass } from '../../helper/entities/HWAddress/sortHelper';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';
import { RaumbuchService } from './raumbuch.service';
import { RaumnameService } from './raumname.service';
import { RvorlageService } from './rvorlage.service';

@Injectable({
  providedIn: 'root',
})
export class AufmassService {
  constructor(
    private baseService: BaseService,
    private controllerService: ControllerService,
    private dialogService: DialogService,
    private restService: RestService,
    private raumbuchService: RaumbuchService,
    private raumnamenService: RaumnameService,
    private rvorlageService: RvorlageService
  ) {}

  async getGrunddatenFromWebservice(userInfo: Userinfo, showDialog = true): Promise<AufmassGrunddaten> {
    const targetUrl = 'AufmassGrunddaten';
    if (showDialog) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Aufmaß Grunddaten...');
    await this.syncUntransferred(userInfo);
    const responseData = await this.restService.returnData<AufmassGrunddaten>(userInfo, targetUrl, userInfo);
    if (!responseData) return null;
    const aufmassGrunddaten = new AufmassGrunddaten(responseData);
    await this.raumnamenService.overrideLocal(aufmassGrunddaten.Raumnamen);
    await this.rvorlageService.overrideLocal(aufmassGrunddaten.Raumvorlagen);
    await this.overrideAllLocal(aufmassGrunddaten.Aufmasse);
    if (showDialog) this.dialogService.closeLoadingDialog();
    return aufmassGrunddaten;
  }

  public async overrideAllLocal(aufmasse: Aufmass[]): Promise<void> {
    await this.controllerService.clearStore('Aufmass');
    await this.controllerService.setData('Aufmass', aufmasse);
  }

  public async getAll(): Promise<Aufmass[]> {
    const all = await this.baseService.getAll(Aufmass);
    const sorted = all.sort(compareAufmass);
    return sorted;
  }

  public async findOneBy(selector: string, value: any): Promise<Aufmass> {
    return await this.baseService.findOneBy(Aufmass, selector, value);
  }

  private async destroy(selector: string, value: string) {
    await this.baseService.destroy(Aufmass, selector, value);
  }

  public async overrideOneLocal(aufmass: Aufmass): Promise<void> {
    await this.destroy('Uuid', aufmass.Uuid);
    await this.controllerService.setData('Aufmass', [aufmass]);
  }

  async syncUntransferred(userInfo: Userinfo): Promise<void> {
    const aufmasse = await this.getAll();
    const untransferred = aufmasse.filter(aufmass => aufmass.fullyTransferred === false);
    for (const aufmass of untransferred) {
      await this.syncUntransferredPositionOfAufmass(aufmass, userInfo);
      await this.raumbuchService.syncAllUntransferred(userInfo, aufmass);
    }
  }

  private async syncUntransferredPositionOfAufmass(aufmass: Aufmass, userInfo: Userinfo): Promise<void> {
    const untransferredRooms = aufmass.getRaumbuchpositionen().filter(pos => pos.transferred === false);
    for (const room of untransferredRooms) await this.addRaum(aufmass, room, userInfo, true, false);
  }

  public async addRaum(
    aufmass: Aufmass,
    raumbuchPosition: RaumbPos,
    userInfo: Userinfo,
    syncAfterFail: boolean,
    showDialog: boolean
  ): Promise<void> {
    const targetUrl = 'neuerRaum';
    if (!syncAfterFail) aufmass.addRaumToRaumbuchpositionen(raumbuchPosition);
    if (showDialog) void this.dialogService.openLoadingDialog('Synchronisation', '...füge Raum hinzu...');
    const successData = await this.restService.returnData<boolean>(userInfo, targetUrl, raumbuchPosition);
    const success = isNullOrUndefined(successData) ? false : successData;
    aufmass.fullyTransferred = success;
    raumbuchPosition.transferred = success;
    await this.overrideOneLocal(aufmass);
    if (showDialog) void this.dialogService.closeLoadingDialog();
  }

  async deleteRoom(aufmass: Aufmass, raumbuchPosition: RaumbPos, userInfo: Userinfo, showDialog = true): Promise<void> {
    const deletePositions = aufmass.removeRaumFromRaumbuchpositionen(raumbuchPosition);
    const targetUrl = 'deleteRooms';
    if (showDialog) void this.dialogService.openLoadingDialog('Synchronisation', '...entferne Raumbuchposition...');
    await this.restService.returnData(userInfo, targetUrl, deletePositions);
    await this.overrideOneLocal(aufmass);
    if (showDialog) void this.dialogService.closeLoadingDialog();
  }

  public async saveAufmass(aufmass: Aufmass, userInfo: Userinfo, showDialog = true): Promise<void> {
    const targetUrl = 'Aufmass';
    if (showDialog) void this.dialogService.openLoadingDialog('Synchronisation', '...speichere Aufmass...');
    const aufmassId = await this.restService.returnData<string>(userInfo, targetUrl, aufmass, true);
    if (isNullOrUndefinedOrEmptyString(aufmassId)) {
      const retry = await this.dialogService.openConfirmDialog(
        'Fehler',
        'Eine Aufmaßanlage ist nur online möglich. Prüfen Sie Ihre Internetverbindung und stellen sicher, dass der Webservice korrekt ausgeführt wird.',
        'Erneut versuchen',
        'Abbrechen'
      );
      if (retry) await this.saveAufmass(aufmass, userInfo, showDialog);
      return;
    }
    aufmass.AufmId = aufmassId;
    aufmass.assignAufmassIdToRaumbuchpositionen();
    await this.overrideOneLocal(aufmass);
    if (showDialog) void this.dialogService.closeLoadingDialog();
  }
}
