import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';
import { RepairOrderService } from './repairOrder.service';
import { HWEmailData, HWNachricht } from 'apps/handwerkPWA/src/app/entities';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BaseService } from './base.service';
import { SortBySender } from '../../helper/services/messageHelper';

@Injectable({
  providedIn: 'root',
})
export class NachrichtenService {
  constructor(
    private controllerService: ControllerService,
    private repairOrderService: RepairOrderService,
    private restService: RestService,
    private dialogService: DialogService,
    private baseService: BaseService
  ) {}

  /**@description Holt alle Nachrichten aus der IDB */
  async getAll(): Promise<HWNachricht[]> {
    const messages: HWNachricht[] = await this.baseService.getAll(HWNachricht);
    return messages.reverse();
  }

  /**@description Holt alle gesendete E-Mails aus der IDB */
  async getAllEmailsSend(): Promise<HWEmailData[]> {
    const allEmails = await this.controllerService.getData<HWEmailData[]>('HWEmailData');
    return allEmails;
  }

  /**@description Überschreibt Nachricht in IDB, sbpw um gelesen zu setzen */
  async save(message: HWNachricht): Promise<void> {
    await this.destroy('ID', message.ID);
    await this.writeNachrichtenToIDB([message], false);
  }

  /**
   * @description Löscht Nachrichten mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(HWNachricht, selector, value);
  }

  /**@description Sendet eine Nachricht ans Handwerk */
  async sendNachricht(userInfo: Userinfo, message: HWNachricht, silent = false): Promise<void> {
    message.setGeraeteId(userInfo);
    const response = await this.restService.returnData<HWNachricht>(userInfo, 'AddNachricht', message, silent);
    const messageResponse = new HWNachricht(response);
    const messageToWrite = messageResponse.ID === '' ? message : messageResponse;
    await this.writeNachrichtenToIDB([messageToWrite], false);
  }

  /**@description Schreibt die Nachrichten in die IDB */
  async writeNachrichtenToIDB(message: HWNachricht[], clear?: boolean): Promise<void> {
    if (clear) {
      await this.controllerService.clearStore('HWNachricht');
    }
    await this.controllerService.setData('HWNachricht', message);
  }

  /**@description Teilt dem Handwerk mit, welche Nachrichten gelesen wurden */
  async sendHandwerkGelesen(userInfo: Userinfo, messages: HWNachricht[]): Promise<void> {
    messages.forEach(message => message.setGeraeteId(userInfo));
    await this.restService.returnData(userInfo, 'GeleseneNachrichten', messages);
  }

  async pushUnpushedNachrichten(userInfo: Userinfo): Promise<void> {
    const idbMessages = await this.getAll();
    const sortedIdbMessages = SortBySender(idbMessages);
    const outgoingMessages = sortedIdbMessages.sendFromPWA;
    const unpushedElements = outgoingMessages.filter(object => object.ID === '');
    if (unpushedElements.length === 0) {
      return;
    }
    for (const unpushedElement of unpushedElements) {
      await this.sendNachricht(userInfo, unpushedElement, true);
    }
  }

  async writeEmailToIDB(email: HWEmailData): Promise<void> {
    if (email) {
      await this.controllerService.setData('HWEmailData', [email]);
    }
  }

  /**@description Sendet eine Nachricht ans Handwerk */
  async getAllNachrichtenFromWebservice(userInfo: Userinfo, showDialog = true): Promise<HWNachricht[]> {
    if (showDialog) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole Nachrichten...');
    }
    const messageData = await this.restService.returnData<HWNachricht[]>(
      userInfo,
      'Nachrichten/mandant/' + userInfo.mandant + '/username/' + userInfo.monteur + '/monteur/' + userInfo.monteur,
      null,
      !showDialog
    );
    const messages: HWNachricht[] = [];
    if (!messageData) {
      this.dialogService.closeLoadingDialog();
      return messages;
    }

    for (const data of messageData) {
      const message = new HWNachricht(data);
      const orderNumber = message.AuftragsNr;
      if (!orderNumber) {
        const repairOrder = await this.repairOrderService.getOrderByAuftragsnummer(orderNumber);
        message.Auftrag = repairOrder;
      }
      messages.push(message);
    }

    await this.writeNachrichtenToIDB(messages, true);
    this.dialogService.closeLoadingDialog();

    return messages;
  }
}
