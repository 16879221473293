import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Aufmass, AufmassKonstrukt, AufmassStrecke, Bauelement, Raumname } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { AufmassService } from '../../../services/dataServices/aufmass.service';
import { RaumnameService } from '../../../services/dataServices/raumname.service';
import { RaumbPos } from '../../../entities/models/aufmass/RaumbPos';
import { RaumbuchService } from '../../../services/dataServices/raumbuch.service';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { Raum } from '../../../entities/models/aufmass/Raum';
import { DialogService } from '@handwerk-pwa/shared';
import { validateFormula } from 'libs/shared/src/lib/helper/formelHelper';
import { BssMeasuringFieldComponent } from '../../../components';
import { timer } from 'rxjs';

@Component({
  selector: 'app-aufmass-messung',
  templateUrl: './aufmass-messung.component.html',
  styleUrls: ['./aufmass-messung.component.scss'],
})
export class AufmassMessungComponent implements OnInit {
  AufmassKonstrukt: AufmassKonstrukt;
  raumnamen: Raumname[];
  raum: RaumbPos;
  aufmass: Aufmass;
  wallsDone = false;
  saveAble = false;
  distanceMeterMode = true;
  @ViewChildren(BssMeasuringFieldComponent) measurefields: QueryList<BssMeasuringFieldComponent>;
  handleFieldjumpManually: boolean;

  constructor(
    private routingService: RoutingService,
    private aufmassService: AufmassService,
    private raumnamenService: RaumnameService,
    private raumbuchService: RaumbuchService,
    private globalSettingService: HWGolbalsettingService,
    private dialogService: DialogService
  ) {}

  async ngOnInit(): Promise<void> {
    const uuid = this.routingService.getRouteParam('aufmassid');
    const raumid = this.routingService.getRouteParam('raumid');
    this.aufmass = await this.aufmassService.findOneBy('Uuid', uuid);
    const raumbuchPositionen = this.aufmass.getRaumbuchpositionen();
    this.raum = raumbuchPositionen.find(position => position.Uuid === raumid);
    this.raumnamen = await this.raumnamenService.getAllFlaechenbezeichnungen();
    this.AufmassKonstrukt = this.raum.reconstructAufmassKonstrukt();
    const appOnlySettings = await this.globalSettingService.getAppOnlySettings();
    this.handleFieldjumpManually = appOnlySettings.aufmassAutoFillRectangleRooms && this.AufmassKonstrukt.isRectangle();
    this.wallsDone = this.AufmassKonstrukt?.AufmassStrecken.every(entry => !isNullOrUndefined(entry.laengeGemessen));
    this.saveAble = this.AufmassKonstrukt?.AufmassStrecken.every(entry => !isNullOrUndefined(entry.laengeGemessen));
  }

  /**@default Setzt die Laenge einer gegenüberliegenden wand , wenn diese noch unbelegt ist */
  private fillParallelWall(strecke: AufmassStrecke): void {
    const parallelWall = this.AufmassKonstrukt.AufmassStrecken.find(
      entity => entity.pointDistance === strecke.pointDistance && entity.bezeichnung !== strecke.bezeichnung
    );
    if (!parallelWall) return;
    parallelWall.laengeGemessen = isNullOrUndefined(parallelWall.laengeGemessen)
      ? strecke.laengeGemessen
      : parallelWall.laengeGemessen;
  }

  /**@description Sobald ein Wert geändert wird - prüft anschließend ob damit alle Pflichtmessungen erfüllt sind (alle Wände,Höhe,alle Pflichtfelder aller Bauelemente) */
  messwertChanged(
    strecke: AufmassStrecke | Bauelement,
    event: string,
    handleFieldjumpManually: boolean,
    hoeheOrTiefe?: 'hoehe' | 'tiefe'
  ): void {
    if (!hoeheOrTiefe) strecke.laengeGemessen = parseFloat(event);
    if (hoeheOrTiefe === 'hoehe') (strecke as Bauelement).Hoehe = parseFloat(event);
    if (hoeheOrTiefe === 'tiefe') (strecke as Bauelement).Tiefe = parseFloat(event);

    if (handleFieldjumpManually) this.fillParallelWall(strecke);

    const allWallsMeasured = this.AufmassKonstrukt.checkAllWallsMeasured();

    if (allWallsMeasured) {
      this.AufmassKonstrukt.tryConstructBodenAndDeckeFormula(
        this.AufmassKonstrukt.AufmassStrecken.flatMap(entry => entry.laengeGemessen)
      );
      this.wallsDone = true;
    }
    const allBauelementeMeasured = this.AufmassKonstrukt.checkAllBauelementeMeasured();
    const hoeheMeasured = !isNullOrUndefined(this.AufmassKonstrukt.Raumhoehe.laengeGemessen);
    this.saveAble = allWallsMeasured && allBauelementeMeasured && hoeheMeasured;

    if (handleFieldjumpManually) this.jumpToNextEmptyField();
  }

  /**
   * @description
   *  Bei einer rechteckigen Wand, bei der gegenüberliegende Seiten autoamtisch gefüllt werden sollen, springt
   * dies die nächste leere Fläche an */
  private jumpToNextEmptyField() {
    const delay = timer(300); // minimaler delay war wegen tieferer asynchronität nötig - dann wirkte aber ein höherer delay optisch besser
    delay.subscribe(() => {
      const fieldNames = this.measurefields.map(field => field.label); // Achtung - dies behebt einen IPAD fehler - ansonsten wurden felder nicht gefunden
      const nextEmptyField = this.measurefields.find(field => isNullOrUndefinedOrEmptyString(field.messwert));
      nextEmptyField?.clickNextMeasuringfield(nextEmptyField.fieldIndex - 1, true);
    });
  }

  private setBodenDeckeToZeroIfUndefined(aufmassKonstrukt: AufmassKonstrukt) {
    if (isNullOrUndefinedOrEmptyString(aufmassKonstrukt.Bodenflaeche.formel))
      aufmassKonstrukt.Bodenflaeche.formel = '0';
    if (isNullOrUndefinedOrEmptyString(aufmassKonstrukt.Deckenflaeche.formel))
      aufmassKonstrukt.Deckenflaeche.formel = '0';
  }

  validateFormel(formel: string, fromInput?: 'Deckenfläche' | 'Bodenfläche'): boolean {
    const valid = formel ? validateFormula(formel) : true;
    if (!valid)
      void this.dialogService.openErrorMessage(
        'Eingabefehler',
        `Ihre Eingabe ${formel} ist ungültig und kann nicht berechnet werden. Bitte geben Sie nur Zahlen, Rechenzeichen und Klammern ein.`
      );
    if (valid && fromInput === 'Deckenfläche')
      this.AufmassKonstrukt.Deckenflaeche.formel = formel?.replaceAll('.', ',');
    if (valid && fromInput === 'Bodenfläche') this.AufmassKonstrukt.Bodenflaeche.formel = formel?.replaceAll('.', ',');
    return valid;
  }

  async messungComplete(
    aufmass: Aufmass,
    raumbuchPosition: RaumbPos,
    aufmassKonstrukt: AufmassKonstrukt
  ): Promise<void> {
    this.setBodenDeckeToZeroIfUndefined(aufmassKonstrukt);
    if (
      !this.validateFormel(aufmassKonstrukt.Bodenflaeche.formel) ||
      !this.validateFormel(aufmassKonstrukt.Deckenflaeche.formel)
    )
      return;
    this.routingService.dataChanged.next(false);
    aufmass.assignAufmassKonstruktToCorrectRaum(raumbuchPosition, aufmassKonstrukt);
    const buch = aufmassKonstrukt.convertToRaumbuchentries(raumbuchPosition);
    const userInfo = await this.globalSettingService.getUserinfo();
    const raum = new Raum(buch, aufmassKonstrukt.pictureDataBase64, raumbuchPosition, true);
    await this.raumbuchService.saveGrundriss(aufmass, userInfo, raum, false);
    await this.aufmassService.overrideOneLocal(aufmass);
    void this.routingService.navigateTo(`aufmass/${aufmass.Uuid}/raum/${raumbuchPosition.Uuid}`);
  }
}
