import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-bss-search-popup',
  templateUrl: './bss-search-popup.component.html',
  styleUrls: ['./bss-search-popup.component.scss'],
})
export class BssSearchPopupComponent implements OnInit {
  @Input() headline: string;
  @Input() inputDataSource;
  @Input() customDisplayExpressions: string[] = [];
  @Input() additionalSearchExpression: string[] = [];
  @Input() buttonText: string;
  @Input() buttonStyle = '';
  @Input() icon: string;
  @Input() datasourceIconPath: string;
  @Input() disabled = false;
  @Input() firstLineLabel: string;
  @Output() selectedValue: EventEmitter<any> = new EventEmitter();
  customSearchExpression = [];
  showPopup = false;

  constructor() {}

  ngOnInit(): void {
    this.customSearchExpression = this.customDisplayExpressions.concat(this.additionalSearchExpression);
  }

  onItemClick(item): void {
    this.selectedValue.emit(item);
    this.showPopup = false;
  }

  getPropertyValue(item, index: number): string {
    const propertyValue = item[this.customDisplayExpressions[index]];
    if (isNullOrUndefined(propertyValue)) {
      return null;
    }
    return propertyValue;
  }

  showPopupClicked(disabled: boolean): void {
    if (disabled) return;
    this.showPopup = !this.showPopup;
  }
}
