import { Component, OnInit } from '@angular/core';
import { Anlagenstandort } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Anlagenstandort';
import { Anschrift, HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-system-location',
  templateUrl: './system-location.component.html',
  styleUrls: ['./system-location.component.scss'],
})
export class SystemLocationComponent implements OnInit {
  anlage: HWAnlage;
  anlagenStandort: Anlagenstandort;

  constructor(private routingService: RoutingService, private wartungsprojectsService: WartungsprojectsService) {}

  async ngOnInit(): Promise<void> {
    await this.loadAnlage();
    this.anlagenStandort = this.anlage.Anlagenstandort;
  }

  openMaps(standort: Anschrift): void {
    const ort = standort.Ort;
    const strasse = standort.Strasse;
    window.open('https://www.google.com/maps/search/?api=1&query=' + strasse + '%2C+' + ort, '_blank');
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.wartungsprojectsService.findOneAnlageBy('UUID', anlageUUID);
  }
}
