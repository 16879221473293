import { IndexedDBTypes } from './dbType';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

export class HWOffeneposten extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWOffeneposten') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') Kundennummer: string;
  @IndexedDBTypes.DataField('string') betreff: string;
  @IndexedDBTypes.DataField('string') bezahlung: string;
  @IndexedDBTypes.DataField('string') brutto: string;
  @IndexedDBTypes.DataField('string') datum: string;
  @IndexedDBTypes.DataField('string') mahnst: string;
  @IndexedDBTypes.DataField('string') name: string;
  @IndexedDBTypes.DataField('string') netto: string;
  @IndexedDBTypes.DataField('string') rechbez: string;
  @IndexedDBTypes.DataField('string') bezahlt: string;
  @IndexedDBTypes.DataField('Data') addedToIdb: Date;
  @IndexedDBTypes.DataField('string') UUID: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
    this.setBezahlt();
    this.addTimeStampIfNotExisting();
  }

  setBezahlt() {
    this.rechbez === 'true' ? (this.bezahlt = 'Ja') : (this.bezahlt = 'Nein');
  }

  /**@description Wenn noch keiner vorhanden ist, wird der timestamp hinzugefügt */
  addTimeStampIfNotExisting() {
    if (!isNullOrUndefined(this.addedToIdb)) {
      return;
    }
    const now = new Date();
    this.addedToIdb = now;
  }

  static toString(): string {
    return 'HWOffeneposten';
  }
}
