<div class="content-block bottom-padding" *ngIf="aufmass">
  <div class="form-block">
    <h2>Aufmaßdetails</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <button
            (click)="editAufmass(aufmass.Uuid)"
            class="bss-button button-block primary-button"
            style="width: 100%"
          >
            Aufmaßdaten editieren
          </button>

          <app-bss-plain-text-box
            *ngIf="aufmass.AufName"
            [labelText]="'Aufmaß'"
            [value]="aufmass.AufName + ' ' + aufmass?.AufmId"
          >
          </app-bss-plain-text-box>

          <app-bss-plain-text-box *ngIf="aufmass.Such" [labelText]="'Suchbegriff'" [value]="aufmass.Such">
          </app-bss-plain-text-box>

          <app-bss-plain-text-box *ngIf="aufmass.Betreff" [labelText]="'Betreff'" [value]="aufmass.Betreff">
          </app-bss-plain-text-box>
        </div>

        <div class="fieldbox-mobile">
          <app-bss-plain-text-box
            [labelText]="'Kunde'"
            [valueArray]="[kunde.NAME, kunde.STRASSE, kunde.PLZ + ' ' + kunde.ORT]"
            [buttonIcon]="'map'"
            (buttonClick)="openMaps(kunde.STRASSE, kunde.PLZ, kunde.ORT)"
          >
          </app-bss-plain-text-box>

          <app-bss-plain-text-box
            *ngIf="aufmass.Datum"
            [labelText]="'Datum'"
            [value]="aufmass.Datum?.substr(0, 10)"
          ></app-bss-plain-text-box>
        </div>

        <app-bss-toggle-textarea [buttonText]="'Bemerkung'" [(value)]="aufmass.Beschr" [disabled]="true">
        </app-bss-toggle-textarea>

        <app-bss-button-link
          *ngIf="fileUploadPossible"
          [title]="'Medien'"
          [path]="'medien/Aufmass/' + aufmass.Uuid"
          [anzahl]="currentFilesAmount"
        ></app-bss-button-link>

        <div *ngIf="raumbuch">
          <p
            id="rootNode"
            style="font-size: 16px"
            (click)="selectRoom(raumbuch)"
            [ngStyle]="{ 'background-color': selectedRaumbuchAsRoot ? 'rgba(0,0,0,.12)' : 'transparent' }"
          >
            <b>{{ raumbuch.Bezeich }}</b>
          </p>
          <dx-tree-view
            *ngIf="raumbuchAsTreeNodes"
            selectionMode="single"
            [focusStateEnabled]="false"
            [selectByClick]="true"
            [expandEvent]="'click'"
            [items]="raumbuchAsTreeNodes"
            (onItemClick)="selectRoom($event.itemData)"
          >
          </dx-tree-view>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="raumbuchAsTreeNodes && raumVorlageExists">
  <dx-speed-dial-action
    *ngIf="selectedRaumbuchAsRoot === false"
    icon="trash"
    class="add-button"
    label="Raum entfernen"
    [index]="4"
    (onClick)="deleteRaum(aufmass, selectedRoom, userInfo)"
  >
  </dx-speed-dial-action>
  <dx-speed-dial-action
    *ngIf="selectedRoom && selectedRoom.level !== 1"
    icon="add"
    class="add-button"
    label="Raum hinzufügen"
    [index]="3"
    (onClick)="addRaum()"
  ></dx-speed-dial-action>
  <dx-speed-dial-action
    *ngIf="selectedRaumbuchAsRoot === false && selectedRoom?.hasMasskette"
    icon="photo"
    class="add-button"
    label="Medien zum Raum"
    [index]="1"
    (onClick)="newImages(aufmass, selectedRoom)"
  >
  </dx-speed-dial-action>
  <dx-speed-dial-action
    *ngIf="selectedRaumbuchAsRoot === false && selectedRoom?.hasMasskette"
    icon="edit"
    class="add-button"
    label="Raumaufmaß bearbeiten"
    [index]="2"
    (onClick)="gotoRaumaufmass(selectedRoom)"
  >
  </dx-speed-dial-action>
  <dx-speed-dial-action
    *ngIf="selectedRaumbuchAsRoot === false && !selectedRoom?.hasMasskette"
    icon="add"
    class="add-button"
    label="Einzelaufmaß hinzufügen"
    [index]="1"
    (onClick)="gotoMasskette(aufmass, selectedRoom, null)"
  >
  </dx-speed-dial-action>
  <dx-speed-dial-action
    *ngIf="selectedRaumbuchAsRoot === false && !selectedRoom?.hasMasskette"
    icon="add"
    class="add-button"
    label="Grundriss-Skizze erstellen"
    [index]="0"
    (onClick)="drawGrund(aufmass, selectedRoom)"
  >
  </dx-speed-dial-action>
</div>

<div *ngIf="!raumVorlageExists">
  <dx-speed-dial-action icon="add" class="add-button" label="Raumvorlage wählen" (onClick)="selectRaumVorlage()">
  </dx-speed-dial-action>
</div>

<dx-popup
  *ngIf="possibleRaumnamen"
  [width]="'90%'"
  [maxWidth]="'500px'"
  [title]="'Raum hinzufügen'"
  [dragEnabled]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
>
  <app-raumvorlage
    *ngIf="showPopup"
    [parentRoom]="selectedRoom"
    [raumbuchPositionen]="raumbuchPositionen"
    [raumNamen]="possibleRaumnamen"
    (selectedRaumbuchposition)="addPositionToAufmass(aufmass, $event, userInfo)"
    [parentIsRaumbuch]="selectedRaumbuchAsRoot"
    [aufmass]="aufmass"
  >
  </app-raumvorlage>
</dx-popup>

<dx-popup
  *ngIf="finished"
  [width]="'90%'"
  [maxWidth]="'500px'"
  [height]="320"
  [title]="'Raumvorlage wählen'"
  [dragEnabled]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showRaumVorlagePopup"
>
  <app-choose-raumvorlage [aufmass]="aufmass"></app-choose-raumvorlage>
</dx-popup>
