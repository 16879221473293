import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { AufmassStrecke, Bauelement, Beschriftung, Punkt } from '../..';
import { Raumbuch } from '../../repository/Raumbuch';
import { RaumbPos } from './RaumbPos';

export class AufmassKonstrukt {
  pictureDataBase64: string = null;
  AufmassStrecken: AufmassStrecke[] = [];
  Beschriftungen: Beschriftung[] = [];
  Bauelemente: Bauelement[] = [];
  Raumhoehe: AufmassStrecke;
  Bodenflaeche: AufmassStrecke;
  Deckenflaeche: AufmassStrecke;
  usedGridFactor: number;

  constructor(
    pictureDataBase64: string,
    AufmassStrecken: AufmassStrecke[],
    Bauelemente: Bauelement[],
    Beschriftungen: Beschriftung[]
  ) {
    this.pictureDataBase64 = pictureDataBase64;
    this.AufmassStrecken = AufmassStrecken;
    this.Bauelemente = Bauelemente;
    this.Beschriftungen = Beschriftungen;
  }

  addRaumhoeheAndBodenAndDeckenflaeche(): void {
    this.Raumhoehe = this.newRaumBaseStructure('r', 'Raumhöhe');
    this.Bodenflaeche = this.newRaumBaseStructure('b', 'Bodenfläche');
    this.Deckenflaeche = this.newRaumBaseStructure('d', 'Deckenfläche');
  }

  private newRaumBaseStructure(
    bezeichner: string,
    bezeichnung: 'Raumhöhe' | 'Bodenfläche' | 'Deckenfläche'
  ): AufmassStrecke {
    const mockPunkt = new Punkt(1, 1);
    const structure = new AufmassStrecke(mockPunkt, mockPunkt, 1);
    structure.bezeichnung = bezeichner;
    structure.bezeichnungLang = bezeichnung;
    if (bezeichnung !== 'Raumhöhe') structure.laengeGemessen = 0;
    if (bezeichnung === 'Bodenfläche') structure.isBoden = true;
    if (bezeichnung === 'Deckenfläche') structure.isDecke = true;
    return structure;
  }

  tryConstructBodenAndDeckeFormula(allLaengen: number[]): void {
    let formula = '';
    const fourCorners = allLaengen.length === 4;
    if (!fourCorners) return;
    const firstWallLength = allLaengen[0];
    const secondWallLength = allLaengen[1];
    const thirdWallLength = allLaengen[2];
    const fourthWallLength = allLaengen[3];
    if (allLaengen.every(wallLength => wallLength === firstWallLength))
      // qudrat
      formula = `${firstWallLength}*${firstWallLength}`;
    if (firstWallLength === thirdWallLength && secondWallLength === fourthWallLength)
      // rechteck
      formula = `${firstWallLength}*${secondWallLength}`;
    this.Bodenflaeche.formel = formula;
    this.Deckenflaeche.formel = formula;
  }

  convertToRaumbuchentries(raum: RaumbPos): Raumbuch[] {
    const currentEntries = [];
    const strecken = this.AufmassStrecken;
    const bauelemente = this.Bauelemente;
    const raumHoeheStrecke = this.Raumhoehe;
    const streckenAsEntries = strecken.map(strecke =>
      strecke.toRaumbuchEntry(currentEntries, raum, raumHoeheStrecke.laengeGemessen)
    );
    const deckeAsEntry = this.Deckenflaeche.toRaumbuchEntry(currentEntries, raum, 1, this.Deckenflaeche.formel);
    const bodenAsEntry = this.Bodenflaeche.toRaumbuchEntry(currentEntries, raum, 1, this.Bodenflaeche.formel);
    const bauelementeAsEntries = bauelemente.map(bauelement =>
      bauelement.toRaumbuchEntry(currentEntries, raum, raumHoeheStrecke.laengeGemessen)
    );
    const result: Raumbuch[] = [];
    result.push(...streckenAsEntries);
    result.push(deckeAsEntry);
    result.push(bodenAsEntry);
    result.push(...bauelementeAsEntries);
    return result;
  }

  /**@description Prüft ob die Längen aller Wände vermessen wurden */
  checkAllWallsMeasured(): boolean {
    const allLaengen = this.AufmassStrecken?.flatMap(entry => entry.laengeGemessen);
    const allWallsMeasured = allLaengen.every(laenge => !isNullOrUndefined(laenge));
    return allWallsMeasured;
  }

  /**@description Iteriert über alle Pflichtfelder aller Bauelemente und guckt so, ob alle Pflichtfelder gefüllt sind */
  checkAllBauelementeMeasured(): boolean {
    const allBauelemente = this.Bauelemente;
    let allBauelementeMeasured = true;
    for (const bauelement of allBauelemente) {
      const vorlage = bauelement.vorlage;
      for (const pflichtFeld of vorlage.pflichtFelder)
        allBauelementeMeasured = allBauelementeMeasured && !isNullOrUndefined(bauelement[pflichtFeld]);
    }
    return allBauelementeMeasured;
  }

  isRectangle(): boolean {
    if (this.AufmassStrecken.length !== 4) return false;
    const wall1and3Parallel = this.AufmassStrecken[0].pointDistance === this.AufmassStrecken[2].pointDistance;
    const wall2and4Parallel = this.AufmassStrecken[1].pointDistance === this.AufmassStrecken[3].pointDistance;
    return wall1and3Parallel && wall2and4Parallel;
  }
}
