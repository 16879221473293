import { Component, OnInit, Input } from '@angular/core';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Anschrift, HWTermin, HWRepairOrder } from 'apps/handwerkPWA/src/app/entities';

@Component({
  selector: 'app-repair-order-card',
  templateUrl: './repair-order-card.component.html',
  styleUrls: ['./repair-order-card.component.scss'],
})
export class RepairOrderCardComponent implements OnInit {
  @Input() auftrag: HWRepairOrder;
  @Input() termin: HWTermin;
  @Input() allAuftraege: HWRepairOrder[];
  contactInformationArrayRepairOrder = ['TEL', 'FUNK_PRIV', 'GLOBEMAIL', 'ArbeitsortAnschrift', 'WWW'];
  showContactBar = false;

  constructor(private routingService: RoutingService) {}

  ngOnInit() {
    if (!isNullOrUndefined(this.termin)) {
      this.auftrag = this.setTerminAuftrag(this.termin, this.allAuftraege);
    }
    const arbeitsortAnschrift = new Anschrift(null, null, null);
    arbeitsortAnschrift.arbeitsortToAnschrift(this.auftrag.ArbeitsOrt);
    this.auftrag.Kunde.ArbeitsortAnschrift = arbeitsortAnschrift;
  }

  /**@description Wenn der Termin einen Zugehörigen Auftrag hat */
  setTerminAuftrag(termin: HWTermin, repairOrders: HWRepairOrder[]) {
    const terminRep = repairOrders.find(rep => rep.Nummer === termin.Referenz);
    const terminRepairOrder: HWRepairOrder = terminRep;
    return terminRepairOrder;
  }

  async goToRepairOrderDetail(): Promise<void> {
    await this.routingService.navigateTo('reparaturauftrag/' + this.auftrag.Guid + '/edit');
  }

  toggleContactBar(): void {
    this.showContactBar = !this.showContactBar;
  }
}
