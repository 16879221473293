import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Aufmass } from '../../../entities';
import { AufmassService } from '../../../services/dataServices/aufmass.service';

@Component({
  selector: 'app-aufmass-index',
  templateUrl: './aufmass-index.component.html',
  styleUrls: ['./aufmass-index.component.scss'],
})
export class AufmassIndexComponent implements OnInit {
  aufmasse: Aufmass[];

  constructor(private aufmassService: AufmassService, private routingService: RoutingService) {}

  async ngOnInit(): Promise<void> {
    this.aufmasse = await this.aufmassService.getAll();
  }

  createNewAufmass(): void {
    void this.routingService.navigateTo('/aufmass/neu');
  }
}
