export type Featurename = 'Basis' | 'Wartung und Service' | 'Aufmass' | 'Medientabelle1' | 'Medientabelle2';

export class Featurecheck {
  name: string;
  dbHighEnough: boolean;
  webserviceHighEnough: boolean;
  available: boolean;
  minWebserviceString: string;
  minDBString: string;
  betaUser: boolean;

  constructor(
    featureName: Featurename,
    dbHighEnough: boolean,
    webserviceHighEnough: boolean,
    minWebserviceString: string,
    minDBString: string,
    betaUser: boolean
  ) {
    this.name = featureName;
    this.betaUser = betaUser;
    this.dbHighEnough = dbHighEnough;
    this.webserviceHighEnough = webserviceHighEnough;
    this.available = dbHighEnough && (webserviceHighEnough || betaUser);
    this.minWebserviceString = minWebserviceString;
    this.minDBString = minDBString;
  }
}
