import { UUID } from 'angular2-uuid';

export class Textvorlage {
  Nummer: string;
  SuchText: string;
  Text: string;

  constructor(data: object) {
    this.Nummer = UUID.UUID();
    Object.assign(this, data);
  }
}
