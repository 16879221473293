<div class="content-block bottom-padding" *ngIf="aufmass">
  <div class="form-block">
    <h2>Raum: {{ raum.Bezeich }}</h2>

    <div *ngIf="raumbuchEntrys?.length == 0">
      Es wurden noch keine Messdaten erfasst. <br /><br />
      Wählen Sie unten rechts "Grundriss-Skizze erstellen", um eine neue Grundriss-Skizze zu erstellen <br /><br />
      oder <br /><br />
      wählen Sie "Grundriss-Skizze bearbeiten", um für eine bereits vorhandenen Grundriss-Skizze Messdaten einzugeben.
    </div>

    <div *ngIf="raumbuchEntrys?.length > 0">
      <div class="upperGrid">
        <b>Raumbucheinträge:</b>
        <app-bss-button-link
          style="width: 31%"
          *ngIf="raumbuchEntrys?.length !== 0"
          [title]="'Fertig'"
          [path]="aufmassPath"
        >
        </app-bss-button-link>
      </div>

      <dx-data-grid (onRowClick)="gotoMasskette(aufmass, raum, $event.data)" class="" [dataSource]="raumbuchEntrys">
        <dxo-editing [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false" mode="cell"> </dxo-editing>
        <dxi-column
          [width]="'28%'"
          [caption]="'Bezeichnung'"
          [dataField]="'getBezeich'"
          dataType="string"
          [allowEditing]="false"
        >
        </dxi-column>
        <dxi-column
          [width]="'28%'"
          [caption]="'Formel'"
          [dataField]="'Aufmass'"
          dataType="string"
          [allowEditing]="false"
        >
        </dxi-column>
        <dxi-column
          [width]="'20%'"
          [caption]="'Summe'"
          [dataField]="'Zresult'"
          dataType="number"
          [allowEditing]="false"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </dxi-column>
        <dxi-column
          [width]="'10%'"
          [caption]="'Abzug'"
          [dataField]="'IsAbzug'"
          dataType="boolean"
          [allowEditing]="false"
        >
        </dxi-column>
        <dxi-column
          [width]="'14%'"
          [caption]="'Nicht berechnen'"
          [dataField]="'NotCalc'"
          dataType="boolean"
          [allowEditing]="false"
        >
        </dxi-column>
      </dx-data-grid>

      <div class="sumBox" *ngIf="sums">
        <app-bss-numberbox
          [labelText]="'Brutto (Gesamt)'"
          [value]="sums.bruto"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        ></app-bss-numberbox>
        <app-bss-numberbox
          [labelText]="'Netto (Gesamt)'"
          [value]="sums.netto"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
        <app-bss-numberbox
          [labelText]="'Differenz (Gesamt)'"
          [value]="sums.difference"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
      </div>

      <div class="sumBox" *ngIf="sums">
        <app-bss-numberbox
          [labelText]="'Brutto (Wände)'"
          [value]="sums.brutoWalls"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
        <app-bss-numberbox
          [labelText]="'Netto (Wände)'"
          [value]="sums.nettoWalls"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
        <app-bss-numberbox
          [labelText]="'Differenz (Wände)'"
          [value]="sums.differenceWalls"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
      </div>
    </div>
  </div>
</div>

<dx-speed-dial-action
  *ngIf="!entrysExists"
  icon="add"
  class="add-button"
  [label]="'Grundriss-Skizze erstellen'"
  [index]="2"
  (onClick)="drawGrund(aufmass, raum)"
>
</dx-speed-dial-action>

<dx-speed-dial-action
  *ngIf="entrysExists"
  icon="redo"
  class="add-button"
  [label]="'Grundriss-Skizze neu erstellen'"
  [index]="2"
  (onClick)="drawGrund(aufmass, raum)"
>
</dx-speed-dial-action>

<dx-speed-dial-action
  *ngIf="entrysExists"
  icon="edit"
  class="add-button"
  label="Grundriss-Messung bearbeiten"
  [index]="1"
  (onClick)="editMessung(aufmass, raum)"
>
</dx-speed-dial-action>

<dx-speed-dial-action
  icon="add"
  class="add-button"
  label="Einzelaufmaß hinzufügen"
  [index]="0"
  (onClick)="gotoMasskette(aufmass, raum, null)"
>
</dx-speed-dial-action>
