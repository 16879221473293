import { Injectable } from '@angular/core';
import { HWContact } from 'apps/handwerkPWA/src/app/entities';
import { ControllerService } from '../globalServices/controller.service';
import { DialogService } from '@handwerk-pwa/shared';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { isNullOrUndefinedOrEmptyArray } from 'libs/shared/src/lib/helper/globalHelper';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private baseService: BaseService
  ) {}

  /**
   * @description Holt alle Adressen aus der IDB mit bestimmten Suchkriterien
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async getAllBy(selector: string, value: any): Promise<HWContact[]> {
    return await this.baseService.getAllBy(HWContact, selector, value);
  }

  /**@description Holt alle Kontakte die geholt werden können */
  async sync(userInfo: Userinfo): Promise<HWContact[]> {
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Kontakte...');
    const contactsData = await this.restService.returnData<HWContact[]>(
      userInfo,
      'allKontakte/mandant/' + userInfo.mandant + '/username/' + userInfo.monteur
    );
    if (isNullOrUndefinedOrEmptyArray(contactsData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const contacts: HWContact[] = this.baseService.rawDataArrayToObjectsArray(HWContact, contactsData);
    await this.writeContactsToIDB(contacts);
    this.dialogService.closeLoadingDialog();
    return contacts;
  }

  async getKontakteByKundennummerFromWebservice(userInfo: Userinfo, KuNr: string): Promise<HWContact[]> {
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole Kontakte...');
    const contactsData = await this.restService.returnData<HWContact[]>(
      userInfo,
      'kontakte/mandant/' + userInfo.mandant + '/KU_NR/' + KuNr + '/username/' + userInfo.monteur
    );
    if (isNullOrUndefinedOrEmptyArray(contactsData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const contacts: HWContact[] = this.baseService.rawDataArrayToObjectsArray(HWContact, contactsData);
    await this.updateContactInIdb(contacts);
    this.dialogService.closeLoadingDialog();
    return contacts;
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description Schreibt die Kontakte in die IDB */
  private async writeContactsToIDB(contacts: HWContact[]) {
    await this.controllerService.clearStore('HWContact');
    await this.controllerService.setData('HWContact', contacts);
  }

  private async updateContactInIdb(contacts: HWContact[]) {
    const kundenNummer = contacts[0].KU_NR;
    await this.controllerService.deleteData('HWContact', 'KU_NR', kundenNummer);
    await this.controllerService.setData('HWContact', contacts);
  }
}
