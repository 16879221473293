<div class="card termincard" (click)="goToTermindetail()">
  <p *ngIf="showDate">
    <b>{{ termin.indexDate }}</b>
  </p>
  <p *ngIf="termin.terminArt != 'Aufgabe'">
    <b>
      {{ termin.start.substring(11, 16) }} Uhr bis {{ termin.finish.substring(11, 16) }} Uhr {{ termin.terminArt }}
    </b>
  </p>
  <p *ngIf="termin.terminArt == 'Aufgabe'"><b>Aufgabe</b></p>

  <p>{{ termin.location }}</p>
  <p *ngIf="termin.kundenName">{{ termin.caption }} - {{ termin.kundenName }}</p>
  <p *ngIf="!termin.kundenName">{{ termin.caption }} - (Kein Kunde eingetragen)</p>
  <p *ngIf="termin.mitarbeiterName">{{ termin.mitarbeiterName }}</p>
</div>
