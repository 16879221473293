<dx-popup
  [width]="'100%'"
  [height]="'100%'"
  [dragEnabled]="false"
  [showCloseButton]="false"
  [showTitle]="false"
  container=".dx-viewport"
  [(visible)]="showPopup"
  (onHidden)="back()"
  (onShowing)="stylePopup($event)"
>
  <div *dxTemplate="let data of 'content'">
    <div class="signature-container">
      <signature-pad [options]="signaturePadOptions"> </signature-pad>

      <div class="actions-container">
        <button class="action-button" (click)="createUnterschrift(unterschriftPerson)">
          <img src="/assets/icons/ok.png" alt="" />
        </button>
        <app-bss-textbox
          [labelText]="'Unterschrift'"
          [(value)]="unterschriftPerson"
          [maxLength]="254"
        ></app-bss-textbox>
        <button class="action-button" (click)="back()">
          <img src="/assets/icons/cancelRed.png" alt="" />
        </button>
      </div>
    </div>
  </div>
</dx-popup>
