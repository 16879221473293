import { Datesortable } from 'apps/handwerkPWA/src/app/interfaces';

/**@description Sortier ein Array welches das Interface Datesortable implementiert hat */
export function sortDatesortableArray<Type>(datesortables: Datesortable[]): Type[] {
  datesortables = datesortables.sort(compareDates);
  datesortables = datesortables.reverse();
  return (datesortables as unknown) as Type[];
}

function compareDates(a: Datesortable, b: Datesortable) {
  const aDate = a.getSortDate();
  const bDate = b.getSortDate();
  if (aDate > bDate) {
    return 1;
  }
  if (aDate < bDate) {
    return -1;
  }
  return 0;
}
