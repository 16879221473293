import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bss-array-organizer',
  templateUrl: './bss-array-organizer.component.html',
  styleUrls: ['./bss-array-organizer.component.scss'],
})
export class BssArrayOrganizerComponent implements OnInit {
  @Input() label = '';
  @Input() selectedValues: any[] = [];
  @Input() possibleChoices: any[] = [];
  /** Die displayProperty wird mit einem propertyAccessor verwendet, um eine property der Items anzuzeigen */
  @Input() displayProperty: string;
  @Output() newArray = new EventEmitter<any[]>();
  @Input() valueFieldLineHeight = '34px';
  @Input() labelText: string;
  @Input() placeholder: string;
  @Output() closingWithEditedContent = new EventEmitter<boolean>(false);
  edited = false;
  arrayContentAsString: string;
  showPopup = false;
  imageSource = 'assets/icons/ic_deleteGrey.png';

  ngOnInit(): void {
    if (!this.selectedValues) throw new Error('ArrayContent muss angegeben werden');
    if (!this.possibleChoices) throw new Error('possiblechoices müssen angegeben werden');
    if (!this.displayProperty) throw new Error('Displayproperty muss angegeben werden');
    this.updateDisplayArray();
  }

  private updateDisplayArray(): void {
    const displayPropertyArray = this.selectedValues?.map(entry => (entry ? entry[this.displayProperty] : ''));
    this.arrayContentAsString = displayPropertyArray?.join(', ');
  }

  removeEntry(inputItem: unknown): void {
    this.edited = true;
    const item = inputItem['itemData'];
    this.possibleChoices.push(item);
    this.selectedValues = this.selectedValues.filter(filterItem => filterItem !== item);
    this.updateDisplayArray();
  }

  addEntry(inputItem: unknown): void {
    if (inputItem) {
      this.edited = true;
      this.selectedValues.push(inputItem);
      this.possibleChoices = this.possibleChoices.filter(item => item !== inputItem);
      this.updateDisplayArray();
    }
  }

  close(): void {
    if (this.edited) {
      this.edited = false;
      this.newArray.emit(this.selectedValues);
      this.closingWithEditedContent.emit(true);
    }
  }
}
