import { Component, OnInit } from '@angular/core';
import { NachrichtenService } from 'apps/handwerkPWA/src/app/services/dataServices/nachrichten.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { SortBySender } from '../../../helper/services/messageHelper';

@Component({
  selector: 'app-messages-index',
  templateUrl: './messages-index.component.html',
  styleUrls: ['./messages-index.component.scss'],
})
export class MessagesIndexComponent implements OnInit {
  searchExpressions = [];
  userInfo: Userinfo;

  messages = [
    {
      messagetype: 'Eingehende Nachrichten',
      icon: 'arrowdown',
      items: [],
      list_id: 'nachrichten',
    },
    {
      messagetype: 'Ausgehende Nachrichten',
      icon: 'arrowup',
      items: [],
      list_id: 'nachrichten',
    },
    {
      messagetype: 'Ausgehende Mails',
      icon: 'email',
      items: [],
      list_id: 'nachrichtenEmail',
    },
  ];

  constructor(
    private nachrichtenService: NachrichtenService,
    private globalSettingService: HWGolbalsettingService,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.globalSettingService.getUserinfo();
    await this.nachrichtenService.getAllNachrichtenFromWebservice(this.userInfo, false);
    const messages = await this.nachrichtenService.getAll();
    this.messages[2].items = await this.nachrichtenService.getAllEmailsSend();
    const sortedNachrichten = SortBySender(messages);
    this.messages[0].items = sortedNachrichten.sendFromHW;
    this.messages[1].items = sortedNachrichten.sendFromPWA;
  }

  async newMessage(): Promise<void> {
    await this.routingService.navigateTo('nachricht/neu');
  }
}
