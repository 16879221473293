import { Injectable } from '@angular/core';
import { HWFileArrayGetUniques, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { ControllerService } from 'apps/handwerkPWA/src/app/services/globalServices/controller.service';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { HWFile, HWObjectaddress } from 'apps/handwerkPWA/src/app/entities';
import { DialogService } from '@handwerk-pwa/shared';
import { AddressService } from './address.service';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { Documentservice } from '../globalServices/document.service';
import { BaseService } from './base.service';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';

@Injectable({
  providedIn: 'root',
})
export class ObjectaddressService {
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private adressService: AddressService,
    private documentService: Documentservice,
    private baseService: BaseService,
    private connectionService: ConnectionService
  ) {}

  /**
   * @description Holt eine Adressen aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden sol
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async findOneBy(selector: string, value: any): Promise<HWObjectaddress> {
    return await this.baseService.findOneBy(HWObjectaddress, selector, value);
  }

  async getAllByTwoSelector(selector: string, value: any, selector2: string, value2: any): Promise<HWObjectaddress[]> {
    return await this.baseService.getAllByTwoSelector(HWObjectaddress, selector, value, selector2, value2);
  }

  async getAllBy(selector: string, value: any): Promise<HWObjectaddress[]> {
    return await this.baseService.getAllBy(HWObjectaddress, selector, value);
  }

  async getAll(): Promise<HWObjectaddress[]> {
    return await this.baseService.getAll(HWObjectaddress);
  }

  /**@description Holt eine Objektadresse anhand der angaben zu Kundenummer und LFdnr */
  async getSpecificObjectaddressFromIDB(kundenNummer: string, lfdnr: string): Promise<HWObjectaddress> {
    return await this.baseService.findOneByTwoSelector(HWObjectaddress, 'KuNr', kundenNummer, 'LfdNr', lfdnr);
  }

  /**@description Nimmt die Adressen aus der IDB oder vom Webservice, je nach Konditionen */
  async getObjektadressesForKundennummerByChoice(
    userInfo: Userinfo,
    kundenNummer: string,
    silent = false
  ): Promise<HWObjectaddress[]> {
    const currentObjectAdresses = await this.getAllBy('KuNr', kundenNummer);
    const isOnline = await this.connectionService.CheckOnline(ConnectionDialogues.GetData);
    if (!isOnline) return currentObjectAdresses;

    const liveObjectAdresses = await this.getObjektadressesForKundennummerFromWebservice(
      userInfo,
      kundenNummer,
      silent
    );
    return liveObjectAdresses;
  }

  /**@description Überschreibt die jeweilige Adresse lokal in der IDB */
  async updateDocumentsInAdressLocally(documents: HWFile[]): Promise<void> {
    for (const document of documents) {
      const kundenNummer = document.Kundennummer;
      const lfdNr = document.LfdNr;
      const objectAdress = await this.getSpecificObjectaddressFromIDB(kundenNummer, lfdNr);
      if (!objectAdress) continue;
      const newFiles = documents.filter(doc => doc.LfdNr === lfdNr && doc.Kundennummer === kundenNummer);
      objectAdress.Files = newFiles;
      await this.deleteAddressInIDB(objectAdress);
      await this.writeObjectadressesToIDB([objectAdress], false);
    }
  }

  /**@description Überschreibt das Dokument innerhalb einer Adresse in der IDB */
  async updateDocumentInAdress(bssFile: HWFile): Promise<void> {
    const kundenNummer = bssFile.Kundennummer;
    const lfdNr = bssFile.LfdNr;
    const objectAdress = await this.getSpecificObjectaddressFromIDB(kundenNummer, lfdNr);
    if (!objectAdress) return;
    let index = 0;
    for (const tempFile of objectAdress.Files) {
      if (tempFile.Name === bssFile.Name) {
        objectAdress.Files[index] = bssFile;
        break;
      }
      index++;
    }
    await this.writeObjectadressesToIDB([objectAdress], false);
  }

  /**@description Holt alle Objectadressen */
  async getAllObjectaddressesFromWebservice(userInfo: Userinfo, showMessage = true): Promise<HWObjectaddress[]> {
    // Erst Ungesyncte Objectadressen / Medien pushen, dann neu holen
    if (showMessage) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Objektadressen...');

    const allObjectAdresses = await this.getAll();
    await this.pushUnpushedObjektadressen(userInfo);
    const unpushedDocuments = this.documentService.getUnpushedFilesOld([], allObjectAdresses);
    await this.documentService.sendUnpushedImagesToWebservice(userInfo, unpushedDocuments);

    const allObjectaddresses: HWObjectaddress[] = [];
    const targetUrl = `ObjektAdr/mandant/${userInfo.mandant}/username/${userInfo.monteur}/GEAENDERT/${userInfo.geaendert}`;
    const allObjectaddressesData = await this.restService.returnData<HWObjectaddress[]>(
      userInfo,
      targetUrl,
      null,
      !showMessage
    );
    if (isNullOrUndefined(allObjectaddressesData)) {
      this.dialogService.closeLoadingDialog();
      return allObjectaddresses;
    }
    for (const objectaddressData of allObjectaddressesData) {
      const objectaddress = new HWObjectaddress(objectaddressData);
      const kunde = await this.adressService.findOneBy('KU_NR', objectaddress.KuNr);
      objectaddress.addKunde(kunde);
      objectaddress.Files = HWFileArrayGetUniques(objectaddress.Files);
      allObjectaddresses.push(objectaddress);
    }
    await this.writeObjectadressesToIDB(allObjectaddresses, true);
    this.dialogService.closeLoadingDialog();
    return allObjectaddresses;
  }

  /**@description Sendet die Objektadresse an den Webservice und somit ans Backend (Lfdnr ist länger als 5 zeichen => dann erstellt er eine neue, sonst update)
   * Möchte man also eine neue Objektadresse anlegen, setzt man Lfdnr bspw. auf xxxxxx - das Backend gibt eine neue Lfdnr
   */
  async sendAddressToWebservice(
    userInfo: Userinfo,
    objectaddressInput: HWObjectaddress,
    silent = false
  ): Promise<void> {
    const targetUrl = 'SetObjAdresse';
    const response = await this.restService.returnData<HWObjectaddress>(
      userInfo,
      targetUrl,
      objectaddressInput,
      silent
    );
    if (!response) {
      await this.writeObjectadressesToIDB([objectaddressInput], false);
      return;
    }
    const objectAddress = new HWObjectaddress(response);
    objectAddress.Kunde = objectaddressInput.Kunde;
    if (objectaddressInput.Files) objectAddress.Files = objectaddressInput.Files;
    await this.replaceAddressInIDB(objectAddress);
  }

  async resetImagesInIDB(): Promise<void> {
    const allObjectAddreses = await this.getAll();
    const addressesWithFiles = allObjectAddreses.filter(object => object.Files.length > 0);
    const addressesWithoutFiles = allObjectAddreses.filter(object => object.Files.length === 0);
    for (const fileAddress of addressesWithFiles) {
      for (const file of fileAddress.Files) file.Data = null;
    }
    await this.writeObjectadressesToIDB(addressesWithFiles, true);
    await this.writeObjectadressesToIDB(addressesWithoutFiles, false);
  }

  /**@description Baut aus den Daten von neu anzulegenden Addressen Adressen und verschickt diese  */
  async pushUnpushedObjektadressen(userInfo: Userinfo): Promise<void> {
    const allObjectAdresses = await this.getAll();
    const unpushedElements = allObjectAdresses.filter(object => object.KuNr.length > 10); // lfdnr größer 10? dann uuid und ungepusht
    if (unpushedElements.length === 0) return;
    for (const unpushedElement of unpushedElements) {
      await this.sendAddressToWebservice(userInfo, unpushedElement, true);
    }
  }

  /*******************************************************************************
   *
   * PRIVATE FUNCTIONS
   *
   ******************************************************************************** */

  private async getObjektadressesForKundennummerFromWebservice(
    userInfo: Userinfo,
    kundenNummer: string,
    silent = false
  ): Promise<HWObjectaddress[]> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Objektadressen...');
    const objektAdressen: HWObjectaddress[] = [];
    const targetEndpoint = `ObjektadresseForKundennummer/mandant/${userInfo.mandant}/kundennummer/${kundenNummer}`;
    const objectAdressData = await this.restService.returnData<HWObjectaddress[]>(userInfo, targetEndpoint);
    if (isNullOrUndefined(objectAdressData)) {
      await this.replaceAllObjectadressesOfAdressInIDB([], kundenNummer);
      this.dialogService.closeLoadingDialog();
      return objektAdressen;
    }
    const kundeZuObjekt = await this.adressService.findOneBy('KU_NR', kundenNummer);
    for (const data of objectAdressData) {
      const objektAdresse = new HWObjectaddress(data);
      objektAdresse.addKunde(kundeZuObjekt);
      objektAdresse.Files = HWFileArrayGetUniques(objektAdresse.Files);
      objektAdressen.push(objektAdresse);
    }
    await this.replaceAllObjectadressesOfAdressInIDB(objektAdressen, kundenNummer);
    if (!silent) this.dialogService.closeLoadingDialog();
    return objektAdressen;
  }

  private async deleteAddressInIDB(objectAdress: HWObjectaddress) {
    await this.controllerService.deleteData('HWObjectaddress', 'Guid', objectAdress.Guid);
  }

  /**@description Schreibt die Objectaddressen in die IDB */
  private async writeObjectadressesToIDB(objectaddresses: HWObjectaddress[], clear: boolean) {
    if (clear) {
      await this.controllerService.clearStore('HWObjectaddress');
    }
    await this.controllerService.setData('HWObjectaddress', objectaddresses);
  }

  /**@description Löscht zu einer Kundennummer alle Objektadressen und ersetzt Sie mit dem aktuellen Satz */
  private async replaceAllObjectadressesOfAdressInIDB(objectaddresses: HWObjectaddress[], kundennummer: string) {
    await this.controllerService.deleteData('HWObjectaddress', 'KuNr', kundennummer);
    await this.writeObjectadressesToIDB(objectaddresses, false);
  }

  /**@description Überschreibt eine Objektadresse in der IDB */
  private async replaceAddressInIDB(objectaddress: HWObjectaddress) {
    const exists = await this.controllerService.getData<HWObjectaddress>('HWObjectaddress', objectaddress.Guid, 'Guid');
    if (exists) {
      await this.controllerService.deleteData('HWObjectaddress', 'Guid', objectaddress.Guid);
    }
    await this.writeObjectadressesToIDB([objectaddress], false);
  }
}
