import { IndexedDBTypes } from './dbType';
import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { Mengeneinheit } from 'apps/handwerkPWA/src/app/entities/models/Mengeneinheit';
import { Steuer } from 'apps/handwerkPWA/src/app/entities/models/Steuer';
import { Zusatzbezeichnungen } from '../models/Zusatzbezeichnungen';
import { Textvorlage } from 'libs/shared/src/lib/entities';
import { AufmassFormel } from '../models/aufmass/AufmassFormel';

export class HWMonteur extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWMonteur') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.DataField('number') StS: number = null;
  @IndexedDBTypes.DataField('string') StSchl: string = null;
  @IndexedDBTypes.DataField('boolean') SumCalcProp: boolean = null;
  @IndexedDBTypes.DataField('string') AdrNummer: string = null;
  @IndexedDBTypes.DataField('string') Nummer: string = null;
  @IndexedDBTypes.DataField('string') Name: string = null;
  @IndexedDBTypes.DataField('string') Telefon: string = null;
  @IndexedDBTypes.DataField('string') Fax: string = null;
  @IndexedDBTypes.DataField('string') Email: string = null;
  @IndexedDBTypes.DataField('string') FirmenAnschrift: string = null;
  @IndexedDBTypes.DataField('string') Rechte: string = null;
  @IndexedDBTypes.DataField('string') Web: string = null;
  @IndexedDBTypes.DataField('Steuer[]') Steuern: Steuer[] = [];
  @IndexedDBTypes.DataField('Mengeneinheit') Mengeneinheiten: Mengeneinheit[] = [];
  ZusatzBezeichnung: Zusatzbezeichnungen = null;
  TextVorlagen: Textvorlage[] = [];
  // Formeln: AufmassFormel[] = [];

  //Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    assignIfPropertyExsits(this, data);
    this.Steuern = this.Steuern.map(steuerData => new Steuer(steuerData));
  }

  static toString(): string {
    return 'HWMonteur';
  }
}
