import { IndexedDBTypes } from './dbType';
import { HWFile } from './HWFile';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { UUID } from 'angular2-uuid';
import { Anschrift } from 'apps/handwerkPWA/src/app/entities/models/Anschrift';
import { HWAddress } from './HWAddress';
import { BaseAdress, UuidEntity } from '../../interfaces';

export class HWObjectaddress extends IndexedDBTypes.DbType implements BaseAdress, UuidEntity {
  @IndexedDBTypes.KlassenName('HWObjectaddress') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') LfdNr: string;
  @IndexedDBTypes.IndexField('string') KuNr: string;
  @IndexedDBTypes.IndexField('string') Guid: string;
  @IndexedDBTypes.DataField('string') STANDORT1: string;
  @IndexedDBTypes.DataField('string') STANDORT2: string;
  @IndexedDBTypes.DataField('string') Name: string;
  @IndexedDBTypes.DataField('string') LAND: string;
  @IndexedDBTypes.DataField('string') PLZ: string;
  @IndexedDBTypes.DataField('string') STRASSE: string;
  @IndexedDBTypes.DataField('string') ORT: string;
  @IndexedDBTypes.DataField('string') Telefon: string;
  @IndexedDBTypes.DataField('string') BEMERKUNG: string;
  @IndexedDBTypes.DataField('string') Zusatz: string[];
  @IndexedDBTypes.DataField('number') Anfahrt: number;
  @IndexedDBTypes.DataField('number') KM: number;
  @IndexedDBTypes.DataField('string') Such: string;
  @IndexedDBTypes.DataField('Files') Files: HWFile[] = [];
  @IndexedDBTypes.DataField('Data') addedToIdb: Date;
  @IndexedDBTypes.DataField('string') Anschrift: Anschrift;
  @IndexedDBTypes.DataField('HWAdress') Kunde: HWAddress;
  ADRTYP: string = 'O';

  //Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object, kundenNummer?: string) {
    super();
    Object.assign(this, data);
    this.addTimeStampIfNotExisting();

    const uuid = UUID.UUID();
    if (isNullOrUndefined(this.LfdNr)) {
      this.setDefault();
      this.LfdNr = uuid;
    }

    this.Anschrift = new Anschrift(this.STRASSE, this.PLZ, this.ORT);

    if (!isNullOrUndefined(kundenNummer)) {
      this.KuNr = kundenNummer;
    }
    if (isNullOrUndefined(this.Guid)) {
      this.Guid = uuid;
    }
    if (isNullOrUndefined(this.Files)) {
      this.Files = [];
    }
  }

  getUuid(): string {
    return this.Guid;
  }

  getKundennummer(): string {
    return this.KuNr;
  }

  getLfdNr(): string {
    return this.LfdNr;
  }

  addKunde(kunde: HWAddress) {
    this.Kunde = kunde;
  }

  /**@description Wenn noch keiner vorhanden ist, wird der timestamp hinzugefügt */
  addTimeStampIfNotExisting() {
    if (!isNullOrUndefined(this.addedToIdb)) {
      return;
    }
    const now = new Date();
    this.addedToIdb = now;
  }

  /**@description Setzt alle Werte leer ( scheint wichtig, lässt man das weg, erkennt der Webservice das Objekt nicht mehr als Objektadresse und legt es nicht an) */
  setDefault() {
    this.LfdNr = '';
    this.KuNr = '';
    this.STANDORT1 = '';
    this.STANDORT2 = '';
    this.Name = '';
    this.LAND = 'D';
    this.PLZ = '';
    this.STRASSE = '';
    this.ORT = '';
    this.Telefon = '';
    this.BEMERKUNG = '';
    this.Zusatz = ['', '', '', '', ''];
    this.Anfahrt = 0;
    this.KM = 0;
  }

  static toString(): string {
    return 'HWObjectaddress';
  }
}
