<app-bss-textbox
  (click)="showPopup = true"
  [labelText]="label"
  [(value)]="arrayContentAsString"
  [readOnly]="true"
></app-bss-textbox>

<dx-popup
  [width]="'100%'"
  [maxWidth]="'500px'"
  [title]="label"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
  (onHiding)="close()"
>
  <app-bss-selectbox
    [labelText]="labelText"
    [placeholder]="placeholder"
    [searchMode]="'contains'"
    [displayExpr]="displayProperty"
    [items]="possibleChoices"
    (selectedValue)="addEntry($event)"
  ></app-bss-selectbox>

  <br />
  <dx-scroll-view [height]="'90%'">
    <dx-list
      [dataSource]="selectedValues"
      [displayExpr]="displayProperty"
      [allowItemDeleting]="true"
      [itemDeleteMode]="'static'"
      (onItemDeleted)="removeEntry($event)"
      [scrollingEnabled]="true"
    >
    </dx-list>
  </dx-scroll-view>
</dx-popup>
