<div class="card flexContainer" *ngIf="address">
  <div class="flexContainerItem" style="display: flex">
    <div class="flexContainerItem" (click)="goToAddressDetail(address, noClickEvents)">
      <b>{{ getSearchExpression() }}</b>
      <br />
      <b>{{ getAddressName() }}</b
      ><br />
      <p>{{ address.STRASSE }}</p>
      <p>{{ address.PLZ }} {{ address.ORT }}</p>
    </div>
    <div *ngIf="!noClickEvents" (click)="toggleContactBar()" style="padding: 0 15px">
      <i class="dx-icon dx-icon-chevrondown"></i>
    </div>
  </div>
  <div *ngIf="withContactBar && showContactBar" class="flexContainerItem">
    <app-bss-contact-bar [contactInformationArray]="contactInformationArray" [dataObject]="address">
    </app-bss-contact-bar>
  </div>
</div>
