/**@description Gibt true zurück, wenn der userAgent auf Android schließen lässt, ansonsten false */
export function isAndroid(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  const isDeviceAndroid = userAgent.indexOf('android') > -1;
  return isDeviceAndroid;
}

/**@description Gibt true zurück, wenn der userAgent auf iOs schließen lässt, ansonsten false */
export function isUserAgentIOS(): boolean {
  const userAgent = navigator.userAgent;
  if (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('Macintosh')) {
    return true;
  }
  return false;
}

export function isIosStandalone(): boolean {
  const ios = isUserAgentIOS();
  const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

  return ios && isInStandaloneMode;
}

/**@description Gibt true zurück wenn das Gerät des Users ein iPad oder Mac ist */
export function isIpad(): boolean {
  const userAgent = navigator.userAgent;
  if (userAgent.includes('iPad')) {
    return true;
  }
  if (userAgent.indexOf('Macintosh') > -1) {
    return true;
  }

  return false;
}

/**@description Hierbei ist Mobile gleich der Summe aus Android und Ios */
export function isMobile(): boolean {
  return isAndroid() || isUserAgentIOS();
}

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  b64Data = b64Data.replace('data:application/pdf;base64,', '');
  b64Data = b64Data.replace('==', '');
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
