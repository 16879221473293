import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { delay } from 'q';

@Component({
  selector: 'app-bss-toggle-textarea',
  templateUrl: './bss-toggle-textarea.component.html',
  styleUrls: ['./bss-toggle-textarea.component.scss'],
})
export class BssToggleTextareaComponent implements OnInit {
  @Input() buttonText: string;
  @Input() labelText: string;
  @Input() value: string;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Output() valueChange = new EventEmitter<string>();
  showTextArea = false;

  constructor() {}

  ngOnInit(): void {}

  async toggleTextArea(): Promise<void> {
    this.showTextArea = !this.showTextArea;
    await delay(50);
  }

  valueChanged(value: string): void {
    this.valueChange.emit(value);
  }
}
