import { rightId } from 'libs/shared/src/lib/entities';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

export function convertRTFSequenceToGermanSigns(plaintextWithSequences: string): string {
  let returnString = plaintextWithSequences.split("\\'F6").join('ö');
  returnString = returnString.split("\\'DF").join('ß');
  returnString = returnString.split("\\'FC").join('ü');
  returnString = returnString.split("\\'E4").join('ä');
  returnString = returnString.split("\\'D6").join('Ä');
  returnString = returnString.split("\\'C4").join('Ä');
  returnString = returnString.split("\\'DC").join('Ü');
  return returnString;
}

/**@description Ersetzt Handwerksmergefelder ( mergefelder sehen bspw. so aus: \*MERGEFIELD PH142\Ansprechpartner (Anlage)\ ) durch Formatierten text innerhalb von
 * kursivem html
 */
export function convertFormattedHandwerkTextToHtml(textInput: string): string {
  if (isNullOrUndefined(textInput) || textInput === '') {
    return '';
  }
  const patternStart = '\\*MERGEFIELD PH';
  const mergefieldExpression = new RegExp(patternStart + '[0-9]*');
  const firstReplace = textInput.split(mergefieldExpression).join('#~');
  const secondReplace = '\\#~\\';
  const splitAtBeginOfMergefield = firstReplace.split(secondReplace);
  const replacedStrings = [];
  for (const splitElementString of splitAtBeginOfMergefield) {
    const splitElementStringReplace = splitElementString.replace('\\', '}</b></i>');
    replacedStrings.push(splitElementStringReplace);
  }
  const htmlString = replacedStrings.join('<i><b>{');
  const htmlStringWithLinebrakes = htmlString.replace(/(?:\r\n|\r|\n)/g, '<br>');
  return htmlStringWithLinebrakes;
}

export function convertToPlain(rtf: string): string {
  rtf = rtf.replace(/\\par[d]?/g, '');
  const plainTextNonGerman = rtf.replace(/\{\*?\\[^{}]+;}|[{}]|\\[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, '').trim();
  const plainTextGerman = convertRTFSequenceToGermanSigns(plainTextNonGerman);
  const htmlText = convertFormattedHandwerkTextToHtml(plainTextGerman);
  return htmlText;
}

/**@description Konvertiert die Route zur zugehörigen RightId */
export function convertToRightId(route: string): rightId {
  route = route.toLocaleLowerCase();
  route = route.startsWith('/') ? route.substring(1) : route;
  if (route.startsWith('beleg')) return 'showBelege';
  if (
    route.startsWith('nachricht') ||
    route.startsWith('reparaturauftra') ||
    route.startsWith('nachricht') ||
    route.startsWith('auftrag')
  )
    return 'showAuftrag';
  if (route.startsWith('wartungsauftra')) return 'handleOrder';
  return null;
}
