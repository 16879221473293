import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

export class Anschrift {
  Strasse: string;
  Postleitzahl: string;
  Ort: string;
  /**Fallback falls die Anschrift nicht im korrekten Format vom handwerk übertragen wird */
  FallbackSearchstring: string;
  FallbackDisplayString: string;

  constructor(strasse: string, postleizahl: string, ort: string) {
    this.Strasse = strasse;
    this.Postleitzahl = postleizahl;
    this.Ort = ort;
  }

  public arbeitsortToAnschrift(arbeitsort: string) {
    if (isNullOrUndefined(arbeitsort)) {
      this.FallbackSearchstring = '';
      return;
    }
    const arbeitsortSplit = arbeitsort.split(',');
    if (arbeitsortSplit.length !== 2) {
      return this.getArbeitsortFallback(arbeitsort);
    }
    const plzOrt = arbeitsortSplit[1].trim();
    const plzOrtSplit = plzOrt.split(' ');
    if (plzOrtSplit.length < 2) {
      return this.getArbeitsortFallback(arbeitsort);
    }
    this.Strasse = arbeitsortSplit[0];
    this.Postleitzahl = plzOrtSplit[0];
    this.Ort = plzOrtSplit[1];
  }

  private getArbeitsortFallback(arbeitsort: string) {
    this.FallbackDisplayString = arbeitsort;
    const fallbackSplit = arbeitsort.split(' ');
    let fallBackSearchString = '';
    for (const word of fallbackSplit) {
      fallBackSearchString = fallBackSearchString + '+' + word;
    }
    const length = fallBackSearchString.length;
    this.FallbackSearchstring = fallBackSearchString.substr(1, length - 1);
    return;
  }
}
