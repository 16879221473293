import { IndexedDBTypes } from './dbType';
import { assignString } from 'libs/shared/src/lib/helper/globalHelper';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import { EmailconnectionInfo, Setting } from 'libs/shared/src/lib/entities';
import { BaseAuftrag } from 'apps/handwerkPWA/src/app/interfaces';
import { HWRepairOrder } from './HWRepairOrder';

export class HWEmailData extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWEmailData') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.DataField('string') smtpUser: string;
  @IndexedDBTypes.DataField('string') smtpPassword: string;
  @IndexedDBTypes.DataField('string') smtpServer: string;
  @IndexedDBTypes.DataField('number') smtpPort: number;
  @IndexedDBTypes.DataField('string') topic: string;
  @IndexedDBTypes.DataField('string') content: string;
  @IndexedDBTypes.DataField('string') base64Pdf: string;
  @IndexedDBTypes.DataField('string') recipient: string;
  @IndexedDBTypes.DataField('Date') sendDate = new Date();
  @IndexedDBTypes.DataField('string') readableDate: string;
  constructor(emailConnectionInfo: EmailconnectionInfo) {
    super();
    this.smtpUser = emailConnectionInfo.smtpUser;
    this.smtpPassword = emailConnectionInfo.smtpPassword;
    this.smtpServer = emailConnectionInfo.smtpServer;
    this.smtpPort = emailConnectionInfo.smtpPort;
    this.readableDate = dateToDatabaseDate(this.sendDate);
  }

  /**@description Weißt Titel,Inhalt,Adressaten und eventuell eine pdf zu */
  assignMailData(order: BaseAuftrag, settings: Setting, mailAdress: string, pdf?: string): void {
    const emailTextvorlage = settings.emailTextvorlage;
    const topic = order instanceof HWRepairOrder ? 'Reparaturauftrag' : 'Serviceauftrag';
    const content = assignString(emailTextvorlage?.Text, `Anbei ist Ihr ${topic}.`);
    this.topic = topic;
    this.content = content;
    this.recipient = mailAdress;
    this.base64Pdf = pdf;
  }

  assignTestmailData(settings: Setting, mailAdress: string): void {
    const emailTextvorlage = settings.emailTextvorlage;
    this.topic = 'Test-Email my blue:app hand:werk';
    const emailVortext = emailTextvorlage?.Text
      ? `Ihr Vortext: ${emailTextvorlage?.Text} `
      : '(Sie verwenden keinen E-Mail Vortext) ';
    this.content = emailVortext + 'Wenn Sie diesen Text lesen, können Sie erfolgreich Mails versenden.';
    this.recipient = mailAdress;
    this.base64Pdf = '';
  }

  static toString(): string {
    return 'HWEmailData';
  }
}
