import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppointmentService } from '../../../services/dataServices/appointment.service';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { HWAddress, HWTermin } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from '../../../services/dataServices/address.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import { DialogService } from '@handwerk-pwa/shared';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { getOverDaysBaseTermin } from '../../../helper/services/terminHelper';
import { RepairOrderService } from '../../../services/dataServices/repairOrder.service';
import { ServiceauftraegeService } from '../../../services/dataServices/serviceauftraege.service';
import { LogErrorToWebserviceService } from '../../../services/globalServices/logErrorToWebservice.service';
import { PWAErrorMessageAndStackTrace } from '../../../entities/models/PWAErrorMessageAndStackTrace';

@Component({
  selector: 'app-appointments-edit',
  templateUrl: './appointments-edit.component.html',
  styleUrls: ['./appointments-edit.component.scss'],
})
export class AppointmentEditComponent implements OnInit, OnDestroy {
  searchTimeoutOption = 200;
  termin: HWTermin;
  appointmentPerson: HWAddress[];
  selectedPerson: HWAddress;
  allowEdit: boolean;
  pickerType = 'rollers';
  contactInformationArray = ['TEL', 'FUNK_PRIV', 'GLOBEMAIL', 'Anschrift', 'WWW'];
  subscription: Subscription;
  blocked = false;
  navigationButtonTitle: string;
  navigationButtonLink: string;
  hasReference: boolean;

  constructor(
    private appointmentService: AppointmentService,
    private globalSettingService: HWGolbalsettingService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private rechteService: RechteService,
    private routingService: RoutingService,
    private repairOrderService: RepairOrderService,
    private serviceOrderService: ServiceauftraegeService,
    private logPwaErrorToWebservice: LogErrorToWebserviceService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadTermin();

    this.subscription = this.routingService.save.subscribe(() => {
      void this.save();
    });

    this.allowEdit = this.rechteService.hasRightOrSettingEnabled('allowEdit');
    const terminAnlage = this.rechteService.hasRightOrSettingEnabled('allowCreation');
    if (!terminAnlage && isNullOrUndefined(this.termin.id)) {
      this.dialogService.openInformDialog(
        'Fehler',
        'Sie verfügen nicht über das nötige Recht um neue Termine anzulegen.',
        'Ok'
      );
      this.routingService.routeBack();
    }
    await this.setPreferredPerson();

    if (this.termin.terminArt === 'Auftrag') {
      this.hasReference = true;
      const repairOrder = await this.repairOrderService.getOrderByAuftragsnummer(this.termin.Referenz);
      if (repairOrder) {
        this.navigationButtonTitle = 'Zum Auftrag';
        this.navigationButtonLink = '/reparaturauftrag/' + repairOrder.Guid + '/edit';
      }
    }
    if (this.termin.terminArt === 'Wartungstermin') {
      this.hasReference = true;
      const serviceOrder = await this.serviceOrderService.findOneBy('Dokumentid', this.termin.DokIdName.substring(1));
      if (serviceOrder) {
        this.navigationButtonTitle = 'zum Wartungstermin';
        this.navigationButtonLink = '/wartungsauftrag/' + serviceOrder.getUuid() + '/edit';
      }
    }
  }

  async navigateToReference(): Promise<void> {
    await this.routingService.navigateTo(this.navigationButtonLink);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**@description Wenn einer der termine geändert wird. */
  dateChanged(date: Date, startDate: boolean): void {
    const dbTermin = dateToDatabaseDate(date, true);

    if (startDate === true) {
      this.termin.start = dbTermin;
      this.termin.startDate = date;
      const endDate = new Date(date);
      const newEndDateAsNumber = endDate.setHours(date.getHours() + 1);
      const newEndDate = new Date(newEndDateAsNumber);
      const dbEndDate = dateToDatabaseDate(newEndDate, true);
      this.termin.finish = dbEndDate;
      this.termin.endDate = newEndDate;
      return;
    }
    if (startDate === false) {
      this.termin.endDate = date;
      this.termin.finish = dbTermin;
    }
  }

  /**@description Versucht Kunden oder Mitarbeiterbelegung durchzuführen */
  private async setPreferredPerson() {
    const allAddresses = await this.addressService.getAllDisplayable();
    this.appointmentPerson = allAddresses.filter(
      address => address.ADRTYP === 'K' || address.ADRTYP === 'M' || address.ADRTYP === 'F' || address.ADRTYP === 'L'
    );
    this.appointmentPerson = this.appointmentPerson.filter(
      address => address.LeaveDate == null || address.LeaveDate >= new Date()
    );
    if (!isNullOrUndefined(this.termin)) {
      const kundenNummer = this.termin.adr;
      this.selectedPerson = this.appointmentPerson.find(address => address.KU_NR === kundenNummer);
    }
  }

  async checkIfTerminIsInPast(): Promise<void> {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 5); // 5 Minuten Toleranz - prüft man auf exakt jetzt, schlägt natürlich immer die Prüfung an
    const terminInThePast = this.termin.startDate < now;
    if (terminInThePast)
      await this.dialogService.openConfirmDialog(
        'Warnung!',
        'Die eingegebenen Termindaten liegen in der Vergangenheit!',
        'Ok',
        null,
        true
      );
  }

  /**@description Speichert Termin in IDB und Webservice */
  async save(): Promise<void> {
    if (this.blocked) return;
    this.blocked = true;
    if (!isNullOrUndefined(this.selectedPerson)) {
      const kundennummer = this.selectedPerson.KU_NR;
      this.termin.adr = kundennummer;
    }
    if (this.termin.startDate > this.termin.endDate) {
      await this.dialogService.openConfirmDialog(
        'Fehler',
        'Der Termin kann nicht enden, bevor er begonnen hat. Bitte korrigieren Sie Ihre Eingabe.',
        'Ok',
        null,
        true
      );
      this.blocked = false;
      return;
    }
    await this.checkIfTerminIsInPast();
    const userInfo = await this.globalSettingService.getUserinfo();
    const logObjectForWebs: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\ncomponent: appointments-edit.component\n function: save',
      JSON.stringify(this.termin)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs);
    await this.appointmentService.syncUpdatedOrAdded(userInfo, this.termin);
    this.routingService.dataChanged.next(false);
    this.blocked = false;
    this.routingService.routeBack();
  }

  private async loadTermin(): Promise<void> {
    const currentRoute = this.routingService.getCurrentRoute();
    if (currentRoute.startsWith('/termin/neu')) {
      this.createNewTermin();
    } else {
      await this.getTerminById();
    }
  }

  private createNewTermin() {
    const dateParam = this.routingService.getRouteParam('date');
    const dateInMs = dateParam ? parseInt(dateParam, 10) : new Date().getTime();
    const date = new Date(dateInMs);
    const customerNumber = this.routingService.getRouteParam('kundennummer');
    this.termin = new HWTermin(null, date, customerNumber);
    this.dateChanged(date, true);
  }

  private async getTerminById(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserinfo();
    const appointmentId = this.routingService.getRouteParam('id');
    const repairOrders = await this.repairOrderService.getAllRepairOrdersFromIDB();
    const appointment = await this.appointmentService.getAllTermineFromIDB(userInfo, null, true, null, repairOrders);
    const termin = appointment.filter(appointmentFilter => appointmentFilter.id === appointmentId)[0];
    if (termin.isTerminOverDays) this.termin = getOverDaysBaseTermin(appointment, termin.id);
    else this.termin = termin;
  }
}
