import { OfflinePosition } from 'apps/handwerkPWA/src/app/interfaces';

export class Lieferant implements OfflinePosition {
  UUID: string = null;
  /** Lieferantennnummer */
  NR: string = null;
  /** Kundennummer des Lieferanten */
  ADRNR: string = null;
  /** Lieferantenname */
  NAME: string = null;
  /** ??? */
  OCILIEF: number = null;
  /** Anzahl von Artikeln */
  HASMATERIAL: number = null;
  /** Eigene Kundennummer beim Lieferanten */
  KDNR: string = null;
  /** Lieferandanennummer kombiniert mit Name */
  displayName = null;
  offlineAvailable = false;
  synchronize = false;

  constructor(data: any) {
    Object.assign(this, data);
    this.displayName = this.NR + ' ' + this.NAME;
  }
}
