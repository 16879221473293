import { BauelementVorlage } from '../entities/models/aufmass/BauelementVorlage';

const kreisFlaeche = '3,141*([laengeGemessen]*[laengeGemessen])';

const Tuer: BauelementVorlage = {
  bezeichnung: 'Tür',
  iconPath: 'assets/icons/Tür.svg',
  bezeichnungLaengegemessen: 'Breite',
  bezeichnungHoehe: 'Höhe',
  bezeichnungTiefe: 'Laibungstiefe',
  pflichtFelder: ['laengeGemessen', 'Hoehe'],
  kannFelder: ['Tiefe'],
  felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
  inRaum: false,
};

const Fenster: BauelementVorlage = {
  bezeichnung: 'Fenster',
  iconPath: 'assets/icons/Fenster.svg',
  bezeichnungLaengegemessen: 'Breite',
  bezeichnungHoehe: 'Höhe',
  bezeichnungTiefe: 'Laibungstiefe',
  pflichtFelder: ['laengeGemessen', 'Hoehe'],
  kannFelder: ['Tiefe'],
  felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
  inRaum: false,
};

const SaeuleRund: BauelementVorlage = {
  bezeichnung: 'Säule rund',
  iconPath: 'assets/icons/Säule.svg',
  bezeichnungLaengegemessen: 'Durchmesser',
  bezeichnungHoehe: '',
  bezeichnungTiefe: '',
  pflichtFelder: ['laengeGemessen'],
  kannFelder: [],
  felder: ['laengeGemessen'],
  inRaum: true,
  inBoden: true,
  alternatingFormula: kreisFlaeche,
};

const SaeuleRechteckig: BauelementVorlage = {
  bezeichnung: 'Säule rechteckig',
  iconPath: 'assets/icons/Säule.svg',
  bezeichnungLaengegemessen: 'Breite',
  bezeichnungHoehe: 'Tiefe',
  bezeichnungTiefe: '',
  pflichtFelder: ['laengeGemessen', 'Hoehe'],
  kannFelder: [],
  felder: ['laengeGemessen', 'Hoehe'],
  inRaum: true,
  inBoden: true,
};

const AbzugWand: BauelementVorlage = {
  bezeichnung: 'Abzugfläche Wand',
  iconPath: 'assets/icons/Durchbruch.svg',
  bezeichnungLaengegemessen: 'Breite',
  bezeichnungHoehe: 'Höhe',
  bezeichnungTiefe: 'Laibungstiefe',
  pflichtFelder: ['laengeGemessen', 'Hoehe'],
  kannFelder: ['Tiefe'],
  felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
  inRaum: false,
};

const AbzugDecke: BauelementVorlage = {
  bezeichnung: 'Abzugfläche Decke',
  iconPath: 'assets/icons/Deckenlampe.svg',
  bezeichnungLaengegemessen: 'Breite',
  bezeichnungHoehe: 'Länge',
  bezeichnungTiefe: '',
  pflichtFelder: ['laengeGemessen', 'Hoehe'],
  kannFelder: [],
  felder: ['laengeGemessen', 'Hoehe'],
  inRaum: true,
  inBoden: false,
};

const AbzugBoden: BauelementVorlage = {
  bezeichnung: 'Abzugfläche Boden',
  iconPath: 'assets/icons/Bauteil.svg',
  bezeichnungLaengegemessen: 'Breite',
  bezeichnungHoehe: 'Länge',
  bezeichnungTiefe: '',
  pflichtFelder: ['laengeGemessen', 'Hoehe'],
  kannFelder: [],
  felder: ['laengeGemessen', 'Hoehe'],
  inRaum: true,
  inBoden: true,
};

// const Heizkoerper: BauelementVorlage = {
//   bezeichnung: 'Heizkörper',
//   iconPath: 'assets/icons/Heizung.svg',
//   bezeichnungLaengegemessen: 'Breite',
//   bezeichnungHoehe: 'Höhe',
//   bezeichnungTiefe: 'Nischentiefe ',
//   pflichtFelder: ['laengeGemessen', 'Hoehe'],
//   kannFelder: ['Tiefe'],
//   felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
//   inRaum: false
// }

// const Abdeckung: BauelementVorlage = {
//   bezeichnung: 'Abdeckung',
//   iconPath: 'assets/icons/Abdenkungen.svg',
//   bezeichnungLaengegemessen: 'Breite',
//   bezeichnungHoehe: 'Höhe',
//   bezeichnungTiefe: 'Nischentiefe ',
//   pflichtFelder: ['laengeGemessen', 'Hoehe'],
//   kannFelder: [],
//   felder: ['laengeGemessen', 'Hoehe'],
//   inRaum: false
// }

// const Lampe: BauelementVorlage = {
//   bezeichnung: 'Deckenlampe',
//   iconPath: 'assets/icons/Deckenlampe.svg',
//   bezeichnungLaengegemessen: 'Breite',
//   bezeichnungHoehe: 'Länge',
//   bezeichnungTiefe: '',
//   pflichtFelder: ['laengeGemessen', 'Hoehe'],
//   kannFelder: [],
//   felder: ['laengeGemessen', 'Hoehe'],
//   inRaum: true,
//   inBoden: false
// }

// const Durchgang: BauelementVorlage = {
//   bezeichnung: 'Durchgang / Durchbruch',
//   iconPath: 'assets/icons/Durchbruch.svg',
//   bezeichnungLaengegemessen: 'Breite',
//   bezeichnungHoehe: 'Höhe',
//   bezeichnungTiefe: 'Laibungstiefe',
//   pflichtFelder: ['laengeGemessen', 'Hoehe'],
//   kannFelder: ['Tiefe'],
//   felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
//   inRaum: false
// }

// const Nische: BauelementVorlage = {
//   bezeichnung: 'Nische',
//   iconPath: 'assets/icons/Nische.svg',
//   bezeichnungLaengegemessen: 'Breite',
//   bezeichnungHoehe: 'Höhe',
//   bezeichnungTiefe: 'Laibungstiefe',
//   pflichtFelder: ['laengeGemessen', 'Hoehe'],
//   kannFelder: ['Tiefe'],
//   felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
//   inRaum: false
// }

// const Bauteil: BauelementVorlage = {
//   bezeichnung: 'Bauteil',
//   iconPath: 'assets/icons/Bauteil.svg',
//   bezeichnungLaengegemessen: 'Breite',
//   bezeichnungHoehe: 'Höhe',
//   bezeichnungTiefe: 'Tiefe',
//   pflichtFelder: ['laengeGemessen', 'Hoehe'],
//   kannFelder: ['Tiefe'],
//   felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
//   inRaum: false
// }

// const Podest: BauelementVorlage = {
//   bezeichnung: 'Podest',
//   iconPath: 'assets/icons/Podest.svg',
//   bezeichnungLaengegemessen: 'Breite',
//   bezeichnungHoehe: 'Höhe',
//   bezeichnungTiefe: 'Tiefe',
//   pflichtFelder: ['laengeGemessen', 'Hoehe'],
//   kannFelder: ['Tiefe'],
//   felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
//   inRaum: false
// }

// const Fliesenspiegel: BauelementVorlage = {
//   bezeichnung: 'Fliesenspiegel',
//   iconPath: 'assets/icons/Fliesenspiegel.svg',
//   bezeichnungLaengegemessen: 'Breite',
//   bezeichnungHoehe: 'Länge',
//   bezeichnungTiefe: '',
//   pflichtFelder: ['laengeGemessen', 'Hoehe'],
//   kannFelder: [],
//   felder: ['laengeGemessen', 'Hoehe', 'Tiefe'],
//   inRaum: false
// }

// export const BauelementVorlagen: BauelementVorlage[] = [Tuer, Fenster, Heizkoerper, SaeuleRund, Abdeckung, Lampe, Durchgang, Nische, Bauteil, Podest, Fliesenspiegel]
export const BauelementVorlagen: BauelementVorlage[] = [
  Tuer,
  Fenster,
  SaeuleRund,
  SaeuleRechteckig,
  AbzugWand,
  AbzugDecke,
  AbzugBoden,
];
