import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';

export class InappChangeRights {
  autoLohn = true;
  rounding = true;
  pdfPreview = true;
  mailFromOrder = true;
  addWorkinghoursToDocument = true;
  showWorkinghoursInPdf = true;
  showPrices = true;
  printPrices = true;

  constructor(inAppChangeRights: InappChangeRights) {
    assignIfPropertyExsits(this, inAppChangeRights);
  }

  allowEverything() {
    this.autoLohn = true;
    this.rounding = true;
    this.pdfPreview = true;
    this.mailFromOrder = true;
    this.addWorkinghoursToDocument = true;
    this.showWorkinghoursInPdf = true;
    this.showPrices = true;
    this.printPrices = true;
  }

  restrictEverything() {
    this.autoLohn = false;
    this.rounding = false;
    this.pdfPreview = false;
    this.mailFromOrder = false;
    this.addWorkinghoursToDocument = false;
    this.showWorkinghoursInPdf = false;
    this.showPrices = false;
    this.printPrices = false;
  }
}
