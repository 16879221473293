import { Injectable } from '@angular/core';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyArray } from 'libs/shared/src/lib/helper/globalHelper';
import { ControllerService } from '../globalServices/controller.service';
import { HWBeleg, BelegInfo, Belegpositionen } from 'apps/handwerkPWA/src/app/entities';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from '@handwerk-pwa/shared';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { AddressService } from './address.service';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BaseService } from './base.service';
import { HWGolbalsettingService } from '../globalServices/hwgolbalsetting.service';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';

@Injectable({
  providedIn: 'root',
})
export class BelegService {
  constructor(
    private controllerService: ControllerService,
    private logger: NGXLogger,
    private restService: RestService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private globalService: HWGolbalsettingService,
    private connectionService: ConnectionService
  ) {}

  /**@description Holt alle Belege aus der IDB oder Webservice */
  async getAll(trySync: boolean = false): Promise<HWBeleg[]> {
    const localBelege: HWBeleg[] = await this.baseService.getAll(HWBeleg);

    if (trySync) {
      const isOnline = await this.connectionService.CheckOnline(ConnectionDialogues.GetData);
      if (isOnline) return await this.getAllBelegeFromWebservice();
    }

    return localBelege;
  }

  /**
   * @description Holt alle Belege aus der IDB mit bestimmten Suchkriterien
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async getAllBy(selector: string, value: any): Promise<HWBeleg[]> {
    return await this.baseService.getAllBy(HWBeleg, selector, value);
  }

  /**
   * @description Holt alle Belege nach Kundennummer aus der IDB oder wenn Internetverbindung besteht aus Webservice
   * @param kundenNummer der Adresse für die die Belege geholt werden sollen
   * */
  async getAllByKundennummer(kundenNummer: string): Promise<HWBeleg[]> {
    const localBelege = await this.getAllBy('kunde', kundenNummer);
    const isOnline = await this.connectionService.CheckOnline(ConnectionDialogues.GetData);
    if (isOnline) return await this.getBelegeByKundennummerFromWebservice(kundenNummer);

    return localBelege;
  }

  /**
   * @description Holt ein Beleg aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async findOneBy(selector: string, value: any): Promise<HWBeleg> {
    return await this.baseService.findOneBy(HWBeleg, selector, value);
  }

  /**
   * @description Holt alle Belegposition für einen Belge aus der IDB oder wenn Internetverbindung besteht aus Webservice
   */
  async getBelegPositionen(userInfo: Userinfo, beleg: HWBeleg): Promise<Belegpositionen[]> {
    const localBelegPositionen = beleg.Belegpositionen;

    const isOnline = await this.connectionService.CheckOnline(ConnectionDialogues.GetData);
    if (isOnline) return await this.getAllBelegPositionenFromWebservice(userInfo, [beleg]);

    return localBelegPositionen;
  }

  /**@description Holt alle Belege die geholt werden können - 0 als Kundennummer holt alle */
  async getAllBelegeFromWebservice(silent = false): Promise<HWBeleg[]> {
    const userInfo = await this.globalService.getUserinfo();
    if (!silent) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Belege...');
    }
    const allBelegeData = await this.restService.returnData<HWBeleg[]>(
      userInfo,
      'belege/mandant/' + userInfo.mandant + '/KU_NR/' + '0' + '/username/' + userInfo.monteur
    );
    let allBelege: HWBeleg[] = [];
    if (isNullOrUndefined(allBelegeData)) {
      this.dialogService.closeLoadingDialog();
      return allBelege;
    }
    allBelege = await this.rawDataArrayToObjectsArray(allBelegeData);
    await this.writeBelegeToIDB(allBelege, true);
    this.dialogService.closeLoadingDialog();
    return allBelege;
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description Fügt den Belgen ihre Positionen hinzu */
  private addBelegpositonenToBelege(allBelege: HWBeleg[], allBelegpositionen: Belegpositionen[]) {
    for (const position of allBelegpositionen) {
      const nummer = position.Belegnummer;
      for (const beleg of allBelege) {
        const belegNummer = beleg.name;
        if (belegNummer === nummer) {
          beleg.addBelegposition(position);
        }
      }
    }
    return allBelege;
  }

  /**@description Schreibt die Belege in die IDB */
  private async writeBelegeToIDB(belege: HWBeleg[], clear: boolean) {
    if (clear) {
      await this.controllerService.clearStore('HWBeleg');
    }
    await this.controllerService.setData('HWBeleg', belege);
  }

  /**@description Holt alle Belegpositionen die geholt werden können */
  private async getAllBelegPositionenFromWebservice(
    userInfo: Userinfo,
    allBelegeToGetPositionsFor: HWBeleg[]
  ): Promise<Belegpositionen[]> {
    const belegInfos = [];
    for (const beleg of allBelegeToGetPositionsFor) {
      const belegnummer = beleg.name;
      const belegJahr = beleg.jahr;
      const belegInfo = new BelegInfo(belegnummer, belegJahr);
      belegInfos.push(belegInfo);
    }
    const allBelegPositionen = await this.restService.returnData<Belegpositionen[]>(
      userInfo,
      'allBelegpositionen',
      belegInfos
    );
    const alleBelegPositionenClean: Belegpositionen[] = [];
    const belegPositionenArray = [];
    if (isNullOrUndefined(allBelegPositionen)) {
      this.logger.error('Belegpositionen nicht vorhanden');
      return alleBelegPositionenClean;
    }
    for (const belegPosition of allBelegPositionen) {
      if (!isNullOrUndefined(belegPosition)) {
        belegPositionenArray.push(belegPosition);
      }
    }
    const belegeArrayFlat = [].concat(...belegPositionenArray);
    for (const data of belegeArrayFlat) {
      const belegPosition = new Belegpositionen(data);
      alleBelegPositionenClean.push(belegPosition);
    }
    return alleBelegPositionenClean;
  }

  private async updateBelegInIdb(belege: HWBeleg[]) {
    if (isNullOrUndefinedOrEmptyArray(belege)) {
      return;
    }
    const kundenNummer = belege[0].kunde;
    await this.controllerService.deleteData('HWBeleg', 'kunde', kundenNummer);
    await this.controllerService.setData('HWBeleg', belege);
  }

  async getBelegeByKundennummerFromWebservice(kundennummer: string): Promise<HWBeleg[]> {
    const userInfo = await this.globalService.getUserinfo();
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole Belege...');
    const allBelegeData = await this.restService.returnData<HWBeleg[]>(
      userInfo,
      'belege/mandant/' + userInfo.mandant + '/KU_NR/' + kundennummer + '/username/' + userInfo.monteur + ''
    );
    let allBelege: HWBeleg[] = [];
    if (isNullOrUndefined(allBelegeData)) {
      this.dialogService.closeLoadingDialog();
      return allBelege;
    }
    allBelege = await this.rawDataArrayToObjectsArray(allBelegeData);
    const allBelegpositionen = await this.getAllBelegPositionenFromWebservice(userInfo, allBelege);
    const belegeWithBelegpositionen = this.addBelegpositonenToBelege(allBelege, allBelegpositionen);
    await this.updateBelegInIdb(belegeWithBelegpositionen);
    this.dialogService.closeLoadingDialog();
    return allBelege;
  }

  private async rawDataArrayToObjectsArray(rawDataArray: object[]): Promise<HWBeleg[]> {
    const belege: HWBeleg[] = [];
    const allAdresses = await this.addressService.getAll();

    for (const belegData of rawDataArray) {
      const beleg = new HWBeleg(belegData);
      const kunde = allAdresses.find(filterKunde => filterKunde.KU_NR === beleg.kunde);
      if (kunde) {
        beleg.kundenname = kunde.NAME;
      }
      belege.push(beleg);
    }

    return belege;
  }
}
