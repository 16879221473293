import { IndexedDBTypes } from './dbType';

export class HWProjekt extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWProjekt') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') Nummer: string;
  @IndexedDBTypes.IndexField('string') Name: string;
  @IndexedDBTypes.DataField('string') Kunde: string;
  @IndexedDBTypes.DataField('string') KdSuch: string;
  @IndexedDBTypes.DataField('string') Betreff: string;

  public getAutoKey(): string {
    return this.AutoKey;
  }

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  static toString(): string {
    return 'HWProjekt';
  }
}
