import { CommonModule } from '@angular/common';
import {
  Component,
  NgModule,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { DxTreeViewModule, DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { PwaLoginResponse, Right } from 'libs/shared/src/lib/entities';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { baseitems, Module, navigationLoggedOut } from '../../../config/navigation';
import { noLicenceShort, noRightShort, noLicenceLong, noRightLong } from '../../../config/TextKonstanten';
import { NavigationItem } from '../../../entities';
import { convertToRightId } from '../../../helper/entities/HWAnlage/convertHelper';
import { StateService } from '../../../services/globalServices/state.service';
import { SyncService } from '../../../services/globalServices/sync.service';

const moduleBaseRoute = ['/reparaturauftraege', '/nachrichten', '/wartungsanlagen', '/wartungsauftraege', '/aufmasse'];

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss'],
})
export class SideNavigationMenuComponent implements OnChanges, OnDestroy {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu: DxTreeViewComponent;
  @Output() selectedItemChanged = new EventEmitter<string>();
  @Input() selectedItem: string;
  items = new BehaviorSubject<NavigationItem[]>(navigationLoggedOut);
  private combineSubscription$: Subscription;
  private menueOpened$: Subscription;
  newThings: BehaviorSubject<boolean>;

  constructor(
    private routingService: RoutingService,
    private rechteService: RechteService,
    private dialogService: DialogService,
    private authorisationService: AuthorisationService,
    private syncService: SyncService,
    private stateService: StateService
  ) {
    const loggedInSubscription = this.stateService.loggedIn;
    const currentRight = this.rechteService.getCurrentRightAsync();
    const currentAuthorisation = this.authorisationService.current;
    this.combineSubscription$ = combineLatest([
      loggedInSubscription,
      currentRight,
      currentAuthorisation,
    ]).subscribe(([loggedIn, right, authorisation]: [boolean, Right, PwaLoginResponse]) =>
      this.setCorrectMenuItemsAndStates(loggedIn, right, authorisation)
    );
    this.menueOpened$ = this.routingService.menuOpen.subscribe(openState => this.toggleModuleEntries(openState));
    this.newThings = this.syncService.newThingsDiscovered;
  }

  ngOnDestroy(): void {
    this.combineSubscription$?.unsubscribe();
    this.menueOpened$?.unsubscribe();
  }

  /**@description Wenn sich entweder der loginStatus oder die Rechte ändern,werden die Menüeinträge dementsprechend gesetzt */
  setCorrectMenuItemsAndStates(loggedIn: boolean, right: Right, authorisation: PwaLoginResponse): void {
    const correctItemSet = this.getItemset(loggedIn);

    // Disabled-Status und Anzeigestatus der Direkten Einträge prüfen
    for (const item of correctItemSet) {
      const itemPath = item.path;
      const pathRightId = convertToRightId(itemPath);
      item.customDisabled = pathRightId ? !right.hasRightOrSettingEnabled(pathRightId) : false;
    }

    const module = correctItemSet.find(item => item.text === 'Module');
    if (module) {
      module.customDisabled = !right.hasRightOrSettingEnabled('showObjektadressen'); // lizenz
      // Disabled-Status und Anzeigestatus der Modul-Einträge prüfen
      for (const modulItem of module.items) {
        const itemPath = modulItem.path;
        const pathRightId = convertToRightId(itemPath);
        modulItem.customDisabled = pathRightId ? !right.hasRightOrSettingEnabled(pathRightId) : false;
      }
      const availableFeatures = Module.filter(item => authorisation.featureCheck(item.feature).available);
      correctItemSet.find(item => item.text === 'Module').items = availableFeatures;
    }

    // Hier das slice um einen neuen Value zu geben,ansonsten erkennt die changedetection keine änderung und die falschen Menüpunkte sind da
    this.items.next(correctItemSet.slice());
  }

  /**@description Bezieht das richtige Itemset */
  private getItemset(loggedIn: boolean): NavigationItem[] {
    if (!loggedIn) return navigationLoggedOut;
    return baseitems;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedItemChange = changes.selectedItem;
    if (selectedItemChange) {
      const currentItemPath = selectedItemChange.currentValue as string;
      this.markSelectedItem(currentItemPath);
      return;
    }
  }

  /**@description Markiert das aktuell gewählte Element anhand seiner Route; handelt es sich um ein Unteritem bleibt der Expandiert Status des Menüs */
  private markSelectedItem(currentItemPath: string) {
    const moduleItem = this.items.value.find(item => item.text === 'Module');
    const moduleHasIt = moduleItem?.items?.some(item => item?.path === currentItemPath);
    const moduleRepairCenterHasIt = moduleItem?.items[0]?.items?.some(item => item?.path === currentItemPath);
    const subItem = moduleHasIt
      ? moduleItem.items.find(item => item.path === currentItemPath)
      : moduleRepairCenterHasIt
      ? moduleItem.items[0].items.find(item => item.path === currentItemPath)
      : null;

    const currentItem = this.items.value.find(item => item.path === currentItemPath);
    const markItem = currentItem || subItem;
    if (!markItem) return;
    this.menu?.instance?.selectItem(markItem);
  }

  /**
   * @description Wird das Menü geschlossen (openstate ist false) müssen auch die Module -Einträge eingeklappt werden
   * - wird das Menü geöffnet, müssen module aufgeklappt werden,wenn man auf einer modulroute ist
   */
  private toggleModuleEntries(openState: boolean) {
    const currentRoute = this.routingService.getCurrentRoute();
    if (openState === false) this.menu?.instance?.collapseAll();
    if (openState === true) {
      if (moduleBaseRoute.includes(currentRoute)) {
        this.menu?.instance?.expandAll();
      }
    }
  }

  onItemClick(item: NavigationItem): void {
    const isModule = item?.text === 'Module';
    const isReparatur = item?.text === 'Reparaturcenter';
    if (item?.customDisabled) {
      const errorTitle = isModule ? noLicenceShort : noRightShort;
      const errorText = isModule ? noLicenceLong : noRightLong;
      void this.dialogService.openErrorMessage(errorTitle, errorText);
      this.routingService.nextMenuState(false);
      return;
    }
    const menuOpen = this.routingService.menuOpen.getValue();
    if (menuOpen) {
      if ((isModule || isReparatur) && item?.isExpanded) this.menu?.instance?.expandItem(Module[0]);
      else this.menu?.instance?.collapseItem(Module[0]);
      const path = item.path;
      this.selectedItemChanged.emit(path);
      return;
    }

    this.routingService.menuOpen.next(true);
  }
}

@NgModule({
  imports: [DxTreeViewModule, CommonModule],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
