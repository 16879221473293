import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import { getSearchexpressionsBasedOnProperties, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-bss-suggestion-field',
  templateUrl: './bss-suggestion-field.component.html',
  styleUrls: ['./bss-suggestion-field.component.scss'],
})
export class BssSuggestionFieldComponent implements OnInit {
  @Input() value: string;
  @Input() disabled = false;
  @Input() readOnly = false;
  showPopup = false;
  @Input() buttonStyle;
  @Input() placeholder;
  @Input() labelText;
  maxLength: number;
  @Input() headline: string;
  @Input() inputDataSource: any[];
  @Input() customSearchExpression: string;
  @Input() customDisplayExpression: string;
  @Input() dataTypeName: string;
  @Output() valueChange = new EventEmitter<string>();
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;
  searchValue: string;
  searchExpressions: string[];

  constructor() {}

  ngOnInit(): void {
    this.searchValue = this.value;
    this.searchExpressions = getSearchexpressionsBasedOnProperties(this.inputDataSource);
  }
  valueChanged(value: string): void {
    return;
  }

  /**@description Wenn geklickt,wird der aktuelle Suchbegriff als Wahl durchgereicht */
  onManualSaveClick(currentSearchExpression: string): void {
    this.emitValue(currentSearchExpression);
  }

  onItemClick(item: any): void {
    const expression: string = item[this.customDisplayExpression] as string;
    this.emitValue(expression);
  }

  private emitValue(expression: string) {
    this.valueChange.emit(expression);
    this.showPopup = false;
  }

  getPropertyValue(item, index: number): string {
    const propertyValue = item[this.customDisplayExpression];
    if (isNullOrUndefined(propertyValue)) {
      return null;
    }
    return propertyValue;
  }
}
