<div class="content-block" *ngIf="anlagenTermin">
  <div class="form-block">
    <h2>Anlagentermin</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box
            [labelText]="'Wartungszyklus'"
            [value]="anlagenTermin.IntervallReadable"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Beginn Wartung'"
            [value]="anlagenTermin.ANLAGEBEG"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Intervall beginnt ab'"
            [value]="anlagenTermin.ISTART"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Ende Wartung'"
            [value]="anlagenTermin.ANLAGEENDE"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Letzte Wartung'"
            [value]="anlagenTermin.LWARTUNG"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Nächste Wartung'"
            [value]="anlagenTermin.NWARTUNG"
          ></app-bss-plain-text-box>
          <app-bss-switchbox
            [labelText]="'Letzte Wartung für Intervall verwenden'"
            [disabled]="true"
            [(value)]="anlagenTermin.USELWTONW"
          ></app-bss-switchbox>
          <app-bss-switchbox
            [labelText]="'Letzten tatsächlichen Wartungstermin verwenden'"
            [disabled]="true"
            [(value)]="anlagenTermin.USERTTONW"
          ></app-bss-switchbox>
          <app-bss-switchbox
            [labelText]="'Keine Wartung'"
            [disabled]="true"
            [(value)]="anlagenTermin.NOWARTUNG"
          ></app-bss-switchbox>
          <app-bss-plain-text-box
            [labelText]="'Benachrichtigung'"
            [value]="anlagenTermin.Benachrichtigung"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Inbetriebnahme'"
            [value]="anlagenTermin.INBETRIEB"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Garantie von'"
            [value]="anlagenTermin.VGARANTIE"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Garantie bis'"
            [value]="anlagenTermin.BGARANTIE"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Gekndigt am'" [value]="anlagenTermin.KUENDAM"></app-bss-plain-text-box>
        </div>
      </div>
    </div>
  </div>
</div>
