import { Injectable } from '@angular/core';
import { Right, PwaLoginResponse } from 'libs/shared/src/lib/entities';
import { saveToLocalstorage, readFromLocalstorage } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { StateService } from './state.service';
import { SyncService } from './sync.service';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  constructor(
    private routingService: RoutingService,
    private rechteService: RechteService,
    private authorisationService: AuthorisationService,
    private syncService: SyncService,
    private stateService: StateService
  ) {}

  /**@description Behält Sessioninformation vor einem Browser Refresh */
  keepSessionData(): void {
    saveToLocalstorage('routes', this.routingService.lastRoutes);
    saveToLocalstorage('loggedIn', this.stateService.loggedIn.value);
    saveToLocalstorage('right', this.rechteService.getCurrentRight());
    saveToLocalstorage('authorisation', this.authorisationService.current.getValue());
    saveToLocalstorage('entdecken', this.syncService.newThingsDiscovered.getValue());
  }

  /**@description Lädt Sessioninformation wenn die app (neu) gestartet wird */
  readSessionData(): void {
    this.routingService.lastRoutes = readFromLocalstorage('routes') || [];
    const loggedIn: boolean = readFromLocalstorage('loggedIn') || false;
    this.stateService.loggedIn.next(loggedIn);
    const currentRight = readFromLocalstorage('right', Right);
    this.rechteService.updateCurrentRight(currentRight);
    const currentAuthorisation = readFromLocalstorage('authorisation', PwaLoginResponse);
    this.authorisationService.current.next(currentAuthorisation);
    const entdecken: boolean = readFromLocalstorage('entdecken') || false;
    this.syncService.newThingsDiscovered.next(entdecken);
  }

  /**@description Löscht die Daten des Localstorage und setzt die werte wo sie herkommen auf defaultwerte zurück */
  clearLocalSessionData(): void {
    localStorage.clear();
    this.routingService.lastRoutes = [];
    this.stateService.loggedIn.next(false);
    this.rechteService.updateCurrentRight(null);
    this.authorisationService.current.next(null);
    this.syncService.newThingsDiscovered.next(false);
  }
}
