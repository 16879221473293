import { DeviceInfo } from 'ngx-device-detector';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { Role } from './Role';
import { Allocation } from './Allocation';
import { Mobiledevices } from './Mobiledevices';

export interface BaseUserInfo {
  uuid: string;
  mandant: string;
  monteur: string;
  branding?: string;
  pin?: string;
}

export class Userinfo {
  /** uuid des handwerks mit der die allocation registriert wurde */
  uuid: string;
  /** der Username des handwerks - häufig ADMINISTRATOR */
  user: string;
  /**passwort des administrators */
  password = 'pk';
  mandant: string;
  branding: string;
  /**personalnummer des monteurs */
  monteur: string;
  /**passwort  des monteurs */
  pin: string;
  geaendert = '01.01.1900';
  // Durch das wegschmeißen des relativ ähnlichen employeeident kamen folgende 3 einträge hinzu
  Role: Role;
  employeeNumber: string;
  employeeName: string;
  /**Zur simplen Steuerung ob beim Lesen von Rechten die Lizenz mit angefragt wird */
  releaseVersion = true;
  currentAllocation: Allocation;
  /**Repräsentation der zugehörigen Handwerkstabelle */
  Device: Mobiledevices = null;
  updateDonePwa = false;

  constructor(userinfoData: BaseUserInfo | Userinfo) {
    Object.assign(this, userinfoData);
    this.employeeNumber = this.monteur;
    this.Device = new Mobiledevices(this.Device);
    this.Device.assignPropertiesFromUserInfo(this);
  }

  assignDeviceInfo(deviceInfo: DeviceInfo): void {
    this.Device.assignDeviceInfo(deviceInfo);
  }

  assignPushId(pushUuid: string): void {
    this.Device.assignPushtoken(pushUuid);
  }

  setPwaUpdateDoneFlag(): void {
    this.updateDonePwa = true;
  }

  unsetPwaUpdateDoneFlag(): void {
    this.updateDonePwa = false;
  }
}
