import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';

@Component({
  selector: 'app-bss-check-box',
  templateUrl: './bss-check-box.component.html',
  styleUrls: ['./bss-check-box.component.scss'],
})
export class BssCheckBoxComponent implements OnInit {
  @Input() checkBoxInputs: { value: boolean; name: string }[];
  localCheckBoxInputsArray: { value: boolean; name: string }[];
  @Input() title: string;
  @Input() showPopup: boolean;
  @Input() height: string;
  checkBoxValue: boolean;
  @Output() checkBoxInputsChange = new EventEmitter<{ value: boolean; name: string }[]>();
  @Output() confirmClicked = new EventEmitter<{ value: boolean; name: string }[]>();
  @Input() allowNothingSelected = true;
  lastChangedName: string;

  constructor(private dialogService: DialogService) {}

  ngOnInit() {
    this.localCheckBoxInputsArray = this.checkBoxInputs.slice();
  }

  onConfirmClick(allowNothingSelected: boolean, lastChangedName: string): void {
    const allUnselected = this.localCheckBoxInputsArray.every(entry => entry.value === false);
    if (!allowNothingSelected && allUnselected) {
      this.localCheckBoxInputsArray.find(entry => entry.name === lastChangedName).value = true;
      return void this.dialogService.openErrorMessage('Fehler', 'Mindestens ein Element muss ausgewählt bleiben. ');
    }
    this.confirmClicked.emit(this.localCheckBoxInputsArray);
  }
}
