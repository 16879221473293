import { Component, OnInit } from '@angular/core';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { getSearchableAnlagen } from 'apps/handwerkPWA/src/app/helper/entities/HWAnlage/anlageSearchHelper';
import { getSearchexpressionsBasedOnProperties } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-systems-index',
  templateUrl: './systems-index.component.html',
  styleUrls: ['./systems-index.component.scss'],
})
export class SystemsIndexComponent implements OnInit {
  anlagen: HWAnlage[];
  searchExpressions = [];

  constructor(private wartungsService: WartungsprojectsService) {}

  async ngOnInit() {
    const baseAnlagen = await this.wartungsService.getAnlagenFromIDB();
    this.anlagen = getSearchableAnlagen(baseAnlagen);
    this.searchExpressions = getSearchexpressionsBasedOnProperties(this.anlagen);
  }
}
