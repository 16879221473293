import { Right } from 'libs/shared/src/lib/entities';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import moment from 'moment';
import { deDateFormat } from '../../config/Konstanten';
import { HWAddress, HWTermin } from '../../entities';

/**@description Nimmt ein Terminarray und ersetzt (wo möglich) den Termin durch einen spezielleren Datensatz */
export function termineToMoreSpecificDatasets(currentFilterTermineRaw: HWTermin[], right: Right): HWTermin[] {
  const auftragsRecht = right.Employeerights.showAuftrag;
  const serviceAuftragsRecht = right.Employeerights.Wartungsrights.handleOrder;
  for (const termin of currentFilterTermineRaw) {
    if (!auftragsRecht && termin.terminArt === 'Auftrag') {
      currentFilterTermineRaw = currentFilterTermineRaw.filter(e => e !== termin);
      continue;
    }
    if (!serviceAuftragsRecht && termin.terminArt === 'Wartungstermin') {
      currentFilterTermineRaw = currentFilterTermineRaw.filter(e => e !== termin);
      continue;
    }
  }
  return currentFilterTermineRaw;
}

export function getAllTermineForCustomerToday(kundenTermine: HWTermin[]): HWTermin[] {
  const today = new Date();
  const todayIndex = dateToDatabaseDate(today);
  const kundenTermineToday = kundenTermine?.filter(searchTermin => searchTermin.indexDate === todayIndex);
  return kundenTermineToday;
}

export function getEmployeeNamesFromTermine(termine: HWTermin[]): string[] {
  const employeeNames: string[] = [];
  for (const termin of termine) {
    const mitarbeiterName = termin.mitarbeiterName;
    if (employeeNames.includes(mitarbeiterName) || isNullOrUndefinedOrEmptyString(mitarbeiterName)) continue;
    employeeNames.push(mitarbeiterName);
  }

  return employeeNames;
}

/**@description Vergleichsfunktion für die Aufträge zum ordnen */
function compareTermine(a: HWTermin, b: HWTermin) {
  const aStart = a.start;
  const bStart = b.start;
  if (aStart > bStart) {
    return 1;
  }
  if (aStart < bStart) {
    return -1;
  }
  return 0;
}

/**@description Sortiert die Termine nach Datum
 * @param reverse Umgekehrte Reihenfolge
 */
export function sortTermineByDate(termine: HWTermin[], reverse?: boolean): HWTermin[] {
  termine = termine.sort(compareTermine);
  if (reverse) {
    termine = termine.reverse();
  }
  return termine;
}

export function putMitarbeiternameAndKundenname(
  termin: HWTermin,
  kundenAdressen: HWAddress[],
  mitarbeiterAdressen: HWAddress[]
): void {
  const customerNumber = termin.adr;
  if (!isNullOrUndefined(customerNumber) && customerNumber !== '') {
    const customer = kundenAdressen.find(filterKunde => filterKunde.KU_NR === customerNumber);
    if (!isNullOrUndefined(customer)) {
      termin.kundenName = customer.NAME;
    }
  }
  const employeeNumber = termin.mitarbeiter;
  if (!isNullOrUndefined(employeeNumber) && employeeNumber !== '') {
    const employee = mitarbeiterAdressen.find(filterMitarbeiter => filterMitarbeiter.KU_NR === employeeNumber);
    if (!isNullOrUndefined(employee)) {
      termin.mitarbeiterName = employee.NAME;
    }
  }
}

/**@description Guckt ob ein Termin sich über mehr als einen Tag erstreckt */
export function isTerminOverDays(currentTermin: HWTermin): boolean {
  const startDay = moment(currentTermin.startDate).format(deDateFormat);
  const endDay = moment(currentTermin.endDate).format(deDateFormat);
  const isOverDays = startDay !== endDay || currentTermin.isTerminOverDays;
  return isOverDays;
}

/**@description Liest die Liste von Ids aus den Serienterminen aus und gibt sie im richtigen Format (id1,id2,id3...) als string zurück */
export function extractIdsStringFromTermine(serienTermineStartTermine: HWTermin[]): string {
  let ids = '';
  for (const serienTermin of serienTermineStartTermine) {
    const id = serienTermin.id;
    ids = ids + ',' + id;
  }
  const idsLength = ids.length;
  ids = ids.substring(1, idsLength);
  return ids;
}

export function getOverDaysBaseTermin(termine: HWTermin[], id: string): HWTermin {
  const overDaysBaseTermin = termine.find(termin => termin.id === id && isTerminOverDays(termin));
  return overDaysBaseTermin;
}
