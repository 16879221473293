import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { HWAddress, HWAnlage, HWDatenblatt, Messwerttabelle } from 'apps/handwerkPWA/src/app/entities';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { getUntransferredMessungen, transformMessungForHandwerkDb } from '../../helper/services/wartungsHelper';

@Injectable({
  providedIn: 'root',
})
export class WartungsprojectsService {
  constructor(
    private dialogService: DialogService,
    private restService: RestService,
    private controllerService: ControllerService
  ) {}

  /**@description Holt alle Anlagen aus der IDB */
  async getAnlagenFromIDB(): Promise<HWAnlage[]> {
    const anlagenData = await this.controllerService.getData<HWAnlage[]>('HWAnlage');
    const anlagen: HWAnlage[] = [];
    for (const data of anlagenData) anlagen.push(data);
    return anlagen;
  }

  /**
   * @description Holt eine Anlage aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async findOneAnlageBy(selector: string, value: string): Promise<HWAnlage> {
    const anlagenData = await this.controllerService.getData<HWAnlage[]>('HWAnlage', value, selector);
    return anlagenData[0];
  }

  async getAnlagenFromWebservice(
    userInfo: Userinfo,
    kunden: HWAddress[],
    datenblaetter: HWDatenblatt[],
    showDialog = true
  ): Promise<HWAnlage[]> {
    if (showDialog) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Anlagen...');
    const anlagen: HWAnlage[] = [];
    const anlagenData = await this.restService.returnData<HWAnlage[]>(
      userInfo,
      `WAnlagen/mandant/${userInfo.mandant}/username/${userInfo.monteur}`
    );
    for (const data of anlagenData) {
      const anlage = new HWAnlage(data, kunden, datenblaetter);
      anlage.addAdditionalAnlagendata(datenblaetter);
      anlagen.push(anlage);
    }
    await this.writeAnlagenToIDB(anlagen, true);
    this.dialogService.closeLoadingDialog();
    return anlagen;
  }

  async getDatenblaetterFromWebservice(userInfo: Userinfo, showDialog = true): Promise<HWDatenblatt[]> {
    if (showDialog) void this.dialogService.openLoadingDialog('Synchronisation', '...hole Datenblätter...');
    const datenblaetter: HWDatenblatt[] = [];
    const datenblaetterData = await this.restService.returnData<HWDatenblatt[]>(
      userInfo,
      `WDatenbls/mandant/${userInfo.mandant}/username/${userInfo.monteur}`
    );
    if (!datenblaetterData) return [];
    for (const data of datenblaetterData) {
      const datenblatt = new HWDatenblatt(data);
      datenblaetter.push(datenblatt);
    }
    await this.writeDatenblaetterToIDB(datenblaetter, true);
    this.dialogService.closeLoadingDialog();
    return datenblaetter;
  }

  /**@description Sendet einen Messwer ans Handwerk */
  async sendMessungToWebservice(
    anlage: HWAnlage,
    Messung: Messwerttabelle,
    userInfo: Userinfo,
    silent = false
  ): Promise<void> {
    const sendMessung = transformMessungForHandwerkDb(anlage, Messung, userInfo);
    const send = await this.restService.returnData<boolean>(userInfo, 'WriteAnlagenmessung', sendMessung, silent);
    Messung.notTransferred = !send;
    anlage.Messwertsatz.Istwerttabellen.push(Messung);
    await this.updateAnlageInIdb(anlage);
  }

  /**@description Schickt die Messungen die nicht übertragen wurden an das Handwerk */
  async pushAllUnpushedMessungen(userInfo: Userinfo): Promise<void> {
    const anlagen = await this.getAnlagenFromIDB();
    const anlageAndMessung = getUntransferredMessungen(anlagen);
    if (anlageAndMessung.length === 0) return;
    for (const dataset of anlageAndMessung) {
      const anlage = dataset.Anlage;
      const messungen = dataset.Messungen;
      for (const messung of messungen) await this.sendMessungToWebservice(anlage, messung, userInfo, true);
    }
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description  Überschreibt die anlage in der IDb */
  private async updateAnlageInIdb(anlage: HWAnlage) {
    await this.controllerService.deleteData('HWAnlage', 'ANLAGE', anlage.ANLAGE);
    await this.controllerService.setData('HWAnlage', [anlage]);
  }

  private async writeDatenblaetterToIDB(datenblaetter: HWDatenblatt[], clear: boolean) {
    if (clear) await this.controllerService.clearStore('HWDatenblatt');
    await this.controllerService.setData('HWDatenblatt', datenblaetter);
  }

  private async writeAnlagenToIDB(anlagen: HWAnlage[], clear: boolean) {
    if (clear) await this.controllerService.clearStore('HWAnlage');
    await this.controllerService.setData('HWAnlage', anlagen);
  }
}
