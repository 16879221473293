import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vacant-position-card',
  templateUrl: './vacant-position-card.component.html',
  styleUrls: ['./vacant-position-card.component.scss'],
})
export class VacantPositionCardComponent implements OnInit {
  @Input() vacantPosition;

  constructor() {}

  ngOnInit(): void {}
}
