import { Injectable } from '@angular/core';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { Aufmass, Raumbuch } from '../../entities';
import { Raum } from '../../entities/models/aufmass/Raum';
import { RaumbPos } from '../../entities/models/aufmass/RaumbPos';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';

function sortByRAufmId(a: Raumbuch, b: Raumbuch) {
  const aId = a.Raufmid;
  const bId = b.Raufmid;
  if (aId > bId) return 1;
  if (aId < bId) return -1;
  return 0;
}

function sortAllForRaum(allForRaum: Raumbuch[]): Raumbuch[] {
  const allSorted: Raumbuch[] = [];
  const notAbzugFlaechen: Raumbuch[] = [];
  if (allForRaum) {
    const all = allForRaum.slice();
    for (const one of all) {
      if (!one.IsAbzug) notAbzugFlaechen.push(one);
    }
    notAbzugFlaechen.sort(sortByRAufmId);
    for (const area of notAbzugFlaechen) {
      const abzuegeForArea = all.filter(entity => entity.IsAbzug && entity.Lineid === area.Lineid);
      allSorted.push(area);
      allSorted.push(...abzuegeForArea);
    }
  }
  return allSorted;
}

@Injectable({
  providedIn: 'root',
})
export class RaumbuchService {
  constructor(
    private baseService: BaseService,
    private controllerService: ControllerService,
    private restService: RestService
  ) {}

  getAll(aufmass: Aufmass): Raumbuch[] {
    return aufmass.RAUMBUCH;
  }

  public async findAufmassBy(value: any, selector: string): Promise<Aufmass> {
    return await this.baseService.findOneBy(Aufmass, selector, value);
  }

  public async getAllBy(selector: string, value: any): Promise<Raumbuch[]> {
    const aufmass = await this.findAufmassBy(value, selector);
    return aufmass.RAUMBUCH;
  }

  async getAllForRaum(raum: RaumbPos, aufmass?: Aufmass): Promise<Raumbuch[]> {
    let allForRaum = null;
    if (!aufmass) {
      const allForAufmass = await this.getAllBy('AufmId', raum.AufmId);
      allForRaum = allForAufmass?.filter(raumbuch => raumbuch.Rpos_Id === raum.Rpos_ID);
    } else {
      allForRaum = aufmass.getRaumbuch()?.filter(raumbuch => raumbuch.Rpos_Id === raum.Rpos_ID);
    }
    const allForRaumSorted = sortAllForRaum(allForRaum);
    return allForRaumSorted;
  }

  async findOneBy(selector: string, value: any): Promise<Raumbuch> {
    return await this.baseService.findOneBy(Raumbuch, selector, value);
  }

  findOneRaumbuchBy(aufmass: Aufmass, selector: string, value: any): Raumbuch {
    return aufmass.RAUMBUCH.find(raumbuch => raumbuch[selector] === value);
  }

  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(Raumbuch, selector, value);
  }

  private async overrideLocalRaum(raum: Raum, aufmass: Aufmass): Promise<void> {
    const entries = raum.RaumbuchEntries.map(position => new Raumbuch(position));
    const first = entries[0];
    await this.controllerService.deleteData('Aufmass', 'AufmId', first.Aufmid);
    aufmass.RAUMBUCH = entries;
    await this.controllerService.setData('Aufmass', [aufmass]);
  }

  private async updateLocalFlaeche(flaeche: Raumbuch, aufmass: Aufmass, justDelete = false): Promise<void> {
    const raumbuchEntry = aufmass.RAUMBUCH.find(raumbuch => raumbuch.Uuid === flaeche.Uuid);
    if (raumbuchEntry) {
      await this.controllerService.deleteData('Aufmass', 'Uuid', aufmass.Uuid);
      aufmass.RAUMBUCH.splice(aufmass.RAUMBUCH.indexOf(raumbuchEntry), 1);

      if (!justDelete) {
        aufmass.RAUMBUCH.push(flaeche);
      }
    } else {
      await this.controllerService.deleteData('Aufmass', 'Uuid', aufmass.Uuid);
      aufmass.RAUMBUCH.push(flaeche);
    }
    await this.controllerService.setData('Aufmass', [aufmass]);
  }

  /**@description Erstellt einen Grundriss - überschreibt alle bisher getätigten messungen */
  async saveGrundriss(aufmass: Aufmass, userInfo: Userinfo, raum: Raum, silent = false): Promise<void> {
    const success = await this.pushToWebservice(userInfo, raum, silent);
    for (const entry of raum.RaumbuchEntries) entry.transferred = success;
    await this.overrideLocalRaum(raum, aufmass);
  }

  async saveSingleFlaeche(
    userInfo: Userinfo,
    entry: Raumbuch,
    raumbPos: RaumbPos,
    aufmass: Aufmass,
    silent = false
  ): Promise<Aufmass> {
    const mockRaum = new Raum([entry], null, raumbPos);
    const success = await this.pushToWebservice(userInfo, mockRaum, silent);
    entry.transferred = success;
    await this.updateLocalFlaeche(entry, aufmass);
    return aufmass;
  }

  /**@description Löscht eine Fläche und Rekursiv alle abzüge von dieser Fläche */
  async deleteSingleFlaeche(
    userInfo: Userinfo,
    raumbuchEntry: Raumbuch,
    aufmass: Aufmass,
    silent = false
  ): Promise<void> {
    const targetUrl = 'deleteRaumbuchEntry';
    await this.restService.returnData(userInfo, targetUrl, raumbuchEntry, silent);
    await this.updateLocalFlaeche(raumbuchEntry, aufmass, true);
  }

  private async pushToWebservice(userInfo: Userinfo, raum: Raum, silent: boolean): Promise<boolean> {
    const targetUrl = 'saveRaumbuchEntries';
    // Zur Sicherheit, dass wirklich alle eckigen Klammern rausgefiltert werden,
    // da das Handwerk die Mengen sonst nicht berechnen kann
    const entryArray: Raumbuch[] = [];
    if (raum.RaumbuchEntries)
      for (const entry of raum.RaumbuchEntries) {
        const tmpEntry = entry;
        tmpEntry.Aufmass = entry.Aufmass.replaceAll('[', '').replaceAll(']', '');
        entryArray.push(tmpEntry);
      }
    raum.RaumbuchEntries = entryArray;
    //
    const successData = await this.restService.returnData<boolean>(userInfo, targetUrl, raum, silent);
    return isNullOrUndefined(successData) ? false : successData;
  }

  async hasRaumMasskette(roomEntity: RaumbPos): Promise<boolean> {
    const allPositions = await this.getAllForRaum(roomEntity);
    let hasMasskette: boolean;
    hasMasskette = false;
    if (allPositions.length !== 0) hasMasskette = true;
    roomEntity.hasMasskette = hasMasskette;
    return hasMasskette;
  }

  async syncAllUntransferred(userInfo: Userinfo, aufmass: Aufmass): Promise<void> {
    const all = this.getAll(aufmass);
    const untransferred = all.filter(entry => entry.transferred === false);
    for (const entry of untransferred) {
      const mockRaumbPos = new RaumbPos(null);
      mockRaumbPos.Uuid = entry.raumbPosUuid;
      await this.saveSingleFlaeche(userInfo, entry, mockRaumbPos, aufmass, true);
    }
  }
}
