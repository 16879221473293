<div class="content-block bottom-padding" *ngIf="termin">
  <div class="form-block">
    <h2>Termindetails</h2>

    <div *ngIf="!allowEdit" class="bss-alert bss-alert-warning">Editierrecht nicht vorhanden</div>

    <div class="dx-card responsive-paddings">
      <fieldset [disabled]="!allowEdit || hasReference">
        <div class="dx-fieldset">
          <div class="fieldbox">
            <app-bss-plain-text-box [labelText]="'Art'" [value]="termin.terminArt"></app-bss-plain-text-box>
            <app-bss-plain-text-box
              *ngIf="termin.mitarbeiterName"
              [labelText]="'Monteur'"
              [value]="termin.mitarbeiterName"
            ></app-bss-plain-text-box>
            <app-bss-textbox [labelText]="'Betreff'" [(value)]="termin.caption"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Ort'" [(value)]="termin.location"></app-bss-textbox>
          </div>

          <app-bss-textarea [labelText]="'Beschreibung'" [(value)]="termin.message"></app-bss-textarea>

          <div style="width: 150px; margin-bottom: 10px">
            <app-bss-search-popup
              [buttonText]="'Kunde wählen'"
              [buttonStyle]="'bss-button block-button primary-button'"
              [headline]="'Wählen Sie einen Kunden aus'"
              [inputDataSource]="appointmentPerson"
              [disabled]="!allowEdit || hasReference"
              [customDisplayExpressions]="['NAME', 'STRASSE', 'PLZ', 'ORT']"
              [additionalSearchExpression]="['KU_NR']"
              (selectedValue)="selectedPerson = $event"
            >
            </app-bss-search-popup>
          </div>

          <div *ngIf="selectedPerson" class="dx-field">
            <div class="dx-field-label">Kunde</div>
            <div class="dx-field-value">
              <div class="card-detail">
                <app-address-card [address]="selectedPerson"></app-address-card>
              </div>
            </div>
          </div>

          <div *ngIf="termin.terminArt != 'Aufgabe'" class="fieldbox">
            <app-bss-datetime-box
              [labelText]="'Beginn'"
              [disabled]="!allowEdit || hasReference"
              [value]="termin.startDate"
              [type]="'datetime'"
              (outValue)="dateChanged($event, true)"
            ></app-bss-datetime-box>
            <app-bss-datetime-box
              [labelText]="'Ende'"
              [disabled]="!allowEdit || hasReference"
              [value]="termin.endDate"
              [type]="'datetime'"
              (outValue)="dateChanged($event, false)"
            ></app-bss-datetime-box>
          </div>

          <div *ngIf="termin.terminArt == 'Aufgabe'" class="fieldbox">
            <app-bss-datetime-box
              [labelText]="'Fälligkeit'"
              [disabled]="!allowEdit || hasReference"
              [value]="termin.startDate"
              (outValue)="dateChanged($event, true)"
            ></app-bss-datetime-box>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>

<dx-speed-dial-action
  *ngIf="!hasReference && allowEdit"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (onClick)="save()"
></dx-speed-dial-action>

<dx-speed-dial-action
  *ngIf="hasReference"
  icon="arrowright"
  class="add-button"
  [label]="navigationButtonTitle"
  [index]="1"
  (onClick)="navigateToReference()"
></dx-speed-dial-action>
