import { IndexedDBTypes } from './dbType';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

export class HWUmsatz extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWUmsatz') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') Kundennummer: string;
  @IndexedDBTypes.DataField('string') jahr: string;
  @IndexedDBTypes.DataField('string') monat: string;
  @IndexedDBTypes.DataField('string') netto: string;
  @IndexedDBTypes.DataField('Data') addedToIdb: Date;

  /**@description Wenn noch keiner vorhanden ist, wird der timestamp hinzugefügt */
  addTimeStampIfNotExisting() {
    if (!isNullOrUndefined(this.addedToIdb)) {
      return;
    }
    const now = new Date();
    this.addedToIdb = now;
  }

  //Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
    this.addTimeStampIfNotExisting();
  }

  static toString(): string {
    return 'HWUmsatz';
  }
}
