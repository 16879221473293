<div class="content-block bottom-padding" *ngIf="anlage">
  <div class="form-block">
    <h2>Anlagendetails</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box [labelText]="'Nummer'" [value]="anlage.ANLAGE"></app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Suchbegriff'" [value]="anlage.Anlagendaten.SUCH">
          </app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Projekt'" [value]="anlage.PROJEKT"></app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Vertrag'" [value]="anlage.Anlagendaten.VERTRAG">
          </app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Anlagentyp'" [value]="anlage.Anlagendaten.Anlagentyp">
          </app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Messblatt'" [value]="anlage.Anlagendaten.REPORT">
          </app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Seriennummer'" [value]="anlage.Anlagendaten.SERIENNR">
          </app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Kunde'" [value]="anlage.Anlagendaten.Kundenobjekt.SUCH">
          </app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'E-Mail'"
            [value]="anlage.Anlagendaten.Kundenobjekt.GLOBEMAIL"
            [buttonIcon]="'message'"
            [buttonEnabled]="anlage?.Anlagendaten?.Kundenobjekt?.GLOBEMAIL !== ''"
          >
          </app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Telefon'"
            [value]="anlage.Anlagendaten.Kundenobjekt.TEL"
            [buttonIcon]="'tel'"
            [buttonEnabled]="anlage?.Anlagendaten?.Kundenobjekt?.TEL !== ''"
          >
          </app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Mobil / Privat'"
            [value]="anlage.Anlagendaten.Kundenobjekt.FUNK_PRIV"
            [buttonIcon]="'tel'"
            [buttonEnabled]="anlage?.Anlagendaten?.Kundenobjekt?.FUNK_PRIV !== ''"
          ></app-bss-plain-text-box>
        </div>
      </div>
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">Weitere Informationen</div>

        <app-bss-toggle-textarea [buttonText]="'Bemerkung'" [(value)]="anlage.BESCHR" [disabled]="true">
        </app-bss-toggle-textarea>
        <app-bss-toggle-textarea
          [buttonText]="'Arbeitsbeschreibung'"
          [(value)]="anlage.ABESCHR"
          [disabled]="true"
        ></app-bss-toggle-textarea>

        <app-bss-button-link
          [title]="'Anlagenbeschreibung'"
          [customIcon]="'assets\\icons\\dreieck.svg'"
          [customIconClass]="showBeschreibung ? 'rotated180 dreieck' : ''"
          [secondCustomIcon]="!!anlage.Anlagenbeschreibung ? 'assets/icons/info.png' : ''"
          (click)="showBeschreibung = !showBeschreibung"
        ></app-bss-button-link>
        <div *ngIf="showBeschreibung" class="beschreibungClass" [innerHTML]="anlage.Anlagenbeschreibung"></div>

        <div class="linkBox">
          <app-bss-button-link [title]="'Standort'" [path]="'wartungsanlage/' + anlage.UUID + '/standort'">
          </app-bss-button-link>
          <app-bss-button-link [title]="'Termine'" [path]="'wartungsanlage/' + anlage.UUID + '/termine'">
          </app-bss-button-link>
          <app-bss-button-link
            *ngIf="anlage.Datenblatt"
            [title]="'Messungen anzeigen'"
            [path]="'wartungsanlage/' + anlage.UUID + '/messungen'"
          ></app-bss-button-link>
          <app-bss-button-link
            *ngIf="anlage.Datenblatt"
            [title]="'Technische Daten 1'"
            [path]="'wartungsanlage/' + anlage.UUID + '/technische-daten-1'"
          ></app-bss-button-link>
          <app-bss-button-link
            *ngIf="anlage.Datenblatt"
            [title]="'Technische Daten 2'"
            [path]="'wartungsanlage/' + anlage.UUID + '/technische-daten-2'"
          ></app-bss-button-link>
          <app-bss-button-link
            *ngIf="fileUploadPossible"
            [title]="'Medien'"
            [path]="'medien/Anlage/' + anlage.UUID"
            [anzahl]="currentFilesAmount"
          ></app-bss-button-link>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-speed-dial-action
  *ngIf="fileUploadPossible"
  label="Medium hinzufügen"
  icon="photo"
  (onClick)="navigateTo('medien/Anlage/' + anlage.UUID + '/upload')"
  [index]="2"
>
</dx-speed-dial-action>

<dx-speed-dial-action
  [visible]="messungEnabled()"
  icon="add"
  class="add-button"
  label="Neue Messung"
  [index]="1"
  (onClick)="navigateTo('wartungsanlage/' + anlage.UUID + '/messung/neu')"
></dx-speed-dial-action>
