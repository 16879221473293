import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { QuickButtonIcons } from '../../../config/Konstanten';

@Component({
  selector: 'app-bss-plain-text-box',
  templateUrl: './bss-plain-text-box.component.html',
  styleUrls: ['./bss-plain-text-box.component.scss'],
})
export class BssPlainTextBoxComponent implements OnInit {
  @Input() labelText;
  @Input() value;
  @Input() valueArray;
  @Input() buttonIcon: QuickButtonIcons;
  @Input() buttonEnabled = true;
  @Input() hintText: string;
  @Input() hintClass = 'bss-alert-danger';
  @Output() buttonClick = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  btnClicked(icon?: QuickButtonIcons, value?: string): void {
    this.buttonClick.emit(true);
    if (!icon) return;
    switch (icon) {
      case 'globe':
        this.goToHomepage(value);
        break;
      case 'tel':
        this.callPhone(value);
        break;
      case 'message':
        this.mailTo(value);
        break;
      case 'map':
        break;
      case 'info':
        break;
    }
  }

  private callPhone(telNumber: string): void {
    if (isNullOrUndefinedOrEmptyString(telNumber)) return;
    window.open(`tel:${telNumber}`);
  }

  private mailTo(mailAdress: string): void {
    if (isNullOrUndefinedOrEmptyString(mailAdress)) return;
    window.open(`mailto:${mailAdress}`);
  }

  private goToHomepage(pageUrl: string): void {
    if (isNullOrUndefinedOrEmptyString(pageUrl)) return;
    window.open(`https://${pageUrl}`);
  }

  private openMaps(adress: string): void {
    window.open(`https://www.google.com/maps/search/?api=1&query=${adress}_blank`);
  }
}
