<div class="content-block">
  <div class="form-block">
    <h2>Standort</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box [labelText]="'Name'" [value]="anlagenStandort?.NAME"></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Straße'"
            [value]="anlagenStandort?.STRASSE"
            [buttonIcon]="'map'"
            (buttonClick)="openMaps(anlagenStandort?.StandortAnschrift)"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Plz'" [value]="anlagenStandort?.PLZ"></app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Ort'" [value]="anlagenStandort?.ORT"></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Standort1'"
            [value]="anlagenStandort?.STANDORT1"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Standort2'"
            [value]="anlagenStandort?.STANDORT2"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box [labelText]="'Telefon'" [value]="anlagenStandort?.TEL"></app-bss-plain-text-box>
        </div>
      </div>
    </div>
  </div>
</div>
