import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';

export class Wartungsrights {
  inputMeasuredValues = true;
  createOrder = true;
  handleOrder = true;

  constructor(wartungsRights: Wartungsrights) {
    assignIfPropertyExsits(this, wartungsRights);
  }

  restrictEverything() {
    this.inputMeasuredValues = false;
    this.createOrder = false;
    this.handleOrder = false;
  }

  allowEverything() {
    this.inputMeasuredValues = true;
    this.createOrder = true;
    this.handleOrder = true;
  }
}
