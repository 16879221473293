import { Right } from './Right';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

export class Role {
  roleName = 'Undefinierte Rolle';
  Right: Right;

  constructor(right: Right, roleName?: string) {
    this.Right = right;
    if (isNullOrUndefined(roleName)) {
      return;
    }
    this.roleName = roleName;
  }
}
