import { IndexedDBTypes } from './dbType';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

export class HWContactperson extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWContactperson') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') KuNr: string;
  @IndexedDBTypes.DataField('string') TkId: number;
  @IndexedDBTypes.DataField('string') HauptAnsprechpartner: boolean;
  @IndexedDBTypes.DataField('string') Anrede: string;
  @IndexedDBTypes.DataField('string') Name: string;
  @IndexedDBTypes.DataField('string') Telefon: string;
  @IndexedDBTypes.DataField('string') Fax: string;
  @IndexedDBTypes.DataField('string') Mobil: string;
  @IndexedDBTypes.DataField('string') EMail: string;
  @IndexedDBTypes.DataField('string') Position: string;
  @IndexedDBTypes.DataField('string') Abteilung: string;
  @IndexedDBTypes.DataField('string') Zusatz: string;
  @IndexedDBTypes.DataField('Data') addedToIdb: Date;

  /**@description Wenn noch keiner vorhanden ist, wird der timestamp hinzugefügt */
  addTimeStampIfNotExisting() {
    if (!isNullOrUndefined(this.addedToIdb)) {
      return;
    }
    const now = new Date();
    this.addedToIdb = now;
  }

  //Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
    this.addTimeStampIfNotExisting();
  }

  static toString(): string {
    return 'HWContactperson';
  }
}
