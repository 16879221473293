import { UUID } from 'angular2-uuid';
import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { RVorlage } from '../..';
import { Aufmass } from '../../repository/Aufmass';
import { AufmassKonstrukt } from './AufmassKonstrukt';
import { BssDxTreeviewNode } from '../BssDxTreeviewNode';
import { AufmassStrecke } from './AufmassStrecke';
import { Bauelement } from './Bauelement';
import { UuidEntity } from '../../../interfaces';

export class RaumbPos extends BssDxTreeviewNode implements UuidEntity {
  AufmId: string = null;
  Stw_ID: number = null;
  Wng_ID: number = null;
  Raumb_ID: number = null;
  Rpos_ID: number = null;
  NrAufmId: number = null; // laut Olaf - nächste LineId im Raumbuch???
  Bezeich: string = null;
  Uuid: string = null;
  /**Gehört nicht zur Handwerkstabelle */
  AufmassKonstrukt: AufmassKonstrukt = null;
  transferred: boolean = null;
  icon: string;
  hasMasskette: boolean;

  constructor(raumbPos: RaumbPos) {
    super();
    assignIfPropertyExsits(this, raumbPos);

    // Informationen für die Treenode verwendung zuweisen
    this.text = this.Bezeich;
  }
  getUuid(): string {
    return this.Uuid;
  }

  /**@description Füllt die meisten informationen je nach ebene entweder anhand eines Sibbling-Knotens oder eines Parent Knotens */
  fillNewFromRelative(parent: RaumbPos, parentIsRaumbuch: boolean, aufmass: Aufmass): void {
    const { maxStockwerkId, maxWohnungId, maxraumId } = this.getMaxIds(parent, parentIsRaumbuch);
    this.buildClearBasedata(aufmass, parent);
    if (parentIsRaumbuch) {
      this.buildNewEtage(parent, maxStockwerkId);
      return;
    }
    if (parent.isEtage()) {
      this.buildNewWohnung(parent, maxWohnungId);
      return;
    }
    if (parent.isWohnung()) {
      this.buildNewRoom(parent, maxraumId);
      return;
    }
  }

  private buildClearBasedata(aufmass: Aufmass, parent: RaumbPos) {
    this.Bezeich = '';
    this.text = this.Bezeich;
    this.Uuid = UUID.UUID();
    this.items = [];
    this.NrAufmId = parent.NrAufmId;
    this.parentId = parent.Uuid;
    this.AufmId = aufmass.AufmId;
  }

  private buildNewRoom(wohnung: RaumbPos, maxraumId: number) {
    this.Wng_ID = wohnung.Wng_ID;
    this.Stw_ID = wohnung.Stw_ID;
    this.Raumb_ID = maxraumId + 1;
    this.level = 1;
  }

  private buildNewWohnung(etage: RaumbPos, maxWohnungId: number) {
    this.Stw_ID = etage.Stw_ID;
    this.Raumb_ID = etage.Raumb_ID;
    this.Wng_ID = maxWohnungId + 1;
    this.level = 2;
  }

  private buildNewEtage(raumBuch: RaumbPos, maxStockwerkId: number) {
    const siblingEtage = raumBuch?.items[0] as RaumbPos;
    this.Stw_ID = maxStockwerkId + 1;
    this.Wng_ID = 0;
    this.level = 3;
    this.Raumb_ID = 0;
    if (!siblingEtage) {
      this.NrAufmId = 0;
      return;
    }
    this.NrAufmId = siblingEtage.NrAufmId;
  }

  /**@description Holt die höchsten Ids der jeweiligen Ebene */
  private getMaxIds(parent: RaumbPos, parentIsRaumbuch: boolean) {
    const allSiblings = parent.items as RaumbPos[];
    if (allSiblings.length === 0) return { maxStockwerkId: parentIsRaumbuch ? -1 : 0, maxWohnungId: 0, maxraumId: 0 };
    const stockwerkIds = allSiblings.flatMap(room => room.Stw_ID);
    const wohnungsIds = allSiblings.flatMap(room => room.Wng_ID);
    const raumIds = allSiblings.flatMap(room => room.Raumb_ID);
    const maxStockwerkId = Math.max(...stockwerkIds);
    const maxWohnungId = Math.max(...wohnungsIds);
    const maxraumId = Math.max(...raumIds);
    return { maxStockwerkId, maxWohnungId, maxraumId };
  }

  assignNextRposid(nextPosLineId: number): void {
    this.Rpos_ID = nextPosLineId;
  }

  isEtage(): boolean {
    return this.Raumb_ID === 0 && this.Wng_ID === 0;
  }

  /**
   * @description Der Begriff der Wohnung ist hier kritisch - in einem Mehrfamilienhaus passt diese Relation, in einem Einfamilienhaus
   * entspricht es der Ebene unter der Etage
   */
  isWohnung(): boolean {
    return this.Raumb_ID === 0 && this.Wng_ID !== 0;
  }

  isRaum(): boolean {
    return !this.isEtage() && !this.isWohnung();
  }

  findAccordingEtage(positionen: RaumbPos[]): RaumbPos {
    const raumbuchPositionen = positionen.slice();
    return raumbuchPositionen.find(position => position.isEtage() && this.Stw_ID === position.Stw_ID);
  }

  findAccordingWohnung(positionen: RaumbPos[]): RaumbPos {
    if (this.level >= 2) return null;
    const raumbuchPositionen = positionen.slice();
    return raumbuchPositionen.find(
      position => position.isWohnung() && this.Stw_ID === position.Stw_ID && this.Wng_ID === position.Wng_ID
    );
  }

  findAccordingRaum(positionen: RaumbPos[]): RaumbPos {
    const raumbuchPositionen = positionen.slice();
    return raumbuchPositionen.find(position => position.isRaum() && this.parentId === position.Uuid);
  }

  /**@description Erstellt mithilfe der Raumbuchvorlage und den Etagen eine */
  createRaumbuchAsRaumbPos(roomEntity: RVorlage, etagen: RaumbPos[]): void {
    this.items = etagen;
  }

  /**@description Löscht die Informationen der Treenode, da sie nciht zur eigentlichen RaumbPos gehören */
  deleteNodeInformation(): void {
    delete this.id;
    delete this.text;
    delete this.items;
    delete this.parentId;
    delete this.selected;
    delete this.expanded;
    delete this.level;
  }

  /**@description Baut das Objekt zurück,indem es die konstruktoren nochmal durchläuft und anschließend werte zuweist */
  reconstructAufmassKonstrukt(): AufmassKonstrukt {
    const pictureData = this.AufmassKonstrukt;
    if (!pictureData) return null;
    const reconstruction = new AufmassKonstrukt(null, null, null, null);
    assignIfPropertyExsits(reconstruction, pictureData);
    const aufmassStrecken: AufmassStrecke[] = [];
    for (const strecke of reconstruction?.AufmassStrecken) {
      const cleanStrecke = new AufmassStrecke(null, null, null);
      assignIfPropertyExsits(cleanStrecke, strecke);
      aufmassStrecken.push(cleanStrecke);
    }
    const bauelemente: Bauelement[] = [];
    for (const bauelement of reconstruction?.Bauelemente) {
      const cleanElement = new Bauelement(null, null, null, null, null, null);
      assignIfPropertyExsits(cleanElement, bauelement);
      bauelemente.push(cleanElement);
    }
    reconstruction.AufmassStrecken = aufmassStrecken;
    reconstruction.Bauelemente = bauelemente;
    reconstruction.Raumhoehe = this.AufmassKonstrukt.Raumhoehe;

    const bodenFleache = new AufmassStrecke(null, null, null);
    assignIfPropertyExsits(bodenFleache, this.AufmassKonstrukt.Bodenflaeche);
    reconstruction.Bodenflaeche = bodenFleache;

    const deckenFleache = new AufmassStrecke(null, null, null);
    assignIfPropertyExsits(deckenFleache, this.AufmassKonstrukt.Deckenflaeche);
    reconstruction.Deckenflaeche = deckenFleache;
    return reconstruction;
  }
}
