import { Component, OnInit } from '@angular/core';
import { Anlagentermin, HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-system-appointment',
  templateUrl: './system-appointment.component.html',
  styleUrls: ['./system-appointment.component.scss'],
})
export class SystemAppointmentComponent implements OnInit {
  anlage: HWAnlage;
  anlagenTermin: Anlagentermin;

  constructor(private routingService: RoutingService, private wartungsprojectsService: WartungsprojectsService) {}

  async ngOnInit(): Promise<void> {
    await this.loadAnlage();
    this.anlagenTermin = this.anlage.Anlagentermin;
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.wartungsprojectsService.findOneAnlageBy('UUID', anlageUUID);
  }
}
