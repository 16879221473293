import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DialogService } from './dialog.service';
import { Allocation, Userinfo } from 'libs/shared/src/lib/entities';
import moment from 'moment';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { StateService } from 'apps/handwerkPWA/src/app/services/globalServices/state.service';
import { topService } from 'apps/handwerkPWA/src/app/config/Konstanten';
import CryptoES from 'crypto-es';
import {
  ConnectionDialogues,
  ConnectionService,
} from 'apps/handwerkPWA/src/app/services/globalServices/connection.service';
import { ControllerService } from 'apps/handwerkPWA/src/app/services/globalServices/controller.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(
    private httpClient: HttpClient,
    private dialogService: DialogService,
    private globalSettingService: HWGolbalsettingService,
    private connectionService: ConnectionService,
    private controllerService: ControllerService,
    private stateService: StateService
  ) {}

  /**
   * @description         Holt eine gültige allocation vom broker
   * @param userInfo:        userinfo in der die uuid des handwerks steht
   * @returns             Userinfo die die currentallocation enthält
   */
  public async updateAllocationInUserinfo(userInfo: Userinfo): Promise<Userinfo> {
    const passkey = 'bssblue';
    const brokerkey = 'bssblue';
    const completeBrokerURL = `https://broker1.bssservices.de/broker/rest/request_allocation?uuid=${userInfo.uuid}&pass_key=${passkey}&broker_key=${brokerkey}`;
    const allocationData = await this.httpGetWithURL<Allocation>(completeBrokerURL);
    const allocation = new Allocation(allocationData);
    userInfo.currentAllocation = allocation;
    const updateUserinfoInIdb = userInfo?.user !== 'guiUser';
    if (updateUserinfoInIdb) await this.globalSettingService.setEntity(userInfo, 'Userinfo');
    return userInfo;
  }

  private async startDataExchange(
    url: string,
    userInfo: Userinfo,
    data: any
  ): Promise<HttpResponse<null> | HttpErrorResponse> {
    const password = userInfo.pin; // vor version 3.0.2.12 fragten die 3 endpunkten das handwerkspasswort statt dem des monteur ab
    const allocation = userInfo.currentAllocation;
    const authorizationUrl = `https://${allocation.allocation_ipv4}:${allocation.allocation_portv4}${url}`;
    const authorizationHeader = CryptoES.HmacMD5(authorizationUrl, password);
    const authorizationBase64 = authorizationHeader.toString(CryptoES.enc.Base64).slice(0, -2);
    const targetUrl = allocation.turnProxyUrl + url;
    let response: HttpResponse<null>;
    const httpHeaders = new HttpHeaders({
      AppAuthorization: authorizationBase64,
      RequestOrigin: 'handwerksPWA',
      port: allocation.allocation_portv4?.toString(),
      Username: userInfo.monteur, // Username und Monteur sind scheinbar wirklich beides die Monteurpnr - zumindest geht so überall die authentication
      Mandant: userInfo.mandant,
      DeviceToken: userInfo.Device.Devtoken,
      Monteur: userInfo.monteur,
      'Content-Type': 'application/json',
    });
    try {
      response = await new Promise((resolve, reject) => {
        this.httpClient
          .post<any>(targetUrl, data, {
            headers: httpHeaders,
            observe: 'events',
            reportProgress: true,
          })
          .subscribe(
            httpEvent => {
              if (httpEvent.type === HttpEventType.Response) {
                resolve(httpEvent);
              }
            },
            error => {
              reject(error);
            }
          );
      });
    } catch (err) {
      return new HttpErrorResponse(err);
    }
    return response;
  }

  private async httpGetWithURL<Type>(url: string): Promise<Type> {
    let result: Type;
    try {
      result = await this.httpClient.get<Type>(url).toPromise();
    } catch (e) {
      console.error('HTTP-Fehler: \n\r' + JSON.stringify(e));
    }
    return result;
  }

  /**@description Sendet Daten zum Server und erhält passende Antwort je nach Endpunkt und gesendeten Daten */
  private async sendGetDataToServer<Type>(
    url: string,
    dataToSend: any,
    silent: boolean,
    userInfo: Userinfo
  ): Promise<Type> {
    if (!silent) {
      const online = await this.connectionService.CheckOnline(ConnectionDialogues.PushData);
      if (!online) return null;
    }

    const requestInitData = JSON.stringify(dataToSend);
    const response = (await this.startDataExchange(url, userInfo, requestInitData)) as HttpResponse<null>;
    // Erfolg
    if (response.status === 200) return isNullOrUndefined(response.body) ? null : response.body;
    // Allokation neu anfragen und erneut versuchen
    if (response.status === 418 || response.status === 0) await this.updateAllocationInUserinfo(userInfo);
    const retryResponse = (await this.startDataExchange(url, userInfo, requestInitData)) as HttpResponse<null>;
    if (retryResponse.status !== 200) await this.createHttpErrorMessage(url, retryResponse, silent);
    return isNullOrUndefined(retryResponse.body) ? null : retryResponse.body;
  }

  /**@description Erstellt je nach code eine Fehlermessage */
  private async createHttpErrorMessage(
    url: string,
    responseFromServerRetry: HttpResponse<null> | HttpErrorResponse,
    silent: boolean
  ) {
    const statuscode = responseFromServerRetry.status;
    if (silent && statuscode !== 401) return;
    url = url?.replace('/TopService.svc/', '');
    if (url?.includes('Email')) return;
    if (statuscode === 401) {
      // fall mobilKennwort falsch
      await this.dialogService.openWaitingConfirmDialog(
        'Authentifizierungsproblem!',
        statuscode + '\n' + 'Das mobile Kennwort ist ungültig.',
        'OK'
      );

      await this.controllerService.clearAllStores();
      // Damit in jedem Fall zurückGeroutet werden kann
      localStorage.clear();
      this.stateService.loggedIn.next(false);
      location.reload();
      return;
    }
    const failedTurnAnswer = statuscode === 418;
    if (url?.includes('checklogin') || failedTurnAnswer) {
      await this.dialogService.openWaitingConfirmDialog(
        'Verbindungsproblem!',
        'Prüfen Sie Ihre Internetverbindung und stellen sicher, dass der Webservice korrekt ausgeführt wird.',
        'OK'
      );
      return;
    }
    if (url?.includes('OfflinePositions') && failedTurnAnswer) {
      await this.dialogService.openWaitingConfirmDialog(
        'Synchronisierungsproblem!',
        'Die Synchronisation der Offline-Positionen kann nicht durchgeführt werden, weil es ein Problem' +
          ' mit der Verbindung gibt oder die Menge der Positionen zu groß ist.',
        'OK'
      );
      return;
    }
    await this.dialogService.openWaitingConfirmDialog(
      `HTTP-Error: ${statuscode}`,
      `Beim Aufruf von ${url} trat ein Fehler auf.`,
      'OK'
    );
  }

  /**@description Funktion um Daten aus dem Webservice zu holen und direkt zu übergeben */
  public async returnData<Type>(userInfo: Userinfo, url: string, data?: any, silent?: boolean): Promise<Type> {
    try {
      const serviceUrl = topService + url;
      return await this.sendGetDataToServer<Type>(serviceUrl, data, silent, userInfo);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**@description Gibt, falls vorhanden, den Last Modified header einer Url als Date zurück */
  public async httpLastModified(targetUrl: string): Promise<Date> {
    const errorDate = new Date('1701-01-31T01:01:01');
    if (await this.connectionService.CheckOnline()) {
      return errorDate;
    }
    let response: HttpResponse<null>;
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
    try {
      response = await new Promise((resolve, reject) => {
        this.httpClient
          .head<any>(targetUrl, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true,
          })
          .subscribe(
            event => {
              resolve(event);
            },
            error => {
              reject(error);
            }
          );
      });
    } catch (err) {
      return errorDate; // fallback mit definitiv älterem datum
    }
    const lastModified = response.headers.get('last-modified');
    const lastModifiedDate = moment(lastModified, 'DD.MM.YYYY HH:mm:ss', 'de')?.toDate();
    return lastModifiedDate;
  }
}
