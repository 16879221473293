export function readFileAsync(file: any): Promise<string> {
  const result = new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (fileEvent: any) => {
      if (fileEvent && fileEvent.target?.result && typeof fileEvent.target.result === 'string') {
        resolve(fileEvent.target.result);
        return;
      }
      reject(fileEvent);
    };
    reader.onerror = (errorEvent: any) => {
      reject(errorEvent);
    };
    reader.readAsDataURL(file);
  });
  return result;
}

export declare const qrcode: {
  callback: (result: string | Error) => void;
  decode: (imageSource: string) => void;
};

export function qrImageToString(fileData: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    qrcode.callback = (result: string | Error) => {
      if (typeof result === 'string') {
        resolve(result);
        return;
      }
      reject(result);
    };
    qrcode.decode(fileData);
  });
}
