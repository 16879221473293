import { assignIfPropertyExsits, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { BaseDocumentPosition } from 'apps/handwerkPWA/src/app/interfaces';

export class Steuer {
  Id: string = null;
  Bez: string = null;
  Steuersatz: number = null;
  New_id: string = null;
  Stdkonto: string = null;
  St_Schl: string = null;
  Stkonto: string = null;
  Ktoskont: string = null;
  Ktomind: string = null;
  Uuid: string = null;
  EndsOn: string = null;
  EndsOnDate: Date;

  constructor(objectData: object) {
    assignIfPropertyExsits(this, objectData);
    this.EndsOnDate = this.parseDateFromDateString(this.EndsOn);
  }

  /**@description Falls ein Valides Datum vorhanden ist,wird dies zu einem Dateobjekt konvertiert */
  private parseDateFromDateString(endsOn: string): Date | null {
    const dateSplit = endsOn?.split('T');
    const dateString = dateSplit?.length === 2 ? dateSplit[0] : null;
    if (dateString) return new Date(dateString);
    return null;
  }
}

/**@description Guckt ob der Aktuelle Steuerschlüssel gültig ist und gibt dann dessen wert zurück, ansonsten wird der prozess mit dem jeweils nächstgültigen probiert */
export function findSteuerInUse(Steuern: Steuer[], steuerSchluesselId = '01'): Steuer {
  const usedSteuer = Steuern.find(steuer => steuer.Id === steuerSchluesselId);
  if (isNullOrUndefined(usedSteuer)) {
    const newDeaultSteuer = new Steuer(null);
    newDeaultSteuer.Steuersatz = 19;
    newDeaultSteuer.St_Schl = '01';
    return newDeaultSteuer;
  }
  const usedSteuerEndDate = usedSteuer.EndsOnDate;
  if (isNullOrUndefined(usedSteuerEndDate)) return usedSteuer;
  const now = new Date();
  const schlueselStillValid = now < usedSteuerEndDate;
  const nextSteuerSchluessel = usedSteuer.New_id;
  if (schlueselStillValid || isNullOrUndefined(nextSteuerSchluessel)) return usedSteuer;
  return findSteuerInUse(Steuern, nextSteuerSchluessel);
}

export function getSteuerBezeichnungOfUsedSteuern(Steuern: Steuer[], items: BaseDocumentPosition[]): string {
  const steuerSchluessel = items?.map(item => item?.getSteuerSchluessel(Steuern));
  const steuerSchluesselSet = new Set(steuerSchluessel);
  const usedSteuern = Steuern?.filter(steuer => steuerSchluesselSet?.has(steuer.Id));
  if (usedSteuern?.length > 0) return usedSteuern[0].Bez;
  return 'Umsatzsteuer 19 %';
}
