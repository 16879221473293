<h2 class="content-block">Umsatz</h2>

<div class="content-block">
  <app-bss-list
    [id]="'address-sales'"
    [inputDatasource]="sales"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine Umsätze vorhanden'"
  ></app-bss-list>
</div>
