import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import {
  LoginComponent,
  AddressesIndexComponent,
  AddressEditComponent,
  AddressVacantPositionsComponent,
  AddressSalesComponent,
  AddressContactPersonComponent,
  AddressContactComponent,
  ServiceOrderEditComponent,
  ServiceOrdersIndexComponent,
  ExploreComponent,
  SystemEditComponent,
  SystemLocationComponent,
  SystemAppointmentComponent,
  SystemTechnicalData1Component,
  SystemTechnicalData2Component,
  MeasurementNewComponent,
  MeasurementsIndexComponent,
  SystemsIndexComponent,
  FilesComponent,
  MessagesIndexComponent,
  MessageNewComponent,
  SettingsComponent,
  RepairOrderEditComponent,
  RepairOrderIndexComponent,
  OrderItemsIndexComponent,
  OrderItemEditComponent,
  RepairOrderNewComponent,
  OrderFinalizeComponent,
  OrderItemNewComponent,
  HomeComponent,
  CalendarComponent,
  AppointmentEditComponent,
  ImpressumComponent,
  AppointmentsIndexComponent,
  ObjectAddressEditComponent,
  SupportingDocumentsIndexComponent,
  SupportingDocumentShowComponent,
  ObjectAddressesIndexComponent,
  AufmassIndexComponent,
  RaumbuchEditComponent,
  RaumEditComponent,
  AufmassNewComponent,
} from './pages';

import { ReloadComponent } from 'libs/shared/src/lib/components/reload/reload.component';
import { RouteGuardService } from './route-guard.service';
import { AufmassMessungComponent } from './pages/measurement/aufmass-messung/aufmass-messung.component';
import { AufmassDrawComponent } from './pages/measurement/aufmass-draw/aufmass-draw.component';
import { MassketteEditComponent } from './pages/measurement/masskette-edit/masskette-edit.component';

const routes: Routes = [
  {
    path: 'Login/UUID/:uuid/Mandant/:mandant/Employeenumber/:employeeNumber/Branding/:branding',
    component: LoginComponent,
    canActivate: [RouteGuardService],
  },
  { path: 'Login', component: LoginComponent, canActivate: [RouteGuardService] },
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  { path: 'startseite', component: HomeComponent, canActivate: [RouteGuardService] },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'entdecken', component: ExploreComponent, canActivate: [RouteGuardService] },
  { path: 'einstellungen', component: SettingsComponent, canActivate: [RouteGuardService] },
  { path: 'reload/:routeToReload', component: ReloadComponent, pathMatch: 'prefix', canActivate: [RouteGuardService] },
  {
    path: 'reload/:routeToReload1/:routeToReload2',
    component: ReloadComponent,
    pathMatch: 'prefix',
    canActivate: [RouteGuardService],
  },
  {
    path: 'reload/:routeToReload1/:routeToReload2/:routeToReload3',
    component: ReloadComponent,
    pathMatch: 'prefix',
    canActivate: [RouteGuardService],
  },
  {
    path: 'reload/:routeToReload1/:routeToReload2/:routeToReload3/:routeToReload4',
    component: ReloadComponent,
    pathMatch: 'prefix',
    canActivate: [RouteGuardService],
  },

  /** Addresses */
  { path: 'adressen', component: AddressesIndexComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/neu/repairOrder', component: AddressEditComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/neu', component: AddressEditComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/:guid/edit', component: AddressEditComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/:guid/objektadressen', component: ObjectAddressesIndexComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/:guid/offene-posten', component: AddressVacantPositionsComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/:guid/umsatz', component: AddressSalesComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/:guid/ansprechpartner', component: AddressContactPersonComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/:guid/kontakte', component: AddressContactComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/:guid/belege', component: SupportingDocumentsIndexComponent, canActivate: [RouteGuardService] },
  { path: 'adresse/:guid/termine', component: AppointmentsIndexComponent, canActivate: [RouteGuardService] },
  { path: 'objektadresse/neu/:kundennummer', component: ObjectAddressEditComponent, canActivate: [RouteGuardService] },
  { path: 'objektadresse/:guid/edit', component: ObjectAddressEditComponent, canActivate: [RouteGuardService] },

  /** Medien */
  { path: 'medien/:type/:guid/upload/:secondGuid', component: FilesComponent, canActivate: [RouteGuardService] },
  { path: 'medien/:type/:guid/upload/', component: FilesComponent, canActivate: [RouteGuardService] },
  { path: 'medien/:type/:guid', component: FilesComponent, canActivate: [RouteGuardService] },
  { path: 'medien/:type/:guid/:secondGuid', component: FilesComponent, canActivate: [RouteGuardService] },

  /** Termine */
  { path: 'termin/neu/:date/:kundennummer', component: AppointmentEditComponent, canActivate: [RouteGuardService] },
  { path: 'termin/neu/:date', component: AppointmentEditComponent, canActivate: [RouteGuardService] },
  { path: 'termin/:id', component: AppointmentEditComponent, canActivate: [RouteGuardService] },

  /** Supporting Documents */
  { path: 'belege', component: SupportingDocumentsIndexComponent, canActivate: [RouteGuardService] },
  { path: 'beleg/:name', component: SupportingDocumentShowComponent, canActivate: [RouteGuardService] },

  /** Kalendar */
  { path: 'kalender', component: CalendarComponent, canActivate: [RouteGuardService] },

  /** Messages */
  { path: 'nachrichten', component: MessagesIndexComponent, canActivate: [RouteGuardService] },
  { path: 'nachricht/neu', component: MessageNewComponent, canActivate: [RouteGuardService] },
  { path: 'nachricht/neu/:guid', component: MessageNewComponent, canActivate: [RouteGuardService] },

  /** Reparaturaufträge */
  { path: 'reparaturauftraege', component: RepairOrderIndexComponent, canActivate: [RouteGuardService] },
  { path: 'reparaturauftrag/:guid/edit', component: RepairOrderEditComponent, canActivate: [RouteGuardService] },
  { path: 'reparaturauftrag/neu', component: RepairOrderNewComponent, canActivate: [RouteGuardService] },
  {
    path: 'reparaturauftrag/neu/:address_type/:guid',
    component: RepairOrderNewComponent,
    canActivate: [RouteGuardService],
  },

  /** Aufträge */
  { path: 'auftragpositionen/:type/:id', component: OrderItemsIndexComponent, canActivate: [RouteGuardService] },
  {
    path: 'auftragpositionen/:type/:id/hinzufuegen',
    component: OrderItemNewComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: 'auftragpositionen/:type/:id/:position_uuid',
    component: OrderItemEditComponent,
    canActivate: [RouteGuardService],
  },
  { path: 'auftragsabschluss/:type/:id', component: OrderFinalizeComponent, canActivate: [RouteGuardService] },

  /** W&S */
  { path: 'wartungsauftraege', component: ServiceOrdersIndexComponent, canActivate: [RouteGuardService] },
  { path: 'wartungsauftrag/:uuid/edit', component: ServiceOrderEditComponent, canActivate: [RouteGuardService] },
  { path: 'wartungsanlagen', component: SystemsIndexComponent, canActivate: [RouteGuardService] },
  { path: 'wartungsanlage/:uuid/edit', component: SystemEditComponent, canActivate: [RouteGuardService] },
  { path: 'wartungsanlage/:uuid/standort', component: SystemLocationComponent, canActivate: [RouteGuardService] },
  { path: 'wartungsanlage/:uuid/termine', component: SystemAppointmentComponent, canActivate: [RouteGuardService] },
  {
    path: 'wartungsanlage/:uuid/technische-daten-1',
    component: SystemTechnicalData1Component,
    canActivate: [RouteGuardService],
  },
  {
    path: 'wartungsanlage/:uuid/technische-daten-2',
    component: SystemTechnicalData2Component,
    canActivate: [RouteGuardService],
  },
  { path: 'wartungsanlage/:uuid/messungen', component: MeasurementsIndexComponent, canActivate: [RouteGuardService] },
  { path: 'wartungsanlage/:uuid/messung/neu', component: MeasurementNewComponent, canActivate: [RouteGuardService] },

  /** Aufmass */
  { path: 'aufmasse', component: AufmassIndexComponent, canActivate: [RouteGuardService] },
  { path: 'aufmass/neu/:kundeUuid', component: AufmassNewComponent, canActivate: [RouteGuardService] },
  { path: 'aufmass/neu', component: AufmassNewComponent, canActivate: [RouteGuardService] },
  { path: 'aufmass/editierung/:uuid', component: AufmassNewComponent, canActivate: [RouteGuardService] },
  { path: 'aufmass/:uuid/raumbuch', component: RaumbuchEditComponent, canActivate: [RouteGuardService] },
  { path: 'aufmass/:uuid/raum/:raumid', component: RaumEditComponent, canActivate: [RouteGuardService] },
  { path: 'aufmass/grundriss/:aufmassid/:raumid', component: AufmassDrawComponent, canActivate: [RouteGuardService] },
  { path: 'aufmass/messung/:aufmassid/:raumid', component: AufmassMessungComponent, canActivate: [RouteGuardService] },
  {
    path: 'aufmass/:uuid/raum/:raumid/masskette/:massketteid',
    component: MassketteEditComponent,
    canActivate: [RouteGuardService],
  },

  //Muss unten bleiben
  { path: '**', redirectTo: '/startseite' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
