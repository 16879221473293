import { Component, OnInit } from '@angular/core';
import { HWAddress, HWRepairOrder, HWTermin, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { AppointmentService } from 'apps/handwerkPWA/src/app/services/dataServices/appointment.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { AddressService } from '../../../services/dataServices/address.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { termineToMoreSpecificDatasets } from '../../../helper/services/terminHelper';

@Component({
  selector: 'app-appointments-index',
  templateUrl: './appointments-index.component.html',
  styleUrls: ['./appointments-index.component.scss'],
})
export class AppointmentsIndexComponent implements OnInit {
  appointmentsForCustomer: (HWTermin | HWRepairOrder | ServiceAuftrag)[];
  selectedTermin: HWTermin;
  dataSource: any;
  searchExpressions = ['start', 'finish', 'adr', 'caption', 'location'];
  address: HWAddress;

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private appointmentService: AppointmentService,
    private rechteService: RechteService,
    private addressService: AddressService,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAppointments();
  }

  private async loadAppointments(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserinfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    this.address = await this.addressService.findOneBy('Guid', addressGuid);
    const rights = this.rechteService.getCurrentRight();
    const baseAppointmentsForCustomer = await this.appointmentService.getAllTermineFromIDB(
      userInfo,
      this.address.KU_NR
    );
    this.appointmentsForCustomer = termineToMoreSpecificDatasets(baseAppointmentsForCustomer, rights);
  }

  async navigateTo(path: string): Promise<void> {
    const dateInMs = new Date().getTime();
    await this.routingService.navigateTo(`${path}/${dateInMs}/${this.address.KU_NR}`);
  }
}
