import { IndexedDBTypes } from './dbType';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { HWRepairOrder } from './HWRepairOrder';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { UUID } from 'angular2-uuid';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';

export enum HWMessageType {
  Incoming = 'E',
  Outgoing = 'A',
}

export class HWNachricht extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWNachricht') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  /**leerer String signalisiert Webservice eine neue Nachricht */
  @IndexedDBTypes.IndexField('string') ID = '';
  @IndexedDBTypes.DataField('string') AuftragsNr: string;
  @IndexedDBTypes.DataField('string') Betreff: string;
  @IndexedDBTypes.DataField('string') MonteurNr: string;
  @IndexedDBTypes.DataField('string') Text: string;
  @IndexedDBTypes.DataField('string') SendDatum: string;
  @IndexedDBTypes.DataField('string') SendZeit: string;
  @IndexedDBTypes.DataField('string') SendZeitDate;
  @IndexedDBTypes.DataField('string') Referenz: string;
  @IndexedDBTypes.DataField('boolean') Gelesen: boolean;
  /**TYP E=Eingehend im Handwerk, A=Ausgehend aus Handwerk */
  @IndexedDBTypes.DataField('string') TYP = HWMessageType.Incoming;
  @IndexedDBTypes.DataField('string') Guid: string = UUID.UUID();
  @IndexedDBTypes.DataField('boolean') offenInView = false;
  @IndexedDBTypes.DataField('HWRepairOrder') Auftrag: HWRepairOrder;
  GeraeteID: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: object) {
    super();
    Object.assign(this, data);
    if (isNullOrUndefined(this.SendZeit)) {
      this.setDatumUhrzeit();
    }
  }

  public setGeraeteId(userInfo: Userinfo): void {
    const device = userInfo.Device;
    this.GeraeteID = device?.Id?.toString();
  }

  /**@description Setzt SendeDatum und Uhrzeit wenn keines Vorhanden ist */
  setDatumUhrzeit(): void {
    const date = new Date();
    const dbDate = dateToDatabaseDate(date, true, true);

    const dbDateSplit = dbDate.split(' ');
    this.SendDatum = dbDateSplit[0];
    this.SendZeit = dbDateSplit[1];
  }

  static toString(): string {
    return 'HWNachricht';
  }
}
