import { Component, OnInit } from '@angular/core';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { BelegService } from 'apps/handwerkPWA/src/app/services/dataServices/beleg.service';
import { AddressService } from '../../../services/dataServices/address.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { Belegpositionen, HWAddress } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-supporting-document-show',
  templateUrl: './supporting-document-show.component.html',
  styleUrls: ['./supporting-document-show.component.scss'],
})
export class SupportingDocumentShowComponent implements OnInit {
  belegPositionen: Belegpositionen[];
  typUndNummer: string;
  customerName: string;
  address: HWAddress;
  priceRight: boolean;
  contactInformationArray = ['TEL', 'FUNK_PRIV', 'GLOBEMAIL', 'Anschrift', 'WWW'];
  searchExpressions = [
    'Artikelnummer',
    'Gesamtpreis',
    'ID',
    'Kurztext',
    'Menge',
    'Positionsnummer',
    'Rabatt',
    'Belegnummer',
  ];
  viewFinished = false;

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private belegService: BelegService,
    private addressService: AddressService,
    private rechteService: RechteService,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserinfo();
    this.priceRight = this.rechteService.hasRightOrSettingEnabled('showPrices');

    const belegName = this.routingService.getRouteParam('name');
    const supportingDoc = await this.belegService.findOneBy('name', belegName);

    this.typUndNummer = supportingDoc.typundnr;
    this.belegPositionen = await this.belegService.getBelegPositionen(userInfo, supportingDoc);
    this.address = await this.addressService.findOneBy('KU_NR', supportingDoc.kunde);
    this.viewFinished = true;
  }
}
