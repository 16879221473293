<h2 class="content-block">Raumaufmaß</h2>

<div class="content-block bottom-padding">
  <app-bss-list
    [id]="'Aufmass'"
    [inputDatasource]="aufmasse"
    [emptyInputDataMessage]="'Es sind keine Raumaufmaße vorhanden'"
    [searchExpressions]="['AufName', 'AufmId', 'Beschr', 'Betreff', 'Datum', 'Such']"
  >
  </app-bss-list>
</div>

<dx-speed-dial-action
  icon="add"
  class="add-button"
  label="Neues Raumaufmaß"
  (onClick)="createNewAufmass()"
></dx-speed-dial-action>
