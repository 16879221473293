import { environment } from 'apps/handwerkPWA/src/environments/environment';
import { versionStringToDecimalVersion } from '../../../helper/services/authorisationHelper';
import { Feature, Features, getFeature } from './Feature';
import { Featurename, Featurecheck } from './Featurecheck';

/**@description Vergleichsfunktion für die Features zum ordnen */
function compareOrder(a: Feature, b: Feature) {
  const aOrder = a.order;
  const bOrder = b.order;
  if (aOrder > bOrder) {
    return -1;
  }
  if (aOrder < bOrder) {
    return 1;
  }
  return 0;
}

export class PwaLoginResponse {
  authenticated: boolean;
  handwerkDBVersion = '60.0'; // Default zu tiefe aber valide VErsions
  handwerkDBVersionValue: number;
  webserviceVersion = '3.0.2.10';
  webserviceVersionValue: number;
  betaUser = false;
  constructor(data: PwaLoginResponse) {
    Object.assign(this, data);
    this.handwerkDBVersionValue = versionStringToDecimalVersion(this.handwerkDBVersion);
    this.webserviceVersionValue = versionStringToDecimalVersion(this.webserviceVersion);
  }

  /**@description Bildet den featurename auf Mindestversionen ab und prüft ob diese erfüllt sind */
  featureCheck(featureName: Featurename): Featurecheck {
    const feature = getFeature(featureName);
    const minDbversionString = feature.minHandwerkDBVersion;
    const minWebserviceVersionString = feature.minWebserviceVersion;
    const requiredDBVersion = versionStringToDecimalVersion(minDbversionString);
    const requiredWebserviceVersion = versionStringToDecimalVersion(minWebserviceVersionString);

    const isDbHighEnough = this.handwerkDBVersionValue >= requiredDBVersion;
    const isWebserviceHighEnough = this.webserviceVersionValue >= requiredWebserviceVersion;
    // if (environment.production === false)
    // return new Featurecheck(featureName, true, true, minWebserviceVersionString, minDbversionString, this.betaUser);
    return new Featurecheck(
      featureName,
      isDbHighEnough,
      isWebserviceHighEnough,
      minWebserviceVersionString,
      minDbversionString,
      this.betaUser
    );
  }

  /**@description Gibt das höchstmögliche Feature, inkrementell je nachdem welche Mindestversionen erreicht sind */
  getHighestFeaturePossible(): Featurename {
    const features = Features.sort(compareOrder);
    for (const feature of features) {
      const dbCheckPassed = this.handwerkDBVersionValue >= versionStringToDecimalVersion(feature.minHandwerkDBVersion);
      const webserviceCheckPassed =
        this.webserviceVersionValue >= versionStringToDecimalVersion(feature.minWebserviceVersion);
      if (dbCheckPassed && webserviceCheckPassed) return feature.name;
    }
    return 'Basis';
  }
}
