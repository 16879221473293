import { Injectable } from '@angular/core';
import { Raumname } from '../../entities';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RaumnameService {
  constructor(private baseService: BaseService, private controllerService: ControllerService) {}

  async getAll(): Promise<Raumname[]> {
    return await this.baseService.getAll(Raumname);
  }

  async getAllBy(selector: string, value: any): Promise<Raumname[]> {
    return await this.baseService.getAllBy(Raumname, selector, value);
  }

  async findOneBy(selector: string, value: any): Promise<Raumname> {
    return await this.baseService.findOneBy(Raumname, selector, value);
  }

  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(Raumname, selector, value);
  }

  async overrideLocal(raumnamen: Raumname[]): Promise<void> {
    await this.controllerService.clearStore('Raumname');
    await this.controllerService.setData('Raumname', raumnamen);
  }

  async getAllFlaechenbezeichnungen(): Promise<Raumname[]> {
    return await this.baseService.getAllBy(Raumname, 'Typ', '0');
  }
}
