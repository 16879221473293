import { NavigationItem } from '../entities';
import { datenschutzpath, hilfepath } from './Konstanten';

const Impressum: NavigationItem = {
  text: 'Impressum',
  path: '/impressum',
  icon: 'info',
  customDisabled: false,
};

const Login: NavigationItem = {
  text: 'Login',
  path: '/Login',
  icon: 'user',
  customDisabled: false,
};

const Datenschutz: NavigationItem = {
  text: 'Datenschutz',
  path: datenschutzpath,
  icon: 'checklist',
  customDisabled: false,
};

const Hilfe: NavigationItem = {
  text: 'Hilfe',
  path: hilfepath,
  icon: 'help',
  customDisabled: false,
};

const Nachrichten: NavigationItem = {
  text: 'Nachrichten',
  path: '/nachrichten',
  // icon: 'message'
  customDisabled: false,
  feature: 'Basis',
};

const Reparaturauftraege: NavigationItem = {
  text: 'Reparaturaufträge',
  path: '/reparaturauftraege',
  customDisabled: false,
  feature: 'Basis',
};

export const navigationLoggedOut: NavigationItem[] = [Login, Datenschutz, Hilfe, Impressum];

export const Module: NavigationItem[] = [
  {
    text: 'Reparaturcenter',
    path: 'reparatur',
    customDisabled: false,
    items: [Reparaturauftraege, Nachrichten],
    isExpanded: true,
    feature: 'Basis',
  },
  {
    text: 'W&S Anlagen',
    path: '/wartungsanlagen',
    customDisabled: false,
    feature: 'Basis',
  },
  {
    text: 'W&S Aufträge',
    path: '/wartungsauftraege',
    customDisabled: false,
    feature: 'Wartung und Service',
  },
  {
    text: 'Raumaufmaß',
    path: '/aufmasse',
    customDisabled: false,
    feature: 'Aufmass',
  },
];

export const baseitems: NavigationItem[] = [
  {
    text: 'Startseite',
    path: '/startseite',
    icon: 'home',
    customDisabled: false,
  },
  {
    text: 'Adressen',
    path: '/adressen',
    icon: 'user',
    customDisabled: false,
  },
  {
    text: 'Belege',
    path: '/belege',
    icon: 'verticalaligntop',
    customDisabled: false,
  },
  {
    text: 'Kalender',
    path: '/kalender',
    icon: 'event',
    customDisabled: false,
  },
  {
    text: 'Module',
    icon: 'box',
    path: 'module',
    isExpanded: false,
    customDisabled: false,
    items: Module,
  },
  {
    text: 'Entdecken',
    path: '/entdecken',
    icon: 'find',
    customDisabled: false,
  },
  {
    text: 'Einstellungen',
    path: '/einstellungen',
    icon: 'preferences',
    customDisabled: false,
  },
  {
    text: 'Synchronisation',
    path: '/Synchronisation',
    icon: 'refresh',
    customDisabled: false,
  },
  Datenschutz,
  Hilfe,
  Impressum,
  {
    text: 'Abmelden',
    path: '/abmelden',
    icon: 'runner',
    customDisabled: false,
  },
];
