<div class="contactBar flexContainer">
  <div
    *ngIf="contactInformationArray[0]"
    class="flexContainerItem"
    [ngClass]="{ 'disabled-mark': !contactArtAvailable(contactInformationArray, 'phone', dataObject) }"
  >
    <dx-button
      class="contact-button"
      stylingMode="text"
      icon="tel"
      type="normal"
      [text]="telefonDescription ? 'Telefon' : 'Festnetz'"
      (onClick)="contact(contactInformationArray, 'phone', dataObject)"
    ></dx-button>
  </div>
  <div
    *ngIf="contactInformationArray[1]"
    class="flexContainerItem"
    [ngClass]="{ 'disabled-mark': !contactArtAvailable(contactInformationArray, 'mobile', dataObject) }"
  >
    <dx-button
      class="contact-button"
      stylingMode="text"
      icon="tel"
      type="normal"
      text="Mobil"
      (onClick)="contact(contactInformationArray, 'mobile', dataObject)"
    ></dx-button>
  </div>
  <div
    *ngIf="contactInformationArray[2]"
    class="flexContainerItem"
    [ngClass]="{ 'disabled-mark': !contactArtAvailable(contactInformationArray, 'email', dataObject) }"
  >
    <dx-button
      class="contact-button"
      stylingMode="text"
      icon="message"
      type="normal"
      text="E-Mail"
      (onClick)="contact(contactInformationArray, 'email', dataObject)"
    ></dx-button>
  </div>
  <div
    *ngIf="contactInformationArray[3]"
    class="flexContainerItem"
    [ngClass]="{ 'disabled-mark': !contactArtAvailable(contactInformationArray, 'navi', dataObject) }"
  >
    <dx-button
      class="contact-button"
      stylingMode="text"
      icon="map"
      type="normal"
      text="Navigieren"
      (onClick)="contact(contactInformationArray, 'navi', dataObject)"
    ></dx-button>
  </div>
  <div
    *ngIf="contactInformationArray[4]"
    class="flexContainerItem"
    [ngClass]="{ 'disabled-mark': !contactArtAvailable(contactInformationArray, 'web', dataObject) }"
  >
    <dx-button
      class="contact-button"
      stylingMode="text"
      icon="globe"
      type="normal"
      text="Web"
      (onClick)="contact(contactInformationArray, 'web', dataObject)"
    ></dx-button>
  </div>
</div>
