import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { IndexedDBTypes } from './dbType';

export class Projekt extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('Projekt') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') Uuid: string = null;
  @IndexedDBTypes.DataField('string') Nummer: string = null;
  @IndexedDBTypes.DataField('string') Name: string = null;
  @IndexedDBTypes.DataField('string') Betreff: string = null;
  @IndexedDBTypes.DataField('string') Kunde: string = null;
  @IndexedDBTypes.DataField('string') KdSuch: string = null;

  constructor(Projekt: Projekt) {
    super();
    assignIfPropertyExsits(this, Projekt);
  }

  static toString(): string {
    return 'Projekt';
  }
}
