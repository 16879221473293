import { IndexedDBTypes } from './dbType';

export class HWGlobalSetting extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWGlobalSetting') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private key: string;
  @IndexedDBTypes.DataField('object') private value: any;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  static toString(): string {
    return 'HWGlobalSetting';
  }
}
