import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { Aufmass } from '../../repository/Aufmass';
import { Raumbuch } from '../../repository/Raumbuch';
import { Raumname } from '../../repository/Raumname';
import { RVorlage } from '../../repository/RVorlage';

export class AufmassGrunddaten {
  Raumbuecher: Raumbuch[] = null;
  Raumnamen: Raumname[] = null;
  Raumvorlagen: RVorlage[] = null;
  Aufmasse: Aufmass[] = null;

  constructor(aufmassGrunddaten: AufmassGrunddaten) {
    assignIfPropertyExsits(this, aufmassGrunddaten);
  }
}
