import { isEmptyGuiData } from 'libs/shared/src/lib/helper/globalHelper';
import { PDFSettingsServiceAuftrag } from './PDFSettingsServiceAuftrag';
import { HWRepairOrder } from 'apps/handwerkPWA/src/app/entities';
import { BaseAuftrag } from 'apps/handwerkPWA/src/app/interfaces';
import { EmailconnectionInfo } from '../EmailconnectionInfo';
import { PDFSettings } from './PDFSettings';
import { Textvorlage } from '../Textvorlage';
import { Briefpapier } from '../Briefpapier';
import { Sliderside } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { KmAllowanceSettings } from './KmAllowanceSettings';

function toString(obj: any) {
  return (obj as unknown) as string;
}

export class Setting {
  sliderOrder: Sliderside[] = [
    'Adressen',
    'Reparaturaufträge',
    'Termine',
    'W&S Anlagen',
    'W&S Aufträge',
    'Raumaufmaße',
  ];
  PDFSettings: PDFSettings;
  /**Ist aktuell in der Antwort des Webservice ein String der Nutzdaten, wird aber als Objekt des Briefpapiers verwendet */
  briefPapier: Briefpapier;
  briefPapiername: string;
  textVorlagen: Textvorlage[] = [];
  emailTextvorlage: Textvorlage = null;
  emailConnectionInfo: EmailconnectionInfo;
  PDFSettingsServiceAuftrag: PDFSettingsServiceAuftrag;
  kmAllowanceSettings: KmAllowanceSettings;

  constructor(data: Setting, wartungUndServiceAvailable: boolean, aufmassAvailable: boolean) {
    const pdfSettingData = !isEmptyGuiData(data?.PDFSettings)
      ? (JSON.parse(toString(data.PDFSettings)) as PDFSettings)
      : null;
    this.PDFSettings = new PDFSettings(pdfSettingData);

    const pdfSettingDataServiceAuftrag = !isEmptyGuiData(data?.PDFSettingsServiceAuftrag)
      ? (JSON.parse(toString(data.PDFSettingsServiceAuftrag)) as PDFSettingsServiceAuftrag)
      : null;
    this.PDFSettingsServiceAuftrag = new PDFSettingsServiceAuftrag(pdfSettingDataServiceAuftrag);

    const briefPapier = !isEmptyGuiData(data?.briefPapier)
      ? new Briefpapier(data.briefPapiername, toString(data.briefPapier))
      : null;
    this.briefPapier = briefPapier;

    const textVorlagen = !isEmptyGuiData(data?.textVorlagen)
      ? (JSON.parse(toString(data.textVorlagen)) as Textvorlage[])
      : [];
    this.textVorlagen = textVorlagen;

    const emailTextVorlage = !isEmptyGuiData(data?.emailTextvorlage)
      ? new Textvorlage(JSON.parse(toString(data.emailTextvorlage)))
      : null;
    this.emailTextvorlage = emailTextVorlage;

    const emailConnectionInfo = !isEmptyGuiData(data?.emailConnectionInfo)
      ? (JSON.parse(toString(data.emailConnectionInfo)) as EmailconnectionInfo)
      : null;
    this.emailConnectionInfo = emailConnectionInfo;

    const kmAllowanceSettings = !isEmptyGuiData(data?.kmAllowanceSettings)
      ? (JSON.parse(toString(data.kmAllowanceSettings)) as KmAllowanceSettings)
      : null;
    this.kmAllowanceSettings = kmAllowanceSettings;

    if (!wartungUndServiceAvailable) {
      const index = this.sliderOrder.findIndex(slider => slider === 'W&S Aufträge');
      this.sliderOrder.splice(index, 1);
    }

    if (!aufmassAvailable) {
      const index = this.sliderOrder.findIndex(slider => slider === 'Raumaufmaße');
      this.sliderOrder.splice(index, 1);
    }
  }
}

export function getSettingFromOrdertype(order: BaseAuftrag, setting: Setting): PDFSettings {
  const orderType = order instanceof HWRepairOrder ? 'HWRepairOrder' : 'ServiceAuftrag';
  switch (orderType) {
    case 'HWRepairOrder':
      return setting.PDFSettings;
    case 'ServiceAuftrag':
      return setting.PDFSettingsServiceAuftrag;
  }
}
