import { convertFormelToValueNamePairs } from 'libs/shared/src/lib/helper/aufmassHelper';
import { assignIfPropertyExsits, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { AufmassFormelRaw } from './AufmassFormelRaw';
import { ValueNamePair } from './ValueNamePair';

export class AufmassFormel {
  Id: number = null;
  Kurzname: string = null;
  Formel: string = null;
  Erkl: string = null;
  Status: string = null;
  Bildname: string = null;
  Uuid: string = null;
  ImageBase64: string = null;
  ValueNamePairs: ValueNamePair[] = [];

  constructor(formel: AufmassFormelRaw) {
    assignIfPropertyExsits(this, formel);
    if (this.Formel) this.ValueNamePairs = convertFormelToValueNamePairs(this.Formel);
  }

  checkAllPairsMeasured(): boolean {
    return this.ValueNamePairs.every(pair => !isNullOrUndefined(pair.value));
  }

  generateValuefilledFormula(): string {
    let transformationFormula = this.Formel;
    const valueNamePairs = this.ValueNamePairs;
    for (const pair of valueNamePairs) {
      const decimalPlaces = pair.value.toString().includes('.') ? pair.value.toString().split('.')[1]?.length : 0;
      transformationFormula = transformationFormula.replaceAll(pair.name, `${pair.value.toFixed(decimalPlaces)}`);
    }
    transformationFormula = transformationFormula.replaceAll('.', ',');
    return transformationFormula;
  }
}
