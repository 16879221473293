import { Component, OnInit, OnDestroy } from '@angular/core';
import { Documentservice } from 'apps/handwerkPWA/src/app/services/globalServices/document.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import {
  HTMLInputEvent,
  isNullOrUndefined,
  isNullOrUndefinedOrEmptyString,
  splitFilenameAndExtension,
} from 'libs/shared/src/lib/helper/globalHelper';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { DialogService } from '@handwerk-pwa/shared';
import { ObjectaddressService } from 'apps/handwerkPWA/src/app/services/dataServices/objectAddress.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { HWAddress, HWObjectaddress, HWFile, HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { allowedDatatypes, SourceOfFilelist, SourceOfFilelistString } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { Subscription } from 'rxjs';
import { ServiceauftraegeService } from '../../../services/dataServices/serviceauftraege.service';
import { RepairOrderService } from '../../../services/dataServices/repairOrder.service';
import { WartungsprojectsService } from '../../../services/dataServices/wartungsprojects.service';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { createHWFile } from '../../../entities/repository/HWFile';
import { createMedium, Medien } from '../../../entities/repository/Medien';
import { getUuidOfFileSource } from '../../../helper/services/documentHelper';
import { AufmassService } from '../../../services/dataServices/aufmass.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit, OnDestroy {
  selectedFile: HWFile | Medien;
  showImg: boolean;
  filesAdded = false;
  fileList: Array<Medien | HWFile> = [];
  saveMediumSubscription: Subscription;
  fileName: string;
  currentFile: File;
  invalidCharInFilename: boolean;
  /**Vor oder nach Einführung der Medientabelle (zweistufig - aktuell Medientabelle1 eingeführt worden) */
  newVersion: boolean;
  description: string;

  constructor(
    private documentService: Documentservice,
    private globalSettingService: HWGolbalsettingService,
    private adressService: AddressService,
    private dialogService: DialogService,
    private objectAdressService: ObjectaddressService,
    private routingService: RoutingService,
    private serviceOrderService: ServiceauftraegeService,
    private repairOrderService: RepairOrderService,
    private wartungsprojectsService: WartungsprojectsService,
    private authorisationService: AuthorisationService,
    private aufmassService: AufmassService
  ) {}

  async ngOnInit(): Promise<void> {
    // Subscription für das Speichern
    this.saveMediumSubscription = this.routingService.save.subscribe(() => void this.saveDocuments());

    this.newVersion = this.authorisationService.current.getValue().featureCheck('Medientabelle1').available;
    const sourceOfFilelist = await this.getSourceOfFileList();

    if (sourceOfFilelist instanceof HWAddress || sourceOfFilelist instanceof HWObjectaddress) {
      this.newVersion = this.authorisationService.current.getValue().featureCheck('Medientabelle2').available;
      this.fileList = sourceOfFilelist.Files.map(file => new HWFile(file));
    } else if (this.newVersion)
      this.fileList = await this.documentService.getAllBy('Baseuuid', getUuidOfFileSource(sourceOfFilelist));

    await this.documentService.getPicturePlaceholderAndNames(this.fileList);
    if (this.routingService.getCurrentRoute().endsWith('upload')) this.triggerUpload();
  }

  private async getSourceOfFileList(): Promise<SourceOfFilelist> {
    const type = this.routingService.getRouteParam('type') as SourceOfFilelistString;
    const guid = this.routingService.getRouteParam('guid');
    const secondGuid = this.routingService.getRouteParam('secondGuid');

    switch (type) {
      case 'RaumbPos': {
        const aufmass = await this.aufmassService.findOneBy('Uuid', guid);
        return aufmass.getRaumbuchpositionen().find(raumbPos => raumbPos.Uuid === secondGuid);
      }
      case 'Aufmass':
        return await this.aufmassService.findOneBy('Uuid', guid);
      case 'Address':
        return await this.adressService.findOneBy('Guid', guid);
      case 'ObjectAdress':
        return await this.objectAdressService.findOneBy('Guid', guid);
      case 'RepairOrder':
        return await this.repairOrderService.findOneByGuid(guid);
      case 'ServiceOrder': {
        const order = await this.serviceOrderService.findOneBy('UUID', guid);
        return await this.wartungsprojectsService.findOneAnlageBy('ANLAGE', order.Anlage);
      }
      case 'Anlage':
        return await this.wartungsprojectsService.findOneAnlageBy('UUID', guid);
    }
  }

  private triggerUpload(): void {
    const uploadButton = document.getElementById('uploadButton') as HTMLButtonElement;
    uploadButton.click();
  }

  async getImageElement(inputFile: HWFile | Medien, listIndex: number): Promise<void> {
    const bssFile = this.newVersion ? new Medien(inputFile) : new HWFile(inputFile);
    if (this.newVersion) delete (bssFile as Medien).PreviewPictureData;
    let documentUrl = bssFile.Data;
    const isText = this.documentService.isDocumentText(inputFile);
    if (isNullOrUndefinedOrEmptyString(documentUrl)) {
      documentUrl = await this.documentService.getDocumentDataUrlFromWebservice(bssFile);
      await bssFile.setDataUrl(documentUrl, isText);
      await this.putFileIntoAdress(bssFile);
      this.fileList[listIndex] = bssFile;
    }
    if (this.documentService.isDocumentPdf(inputFile)) {
      this.documentService.openPdf(documentUrl);
      return;
    }
    if (isText) {
      this.documentService.openText(documentUrl);
      return;
    }
    this.selectedFile = bssFile;
    this.showImg = true;
  }

  private async putFileIntoAdress(bssFile: HWFile | Medien): Promise<void> {
    if (bssFile instanceof Medien) return;
    if (!bssFile.Kundennummer) return;
    if (isNullOrUndefined(bssFile.LfdNr))
      // Keine LFdNr, dann Adresse
      return await this.adressService.updateDocumentInAdress(bssFile);
    await this.objectAdressService.updateDocumentInAdress(bssFile);
  }

  /**@description Wenn ein Dokument der jeweiligen Adresse hinzugefügt wird (upload click) */
  async addDocument(event: HTMLInputEvent | Event): Promise<void> {
    const fileList = Array.from((event as HTMLInputEvent).target.files);
    for (const file of fileList) {
      const currentFileNameSplit = splitFilenameAndExtension(file.name);
      const fileExtension = currentFileNameSplit.extension;
      const allowed = allowedDatatypes.includes(fileExtension?.toLowerCase());
      if (!allowed) {
        void this.dialogService.openErrorMessage(
          'Fehler',
          `Unerlaubtes Dateiformat: ${fileExtension} (${file.name}) - Erlaubt sind: ${allowedDatatypes.join(', ')}`
        );
        continue;
      }
      if (fileList.length === 1) {
        // eine Datei, name selbst wählen,PopUp Get Nun Auf
        this.currentFile = file;
        this.fileName = currentFileNameSplit.name;
      } else await this.parseAndAddFile(file, file.name, null);
    }
  }

  /**@description Datei wird nun zusammengebaut (eventuell mit neuem namen) und an den webservice übergeben */
  async parseAndAddFile(file: File, fileName: string, description: string): Promise<void> {
    const fileNameSplit = splitFilenameAndExtension(file.name);
    const newFileName = `${fileName}.${fileNameSplit.extension}`;
    const source = await this.getSourceOfFileList();
    const parsedFile = this.newVersion
      ? await createMedium(file, newFileName, source, description)
      : await createHWFile(file, newFileName);
    this.fileName = undefined;
    this.description = undefined;
    this.currentFile = undefined;
    const currentFileDuplicate = this.documentService.checkFileExists(this.fileList, parsedFile);
    if (currentFileDuplicate)
      return await this.dialogService.openErrorMessage('Fehler', 'Ein Medium mit gleichen Namen existiert bereits.');
    const sourceOfFilelist = await this.getSourceOfFileList();
    if (parsedFile instanceof HWFile) parsedFile.addZuordnung(sourceOfFilelist);
    this.fileList.unshift(parsedFile);
    this.filesAdded = true;
    this.routingService.dataChanged.next(true);
  }

  async saveDocuments(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserinfo();
    this.filesAdded = false;
    const baseFile = this.fileList[0];
    if (isNullOrUndefined(baseFile)) return;
    void this.dialogService.openLoadingDialog('Dateitransfer', 'Medien werden übertragen.');
    const uuid = this.routingService.getRouteParam('guid');
    const documents = await this.documentService.sendUnpushedImagesToWebservice(userInfo, this.fileList);
    await this.assignAdressinfoOld(baseFile, uuid, documents);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    this.routingService.routeBack();
  }

  /**@description Schritte die vor Einführung der Medientabelle notwendig waren */
  private async assignAdressinfoOld(baseFile: HWFile | Medien, uuid: string, documents: Array<Medien | HWFile>) {
    if (baseFile instanceof HWFile && baseFile.LfdNr)
      await this.objectAdressService.updateDocumentsInAdressLocally(documents as HWFile[]);
    else if (baseFile instanceof HWFile && baseFile.Kundennummer)
      await this.adressService.updateDocumentsInAdressLocally(documents as HWFile[]);
  }

  validateFilename(newValue: string): void {
    if (isNullOrUndefined(newValue)) return;
    const disallowedFilenameChars = ['\\', '/', ':', '*', '?', '"', '<', '>', '|'];
    for (const disallowedChar of disallowedFilenameChars)
      if (newValue.includes(disallowedChar)) {
        this.invalidCharInFilename = true;
        return;
      } else this.invalidCharInFilename = false;
  }

  ngOnDestroy(): void {
    this.saveMediumSubscription?.unsubscribe();
  }

  getImageName(file: HWFile | Medien): string {
    if (file instanceof HWFile) return file.NameWithoutEnding;
    if (file instanceof Medien) return file.Orgname;
    return '';
  }
}
