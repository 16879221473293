import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { Aufmass, Raumbuch, Raumname } from '../../../entities';
import { RaumbPos } from '../../../entities/models/aufmass/RaumbPos';
import { AufmassService } from '../../../services/dataServices/aufmass.service';
import { RaumbuchService } from '../../../services/dataServices/raumbuch.service';
import { RaumnameService } from '../../../services/dataServices/raumname.service';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';

@Component({
  selector: 'app-masskette-edit',
  templateUrl: './masskette-edit.component.html',
  styleUrls: ['./masskette-edit.component.scss'],
})
export class MassketteEditComponent implements OnInit, OnDestroy {
  aufmass: Aufmass;
  raum: RaumbPos;
  raumbuchEntry: Raumbuch;
  breite: number;
  hoehe: number;
  anzahl: number;
  raumnamen: Raumname[];
  /**Gibt an ob die Messfelder gezeigt werden oder die Aufmaßformel direkt eingegeben werden soll */
  freeInput = true;
  inputDone = false;
  bezugsFlaechen: Raumbuch[];
  selectedBezug: Raumbuch;
  currentRaumbuchEntries: Raumbuch[];
  saveSubscription: Subscription;

  constructor(
    private routingService: RoutingService,
    private aufmassService: AufmassService,
    private raumnamenService: RaumnameService,
    private raumbuchService: RaumbuchService,
    private globalSettingService: HWGolbalsettingService,
    private dialogService: DialogService
  ) {}
  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    const { massKetteId, currentRaumbuchEntries } = await this.loadData();
    await this.handleNewOrExisting(massKetteId, currentRaumbuchEntries);
    this.saveSubscription = this.routingService.save.subscribe(
      () => void this.save(this.raumbuchEntry, this.currentRaumbuchEntries, this.raum, this.aufmass)
    );
  }

  /**@description Je nachdem ob es ein neuer oder vorhandener eintrag ist die vorbelegungen treffen  */
  private async handleNewOrExisting(massKetteId: string, currentRaumbuchEntries: Raumbuch[]) {
    this.currentRaumbuchEntries = currentRaumbuchEntries;
    if (massKetteId === 'neu') {
      this.raumbuchEntry = new Raumbuch(null, this.raum);
      this.raumbuchEntry.createNewFromScratch(currentRaumbuchEntries, this.raum);
    } else {
      this.raumbuchEntry = new Raumbuch(this.raumbuchService.findOneRaumbuchBy(this.aufmass, 'Uuid', massKetteId));
      if (this.raumbuchEntry.Aufmass?.includes('*')) this.parseDimensions(this.raumbuchEntry.Aufmass);
    }
    this.inputDone = !isNullOrUndefinedOrEmptyString(this.raumbuchEntry.Aufmass);
    this.bezugsFlaechen = currentRaumbuchEntries.filter(
      flaeche => !flaeche.IsAbzug && flaeche.Uuid !== this.raumbuchEntry.Uuid
    );
    if (this.raumbuchEntry.IsAbzug)
      this.selectedBezug = this.bezugsFlaechen.find(flaeche => flaeche.Lineid === this.raumbuchEntry.Lineid);
  }

  /**@description Versucht Höhe,breite und länge aus der Formel zu parsen */
  parseDimensions(formelToParseFrom: string): void {
    const factorSplit = formelToParseFrom.split('*');
    if (factorSplit.length !== 2 && factorSplit.length !== 3) return;
    const breite = parseInt(factorSplit[0], 10);
    const hoehe = parseInt(factorSplit[1], 10);
    const anzahl = factorSplit.length === 3 ? parseInt(factorSplit[2], 10) : 1;
    if (isNaN(breite) || isNaN(hoehe) || isNaN(anzahl)) return;
    this.breite = breite;
    this.hoehe = hoehe;
    this.anzahl = anzahl;
    this.freeInput = false;
  }

  private async loadData() {
    const aufmassUuid = this.routingService.getRouteParam('uuid');
    this.aufmass = await this.aufmassService.findOneBy('Uuid', aufmassUuid);
    const raumId = this.routingService.getRouteParam('raumid');
    const raumbuchPositionen = this.aufmass.getRaumbuchpositionen();
    this.raum = raumbuchPositionen.find(position => position.Uuid === raumId);
    const massKetteId = this.routingService.getRouteParam('massketteid');
    const currentRaumbuchEntries = await this.raumbuchService.getAllForRaum(this.raum, this.aufmass);
    this.raumnamen = await this.raumnamenService.getAllFlaechenbezeichnungen();
    return { massKetteId, currentRaumbuchEntries };
  }

  messwertChanged(newValueString: string, hoeheBreiteAnzahl: 'hoehe' | 'breite' | 'anzahl'): void {
    const newValue = parseInt(newValueString, 10);
    if (isNaN(newValue)) return;
    switch (hoeheBreiteAnzahl) {
      case 'anzahl':
        this.anzahl = newValue;
        break;
      case 'hoehe':
        this.hoehe = newValue;
        break;
      case 'breite':
        this.breite = newValue;
        break;
    }
    if (this.anzahl && this.hoehe && this.breite) {
      const newFormel = `${this.anzahl}*${this.hoehe}*${this.breite}`;
      this.raumbuchEntry.useFormel(newFormel);
    }
  }

  selectedBezugsflaeche(value: Raumbuch): void {
    this.selectedBezug = value;
  }

  async save(raumbuchEntry: Raumbuch, currentEntries: Raumbuch[], raumbPos: RaumbPos, aufmass: Aufmass): Promise<void> {
    if (isNullOrUndefinedOrEmptyString(raumbuchEntry.Bezeich)) {
      void this.dialogService.openErrorMessage('Fehler', 'Sie haben die Fläche noch nicht benannt. ');
      return;
    }
    if (isNullOrUndefinedOrEmptyString(raumbuchEntry.Aufmass)) {
      void this.dialogService.openErrorMessage('Fehler', 'Die Formel darf nicht leer sein. ');
      return;
    }
    if (raumbuchEntry.IsAbzug && !this.selectedBezug) {
      void this.dialogService.openErrorMessage(
        'Fehler',
        'Dieses Flächenaufmass ist als Abzug gekennzeichnet, es wurde aber kein Bezug gewählt. '
      );
      return;
    }
    raumbuchEntry.fillLineIdAbzugId(currentEntries, this.selectedBezug);
    const userInfo = await this.globalSettingService.getUserinfo();
    this.aufmass = await this.raumbuchService.saveSingleFlaeche(userInfo, raumbuchEntry, raumbPos, aufmass, false);
    void this.routingService.routeBack(true);
  }

  async deleteEntry(raumbuchEntry: Raumbuch, aufmass: Aufmass): Promise<void> {
    const continueDelete = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Möchten Sie diese Maßkette wirklich löschen? ',
      'Löschen',
      'Nicht löschen',
      false
    );
    if (!continueDelete) return;
    const userInfo = await this.globalSettingService.getUserinfo();
    await this.raumbuchService.deleteSingleFlaeche(userInfo, raumbuchEntry, aufmass, false);
    void this.routingService.routeBack(true);
  }
}
