<div class="content-block bottom-padding" *ngIf="order">
  <div class="form-block">
    <h2>Reparaturauftrag erstellen</h2>

    <div *ngIf="!kunde" style="display: flex; flex-wrap: wrap; justify-content: space-between">
      <div style="width: 48%">
        <app-bss-search-popup
          [buttonStyle]="'bss-button button-block primary-button'"
          [buttonText]="'Kunden wählen'"
          [headline]="'Wählen Sie einen Kunden aus'"
          [inputDataSource]="kundenListe"
          [customDisplayExpressions]="['NAME', 'STRASSE', 'PLZ', 'ORT']"
          [additionalSearchExpression]="['KU_NR']"
          (selectedValue)="onKundeChange(userInfo, $event)"
        >
        </app-bss-search-popup>
      </div>
      <div style="width: 48%">
        <div class="bss-button button-block primary-button" (click)="createNewAdress()">Neuen Kunden anlegen</div>
      </div>
    </div>

    <div class="dx-card responsive-paddings" *ngIf="kunde">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-datetime-box
            [labelText]="'Eingangsdatum'"
            [value]="newDate"
            [type]="'date'"
            (outStringValue)="order.Eingang = $event"
          ></app-bss-datetime-box>
          <app-bss-datetime-box
            [labelText]="'Termin'"
            [value]="newDate"
            [type]="'datetime'"
            (outValue)="parseMinFromTermin($event)"
          ></app-bss-datetime-box>
          <app-bss-datetime-box
            [labelText]="'Bis'"
            [min]="minEnde"
            [max]="maxEnde"
            [value]="currentEndTime"
            [type]="'time'"
            (outStringValue)="order.Endzeit = $event"
          ></app-bss-datetime-box>
          <app-bss-textbox [labelText]="'Betreff'" [(value)]="order.Betreff"></app-bss-textbox>
        </div>
        <div class="fieldbox">
          <app-bss-plain-text-box
            [labelText]="'Kunde'"
            [value]="kunde ? kunde.NAME : '--- kein Kunde ausgewählt ---'"
            [hintText]="kunde && kunde.LIEFERSPERRE ? 'Liefersperre hinterlegt' : null"
          ></app-bss-plain-text-box>

          <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
            <div style="width: 48%">
              <app-bss-search-popup
                [buttonStyle]="'bss-button button-block primary-button'"
                [buttonText]="'Kunden wählen'"
                [headline]="'Wählen Sie einen Kunden aus'"
                [inputDataSource]="kundenListe"
                [customDisplayExpressions]="['NAME', 'STRASSE', 'PLZ', 'ORT']"
                [additionalSearchExpression]="['KU_NR']"
                (selectedValue)="onKundeChange(userInfo, $event)"
              >
              </app-bss-search-popup>
            </div>
            <div style="width: 48%">
              <div class="bss-button button-block primary-button" (click)="createNewAdress()">Neuen Kunden anlegen</div>
            </div>
            <div *ngIf="objektadressListe && objektadressListe.length != 0" style="width: 100%; padding-top: 10px">
              <app-bss-search-popup
                [buttonStyle]="'bss-button button-block primary-button'"
                [buttonText]="'Objektadresse auswählen'"
                [headline]="'Wählen Sie eine Objektadresse aus'"
                [inputDataSource]="objektadressListe"
                [customDisplayExpressions]="['Name', 'STRASSE', 'PLZ', 'ORT']"
                (selectedValue)="objektadresse = $event"
              >
              </app-bss-search-popup>
            </div>
          </div>

          <app-bss-plain-text-box
            *ngIf="objektadresse"
            [labelText]="'Objekt'"
            [value]="objektadresse.Name"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            *ngIf="objektadresse && objektadresse.STANDORT1"
            [labelText]="'Standort 1'"
            [value]="objektadresse.STANDORT1"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            *ngIf="objektadresse && objektadresse.STANDORT2"
            [labelText]="'Standort 2'"
            [value]="objektadresse.STANDORT2"
          ></app-bss-plain-text-box>
        </div>

        <app-bss-textarea [labelText]="'Arbeitsauftrag'" [(value)]="order.Beschreibung"></app-bss-textarea>
        <app-bss-textarea [labelText]="'Bemerkung'" [(value)]="order.Bemerkung"></app-bss-textarea>
      </div>
    </div>
  </div>
</div>

<dx-speed-dial-action
  icon="check"
  class="add-button"
  label="erstellen"
  [index]="1"
  (onClick)="saveNewRepairOrder(order, kunde, 10)"
></dx-speed-dial-action>
<dx-speed-dial-action
  icon="check"
  class="add-button"
  label="erstellen und anfangen"
  [index]="1"
  (onClick)="saveNewRepairOrder(order, kunde, 30)"
></dx-speed-dial-action>
