import { UUID } from 'angular2-uuid';
import { DeviceInfo } from 'ngx-device-detector';
import { assignIfPropertyExsits, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { Userinfo } from './Userinfo';

export class Mobiledevices {
  Id = -1;
  Monteurnr: string = null;
  Devtype: string = null;
  Devos: string = null;
  Devosversion: string = null;
  /**Ist die ID des Gerätes für Push - wird nur gesetzt falls Push aktiv und zugestimmt wurde */
  Devtoken = 'NoPush';
  /**Scheinbar der letzte Zeitpunkt der Kommunikation */
  LContact: Date = null;
  Unregistered = false;
  LicenceUuid: string = null;

  constructor(data: object) {
    assignIfPropertyExsits(this, data);
    if (isNullOrUndefinedOrEmptyString(this.LicenceUuid)) this.LicenceUuid = UUID.UUID();
    this.Unregistered = false;
  }

  assignPropertiesFromUserInfo(userInfo: Userinfo): void {
    this.Monteurnr = userInfo.monteur;
  }

  assignDeviceInfo(deviceInfo: DeviceInfo): void {
    this.Devtype = deviceInfo.device;
    this.Devos = deviceInfo.os;
    this.Devosversion = deviceInfo.os_version;
  }

  assignPushtoken(pushToken: string): void {
    this.Devtoken = pushToken;
  }

  assignId(Id: number): void {
    this.Id = Id;
  }

  unregister(): void {
    this.Unregistered = true;
  }
}
