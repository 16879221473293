import { HWAnlage } from 'apps/handwerkPWA/src/app/entities';

/**@description Splittet die Standortdaten auf und fügt sie dem Anlagenobjekt auf unterster eben hinzu, damit darüber gesucht werden kann */
export function getSearchableAnlagen(anlagen: HWAnlage[]): HWAnlage[] {
  const searchAnlagen = [];
  for (const anlage of anlagen) {
    const searchAnlage = {
      ...anlage,
      ...anlage.Anlagenstandort,
      ...anlage.Anlagendaten,
    };
    searchAnlagen.push(searchAnlage);
  }

  return searchAnlagen;
}
