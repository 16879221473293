import { UUID } from 'angular2-uuid';
import { assignIfPropertyExsits, getNextNumber, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { AufmassStrecke } from '../models/aufmass/AufmassStrecke';
import { Bauelement } from '../models/aufmass/Bauelement';
import { RaumbPos } from '../models/aufmass/RaumbPos';

function getNextAbzugId(currentEntries: Raumbuch[], belongsToStreckeWithLineId: number): number {
  const allOtherBauelemente = currentEntries.filter(
    entry => entry.Lineid === belongsToStreckeWithLineId && entry.IsAbzug
  );
  const currentAbzugIds = allOtherBauelemente.flatMap(bauelement => bauelement.Abzugid);
  return getNextNumber(currentAbzugIds, 1);
}

export class Raumbuch {
  KlassenName: string;
  private AutoKey: string;
  Uuid: string = null;
  Aufmid: string = null;
  /**Ordnet einen Raumbucheintrag einem Raum zu */
  Rpos_Id: number = null;
  /**Fortlaufende, bei 1 beginnende, Nummer für alle Elemente, die kein Abzug sind - Ist die position ein abzug, zeigt die LineId auf welches eigentliche Element sich der abzug bezieht */
  Lineid: number = null;
  /**Fortlaufendee bei 1 beginnende Nummer - individuell für jede fläche. Bsp: Wand1 hat FensterA,FensterB , Wand2 hat Tür - dann wären die Abzugids : FensterA = 1, FensterB = 2 , Tür = 1 (da andere fläche ) */
  Abzugid: number = null;
  /**Fortlaufende, bei 1 beginnende, Nummer für jeden Raumbuch eintrag eines Raumes,erste eintrag 1, zweite 2,... */
  Raufmid: number = null;
  Bezeich: string = null;
  Zresult: number = null;
  IsAbzug: boolean = null;
  NotCalc: boolean = null;
  Aufmass: string = null;
  /**Fortlaufende, bei 1 beginnende, Nummer über ALLE einträge ALLER Räume von ALLEN Aufmassen (Autoinc) */
  Id: number = null;
  transferred: boolean = null;
  raumbPosUuid: string = null;

  // Appintern
  isBoden = false;
  isDecke = false;

  constructor(raumbuch: Raumbuch, raumbPos?: RaumbPos) {
    this.Uuid = UUID.UUID();
    assignIfPropertyExsits(this, raumbuch);

    // formell, damit die logik des baseservices weiter funktioniert - bei neuerstellung MUSS die Raumbpos angegeben werden
    if (raumbPos) this.raumbPosUuid = raumbPos.Uuid;
  }

  /**@description Hilfsfunktion um abgewandelte bezeichner für bauelemente zu geben */
  getBezeich(): string {
    if (this.IsAbzug) return '>' + this.Bezeich;
    return this.Bezeich;
  }

  fillFromMessung(
    currentEntries: Raumbuch[],
    raum: RaumbPos,
    messung: AufmassStrecke | Bauelement,
    raumHoeheOrElementHoehe: number,
    formel?: string
  ): void {
    this.Uuid = messung.uuid;
    this.Aufmid = raum.AufmId;
    this.Rpos_Id = raum.Rpos_ID;
    this.Bezeich = messung.bezeichnungLang;
    const isBauelement = messung instanceof Bauelement;
    let belongsToStreckeWithLineId: number;
    if (messung instanceof Bauelement) {
      let abzugFrom: Raumbuch;
      if (messung.abzugFromUuuid === 'boden') abzugFrom = currentEntries.find(entry => entry.isBoden);
      if (messung.abzugFromUuuid === 'decke') abzugFrom = currentEntries.find(entry => entry.isDecke);
      if (!(messung.abzugFromUuuid === 'decke' || messung.abzugFromUuuid === 'boden'))
        abzugFrom = currentEntries.find(entry => entry.Uuid === messung.abzugFromUuuid);
      belongsToStreckeWithLineId = abzugFrom.Lineid;
    }
    this.IsAbzug = isBauelement;
    this.NotCalc = false;
    this.Raufmid = getNextNumber(
      currentEntries.map(entry => entry.Raufmid),
      1
    );
    this.Lineid = !isBauelement
      ? getNextNumber(
          currentEntries.map(entry => entry.Lineid),
          1
        )
      : belongsToStreckeWithLineId;
    this.Abzugid = !isBauelement ? 0 : getNextAbzugId(currentEntries, belongsToStreckeWithLineId);
    this.isBoden = messung.isBoden;
    this.isDecke = messung.isDecke;
    if (!isNullOrUndefined(formel)) this.useFormel(formel);
    else this.calculateFromMessung(raumHoeheOrElementHoehe, messung);
    currentEntries.push(this);
  }

  private calculateFromMessung(raumHoehe: number, messung: AufmassStrecke | Bauelement) {
    const raumHoeheString = raumHoehe.toString().replace('.', ',');
    const laenge = messung.laengeGemessen;
    const laengeString = laenge.toString().replace('.', ',');
    const aufmassString = `${laengeString} * ${raumHoeheString}`;
    this.Aufmass = aufmassString?.replaceAll('.', ',');
    this.Zresult = raumHoehe * laenge;
  }

  useFormel(formel: string): void {
    if (formel === '') formel = '0';
    this.Aufmass = formel?.replaceAll('.', ',');
    this.Zresult = eval(formel?.replaceAll(',', '.').replaceAll('[', '').replaceAll(']', '')) as number;
  }

  createNewFromScratch(currentEntries: Raumbuch[], raum: RaumbPos): void {
    this.Uuid = UUID.UUID();
    this.Aufmid = raum.AufmId;
    this.Rpos_Id = raum.Rpos_ID;
    this.NotCalc = false;
    this.Raufmid = getNextNumber(
      currentEntries.map(entry => entry.Raufmid),
      1
    );
    this.IsAbzug = false;
  }

  /**@description Am Ende einer Erstellung eines neuen, freien Aufmaßes (nicht aus zeichnung) */
  fillLineIdAbzugId(currentEntries: Raumbuch[], abzugBezug?: Raumbuch): void {
    this.Lineid = !this.IsAbzug
      ? getNextNumber(
          currentEntries.map(entry => entry.Lineid),
          1
        )
      : abzugBezug.Lineid;
    this.Abzugid = !this.IsAbzug ? 0 : getNextAbzugId(currentEntries, abzugBezug.Lineid);
  }

  static toString(): string {
    return 'Raumbuch';
  }
}
