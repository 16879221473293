import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bss-switchbox',
  templateUrl: './bss-switchbox.component.html',
  styleUrls: ['./bss-switchbox.component.scss'],
})
export class BssSwitchboxComponent implements OnInit {
  @Input() labelText;
  @Input() disabled = false;
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  valueChanged(): void {
    this.valueChange.emit(this.value);
  }
}
