import { ErrorHandler, Injectable } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from '@handwerk-pwa/shared';
import { LogErrorToWebserviceService } from '../services/globalServices/logErrorToWebservice.service';
import { PWAErrorMessageAndStackTrace } from '../entities/models/PWAErrorMessageAndStackTrace';
import { HWGolbalsettingService } from '../services/globalServices/hwgolbalsetting.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  fileUrl: any;
  constructor(
    private dialogService: DialogService,
    private logErrorToWebserviceService: LogErrorToWebserviceService,
    private logger: NGXLogger,
    private globalSettingService: HWGolbalsettingService
  ) {}
  handleError(error: Error): void {
    const message = error.message ? error.message : error.toString();
    void StackTrace.fromError(error).then(async stackframes => {
      const stackString: string = stackframes
        .splice(0, 20)
        .map(function (sf) {
          return sf.toString();
        })
        .join('\n');
      this.logger.error('ErrorMessage:\n' + message + '\n\nStacktrace:\n' + stackString);
      if (await this.globalSettingService.getEntity('ErrorLoggingEnabled')) {
        const dialogResponse = await this.dialogService.openConfirmDialog(
          'Fehler in der Anwendung!',
          'Möchten Sie den Fehler in einer Textdatei sichern?',
          'Speichern',
          'Abbrechen',
          false,
          null,
          false
        );

        if (dialogResponse) {
          const blob = new Blob(['ErrorMessage:\n' + message + '\n\nStacktrace:\n' + stackString], {
            type: 'application/octet-stream',
          });
          this.downloadBlob('handwerkPWA_log.txt', blob);
        }
      }
      await this.logErrorToWebserviceService.LogErrorToWebservice(
        new PWAErrorMessageAndStackTrace(message, stackString)
      );
    });
  }

  public downloadBlob(fileName: string, blob: Blob): void {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }
}
