<div class="content-block bottom-padding" *ngIf="handleFieldjumpManually != undefined">
  <div class="form-block dx-card responsive-paddings">
    <dx-scroll-view [height]="'40vh'">
      <div style="text-align: center">
        <img id="aufmassImage" [src]="AufmassKonstrukt.pictureDataBase64" />
      </div>
    </dx-scroll-view>

    <dx-scroll-view [height]="'40vh'">
      <b>Wände</b>
      <div class="element-box" *ngFor="let strecke of AufmassKonstrukt.AufmassStrecken; let i = index">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="strecke.bezeichnung"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-suggestion-field
              [headline]="'Wählen Sie eine Flächenbezeichnung'"
              [dataTypeName]="'Flächenbezeichnung'"
              [(value)]="strecke.bezeichnungLang"
              [inputDataSource]="raumnamen"
              [customDisplayExpression]="'Bezeichng'"
            >
            </app-bss-suggestion-field>
          </div>
        </div>
        <div class="element-row">
          <div class="element-label text-label">Länge:</div>
          <div class="element-field">
            <app-bss-measuring-field
              [jumpToNext]="!handleFieldjumpManually"
              [keyboardPosition]="'bottom'"
              [title]="'Breite ' + strecke.bezeichnungLang + ' (' + strecke.bezeichnung + ')'"
              [fieldIndex]="i"
              [messwert]="strecke.laengeGemessen?.toString()"
              (messwertChange)="messwertChanged(strecke, $event, handleFieldjumpManually)"
              [unit]="'m'"
              [placeholder]="'Länge eingeben'"
              [distanceMeterMode]="distanceMeterMode"
              [beginningEmpty]="true"
              [useDxTemplate]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>
      </div>

      <div class="element-box" *ngIf="AufmassKonstrukt.Raumhoehe">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="AufmassKonstrukt.Raumhoehe.bezeichnung"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-measuring-field
              [jumpToNext]="!handleFieldjumpManually"
              [keyboardPosition]="'bottom'"
              [title]="'Raumhöhe'"
              [fieldIndex]="AufmassKonstrukt.AufmassStrecken.length"
              [messwert]="AufmassKonstrukt.Raumhoehe.laengeGemessen?.toString()"
              (messwertChange)="messwertChanged(AufmassKonstrukt.Raumhoehe, $event, handleFieldjumpManually)"
              [unit]="'m'"
              [placeholder]="'Raumhöhe eingeben'"
              [distanceMeterMode]="distanceMeterMode"
              [beginningEmpty]="true"
              [useDxTemplate]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>
      </div>

      <div class="element-box" *ngIf="wallsDone">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="AufmassKonstrukt.Deckenflaeche.bezeichnung"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-suggestion-field
              [headline]="'Wählen Sie eine Deckenflächenbezeichnung'"
              [dataTypeName]="'Deckenflächenbezeichnung'"
              [(value)]="AufmassKonstrukt.Deckenflaeche.bezeichnungLang"
              [inputDataSource]="raumnamen"
              [customDisplayExpression]="'Bezeichng'"
            >
            </app-bss-suggestion-field>
          </div>
        </div>
        <div class="element-row">
          <div class="element-label text-label">Formel:</div>
          <div class="element-field">
            <app-bss-textbox
              [placeholder]="'Formel eingeben'"
              [value]="AufmassKonstrukt.Deckenflaeche.formel?.replaceAll('.', ',')"
              (focusOut)="validateFormel($event, 'Deckenfläche')"
            >
            </app-bss-textbox>
          </div>
        </div>
      </div>

      <div class="element-box" *ngIf="wallsDone">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="AufmassKonstrukt.Bodenflaeche.bezeichnung"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-suggestion-field
              [headline]="'Wählen Sie eine Bodenflächenbezeichnung'"
              [dataTypeName]="'Bodenflächenbezeichnung'"
              [(value)]="AufmassKonstrukt.Bodenflaeche.bezeichnungLang"
              [inputDataSource]="raumnamen"
              [customDisplayExpression]="'Bezeichng'"
            >
            </app-bss-suggestion-field>
          </div>
        </div>
        <div class="element-row">
          <div class="element-label text-label">Formel:</div>
          <div class="element-field">
            <app-bss-textbox
              [placeholder]="'Formel eingeben'"
              [value]="AufmassKonstrukt.Bodenflaeche.formel?.replaceAll('.', ',')"
              (focusOut)="validateFormel($event, 'Bodenfläche')"
            >
            </app-bss-textbox>
          </div>
        </div>
      </div>

      <b *ngIf="AufmassKonstrukt?.Bauelemente?.length > 0">Bauelemente</b>
      <div class="element-box" *ngFor="let element of AufmassKonstrukt.Bauelemente; let j = index">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="element.bezeichnung"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-suggestion-field
              [headline]="'Wählen Sie eine Bezeichnung'"
              [dataTypeName]="'Bezeichnung'"
              [(value)]="element.bezeichnungLang"
              [inputDataSource]="raumnamen"
              [customDisplayExpression]="'Bezeichng'"
            >
            </app-bss-suggestion-field>
          </div>
        </div>
        <div class="element-row">
          <div class="element-label text-label">{{ element.vorlage.bezeichnungLaengegemessen }}:</div>
          <div class="element-field">
            <app-bss-measuring-field
              [jumpToNext]="!handleFieldjumpManually"
              [keyboardPosition]="'bottom'"
              [title]="
                element.vorlage.bezeichnungLaengegemessen +
                ' ' +
                element.bezeichnungLang +
                ' (' +
                element.bezeichnung +
                ')'
              "
              [fieldIndex]="AufmassKonstrukt.AufmassStrecken.length + 2 + (2 * j - 1)"
              [messwert]="element.laengeGemessen?.toString()"
              (messwertChange)="messwertChanged(element, $event, handleFieldjumpManually)"
              [unit]="'m'"
              [useDxTemplate]="true"
              [placeholder]="element.vorlage.bezeichnungLaengegemessen"
              [distanceMeterMode]="distanceMeterMode"
              [beginningEmpty]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>
        <div class="element-row" *ngIf="element.vorlage.felder.length > 1">
          <div class="element-label text-label">
            {{ element.vorlage.bezeichnungHoehe }}
          </div>
          <div class="element-field">
            <app-bss-measuring-field
              [jumpToNext]="!handleFieldjumpManually"
              [keyboardPosition]="'bottom'"
              [title]="element.vorlage.bezeichnungHoehe + ' ' + element.bezeichnungLang"
              [fieldIndex]="AufmassKonstrukt.AufmassStrecken.length + 2 + 2 * j"
              [messwert]="element.Hoehe?.toString()"
              [useDxTemplate]="true"
              (messwertChange)="messwertChanged(element, $event, handleFieldjumpManually, 'hoehe')"
              [unit]="'m'"
              [placeholder]="element.vorlage.bezeichnungHoehe"
              [distanceMeterMode]="distanceMeterMode"
              [beginningEmpty]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</div>

<dx-speed-dial-action
  [visible]="saveAble"
  icon="check"
  class="add-button"
  [ngClass]="{ aclass: !saveAble }"
  label="speichern"
  [index]="1"
  (onClick)="messungComplete(aufmass, raum, AufmassKonstrukt)"
>
</dx-speed-dial-action>
