import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { HWGolbalsettingService } from '../globalServices/hwgolbalsetting.service';
import { Textposition, HWMonteur } from 'apps/handwerkPWA/src/app/entities';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class MonteurService {
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private globalSettingservice: HWGolbalsettingService,
    private baseService: BaseService
  ) {}

  /**@description Holt die jeweiligen Kontakte.
   *  Schreibt sie in die IDB, löscht zuvor die jeweilige Tabelle in die geschrieben wird */
  async getMonteurFromWebserivce(userInfo: Userinfo, silent = false): Promise<HWMonteur> {
    if (!silent) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...aktualisiere Daten des angemeldeten Nutzers...');
    }
    const monteurData = await this.restService.returnData<HWMonteur>(
      userInfo,
      'Monteur/mandant/' + userInfo.mandant + '/adminname/' + userInfo.user + '/monteur/' + userInfo.monteur
    );
    if (isNullOrUndefined(monteurData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const monteur = new HWMonteur(monteurData);
    await this.globalSettingservice.setEntity(monteur.ZusatzBezeichnung, 'Zusatzbezeichnungen');
    const textpositionen: Textposition[] = this.getTextpositionen(monteur.TextVorlagen);
    await this.globalSettingservice.setEntity(textpositionen, 'Textvorlagen');
    // await this.globalSettingservice.setEntity(monteur.Formeln, 'Formeln');
    await this.writeMonteurToIDB(monteur, true);
    this.dialogService.closeLoadingDialog();
    return monteur;
  }

  /**@description Gets the currently logged in employee from the IDB */
  async getMonteurFromIDB(): Promise<HWMonteur> {
    const monteurData = await this.controllerService.getData<HWMonteur[]>('HWMonteur');
    const monteur = new HWMonteur(monteurData[0]);
    return monteur;
  }

  /**@description Gibt true zurück, wenn ein Nutzer eingeloggt ist */
  async userSignedIn(): Promise<boolean> {
    const userInfo = await this.globalSettingservice.getUserinfo();
    const signedIn = !isNullOrUndefined(userInfo?.pin);

    return signedIn;
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description Schreibt die Monteure in die IDB */
  private async writeMonteurToIDB(monteur: HWMonteur, clear: boolean) {
    if (clear) {
      await this.controllerService.clearStore('HWMonteur');
    }
    await this.controllerService.setData('HWMonteur', [monteur]);
  }

  private getTextpositionen(positionData: any): Textposition[] {
    const positionen: Textposition[] = [];
    if (isNullOrUndefined(positionData)) {
      return positionen;
    }

    return this.baseService.rawDataArrayToObjectsArray(Textposition, positionData);
  }
}
