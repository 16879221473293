import { UUID } from 'angular2-uuid';
import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { RaumbPos } from '../models/aufmass/RaumbPos';
import { Aufmass } from './Aufmass';
import { IndexedDBTypes } from './dbType';

export class RVorlage extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('RVorlage') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') Uuid: string = null;
  @IndexedDBTypes.IndexField('number') Haustyp: number = null;
  @IndexedDBTypes.DataField('number') Raum: number = null;
  @IndexedDBTypes.DataField('number') Etage: number = null;
  @IndexedDBTypes.DataField('number') Wohnung: number = null;
  @IndexedDBTypes.DataField('string') Bezeich: string = null;
  constructor(rvorlage: RVorlage) {
    super();
    assignIfPropertyExsits(this, rvorlage);
  }

  public isRaumbuchVorlage(): boolean {
    return this.Raum === 0 && this.Wohnung === 0 && this.Etage === 0;
  }

  /**@description Konvertiert eine Vorlage in eine konkrete Raumbuchposition */
  toRaumbuchposition(aufmass: Aufmass): RaumbPos {
    const raumbuchPosition = new RaumbPos(null);
    raumbuchPosition.Uuid = UUID.UUID();
    raumbuchPosition.AufmId = aufmass.AufmId;
    raumbuchPosition.Bezeich = this.Bezeich;
    raumbuchPosition.Stw_ID = this.Etage;
    raumbuchPosition.Wng_ID = this.Wohnung;
    raumbuchPosition.Raumb_ID = this.Raum;
    raumbuchPosition.NrAufmId = 1;
    delete raumbuchPosition.items;
    delete raumbuchPosition.text;
    return raumbuchPosition;
  }

  static toString(): string {
    return 'RVorlage';
  }
}
