import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'apps/handwerkPWA/src/environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private logger: NGXLogger, private analytics: AngularFireAnalytics) {}

  /**@description Wenn es sich um die Produktionumgebung handelt, wird ein Firebase Event gesendet */
  logAnalyticsEvent(eventName: string, eventParams?: { [key: string]: any }): void {
    const productionEnviroment = (environment.production && window.location.hostname.startsWith('handwerk.')) || true;
    if (!productionEnviroment) return;
    void this.analytics.logEvent(eventName, eventParams);
    // const params = Object.keys(eventParams).map(param => param);
    // const values = Object.values(eventParams).map(value => value as string);
    // this.logger.log('Firebaseevent: ' + eventName + '-- versendet');
    // if (params?.length > 0)
    //   this.logger.log('mit Paremeter: ' + params.toString() + '  -  ' + values?.toString());
  }
}
