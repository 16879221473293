import { Injectable } from '@angular/core';
import { DialogInformation } from 'libs/shared/src/lib/entities';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { ControllerService } from './controller.service';
import { DialogService } from '@handwerk-pwa/shared';
import { FirstTime } from '../../entities/models/FirstTime';
import { HWGolbalsettingService } from './hwgolbalsetting.service';

@Injectable({
  providedIn: 'root',
})
export class FirstTimeService {
  constructor(
    private controllerService: ControllerService,
    private dialogService: DialogService,
    private globalSettingService: HWGolbalsettingService
  ) {}

  /**@description Guckt ob etwas zum ersten mal aufgerufen wird, ist dies nicht der fall, wird false und zusätzliche daten zurückgegeben,  ansonsten true und ein key wird angelegt */
  async isFirstTime(
    name: string,
    additionalResourceData: any = null,
    messageOnFirsttime: DialogInformation = null
  ): Promise<{ isFirstTime: boolean; additionalData: any }> {
    const firstTimeArray = await this.getFirstTimeArray();
    const entry = firstTimeArray.find(firstTimeEntry => firstTimeEntry.name === name);
    if (entry) {
      return { isFirstTime: false, additionalData: entry.data };
    }
    const newEntry = new FirstTime(name, additionalResourceData);
    firstTimeArray.push(newEntry);
    await this.controllerService.deleteData('HWGlobalSetting', 'FirstTime');
    await this.setFirstTimeArray(firstTimeArray);
    if (!isNullOrUndefined(messageOnFirsttime))
      await this.dialogService.openDialogFromDialogInformation(messageOnFirsttime);
    return { isFirstTime: true, additionalData: null };
  }

  async setFirstTimeArray(firstTimeArray: FirstTime[]): Promise<void> {
    const key = 'FirstTime';
    await this.controllerService.setData('HWGlobalSetting', [{ key, value: firstTimeArray }]);
  }

  /**@description Holt die Informationen zu den Dingen,  */
  async getFirstTimeArray(): Promise<FirstTime[]> {
    return this.globalSettingService.getEntity<FirstTime[]>('FirstTime');
  }
}
