import { Injectable } from '@angular/core';
import { ControllerService } from '../../services/globalServices/controller.service';
import { SyncService } from '../../services/globalServices/sync.service';
import { HWGolbalsettingService } from '../../services/globalServices/hwgolbalsetting.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from '@handwerk-pwa/shared';
import { FirebaseService } from 'apps/handwerkPWA/src/app/services/globalServices/firebase.service';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BackgroundService } from 'apps/handwerkPWA/src/app/services/globalServices/background.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { FirstTimeService } from 'apps/handwerkPWA/src/app/services/globalServices/first-time.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Mobiledevices } from 'libs/shared/src/lib/entities';
import { LocalstorageService } from '../../services/globalServices/localstorage.service';
import { AppOnlySettings } from '../../entities';
import { FirstTime } from '../../entities/models/FirstTime';
import { StateService } from '../../services/globalServices/state.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private controllerService: ControllerService,
    private syncService: SyncService,
    private globalSettingService: HWGolbalsettingService,
    private logger: NGXLogger,
    private dialogService: DialogService,
    private firebaseService: FirebaseService,
    private backgroundService: BackgroundService,
    private routingService: RoutingService,
    private firstTimeService: FirstTimeService,
    private restService: RestService,
    private deviceService: DeviceDetectorService,
    private localStorageService: LocalstorageService,
    private stateService: StateService
  ) {}

  /**@description Logt den User aus - löscht alle Daten */
  async logOut(inUpdateRoutine = false): Promise<void> {
    // login ist schlecht zum ansteuern nach einem pwaupdate, da eine seite refreshed werden muss - von der logincomponente aber wird man eventuell zu früh weitergeleitet
    const routeAfterLogout = inUpdateRoutine ? '/impressum' : '/Login';
    try {
      this.backgroundService.stopBackgroundTasks();
      const {
        userInfo,
        entdeckenLastModifiedBase,
        entdeckenLastModifiedPremium,
        firstTimeArray,
        appOnlySettings,
      } = await this.getDataToKeep();
      if (isNullOrUndefined(userInfo) || isNullOrUndefined(userInfo.pin)) {
        this.logger.error('Fehler beim regulären abmelden - Userinfo oder pin nicht vorhanden');
        await this.routingService.navigateTo(routeAfterLogout);
        return;
      }
      await this.syncService.pushAllUnpushedData(userInfo, true, false);
      await this.updateDeviceInformationInHandwerkDb(userInfo, true);
      const storeNames = [
        'HWAddress',
        'HWBeleg',
        'HWContact',
        'HWContactperson',
        'HWEmailData',
        'HWMonteur',
        'HWNachricht',
        'HWObjectaddress',
        'HWOffeneposten',
        'HWRepairOrder',
        'HWTermin',
        'HWUmsatz',
        'HWFile',
        'HWAnlage',
        'HWDatenblatt',
        'HWRepairOrderItem',
        'ServiceAuftrag',
        'Raumname',
        'Projekt',
        'Aufmass',
        'Medien',
      ];
      if (!inUpdateRoutine)
        // ist man in der updateroutine,soll dieser store nicht gelöscht werden
        storeNames.push('HWGlobalSetting');
      for (const storeName of storeNames) {
        this.logger.log(storeName);
        await this.controllerService.clearStore(storeName);
      }
      if (!inUpdateRoutine)
        // ist man in der updateroutine benötigt man anschließend den pin um neu anzumelden - sonst leeren
        userInfo.pin = undefined;
      await this.setDataToKeep(
        userInfo,
        entdeckenLastModifiedBase,
        entdeckenLastModifiedPremium,
        firstTimeArray,
        appOnlySettings
      );
    } catch (err) {
      this.logger.error('Fehler beim regulären abmelden: ' + err);
    } finally {
      // Damit in jedem Fall zurückgerouted werden kann
      this.stateService.loggedIn.next(false);
      await this.routingService.navigateTo(routeAfterLogout);
      this.localStorageService.clearLocalSessionData();
    }
  }

  /**@description Holt sich die Daten, die auch nach Abmelden behalten werden sollen */
  private async getDataToKeep() {
    const userInfo = await this.globalSettingService.getUserinfo();
    const entdeckenLastModifiedBase = await this.globalSettingService.getEntity('entdeckenLastModifiedBase');
    const entdeckenLastModifiedPremium = await this.globalSettingService.getEntity('entdeckenLastModifiedPremium');
    const firstTimeArray = await this.firstTimeService.getFirstTimeArray();
    const appOnlySettings = await this.globalSettingService.getAppOnlySettings();
    return { userInfo, entdeckenLastModifiedBase, entdeckenLastModifiedPremium, firstTimeArray, appOnlySettings };
  }

  /**@description Speichert nach zurücksetzen der Datenbank die Daten, die auch nach Abmelden behalten werden sollen */
  private async setDataToKeep(
    userInfo: Userinfo,
    entdeckenLastModifiedBase: unknown,
    entdeckenLastModifiedPremium: unknown,
    firstTimeArray: FirstTime[],
    appOnlySettings: AppOnlySettings
  ) {
    await this.globalSettingService.setEntity(userInfo, 'Userinfo');
    await this.globalSettingService.setEntity(entdeckenLastModifiedBase, 'entdeckenLastModifiedBase');
    await this.globalSettingService.setEntity(entdeckenLastModifiedPremium, 'entdeckenLastModifiedPremium');
    await this.firstTimeService.setFirstTimeArray(firstTimeArray);
    await this.globalSettingService.setEntity(appOnlySettings, 'AppOnlySettings');
  }

  /**@description Logt den Nutzer über seine userinfo ein */
  async loginUser(userInfo: Userinfo, withSync = true): Promise<void> {
    if (!this.globalSettingService.getIsProductionAndProductionUrl()) {
      this.logger.log(userInfo);
    }
    const deviceInfo = this.deviceService.getDeviceInfo();
    userInfo.assignDeviceInfo(deviceInfo);
    userInfo.unsetPwaUpdateDoneFlag();
    userInfo = await this.restService.updateAllocationInUserinfo(userInfo);
    const allocation = userInfo.currentAllocation;
    const statusCode = allocation?.statusCode;
    if (statusCode !== 0) {
      void this.errorHandling(statusCode);
      return;
    }
    this.firebaseService.logAnalyticsEvent('Brandings', {
      Combined_Key: userInfo.uuid + '_' + userInfo.monteur,
      UUID: userInfo.uuid,
      Monteur: userInfo.monteur,
      Branding: userInfo.branding,
    });
    void this.dialogService.openLoadingDialog(
      'Anmeldung',
      'Sie werden angemeldet und Ihre Daten werden synchronisiert.'
    );
    const errorLogSetting = await this.globalSettingService.getEntity('ErrorLoggingEnabled');
    if (errorLogSetting === null || errorLogSetting === undefined)
      await this.globalSettingService.setEntity(false, 'ErrorLoggingEnabled');
    await this.enterMainView(userInfo, withSync);
    this.dialogService.closeLoadingDialog();
  }

  private errorHandling(statusCode: number) {
    if (statusCode === 404) {
      this.dialogService.openInformDialog(
        'Verbindungsfehler',
        'Es existiert keine gültige Allokation. Prüfen Sie ob der Webservice gestartet ist und Verbindung zum Internet hat.',
        'Ok'
      );
      return;
    }
    if (statusCode === 405) {
      this.dialogService.openInformDialog(
        'Verbindungsfehler!',
        'Ihre Allokation ist abgelaufen. Prüfen Sie ob der Webservice weiterhin ordnugsgemäß funktioniert. ',
        'Ok'
      );
      return;
    }
    this.dialogService.openInformDialog('Verbindungsfehler(Allokation)!', 'Statuscode: ' + statusCode, 'OK');
    return;
  }

  /**@description Holt alle holbaren Daten, startet backgroundtask und subscription und registriert das Device für Push Mainview */
  private async enterMainView(userInfo: Userinfo, withSync: boolean) {
    let success = true;
    if (withSync) success = await this.syncService.getAllDataFromWebService(userInfo, false, true);
    else {
      await this.syncService.SyncDataForAppStart(userInfo, false);
    }
    if (!success) return;
    await this.updateDeviceInformationInHandwerkDb(userInfo);
    const intervallMinutes = await this.backgroundService.getCurrentSyncIntervall();
    void this.backgroundService.startBackgroundSyncIntervall(userInfo, intervallMinutes);
    void this.backgroundService.doDailyCheck();
    this.stateService.loggedIn.next(true);
    await this.routingService.navigateTo('/startseite');
    if (!withSync) {
      this.routingService.reload();
    }
  }

  /**@description Neuer Endpunkt zum einheitlichen Verwalten der Geräteinformationen im Handwerk */
  private async updateDeviceInformationInHandwerkDb(userInfo: Userinfo, unregisterDevice = false): Promise<void> {
    if (unregisterDevice) userInfo.Device.unregister();
    const url = 'DeviceManagement';
    void this.dialogService.openLoadingDialog('Synchronisation', '...aktualisiere Geräteinformationen...');
    const deviceAnswer = await this.restService.returnData<Mobiledevices>(userInfo, url, userInfo.Device);
    if (deviceAnswer) {
      userInfo.Device.assignId(deviceAnswer.Id);
      await this.globalSettingService.setEntity(userInfo, 'Userinfo');
    }
    this.dialogService.closeLoadingDialog();
  }
}
