<div class="messungenBox" [ngClass]="{ 'dx-field': useDxTemplate }">
  <div
    class="messungenLabel"
    style="margin-top: 3px; font-size: 17px"
    [ngClass]="{ 'dx-field-label': useDxTemplate }"
    *ngIf="label"
  >
    {{ label }}
  </div>
  <div class="messungenField" style="margin-top: 3px; font-size: 17px" *ngIf="!noSollwert">{{ sollwert }}</div>
  <div
    class="messungenFieldDoubleWidth"
    [ngClass]="{ 'dx-field-value': useDxTemplate }"
    *ngIf="measuringFieldClass != 'measuringFieldDisplayonly'"
  >
    <app-bss-number-popup
      [position]="keyboardPosition"
      [distanceMeterMode]="true"
      [disabled]="disabled"
      [minusAllowed]="minusAllowed"
      [id]="id + fieldIndex"
      #mengenInput
      class="customInputStyle"
      [ignoreLabelStyles]="true"
      [title]="title"
      [description]="label"
      [inputValue]="measurementfunc()"
      [ignoreLabelStyles]="true"
      (outputValue)="valueChanged($event)"
      [mengenTyp]="unit"
      [placeholder]="placeholder"
      [rtlEnabled]="rtlEnabled"
      [showArrows]="false"
      [DecimalPlaces]="getDecimalPlacesLength()"
    >
    </app-bss-number-popup>
  </div>
</div>
