<div *ngIf="formeln" class="card">
  <app-bss-search-popup
    [buttonText]="'Formel aus Vorlage'"
    (click)="formelString = ''"
    [buttonStyle]="'bss-button primary-button'"
    [headline]="'Wählen Sie eine Formel aus'"
    [inputDataSource]="formeln"
    [customDisplayExpressions]="['Kurzname']"
    (selectedValue)="selectedFormel = $event"
  >
  </app-bss-search-popup>

  <div *ngIf="selectedFormel">
    <br />
    <button (click)="clearFormelSelection()" class="bss-button primary-button" style="width: 100%">
      Formel abwählen
    </button>
  </div>

  <div *ngIf="selectedFormel">
    <br />
    <app-bss-plain-text-box [labelText]="'Bezeichnung'" [value]="selectedFormel.Kurzname"> </app-bss-plain-text-box>
    <br />

    <div style="display: grid">
      <img style="margin: auto" *ngIf="selectedFormel.ImageBase64" [src]="getImage(selectedFormel.ImageBase64)" />
    </div>

    <br />
    <app-bss-plain-text-box
      *ngIf="selectedFormel"
      [labelText]="'Formel'"
      [value]="selectedFormel.Formel.replaceAll('[', '').replaceAll(']', '')"
    >
    </app-bss-plain-text-box>
    <br />
    <div *ngFor="let pair of selectedFormel.ValueNamePairs">
      <app-bss-number-popup
        [labelText]="pair.displayName"
        [title]="'Eingabe: ' + pair.displayName"
        [id]="'messwert'"
        (outputValue)="messwertChanged($event, pair.displayName)"
        [mengenTyp]="'m'"
        [placeholder]="pair.displayName"
        [rtlEnabled]="true"
        [inputValue]="pair.value"
        [minusAllowed]="false"
        [showArrows]="false"
        [DecimalPlaces]="getDecimalPlaces(pair)"
      >
      </app-bss-number-popup>
      <br />
    </div>
  </div>

  <br />

  <div *ngIf="!selectedFormel" class="or-box">oder</div>

  <br *ngIf="!selectedFormel" />

  <app-bss-textbox
    *ngIf="!selectedFormel"
    [labelText]="'Maßkette eingeben :'"
    [(value)]="formelString"
    (focusOut)="validateFormel($event)"
  >
  </app-bss-textbox>

  <app-bss-textbox
    *ngIf="selectedFormel && allMeasured"
    [labelText]="'Werteformel :'"
    [(value)]="formelString"
    (focusOut)="validateFormel($event)"
  >
  </app-bss-textbox>
</div>
