import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';

export enum ConnectionDialogues {
  GetData = 1,
  Update = 2,
  PushData = 3,
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(private dialogService: DialogService) {}

  async CheckOnline(dialogOption?: ConnectionDialogues): Promise<boolean> {
    const isOnline = navigator.onLine;
    if (isOnline) return true;

    switch (dialogOption) {
      case ConnectionDialogues.GetData:
        await this.dialogService.openWaitingConfirmDialog(
          'Keine Internetverbindung',
          'Zum beziehen der Daten wird eine Internetverbindung benötigt',
          'Ok'
        );
        break;
      case ConnectionDialogues.Update:
        await this.dialogService.openWaitingConfirmDialog(
          'Update',
          'Das Update kann nur bei bestehender Internetverbindung durchgeführt werden',
          'Ok'
        );
        break;
      case ConnectionDialogues.PushData:
        await this.dialogService.openWaitingConfirmDialog(
          'Keine Verbindung zum Handwerk',
          'Zur Zeit besteht keine Internetverbindung. Sobald wieder eine Internetverbindung zur Verfügung steht, werden die Daten automatisch übertragen.',
          'OK'
        );
        break;
    }
    return false;
  }
}
