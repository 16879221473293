import { Form } from '../entities/models/aufmass/Form';

export const Formen: Form[] = [
  {
    Bezeichner: 'Quadrat',
    iconPath: 'assets/icons/square.jpg',
    PunktStrecke: [
      { xKoordinate: 1, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 3 },
      { xKoordinate: 3, yKoordinate: 3 },
      { xKoordinate: 3, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 1 },
    ],
  },
  {
    Bezeichner: 'Rechteck hoch',
    iconPath: 'assets/icons/rectangleHigh.png',
    PunktStrecke: [
      { xKoordinate: 1, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 4 },
      { xKoordinate: 3, yKoordinate: 4 },
      { xKoordinate: 3, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 1 },
    ],
  },
  {
    Bezeichner: 'Rechteck breit',
    iconPath: 'assets/icons/rectangle.png',
    PunktStrecke: [
      { xKoordinate: 0.5, yKoordinate: 1 },
      { xKoordinate: 0.5, yKoordinate: 2 },
      { xKoordinate: 3.5, yKoordinate: 2 },
      { xKoordinate: 3.5, yKoordinate: 1 },
      { xKoordinate: 0.5, yKoordinate: 1 },
    ],
  },
  // {
  //     Bezeichner: 'Tetris',
  //     PunktStrecke: [{ xKoordinate: 1, yKoordinate: 1 }, { xKoordinate: 1, yKoordinate: 2 },
  //     { xKoordinate: 2, yKoordinate: 2 }, { xKoordinate: 2, yKoordinate: 3 }, { xKoordinate: 3, yKoordinate: 3 },
  //     { xKoordinate: 3, yKoordinate: 1 }, { xKoordinate: 1, yKoordinate: 1 }]
  // },
  {
    Bezeichner: 'L-Form',
    iconPath: 'assets/icons/lIcon.png',
    PunktStrecke: [
      { xKoordinate: 1, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 4.5 },
      { xKoordinate: 3, yKoordinate: 4.5 },
      { xKoordinate: 3, yKoordinate: 3.5 },
      { xKoordinate: 2, yKoordinate: 3.5 },
      { xKoordinate: 2, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 1 },
    ],
  },
  {
    Bezeichner: 'L-Form gespiegelt',
    iconPath: 'assets/icons/lIconMirrored.png',
    PunktStrecke: [
      { xKoordinate: 2, yKoordinate: 1 },
      { xKoordinate: 2, yKoordinate: 3.5 },
      { xKoordinate: 1, yKoordinate: 3.5 },
      { xKoordinate: 1, yKoordinate: 4.5 },
      { xKoordinate: 3, yKoordinate: 4.5 },
      { xKoordinate: 3, yKoordinate: 1 },
      { xKoordinate: 2, yKoordinate: 1 },
    ],
  },
  {
    Bezeichner: 'L-Form oben',
    iconPath: 'assets/icons/lIconTop.png',
    PunktStrecke: [
      { xKoordinate: 1, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 4.5 },
      { xKoordinate: 2, yKoordinate: 4.5 },
      { xKoordinate: 2, yKoordinate: 2 },
      { xKoordinate: 3, yKoordinate: 2 },
      { xKoordinate: 3, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 1 },
    ],
  },
  {
    Bezeichner: 'L-Form oben gespiegelt',
    iconPath: 'assets/icons/lIconTopMirrored.png',
    PunktStrecke: [
      { xKoordinate: 1, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 2 },
      { xKoordinate: 2, yKoordinate: 2 },
      { xKoordinate: 2, yKoordinate: 4.5 },
      { xKoordinate: 3, yKoordinate: 4.5 },
      { xKoordinate: 3, yKoordinate: 1 },
      { xKoordinate: 1, yKoordinate: 1 },
    ],
  },

  // {
  //     Bezeichner: 'Dreieck (rechtwinklig)',
  //     PunktStrecke: [{ xKoordinate: 1, yKoordinate: 1 }, { xKoordinate: 1, yKoordinate: 3.5 },
  //     { xKoordinate: 3.5, yKoordinate: 3.5 }, { xKoordinate: 1, yKoordinate: 1 }]
  // },
  // {
  //     Bezeichner: 'Dreieck (gleichschenklig)',
  //     PunktStrecke: [{ xKoordinate: 1.5, yKoordinate: 1 }, { xKoordinate: 0, yKoordinate: 4 },
  //     { xKoordinate: 3, yKoordinate: 4 }, { xKoordinate: 1.5, yKoordinate: 1 }]
  // }
];
