<div class="card serviceauftrag-card">
  <div class="flexContainer" *ngIf="anlage && serviceAuftrag" style="flex-direction: column">
    <div class="flexContainerItem">
      <div class="flexContainer">
        <div class="flexContainerItem" (click)="goToServiceauftragDetail()">
          <b>Serviceauftrag {{ serviceAuftrag.getNummer() }}</b
          ><br />
          <b>Termin {{ serviceAuftrag.TerminObject?.start }}</b
          ><br />
          <b>Betreff: {{ serviceAuftrag.getBetreff() }}</b
          ><br />
          <p>Status: {{ serviceAuftrag.getStatusText() }}</p>
          <p>{{ anlage.Anlagendaten.SUCH }}</p>
          <p>Typ: {{ anlage.Anlagendaten.Anlagentyp }}</p>
          <p>Kunde: {{ serviceAuftrag.getKunde().SUCH }}</p>
        </div>
        <div *ngIf="extended" class="flexContainerItem standort" (click)="goToServiceauftragDetail()">
          <b>Standort:</b><br />
          <p>{{ anlage.Anlagenstandort.NAME }}</p>
          <p>{{ anlage.Anlagenstandort.STRASSE }}</p>
          <p>{{ anlage.Anlagenstandort.PLZ }} {{ anlage.Anlagenstandort.ORT }}</p>
        </div>
        <div *ngIf="extended" (click)="toggleContactBar()" class="boxWithToggle" style="padding: 0 15px">
          <i class="dx-icon dx-icon-chevrondown"></i>
        </div>
      </div>
    </div>
    <div *ngIf="extended && showContactBar" class="flexContainerItem">
      <app-bss-contact-bar
        [contactInformationArray]="contactInformationArrayAnlageAnsprechpartner"
        [dataObject]="anlage.Anlagenstandort"
        [telefonDescription]="true"
      >
      </app-bss-contact-bar>
    </div>
  </div>
</div>
