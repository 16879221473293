import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';
import { HWFileArrayGetUniques, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { HWGolbalsettingService } from '../globalServices/hwgolbalsetting.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { DialogService } from '@handwerk-pwa/shared';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { Documentservice } from '../globalServices/document.service';
import { HWFile, HWAddress } from 'apps/handwerkPWA/src/app/entities';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BaseService } from './base.service';
import { sortAddresses } from 'apps/handwerkPWA/src/app/helper/entities/HWAddress/sortHelper';
import { UUID } from 'angular2-uuid';
import { hasAddressRight } from '../../helper/entities/HWAddress/addressRightHelper';
import { Mailservice } from 'libs/shared/src/lib/services/mail.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private globalSettingService: HWGolbalsettingService,
    private rechteService: RechteService,
    private documentService: Documentservice,
    private dialogService: DialogService,
    private baseService: BaseService,
    private mailService: Mailservice
  ) {}

  /**@description Holt alle Adressen, für die der user das Recht zur Anzeige hat */
  async getAllDisplayable(): Promise<HWAddress[]> {
    const allAdresses = await this.baseService.getAll(HWAddress);
    const addressRights = await this.getAddressRights();
    const displayableAdresses = allAdresses.filter(address => hasAddressRight(addressRights, address.ADRTYP, 'show'));
    return sortAddresses(displayableAdresses);
  }

  /**@description Holt alle Adressen aus der IDB */
  async getAll(): Promise<HWAddress[]> {
    return sortAddresses(await this.baseService.getAll(HWAddress));
  }

  /**
   * @description Holt alle Adressen aus der IDB mit bestimmten Suchkriterien
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async getAllBy(selector: string, value: any): Promise<HWAddress[]> {
    return await this.baseService.getAllBy(HWAddress, selector, value);
  }

  /**
   * @description Holt eine Adressen aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async findOneBy(selector: string, value: any): Promise<HWAddress> {
    return await this.baseService.findOneBy(HWAddress, selector, value);
  }

  /**
   * @description Löscht Adressen mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(HWAddress, selector, value);
  }

  /**
   * @description Speichert die übergebene Adresse sowohl im Webservice, als auch in lokaler Datenbank
   * @param userInfo aktueller UserInfo
   * @param address Adresse die gespeichert werden soll
   * @param silent true/false ob eine Meldung beim Speichern angezeigt werden soll
   * @return Adresse die gespeichert wurde
   * */
  async save(userInfo: Userinfo, address: HWAddress, silent = false): Promise<HWAddress> {
    const addressToSave = await this.pushToWebservice(userInfo, address, silent);
    await this.destroy('KU_NR', address.KU_NR);
    await this.saveLocal([addressToSave]);
    return addressToSave;
  }

  /**
   * @description Synchronisiert alle Kunden-, Mitarbeiter-, Freie- und Lieferantenadressen mit dem Webservice
   * @param userInfo aktueller UserInfo
   * @param showMessage true/false ob eine Meldung angezeigt werden soll
   * @return alle geholte Adressen
   * */
  async sync(userInfo: Userinfo, showMessage = true): Promise<HWAddress[]> {
    if (showMessage) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Adressen...');
    }

    await this.pushAllUnpushedAddresses(userInfo);
    const addresses = await this.getAllAddressesFromWebservice(userInfo, showMessage);

    this.dialogService.closeLoadingDialog();
    return addresses;
  }

  /**
   * @description Kreiert eine neue Adresse und schreibt sie global wie zuvor
   * */
  async createNewAddress(): Promise<HWAddress> {
    const newAddress = new HWAddress(null);
    newAddress.KU_NR = '-1';
    newAddress.ADRTYP = 'K';
    newAddress.LAND = 'D';
    newAddress.GEBURTSTAG = '01.01.1970 00:00:00';
    newAddress.LIEFERSPERRE = false;
    const userInfo = await this.globalSettingService.getUserinfo();
    newAddress.mandant = userInfo.mandant;
    newAddress.Guid = UUID.UUID();
    return newAddress;
  }

  /**
   * @description setzt lokal alle Medien von Adressen zurück
   * */
  async resetImagesInIDB(): Promise<void> {
    const allAddreses = await this.getAll();
    for (const address of allAddreses) {
      for (const file of address.Files) {
        file.Data = null;
      }
    }
    await this.destroyAllLocalAddresses();
    await this.saveLocal(allAddreses);
  }

  /**
   * TODO eventuelle Auslagerung in ein Service für Dokumente
   * TODO refactoring
   * @description Überschreibt das Dokument innerhalb einer Adresse in der IDB
   * @param bssFile neue Version vom Dokument mit dem überschrieben wird
   * */
  async updateDocumentInAdress(bssFile: HWFile): Promise<void> {
    const kundenNummer = bssFile.Kundennummer;
    const address = await this.findOneBy('KU_NR', kundenNummer);
    let index = 0;
    for (const tempFile of address.Files) {
      if (tempFile.Name === bssFile.Name) {
        address.Files[index] = bssFile;
        break;
      }
      index++;
    }
    await this.destroy('KU_NR', address.KU_NR);
    await this.saveLocal([address]);
  }

  /**
   * TODO eventuelle Auslagerung in ein Service für Dokumente
   * TODO refactoring
   *
   * @description Überschreibt die jeweilige Adresse lokal in der IDB
   * */
  async updateDocumentsInAdressLocally(documents: HWFile[]): Promise<void> {
    const kundenNummernToUpdate = documents?.map(document => document.Kundennummer);
    for (const kundenNummer of kundenNummernToUpdate) {
      const address = await this.findOneBy('KU_NR', kundenNummer);
      address.Files = documents.filter(object => object.Kundennummer === kundenNummer);
      await this.destroy('KU_NR', address.KU_NR);
      await this.saveLocal([address]);
    }
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**
   * @descirption Pusht eine Adresse zum Webservice
   * @param userInfo aktueller UserInfo
   * @param address die zu pushende Adresse
   * @param silent true/false ob eine Meldung ausgegeben werden soll
   * @returns gibt bei Erfolg neue Adresse zurück, ansonsten die gleiche Adressen, die übergeben wurde
   */
  private async pushToWebservice(userInfo: Userinfo, address: HWAddress, silent: boolean) {
    const newAddressData = await this.restService.returnData<HWAddress>(userInfo, 'SetAdresse', address, silent);

    if (!isNullOrUndefined(newAddressData) && !(newAddressData.toString() === '503')) {
      const newAddress = new HWAddress(newAddressData);
      if (address.Files) newAddress.Files = address.Files;
      return newAddress;
    }

    return address;
  }

  /**
   * @description speichert übergebene Adresse lokal in die Datenbank
   * @param addresses Adressen die lokal gespeichert werden sollen
   */
  private async saveLocal(addresses: HWAddress[]) {
    await this.controllerService.setData('HWAddress', addresses);
  }

  /**
   * @description holt alle Adressen
   * @param userInfo aktueller UserInfo
   * @param showMessage true/false ob eine Meldung angezeigt werden soll
   * @returns alle geholte Adressen
   */
  private async getAllAddressesFromWebservice(userInfo: Userinfo, showMessage = true): Promise<HWAddress[]> {
    const addresses: HWAddress[] = [];
    const newAddressesData = await this.restService.returnData<HWAddress[]>(
      userInfo,
      'getAllAddresses/mandant/' +
        userInfo.mandant +
        '/GEAENDERT/' +
        userInfo.geaendert +
        '/username/' +
        userInfo.monteur,
      null,
      !showMessage
    );

    if (isNullOrUndefined(newAddressesData)) return addresses;

    for (const addressData of newAddressesData) {
      addressData.Files = HWFileArrayGetUniques(addressData.Files);

      const leaveString = (addressData.LeaveDate as unknown) as string;
      if (leaveString) {
        addressData.LeaveDate = new Date(JSON.parse(leaveString) as Date);
      }

      addresses.push(new HWAddress(addressData));
    }

    await this.destroyAllLocalAddresses();
    await this.saveLocal(addresses);

    return addresses;
  }

  /**
   * @description Pusht alle nicht gepushte Adressen zum Webservice
   * @param userInfo aktueller UserInfo
   */
  private async pushAllUnpushedAddresses(userInfo: Userinfo) {
    const allAddreses = await this.getAll();
    const unpushedElements = allAddreses.filter(object => object.KU_NR === '-1');

    for (const unpushedElement of unpushedElements) await this.pushToWebservice(userInfo, unpushedElement, true);

    const unpushedDocuments = this.documentService.getUnpushedFilesOld(allAddreses, []);
    await this.documentService.sendUnpushedImagesToWebservice(userInfo, unpushedDocuments);
  }

  /**
   * @description Holt alle Rechte die mit Adressen zu tun haben
   * @returns Adressenrechte
   */
  private async getAddressRights() {
    const rechte = this.rechteService.getCurrentRight();
    const mitarbeiterRechte = rechte.Employeerights;
    const adressRights = mitarbeiterRechte.Adressrights;

    return adressRights;
  }

  /**
   * @description Löscht alle Adressen lokal in der Datenbank
   */
  private async destroyAllLocalAddresses() {
    await this.controllerService.clearStore('HWAddress');
  }
}
