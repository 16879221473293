<dx-popup
  [width]="'95%'"
  [maxWidth]="'500px'"
  [title]="InputFile.getOriginalName()"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
  (onHidden)="closePopup()"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <button class="bss-button primary-button fullWidth" (click)="openInFullscreen(InputFile)">
        In neuem Tab öffnen
      </button>

      <br />
      <br /><br />

      <pinch-zoom id="pinch" [backgroundColor]="'rgba(255, 255, 255, 1)'">
        <img [src]="InputFile.Data" />
      </pinch-zoom>

      <div *ngIf="description">
        <br /><br />
        <app-bss-textarea [labelText]="'Beschreibung'" [value]="description"></app-bss-textarea>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
