import { Component, OnInit } from '@angular/core';
import { HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-system-technical-data2',
  templateUrl: './system-technical-data2.component.html',
  styleUrls: ['./system-technical-data2.component.scss'],
})
export class SystemTechnicalData2Component implements OnInit {
  anlage: HWAnlage;
  technischeDatenZusaetze: string[];
  technischeDatenBezeichnung: string[];

  constructor(private routingService: RoutingService, private wartungsprojectsService: WartungsprojectsService) {}

  async ngOnInit(): Promise<void> {
    await this.loadAnlage();
    const technischeDaten = this.anlage.TechnischeDaten;
    this.technischeDatenZusaetze = technischeDaten.ZUSAETZE.slice(10, 20);
    this.technischeDatenBezeichnung = technischeDaten.BezeichnungenExtended.slice(10, 20);
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.wartungsprojectsService.findOneAnlageBy('UUID', anlageUUID);
  }
}
