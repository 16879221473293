import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { AddressService } from './address.service';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { createOverDaysTermine, HWTermin, HWAddress, HWRepairOrder } from 'apps/handwerkPWA/src/app/entities';
import moment from 'moment';
import { deDateFormat, timeFormat } from '../../config/Konstanten';
import { BaseAuftrag } from '../../interfaces';
import {
  extractIdsStringFromTermine,
  isTerminOverDays,
  putMitarbeiternameAndKundenname,
  sortTermineByDate,
} from '../../helper/services/terminHelper';
import { BaseService } from './base.service';
import { UUID } from 'angular2-uuid';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { LogErrorToWebserviceService } from '../globalServices/logErrorToWebservice.service';
import { PWAErrorMessageAndStackTrace } from '../../entities/models/PWAErrorMessageAndStackTrace';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private rechteService: RechteService,
    private logPwaErrorToWebservice: LogErrorToWebserviceService
  ) {}

  async findOneBy(selector: string, value: any): Promise<HWTermin> {
    const termin = await this.baseService.findOneBy(HWTermin, selector, value);
    const logObjectForWebs: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\ncomponent: appointment.service\n function: findOneBy\nSelector=>Value = ' +
        selector +
        '=>' +
        value,
      JSON.stringify(termin)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs);
    return termin;
  }

  /**@description Fügt dem Webservice einen Termin hinzu und schreibt ihn in die IDB */
  async syncUpdatedOrAdded(userInfo: Userinfo, uploadAppointment: HWTermin, silent = false): Promise<void> {
    let targetUrl = 'UpdateTermin';
    if (isNullOrUndefinedOrEmptyString(uploadAppointment.id)) {
      targetUrl = 'AddTermin';
      uploadAppointment.mitarbeiter = userInfo.monteur;
    }
    const logObjectForWebs: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\n' + targetUrl,
      JSON.stringify(uploadAppointment)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs);
    const responseAppointmentData: HWTermin = await this.restService.returnData<HWTermin>(
      userInfo,
      targetUrl,
      uploadAppointment,
      silent
    );
    const allAddresses = await this.addressService.getAll();
    const customerAddresses = allAddresses.filter(address => address.ADRTYP === 'K');
    const employeeAddresses = allAddresses.filter(address => address.ADRTYP === 'M');
    putMitarbeiternameAndKundenname(uploadAppointment, customerAddresses, employeeAddresses);
    if (!responseAppointmentData) {
      uploadAppointment.temporaryId = UUID.UUID();
      uploadAppointment.send = false;
    }
    if (responseAppointmentData) uploadAppointment.send = true;
    if (responseAppointmentData && targetUrl === 'AddTermin')
      uploadAppointment.setIdConstruct(responseAppointmentData.id);
    await this.updateInIDB(uploadAppointment);
  }

  /**@description Holt alle Termine aus der IDB für die man das Recht hat */
  async getAllTermineFromIDB(
    userInfo: Userinfo,
    customerNr?: string,
    withOverDaysBase = false,
    withOverDaysFromNotSerie = true,
    repairOrders?: HWRepairOrder[]
  ): Promise<HWTermin[]> {
    let appointments: HWTermin[] = [];
    const allOverDaysAppointments: HWTermin[] = [];
    const overDaysBaseAppointments: HWTermin[] = [];
    if (isNullOrUndefinedOrEmptyString(customerNr)) appointments = await this.baseService.getAll(HWTermin);
    else appointments = await this.baseService.getAllBy(HWTermin, 'adr', customerNr);
    for (const appointment of appointments) {
      const terminOverDays = isTerminOverDays(appointment);
      if (terminOverDays && withOverDaysFromNotSerie) {
        const skipLastDay = appointment.Options === 3; // ganztaegiger termin => folgetag überspringen
        allOverDaysAppointments.push(...createOverDaysTermine(appointment, skipLastDay));
        overDaysBaseAppointments.push(appointment);
      }
    }
    appointments.push(...allOverDaysAppointments);
    if (withOverDaysBase === false)
      appointments = appointments.filter(termin => !overDaysBaseAppointments.includes(termin));

    const rights = this.rechteService.getCurrentRight();
    const hasShowAllAppointmentsRight = rights?.Employeerights?.Terminrights?.showAll ?? false;

    if (!hasShowAllAppointmentsRight) {
      appointments = appointments.filter(termin => termin.MA_ID_LIST?.includes(userInfo.monteur));
    }

    const repairOrderEmployee2: HWTermin[] = [];
    if (repairOrders) {
      if (customerNr) repairOrders = repairOrders.filter(order => order.Monteur2 === customerNr);
      const repairOrderIds = repairOrders.map(order => order.Nummer);
      for (const id of repairOrderIds) {
        const appointment = await this.baseService.findOneBy(HWTermin, 'Referenz', id);
        if (!appointments.find(e => e.id === appointment.id)) repairOrderEmployee2.push(appointment);
      }
    }
    appointments = appointments.concat(repairOrderEmployee2);

    const termineSorted = sortTermineByDate(appointments);
    return termineSorted;
  }

  /**@description Holt alle Termine die geholt werden können (neuste Version mit Prüfung auf Recht) */
  async syncAll(userInfo: Userinfo, showDialog = true): Promise<HWTermin[]> {
    if (showDialog) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Termine...');
    await this.pushUnpushedTermine(userInfo);
    const targetUrl = `termine/mandant/${userInfo.mandant}/KU_NR/${0}/username/${userInfo.monteur}`;
    const allAppointmentData = await this.restService.returnData<HWTermin[]>(userInfo, targetUrl, null, !showDialog);
    if (isNullOrUndefined(allAppointmentData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const allAddresses = await this.addressService.getAll();
    const customerAddresses = allAddresses.filter(address => address.ADRTYP === 'K');
    const employeeAddresses = allAddresses.filter(address => address.ADRTYP === 'M');
    const allAppointments: HWTermin[] = [];
    for (const appointmentData of allAppointmentData) {
      const termin = new HWTermin(appointmentData);
      putMitarbeiternameAndKundenname(termin, customerAddresses, employeeAddresses);
      allAppointments.push(termin);
    }
    await this.writeTermineToIDB(allAppointments, true);
    this.dialogService.closeLoadingDialog();
    return allAppointments;
  }

  async getSerienTermineFromWebserviceByRange(
    userInfo: Userinfo,
    date: Date,
    rangeMonth: number,
    silent = false
  ): Promise<void> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Serientermine...');
    const laterDate: Date = new Date(date);
    const currentMonth = date.getMonth();
    laterDate.setMonth(currentMonth + rangeMonth);
    const earlierDate: Date = new Date(date);
    earlierDate.setMonth(currentMonth - rangeMonth);
    const start = dateToDatabaseDate(earlierDate);
    const finish = dateToDatabaseDate(laterDate);
    await this.getSerienTermineWebservice(userInfo, start, finish);
    if (!silent) void this.dialogService.closeLoadingDialog();
  }

  /**@description Baut aus den Daten von neu anzulegenden Daten die Objekte und verschickt diese  */
  async pushUnpushedTermine(userInfo: Userinfo): Promise<void> {
    const all = await this.baseService.getAll(HWTermin);
    const untransferred = all.filter(appointment => appointment.send === false);
    const logObjectForWebs: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\ncomponent: appointment.service\n function: pushUnpushedTermine',
      JSON.stringify(untransferred)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs);
    for (const termin of untransferred) await this.syncUpdatedOrAdded(userInfo, termin, true);
  }

  /**@description Updated Start und Ende eines Termins und schreibt ihn in die idb */
  async updateOrdertermin(order: BaseAuftrag): Promise<void> {
    const logObjectForWebs: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\ncomponent: appointment.service\n function: updateOrdertermin',
      'Auftrag: ' + JSON.stringify(order)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs);
    const appointmentId = order?.getTerminId();
    const appointmentObject = await this?.getTerminById(appointmentId);
    if (isNullOrUndefined(appointmentObject)) return;
    const newAppointmentDate = order.getSortDate();
    const AppointmentMoment = moment(newAppointmentDate);
    const datePart = AppointmentMoment.format(deDateFormat);
    const timePart = AppointmentMoment.format(timeFormat);
    appointmentObject.setNewStart(datePart, timePart);
    AppointmentMoment.add(1, 'h');
    const datePartEnd = AppointmentMoment.format(deDateFormat);
    const timePartEnd = AppointmentMoment.format(timeFormat);
    appointmentObject.setNewEnd(datePartEnd, timePartEnd);
    const logObjectForWebs2: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\ncomponent: appointment.service\n function: updateOrdertermin',
      'Termin: ' + JSON.stringify(appointmentObject)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs2);
    await this.updateInIDB(appointmentObject);
  }

  /**@description Schreibt einen Auftragstermin bis zum nächsten sync lokal in die IDB */
  async createLocalTerminForRepairOrder(order: HWRepairOrder, customer: HWAddress, terminId: string): Promise<void> {
    const logObjectForWebs: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\ncomponent: appointment.service\n function: createLocalTerminForRepairOrder',
      'Auftrag: ' + JSON.stringify(order)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs);
    const start = order.Termin;
    const endTimeSplit = order?.Endzeit?.split(':');
    const finishDate = moment(start, 'dd.MM.yyyy HH:mm').toDate();
    finishDate.setHours(finishDate.getHours() + 1);
    if (endTimeSplit?.length === 2) {
      // Reguläre endzeit eingetragen
      const endHours = parseInt(endTimeSplit[0], 10);
      const endMinutes = parseInt(endTimeSplit[1], 10);
      finishDate.setHours(endHours);
      finishDate.setMinutes(endMinutes);
    }
    const finish = moment(finishDate).format('dd.MM.yyyy HH:mm');
    const appointment = new HWTermin({ start, finish });
    appointment.addAuftragsinformation(order.Monteur1, order.Nummer);
    appointment.addKundeninfo(customer);
    appointment.setIdConstruct(terminId);
    const logObjectForWebs2: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\ncomponent: appointment.service\n function: createLocalTerminForRepairOrder',
      'Termin: ' + JSON.stringify(appointment)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs2);
    await this.writeTermineToIDB([appointment], false);
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  private async updateInIDB(appointment: HWTermin) {
    if (!isNullOrUndefinedOrEmptyString(appointment.id))
      await this.controllerService.deleteData('HWTermin', 'id', appointment.id);
    if (!isNullOrUndefinedOrEmptyString(appointment.temporaryId))
      await this.controllerService.deleteData('HWTermin', 'temporaryId', appointment.temporaryId);
    await this.writeTermineToIDB([appointment], false);
  }

  /**@description Holt alle Termine die geholt werden können (neuste Version mit Prüfung auf Recht) */
  private async getSerienTermineWebservice(userInfo: Userinfo, start: string, finish: string): Promise<HWTermin[]> {
    const allAppointments = await this.getAllTermineFromIDB(userInfo, null, false, false);
    const recurringAppointmentStart = allAppointments.filter(termin => termin.isStartOfSerie);
    const recurringAppointmentNew: HWTermin[] = [];
    const ids = extractIdsStringFromTermine(recurringAppointmentStart);
    const targetUrl = `SerienTermine/start/${start}/finish/${finish}`;
    const recurringAppointmentData = await this.restService.returnData<string>(userInfo, targetUrl, ids);
    if (isNullOrUndefined(recurringAppointmentData)) return null;
    let parsedData;
    try {
      parsedData = JSON.parse(recurringAppointmentData) as { Termine: HWTermin[] };
    } catch (e) {
      return null;
    }
    const parsedAppointmentResponseArray = parsedData.Termine as HWTermin[];
    if (parsedAppointmentResponseArray.length > 0) {
      for (const data of parsedAppointmentResponseArray) {
        const recurringAppointment = recurringAppointmentStart.filter(termin => termin.id === data.id)[0];
        const mewRecurringAppointment = new HWTermin(recurringAppointment);
        mewRecurringAppointment.addNewSerienterminInformation(data);
        recurringAppointmentNew.push(mewRecurringAppointment);
      }
    }
    const alleAppointmentsWithRecurring = allAppointments.concat(recurringAppointmentNew);
    // IDX 0 ist der Start-Serientermin einer Serie. Da dieser aber auch als normaler Termin existiert, muss dieser in der nächsten Zeile herausgefiltert werden
    const allTermine = alleAppointmentsWithRecurring.filter(termin => termin.idx !== '0');
    await this.writeTermineToIDB(allTermine, true);
    this.dialogService.closeLoadingDialog();
    return recurringAppointmentNew;
  }

  /**@description Schreibt die Termine in die IDB */
  private async writeTermineToIDB(appointment: HWTermin[], clear: boolean) {
    if (clear) await this.controllerService.clearStore('HWTermin');
    await this.controllerService.setData('HWTermin', appointment);
  }

  /**@description Gibt einen Termin anhand seiner ID zurück */
  private async getTerminById(id: string): Promise<HWTermin> {
    const termin = await this.baseService.findOneBy(HWTermin, 'id', id);
    const logObjectForWebs2: PWAErrorMessageAndStackTrace = new PWAErrorMessageAndStackTrace(
      'DIES IST EINE INFO, KEIN ERROR!!\ncomponent: appointment.service\n function: getTerminById',
      'Termin: ' + JSON.stringify(termin)
    );
    await this.logPwaErrorToWebservice.LogErrorToWebservice(logObjectForWebs2);
    return termin;
  }
}
