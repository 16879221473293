import { rightId } from 'libs/shared/src/lib/entities';
import { Featurename } from 'libs/shared/src/lib/entities/models/user/Featurecheck';

export class PageSetting {
  constructor(
    public path: string,
    public right_name: rightId,
    public featurename: Featurename,
    public licenceNeeded: boolean
  ) {}
}

export const pageSettings = [
  new PageSetting('adresse/:guid/offene-posten', 'showOffenePosten', 'Basis', false),
  new PageSetting('adresse/:guid/umsatz', 'showUmsatz', 'Basis', false),
  new PageSetting('adresse/:guid/termine', 'showAll', 'Basis', false),

  new PageSetting('termin/:id', 'showKunden', 'Basis', false),
  new PageSetting('termin/neu/:date/:kundennummer', 'allowCreation', 'Basis', false),
  new PageSetting('termin/neu/:date', 'allowCreation', 'Basis', false),

  new PageSetting('belege', 'showBelege', 'Basis', false),
  new PageSetting('beleg/:name', 'showBelege', 'Basis', false),

  // Aufträge
  new PageSetting('reparaturauftraege', 'showAuftrag', 'Basis', true),
  new PageSetting('reparaturauftrag/:guid/edit', 'showAuftrag', 'Basis', true),
  new PageSetting('reparaturauftrag/neu', 'createRepairOrder', 'Basis', true),
  new PageSetting('reparaturauftrag/neu/:address_type/:guid', 'createRepairOrder', 'Basis', true),

  new PageSetting('nachrichten', 'showAuftrag', 'Basis', true),
  new PageSetting('nachricht/neu', 'showAuftrag', 'Basis', true),
  new PageSetting('nachricht/neu/:guid', 'showAuftrag', 'Basis', true),

  new PageSetting('auftragpositionen/:type/:id', 'showAuftrag', 'Basis', true),
  new PageSetting('auftragpositionen/:type/:id/hinzufuegen', 'showAuftrag', 'Basis', true),
  new PageSetting('auftragpositionen/:type/:id/:position_uuid', 'showAuftrag', 'Basis', true),
  new PageSetting('auftragsabschluss/:type/:id', 'showAuftrag', 'Basis', true),

  // Wartung und Service
  // Achtung, Wartungsanlagen existierten bereits vor den Serviceuafträgen und sind somit teil des Featurs "Basis", nicht "Wartung und Service"
  new PageSetting('wartungsauftraege', 'handleOrder', 'Wartung und Service', true),
  new PageSetting('wartungsauftrag/:uuid/edit', 'handleOrder', 'Wartung und Service', true),
  new PageSetting('wartungsanlagen', 'showKunden', 'Basis', true),
  new PageSetting('wartungsanlage/:uuid/edit', 'showKunden', 'Basis', true),
  new PageSetting('wartungsanlage/:uuid/standort', 'showKunden', 'Basis', true),
  new PageSetting('wartungsanlage/:uuid/termine', 'showKunden', 'Basis', true),
  new PageSetting('wartungsanlage/:uuid/technische-daten-1', 'showKunden', 'Basis', true),
  new PageSetting('wartungsanlage/:uuid/technische-daten-2', 'showKunden', 'Basis', true),
  new PageSetting('wartungsanlage/:uuid/messungen', 'showKunden', 'Basis', true),
  new PageSetting('wartungsanlage/:uuid/messung/neu', 'showKunden', 'Basis', true),

  // Aufmass
  new PageSetting('aufmasse', 'showKunden', 'Aufmass', true),
  new PageSetting('aufmass/neu', 'showKunden', 'Aufmass', true),
  new PageSetting('aufmass/editierung/:uuid', 'showKunden', 'Aufmass', true),
  new PageSetting('aufmass/:uuid/raumbuch', 'showKunden', 'Aufmass', true),
  new PageSetting('aufmass/:uuid/raum/:raumid', 'showKunden', 'Aufmass', true),
  new PageSetting('aufmass/grundriss/:aufmassid/:raumid', 'showKunden', 'Aufmass', true),
  new PageSetting('aufmass/messung/:aufmassid/:raumid', 'showKunden', 'Aufmass', true),
  new PageSetting('aufmass/:uuid/raum/:raumid/masskette/:massketteid', 'showKunden', 'Aufmass', true),
];
