import { Punkt } from '../..';

export class Beschriftung {
  Punkt: Punkt;
  text: string;

  constructor(punkt: Punkt, text: string) {
    this.Punkt = punkt;
    this.text = text;
  }
}
