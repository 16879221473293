<div class="dx-card responsive-paddings">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Typ der Position</div>
      <div class="dx-field-value">
        <dx-radio-group
          id="typeRadiogroup"
          [items]="customTypes"
          [value]="manuellePosition.type"
          layout="horizontal"
          (onValueChanged)="customTypeChange($event.value)"
        >
        </dx-radio-group>
      </div>
    </div>

    <div class="fieldbox">
      <div *ngIf="manuellePosition.type != 'Textposition'" class="dx-field" style="margin-bottom: 10px">
        <div class="dx-field-label">Nummer</div>
        <div class="dx-field-value">
          <dx-text-box [(value)]="manuellePosition.Nummer" valueChangeEvent="keyup">
            <dx-validator>
              <dxi-validation-rule
                type="pattern"
                [pattern]="alphaNumericPattern"
                message="Ausschließlich alphanumerische Eingaben erlaubt - Sollten Sie fortfahren werden nicht alphanumerische Zeichen aus Ihrer Eingabe entfernt"
              >
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>

      <app-bss-textbox [labelText]="'Bezeichnung'" [(value)]="manuellePosition.Bezeichnung"></app-bss-textbox>
      <app-bss-selectbox
        *ngIf="manuellePosition.type == 'Artikel'"
        [labelText]="'Mengeneinheit'"
        [items]="mengeneinheiten"
        [acceptCustomValue]="true"
        [placeholder]="'Auswählen oder eintragen'"
        (selectedValue)="setMengeneinheit($event)"
      ></app-bss-selectbox>

      <app-bss-number-popup
        #mengenInput
        *ngIf="manuellePosition.type === 'Artikel'"
        class="customInputStyle"
        [ignoreLabelStyles]="true"
        [labelText]="'Menge ändern'"
        [title]="'Menge ändern'"
        [inputValue]="manuellePosition.Menge"
        (outputValue)="setMengeOrZeit($event, manuellePosition.type)"
        [DecimalPlaces]="2"
        [DecimalPlacesInputEnabled]="true"
        [ValidateInputDecimals]="true"
      >
      </app-bss-number-popup>

      <app-bss-time-input
        *ngIf="manuellePosition.type == 'Lohn'"
        [lohnPosition]="manuellePosition"
        (outputMinutes)="manuellePosition.Zeit = $event"
      >
      </app-bss-time-input>

      <app-bss-number-popup
        *ngIf="manuellePosition.type != 'Textposition'"
        class="customInputStyle"
        [labelText]="einzelPreisLable"
        [title]="'Preis'"
        [ignoreLabelStyles]="true"
        [mengenTyp]="'€'"
        [description]="'Preis'"
        (outputValue)="manuellePosition.PriceVK = $event"
        [inputValue]="manuellePosition.PriceVK"
        [DecimalPlaces]="2"
        [DecimalPlacesInputEnabled]="true"
        [ValidateInputDecimals]="true"
      >
      </app-bss-number-popup>
    </div>

    <button (click)="createCustom(manuellePosition)" class="bss-button primary-button">Speichern</button>
  </div>
</div>
