import { SourceOfFilelist } from '../../config/Konstanten';

/**
 * JPEG Quality factor. 70% sieht noch akzetpabl aus, ist aber trotzdem deutlich kleiner in der Größe
 */
const jpgQualityFactor = 0.7;

/**
 * Maximale Auflösung, ab der eine Komprimierung stattfindet.
 * Gleichzeitig auch die Ziel-Auflösung, auf die komprimiert wird
 */
const maxResolution = 1920;

/**
 * Declarationen des Typs von Offscrencanvas, weil es noch zu neu ist
 * und keine Typen dazu in Typescript vorhanden sind
 */
declare interface ConvertToBlobOptions {
  type: string;
  quality?: number;
}

declare class OffscreenCanvas {
  constructor(width: number, height: number);

  getContext(ctxType: string): CanvasRenderingContext2D;

  convertToBlob(options: ConvertToBlobOptions): Blob;
}

/**
 * Durch Regex aus DataUrl die entsprechenden Stellen rausschneiden um base64 String zu erhalten
 * @param dataUrl ursprüngliche Dataurl der Datei
 * @returns base64 String
 */
export function dataUrlToBase64(dataUrl: string): string {
  return dataUrl.replace(/^(data:image|data:application)\/(png|jpg|jpeg|pdf);base64,/, '');
}

/**
 * Durch Regex aus DataUrl die entsprechenden Stellen rausschneiden um base64 String zu erhalten
 * @param base64Data base64 string
 * @param contentType string z.B.: "application/pdf", "image/jpeg",...
 * @returns blob
 */
export function base64toBlob(base64Data, contentType) {
  let byteCharacters = atob(base64Data);
  const ab = new ArrayBuffer(byteCharacters.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteCharacters.length; i++) {
    ia[i] = byteCharacters.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
}

/**
 * Holt eine DataUrl zu einem Blob.
 * @param blob
 */
export async function readBlobAsDataUrlAsync(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const dataUrl = <string>reader.result;
      resolve(dataUrl);
    };

    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function getContentType(base64String: string): 'application/pdf' | 'image/jpg' {
  const byteCharacters = atob(base64String);
  if (byteCharacters.substring(0, 4) === '%PDF') return 'application/pdf';
  else return 'image/jpg';
}

/**
 * Safari unterstützt keine OffScreenCanvas, deswegen muss dies seperat behandelt werden
 * @param file ursprüngliches Bild
 * @returns komprimiertes Bild
 */
export async function customCompressIOs(file: Blob): Promise<any> {
  // Polyfill für iOs , der Fix war für Javascript, für TS werden Fehler erzeugt, die Unterdrückung mit ts-ignore ist notwendig
  if (!('createImageBitmap' in window)) {
    // @ts-ignore
    window.createImageBitmap = async function (data: ImageBitmapSource) {
      return new Promise<ImageBitmap>((resolve, reject) => {
        let dataURL;
        if (data instanceof Blob) {
          dataURL = URL.createObjectURL(data);
        } else if (data instanceof ImageData) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = data.width;
          canvas.height = data.height;
          ctx.putImageData(data, 0, 0);
          dataURL = canvas.toDataURL();
        } else {
          throw new Error('createImageBitmap does not handle the provided image source type');
        }
        const img = document.createElement('img');
        img.addEventListener('load', function () {
          // @ts-ignore
          resolve(this);
        });
        img.src = dataURL;
      });
    };
  }
  const img = await window.createImageBitmap(file);

  // Bei Formaten, die nicht jpeg sind (png), ist Orientation immer -1 (nicht vorhanden)
  const imageOrientation = file.type === 'image/jpeg' ? await getOrientation(file) : -1;

  if (canNotCompress(img, imageOrientation)) {
    // Keine Komprimierung und keine Drehung notwendig
    return file;
  }

  let compressedWidth = img.width;
  let compressedHeight = img.height;

  if (shouldCompress(img)) {
    if (img.width > img.height) {
      compressedWidth = maxResolution;
      const ratio = maxResolution / img.width;
      compressedHeight = img.height * ratio;
    } else {
      compressedHeight = maxResolution;
      const ratio = maxResolution / img.height;
      compressedWidth = img.width * ratio;
    }
  }

  if (4 < imageOrientation && imageOrientation < 9 && imageOrientation !== 6) {
    // switche Höhe und Breite, weil das Bild gedreht werden muss
    const temp = compressedWidth;
    compressedWidth = compressedHeight;
    compressedHeight = temp;
  }

  const canvas = document.createElement('canvas');
  canvas.width = compressedWidth;
  canvas.height = compressedHeight;
  // const canvas = document.createElement(compressedWidth, compressedHeight);
  const ctx = canvas.getContext('2d');

  switch (imageOrientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, compressedHeight, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, compressedWidth, compressedHeight);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, compressedWidth);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, compressedWidth, compressedHeight);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, compressedHeight);
      break;
    default:
      break;
  }

  if (imageOrientation > 4 && imageOrientation < 9 && imageOrientation !== 6) {
    ctx.drawImage(img, 0, 0, compressedHeight, compressedWidth);
  } else {
    ctx.drawImage(img, 0, 0, compressedWidth, compressedHeight);
  }
  function getCanvasBlob(canvasInput) {
    return new Promise(function (resolve, reject) {
      canvasInput.toBlob(
        function (blob) {
          resolve(blob);
        },
        'image/jpeg',
        jpgQualityFactor
      );
    });
  }
  const canvasBlob = getCanvasBlob(canvas);
  return canvasBlob;
}

/**
 * Komprimiert ein Bild und führt dabei eine Auto-Rotation aus. Das heisst das Bild wird gemäß der Orientation Information im EXIF-Header
 * entsrpechend richtig rum rotiert. Beim Komprimieren gehen alle EXIF-Daten verloren.
 * @param file ursprüngliches Bild
 * @returns komprimiertes Bild
 */
export async function customCompress(file: Blob): Promise<Blob> {
  const img = await createImageBitmap(file);
  // Bei Formaten, die nicht jpeg sind (png), ist Orientation immer -1 (nicht vorhanden)
  const imageOrientation = file.type === 'image/jpeg' ? await getOrientation(file) : -1;
  if (canNotCompress(img, imageOrientation)) {
    // Keine Komprimierung und keine Drehung notwendig
    return file;
  }

  let compressedWidth = img.width;
  let compressedHeight = img.height;

  if (shouldCompress(img)) {
    if (img.width > img.height) {
      compressedWidth = maxResolution;
      const ratio = maxResolution / img.width;
      compressedHeight = img.height * ratio;
    } else {
      compressedHeight = maxResolution;
      const ratio = maxResolution / img.height;
      compressedWidth = img.width * ratio;
    }
  }

  if (4 < imageOrientation && imageOrientation < 9 && imageOrientation !== 6) {
    // switche Höhe und Breite, weil das Bild gedreht werden muss
    const temp = compressedWidth;
    compressedWidth = compressedHeight;
    compressedHeight = temp;
  }

  const canvas = new OffscreenCanvas(compressedWidth, compressedHeight);
  const ctx = canvas.getContext('2d');

  switch (imageOrientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, compressedHeight, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, compressedWidth, compressedHeight);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, compressedWidth);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, compressedWidth, compressedHeight);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, compressedHeight);
      break;
    default:
      break;
  }

  if (imageOrientation > 4 && imageOrientation < 9 && imageOrientation !== 6) {
    ctx.drawImage(img, 0, 0, compressedHeight, compressedWidth);
  } else {
    ctx.drawImage(img, 0, 0, compressedWidth, compressedHeight);
  }

  const options: ConvertToBlobOptions =
    file.type === 'image/jpeg'
      ? { type: file.type, quality: shouldCompress(img) ? jpgQualityFactor : 1 }
      : { type: file.type };

  const compressedFile = canvas.convertToBlob(options);

  return compressedFile;
}

function canNotCompress(img: ImageBitmap, imageOrientation: number) {
  return !shouldCompress(img) && imageOrientation < 2;
}

/**
 * Prüft, ob eine Komprimierung stattfinden soll
 * @param img das zu prüfende Bild
 */
function shouldCompress(img: ImageBitmap): boolean {
  return Math.max(img.width, img.height) > maxResolution;
}

/**
 * Liest aus den EXIF-Daten des JPGs die Orientation Information aus.
 * @param file Integer, der die Orientation beschreibt. -1, wenn gar keine Orientation enthalten ist.
 */
function getOrientation(file: Blob): Promise<number> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const view = new DataView(event.target.result);

      if (view.getUint16(0, false) != 0xffd8) {
        return resolve(-2);
      }

      let length = view.byteLength,
        offset = 2;

      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;

        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) {
            return resolve(-1);
          }
          const little = view.getUint16((offset += 6), false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;

          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) == 0x0112) {
              return resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) != 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(-1);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  });
}

/**@description Hack, da die Konstruktor struktur der Anlage kein Rebuild per Konstruktor zulässt  */
export function getUuidOfFileSource(source: SourceOfFilelist): string {
  let uuid: string;
  try {
    uuid = source.getUuid();
  } catch {
    uuid = source['UUID'];
  }
  return uuid;
}
