/* eslint-disable @typescript-eslint/prefer-for-of */
import { Injectable, SecurityContext } from '@angular/core';
import {
  base64To64Raw,
  isNullOrUndefined,
  isNullOrUndefinedOrEmptyString,
  splitFilenameAndExtension,
} from 'libs/shared/src/lib/helper/globalHelper';
import { HWGolbalsettingService } from './hwgolbalsetting.service';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { HWFile, HWAddress, HWObjectaddress } from 'apps/handwerkPWA/src/app/entities';
import { noPreviewImage, noPreviewPdf } from 'apps/handwerkPWA/src/assets/images/noPreviewImage';
import {
  base64toBlob,
  readBlobAsDataUrlAsync,
  dataUrlToBase64,
  getContentType,
} from 'apps/handwerkPWA/src/app/helper/services/documentHelper';
import { isMobile, isIpad, b64toBlob } from 'libs/shared/src/lib/helper/deviceHelper';
import { addMedienSignature, Medien } from '../../entities/repository/Medien';
import { BaseService } from '../dataServices/base.service';
import { ControllerService } from './controller.service';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root',
})
export class Documentservice {
  constructor(
    private globalSettingService: HWGolbalsettingService,
    private restService: RestService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private controllerService: ControllerService,
    private sanitizer: DomSanitizer
  ) {}

  async getAllBy(selector: string, value: string): Promise<Medien[]> {
    const medien = await this.baseService.getAllBy(Medien, selector, value);
    for (const medium of medien)
      switch (medium.Datatype) {
        case 0: {
          await readBlobAsDataUrlAsync(base64toBlob(dataUrlToBase64(noPreviewPdf), 'image/png'));
          break;
        }
        case 1: {
          await readBlobAsDataUrlAsync(base64toBlob(dataUrlToBase64(noPreviewImage), 'image/png'));
          break;
        }
        case 2: {
          await readBlobAsDataUrlAsync(base64toBlob(dataUrlToBase64(noPreviewPdf), 'image/png'));
          break;
        }
      }
    return medien;
  }

  private async getAll(): Promise<Medien[]> {
    const medien = await this.baseService.getAll(Medien);
    return medien;
  }

  getUnpushedFilesOld(allAdresses: HWAddress[], allObjectAdresses: HWObjectaddress[]): HWFile[] {
    const filesObjectAdresses = allObjectAdresses?.flatMap(adress => adress.Files);
    const fileAdresses = allAdresses?.flatMap(adress => adress.Files);
    const files = fileAdresses.concat(filesObjectAdresses);
    const unpushedDocuments = files?.filter(hwFile => hwFile.Send === false);
    return unpushedDocuments;
  }

  async getUnpushedFiles(): Promise<Medien[]> {
    const allMedien = await this.getAll();
    const unpushed = allMedien.filter(medium => medium.Send === false);
    return unpushed;
  }

  checkFileExists(fileArray: Array<Medien | HWFile>, parsedFile: HWFile | Medien): boolean {
    if (fileArray?.some(currentObject => currentObject.getOriginalName() === parsedFile.getOriginalName())) return true;
    return false;
  }

  openPdf(pdfUrl: string): void {
    const blob = b64toBlob(pdfUrl, 'application/pdf');
    const url = window.URL.createObjectURL(blob);
    window.location.href = url;
  }

  isDocumentPdf(inputFile: HWFile | Medien): boolean {
    if (inputFile instanceof Medien) return inputFile.Datatype === 2;
    const documentName = inputFile.Name;
    const nameLength = documentName.length;
    if (documentName.substr(nameLength - 3, nameLength).toLowerCase() === 'pdf') return true;
    return false;
  }

  openText(documentUrl: string) {
    let openDocumentUrl = documentUrl;
    if (!documentUrl.startsWith('data:text/plain;base64,')) openDocumentUrl = 'data:text/plain;base64,' + documentUrl;
    const uri = encodeURI(openDocumentUrl);
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const text = reader.result;
        const htmlContent = `<div >${text}</div>`;
        const tab = window.open('about:blank', '_blank');
        tab.document.write(htmlContent);
        tab.document.close();
      },
      false
    );

    fetch(uri)
      .then(res => res.blob())
      .then(blob => {
        const text = reader.readAsText(blob);
      });
  }
  isDocumentText(inputFile: HWFile | Medien) {
    if (inputFile instanceof Medien) return inputFile.Datatype === 0;
    const documentName = inputFile.Name;
    const nameLength = documentName.length;
    if (documentName.substr(nameLength - 3, nameLength).toLowerCase() === 'txt') return true;
    return false;
  }

  private removeHeaderFromDataUrl(bilddata: string): string {
    if (bilddata.startsWith('data:image/jpeg;base64,')) return bilddata.replace('data:image/jpeg;base64,', '');
    if (bilddata.startsWith('data:image/png;base64,')) return bilddata.replace('data:image/png;base64,', '');
    if (bilddata.startsWith('data:application/pdf,')) return bilddata.replace('data:application/pdf,', '');
    return bilddata;
  }

  async getDocumentDataUrlFromWebservice(file: HWFile | Medien): Promise<string> {
    const userInfo = await this.globalSettingService.getUserinfo();
    void this.dialogService.openLoadingDialog('Hole Daten', 'Hole Datei vom Server...');
    const targetUrl =
      file instanceof Medien
        ? 'getMediumData'
        : `addressImages/mandant/${userInfo.mandant}/username/${userInfo.user}/KU_NR/${userInfo.monteur}/bildname/${file.Name}`;
    const mediumUuidOrBezugsUuuid = file instanceof Medien ? file : file.Kundennummer;
    const returnValue = await this.restService.returnData<string | Medien>(
      userInfo,
      targetUrl,
      mediumUuidOrBezugsUuuid
    );
    if (file instanceof Medien) {
      this.dialogService.closeLoadingDialog();
      const dataWithSignature = addMedienSignature(file.Datatype, (returnValue as Medien).Data);
      return dataWithSignature;
    }
    const base64String = returnValue as string;
    const documentUrl = await readBlobAsDataUrlAsync(base64toBlob(base64String, getContentType(base64String)));
    this.dialogService.closeLoadingDialog();
    return documentUrl;
  }

  /**
   * @description Sendet Medien zum Webservice
   * @returns FileArray mit den korrekt gesetzten Zuständen
   */
  async sendUnpushedImagesToWebservice(
    userInfo: Userinfo,
    documents: Array<Medien | HWFile>
  ): Promise<Array<Medien | HWFile>> {
    const unpushedDocuments = documents.filter(doc => doc.Send === false && !isNullOrUndefined(doc.Data));
    for (const aFile of unpushedDocuments) {
      const newVersion = aFile instanceof Medien;
      if (newVersion) await this.transferDocuments(userInfo, aFile as Medien);
      else {
        const oldTransfer = (await this.transferDocumentsOld(userInfo, aFile as HWFile)) as {
          Message: string;
          Success: boolean;
        };
        (aFile as HWFile).Send = oldTransfer?.Success === true;
      }
    }
    return documents;
  }

  private async transferDocuments(userInfo: Userinfo, file: Medien): Promise<void> {
    const targetUrl = 'addMedium';
    const sendFile = new Medien(file);
    delete sendFile.PreviewPictureData;
    sendFile.Data = base64To64Raw(sendFile.Data); // Webservice in C# mag zusätzlich info in base64 nicht
    const success = await this.restService.returnData<boolean>(userInfo, targetUrl, sendFile, false);
    file.Send = success;
    await this.insertOrUpdate(file);
  }

  private async insertOrUpdate(file: Medien): Promise<void> {
    await this.controllerService.deleteData('Medien', 'UUID', file.UUID);
    await this.controllerService.setData('Medien', [file]);
  }

  /**@description Sendet Bild bzw Datei an den Webservice und somit ans Backend
   */
  private async transferDocumentsOld(userInfo: Userinfo, file: HWFile): Promise<{ Message: string; Success: boolean }> {
    const dataWithoutHeader = this.removeHeaderFromDataUrl(file.Data);
    const lfdnr = file.LfdNr;
    const kundennummer = file.Kundennummer;
    const dateiname = file.Name;
    const mandant = userInfo.mandant;
    const username = userInfo.monteur;
    let targetUrl = 'setObjAddressPic';
    // Keine Laufende Nummer -> Normale Adresse
    if (isNullOrUndefined(lfdnr)) {
      targetUrl = 'setAddressImage';
      return await this.restService.returnData<{ Message: string; Success: boolean }>(userInfo, targetUrl, {
        Mandant: mandant,
        User: username,
        KU_NR: kundennummer,
        Filename: dateiname,
        Filedata: dataWithoutHeader,
      });
    } // Laufende Nummer -> Objekt Adresse
    return await this.restService.returnData<{ Message: string; Success: boolean }>(userInfo, targetUrl, {
      Mandant: mandant,
      User: username,
      KU_NR: kundennummer + ';' + lfdnr,
      Filename: dateiname,
      Filedata: dataWithoutHeader,
    });
  }

  /**@description Setzt basierend auf den vorhandenen oder nicht vorhandenen Bilddaten ein Platzhalterbild für die Galerie */
  async getPicturePlaceholderAndNames(inputFiles: Array<Medien | HWFile>): Promise<Array<Medien | HWFile>> {
    const noPreviewPdfUrl = await readBlobAsDataUrlAsync(base64toBlob(dataUrlToBase64(noPreviewPdf), 'image/png'));
    const noPreviewImageUrl = await readBlobAsDataUrlAsync(base64toBlob(dataUrlToBase64(noPreviewImage), 'image/jpeg'));
    for (const file of inputFiles) {
      const fileName = file.getOriginalName();
      const lowerCaseFileEnding = splitFilenameAndExtension(fileName).extension;

      // Default ist das Medien-Vorschaubild, bei PDFs immer das PDF-Bild und wenn Daten vorhanden sind, ist es das Bild selbst
      file.PreviewPictureData = noPreviewImageUrl;
      if (lowerCaseFileEnding === 'pdf') {
        file.PreviewPictureData = noPreviewPdfUrl;
        continue;
      }
      if (!isNullOrUndefinedOrEmptyString(file.Data)) file.PreviewPictureData = file.Data;
    }
    return inputFiles;
  }

  async getAllFromWebservice(userInfo: Userinfo, silent = false): Promise<void> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Medienkopfdaten...');
    const targetUrl = 'getMedienWithoutData';
    const medienData: Medien[] = await this.restService.returnData<Medien[]>(userInfo, targetUrl, null, silent);
    if (!medienData) return void this.dialogService.closeLoadingDialog();
    const medien = medienData.map(data => new Medien(data));
    await this.overrideLocal(medien);
    if (!silent) void this.dialogService.closeLoadingDialog();
  }

  private async overrideLocal(medien: Medien[]): Promise<void> {
    await this.controllerService.clearStore('Medien');
    await this.controllerService.setData('Medien', medien);
  }
}
