import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { HWAddress, HWAnlage, HWTermin, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { ControllerService } from '../globalServices/controller.service';
import { DialogService } from '@handwerk-pwa/shared';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { AddressService } from './address.service';
import { AppointmentService } from './appointment.service';
import { WartungsprojectsService } from './wartungsprojects.service';
/** Workaround */
import { HWGolbalsettingService } from '../globalServices/hwgolbalsetting.service';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceauftraegeService {
  stuecklistenChange = new Subject<ServiceAuftrag>();

  constructor(
    private dialogService: DialogService,
    private restService: RestService,
    private controllerService: ControllerService,
    private addressService: AddressService,
    private wartungsprojectsService: WartungsprojectsService,
    private appointmentService: AppointmentService,
    private authorisationService: AuthorisationService,

    /** workaround */
    private globalSettingService: HWGolbalsettingService
  ) {}

  /**
   * TODO
   * muss noch refactored werden
   */
  async findOneBy(selector: string, value: any): Promise<ServiceAuftrag> {
    const monteure = await this.addressService.getAllBy('ADRTYP', 'M');
    const allAnlagen = await this.wartungsprojectsService.getAnlagenFromIDB();
    const userInfo = await this.globalSettingService.getUserinfo();
    const termine = await this.appointmentService.getAllTermineFromIDB(userInfo);

    const auftragsData = await this.controllerService.getData<ServiceAuftrag[]>('ServiceAuftrag');
    const serviceAuftraege: ServiceAuftrag[] = [];
    for (const data of auftragsData) {
      const serviceAuftrag = new ServiceAuftrag(data, monteure, allAnlagen, termine);
      serviceAuftraege.push(serviceAuftrag);
    }

    let serviceOrder: ServiceAuftrag = null;
    if (selector === 'UUID') serviceOrder = serviceAuftraege.find(order => order.UUID === value);
    if (selector === 'Dokumentid') serviceOrder = serviceAuftraege.find(order => order.Dokumentid === value);
    return serviceOrder;
  }

  async getAllServiceauftraegeFromWebservice(
    userInfo: Userinfo,
    monteure: HWAddress[],
    anlagen: HWAnlage[],
    termine: HWTermin[],
    showDialog = true
  ): Promise<ServiceAuftrag[]> {
    // Erst alle nicht synchronisierte Daten pushen
    await this.pushUnpushedOrders(userInfo);

    const targetUrl = 'GetServiceAuftraege';
    if (showDialog) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole Serviceaufträge...');
    }
    const responseData = await this.restService.returnData<ServiceAuftrag[]>(userInfo, targetUrl, userInfo);
    const serviceOrders: ServiceAuftrag[] = [];
    for (const data of responseData) {
      const serviceOrder = new ServiceAuftrag(data, monteure, anlagen, termine);
      serviceOrders.push(serviceOrder);
    }
    await this.writeServiceorderToIDB(serviceOrders, true);
    if (showDialog) {
      this.dialogService.closeLoadingDialog();
    }
    return serviceOrders;
  }

  /**@description Schreibt die Reparaturaufträge in die IDB */
  private async writeServiceorderToIDB(serviceOrder: ServiceAuftrag[], clear: boolean) {
    if (clear) {
      await this.controllerService.clearStore('ServiceAuftrag');
    }
    await this.controllerService.setData('ServiceAuftrag', serviceOrder);
  }

  /**@description Holt alle Aufträge aus der IDB*/
  async getAllServiceauftraegeFromIDB(
    monteure: HWAddress[],
    anlagen: HWAnlage[],
    termine: HWTermin[],
    kundennummer?: string
  ): Promise<ServiceAuftrag[]> {
    const auftragsData = await this.controllerService.getData<ServiceAuftrag[]>(
      'ServiceAuftrag',
      kundennummer,
      'KUNDE'
    );
    const serviceAuftraegeWithTermin: ServiceAuftrag[] = [];
    const serviceAuftraegeWithoutTermin: ServiceAuftrag[] = [];
    for (const data of auftragsData) {
      const serviceAuftrag = new ServiceAuftrag(data, monteure, anlagen, termine);
      if (serviceAuftrag.TerminObject) serviceAuftraegeWithTermin.push(serviceAuftrag);
      else serviceAuftraegeWithoutTermin.push(serviceAuftrag);
    }

    if (serviceAuftraegeWithTermin.length > 0 && serviceAuftraegeWithoutTermin.length > 0) {
      return serviceAuftraegeWithTermin
        .sort((a, b) => b.TerminObject.startDate.getTime() - a.TerminObject.startDate.getTime())
        .concat(serviceAuftraegeWithoutTermin);
    } else if (serviceAuftraegeWithTermin.length > 0 && serviceAuftraegeWithoutTermin.length === 0) {
      return serviceAuftraegeWithTermin.sort(
        (a, b) => b.TerminObject.startDate.getTime() - a.TerminObject.startDate.getTime()
      );
    } else {
      return serviceAuftraegeWithoutTermin;
    }
  }

  /**@description Sendet einen Serviceauftrag zum Webservice, damit er dort überschrieben wird */
  async sendServiceorderToWebservice(
    userInfo: Userinfo,
    serviceAuftrag: ServiceAuftrag,
    silent: boolean
  ): Promise<ServiceAuftrag> {
    if (!silent)
      void this.dialogService.openLoadingDialog('Serviceauftrag', '...speichere Änderungen am Serviceauftrag...');
    const success = !!(await this.restService.returnData<ServiceAuftrag>(
      userInfo,
      'SaveServiceauftrag',
      serviceAuftrag,
      silent
    ));
    serviceAuftrag.edited = success ? false : true;
    await this.updateOrderInIDB(serviceAuftrag);
    if (!silent) this.dialogService.closeLoadingDialog();
    return serviceAuftrag;
  }

  private async updateOrderInIDB(serviceAuftrag: ServiceAuftrag) {
    await this.deleteServiceOrderInIDB(serviceAuftrag);
    await this.writeServiceorderToIDB([serviceAuftrag], false);
  }

  async finalizeOrder(serviceOrder: ServiceAuftrag, userInfo: Userinfo, showDialog = true): Promise<void> {
    serviceOrder.setAuftragsstatus('Complete');
    const targetUrl = 'FinalizeServiceOrder';
    if (showDialog) void this.dialogService.openLoadingDialog('Serviceauftrag', '...schließe Serviceauftrag ab...');
    const success = await this.restService.returnData<boolean>(userInfo, targetUrl, serviceOrder, !showDialog);
    if (showDialog) this.dialogService.closeLoadingDialog();
    if (success) {
      await this.deleteServiceOrderInIDB(serviceOrder);
      return;
    }
    await this.updateOrderInIDB(serviceOrder); // fehlerfall
  }

  async acceptServiceOrder(
    serviceOrder: ServiceAuftrag,
    monteure: HWAddress[],
    userInfo: Userinfo
  ): Promise<ServiceAuftrag> {
    serviceOrder.acceptOrder(userInfo);
    void this.dialogService.openLoadingDialog('Zuordnung', '...Versuche Serviceauftrag zuzuordnen...');
    const targetUrl = 'acceptServiceOrder';
    const newOrderData = await this.restService.returnData<ServiceAuftrag>(userInfo, targetUrl, serviceOrder, true);
    const anlage = serviceOrder.AnlageObject;
    const termin = await this.appointmentService.findOneBy('Referenz', serviceOrder.getNummer());
    const returnedServiceOrder = new ServiceAuftrag(newOrderData, monteure, [anlage], [termin]);
    this.dialogService.closeLoadingDialog();
    if (returnedServiceOrder.isUserMainMonteur(userInfo)) {
      await this.updateOrderInIDB(returnedServiceOrder);
      return returnedServiceOrder;
    }
    return null;
  }

  async deleteServiceOrderInIDB(serviceAuftrag: ServiceAuftrag): Promise<void> {
    await this.controllerService.deleteData('ServiceAuftrag', 'UUID', serviceAuftrag.UUID);
  }

  /**
   * @description Überträgt zuerst nicht übertragen items ans handwerk, dann nimmt man die liste der nun zugeordneten und übertragenen items
   * und weißt sie der ursprünglichen liste der nicht übertragenen idbRepairOrders zu um sie dann abzuschließen
   */
  async pushUnpushedOrders(userInfo: Userinfo): Promise<void> {
    if (!this.authorisationService.current.getValue().featureCheck('Wartung und Service').available) {
      return;
    }

    const allAddresses = await this.addressService.getAll();
    const monteure = allAddresses.filter(address => address.ADRTYP === 'M');
    const anlagen = await this.wartungsprojectsService.getAnlagenFromIDB();
    const termine = await this.appointmentService.getAllTermineFromIDB(userInfo);

    const idbServiceOrders = await this.getAllServiceauftraegeFromIDB(monteure, anlagen, termine);
    const untransferredOrders = idbServiceOrders?.filter(order => order.edited);
    for (let order of untransferredOrders) {
      order = await this.sendServiceorderToWebservice(userInfo, order, true);
    }
    const notFinalizedOrders = idbServiceOrders?.filter(order => order.finalizeHasFailed());
    for (const order of notFinalizedOrders) {
      await this.finalizeOrder(order, userInfo, true);
    }
  }
}
