import { Component, OnDestroy, OnInit } from '@angular/core';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { NachrichtenService } from '../../../services/dataServices/nachrichten.service';
import { HWRepairOrder, HWNachricht } from 'apps/handwerkPWA/src/app/entities';
import { RepairOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrder.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { DialogService } from '@handwerk-pwa/shared';

@Component({
  selector: 'app-message-new',
  templateUrl: './message-new.component.html',
  styleUrls: ['./message-new.component.scss'],
})
export class MessageNewComponent implements OnInit, OnDestroy {
  message = new HWNachricht(null);
  repairOrder: HWRepairOrder;
  selectableRepairOrders: HWRepairOrder[];
  subscription: Subscription;

  constructor(
    private hWGlobalSettingService: HWGolbalsettingService,
    private nachrichtenService: NachrichtenService,
    private repairOrderService: RepairOrderService,
    private routingService: RoutingService,
    private dialogService: DialogService
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscription = this.routingService.save.subscribe(() => {
      void this.save();
    });

    const repairOrderGuid = this.routingService.getRouteParam('guid');
    const repairOrder = await this.repairOrderService.findOneByGuid(repairOrderGuid);
    this.selectableRepairOrders = await this.repairOrderService.getAllRepairOrdersFromIDB();
    if (repairOrder) {
      this.repairOrder = this.selectableRepairOrders.find(order => order.Nummer === repairOrder.Nummer);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**@description Sendet die Nachricht ans Handwerk */
  async save(): Promise<void> {
    const userInfo = await this.hWGlobalSettingService.getUserinfo();
    this.message.MonteurNr = userInfo.monteur;
    if (isNullOrUndefined(this.repairOrder)) {
      this.dialogService.openInformDialog(
        'Kein Auftrag',
        'Nachrichten können nur mit einem Auftrag versendet werden. Bitte wählen sie einen Auftrag aus.',
        'Ok'
      );
      return;
    }
    const auftragsNummer = this.repairOrder.Nummer;
    this.message.Auftrag = this.repairOrder;
    this.message.AuftragsNr = auftragsNummer;
    await this.nachrichtenService.sendNachricht(userInfo, this.message);
    this.routingService.dataChanged.next(false);
    await this.routingService.navigateTo('nachrichten');
  }

  /**@description Setzt die Displayexpression */
  displayExpr(repairOrder: HWRepairOrder): string {
    if (isNullOrUndefined(repairOrder)) return '';
    return repairOrder.Nummer + ' ' + repairOrder.KundenName;
  }
}
