<div class="content-block bottom-padding" *ngIf="serviceOrder">
  <div class="form-block">
    <h2>Servicedetails</h2>

    <div *ngIf="customer?.LIEFERSPERRE === true" class="bss-alert bss-alert-danger">Liefersperre hinterlegt</div>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <div class="dx-field">
            <div class="dx-field-label">Status</div>
            <div class="dx-field-value" style="display: flex; margin-bottom: 10px">
              {{ serviceOrder.getStatusText() }}
            </div>
          </div>

          <app-bss-plain-text-box
            *ngIf="serviceOrder.getNummer()"
            [labelText]="'Serviceauftrag'"
            [value]="serviceOrder.getNummer()"
          ></app-bss-plain-text-box>
          <app-bss-array-organizer
            *ngIf="selectableEmployees"
            [label]="'Zusätzliche Monteure'"
            [selectedValues]="selectedEmployees"
            [possibleChoices]="selectableEmployees"
            [displayProperty]="'NAME'"
            (newArray)="updateEmployeeList($event)"
            [labelText]="'Monteur auswählen'"
            [placeholder]="'Monteur auswählen'"
            (closingWithEditedContent)="reloadWithSaving()"
          >
          </app-bss-array-organizer>
          <app-bss-plain-text-box
            *ngIf="serviceOrder.getBetreff()"
            [labelText]="'Betreff'"
            [value]="serviceOrder.getBetreff()"
          ></app-bss-plain-text-box>
        </div>
      </div>
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box
            *ngIf="serviceOrder.TerminObject?.start"
            [labelText]="'Beginn'"
            [value]="serviceOrder.TerminObject?.start?.substr(0, 16)"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            *ngIf="serviceOrder.TerminObject?.finish"
            [labelText]="'Ende'"
            [value]="serviceOrder.TerminObject?.finish?.substr(0, 16)"
          ></app-bss-plain-text-box>
        </div>
      </div>
      <div class="dx-fieldset">
        <app-bss-toggle-textarea
          *ngIf="anlage"
          [buttonText]="'Arbeitsbeschreibung'"
          [(value)]="anlage.ABESCHR"
          [disabled]="true"
        ></app-bss-toggle-textarea>

        <div class="card-detail" *ngIf="anlage">
          <app-system-card [anlage]="anlage"></app-system-card>
        </div>

        <app-bss-button-link
          [title]="'Positionen'"
          [anzahl]="currentPositionAmount"
          [path]="'auftragpositionen/wartungsauftrag/' + serviceOrder.UUID"
        ></app-bss-button-link>

        <app-bss-button-link
          *ngIf="fileUploadPossible"
          [title]="'Medien'"
          [path]="'medien/ServiceOrder/' + serviceOrder.UUID"
          [anzahl]="currentFilesAmount"
        >
        </app-bss-button-link>
      </div>
    </div>
  </div>
</div>

<div *ngIf="serviceOrder">
  <dx-speed-dial-action
    icon="photo"
    *ngIf="fileUploadPossible"
    label="Medium hinzufügen"
    (onClick)="navigateTo('medien/ServiceOrder/' + serviceOrder.UUID + '/upload')"
    [index]="2"
  >
  </dx-speed-dial-action>

  <dx-speed-dial-action
    *ngIf="serviceOrder?.getStatusText() === 'In Bearbeitung'"
    icon="check"
    label="Auftrag abschließen"
    (onClick)="editOrder(serviceOrder, userInfo, 'auftragsabschluss')"
    [index]="1"
  >
  </dx-speed-dial-action>

  <dx-speed-dial-action
    *ngIf="serviceOrder?.getStatusText() === 'Zugewiesen'"
    icon="add"
    label="Auftrag beginnen"
    [index]="0"
    (onClick)="editOrder(serviceOrder, userInfo, 'beginnen')"
  >
  </dx-speed-dial-action>

  <dx-speed-dial-action
    *ngIf="serviceOrder?.getStatusText() === 'In Bearbeitung'"
    icon="add"
    label="Position hinzufügen"
    [index]="0"
    (onClick)="editOrder(serviceOrder, userInfo, 'auftragpositionen')"
  >
  </dx-speed-dial-action>
</div>
