import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { IndexedDBTypes } from './dbType';

export function filterRoomsPerLevel(raumNamen: Raumname[], level: number): Raumname[] {
  const allRoomnames = raumNamen.slice();
  if (level === 2 || level === 1) return allRoomnames.filter(raumName => raumName.Typ === '2' || raumName.Typ === '1'); // sonderfall - könnte wohnung oder raum wollen
  const rommsPerLevel = allRoomnames.filter(raumName => raumName.Typ === `${level}`);
  return rommsPerLevel;
}

export class Raumname extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('Raumname') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') Uuid: string = null;
  @IndexedDBTypes.IndexField('string') Typ: string = null;
  @IndexedDBTypes.DataField('string') Bezeichng: string = null;
  constructor(raumname: Raumname) {
    super();
    assignIfPropertyExsits(this, raumname);
  }

  static toString(): string {
    return 'Raumname';
  }
}
