import { Component, OnInit } from '@angular/core';
import { HWAnlage, Messwerttabelle, MesswertTabellenEintrag } from 'apps/handwerkPWA/src/app/entities';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-measurements-index',
  templateUrl: './measurements-index.component.html',
  styleUrls: ['./measurements-index.component.scss'],
})
export class MeasurementsIndexComponent implements OnInit {
  anlage: HWAnlage;
  selectedMesswertsatz: Messwerttabelle;
  selectedMesswerteintraege: MesswertTabellenEintrag[];
  possibleMesswerttabellen: Messwerttabelle[];
  sollwertTabelle: Messwerttabelle;
  viewFinished = false;

  constructor(private routingService: RoutingService, private wartungsprojectsService: WartungsprojectsService) {}

  async ngOnInit(): Promise<void> {
    await this.loadAnlage();
    const messwertSatz = this.anlage.Messwertsatz;
    this.sollwertTabelle = messwertSatz.Sollwerttabelle;

    this.possibleMesswerttabellen = messwertSatz.Istwerttabellen;
    const firstMessung = this.possibleMesswerttabellen[0];
    if (firstMessung) {
      this.selectedMesswertsatz = firstMessung;
      this.selectedMesswerteintraege = this.selectedMesswertsatz.MesswertTabellenEintraege;
    }
    this.viewFinished = true;
  }

  async selectMesswertsatz(
    possibleMesswerttabellen: Messwerttabelle[],
    messwerttabelle: Messwerttabelle
  ): Promise<void> {
    await this.loadAnlage();
    const messwertSatz = this.anlage.Messwertsatz;
    this.sollwertTabelle = messwertSatz.Sollwerttabelle;
    const index = possibleMesswerttabellen.findIndex(satz => satz.UUID === messwerttabelle.UUID);
    this.selectedMesswertsatz = possibleMesswerttabellen[index];
    this.selectedMesswerteintraege = this.selectedMesswertsatz.MesswertTabellenEintraege;
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.wartungsprojectsService.findOneAnlageBy('UUID', anlageUUID);
  }
}
