import { Adressrights } from 'libs/shared/src/lib/entities';

export function hasAddressRight(addressRights: Adressrights, addressType: string, rightType: string): boolean {
  switch (addressType) {
    case 'K': {
      return rightType == 'edit' ? addressRights.editKunden : addressRights.showKunden;
    }
    case 'M': {
      return rightType == 'edit' ? addressRights.editMitarbeiter : addressRights.showMitarbeiter;
    }
    case 'F': {
      return rightType == 'edit' ? addressRights.editFreiAdressen : addressRights.showFreieAdressen;
    }
    case 'L': {
      return rightType == 'edit' ? addressRights.editLieferanten : addressRights.showLieferanten;
    }
    default: {
      return false;
    }
  }
}
