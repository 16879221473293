import { Employeerights } from './rights/Employeerights';
import { InappChangeRights } from './rights/InappChangeRights';
import { Employeesettings } from '../setting/Employeesettings';
import { PositionSettings } from '../setting/PositionSettings';
import { Adressrights, changeRightId, Gewerk, Lieferant, rightId, Terminrights, Wartungsrights } from '../..';
import { environment } from 'apps/handwerkPWA/src/environments/environment';
import { isNullOrUndefined, positionSettingChanged } from '../../../helper/globalHelper';
import { changeRightIds, accessRightsIds, accessRightId } from '../Typedstrings';

export class Right {
  InappChangeRights: InappChangeRights;
  Employeerights: Employeerights;
  Employeesettings: Employeesettings;
  PositionSettings: PositionSettings;

  constructor(data: Right) {
    this.Employeerights = new Employeerights(data?.Employeerights);
    this.InappChangeRights = new InappChangeRights(data?.InappChangeRights);
    this.Employeesettings = new Employeesettings(data?.Employeesettings);
    this.PositionSettings = new PositionSettings(data?.PositionSettings);
  }

  createRestrictedRight(): void {
    this.Employeerights.restrictEverything();
    this.InappChangeRights.restrictEverything();
  }

  createSuperRight(): void {
    this.Employeerights.allowEverything();
    this.InappChangeRights.allowEverything();
  }

  /**@description Prüft ob Dinge an den Rechten sich unterscheiden */
  areRightsEqual(compareRight: Right): boolean {
    for (const changeRight of changeRightIds) {
      const equalChangeRight =
        this.hasRightOrSettingEnabled(changeRight, true) === compareRight.hasRightOrSettingEnabled(changeRight, true);
      if (!equalChangeRight) return false;
    }
    for (const accessRight of accessRightsIds) {
      const equalAccessRight =
        this.hasRightOrSettingEnabled(accessRight, false) === compareRight.hasRightOrSettingEnabled(accessRight, false);
      if (!equalAccessRight) return false;
    }

    if (positionSettingChanged(this.PositionSettings, compareRight.PositionSettings, 'Gewerk')) return false;

    if (positionSettingChanged(this.PositionSettings, compareRight.PositionSettings, 'Lieferant')) return false;

    return true;
  }

  /**
   * @description Guckt ob das jeweilige Recht oder Setting an ist
   * @param changeRight Prüft statt das Recht selbst auf das jeweilige ChangeRight
   * */
  hasRightOrSettingEnabled(rightIdentifier: rightId, changeRight = false): boolean {
    if (changeRight) return this.hasChangeRight(rightIdentifier as changeRightId);
    const employeeRights = this.Employeerights;
    const adressRights = employeeRights.Adressrights;
    const terminRights = employeeRights.Terminrights;
    const wartungsRights = employeeRights.Wartungsrights;
    const allRights = {
      ...this.PositionSettings,
      ...this.Employeesettings,
      ...employeeRights,
      ...adressRights,
      ...terminRights,
      ...wartungsRights,
    };
    this.deleteCategories(allRights);
    if (!environment.production) this.checkAllRightsChecked(allRights, false);
    if (rightIdentifier === 'autoLohn') return allRights.autoLohn !== 'Aus';
    if (isNullOrUndefined(allRights[rightIdentifier as accessRightId]))
      throw new Error(`Rightid  ${rightIdentifier} does not exist`);
    return allRights[rightIdentifier as accessRightId] as boolean;
  }

  private hasChangeRight(changeRightIdentifier: changeRightId): boolean {
    const changeRights = this.InappChangeRights;
    if (!environment.production) this.checkAllRightsChecked(changeRights, true);
    return changeRights[changeRightIdentifier];
  }

  private deleteCategories(allRights: {
    Adressrights: Adressrights;
    Terminrights: Terminrights;
    Wartungsrights: Wartungsrights;
    Lieferanten: Lieferant[];
    Gewerk: Gewerk[];
  }) {
    delete allRights.Gewerk;
    delete allRights.Lieferanten;
    delete allRights.Adressrights;
    delete allRights.Wartungsrights;
    delete allRights.Terminrights;
  }

  /**@description Prüft im develop ob alle Rechte korrekt verglichen werden */
  private checkAllRightsChecked(allRights: unknown, changeRights: boolean) {
    const numberOfCheckedRights = Object.keys(allRights).length;
    const numberOfRightsChecked = changeRights ? changeRightIds.length : accessRightsIds.length;
    if (numberOfCheckedRights !== numberOfRightsChecked) {
      console.error(allRights);
      console.error('vs');
      const error = changeRights ? console.error(changeRightIds) : console.error(accessRightsIds);
      console.error(`Number of rights to check is ${numberOfCheckedRights} but should be ${numberOfRightsChecked}`);
    }
  }
}
