import { Component, OnInit } from '@angular/core';
import { HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { Documentservice } from 'apps/handwerkPWA/src/app/services/globalServices/document.service';
import { calculateDataAmount } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-system-edit',
  templateUrl: './system-edit.component.html',
  styleUrls: ['./system-edit.component.scss'],
})
export class SystemEditComponent implements OnInit {
  anlage: HWAnlage;
  showBeschreibung = false;
  scrollOffsetTop: number;
  fileUploadPossible: boolean;
  currentFilesAmount: string;

  constructor(
    private wartungsprojectsService: WartungsprojectsService,
    private routingService: RoutingService,
    private authorisationService: AuthorisationService,
    private documentService: Documentservice,
    private rightService: RechteService
  ) {}

  async ngOnInit(): Promise<void> {
    this.fileUploadPossible = this.authorisationService.current.getValue().featureCheck('Medientabelle1').available;
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.wartungsprojectsService.findOneAnlageBy('UUID', anlageUUID);
    await this.getFileAmount();
  }

  private async getFileAmount() {
    const files = await this.documentService.getAllBy('Baseuuid', this.anlage.UUID);
    this.currentFilesAmount = calculateDataAmount(files);
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  public messungEnabled(): boolean {
    return this.rightService.hasRightOrSettingEnabled('inputMeasuredValues');
  }
}
