import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-bss-numberbox',
  templateUrl: './bss-numberbox.component.html',
  styleUrls: ['./bss-numberbox.component.scss'],
})
export class BssNumberboxComponent implements OnInit {
  @Input() format: string | {};
  @Input() max: number;
  @Input() min: number;
  @Input() labelText;
  @Input() buttonIcon;
  @Input() value: number;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Output() valueChange = new EventEmitter<number>();
  @Output() buttonClick = new EventEmitter<boolean>();

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {}

  valueChanged(value: number): void {
    this.routingService.dataChanged.next(true);
    this.valueChange.emit(value);
  }

  btnClicked(): void {
    this.buttonClick.emit(true);
  }
}
