import { Component, OnDestroy, OnInit } from '@angular/core';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { ObjectaddressService } from '../../../../services/dataServices/objectAddress.service';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import {
  calculateDataAmount,
  isNullOrUndefined,
  isNullOrUndefinedOrEmptyString,
} from 'libs/shared/src/lib/helper/globalHelper';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { HWFile, HWAddress, HWObjectaddress, Zusatzbezeichnungen } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { Documentservice } from 'apps/handwerkPWA/src/app/services/globalServices/document.service';

@Component({
  selector: 'app-object-address-edit',
  templateUrl: './object-address-edit.component.html',
  styleUrls: ['./object-address-edit.component.scss'],
})
export class ObjectAddressEditComponent implements OnInit, OnDestroy {
  objectaddress: HWObjectaddress;
  kunden: HWAddress[];
  showZusaetze = false;
  objektZusatzBezeichnungen: string[];
  selectedFile: HWFile;
  infoIconZusatz: boolean;
  laender = [
    'A',
    'B',
    'CH',
    'CZ',
    'D',
    'DK',
    'E',
    'EE',
    'F',
    'FIN',
    'GB',
    'GR',
    'H',
    'I',
    'IE',
    'L',
    'LT',
    'LV',
    'M',
    'NL',
    'NOR',
    'P',
    'PL',
    'RU',
    'S',
    'SI',
    'SK',
  ];
  kundenPath: string;
  userInfo: Userinfo;
  dataChanged: boolean;
  subscription: Subscription;
  dataSavedSubscription: Subscription;
  currentFilesAmount: string;

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private objectaddressService: ObjectaddressService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private authorisationService: AuthorisationService,
    private documentService: Documentservice
  ) {}

  async ngOnInit() {
    this.userInfo = await this.globalSettingService.getUserinfo();

    this.subscription = this.routingService.save.subscribe(save => {
      void this.save();
    });

    await this.loadObjectAddress();

    this.dataSavedSubscription = this.routingService.dataChanged.subscribe(dataChanged => {
      this.dataChanged = dataChanged;
    });
    this.kunden = await this.addressService.getAllBy('ADRTYP', 'K');
    this.kundenPath = this.createKundenpath(this.kunden);
    const zusatzBezeichnungen: Zusatzbezeichnungen = await this.globalSettingService.getEntity('Zusatzbezeichnungen');
    if (!isNullOrUndefined(zusatzBezeichnungen))
      this.objektZusatzBezeichnungen = zusatzBezeichnungen.ObjektZusatzBezeichnung;
    this.setUnsetZusaetzeIcon();
    const newVersion = this.authorisationService.current.getValue().featureCheck('Medientabelle2').available;
    const files = newVersion
      ? await this.documentService.getAllBy('Baseuuid', this.objectaddress.getUuid())
      : this.objectaddress.Files;
    this.currentFilesAmount = calculateDataAmount(files);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.dataSavedSubscription?.unsubscribe();
  }

  private createKundenpath(kunden: HWAddress[]): string {
    const kundenGuid = kunden.find(kunde => kunde.KU_NR === this.objectaddress.KuNr)?.Guid;
    return `/adresse/${kundenGuid}/edit`;
  }

  setUnsetZusaetzeIcon(): void {
    this.infoIconZusatz = false;
    const tmpZusatzArray = this.objectaddress.Zusatz.filter(zusatz => isNullOrUndefinedOrEmptyString(zusatz));
    if (tmpZusatzArray.length < 5) this.infoIconZusatz = true;
  }

  selectedACountry(laenderKennzeichen: string) {
    this.objectaddress.LAND = laenderKennzeichen;
  }

  isNewObjectAddress(): boolean {
    const LfdNr = this.objectaddress.LfdNr;
    if (LfdNr.length > 10) return true;
    return false;
  }

  async save(): Promise<void> {
    if (isNullOrUndefined(this.objectaddress.Name) || this.objectaddress.Name === '') {
      this.dialogService.closeLoadingDialog();
      await this.dialogService.openConfirmDialog(
        'Fehlende Daten!',
        'Um diesen Datensatz zu sichern muss ein Name angegeben werden.',
        'ok',
        null,
        true
      );
      return;
    }

    void this.dialogService.openLoadingDialog('Speichern', 'Speichere Datensatz.');
    if (this.isNewObjectAddress()) {
      const kunde = await this.addressService.findOneBy('KU_NR', this.userInfo.monteur);
      const mitarbeiter = kunde.NAME;
      const datum = dateToDatabaseDate(new Date());
      this.objectaddress.BEMERKUNG =
        '# Mobil angelegt am ' + datum + ' durch ' + mitarbeiter + ' # ' + this.objectaddress.BEMERKUNG;
    }
    this.objectaddress.Such = this.objectaddress.Name.toUpperCase();
    if (isNullOrUndefined(this.objectaddress.Kunde)) {
      const kundeZuObject = this.kunden.find(kunde => kunde.KU_NR === this.objectaddress.KuNr);
      this.objectaddress.addKunde(kundeZuObject);
    }
    await this.objectaddressService.sendAddressToWebservice(this.userInfo, this.objectaddress);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    await this.routingService.navigateTo('adresse/' + this.objectaddress.Kunde.Guid + '/objektadressen');
  }

  openMaps(item: HWObjectaddress): void {
    const ort = item.ORT;
    const strasse = item.STRASSE;
    if (!isNullOrUndefined(ort) && ort !== '') {
      window.open('https://www.google.com/maps/search/?api=1&query=' + strasse + '%2C+' + ort, '_blank');
    }
  }

  private async loadObjectAddress(): Promise<void> {
    if (this.routingService.getCurrentRoute().includes('neu')) this.objectaddress = await this.createNewObjectAdress();
    else {
      const addressGuid = this.routingService.getRouteParam('guid');
      this.objectaddress = await this.objectaddressService.findOneBy('Guid', addressGuid);
    }
  }

  private async createNewObjectAdress(): Promise<HWObjectaddress> {
    const kundennummer = this.routingService.getRouteParam('kundennummer');
    const kunde = await this.addressService.findOneBy('KU_NR', kundennummer);

    const newObjectAdress = new HWObjectaddress(null, kundennummer);
    newObjectAdress.addKunde(kunde);

    return newObjectAdress;
  }

  async newImages(): Promise<void> {
    await this.routingService.navigateTo(`medien/ObjectAdress/${this.objectaddress.Guid}/upload`);
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }
}
