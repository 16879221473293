import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { getLongtypeFromKundenpreisId } from 'apps/handwerkPWA/src/app/helper/entities/HWRepairOrderItem/longTypeHelper';
import { HWRepairOrderItem } from '../repository/HWRepairOrderItem';

export class Kundenpreis {
  KU_NR: string = null;
  /**NICHT analog zu den Positionstypen,hier: M = Artikel, L = Lohn */
  Typ: 'M' | 'L' = null;
  Lief: string = null;
  Nummer: string = null;
  Preis: number = null;
  Geaendert: Date = null;
  User: number = null;
  Bemerkung: string = null;
  Isbrutto: boolean = null;
  Ispeconv: boolean = null;
  Uuid: string = null;
  Preiskategorie: string = null;

  constructor(kundenPreis: Kundenpreis) {
    assignIfPropertyExsits(this, kundenPreis);
  }
}

/**@description Guckt ob ein abweichender Kundenpreis für diese Position vorliegt */
export function checkForKundenprice(
  kundenpreise: Kundenpreis[],
  item: HWRepairOrderItem,
  initialPrice: number
): number {
  const itemType = item.getLongtype();
  const itemNummer = item.Nummer;
  const differentKundenpreis = kundenpreise?.find(
    kundenpreis => getLongtypeFromKundenpreisId(kundenpreis.Typ) === itemType && itemNummer === kundenpreis.Nummer
  );
  if (differentKundenpreis) return differentKundenpreis.Preis;
  return initialPrice;
}
