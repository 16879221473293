import { AufmassStrecke } from './AufmassStrecke';
import { BauelementVorlage } from './BauelementVorlage';
import { Punkt } from './Punkt';

export class Bauelement extends AufmassStrecke {
  // laengeGemessen - entspricht der laenge in der jeweiligen wand. also nach sprachgebrauch wohl eher der "breite"
  /**bspw. höhe einer tür */
  Hoehe?: number = null;
  /**räumliche tiefe - leibungstiefe */
  Tiefe?: number = null;
  vorlage: BauelementVorlage = null;
  abzugFromUuuid: string = null;
  constructor(
    startPunkt: Punkt,
    endPunkt: Punkt,
    vorlage: BauelementVorlage,
    index: number,
    abzugFromUuid: string,
    alternatingIndex: number
  ) {
    super(startPunkt, endPunkt, index);
    this.bezeichnung = 'b' + index;
    this.bezeichnungLang = vorlage?.bezeichnung + alternatingIndex;
    this.vorlage = vorlage;
    this.abzugFromUuuid = abzugFromUuid;
  }
}
