export const changeRightIds = [
  'showWorkinghoursInPdf',
  'autoLohn',
  'addWorkinghoursToDocument',
  'mailFromOrder',
  'pdfPreview',
  'printPrices',
  'rounding',
  'showPrices',
] as const;
export type changeRightId = typeof changeRightIds[number];
export const accessRightsIds = [
  'addWorkingHoursToOrder',
  'addWorkingHoursToPDF',
  'allowCashPayment',
  'allowCreation',
  'allowEdit',
  'createOrder',
  'editFreiAdressen',
  'editKunden',
  'editLieferanten',
  'editMitarbeiter',
  'handleOrder',
  'inputMeasuredValues',
  'pdfPreview',
  'printPrices',
  'sendRepairOrderMail',
  'showAll',
  'showAuftrag',
  'showBelege',
  'showFreieAdressen',
  'showKunden',
  'showLieferanten',
  'showMitarbeiter',
  'showObjektadressen',
  'showOffenePosten',
  'showPrices',
  'showPurchasingPrice',
  'showRetailprice',
  'showUmsatz',
  'sumUp',
  'useFilterGewerk',
  'useFilterLieferanten',
  'autoLohn',
  'createRepairOrder',
] as const;
export type accessRightId = typeof accessRightsIds[number];
export type rightId = changeRightId | accessRightId;

export const globalSettingsEntities = [
  'UpdateDone',
  'Zusatzbezeichnungen',
  'Textvorlagen',
  'TimestampTable',
  'entdeckenLastModifiedBase',
  'entdeckenLastModifiedPremium',
  'AppOnlySettings',
  'EmailConnectionInfo',
  'Rechte',
  'Einstellungen',
  'Userinfo',
  'ErrorLoggingEnabled',
  'FirstTime',
] as const;
export type GlobalSettingsEntity = typeof globalSettingsEntities[number];

export const entdeckenBasicUrl = 'https://wordpress-dev.bssservices.de/basic/';
export const entdeckenPremiumUrl = 'https://wordpress-dev.bssservices.de/premium/';
