<div class="plain-text-box">
  <div class="dx-field">
    <div *ngIf="labelText" class="dx-field-label">{{ labelText }}</div>
    <div class="dx-field-value" [ngClass]="{ 'input-with-button': buttonIcon && buttonEnabled }">
      <div class="text-input" style="line-height: normal !important">
        <p *ngFor="let val of valueArray">{{ val }}</p>
        <p *ngIf="value">{{ value }}</p>
        <div class="hint" *ngIf="hintText" [ngClass]="hintClass">{{ hintText }}</div>
      </div>
      <dx-button
        *ngIf="buttonIcon && buttonEnabled"
        [icon]="buttonIcon"
        (onClick)="btnClicked(buttonIcon, value)"
      ></dx-button>
    </div>
  </div>
</div>
