import { Injectable } from '@angular/core';
import { BaseAuftrag } from 'apps/handwerkPWA/src/app/interfaces';
import { EmailconnectionInfo, Setting } from 'libs/shared/src/lib/entities';
import { HWEmailData, HWGlobalSetting } from 'apps/handwerkPWA/src/app/entities';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { DialogService } from '@handwerk-pwa/shared';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import CryptoES from 'crypto-es';
import { ControllerService } from 'apps/handwerkPWA/src/app/services/globalServices/controller.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { RechteService } from './rechte.service';
const secret = 'bsssmpt';

@Injectable({
  providedIn: 'root',
})
export class Mailservice {
  constructor(
    private dialogService: DialogService,
    private restService: RestService,
    private controllerService: ControllerService,
    private globalSettingService: HWGolbalsettingService,
    private rechteService: RechteService
  ) {}

  /**@description Sendet die E-mail zu einem Reparatur oder Serviceauftrag */
  async sendOrderMail(userInfo: Userinfo, order: BaseAuftrag, pdf: string, mailAdress: string): Promise<HWEmailData> {
    const emailConnectionInfo = await this.getEmailconnectionInfo();

    if (!mailAdress) mailAdress = await this.EmptyEmailDialog(mailAdress);

    const possible = await this.checkSendPossible(mailAdress, emailConnectionInfo);
    if (!possible) return null;

    const emailData = new HWEmailData(emailConnectionInfo);
    const settings = await this.globalSettingService.getEntity<Setting>('Einstellungen');

    emailData.assignMailData(order, settings, mailAdress, pdf);
    const response: string = await this.sendMail(userInfo, emailData);
    const success = await this.handleEmailResponse(response);
    if (success) return emailData;
    return null;
  }

  /**@description Lets the user choose to create an email if it is missing */
  private async EmptyEmailDialog(mailAdress: string): Promise<string> {
    const confirmResponse = await this.dialogService.openConfirmDialog(
      'E-Mail-Adresse erstellen?',
      'Es wurde keine E-Mail-Adresse angegeben, an welche die E-Mail versandt werden soll. Möchten Sie diese jetzt eingeben?',
      'E-Mail-Adresse eingeben',
      'keine E-Mail verschicken',
      true
    );
    if (confirmResponse) return await this.InputEmailDialog();
    return mailAdress;
  }

  /**@description Opens the InputDialog and returns its output */
  private async InputEmailDialog(): Promise<string> {
    return await this.dialogService.openInputDialog('E-Mail', 'Folgende E-Mail-Adresse soll verwendet werden:');
  }

  /**@description Sendet die Testmail */
  async sendTestMail(userInfo: Userinfo, settings: Setting, mailAdress: string): Promise<string> {
    const emailConnectionInfo = settings.emailConnectionInfo
      ? settings.emailConnectionInfo
      : await this.getEmailconnectionInfo();
    const possible = await this.checkSendPossible(mailAdress, emailConnectionInfo);
    if (!possible) return null;

    const emailData = new HWEmailData(emailConnectionInfo);
    emailData.assignTestmailData(settings, mailAdress);
    const response: string = await this.sendMail(userInfo, emailData);
    await this.handleEmailResponse(response);
    return response;
  }

  /**@description Benachrichtigt den User über erfolg oder misserfolg seines versands */
  private async handleEmailResponse(response: string): Promise<boolean> {
    if (isNullOrUndefined(response)) {
      await this.dialogService.openErrorMessage('E-Mail Fehler', 'Es trat ein Fehler beim Emailversand auf.');
      return false;
    }

    if (response.includes('Emailversand erfolgt')) {
      this.dialogService.openInformDialog('Erfolg', 'E-Mail versand erfolgt.');
      return true;
    }
    if (response.includes('mailbox unavailable')) {
      await this.dialogService.openErrorMessage('Fehler', 'Das Postfach des Empfängers konnte nicht erreicht werden.');
      return false;
    }
    if (response.includes('Authentication credentials invalid')) {
      await this.dialogService.openErrorMessage('Fehler', 'E-Mail Zugangsdaten sind ungültig');
      return false;
    }

    await this.dialogService.openErrorMessage('Fehler', 'Es trat ein unbekannter Fehler auf');
    return false;
  }

  /**@description Guckt ob der Versand generell möglich ist (wenn eine Zieladresse vorhanden ist und zumindest ein smtpuser) */
  private async checkSendPossible(mailAdress: string, emailConnectionInfo: EmailconnectionInfo): Promise<boolean> {
    let isPossible = false;
    if (await this.validateEmailAdress(mailAdress)) {
      isPossible = await this.validateEmailonnection(emailConnectionInfo, true);
    }
    return isPossible;
  }

  private async sendMail(userInfo: Userinfo, emailData: HWEmailData): Promise<string> {
    emailData.smtpPassword = btoa(emailData.smtpPassword);
    const response = await this.restService.returnData<string>(userInfo, 'sendRepairOrderEmail', emailData);
    return response;
  }

  async validateEmailAdress(EmailAdress: string): Promise<boolean> {
    if (!EmailAdress) {
      await this.dialogService.openErrorMessage(
        'E-Mail',
        'Die E-Mail konnte nicht versendet werden, da keine Empfängeradresse vorhanden ist.'
      );
      return false;
    }
    const atSplit = EmailAdress.split('@');
    if (atSplit?.length !== 2 || !atSplit[1]?.includes('.')) {
      await this.dialogService.openErrorMessage('Fehler', 'Empfänger-Adresse ist ungültig.');
      return false;
    }
    return true;
  }

  async validateEmailonnection(
    emailConnection: EmailconnectionInfo,
    checkForSendEmail: boolean = false
  ): Promise<boolean> {
    if (checkForSendEmail && isNullOrUndefined(emailConnection)) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlende E-Mail-Einstellungen',
        'Sie müssen die E-Mail-Einstellungen ausfüllen oder vom Server synchronisieren um E-Mails versenden zu können',
        'Ok'
      );
      return false;
    }
    if (isNullOrUndefined(emailConnection?.smtpUser)) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlender Benutzername',
        'Sie müssen einen Benutzernamen angeben um die E-Mail Zugangsdaten zu speichern',
        'Ok'
      );
      return false;
    }
    if (isNullOrUndefined(emailConnection?.smtpPassword)) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlendes Passwort',
        'Sie müssen ein Passwort angeben um die E-Mail Zugangsdaten zu speichern',
        'Ok'
      );
      return false;
    }
    if (isNullOrUndefined(emailConnection?.smtpServer)) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlender SMTP-Server',
        'Sie müssen einen SMTP-Server angeben um die E-Mail Zugangsdaten zu speichern',
        'Ok'
      );
      return false;
    }
    if (
      !isNullOrUndefinedOrEmptyString(emailConnection?.smtpUser) &&
      !isNullOrUndefinedOrEmptyString(emailConnection?.smtpPassword) &&
      !isNullOrUndefinedOrEmptyString(emailConnection?.smtpServer) &&
      isNullOrUndefined(emailConnection?.smtpPort)
    ) {
      await this.dialogService.openAwaitableInformDialog(
        'Fehlender SMTP-Port',
        'Sie müssen einen SMTP-Port angeben um die E-Mail Zugangsdaten zu speichern',
        'Ok'
      );
      return false;
    }
    return true;
  }

  async setEmailconnectionInfo(
    emailconnectionInfo: EmailconnectionInfo,
    containingPasswordAsBase64 = false
  ): Promise<void> {
    const newMailConnectionInfo = new EmailconnectionInfo();
    Object.assign(newMailConnectionInfo, emailconnectionInfo);
    if (containingPasswordAsBase64 && newMailConnectionInfo?.smtpPassword)
      newMailConnectionInfo.smtpPassword = atob(newMailConnectionInfo.smtpPassword);
    const passwordEncrypted = CryptoES.AES.encrypt(newMailConnectionInfo.smtpPassword, secret).toString();
    newMailConnectionInfo.smtpPassword = passwordEncrypted;
    await this.globalSettingService.setEntity(newMailConnectionInfo, 'EmailConnectionInfo');
  }

  async getEmailconnectionInfo(): Promise<EmailconnectionInfo> {
    const emailConnectionInfo = await this.globalSettingService.getEntity<EmailconnectionInfo>('EmailConnectionInfo');
    if (!emailConnectionInfo) return null;
    const passwordEncrypted = emailConnectionInfo.smtpPassword;
    const passwordDecrypted = CryptoES.AES.decrypt(passwordEncrypted, secret).toString(CryptoES.enc.Utf8);
    emailConnectionInfo.smtpPassword = passwordDecrypted;
    return emailConnectionInfo;
  }

  public async overrideLocalEmailConnectionInfoWithHandwerkDbData(
    userInfo: Userinfo,
    showDialog: boolean
  ): Promise<EmailconnectionInfo> {
    const setting = await this.rechteService.getSettingFromHandwerkDb(userInfo, false, !showDialog);
    await this.setEmailconnectionInfo(setting.emailConnectionInfo, true);
    return setting.emailConnectionInfo;
  }
}
