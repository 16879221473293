import { Component, OnDestroy, OnInit } from '@angular/core';
import { Messwertsatz, Messwerttabelle, MesswertTabellenEintrag, HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-measurement-new',
  templateUrl: './measurement-new.component.html',
  styleUrls: ['./measurement-new.component.scss'],
})
export class MeasurementNewComponent implements OnInit, OnDestroy {
  userInfo: Userinfo;
  anlage: HWAnlage;
  Messwertsatz: Messwertsatz;
  Sollwersatz: Messwerttabelle;
  Sollwerteintraege: MesswertTabellenEintrag[];
  Messung: Messwerttabelle;
  Messwerteintraege: MesswertTabellenEintrag[];
  openKeyboard = false;
  subscription: Subscription;

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private wartungsprojectsService: WartungsprojectsService,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscription = this.routingService.save.subscribe(save => {
      void this.addMessung();
    });

    this.userInfo = await this.globalSettingService.getUserinfo();
    await this.loadAnlage();
    const anlageDaten = this.anlage.Anlagendaten;
    this.Messwertsatz = this.anlage.Messwertsatz;

    this.Sollwersatz = this.Messwertsatz.Sollwerttabelle;
    this.Sollwerteintraege = this.Sollwersatz.MesswertTabellenEintraege;

    this.Messung = new Messwerttabelle(anlageDaten, this.Sollwersatz, true);
    this.Messwerteintraege = this.Messung.MesswertTabellenEintraege;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  clearIstwerte(Messwerteintraege: MesswertTabellenEintrag[]): void {
    for (const eintrag of Messwerteintraege) {
      eintrag.Messwert = ' ';
    }
  }

  async addMessung(): Promise<void> {
    await this.wartungsprojectsService.sendMessungToWebservice(this.anlage, this.Messung, this.userInfo);
    this.routingService.dataChanged.next(false);
    void this.routingService.routeBack();
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.wartungsprojectsService.findOneAnlageBy('UUID', anlageUUID);
  }
}
