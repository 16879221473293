import { deDateFormat, deDatettimeFormat } from 'apps/handwerkPWA/src/app/config/Konstanten';
import moment from 'moment';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from './globalHelper';

/**@description Prüft ob ein String als ein Valides Date interpretiert werden kann
 * @param withTime Ob das Date eine Uhrzeit in format Stunden:minuten enthält
 */
export function validateDateinput(dateString: string, withTime = false) {
  if (isNullOrUndefined(dateString)) {
    return false;
  }
  if (dateString === '') {
    return false;
  }
  const dateSplit = dateString.split('.');
  if (dateSplit.length !== 3) {
    return false;
  }
  const tag = parseInt(dateSplit[0], 10);
  if (isNaN(tag)) {
    return false;
  }
  const monat = parseInt(dateSplit[1], 10);
  if (isNaN(monat)) {
    return false;
  }
  const jahr = parseInt(dateSplit[2], 10);
  if (isNaN(jahr)) {
    return false;
  }
  if (tag < 1 || tag > 31) {
    return false;
  }
  if (monat < 1 || monat > 12) {
    return false;
  }
  if (dateSplit[2].length < 4) {
    // Jahresziffern
    return false;
  }
  if (withTime) {
    const dateIncludesValidTime = datestringIncludesValidTimeString(dateString);
    return dateIncludesValidTime;
  }
  return true;
}

/**Nimmt ein formatiertes Datum im Format 31.12.2001 00:00:00 und gibt ein date zurück */
export function DatabaseDateToDate(currentDateComplete: string, withTime?: boolean): Date {
  // currentDateComplete kommt im Format: 01.12.2019 00:00:00 , deswegen splitten an leerzeichen, anschließend an punkten
  let newDate;
  if (currentDateComplete) {
    const currentDate = currentDateComplete.split(' ');
    const tagMonatJahr = currentDate[0].split('.');
    const tag = parseInt(tagMonatJahr[0], 0);
    // auch hier beachten, monat beginnt bei 0, daher einen abziehen
    const monat = parseInt(tagMonatJahr[1], 0) - 1;
    const jahr = parseInt(tagMonatJahr[2], 0);

    if (!withTime) {
      newDate = new Date(jahr, monat, tag);
    }
    if (withTime) {
      const hoursMinutes = currentDate[1].split(':');
      newDate = new Date(jahr, monat, tag, Number(hoursMinutes[0]), Number(hoursMinutes[1]));
    }
  }
  return newDate;
}

/**@description Nimmt ein Date und gibt es im Format dd.mm.yyyy zurück (dd.mm.yyyy HH:mm wenn withTime auf true) (dd.mm.yyyy HH:mm:ss wenn withTime auf true) */
export function dateToDatabaseDate(date: Date, withTime?: boolean, withSeconds?: boolean): string {
  if (!date) {
    return null;
  }

  let formatString = 'DD.MM.YYYY';
  if (withTime || withSeconds) formatString += ' HH:mm';
  if (withSeconds) formatString += ':ss';
  const dateString = moment(date).format(formatString);
  return dateString;
}

/**@description Nimmt eine Zeit in Minuten und gibt sie im Format x Stunden y Minuten zurück */
export function getStundenMinutenString(zeit: number) {
  const stunden = Math.floor(zeit / 60);
  const minuten = zeit % 60;
  let stundenstring = ' Stunde ';
  let minutenString = ' Minuten ';
  if (stunden > 1) {
    stundenstring = ' Stunden ';
  }
  if (minuten === 1) {
    minutenString = ' Minute ';
  }
  if (stunden === 0) {
    return minuten + minutenString;
  }
  if (minuten === 0) {
    return stunden + stundenstring;
  }

  return stunden + stundenstring + minuten + minutenString;
}

/**@description Prüft ob ein Datestring einen Validen Stunde:Minuten timestring enthält */
function datestringIncludesValidTimeString(dateString: string): boolean {
  const dateTimeSplit = dateString.split(' ');
  if (dateTimeSplit.length !== 2) {
    return false;
  }
  const zeitString = dateTimeSplit[1];
  const zeitSplit = zeitString.split(':');
  const stundenString = zeitSplit[0];
  const stunden = parseInt(stundenString, 10);
  if (isNaN(stunden)) {
    return false;
  }
  if (stunden < 1 || stunden > 23) {
    return false;
  }
  const minutenString = zeitSplit[1];
  if (minutenString.length !== 2) {
    return false;
  }
  const minuten = parseInt(minutenString, 10);
  if (isNaN(minuten)) {
    return false;
  }
  if (minuten < 1 || minuten > 59) {
    return false;
  }
  return true;
}

/**parsed einen Datestring im Format dd.MM.YYYY oder dd.MM.YYYY HH:mm */
export function parseTermin(dateString: string): Date {
  dateString = dateString?.trim();
  if (isNullOrUndefinedOrEmptyString(dateString)) return null;
  const split = dateString.split(' ');
  if (split.length === 1) return moment(dateString, deDateFormat).toDate();
  if (split.length === 2) return moment(dateString, deDatettimeFormat).toDate();
  return null;
}

/**führt führende nullen ein */
export function padTime(hoursOrMinutes: number): string {
  const value = hoursOrMinutes.toString();
  return value.padStart(2, '0');
}
