import { Component, Input, OnInit } from '@angular/core';
import { RVorlage } from 'apps/handwerkPWA/src/app/entities';
import { RaumbPos } from 'apps/handwerkPWA/src/app/entities/models/aufmass/RaumbPos';

@Component({
  selector: 'app-raum-card',
  templateUrl: './raum-card.component.html',
  styleUrls: ['./raum-card.component.scss'],
})
export class RaumbuchCardComponent implements OnInit {
  @Input() raum: RVorlage | RaumbPos;

  constructor() {}

  ngOnInit(): void {}

  gotoRaumbuchDetails() {}
}
