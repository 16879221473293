<div *ngIf="viewFinished">
  <h2 class="content-block">{{ title }}</h2>

  <div class="content-block">
    <dx-select-box
      *ngIf="!addressGuid"
      [dataSource]="customerAddresses"
      displayExpr="NAME"
      searchMode="contains"
      [searchExpr]="'NAME'"
      [minSearchLength]="1"
      [searchEnabled]="true"
      [showDataBeforeSearch]="true"
      (onSelectionChanged)="selectedCustomerChanged($event)"
    ></dx-select-box>
    <br />
    <app-bss-list
      [id]="'supporting-documents'"
      [inputDatasource]="supportingDocuments"
      [searchExpressions]="displayFieldsBelege"
      [emptyInputDataMessage]="'Es sind keine Belege vorhanden'"
    ></app-bss-list>
  </div>
</div>
