import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { SyncService } from './sync.service';
import { DialogService } from '@handwerk-pwa/shared';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { DataSetNames, SitesToReloadAfterNewPushData } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { Right } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { RepairOrderService } from '../dataServices/repairOrder.service';
import {
  changesInToRoute,
  changesInToString,
  convertToServicename,
} from 'apps/handwerkPWA/src/app/helper/services/backgroundHelper';
import { MonteurService } from '../dataServices/monteur.service';
import { HWGolbalsettingService } from './hwgolbalsetting.service';
import { ConnectionService } from './connection.service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundService implements OnDestroy {
  dailySubscription: Subscription;
  backgroundIntervallSubscription: Subscription;

  constructor(
    private syncService: SyncService,
    private dialogService: DialogService,
    private rechteService: RechteService,
    private routingService: RoutingService,
    private repairOrderService: RepairOrderService,
    private monteurService: MonteurService,
    private globalSettingService: HWGolbalsettingService,
    private connectionService: ConnectionService
  ) {}

  ngOnDestroy(): void {
    this.stopBackgroundTasks();
  }

  /**@description Führt tägliche Dinge durch: Nach neuen Dingen im "Entdecken bereich gucken" */
  doDailyCheck(): void {
    const timeIntervallInMinutes = 60 * 24;
    const everyNMinutes$ = interval(timeIntervallInMinutes * 60 * 1000);
    this.dailySubscription = everyNMinutes$.subscribe(() => {
      void this.syncService.getNewThingsToDiscover();
    });
  }

  stopBackgroundTasks(): void {
    this.stopBackgroundSyncIntervall();
    this.dailySubscription?.unsubscribe();
  }

  stopBackgroundSyncIntervall(): void {
    this.backgroundIntervallSubscription?.unsubscribe();
  }

  /**@description Führt alle Backgroundtasks durch, aktuell: Eventhandler initiieren
   * @value timeIntervall Zeitinvervall in Minuten, 0 = aus
   */
  startBackgroundSyncIntervall(userInfo: Userinfo, timeIntervall: number): void {
    if (timeIntervall === 0) return;
    const everyNMinutes$ = interval(timeIntervall * 60 * 1000);
    this.backgroundIntervallSubscription = everyNMinutes$.subscribe(() => void this.doBackgroundRoutine(userInfo));
  }

  private async doBackgroundRoutine(userInfo: Userinfo): Promise<void> {
    if (!(await this.connectionService.CheckOnline())) return;
    const rights = await this.checkForUnsyncedData(userInfo);
    await this.pushAlternative(userInfo, rights);
  }

  /**@description Alternative zum Push für IOS und Android mit abgelehntem Push */
  private async pushAlternative(userInfo: Userinfo, rights: Right) {
    const auftragsRecht = rights.Employeerights.showAuftrag && rights.Employeerights.showObjektadressen;
    const changes = await this.syncService.checkForChanges(userInfo, rights, true);
    const changesIn = changes.dataSetNames;
    if (changesIn.length === 0) return;
    for (const dataSet of changesIn) {
      if (dataSet === 'Aufträge' && !auftragsRecht) continue;
      const service = convertToServicename(dataSet);
      await this.syncService.getSpecificDataFromWebService(userInfo, service, true);
    }
    void this.routeOrReloadAfterChanges(changesIn, auftragsRecht, changes.ordersAlreadyInApp);
  }

  private async routeOrReloadAfterChanges(
    changesIn: DataSetNames[],
    auftragsRecht: boolean,
    ordersAlreadyInApp: string[]
  ) {
    const changesInPushData = changesIn.includes('Aufträge') || changesIn.includes('Nachrichten');
    const changesInBoth = changesIn.includes('Aufträge') && changesIn.includes('Nachrichten');
    const changeString = changesInToString(changesIn, auftragsRecht);
    if (changesInPushData && !changesInBoth)
      return await this.routeToChangedData(changeString, changesIn, ordersAlreadyInApp);
    if (changesInPushData)
      this.dialogService.openInformDialog(
        'Daten aktualisiert',
        'Es gibt Neuerungen oder Änderungen in folgenden Datensätzen: ' + changeString,
        'Ok'
      );
    const currentRoute = this.routingService.lastRoutes[0];
    const onReloadableSite = SitesToReloadAfterNewPushData.includes(currentRoute);
    if (onReloadableSite) this.routingService.reload();
  }

  /**@description Fragt den Nutzer ob er zum konkret aktualisierten Datensatz navigieren möchte */
  private async routeToChangedData(changeString: string, changesIn: DataSetNames[], ordersAlreadyInApp: string[]) {
    let routeTo: string = changesInToRoute(changesIn);
    let navigateToString =
      routeTo === '/nachrichten' ? 'Zu Nachrichten navigieren' : 'Zu den Reparaturaufträgen navigieren';
    if (routeTo === '/reparaturauftraege')
      ({ routeTo, navigateToString } = await this.tryFindingNewestRepairOrder(
        ordersAlreadyInApp,
        routeTo,
        navigateToString
      ));
    const navigate = await this.dialogService.openConfirmDialog(
      'Daten aktualisiert',
      `Es gibt Neuerungen oder Änderungen in ${changeString} `,
      navigateToString,
      'Schließen',
      true
    );
    if (navigate) void this.routingService.navigateTo(routeTo);
    return;
  }

  /**@description Versucht neusten Auftrag zu finden - gelingt dies wird die Route geändert auf die Detailansicht und der Text angepasst */
  private async tryFindingNewestRepairOrder(ordersAlreadyInApp: string[], routeTo: string, navigateToString: string) {
    const allOrders = await this.repairOrderService.getAllRepairOrdersFromIDB(true);
    const allOrderNumbers = allOrders.map(order => order.getNummer());
    const newNumbers = allOrderNumbers.filter(order => !ordersAlreadyInApp.includes(order));
    if (newNumbers.length === 1) {
      const newOrderNumber = newNumbers[0];
      const newOrder = allOrders.find(order => order.getNummer() === newOrderNumber);
      routeTo = `/reparaturauftrag/${newOrder.Guid}/edit`;
      navigateToString = `Reparaturauftrag ${newOrder.getNummer() + ' ' + newOrder.Betreff} öffnen`;
    }
    return { routeTo, navigateToString };
  }

  /**@description Pusht ungepushte Dinge und bezieht die Einstllungen und Rechte neu */
  async checkForUnsyncedData(userInfo: Userinfo): Promise<Right> {
    await this.syncService.pushAllUnpushedData(userInfo, false, false);
    const response = await this.rechteService.getEinstellungenUndRechteFromHandwerk(userInfo, false, true);
    return response;
  }

  async restartServiceOnPageRefresh(): Promise<void> {
    const signedIn = await this.monteurService.userSignedIn();
    if (!signedIn) return;

    const userInfo = await this.globalSettingService.getUserinfo();
    const intervallMinutes = await this.getCurrentSyncIntervall();
    this.stopBackgroundSyncIntervall();
    this.startBackgroundSyncIntervall(userInfo, intervallMinutes);
  }

  async getCurrentSyncIntervall(): Promise<number> {
    const appOnlySettings = await this.globalSettingService.getAppOnlySettings();
    const intervallMinutes = appOnlySettings.backgroundSyncInterval;
    return intervallMinutes;
  }
}
