import { Bauelement, Punkt, Raumbuch, RVorlage } from 'apps/handwerkPWA/src/app/entities';
import { RaumbPos } from 'apps/handwerkPWA/src/app/entities/models/aufmass/RaumbPos';
import { ValueNamePair } from 'apps/handwerkPWA/src/app/entities/models/aufmass/ValueNamePair';
import { getAllElementsBetween } from './globalHelper';

export type RoomEntityName = 'Etage' | 'Raum oder Wohnung' | 'Raum';

const massKetteIcon = 'assets/icons/Check.PNG';

const noMassketteIcon = 'assets/icons/existiertNicht.png';

/**@description Vergleichsfunktion für die Positionen, sortiert nach Nummer */
function compareRoomEntity(a: RaumbPos, b: RaumbPos): number {
  let aNumber = a.Raumb_ID;
  if (a.isWohnung()) aNumber = a.Wng_ID;
  if (a.isEtage()) aNumber = a.Stw_ID;

  let bNumber = b.Raumb_ID;
  if (b.isWohnung()) bNumber = b.Wng_ID;
  if (b.isEtage()) bNumber = b.Stw_ID;

  if (aNumber > bNumber) return 1;
  if (aNumber < bNumber) return -1;
  return 0;
}

/**
 * @description
 *  Guckt welches Icon gesetzt ist und rekursiv für die unterpositionen und setzt so das eigene icon (bewusst nicht) gucken
 * ob der knoten eine masskette hat,sonst werden weitere ebenen nicht erreicht */
function getCheckIcon(position: RaumbPos, unterPosition: RaumbPos[]): string {
  if (position.icon === massKetteIcon) return massKetteIcon;
  else if (unterPosition.length > 0 && unterPosition.every(pos => pos.icon === massKetteIcon)) return massKetteIcon;
  return noMassketteIcon;
}

/**@param rVorlage Heißt rVorlage im Aufmass, aber Haustyp in der RVorlage Entity */
export function getRaumbuchvorlage(vorlagen: RVorlage[], rVorlage: number): RVorlage {
  return vorlagen.find(vorlage => vorlage.Haustyp === rVorlage);
}

export function getRaeume(raumbuchPositionen: RaumbPos[]): RaumbPos[] {
  const positionen = raumbuchPositionen.slice();
  return positionen?.filter(raumbuchPosition => raumbuchPosition.isRaum());
}

export function getWohnungen(raumbuchPositionen: RaumbPos[]): RaumbPos[] {
  const positionen = raumbuchPositionen.slice();
  return positionen?.filter(raumbuchPosition => raumbuchPosition.isWohnung());
}

export function getEtagen(raumbuchPositionen: RaumbPos[]): RaumbPos[] {
  const positionen = raumbuchPositionen.slice();
  return positionen?.filter(raumbuchPosition => raumbuchPosition.isEtage());
}

/**@description Weißt einem Knoten die ParentId zu */
function assignParentId(raumbuchPositionen: RaumbPos[], parent: RaumbPos) {
  for (const position of raumbuchPositionen) position.parentId = parent.Uuid;
}

/**@description Weißt mit allen Knoten jeder ursprünglichen Position die parentid zu */
export function assignParentIds(raumbuchPositionen: RaumbPos[], treeNodes: RaumbPos[]): void {
  const treenodesPlain: RaumbPos[] = [];
  const etagen = treeNodes.slice();
  const wohnungen = etagen.flatMap(etage => etage.items as RaumbPos[]);
  const raeume = wohnungen.flatMap(etage => etage.items as RaumbPos[]);
  treenodesPlain.push(...etagen);
  treenodesPlain.push(...wohnungen);
  treenodesPlain.push(...raeume);
  for (const raumbuchPosition of raumbuchPositionen) {
    const positionInTreenodes = treenodesPlain.find(node => node.Uuid === raumbuchPosition.Uuid);
    raumbuchPosition.parentId = positionInTreenodes?.parentId;
  }
}

/**
 * @description Holt sich alle Etagen,Wohnungen und Räume - weißt anschließend den Wohnungen die Räume anhand der WohnungsID und StockwerkId zu
 * und anschließend werden die Wohnungen der Etage zugewiesen,anhand der Stockwerk id
 */
export function buildTreeNodes(raumbuchPositionen: RaumbPos[]): RaumbPos[] {
  for (const position of raumbuchPositionen) {
    position.selected = false;
    position.expanded = false;
    position.level = 1;
    position.icon = position.hasMasskette ? massKetteIcon : noMassketteIcon;
  }

  const etagen = getEtagen(raumbuchPositionen);
  const wohnungen = getWohnungen(raumbuchPositionen);
  const raeume = getRaeume(raumbuchPositionen);

  for (const wohnung of wohnungen) {
    wohnung.level = 2;
    const raeumeOfWohnung = raeume
      .filter(raum => raum.Wng_ID === wohnung.Wng_ID && raum.Stw_ID === wohnung.Stw_ID)
      ?.sort(compareRoomEntity);
    wohnung.icon = getCheckIcon(wohnung, raeumeOfWohnung);
    assignParentId(raeumeOfWohnung, wohnung);
    wohnung.items.push(...raeumeOfWohnung);
  }

  for (const etage of etagen) {
    etage.level = 3;
    const wohnungenOfEtage = wohnungen.filter(wohnung => wohnung.Stw_ID === etage.Stw_ID)?.sort(compareRoomEntity);
    etage.icon = getCheckIcon(etage, wohnungenOfEtage);
    assignParentId(wohnungenOfEtage, etage);
    etage.items.push(...wohnungenOfEtage);
  }

  return etagen.sort(compareRoomEntity);
}

/**@description Guckt ob ein Punkt nahe an einem anderen ist und interpretiert diesen dann darauf, anonsten wird der gedrückte punkt zurückgegeben */
export function getExistingPointIfClose(punkt: Punkt, aufmassPunkte: Punkt[]): Punkt {
  const xKoordinatePunkt = punkt.xKoordinate;
  const yKoordinatePunkt = punkt.yKoordinate;
  const tolerance = 20;
  for (const aufmassPunkt of aufmassPunkte) {
    const xKoordinate = aufmassPunkt.xKoordinate;
    const yKoordinate = aufmassPunkt.yKoordinate;
    const nearX = xKoordinate - tolerance < xKoordinatePunkt && xKoordinatePunkt < xKoordinate + tolerance;
    const nearY = yKoordinate - tolerance < yKoordinatePunkt && yKoordinatePunkt < yKoordinate + tolerance;
    if (nearX && nearY) return aufmassPunkt;
  }
  return punkt;
}

/**@description Berechnet Bruto  (Summe aller Flächen) und Netto(Summe aller Flächen - Abzugsflächen) */
export function calculateBrutoNettoSums(
  entries: Raumbuch[]
): {
  bruto: number;
  netto: number;
  difference: number;
  brutoWalls: number;
  nettoWalls: number;
  differenceWalls: number;
} {
  const calcEntries = entries.filter(entry => !entry.NotCalc);
  const wandFlaechen = calcEntries
    .filter(entry => entry.IsAbzug === false && !(entry.isBoden || entry.isDecke) && !entry.NotCalc)
    .flatMap(buchEntry => buchEntry.Zresult || 0);
  const wandFlaechenSums = wandFlaechen.reduce((a, b) => a + b, 0);
  const wandFlaechenAbzug = calcEntries
    .filter(entry => entry.IsAbzug === true && !(entry.isBoden || entry.isDecke))
    .flatMap(buchEntry => buchEntry.Zresult || 0);
  const wandFlaechenAbzugSums = wandFlaechenAbzug.reduce((a, b) => a + b, 0);
  const brutoFleaechen = calcEntries
    .filter(entry => entry.IsAbzug === false)
    .flatMap(buchEntry => buchEntry.Zresult || 0);
  const brutoSums = brutoFleaechen.reduce((a, b) => a + b, 0);
  const abzugFlaechen = calcEntries
    .filter(entry => entry.IsAbzug === true)
    .flatMap(buchEntry => buchEntry.Zresult || 0);
  const abzugSums = abzugFlaechen.reduce((a, b) => a + b, 0);
  return {
    bruto: brutoSums,
    netto: brutoSums - abzugSums,
    difference: abzugSums,
    brutoWalls: wandFlaechenSums,
    nettoWalls: wandFlaechenSums - wandFlaechenAbzugSums,
    differenceWalls: wandFlaechenAbzugSums,
  };
}

export function createFormelFromAlternatingFormula(element: Bauelement, alternatingFormula: string): string {
  const valueNames = getAllElementsBetween(alternatingFormula, '[', ']');
  const valueNamePairs = valueNames.map(name => new ValueNamePair(name));
  for (const pair of valueNamePairs) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    pair.value = element[pair.name];
  }
  let result = alternatingFormula;
  for (const pair of valueNamePairs) result = result.replaceAll(pair.name, pair.value.toString());
  return result;
}

export function convertFormelToValueNamePairs(formel: string): ValueNamePair[] {
  const valueNames = getAllElementsBetween(formel, '[', ']');
  const valueNamePairs = valueNames.map(name => new ValueNamePair(name));
  return valueNamePairs;
}

export function isInTolerance(number1: number, number2: number, tolerance: number): boolean {
  const difference = Math.abs(number1 - number2);
  const inTolerance = difference < tolerance;
  return inTolerance;
}

export function isBetween(numberToCheck: number, number1: number, number2: number): boolean {
  const between1 = number1 < numberToCheck && numberToCheck < number2;
  const between2 = number2 < numberToCheck && numberToCheck < number1;
  return between1 || between2;
}

/**@default Prüft ob eine Zahl zwischen zwei werten liegt, tut sie es nicht,wird das nähere ende zurückgegeben */
export function getEdgeIfOver(checkNumber: number, edge1: number, edge2: number): number {
  const bottomEdge = Math.min(edge1, edge2);
  const topEdge = Math.max(edge1, edge2);
  if (bottomEdge < checkNumber && checkNumber < topEdge) return checkNumber;
  if (checkNumber > topEdge) return topEdge;
  if (checkNumber < bottomEdge) return bottomEdge;
  return null;
}

export async function cutoutOfCanvas(
  url: string,
  inputXStart: number,
  inputYStart: number,
  inputXWidth: number,
  inputXHeight: number
): Promise<string> {
  return new Promise(resolve => {
    const inputImage = new Image();
    inputImage.onload = () => {
      const outputImage = document.createElement('canvas');
      outputImage.width = inputXWidth;
      outputImage.height = inputXHeight;
      const context = outputImage.getContext('2d');
      context.drawImage(
        inputImage,
        inputXStart,
        inputYStart,
        inputXWidth,
        inputXHeight,
        0,
        0,
        inputXWidth,
        inputXHeight
      );
      resolve(outputImage.toDataURL('image/jpeg'));
    };
    inputImage.src = url;
  });
}

export function getAufmasspictureBoundaries(
  aufmassPunkte: Punkt[],
  padding: number
): { boundaryX: number; width: number; boundaryY: number; height: number } {
  const xCoordinates = aufmassPunkte.map(point => point.xKoordinate);
  const yCoordinates = aufmassPunkte.map(point => point.yKoordinate);
  const xMin = Math.min(...xCoordinates);
  const xMax = Math.max(...xCoordinates);
  const xDistance = xMax - xMin;
  const yMin = Math.min(...yCoordinates);
  const yMax = Math.max(...yCoordinates);
  const yDistance = yMax - yMin;
  return {
    boundaryX: xMin - padding,
    width: xDistance + 2 * padding,
    boundaryY: yMin - padding,
    height: yDistance + 2 * padding,
  };
}
