import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(protected controllerService: ControllerService) {}

  public async getAll<Type>(classname: new (data) => Type): Promise<Type[]> {
    const rawDataArray = await this.controllerService.getData<Type[]>(classname.toString());

    return this.rawDataArrayToObjectsArray(classname, rawDataArray);
  }

  public async getAllBy<Type>(classname: new (data) => Type, selector: string, value: any): Promise<Type[]> {
    const rawDataArray = await this.controllerService.getData<Type[]>(classname.toString(), value, selector);

    return this.rawDataArrayToObjectsArray(classname, rawDataArray);
  }

  public async getAllByTwoSelector<Type>(
    classname: new (data) => Type,
    selector: string,
    value: any,
    selector2: string,
    value2: any
  ): Promise<Type[]> {
    const rawDataArray = await this.controllerService.getData<Type[]>(classname.toString(), value, selector);
    const objectsArray = this.rawDataArrayToObjectsArray(classname, rawDataArray);
    const filtered = objectsArray.filter(entry => entry[selector2] === value2);
    return filtered;
  }

  public async findOneBy<Type>(classname: new (data) => Type, selector: string, value: any): Promise<Type> {
    const rawData = await this.controllerService.getData(classname.toString(), value, selector);
    const object = new classname(rawData[0]);

    return object;
  }

  public async findOneByTwoSelector<Type>(
    classname: new (data) => Type,
    selector: string,
    value: any,
    selector2: string,
    value2: any
  ): Promise<Type> {
    const allData = await this.getAllBy(classname, selector, value);
    return allData.find(entry => entry[selector2] === value2);
  }

  public async destroy<Type>(classname: new (data) => Type, selector: string, value: any): Promise<void> {
    await this.controllerService.deleteData(classname.toString(), selector, value);
  }

  public rawDataArrayToObjectsArray<Type>(classname: new (data) => Type, rawDataArray: Type[]): Type[] {
    const objectsArray: Type[] = [];

    rawDataArray?.map(rawData => objectsArray.push(new classname(rawData)));

    return objectsArray;
  }
}
