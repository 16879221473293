import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';

export class Terminrights {
  allowCreation = true;
  allowEdit = true;
  showAll = true;

  constructor(terminRights: Terminrights) {
    assignIfPropertyExsits(this, terminRights);
  }

  allowEverything() {
    this.allowCreation = true;
    this.allowEdit = true;
    this.showAll = true;
  }

  restrictEverything() {
    this.allowCreation = false;
    this.allowEdit = false;
    this.showAll = false;
  }
}
