<div class="content-block" *ngIf="settings && rechte && viewFinished">
  <div class="form-block">
    <h2>Einstellungen</h2>

    <dx-accordion [collapsible]="true" [selectedItems]="[]">
      <dxi-item title="Allgemeines">
        <button
          *ngIf="!pwaInstalled"
          (click)="installPwa()"
          class="bss-button button-block primary-button"
          style="width: 100%; margin-bottom: 10px"
        >
          Zum Startbildschirm hinzufügen
        </button>
        <button
          *ngIf="canAskOfflineArtikel"
          (click)="getOfflinePosition('Artikel')"
          class="bss-button button-block primary-button"
          style="width: 100%; margin-bottom: 10px"
        >
          Offline-Artikel beziehen
        </button>
        <button
          *ngIf="canAskOfflineLeistung"
          (click)="getOfflinePosition('Leistung')"
          class="bss-button button-block primary-button"
          style="width: 100%; margin-bottom: 10px"
        >
          Offline-Leistungen beziehen
        </button>
        <button
          (click)="getOfflinePosition('Lohn')"
          class="bss-button button-block primary-button"
          style="width: 100%; margin-bottom: 10px"
        >
          Offline-Lohnpositionen beziehen
        </button>
        <div class="fieldbox" style="padding-top: 10px">
          <app-bss-switchbox
            [labelText]="'E-Mail (Auftragsabschluss)'"
            [disabled]="!rechte.InappChangeRights.mailFromOrder"
            [(value)]="rechte.Employeesettings.sendRepairOrderMail"
          ></app-bss-switchbox>
          <app-bss-switchbox
            [labelText]="'Preise anzeigen'"
            [disabled]="!showPricesChangeRight"
            [(value)]="rechte.Employeesettings.showPrices"
          ></app-bss-switchbox>
        </div>
        <app-bss-numberbox
          (valueChange)="restartBackgroundSyncTimer(userInfo, $event)"
          [format]="'fixedPoint'"
          [min]="0"
          [labelText]="'Hintergrundsynchronisation in Minuten (0=aus)'"
          [(value)]="appOnlySettings.backgroundSyncInterval"
        ></app-bss-numberbox>
        <div class="fieldbox" style="padding-top: 20px">
          <app-bss-switchbox
            [labelText]="'Fehlermeldungen herunterladen'"
            [(value)]="errorDialogsEnabled"
          ></app-bss-switchbox>
        </div>
      </dxi-item>
      <dxi-item title="Anzeige">
        <p class="explainText">Hier können Sie einstellen, welche Slider auf der Startseite angezeigt werden</p>
        <button
          (click)="showCheckBox = !showCheckBox"
          class="bss-button button-block primary-button"
          style="width: 100%; margin-bottom: 10px"
        >
          Slider Anzeige
        </button>
        <app-bss-check-box
          [allowNothingSelected]="false"
          [height]="'auto'"
          [checkBoxInputs]="possibleSliderSettings"
          [showPopup]="showCheckBox"
          [title]="'Slider anzeigen'"
          (confirmClicked)="confirmSliders($event)"
        ></app-bss-check-box>
        <p class="explainText">Hier können Sie die Reihenfolge von Slidern auf der Startseite bearbeiten</p>
        <dx-list
          *ngIf="sliderOrder"
          [items]="sliderOrder"
          class="multiview-reordering"
          [repaintChangesOnly]="true"
          (onItemReordered)="dragEnd()"
        >
          <dxo-item-dragging [data]="sliderOrder" [allowReordering]="true">
            <div *dxTemplate="let option of 'item'">
              {{ sliderOrder.indexOf(option) + 1 + ' ' + option }}
            </div>
          </dxo-item-dragging>
        </dx-list>
      </dxi-item>
      <dxi-item title="E-Mail Einstellungen">
        <div class="dx-fieldset">
          <div class="fieldbox">
            <app-bss-textbox
              [labelText]="'Username'"
              [(value)]="emailConnectionInfo.smtpUser"
              [maxLength]="50"
            ></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'Passwort'"
              [(value)]="emailConnectionInfo.smtpPassword"
              [maxLength]="200"
              [mode]="'password'"
            ></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'SMTP-Server'"
              [(value)]="emailConnectionInfo.smtpServer"
              [maxLength]="100"
            ></app-bss-textbox>
            <app-bss-numberbox [labelText]="'SMTP-Port'" [(value)]="emailConnectionInfo.smtpPort"> </app-bss-numberbox>
            <div class="dx-field">
              <button class="bss-button block-button primary-button" (click)="saveEmailConnectionInfo()">
                Speichern
              </button>
            </div>
            <div class="dx-field">
              <button class="bss-button block-button primary-button" (click)="getEmailConnectionInfoFromWebservice()">
                Einstellungen vom Server holen
              </button>
            </div>
          </div>
        </div>
      </dxi-item>
      <dxi-item title="E-Mail Test">
        <div class="dx-fieldset">
          <app-mailtest [userInfo]="userInfo" [settings]="settings"> </app-mailtest>
        </div>
      </dxi-item>
      <dxi-item *ngIf="hasAufmass" title="Aufmass Einstellungen">
        <div class="dx-fieldset">
          <app-bss-switchbox
            [labelText]="'Raster automatisch an'"
            [(value)]="appOnlySettings.aufmassGridOnStart"
          ></app-bss-switchbox>
          <app-bss-switchbox
            [labelText]="'In rechteckigen Räumen gegenüberliegende Seiten automatisch übernehmen'"
            [(value)]="appOnlySettings.aufmassAutoFillRectangleRooms"
          ></app-bss-switchbox>

          <app-bss-number-popup
            [min]="20"
            [max]="100"
            class="marginAuto customInputStyle"
            [ignoreLabelStyles]="true"
            [labelText]="'Rastergröße ( 20 - 100 )'"
            [title]="'Rastergröße ( 20 - 100 )'"
            [inputValue]="appOnlySettings.aufmassRasterFactor"
            [DecimalPlaces]="0"
            [DecimalPlacesInputEnabled]="false"
            [minusAllowed]="false"
            (outputValue)="appOnlySettings.aufmassRasterFactor = $event"
            [ValidateInputDecimals]="true"
          >
          </app-bss-number-popup>
        </div>
      </dxi-item>
    </dx-accordion>
    <div class="settings-foooter" (click)="manualUpdateTrigger()">
      <div class="current-user">Angemeldet als: {{ currentMonteur?.Name }}</div>
      <div class="version">App-Version: {{ version.version }}</div>
      <div class="copyRight">Copyright © {{ year }} by blue:solution Software GmbH</div>
    </div>
  </div>
</div>
