import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { Adressrights } from './Adressrights';
import { Terminrights } from './Terminrights';
import { Wartungsrights } from './Wartungsrights';

export class Employeerights {
  Adressrights: Adressrights;
  Terminrights: Terminrights;
  Wartungsrights: Wartungsrights;
  showRetailprice = true;
  showPurchasingPrice = true;
  showOffenePosten = true;
  showUmsatz = true;
  allowCashPayment = true;
  showAuftrag = true;
  createRepairOrder = true;
  showBelege = true;
  /** Zeigt den Lizenzstatus an - True = Lizenz, False = keine Lizenz */
  showObjektadressen = true;

  constructor(employeeRights: Employeerights) {
    this.Adressrights = new Adressrights(employeeRights?.Adressrights);
    this.Terminrights = new Terminrights(employeeRights?.Terminrights);
    this.Wartungsrights = new Wartungsrights(employeeRights?.Wartungsrights);
    assignIfPropertyExsits(this, employeeRights);
  }

  restrictEverything(): void {
    this.Adressrights.restrictEverything();
    this.Terminrights.restrictEverything();
    this.Wartungsrights.restrictEverything();
    this.showRetailprice = false;
    this.showPurchasingPrice = false;
    this.showOffenePosten = false;
    this.showUmsatz = false;
    this.allowCashPayment = false;
    this.showAuftrag = false;
    this.showBelege = false;
    this.showObjektadressen = false;
    this.createRepairOrder = false;
  }

  allowEverything(): void {
    this.Adressrights.allowEverything();
    this.Terminrights.allowEverything();
    this.Wartungsrights.allowEverything();
    this.showRetailprice = true;
    this.showPurchasingPrice = true;
    this.showOffenePosten = true;
    this.showUmsatz = true;
    this.allowCashPayment = true;
    this.showAuftrag = true;
    this.showBelege = true;
    this.showObjektadressen = true;
    this.createRepairOrder = true;
  }
}
