import { Injectable } from '@angular/core';
import { AddressService } from '../dataServices/address.service';
import { ObjectaddressService } from '../dataServices/objectAddress.service';
import { ContactsService } from '../dataServices/contacts.service';
import { BelegService } from '../dataServices/beleg.service';
import { OffenepostenService } from '../dataServices/offeneposten.service';
import { UmsatzService } from '../dataServices/umsatz.service';
import { AppointmentService } from '../dataServices/appointment.service';
import { MonteurService } from '../dataServices/monteur.service';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyArray } from 'libs/shared/src/lib/helper/globalHelper';
import { RepairOrderService } from '../dataServices/repairOrder.service';
import { NachrichtenService } from '../dataServices/nachrichten.service';
import { HWGolbalsettingService } from './hwgolbalsetting.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { DialogService } from '@handwerk-pwa/shared';
import { Documentservice } from './document.service';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { WartungsprojectsService } from '../dataServices/wartungsprojects.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { DataSetNames, ServiceName } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { ServiceauftraegeService } from '../dataServices/serviceauftraege.service';
import { TimeStampTableRequestNew, TimestampTable, HWFile } from 'apps/handwerkPWA/src/app/entities';
import { Right } from 'libs/shared/src/lib/entities';
import { AufmassService } from '../dataServices/aufmass.service';
import { ProjektService } from '../dataServices/projekt.service';
import { Medien } from '../../entities/repository/Medien';
import { entdeckenBasicUrl, entdeckenPremiumUrl } from 'libs/shared/src/lib/entities/models/Typedstrings';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  /**Der Boolean gibt an, ob es sich um etwas neues handelt (=true) oder ob etwas neues angeklickt wurde (=false) */
  newThingsDiscovered = new BehaviorSubject<boolean>(false);

  constructor(
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private contactsService: ContactsService,
    private wartungsprojectsService: WartungsprojectsService,
    private belegService: BelegService,
    private offenePostenService: OffenepostenService,
    private umsatzService: UmsatzService,
    private appointmentService: AppointmentService,
    private monteurService: MonteurService,
    private repairOrderService: RepairOrderService,
    private nachrichtenService: NachrichtenService,
    private rechteService: RechteService,
    private globalSettingService: HWGolbalsettingService,
    private dialogService: DialogService,
    private documentService: Documentservice,
    private authorisationService: AuthorisationService,
    private restService: RestService,
    private serviceAuftraegeService: ServiceauftraegeService,
    private aufmassService: AufmassService,
    private projectService: ProjektService
  ) {}

  /**
   * @description Holt alles, was man holen kann
   * @param loginProcess Guckt nur ob Verbindung okay wäre
   */
  async getAllDataFromWebService(userInfo: Userinfo, getItall: boolean, loginProcess: boolean): Promise<boolean> {
    const success = await this.SyncDataForAppStart(userInfo, loginProcess);
    if (!success) return false;
    if (!loginProcess) await this.pushAllUnpushedData(userInfo, true);
    const right = this.rechteService.getCurrentRight();
    const medienTabelle = this.authorisationService.current.getValue().featureCheck('Medientabelle1').available;
    if (medienTabelle) await this.documentService.getAllFromWebservice(userInfo);
    const adressen = await this.addressService.sync(userInfo);
    await this.objectAddressService.getAllObjectaddressesFromWebservice(userInfo);

    if (getItall) {
      if (right.hasRightOrSettingEnabled('showBelege')) await this.belegService.getAllBelegeFromWebservice();
      if (right.hasRightOrSettingEnabled('showOffenePosten'))
        await this.offenePostenService.getAllOffenepostenFromWebserivce(userInfo);
      if (right.hasRightOrSettingEnabled('showUmsatz')) await this.umsatzService.getUmsaetzeFromWebservice(userInfo);
      await this.contactsService.sync(userInfo);
    }
    const termine = await this.appointmentService.syncAll(userInfo);
    await this.appointmentService.getSerienTermineFromWebserviceByRange(userInfo, new Date(), 6);

    // Endpunkte die nur mit Lizenz Sinn machen
    if (this.rechteService.hasRightOrSettingEnabled('showObjektadressen')) {
      const datenblaetter = await this.wartungsprojectsService.getDatenblaetterFromWebservice(userInfo);
      const anlagen = await this.wartungsprojectsService.getAnlagenFromWebservice(userInfo, adressen, datenblaetter);
      const monteure = adressen.filter(adresse => adresse.ADRTYP === 'M');
      const authorisation = this.authorisationService.current.getValue();
      await this.repairOrderService.getAllReparaturauftraegeFromWebservice(userInfo, adressen);
      await this.nachrichtenService.getAllNachrichtenFromWebservice(userInfo);
      if (authorisation.featureCheck('Wartung und Service').available)
        await this.serviceAuftraegeService.getAllServiceauftraegeFromWebservice(userInfo, monteure, anlagen, termine);
      if (authorisation.featureCheck('Aufmass').available) {
        await this.aufmassService.getGrunddatenFromWebservice(userInfo);
        await this.projectService.sync(userInfo);
      }
    }

    await this.checkForChanges(userInfo, right, false);
    await this.addressService.resetImagesInIDB();
    await this.objectAddressService.resetImagesInIDB();
    this.dialogService.closeLoadingDialog();
    return true;
  }

  async SyncDataForAppStart(userInfo: Userinfo, loginProcess: boolean): Promise<boolean> {
    const checkLogin = await this.authorisationService.checkLogin(userInfo);
    if (!checkLogin?.isOkay) return false;
    await this.monteurService.getMonteurFromWebserivce(userInfo);
    await this.rechteService.getEinstellungenUndRechteFromHandwerk(userInfo, true, !loginProcess);
    return true;
  }

  /**
   * @description Neue Methode, prüft ob die Timestamps der Tables sich unterscheiden(übertragen wird immer der aktuellste timestamp der jeweiligen
   * tabelle)
   * @returns Array von Datensätzen in denen es Änderungen gab*/
  async checkForChanges(
    userInfo: Userinfo,
    recht: Right,
    silent = true
  ): Promise<{ dataSetNames: DataSetNames[]; ordersAlreadyInApp: string[] }> {
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...prüfe auf neue/geänderte Daten...');
    const allRepairOrders = await this.repairOrderService.getAllRepairOrdersFromIDB(true);
    const repairOrderNumbersAlreadyInApp = allRepairOrders?.map(order => order.Nummer);
    const alleTermine = recht.Employeerights.Terminrights.showAll;
    const timeStampTableRequest = new TimeStampTableRequestNew(
      userInfo.mandant,
      userInfo.monteur,
      repairOrderNumbersAlreadyInApp,
      alleTermine
    );
    const tableResponse = await this.restService.returnData<TimestampTable>(
      userInfo,
      'GetGeaendertTableNew',
      timeStampTableRequest,
      true
    );
    const timeStampTable = new TimestampTable(tableResponse);
    const lastTimestampTable: TimestampTable = await this.globalSettingService.getEntity('TimestampTable');
    await this.globalSettingService.setEntity(timeStampTable, 'TimestampTable');
    if (!silent) void this.dialogService.closeLoadingDialog();
    if (isNullOrUndefined(lastTimestampTable)) return { dataSetNames: [], ordersAlreadyInApp: [] };
    const licence = recht.Employeerights.showObjektadressen;
    const changesIn = timeStampTable.checkForUpdates(lastTimestampTable, licence);
    return { dataSetNames: changesIn, ordersAlreadyInApp: repairOrderNumbersAlreadyInApp };
  }

  /**@description Schickt alle Daten an den Webservice, die bisher noch nicht übertragen wurden */
  async pushAllUnpushedData(userInfo: Userinfo, bypassCheck?: boolean, showDialog = true): Promise<void> {
    if (isNullOrUndefined(bypassCheck)) {
      const checkLogin = await this.authorisationService.checkLogin(userInfo, !showDialog);
      if (!checkLogin?.isOkay) return;
    }
    if (showDialog)
      void this.dialogService.openLoadingDialog(
        'Synchronisation',
        '...sende alle nicht synchronisierten Daten an den Webservice...'
      );
    const allAdresses = await this.addressService.getAll();
    const objectAdresses = await this.objectAddressService.getAll();
    await this.objectAddressService.pushUnpushedObjektadressen(userInfo);
    await this.appointmentService.pushUnpushedTermine(userInfo);
    await this.repairOrderService.pushUnpushedRepairOrders(userInfo);
    await this.nachrichtenService.pushUnpushedNachrichten(userInfo);
    await this.wartungsprojectsService.pushAllUnpushedMessungen(userInfo);
    await this.serviceAuftraegeService.pushUnpushedOrders(userInfo);
    const medienTabelle = this.authorisationService.current.getValue().featureCheck('Medientabelle1').available;
    const unpushedDocuments = medienTabelle
      ? await this.documentService.getUnpushedFiles()
      : this.documentService.getUnpushedFilesOld(allAdresses, objectAdresses);
    const allImagesPushed = isNullOrUndefinedOrEmptyArray(unpushedDocuments) ? true : false;
    if (this.globalSettingService.askForFileSync && !allImagesPushed)
      return await this.pushUnpushedFiles(userInfo, unpushedDocuments, medienTabelle);
    this.dialogService.closeLoadingDialog();
  }

  async pushUnpushedFiles(
    userInfo: Userinfo,
    unpushedDocuments: HWFile[] | Medien[],
    medienTabelle: boolean
  ): Promise<void> {
    this.dialogService.closeLoadingDialog();
    const syncData = await this.dialogService.openConfirmDialog(
      'Nicht synchronisierte Dateien!',
      'Sie haben Dateien hinzugefügt, diese aber noch nicht synchronisiert. Möchten Sie diese nun synchronisieren?',
      'Synchronisieren',
      'Später',
      false
    );
    if (syncData) {
      const pushedFiles = await this.documentService.sendUnpushedImagesToWebservice(userInfo, unpushedDocuments);
      if (!medienTabelle) {
        await this.addressService.updateDocumentsInAdressLocally(pushedFiles as HWFile[]);
        await this.objectAddressService.updateDocumentsInAdressLocally(pushedFiles as HWFile[]);
      }
      this.globalSettingService.askForFileSync = pushedFiles?.length !== unpushedDocuments?.length;
      return;
    }
    this.dialogService.closeLoadingDialog();
    const notAsk = await this.dialogService.openConfirmDialog(
      'System',
      'Möchten Sie die Synchronisationsanfrage temporär bis zu einem Appneustart ausblenden?',
      'Ausblenden',
      'Beibehalten',
      false
    );
    if (notAsk) {
      this.globalSettingService.askForFileSync = false;
      return;
    }
    this.globalSettingService.askForFileSync = true;
  }

  /**@description Synchronisiert nur die Daten zum jeweils angegebenen Servicenamen */
  async getSpecificDataFromWebService(userInfo: Userinfo, service: ServiceName, silent: boolean): Promise<void> {
    const checkLogin = await this.authorisationService.checkLogin(userInfo, silent);
    if (!checkLogin?.isOkay) return;
    await this.rechteService.getEinstellungenUndRechteFromHandwerk(userInfo, !silent, true);
    const right = this.rechteService.getCurrentRight();
    const licence = right.Employeerights.showObjektadressen;
    const medienTabelle = this.authorisationService.current.getValue().featureCheck('Medientabelle1').available;
    switch (service) {
      case 'nachrichtenservice': {
        if (licence) await this.nachrichtenService.getAllNachrichtenFromWebservice(userInfo, !silent);
        break;
      }
      case 'belegservice': {
        await this.belegService.getAllBelegeFromWebservice(silent);
        break;
      }
      case 'terminservice':
      case 'auftragsservice': {
        const adresses = await this.addressService.sync(userInfo, !silent);
        const termine = await this.appointmentService.syncAll(userInfo, !silent);
        await this.appointmentService.getSerienTermineFromWebserviceByRange(userInfo, new Date(), 6, silent);
        const datenblaetter = await this.wartungsprojectsService.getDatenblaetterFromWebservice(userInfo, !silent);
        const anlagen = await this.wartungsprojectsService.getAnlagenFromWebservice(
          userInfo,
          adresses,
          datenblaetter,
          !silent
        );
        if (medienTabelle) await this.documentService.getAllFromWebservice(userInfo);
        const monteure = adresses.filter(adresse => adresse.ADRTYP === 'M');
        if (licence) {
          await this.repairOrderService.getAllReparaturauftraegeFromWebservice(userInfo, adresses, !silent);
          await this.serviceAuftraegeService.getAllServiceauftraegeFromWebservice(
            userInfo,
            monteure,
            anlagen,
            termine,
            !silent
          );
        }
        break;
      }
      case 'adressservice': {
        await this.addressService.sync(userInfo, !silent);
        await this.objectAddressService.getAllObjectaddressesFromWebservice(userInfo, !silent);
        break;
      }
      case 'einstellungen': {
        await this.rechteService.getEinstellungenUndRechteFromHandwerk(userInfo, !silent, true);
        await this.monteurService.getMonteurFromWebserivce(userInfo, silent);
        break;
      }
      case 'wartungsservice': {
        const adressen = await this.addressService.sync(userInfo, !silent);
        const termine = await this.appointmentService.syncAll(userInfo, !silent);
        const datenblaetter = await this.wartungsprojectsService.getDatenblaetterFromWebservice(userInfo, !silent);
        const anlagen = await this.wartungsprojectsService.getAnlagenFromWebservice(
          userInfo,
          adressen,
          datenblaetter,
          !silent
        );
        const monteure = adressen.filter(adresse => adresse.ADRTYP === 'M');
        if (this.authorisationService.current.getValue().featureCheck('Wartung und Service').available && licence)
          await this.serviceAuftraegeService.getAllServiceauftraegeFromWebservice(
            userInfo,
            monteure,
            anlagen,
            termine,
            !silent
          );
        if (medienTabelle) await this.documentService.getAllFromWebservice(userInfo);
        break;
      }
      case 'aufmassservice': {
        if (!licence) break;
        await this.addressService.sync(userInfo, !silent);
        await this.projectService.sync(userInfo, !silent);
        await this.aufmassService.getGrunddatenFromWebservice(userInfo, !silent);
        if (medienTabelle) await this.documentService.getAllFromWebservice(userInfo);
        break;
      }
    }
  }

  /**
   * @description Prüft ob neue Daten für den "Entdecken" Bereich bzw den Premium entdecken Bereich existieren
   * - prüft dann ob neue Daten für den Bereich,den man hat existieren,ist dies der Fall,leitet die Info weiter an das Entdecken-Flag
   */
  async getNewThingsToDiscover(): Promise<void> {
    const lastTimestampBase: Date = await this.globalSettingService.getEntity('entdeckenLastModifiedBase');
    const lastTimestampPremium: Date = await this.globalSettingService.getEntity('entdeckenLastModifiedPremium');
    const currentTimeStampBase = await this.restService.httpLastModified(entdeckenBasicUrl);
    const currentTimeStampPremium = await this.restService.httpLastModified(entdeckenPremiumUrl);
    let newEntdeckenContentBase = false;
    if (!isNullOrUndefined(lastTimestampBase)) {
      newEntdeckenContentBase = currentTimeStampBase > lastTimestampBase;
    }
    let newEntdeckenContentPremium = false;
    if (!isNullOrUndefined(lastTimestampPremium)) {
      newEntdeckenContentPremium = currentTimeStampPremium > lastTimestampPremium;
    }
    const newThingsBase = isNullOrUndefined(lastTimestampBase) || newEntdeckenContentBase;
    if (newThingsBase) await this.globalSettingService.setEntity(currentTimeStampBase, 'entdeckenLastModifiedBase');

    const newThingsPremium = isNullOrUndefined(lastTimestampPremium) || newEntdeckenContentPremium;
    if (newThingsPremium)
      await this.globalSettingService.setEntity(currentTimeStampPremium, 'entdeckenLastModifiedPremium');

    const isPremium = this.rechteService.hasRightOrSettingEnabled('showObjektadressen');
    if ((isPremium && newThingsPremium) || (!isPremium && newThingsBase)) this.newThingsDiscovered.next(true);
  }
}
