import { Component, OnInit, ViewChild } from '@angular/core';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { DxListComponent } from 'devextreme-angular';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BaseAuftrag, BaseDocumentPosition } from 'apps/handwerkPWA/src/app/interfaces';
import { RepairOrderService } from '../../../services/dataServices/repairOrder.service';
import { ServiceauftraegeService } from '../../../services/dataServices/serviceauftraege.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { HWRepairOrder, ServiceAuftrag } from '../../../entities';
import { DragEvent } from '../../../interfaces/DragEvent';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';

@Component({
  selector: 'app-order-items-index',
  templateUrl: './order-items-index.component.html',
  styleUrls: ['./order-items-index.component.scss'],
})
export class OrderItemsIndexComponent implements OnInit {
  searchExpressions = ['Bezeichnung', 'AuftrNR', 'Nummer', 'MengenEinheit', 'Menge', 'VK'];
  finalizeAble = false;
  sortedItems: BaseDocumentPosition[];
  @ViewChild(DxListComponent, { static: true }) itemsList: DxListComponent;
  order: BaseAuftrag;
  userInfo: Userinfo;
  showPrices: boolean;
  orderType: string;
  orderId: string;

  constructor(
    private globalSettingservice: HWGolbalsettingService,
    private routingService: RoutingService,
    private repairOrderService: RepairOrderService,
    private serviceOrderService: ServiceauftraegeService,
    private rightService: RechteService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.globalSettingservice.getUserinfo();
    await this.loadOrder();
    const showPricesRight = this.rightService.hasRightOrSettingEnabled('showPrices');
    this.showPrices = this.order.getShowPrices(showPricesRight);
    this.sortedItems = this.order.getDisplayPositions();
  }

  async loadOrder(): Promise<void> {
    this.orderType = this.routingService.getRouteParam('type');
    this.orderId = this.routingService.getRouteParam('id');

    if (this.orderType === 'reparaturauftrag') {
      this.order = await this.repairOrderService.findOneByGuid(this.orderId);
    } else if (this.orderType === 'wartungsauftrag') {
      this.order = await this.serviceOrderService.findOneBy('UUID', this.orderId);
    }
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  reorderList(dragEvent: DragEvent): void {
    this.order.reorderPositions(dragEvent);
    this.sortedItems = this.order.getDisplayPositions();
    if (this.order instanceof HWRepairOrder)
      void this.repairOrderService.sendRepairOrderToWebservice(this.userInfo, this.order, false);
    if (this.order instanceof ServiceAuftrag)
      void this.serviceOrderService.sendServiceorderToWebservice(this.userInfo, this.order, false);
  }
}
