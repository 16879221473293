<dx-tab-panel
  [dataSource]="messages"
  [selectedIndex]="0"
  [loop]="false"
  [animationEnabled]="true"
  [swipeEnabled]="true"
>
  <div *dxTemplate="let message of 'title'">
    <div class="flexContainer">
      <i [className]="'dx-icon-' + message.icon + ' dx-icon'"></i>
      <div class="flexContainerItem title-text" style="padding-left: 7px">
        {{ message.messagetype }}
      </div>
    </div>
  </div>
  <div *dxTemplate="let message of 'item'">
    <div class="content-block">
      <app-bss-list
        [id]="message.list_id"
        [inputDatasource]="message.items"
        [searchExpressions]="searchExpressions"
        [userInfo]="userInfo"
        [emptyInputDataMessage]="'Es sind keine Nachrichten vorhanden'"
      ></app-bss-list>
    </div>
  </div>
</dx-tab-panel>

<dx-speed-dial-action icon="add" [index]="1" label="Neue Nachricht" (onClick)="newMessage()"></dx-speed-dial-action>
