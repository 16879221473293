import { Component, OnInit } from '@angular/core';
import { HWAddress, HWAnlage, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { ServiceauftraegeService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceauftraege.service';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { DialogService } from '@handwerk-pwa/shared';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import {
  beginImpossible,
  beginImpossibleReason,
  editImpossible,
  noEditServiceoderReason as noEditServiceOrderReason,
} from 'apps/handwerkPWA/src/app/config/TextKonstanten';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { Documentservice } from 'apps/handwerkPWA/src/app/services/globalServices/document.service';
import { calculateDataAmount } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-service-order-edit',
  templateUrl: './service-order-edit.component.html',
  styleUrls: ['./service-order-edit.component.scss'],
})
export class ServiceOrderEditComponent implements OnInit {
  serviceOrder: ServiceAuftrag;
  anlage: HWAnlage;
  positionCount = 0;
  showPreview = true;
  userInfo: Userinfo;
  selectableEmployees: HWAddress[];
  fileUploadPossible: boolean;
  currentFilesAmount: string;
  currentPositionAmount: string;
  customer: HWAddress;
  allEmployees: HWAddress[];
  selectedEmployees: HWAddress[];

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private maintenanceService: WartungsprojectsService,
    private serviceAuftragsService: ServiceauftraegeService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private addressService: AddressService,
    private authorisationService: AuthorisationService,
    private documentService: Documentservice
  ) {}

  async ngOnInit(): Promise<void> {
    this.fileUploadPossible = this.authorisationService.current.getValue().featureCheck('Medientabelle1').available;
    const orderId = this.routingService.getRouteParam('uuid');
    this.serviceOrder = await this.serviceAuftragsService.findOneBy('UUID', orderId);
    this.selectedEmployees = this.serviceOrder.getZusatzMonteure();

    this.userInfo = await this.globalSettingService.getUserinfo();

    this.anlage = await this.maintenanceService.findOneAnlageBy('ANLAGE', this.serviceOrder.Anlage);
    const workSpecification = this.anlage?.ABESCHR;
    this.positionCount = this.serviceOrder
      .getPositionen()
      ?.filter(position => position.isRechnungsposten(false, workSpecification))?.length;

    const assignedEmployees = this.serviceOrder.getAllMonteureInOrder();
    const assignedEmployeeNumbers = assignedEmployees?.flatMap(employee => employee.KU_NR);

    await this.assignSelectableEmployees(assignedEmployeeNumbers);

    if (assignedEmployeeNumbers?.length === 0)
      await this.tryAcceptingOrder(this.serviceOrder, this.userInfo, this.selectableEmployees);
    await this.getFileAmount(this.anlage);
    this.currentPositionAmount = calculateDataAmount(
      this.serviceOrder.getPositionen()?.filter(position => position.isRechnungsposten(false, workSpecification))
    );
    this.customer = this.anlage.Anlagendaten.Kundenobjekt;
  }
  async ngOnDestroy(): Promise<void> {
    if (this.serviceOrder.edited)
      this.serviceOrder = await this.serviceAuftragsService.sendServiceorderToWebservice(
        this.userInfo,
        this.serviceOrder,
        false
      );
  }

  /**@description Takes the customer numbers of already assigned Employees and sets the selectable additional Employees */
  private async assignSelectableEmployees(assignedEmployeeNumbers: string[]) {
    // Get all Employees
    this.allEmployees = await this.addressService.getAllBy('ADRTYP', 'M');
    // Filter out terminated Employees
    this.allEmployees = this.allEmployees.filter(
      employee => employee.LeaveDate == null || employee.LeaveDate >= new Date()
    );
    // Filter out already assigned Employees
    this.selectableEmployees = this.allEmployees.filter(employee => !assignedEmployeeNumbers.includes(employee.KU_NR));
  }

  private async getFileAmount(anlage: HWAnlage) {
    const files = await this.documentService.getAllBy('Baseuuid', anlage.UUID);
    this.currentFilesAmount = calculateDataAmount(files);
  }

  async tryAcceptingOrder(serviceAuftrag: ServiceAuftrag, userInfo: Userinfo, employees: HWAddress[]): Promise<void> {
    const wantToAccept = await this.dialogService.openConfirmDialog(
      'Serviceauftrag',
      'Dieser Serviceauftrag ist Ihnen noch nicht zugeordnet. Möchten Sie den Serviceauftrag übernehmen?',
      'Übernehmen',
      'Nicht übernehmen',
      false
    );

    if (!wantToAccept) {
      this.routingService.routeBack();
      return;
    }

    const returnedOrder = await this.serviceAuftragsService.acceptServiceOrder(serviceAuftrag, employees, userInfo);
    if (returnedOrder) {
      this.routingService.reload();
      return;
    }

    // accepted and returned a value
    const errorMessage = 'Der Serviceauftrag wurde bereits einem Monteur zugeordnet.';
    await this.dialogService.openErrorMessage('Serviceauftrag', errorMessage);
    void this.routingService.routeBack();
  }

  updateEmployeeList(currentEmployees: HWAddress[]): void {
    this.serviceOrder.updateAdditionalEmployeeList(currentEmployees);
    this.serviceOrder.edited = true;
    this.routingService.dataChanged.next(false);
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  /**@description Prüft erst ob Editierungen vorgenommen werden dürfen bevor es weiterleitet */
  async editOrder(
    order: ServiceAuftrag,
    userInfo: Userinfo,
    action: 'auftragpositionen' | 'auftragsabschluss' | 'beginnen'
  ): Promise<void> {
    if (!order.isEditable(userInfo) && action !== 'beginnen') {
      void this.dialogService.openErrorMessage(editImpossible, noEditServiceOrderReason);
      return;
    }
    const beginFromNotMainMonteur = action === 'beginnen' && order.isUserMainMonteur(userInfo) === false;
    if (beginFromNotMainMonteur) {
      void this.dialogService.openErrorMessage(beginImpossible, beginImpossibleReason);
      return;
    }

    if (action === 'beginnen') {
      await this.tryBegin();
      return;
    }
    const basePath = `${action}/wartungsauftrag/${order.UUID}`;
    const fullPath = action === 'auftragpositionen' ? basePath + '/hinzufuegen' : basePath;
    void this.navigateTo(fullPath);
  }

  /**
   * @description Versucht den Auftrag anzunehmen. Im Erfolgsfall(Nutzer hat bestätigt, Auftrag kommt erfolgreich angenommen zurück) wird die Seite neu geladen
   * ansonsten zurück geroutet
   */
  private async tryBegin() {
    const confirmation = await this.dialogService.openConfirmDialog(
      'Auftragsbeginn',
      'Auftrag jetzt beginnen?',
      'Ok',
      'Abbruch',
      false
    );
    if (!confirmation) return;
    this.serviceOrder.setAuftragsstatus('Begin');
    this.serviceOrder = await this.serviceAuftragsService.sendServiceorderToWebservice(
      this.userInfo,
      this.serviceOrder,
      false
    );
    const success = !this.serviceOrder.edited;
    if (success) {
      this.routingService.reload();
    } else {
      await this.dialogService.openErrorMessage(
        'Serviceauftrag',
        'Der Serviceauftrag wurde nicht erfolgreich begonnen.'
      );
      this.routingService.routeBack();
    }
  }

  public async reloadWithSaving(): Promise<void> {
    this.serviceOrder = await this.serviceAuftragsService.sendServiceorderToWebservice(
      this.userInfo,
      this.serviceOrder,
      true
    );
    this.routingService.reload();
  }
}
