import { Component, OnInit, Input } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-bss-button-link',
  templateUrl: './bss-button-link.component.html',
  styleUrls: ['./bss-button-link.component.scss'],
})
export class BssButtonLinkComponent implements OnInit {
  @Input() title: string;
  @Input() path: string;
  @Input() anzahl;
  @Input() customIcon: string;
  @Input() customIconClass;
  @Input() secondCustomIcon: string;
  @Input() secondCustomIconClass;
  @Input() showHasContent = false;
  defaultIcon = 'assets/icons/dreieckRechts.svg';
  infoIconSource = 'assets/icons/info.png';

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {}

  async clickAction(): Promise<void> {
    if (this.path) {
      await this.routingService.navigateTo(this.path);
    }
  }
}
