import { UUID } from 'angular2-uuid';
import { assignIfPropertyExsits, removeFromArray } from 'libs/shared/src/lib/helper/globalHelper';
import moment from 'moment';
import { AufmassKonstrukt, Raumbuch, RVorlage } from '..';
import { deDatettimeFormatWithSeconds } from '../../config/Konstanten';
import { UuidEntity } from '../../interfaces';
import { AufmassDrawStack } from '../models/aufmass/AufmassDrawStack';
import { AufmassDrawStackelement } from '../models/aufmass/AufmassDrawStackelement';
import { RaumbPos } from '../models/aufmass/RaumbPos';
import { IndexedDBTypes } from './dbType';

export class Aufmass extends IndexedDBTypes.DbType implements UuidEntity {
  @IndexedDBTypes.KlassenName('Aufmass') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') Uuid: string = null;
  @IndexedDBTypes.IndexField('string') AufmId: string = null;
  @IndexedDBTypes.DataField('string') Such: string = null;
  @IndexedDBTypes.DataField('string') AufName: string = null;
  @IndexedDBTypes.DataField('string') Variant: string = null;
  @IndexedDBTypes.DataField('string') Type: string = null;
  @IndexedDBTypes.DataField('string') Beschr: string = null;
  @IndexedDBTypes.DataField('string') ProjName: string = null;
  @IndexedDBTypes.DataField('string') Kunde: string = null;
  @IndexedDBTypes.DataField('string') RBezeich: string = null;
  @IndexedDBTypes.DataField('string') Datum: string = null;
  @IndexedDBTypes.DataField('number') AppStatus: number = null;
  @IndexedDBTypes.DataField('string') Betreff: string = null;
  @IndexedDBTypes.DataField('string') private RaumbuchPositionen: RaumbPos[] = null;
  /**Eintrag "HAUSTYP" in der Tabelle RVorlage - konkrete Vorlage ist dann mit Haustyp = Rvorlage und Etage,Wohnung,Raum = 0 */
  @IndexedDBTypes.DataField('number') RVorlage: number = null;
  /**@description Nächste Line Id - wird durch jede neue Raumbuchposition erhöht */
  @IndexedDBTypes.DataField('number') NPosLineId = 0;
  @IndexedDBTypes.DataField('number') TSTELLEN = 1;
  @IndexedDBTypes.DataField('number') TSCHRITT = 1;
  @IndexedDBTypes.DataField('number') PSTELLEN = 1;
  @IndexedDBTypes.DataField('number') PSCHRITT = 1;
  @IndexedDBTypes.DataField('number') ANZZEILEN = 0;
  // PWAONLY:
  @IndexedDBTypes.DataField('number') drawStack: AufmassDrawStack[] = [];
  @IndexedDBTypes.DataField('number') usedGridFactor: number = null;
  @IndexedDBTypes.DataField('boolean') fullyTransferred: boolean = null;
  @IndexedDBTypes.DataField('string') RAUMBUCH: Raumbuch[] = null;

  constructor(Aufmass: Aufmass) {
    super();
    assignIfPropertyExsits(this, Aufmass);
  }

  static toString(): string {
    return 'Aufmass';
  }

  getRaumbuchpositionen(): RaumbPos[] {
    return this.RaumbuchPositionen?.map(position => new RaumbPos(position));
  }

  getRaumbuch(): Raumbuch[] {
    return this.RAUMBUCH?.map(rb => new Raumbuch(rb));
  }

  addRaumToRaumbuchpositionen(raumBuchPosition: RaumbPos): void {
    raumBuchPosition.deleteNodeInformation();
    raumBuchPosition.assignNextRposid(this.NPosLineId);
    this.NPosLineId++;
    this.RaumbuchPositionen?.push(raumBuchPosition);
  }

  /**
   * @description Entfernt die Position und alle Unterpositionen aus dem Aufmass
   * @return Ein Array aller entfernten Knoten
   */
  removeRaumFromRaumbuchpositionen(rootDeleteNode: RaumbPos): RaumbPos[] {
    if (rootDeleteNode.isEtage()) {
      const allEntitysInEtage = this.RaumbuchPositionen.filter(pos => pos.Stw_ID === rootDeleteNode.Stw_ID);
      for (const etageMember of allEntitysInEtage)
        this.RaumbuchPositionen = removeFromArray(this.RaumbuchPositionen, etageMember);
      return allEntitysInEtage;
    } else if (rootDeleteNode.isWohnung()) {
      const allEntitysInWohnung = this.RaumbuchPositionen.filter(
        pos => pos.Wng_ID === rootDeleteNode.Wng_ID && pos.Stw_ID === rootDeleteNode.Stw_ID
      );
      for (const wohnungMember of allEntitysInWohnung)
        this.RaumbuchPositionen = removeFromArray(this.RaumbuchPositionen, wohnungMember);
      return allEntitysInWohnung;
    } else {
      const raumToRemove = this.RaumbuchPositionen.filter(pos => pos.Uuid === rootDeleteNode.Uuid)[0];
      this.RaumbuchPositionen = removeFromArray(this.RaumbuchPositionen, raumToRemove);
      return [rootDeleteNode];
    }
  }

  /**@description Erstellt anhand von vom user eingegebnen daten ein neues aufmaß */
  createNew(): void {
    this.Uuid = UUID.UUID();
    this.NPosLineId = 0;
    const today = new Date();
    this.Datum = moment(today).format(deDatettimeFormatWithSeconds).toString();
    this.Type = 'R';
    this.Variant = 'E';
    this.AppStatus = 0;
    this.AufName = 'Raumaufmaß';
    this.RaumbuchPositionen = [];
    this.RVorlage = 0;
    this.RAUMBUCH = [];
  }

  /**@description Fügt alle Raumbuchpositionen einer Raumvorlage dem Aufmass hinzu */
  addRaumbuchpositionenFromRaumbuchvorlage(roomBook: number, roomTemplates: RVorlage[]): void {
    const correctRoomTemplates = roomTemplates.filter(template => template.Haustyp === roomBook);
    const indexRaumvorlage = correctRoomTemplates.findIndex(rVorlage => rVorlage.isRaumbuchVorlage());
    if (indexRaumvorlage > -1) correctRoomTemplates.splice(indexRaumvorlage, 1);
    const raumbuchPositionen: RaumbPos[] = correctRoomTemplates.map(template => template.toRaumbuchposition(this));
    this.NPosLineId = raumbuchPositionen?.length;
    let index = 0;
    for (const raumbuchPosition of raumbuchPositionen) {
      raumbuchPosition.assignNextRposid(index);
      index++;
    }
    this.RaumbuchPositionen = raumbuchPositionen;
  }
  assignAufmassKonstruktToCorrectRaum(raum: RaumbPos, AufmassKonstrukt: AufmassKonstrukt): void {
    const correctRaum = this.RaumbuchPositionen.find(position => position.Uuid === raum.Uuid);
    correctRaum.AufmassKonstrukt = AufmassKonstrukt;
  }

  /**@description Mitlerweile wird die AufmassID vom Handwerk bezogen und muss demnach nach erfolg in die Positionen getragen werden */
  assignAufmassIdToRaumbuchpositionen(): void {
    for (const position of this.RaumbuchPositionen) position.AufmId = this.AufmId;
  }

  assignDrawstackElements(Uuid: string, drawStackElements: AufmassDrawStackelement[], gridEnabled: boolean): void {
    const existingElement = this.drawStack.find(element => element.raumUuid === Uuid);
    if (existingElement) existingElement.AufmassDrawStackElements = drawStackElements;
    else {
      const newElement = new AufmassDrawStack(Uuid, drawStackElements, gridEnabled);
      this.drawStack.push(newElement);
    }
  }

  getUuid(): string {
    return this.Uuid;
  }
}
