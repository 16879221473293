import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { Projekt } from '../../entities/repository/Projekt';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProjektService {
  constructor(
    private baseService: BaseService,
    private controllerService: ControllerService,
    private dialogService: DialogService,
    private restService: RestService
  ) {}

  async getAll(): Promise<Projekt[]> {
    return await this.baseService.getAll(Projekt);
  }

  async getAllBy(selector: string, value: any): Promise<Projekt[]> {
    return await this.baseService.getAllBy(Projekt, selector, value);
  }

  async findOneBy(selector: string, value: any): Promise<Projekt> {
    return await this.baseService.findOneBy(Projekt, selector, value);
  }

  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(Projekt, selector, value);
  }

  async overrideLocal(projekte: Projekt[]): Promise<void> {
    await this.controllerService.clearStore('Projekt');
    await this.controllerService.setData('Projekt', projekte);
  }

  async sync(userInfo: Userinfo, showMessage = true): Promise<void> {
    if (showMessage) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Projekte...');

    await this.getAllProjekteFromWebservice(userInfo, showMessage);
    this.dialogService.closeLoadingDialog();
  }

  private async getAllProjekteFromWebservice(userInfo: Userinfo, showMessage: boolean): Promise<Projekt[]> {
    const projekte: Projekt[] = [];
    const targetUrl = 'ProjektePwa';
    const projekteData = await this.restService.returnData<Projekt[]>(userInfo, targetUrl, null, !showMessage);
    if (!projekteData) return projekte;
    for (const projektData of projekteData) {
      const projekt = new Projekt(projektData);
      projekte.push(projekt);
    }
    await this.overrideLocal(projekte);
    return projekte;
  }
}
