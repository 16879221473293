<div class="card aufmass-card" *ngIf="aufmass">
  <div class="flexContainer">
    <div class="flexContainerItem" (click)="goToAufmassDetail()">
      <div class="flexContainer">
        <div class="flexContainerItem">
          <b>{{ aufmass.AufName }} {{ aufmass?.AufmId }} </b><br />
          <p>Datum {{ aufmass.Datum?.substring(0, 10) }}</p>
          <p>Suchbegriff: {{ aufmass.Such }}</p>
          <p *ngIf="aufmass.Betreff">Betreff: {{ aufmass.Betreff }}</p>
          <p>{{ aufmass.Beschr }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
