import { assignIfPropertyExsits, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { HWRepairOrderItem, PositionsId } from 'apps/handwerkPWA/src/app/entities/repository/HWRepairOrderItem';

export class Textposition extends HWRepairOrderItem {
  Nummer: string = null;
  SuchText: string = null;
  Text: string = null;
  Mobil: boolean = null;
  UUID: string = null;
  Ident: PositionsId = 'T';
  RTFDATA: string = null;

  constructor(data: any) {
    super(data);
    assignIfPropertyExsits(this, data);
    this.MengenEinheit = '';
    this.RTFDATA = this.Text;
    this.Bezeichnung = this.Text;
    this.LangText = this.Text;
    this.KurzText = this.Text;
    this.SuchText = isNullOrUndefinedOrEmptyString(this.SuchText) ? this.Text : this.SuchText;
    this.Suchbegriff = this.SuchText;
    this.ID = 'T';
    this.Menge = 1;
  }
}
