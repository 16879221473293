import { Aufmass, HWAddress, HWAnlage, HWObjectaddress, HWRepairOrder, ServiceAuftrag } from '../entities';
import { Raum } from '../entities/models/aufmass/Raum';
import { RaumbPos } from '../entities/models/aufmass/RaumbPos';

/** Array mit Seiten, von denen ohne Check auf geändert Daten weggeroutet werden soll */
export const ignoreChangesOn: string[] = [
  '/einstellungen',
  'aufmass/:uuid/raumbuch',
  'aufmass/neu',
  'Login/UUID/:uuid/Mandant/:mandant/Employeenumber/:employeeNumber/Branding/:branding',
  '/Login',
  'aufmass/messung/:aufmassid/:raumid',
];
export const sitesToAutosaveOnChange: string[] = [
  '/reparaturauftrag/:guid/edit',
  '/auftragsabschluss/reparaturauftrag/:guid',
  '/auftragpositionen',
  'aufmass/grundriss/:aufmassid/:raumid',
];
/**Seiten mit Syncbutton */
export const syncButtonRoutes: string[] = [
  'adressen',
  'belege',
  'kalender',
  'nachrichten',
  'reparaturauftraege',
  'wartungsanlagen',
  'wartungsauftraege',
  'aufmasse',
];
export const noBackAvailable: string[] = ['Login', 'startseite', 'impressum'];
export const noMenuAvailable: string[] = ['Login'];

export const allowedPictureTypes: string[] = ['jpeg', 'jpg'];
export const allowedDocumentTypes: string[] = ['pdf', 'txt'];
export const allowedDatatypes: string[] = allowedPictureTypes.concat(allowedDocumentTypes);

export type positionType = 'Artikel' | 'Lohn' | 'Leistung' | 'Kostenart' | 'Leistungsposition' | 'Textposition';

export type terminTypeNumber = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';

export type terminType =
  | 'Termin'
  | 'Auftrag'
  | 'Aufgabe'
  | 'Telefon'
  | 'Wartungsrechnung'
  | 'Wartungstermin'
  | 'Geburtstag';

export type StuecklistenGlobalPrintSetting = 'An' | 'Aus' | 'Individuell';

export const deDatettimeFormatWithSeconds = 'DD.MM.YYYY HH:mm:SS';

export const deDatettimeFormat = 'DD.MM.YYYY HH:mm';

export const deDateFormat = 'DD.MM.YYYY';

export const timeFormat = 'HH:mm:ss';

export type DataSetNames = 'Adressen' | 'Aufträge' | 'Termine' | 'Nachrichten' | 'Serviceaufträge';

export type ServiceName =
  | 'nachrichtenservice'
  | 'belegservice'
  | 'terminservice'
  | 'auftragsservice'
  | 'nachrichtenservice'
  | 'adressservice'
  | 'einstellungen'
  | 'wartungsservice'
  | 'objectaddressservice'
  | 'aufmassservice';

export const SitesToReloadAfterNewPushData = [
  '/startseite',
  '/adressen',
  '/reparaturauftraege',
  '/kalender',
  '/nachrichten',
  '/wartungsauftraege',
];

export type AddressShortTypes = 'M' | 'F' | 'K' | 'L' | 'O';

export type AddressLongTypes = 'Mitarbeiter' | 'Freie Adressen' | 'Kunden' | 'Lieferanten' | 'Objektadressen';

export type DatesortableObjectType = 'HWRepairOrder' | 'HWTermin' | 'ServiceAuftrag' | 'ServiceAuftragPoolEntry';

export const topService = '/TopService.svc/';

export const datenschutzpath = 'https://bluesolution.de/datenschutzerklaerung/';

export const hilfepath = 'https://myblueapp.de/handwerk/produktinformationen/';

export type QuickButtonIcons = 'map' | 'tel' | 'globe' | 'info' | 'message';

export const lineBrakeRegex = /(?:\r\n|\r|\n)/g;
export type SourceOfFilelist =
  | HWAddress
  | HWObjectaddress
  | HWRepairOrder
  | ServiceAuftrag
  | HWAnlage
  | Aufmass
  | RaumbPos;

export type SourceOfFilelistString =
  | 'Address'
  | 'ObjectAdress'
  | 'RepairOrder'
  | 'ServiceOrder'
  | 'Anlage'
  | 'Aufmass'
  | 'RaumbPos';

/**btAllgemein{0}, btAdressen{1}, btMaterial{2}, btRepAuftrag{3}, btWSVertrag{4}, btWSAnlage{5}, btAufmass{6} */
export type BaseMediumType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

/**dtText{0}, dtPicture{1}, dtPDF{2} */
export type MediumDataType = 0 | 1 | 2;
export type Sliderside = 'Adressen' | 'Reparaturaufträge' | 'Termine' | 'W&S Anlagen' | 'W&S Aufträge' | 'Raumaufmaße';

export const allSliders: Sliderside[] = [
  'Adressen',
  'Reparaturaufträge',
  'Termine',
  'W&S Anlagen',
  'W&S Aufträge',
  'Raumaufmaße',
];

export const allSliderSettings: Array<{ name: Sliderside; value: boolean }> = [
  { name: 'Adressen', value: true },
  { name: 'Reparaturaufträge', value: true },
  { name: 'Termine', value: true },
  { name: 'W&S Anlagen', value: true },
  { name: 'W&S Aufträge', value: true },
  { name: 'Raumaufmaße', value: true },
];
