<h2 class="content-block">Objektadressen</h2>

<div class="content-block bottom-padding">
  <app-bss-list
    [id]="'adresses'"
    [inputDatasource]="objectAddresses"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine Objektadressen vorhanden'"
  ></app-bss-list>
</div>

<dx-speed-dial-action
  icon="add"
  class="add-button"
  label="Neue Objektadresse"
  [index]="3"
  (onClick)="navigateTo('objektadresse/neu/' + this.address.KU_NR)"
></dx-speed-dial-action>
