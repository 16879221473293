import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { IndexedDBTypes } from './dbType';
import { UUID } from 'angular2-uuid';
import { allowedPictureTypes, SourceOfFilelist } from '../../config/Konstanten';
import { HWAddress } from './HWAddress';
import { HWObjectaddress } from './HWObjectaddress';
import { noPreviewPdf } from 'apps/handwerkPWA/src/assets/images/noPreviewImage';
import { isUserAgentIOS } from 'libs/shared/src/lib/helper/deviceHelper';
import {
  readBlobAsDataUrlAsync,
  base64toBlob,
  dataUrlToBase64,
  customCompressIOs,
  customCompress,
} from '../../helper/services/documentHelper';
import { Medien } from './Medien';

export class HWFile extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWFile') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') Kundennummer: string;
  @IndexedDBTypes.IndexField('string') LfdNr: string;
  @IndexedDBTypes.DataField('string') Name: string;
  @IndexedDBTypes.DataField('string') Data: string;
  @IndexedDBTypes.DataField('boolean') Send = true;
  @IndexedDBTypes.DataField('string') PreviewPictureData: string;
  @IndexedDBTypes.DataField('string') NameWithoutEnding: string;
  @IndexedDBTypes.DataField('string') Extension: string;
  @IndexedDBTypes.IndexField('string') UUID: string;

  /**@description Ios hat bei neu aufgenommen medienn den dateinamen "image.jpeg" - daher neuen Namen geben sonst könnte man nur ein bild einfügen, weil sonst
   * das nächste den gleichen Namen hätte
   */
  setRandomIosFilenameIfNeeded(name: string): void {
    if (name === 'image.jpeg' || name === 'image.jpg') {
      const uuid = UUID.UUID();
      const uuidSplit = uuid.split('-');
      const newFileName = uuidSplit[4] + '.jpeg';
      this.setFilenameAndExtension(newFileName);
    }
  }

  setFilenameAndExtension(nameWithExtension: string): void {
    this.Name = nameWithExtension;
    if (!this.Name) return;
    const nameSplit = nameWithExtension.split('.');
    this.NameWithoutEnding = nameSplit[0];
    const extension = nameSplit[1];
    this.Extension = !isNullOrUndefined(extension) ? extension.toLowerCase() : '';
  }

  setDataUrl(documentUrl: string): void {
    this.Data = documentUrl;
    if (this.Extension !== 'pdf') this.PreviewPictureData = documentUrl;
  }

  addZuordnung(sourceOfFile: SourceOfFilelist): void {
    if (sourceOfFile instanceof HWAddress || sourceOfFile instanceof HWObjectaddress) {
      sourceOfFile = sourceOfFile as HWAddress;
      this.Kundennummer = sourceOfFile.getKundennummer();
      this.LfdNr = sourceOfFile.getLfdNr();
    }
  }

  getOriginalName(): string {
    return this.Name;
  }

  constructor(data: HWFile | Medien) {
    super();
    this.UUID = UUID.UUID();
    Object.assign(this, data);
    if (!isNullOrUndefined(this.Name) && this.Name !== '') {
      this.setFilenameAndExtension(this.Name);
    }
    if (data instanceof Medien) this.parseMedienInformation(data);
  }

  private parseMedienInformation(data: Medien) {}

  static toString(): string {
    return 'HWFile';
  }
}

export async function createHWFile(file: File, fileName: string): Promise<HWFile> {
  const previewPicutre = await readBlobAsDataUrlAsync(base64toBlob(dataUrlToBase64(noPreviewPdf), 'image/png'));
  const bssFile = new HWFile(null);
  bssFile.Send = false;
  bssFile.setFilenameAndExtension(fileName);
  if (file.type === 'application/pdf') {
    const dataUrlPdf = await readBlobAsDataUrlAsync(file);
    bssFile.Data = dataUrlToBase64(dataUrlPdf);
    bssFile.PreviewPictureData = previewPicutre;
  }
  // Medien
  const cleanFileType = file.type.replace('image/', '');
  if (allowedPictureTypes.includes(cleanFileType)) {
    let compressedFile: Blob;
    if (isUserAgentIOS()) compressedFile = (await customCompressIOs(file)) as Blob;
    else compressedFile = await customCompress(file);
    const dataUrlPicture = await readBlobAsDataUrlAsync(compressedFile);
    bssFile.PreviewPictureData = dataUrlPicture;
    bssFile.Data = dataUrlPicture;
  }
  return bssFile;
}
