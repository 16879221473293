import { Aufmass, HWAddress, MultiViewSite } from 'apps/handwerkPWA/src/app/entities';

export function sortAddresses(addresses: HWAddress[]): HWAddress[] {
  const allAdresses = sortAdressByKundennummer(addresses);
  const allAdressesSorted = changeOrderOfAdresstypes(allAdresses);

  return allAdressesSorted;
}

/**@description Vergleichsfunktion für die Aufträge zum ordnen */
function compareAdress(a: HWAddress, b: HWAddress) {
  const aKUNR = a.KU_NR;
  const bKUNR = b.KU_NR;
  if (aKUNR > bKUNR) {
    return -1;
  }
  if (aKUNR < bKUNR) {
    return 1;
  }

  return 0;
}

export function compareMultiViewSite(a: MultiViewSite, b: MultiViewSite) {
  const aValue = a.index;
  const bValue = b.index;
  if (aValue > bValue) {
    return -1;
  }
  if (aValue < bValue) {
    return 1;
  }

  return 0;
}

export function compareAufmass(a: Aufmass, b: Aufmass) {
  const aValue = a.AufmId;
  const bValue = b.AufmId;
  if (aValue > bValue) {
    return -1;
  }
  if (aValue < bValue) {
    return 1;
  }

  return 0;
}

/**@description Sortiert die Adressen nach Kundennummer
 * @param reverse Umgekehrte Reihenfolge
 */
function sortAdressByKundennummer(adresses: HWAddress[], reverse?: boolean) {
  adresses = adresses.sort(compareAdress);
  if (reverse) {
    adresses = adresses.reverse();
  }

  return adresses;
}

/**@description Sortiert die Adressen in Blöcke um sie neu zusammenzusetzen */
function changeOrderOfAdresstypes(allAddreses: HWAddress[]) {
  const kunden = [];
  const lieferanten = [];
  const freie = [];
  const mitarbeiter = [];
  for (const address of allAddreses) {
    const type = address.ADRTYP;
    switch (type) {
      case 'K': {
        kunden.push(address);
        break;
      }
      case 'L': {
        lieferanten.push(address);
        break;
      }
      case 'F': {
        freie.push(address);
        break;
      }
      case 'M': {
        mitarbeiter.push(address);
        break;
      }
    }
  }
  const firstBlock = kunden.concat(lieferanten);
  const secondBlock = freie.concat(mitarbeiter);
  const allAddresesReordered = firstBlock.concat(secondBlock);
  return allAddresesReordered;
}
