import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Aufmass, Projekt, RVorlage, HWAddress } from '../../../entities';
import { AufmassService } from '../../../services/dataServices/aufmass.service';
import { ProjektService } from '../../../services/dataServices/projekt.service';
import { RvorlageService } from '../../../services/dataServices/rvorlage.service';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { AddressService } from '../../../services/dataServices/address.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-aufmass-new',
  templateUrl: './aufmass-new.component.html',
  styleUrls: ['./aufmass-new.component.scss'],
})
export class AufmassNewComponent implements OnInit, OnDestroy {
  headline = 'Neues Raumaufmaß erstellen';
  userInfo: Userinfo;
  projects: Projekt[];
  roomBooks: RVorlage[];
  roomTemplates: RVorlage[];
  customers: HWAddress[];
  isEdit = false;
  aufmass: Aufmass;
  showKundeCard = true;
  selectedAdress: HWAddress;
  selectedProject: Projekt;
  loaded = false;
  saveSubscription: Subscription;

  constructor(
    private routingService: RoutingService,
    private aufmassService: AufmassService,
    private rvorlageService: RvorlageService,
    private globalSettingService: HWGolbalsettingService,
    private dialogService: DialogService,
    private projectService: ProjektService,
    private adressService: AddressService
  ) {}
  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    const newAufmass = new Aufmass(null);
    newAufmass.createNew();
    this.aufmass = newAufmass;
    await this.loadData();
    const uuidFromEdit = this.routingService.getRouteParam('uuid');
    this.isEdit = !isNullOrUndefined(uuidFromEdit);
    if (this.isEdit) await this.loadDataFromAufmass(uuidFromEdit);
    this.loaded = true;
    await this.preselectKunde();
  }

  /**@description Falls über den Kunden hernavigiert wird,diesen auswählen */
  private async preselectKunde() {
    const kundeUuid = this.routingService.getRouteParam('kundeUuid');
    if (!kundeUuid) return;
    const kunde = await this.adressService.findOneBy('Guid', kundeUuid);
    this.selectedOne(kunde);
  }

  /**@description Lädt Aufmaßdaten und füllt die felder zum editieren */
  private async loadDataFromAufmass(uuid: string): Promise<void> {
    this.headline = 'Aufmass bearbeiten';
    this.aufmass = await this.aufmassService.findOneBy('Uuid', uuid);
    this.selectedAdress = this.customers.find(customer => customer.KU_NR === this.aufmass.Kunde);
    this.selectedProject = this.aufmass.ProjName
      ? this.projects.find(project => project.Name === this.aufmass.ProjName)
      : null;
    this.saveSubscription = this.routingService.save.subscribe(() => void this.saveEdit(this.aufmass));
  }

  private async loadData() {
    this.userInfo = await this.globalSettingService.getUserinfo();
    this.projects = await this.projectService.getAll();
    this.customers = await this.adressService.getAllBy('ADRTYP', 'K');
    this.roomTemplates = await this.rvorlageService.getAll();
    this.roomBooks = await this.rvorlageService.getAllRaumbuchvorlagen();
  }

  selectedOne(entity: Projekt | RVorlage | HWAddress, wasRVorlage = false): void {
    if (entity instanceof Projekt) {
      this.selectedProject = entity;
      if (!this.aufmass.Such) this.aufmass.Such = entity.KdSuch;
      this.aufmass.ProjName = entity.Name;
      this.aufmass.Betreff = entity.Betreff;
      const projektCustomer = this.customers.find(customer => customer.KU_NR === entity.Kunde);
      this.selectedOne(projektCustomer);
    }
    if (entity instanceof HWAddress) {
      this.selectedAdress = entity;
      if (!this.aufmass.Such) this.aufmass.Such = entity.SUCH;
      this.aufmass.Kunde = entity.KU_NR;
      if (this.selectedAdress.KU_NR !== this.selectedProject?.Kunde)
        // Projekt passt nicht mehr zum Kunden => abbelegen
        this.selectedProject = undefined;
    }
    if (entity instanceof RVorlage) {
      this.aufmass.RVorlage = entity.Haustyp;
      this.aufmass.RBezeich = entity.Bezeich;
    }
    if (wasRVorlage && entity === null) {
      this.aufmass.RVorlage = 0;
      this.aufmass.RBezeich = '';
    }
  }

  async createNew(aufmass: Aufmass): Promise<void> {
    if (!aufmass.ProjName && !aufmass.Kunde) {
      await this.dialogService.openErrorMessage(
        'Fehlende Eingabe',
        'Es muss entweder ein Projekt oder eine Kunde ausgewählt sein.'
      );
      return;
    }
    if (!aufmass.RBezeich) {
      await this.dialogService.openErrorMessage(
        'Fehlendes Raumbuch',
        'Sie müssen ein Raumbuch wählen oder einen Namen für ein freies Raumbuch eingeben.'
      );
      return;
    }
    if (aufmass.RVorlage !== 0) aufmass.addRaumbuchpositionenFromRaumbuchvorlage(aufmass.RVorlage, this.roomTemplates);
    await this.saveAndExit(aufmass);
  }

  private async saveAndExit(newAufmass: Aufmass) {
    await this.aufmassService.saveAufmass(newAufmass, this.userInfo);
    void this.routingService.navigateTo(`/aufmass/${newAufmass.Uuid}/raumbuch`);
  }

  async saveEdit(aufmass: Aufmass): Promise<void> {
    this.routingService.dataChanged.next(false);
    await this.saveAndExit(aufmass);
  }
}
