import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Aufmass, RVorlage } from '../../../entities';
import { AufmassService } from '../../../services/dataServices/aufmass.service';
import { RvorlageService } from '../../../services/dataServices/rvorlage.service';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';

@Component({
  selector: 'app-choose-raumvorlage',
  templateUrl: './choose-raumvorlage.component.html',
  styleUrls: ['./choose-raumvorlage.component.scss'],
})
export class ChooseRaumvorlageComponent implements OnInit {
  roomBooks: RVorlage[];
  roomTemplates: RVorlage[];
  userInfo: Userinfo;

  @Input() aufmass: Aufmass;

  constructor(
    private rvorlageService: RvorlageService,
    private dialogService: DialogService,
    private aufmassService: AufmassService,
    private routingService: RoutingService,
    private globalSettingService: HWGolbalsettingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.roomBooks = await this.rvorlageService.getAllRaumbuchvorlagen();
    this.roomTemplates = await this.rvorlageService.getAll();
    this.userInfo = await this.globalSettingService.getUserinfo();
  }

  addVorlage(vorlage: RVorlage): void {
    if (vorlage instanceof RVorlage) {
      this.aufmass.RVorlage = vorlage.Haustyp;
      this.aufmass.RBezeich = vorlage.Bezeich;
    }
    if (vorlage === null) {
      this.aufmass.RVorlage = 0;
      this.aufmass.RBezeich = '';
    }
  }

  async save(): Promise<void> {
    if (!this.aufmass.RBezeich) {
      await this.dialogService.openErrorMessage(
        'Fehlendes Raumbuch',
        'Sie müssen ein Raumbuch wählen oder einen Namen für ein freies Raumbuch eingeben.'
      );
      return;
    }
    if (this.aufmass.RVorlage !== 0) {
      this.aufmass.addRaumbuchpositionenFromRaumbuchvorlage(this.aufmass.RVorlage, this.roomTemplates);
    }
    await this.aufmassService.saveAufmass(this.aufmass, this.userInfo);
    void this.routingService.navigateTo(`/aufmass/${this.aufmass.Uuid}/raumbuch`);
  }
}
