import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import { getSearchexpressionsBasedOnProperties, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { Userinfo } from 'libs/shared/src/lib/entities';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { DragEvent } from '../../interfaces/DragEvent';

/**Wichtig: Verwendet man asynchrone Daten als Input muss ein ngIf gekoppelt an den letzten asynchronen Input verwendet werden */
@Component({
  selector: 'app-bss-list',
  templateUrl: './bss-list.component.html',
  styleUrls: ['./bss-list.component.scss'],
})
export class BssListComponent implements OnInit, OnChanges {
  /** Kann weggelassen werden, dann werden alle Properties als suchkriterium genommen, die im Nullten Element der Datasource gesetzt waren */
  @Input() searchExpressions: string[];
  @Input() inputDatasource: any[];
  dataSource: DataSource;
  @Input() priceRight: boolean;
  @Input() searchEnabled = true;
  @Input() id: string;
  @Input() listClass: string;
  @Input() secondaryDatasource;
  @Input() userInfo: Userinfo;
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;
  @Input() dragAndDrop = false;
  @Input() emptyInputDataMessage: string;
  @Output() itemDragged = new EventEmitter<DragEvent>();
  create = new Date();
  activeStateEnabled = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const dataSourceChanged = changes.inputDatasource;
    if (dataSourceChanged) {
      this.setDataSource(this.inputDatasource);
      return;
    }
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.inputDatasource) || this.inputDatasource.length === 0) return;
    if (isNullOrUndefined(this.searchExpressions) || this.searchExpressions.length === 0)
      this.searchExpressions = getSearchexpressionsBasedOnProperties(this.inputDatasource);
    this.setDataSource(this.inputDatasource);
  }

  private setDataSource(nextDataSource: any[]) {
    this.dataSource = new DataSource({
      store: new ArrayStore({
        data: nextDataSource,
      }),
      paginate: true,
      pageSize: 50,
    });
  }

  removeItem(item): void {
    const index = this.inputDatasource.indexOf(item, 0);
    if (index > -1) {
      this.inputDatasource.splice(index, 1);
    }
    this.reAssignDatasource(this.inputDatasource);
  }

  /**@description Ändert sich von Außen die Liste,muss die Datasource neu zugewiesen werden */
  reAssignDatasource<Type>(updatedDataSource: Type[]): void {
    this.setDataSource(updatedDataSource);
  }

  dragEnd(event: DragEvent): void {
    this.itemDragged.emit(event);
  }
}
