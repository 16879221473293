import { PositionsArt } from 'apps/handwerkPWA/src/app/entities';

export function getLongtypeFromId(ident: string): PositionsArt {
  switch (ident) {
    case 'M':
    case 'm': {
      // normale Artikel
      return 'Artikel';
    }
    case 'J':
    case 'l': {
      // Leistung
      return 'Leistung';
    }
    case 'l': {
      return 'Unterleistung';
    }
    case 'S':
    case 's': {
      // Lohn
      return 'Lohn';
    }
    case 'D':
    case 'd': {
      // geräte
      return 'Gerät';
    }
    case 'F':
    case 'f': {
      // fremdleistung
      return 'Fremdleistung';
    }
    case 'O':
    case 'o': {
      // sonstiges
      return 'Sonstiges';
    }
    case 'T': {
      // sonstiges
      return 'Textposition';
    }
    default: {
      // fallback
      return 'Undefiniert';
    }
  }
}

export function getLongtypeFromKundenpreisId(ident: string): PositionsArt {
  switch (ident) {
    case 'M': {
      // normale Artikel
      return 'Artikel';
    }
    case 'L': {
      // Lohn
      return 'Lohn';
    }
    default: {
      // fallback
      return 'Undefiniert';
    }
  }
}
