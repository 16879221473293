import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SyncService } from 'apps/handwerkPWA/src/app/services/globalServices/sync.service';
import { entdeckenBasicUrl, entdeckenPremiumUrl } from 'libs/shared/src/lib/entities/models/Typedstrings';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent implements OnInit {
  entdeckenUrl: SafeResourceUrl;

  constructor(private syncService: SyncService, private rightService: RechteService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const licence = this.rightService.hasRightOrSettingEnabled('showObjektadressen');
    const entdeckenUrl = licence ? entdeckenPremiumUrl : entdeckenBasicUrl;
    this.entdeckenUrl = this.sanitizer.bypassSecurityTrustResourceUrl(entdeckenUrl);
    this.syncService.newThingsDiscovered.next(false);
  }
}
