import { Component, OnInit, HostListener, HostBinding } from '@angular/core';
import { ControllerService } from './services/globalServices/controller.service';
import deMessages from 'apps/handwerkPWA/src/assets/de.json';
import { HWGolbalsettingService } from './services/globalServices/hwgolbalsetting.service';
import { UpdatepwaService } from './services/globalServices/updatepwa.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { NGXLogger } from 'ngx-logger';
import { locale, loadMessages } from 'devextreme/localization';
import { imagesPaths } from './config/assets';
import { ScreenService } from 'libs/shared/src/lib/services/screen.service';
import config from 'devextreme/core/config';
import { BackgroundService } from './services/globalServices/background.service';
import { timer } from 'rxjs';
import { LocalstorageService } from './services/globalServices/localstorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class') get getClass(): string {
    return Object.keys(this.screen.sizes)
      .filter(cl => this.screen.sizes[cl])
      .join(' ');
  }
  constructor(
    private controllerService: ControllerService,
    private hWGolbalsettingService: HWGolbalsettingService,
    private updatePwaService: UpdatepwaService,
    private routingService: RoutingService,
    private logger: NGXLogger,
    private screen: ScreenService,
    private backgroundService: BackgroundService,
    private localStorageService: LocalstorageService
  ) {
    // Für die Lokalisierung(deutsche Namen und Datumsbezeichnungen)
    loadMessages(deMessages);
    locale('de-DE');
  }

  title = 'handwerkPWA';

  /**@description Guckt nach dem Browserback, verwendet dann anschließend den eigenen Service (Browser back unterdrückt durch BackButtonDisableModule) */
  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    const source = timer(1); // ansonsten wird scheinbar nicht geroutet
    source.subscribe(() => void this.routingService.routeBack());
  }

  /**@description Bevor die PWA neu geladen wird, bspw. bei einem Refresh */
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(): void {
    this.localStorageService.keepSessionData();
  }

  async ngOnInit(): Promise<void> {
    this.logger.log('----Appstart----');
    this.localStorageService.readSessionData();
    const idb = globalThis.indexedDB;
    void this.updatePwaService.storeInstallEvent();
    void this.updatePwaService.triggerInstall();
    await this.controllerService.storesErstellen();
    await this.backgroundService.restartServiceOnPageRefresh();
    this.preloadImages(imagesPaths);
    void this.updatePwaService.periodicCheck(60);
    this.configDevExtreme();
    const updateDone: string = await this.hWGolbalsettingService.getEntity('UpdateDone');
    if (updateDone === 'UpdateDone') {
      this.logger.log('Appstart nach Update');
      const userInfo = await this.updatePwaService.afterUpdateInitiated();
      userInfo.setPwaUpdateDoneFlag();
      await this.hWGolbalsettingService.setEntity(userInfo, 'Userinfo');
      this.logger.log('Userinfo nach Update bezogen - Logge wieder ein');
      void this.routingService.navigateTo('/Login');
      return;
    }
  }

  private preloadImages(images: string[]) {
    for (const image of images) {
      const img = new Image();
      img.src = image;
    }
  }

  private configDevExtreme() {
    config({
      floatingActionButtonConfig: { shading: true },
    });
  }
}
