import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { DataSetNames } from 'apps/handwerkPWA/src/app/config/Konstanten';

/**Einen Wert von -1 gibt der Webservice zurück bei nicht vorhandensein von Timestamps */
export class TimestampTable {
  Adressen = -1;
  Auftrag = -1;
  Material = -1;
  Leistungen = -1;
  Calender = -1;
  OwnRights = -1;
  Nachricht = -1;
  Serviceauftrag = -1;

  constructor(inputFromWebservice: any) {
    assignIfPropertyExsits(this, inputFromWebservice);
  }

  /**@description Prüft ob der Webservice an der jeweiligen Stelle noch einen Timestamp zu einem Datensatz fand und ob dieser timestamp sich vom vorherigen unterscheidet */
  checkForUpdates(lastTimestampTable: TimestampTable, licence: boolean): DataSetNames[] {
    const changesIn: DataSetNames[] = [];
    if (this.Adressen !== -1 && this.Adressen > lastTimestampTable.Adressen) changesIn.push('Adressen');
    if (this.Calender !== -1 && this.Calender > lastTimestampTable.Calender) changesIn.push('Termine');
    if (!licence)
      // Nachfolgend noch datensätze die nur mit lizenz benachrichtigt werden sollen
      return changesIn;
    if (this.Auftrag !== -1 && this.Auftrag > lastTimestampTable.Auftrag) changesIn.push('Aufträge');
    if (this.Nachricht !== -1 && this.Nachricht > lastTimestampTable.Nachricht) changesIn.push('Nachrichten');
    if (this.Serviceauftrag !== -1 && this.Serviceauftrag > lastTimestampTable.Serviceauftrag)
      changesIn.push('Serviceaufträge');
    return changesIn;
  }
}

export class TimeStampTableRequestNew {
  mandant: string;
  employeeNumber: string;
  repairOrderNumbersAlreadyInApp: string[];
  allTermine: boolean;

  constructor(mandant: string, employeeNumber: string, repairOrderNumbersAlreadyInApp: string[], allTermine: boolean) {
    this.mandant = mandant;
    this.employeeNumber = employeeNumber;
    this.repairOrderNumbersAlreadyInApp = repairOrderNumbersAlreadyInApp;
    this.allTermine = allTermine;
  }
}
