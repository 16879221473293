import { BauelementVorlage } from './BauelementVorlage';
import { Punkt } from './Punkt';

export type inputMode =
  | 'textinput'
  | 'bauelement'
  | 'drawpoints'
  | 'none'
  | 'wandGrid'
  | 'bauelementRaum'
  | 'bauelementWand';

export class AufmassDrawStackelement {
  event: Punkt;
  gridEnabled: boolean;
  currentMode: inputMode;
  currentBauelement?: BauelementVorlage;

  constructor(event: Punkt, gridEnabled: boolean, currentMode: inputMode, currentBauelement?: BauelementVorlage) {
    this.event = event;
    this.gridEnabled = gridEnabled;
    this.currentMode = currentMode;
    this.currentBauelement = currentBauelement;
  }
}
