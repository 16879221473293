export type timeInputType = 'Decimal' | 'Minutes';

export class TimeInput {
  inMinutes = 60;
  inDecimal = 1;
  displayMinutes = 60;
  displayDecimal = 1;

  constructor(value: number, type?: timeInputType) {
    this.updateTime(value, type);
  }

  updateTime(value: number, type: timeInputType): void {
    if (type === 'Decimal') {
      this.setTimeFromDecimal(value);
      return;
    }
    this.setTimeFromMinutes(value);
  }

  private setTimeFromMinutes(value: number): void {
    this.inMinutes = value;
    this.inDecimal = value / 60;
    this.displayMinutes = value;
    const roundedDecimalString = this.inDecimal.toFixed(2);
    this.displayDecimal = parseFloat(roundedDecimalString);
  }

  private setTimeFromDecimal(value: number): void {
    this.inDecimal = value;
    this.inMinutes = value * 60;
    this.displayDecimal = value;
    this.displayMinutes = Math.round(this.inMinutes);
  }
}
