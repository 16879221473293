import { DataSetNames, ServiceName } from 'apps/handwerkPWA/src/app/config/Konstanten';

export function changesInToRoute(changesIn: DataSetNames[]): '/nachrichten' | '/reparaturauftraege' {
  if (changesIn?.includes('Aufträge')) return '/reparaturauftraege';
  if (changesIn?.includes('Nachrichten')) return '/nachrichten';
  return null;
}

/**@description Übersetzt Änderungsdatensätze in einen Nutzerlesbaren String */
export function changesInToString(
  changesIn: DataSetNames[],
  auftragsRecht: boolean
): 'Nachrichten' | 'Reparaturaufträgen' | string {
  if (changesIn.length === 1) {
    const changeIn = changesIn[0];
    switch (changeIn) {
      case 'Nachrichten':
        return 'Nachrichten';
      case 'Aufträge':
        return 'Reparaturaufträgen';
    }
  }
  let changesInString = '';
  for (const dataSet of changesIn) {
    if (dataSet === 'Aufträge' && !auftragsRecht) continue;
    changesInString = changesInString + ', ' + dataSet;
  }
  changesInString = changesInString.replace(',', '');
  return changesInString;
}

/**@description Wandelt den Namen eines Datensatzes (zb. Adressen) in den zugehörigen Service um (adressservice) */
export function convertToServicename(dataSetName: DataSetNames): ServiceName {
  switch (dataSetName) {
    case 'Adressen':
      return 'adressservice';
    case 'Aufträge':
      return 'auftragsservice';
    case 'Nachrichten':
      return 'nachrichtenservice';
    case 'Termine':
      return 'terminservice';
    case 'Serviceaufträge':
      return 'wartungsservice';
  }
}
