import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { Positionrequest, HWRepairOrderItem, getShortTypeFromLeistungstype } from 'apps/handwerkPWA/src/app/entities';
import { Right, PositionSettings, Userinfo } from 'libs/shared/src/lib/entities';
import { ControllerService } from '../globalServices/controller.service';
import { positionType } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { cantUseFilterSettings } from 'apps/handwerkPWA/src/app/helper/services/repairOrderitemHelper';
import { BaseService } from './base.service';
import { ConnectionService } from '../globalServices/connection.service';

@Injectable({
  providedIn: 'root',
})
export class RepairOrderItemService {
  constructor(
    private dialogService: DialogService,
    private restService: RestService,
    private controllerService: ControllerService,
    private baseService: BaseService,
    private connectionService: ConnectionService
  ) {}

  /**
   * @description Holt eine Adressen aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async findOneBy(selector: string, value: any): Promise<HWRepairOrderItem> {
    return await this.baseService.findOneBy(HWRepairOrderItem, selector, value);
  }

  /**@description Holt die Offlinepositionen die in den Positionssettings markiert sind */
  async getOfflinePositionsFromWebservice(
    rechte: Right,
    userInfo: Userinfo,
    art: 'Lohn' | 'Leistung' | 'Artikel'
  ): Promise<void> {
    let artReadable = 'Artikel';
    if (art === 'Lohn') {
      artReadable = 'Lohnpositionen';
    }
    if (art === 'Leistung') {
      artReadable = 'Leistungen';
    }

    if (art === 'Artikel' || art === 'Leistung') {
      const itemsCount = await this.getOfflinePositionsCountFromWebservice(rechte, userInfo, art);

      if (itemsCount > 10000) {
        const confirmation = await this.dialogService.openConfirmDialog(
          'Synchronisation',
          `Sie versuchen, eine sehr große Menge von Artikeln (über 10.000) auf Ihr Mobilgerät zu synchronisieren. 
          Dafür sind viele mobile Endgeräte nicht ausgelegt und es kann zu langen Synchronisationszeiten und/oder zu Fehlern während 
          der Synchronisation kommen. Wollen Sie wirklich die Synchronisation fortsetzen?`,
          'Fortsetzen',
          'Abbrechen'
        );
        if (!confirmation) {
          return;
        }
      }

      const stepLarge = 2000;
      const stepsCount = Math.ceil(itemsCount / stepLarge);
      let dbWasResetted = false;

      for (let stepNumber = 1; stepNumber <= stepsCount; stepNumber++) {
        let stepItemsCount = stepNumber * stepLarge;
        if (stepItemsCount > itemsCount) {
          stepItemsCount = itemsCount;
        }
        void this.dialogService.openLoadingDialog(
          'Synchronisation',
          '...beziehe ausgewählte offline ' + artReadable + '. ' + stepItemsCount + ' von ' + itemsCount + '...'
        );
        const positionRequest = new Positionrequest(userInfo.mandant, userInfo.monteur);
        positionRequest.requestType = 'Offlinepositions';
        positionRequest.changeType(art);
        positionRequest.skip = (stepNumber - 1) * stepLarge;
        positionRequest.take = stepLarge;
        const positions = await this.getItemsFromWebservice(userInfo, positionRequest, rechte.PositionSettings);
        if (isNullOrUndefined(positions)) {
          this.dialogService.closeLoadingDialog();
          continue;
        }
        for (const position of positions) {
          position.offlinePosition = true;
        }
        if (!dbWasResetted) {
          await this.controllerService.deleteData('HWRepairOrderItem', 'Ident', positions[0]?.Ident);
          dbWasResetted = true;
        }
        await this.controllerService.setData('HWRepairOrderItem', positions);
        this.dialogService.closeLoadingDialog();
      }
    } else {
      void this.dialogService.openLoadingDialog(
        'Synchronisation',
        '...beziehe ausgewählte offline ' + artReadable + '...'
      );
      const positionsSettings = rechte.PositionSettings;
      const positionRequest = new Positionrequest(userInfo.mandant, userInfo.monteur);
      positionRequest.requestType = 'Offlinepositions';
      positionRequest.changeType(art);
      const positions = await this.getItemsFromWebservice(userInfo, positionRequest, positionsSettings);
      if (isNullOrUndefined(positions)) {
        this.dialogService.closeLoadingDialog();
        return;
      }
      for (const position of positions) {
        position.offlinePosition = true;
      }
      const ident = positions[0]?.Ident;
      await this.controllerService.deleteData('HWRepairOrderItem', 'Ident', ident);
      await this.controllerService.setData('HWRepairOrderItem', positions);
      this.dialogService.closeLoadingDialog();
    }
  }

  async getOfflinePositionsCountFromWebservice(
    rechte: Right,
    userInfo: Userinfo,
    art: 'Leistung' | 'Artikel'
  ): Promise<number> {
    const positionRequest = new Positionrequest(userInfo.mandant, userInfo.monteur);
    positionRequest.requestType = 'Offlinepositions';
    positionRequest.changeType(art);
    positionRequest.addPositionSettingData(rechte.PositionSettings);
    const itemsCount = await this.restService.returnData<number>(userInfo, 'OfflinePositionsCount', positionRequest);
    if (isNullOrUndefined(itemsCount)) {
      return 0;
    }
    return itemsCount;
  }

  /**@description Je nach Einstellung und ob man online ist werden Items entweder online nachgeschlagen oder aus der DB geholt */
  async getItemsByChoice(userInfo: Userinfo, positionRequestData: Positionrequest): Promise<HWRepairOrderItem[]> {
    const type = positionRequestData.getType();
    const impossibleToFindResults = this.isImpossibleToFindResults(positionRequestData, type);
    if (impossibleToFindResults) {
      await this.dialogService.openErrorMessage(
        'Leeres Ergebnis',
        'Aufgrund der Rechteeinstellung des Mitarbeiters können keine Artikel gefunden werden.'
      );
      return [];
    }
    const canAskOffline = this.canAskOffline(positionRequestData, type);
    const noOnlinePositionsAvailable = this.noOnlinePositionsAvailable(positionRequestData, type);
    const userIsOffline = !(await this.connectionService.CheckOnline());
    const takeOffline = (canAskOffline && userIsOffline) || (noOnlinePositionsAvailable && canAskOffline);
    if (takeOffline) {
      const offlineItems = await this.getOfflineItems(positionRequestData, type);
      return offlineItems;
    }
    positionRequestData.requestType = 'Liverequest';
    const onlineItems = await this.getItemsFromWebservice(userInfo, positionRequestData, positionRequestData);
    const noOnlineSucces = onlineItems?.length === 0 || isNullOrUndefined(onlineItems);
    const searchOfflineAfterNoOnlineSucces = noOnlineSucces && canAskOffline;
    if (searchOfflineAfterNoOnlineSucces) {
      const offlineItems = await this.getOfflineItems(positionRequestData, type);
      return offlineItems;
    }
    return onlineItems;
  }

  private async getOfflineItems(positionRequestData: Positionrequest, type: positionType) {
    positionRequestData.requestType = 'Offlinepositions';
    const offlineItems = await this.getOfflinePositionsFromIDB(positionRequestData, type);
    return offlineItems;
  }

  /**@description Guckt ob Filter an sind, aber nichts ausgewählt ist */
  private isImpossibleToFindResults(positionRequestData: Positionrequest, type: positionType): boolean {
    if (cantUseFilterSettings(positionRequestData, type)) {
      return false;
    }
    const lieferanbtenSelected = positionRequestData.Lieferanten?.find(
      lieferant => lieferant.synchronize === true || lieferant.offlineAvailable === true
    );
    if (type === 'Artikel' && positionRequestData.useFilterLieferanten && isNullOrUndefined(lieferanbtenSelected)) {
      return true;
    }
    const gewerkeSelected = positionRequestData.Gewerk?.find(
      gewerk => gewerk.synchronize === true || gewerk.offlineAvailable === true
    );
    if (type === 'Leistung' && positionRequestData.useFilterGewerk && isNullOrUndefined(gewerkeSelected)) {
      return true;
    }
    return false;
  }

  /**@description Guckt ob gar keine online verfügbaren Dinge zugelassen sind */
  private noOnlinePositionsAvailable(positionRequest: Positionrequest, type: positionType) {
    if (cantUseFilterSettings(positionRequest, type)) {
      return false;
    }
    if (type === 'Kostenart' || type === 'Lohn') {
      return false;
    }
    let onlineCandidad;
    if (type === 'Artikel') {
      onlineCandidad = positionRequest.Lieferanten.find(lieferant => lieferant.synchronize);
    }
    if (type === 'Leistung') {
      onlineCandidad = positionRequest.Gewerk.find(gewerk => gewerk.synchronize);
    }
    const justOnlineArticles = isNullOrUndefined(onlineCandidad);
    return justOnlineArticles;
  }

  /**@description Guckt ob es erlaubt ist und ob überhaupt offline beziehbare Dinge ausgewählt sind */
  canAskOffline(positionRequest: Positionrequest, type: positionType): boolean {
    if (type === 'Lohn') {
      return true;
    }
    if (type === 'Kostenart') {
      return false;
    }
    if (cantUseFilterSettings(positionRequest, type)) {
      return false;
    }
    let offlineAllowed = positionRequest.useFilterLieferanten; // Damit überhaupt eingestellt sein kann, dass offline darf, muss zumindest allgemein ein filter verwendet werden
    if (type === 'Leistung') {
      offlineAllowed = positionRequest.useFilterGewerk;
    }
    if (!offlineAllowed) {
      return false;
    }

    let offlineCandidad;
    if (type === 'Artikel') {
      offlineCandidad = positionRequest.Lieferanten.find(lieferant => lieferant.offlineAvailable);
    }
    if (type === 'Leistung') {
      offlineCandidad = positionRequest.Gewerk.find(gewerk => gewerk.offlineAvailable);
    }

    const offlineAvailable = !isNullOrUndefined(offlineCandidad);

    return offlineAvailable;
  }

  /**@description Sucht in allen offlienPositionen diejenigen, in denen der Suchtext enthalten ist */
  async getOfflinePositionsFromIDB(positionRequest: Positionrequest, type: positionType): Promise<HWRepairOrderItem[]> {
    const shortType = getShortTypeFromLeistungstype(type);
    const allItemsFromRequestTypeData = await this.controllerService.getData<HWRepairOrderItem[]>(
      'HWRepairOrderItem',
      shortType,
      'Ident'
    );
    const allItemsFromRequestType = allItemsFromRequestTypeData.map(data => new HWRepairOrderItem(data));
    if (shortType === 'S') {
      return allItemsFromRequestType;
    }
    let allowedItems: HWRepairOrderItem[];
    if (type === 'Artikel') {
      const allowedLieferanten = positionRequest.Lieferanten.filter(lieferant => lieferant.offlineAvailable === true);
      const allowedLieferantenNumbers = allowedLieferanten.map(lieferant => lieferant.NR);
      allowedItems = allItemsFromRequestType.filter(item => allowedLieferantenNumbers.includes(item.Lieferant));
    }
    if (type === 'Leistung') {
      const allowedGewerke = positionRequest.Gewerk.filter(gewerk => gewerk.offlineAvailable === true);
      const allowedGewerkeNumbers = allowedGewerke.map(gewerk => gewerk.NR);
      allowedItems = allItemsFromRequestType.filter(item => allowedGewerkeNumbers.includes(item.GEWERK));
    }
    const searchText = positionRequest.SearchText;
    const requestedItems = allowedItems.filter(item => this.searchForItem(item, searchText));
    return requestedItems;
  }

  /**@description Sucht anhand des Suchtextes in bezeichung, kurztext und langtext */
  private searchForItem(item: HWRepairOrderItem, searchText: string): boolean {
    searchText = searchText.toLowerCase();
    const itemBezeichnung = item.getBezeichnung().toLowerCase();
    const kurzText = item.KurzText.toLowerCase();
    const langText = item.getLangtext().toLowerCase();
    const nummer = item.Nummer;
    const suchbegriff = item.getSuchbegriff().toLowerCase();
    return (
      suchbegriff.includes(searchText) ||
      itemBezeichnung.includes(searchText) ||
      kurzText.includes(searchText) ||
      langText.includes(searchText) ||
      nummer.includes(searchText)
    );
  }

  async getItemsFromWebservice(
    userInfo: Userinfo,
    positionRequest: Positionrequest,
    positionsSettings: PositionSettings
  ): Promise<HWRepairOrderItem[]> {
    const typeUrl = 'OfflinePositions';
    positionRequest.addPositionSettingData(positionsSettings);
    const positionData = await this.restService.returnData<HWRepairOrderItem[]>(userInfo, typeUrl, positionRequest);
    const positions: HWRepairOrderItem[] = [];
    if (isNullOrUndefined(positionData)) {
      return positions;
    }
    for (const data of positionData) {
      const position = new HWRepairOrderItem(data);
      positions.push(position);
    }
    return positions;
  }
}
