import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';
@Component({
  selector: 'app-bss-selectbox',
  templateUrl: './bss-selectbox.component.html',
  styleUrls: ['./bss-selectbox.component.scss'],
})
export class BssSelectboxComponent implements AfterViewInit {
  @Input() labelText;
  @Input() items;
  @Input() value;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() acceptCustomValue = false;
  @Input() showClearButton = false;
  @Input() displayExpr;
  @Input() searchMode: string;

  @Output() selectedValue = new EventEmitter<any>();
  @Output() itemClicked = new EventEmitter<any>();
  @ViewChild(DxSelectBoxComponent) selectBox: DxSelectBoxComponent;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.searchMode && this.selectBox) {
      this.selectBox.searchMode = this.searchMode;
    }
  }

  valueChanged(event): void {
    const value = event.value;
    this.selectedValue.emit(value);
  }

  itemWasClicked(event): void {
    this.itemClicked.emit(event);
  }
}
