import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { DialogService } from './dialog.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { Userinfo, PwaLoginResponse } from 'libs/shared/src/lib/entities';
import { BehaviorSubject } from 'rxjs';
import { baseWebserviceVersionString } from '../entities/models/user/Feature';

@Injectable({
  providedIn: 'root',
})
export class AuthorisationService {
  current = new BehaviorSubject<PwaLoginResponse>(null);

  constructor(private restService: RestService, private dialogService: DialogService) {}

  private async handleError(errorHeader: string, errorMessage: string) {
    const htmlString =
      errorMessage +
      ' Nähere Informationen erhalten Sie in der <a href="https://myblueapp.de/handwerk/produktinformationen/" target="_blank">FAQ</a> .';
    await this.dialogService.openConfirmDialog(errorHeader, null, 'Ok', null, false, htmlString);
  }

  /**@description Prüft Authentifizierung, MindestHandwerksDbVersion und WebserviceVersion */
  async checkLogin(
    userInfo: Userinfo,
    silent = false
  ): Promise<{
    isOkay: boolean;
    response: PwaLoginResponse;
  }> {
    const response = await this.restService.returnData<PwaLoginResponse>(
      userInfo,
      'checklogin',
      userInfo.mandant,
      silent
    );
    return await this.reactOnLoginresponse(response, userInfo);
  }

  /**@description Gleiche wie checklogin aufruf,aber leidglich für die versionen,ohne authentifizierung */
  async checkVersion(
    userInfo: Userinfo
  ): Promise<{
    isOkay: boolean;
    response: PwaLoginResponse;
  }> {
    const response = await this.restService.returnData<PwaLoginResponse>(
      userInfo,
      'checkVersionPwa',
      userInfo.mandant,
      true
    );
    return await this.reactOnLoginresponse(response, userInfo, true);
  }

  /**@description Wertet eine Loginresponse aus */
  private async reactOnLoginresponse(
    response: PwaLoginResponse,
    userInfo: Userinfo,
    callFromGui = false
  ): Promise<{
    isOkay: boolean;
    response: PwaLoginResponse;
  }> {
    if (!response) return { isOkay: false, response: null };

    const loginResponse = new PwaLoginResponse(response);

    if (!callFromGui) this.current.next(loginResponse);

    const product = callFromGui ? 'aktuellen Rechteverwaltung' : 'my blue:app hand:werk';
    if (isNullOrUndefined(loginResponse.webserviceVersion)) {
      await this.handleError(
        'Inkompatibler Webservice',
        `Der Webservice passt nicht zur ${product}. Sie benötigen mindestens einen Webservice mit der Version ${baseWebserviceVersionString} .`
      );
      return { isOkay: false, response: loginResponse };
    }

    const baseFunctionality = loginResponse.featureCheck('Basis');

    if (baseFunctionality.available) return { isOkay: true, response: loginResponse };

    // Webservice Version zu tief => weiterarbeit unterbinden
    if (!baseFunctionality.webserviceHighEnough) {
      await this.handleError(
        'Inkompatibler Webservice',
        `Der Webservice  ${loginResponse.webserviceVersion} passt nicht zur ${product}. Sie benötigen mindestens einen Webservice mit der Version ${baseFunctionality.minWebserviceString} .`
      );
      return { isOkay: false, response: loginResponse };
    }

    // HandwerksDbVersion zu tief => nicht weiterarbeiten lassen
    if (!baseFunctionality.dbHighEnough) {
      await this.handleError(
        'Inkompatible Datenbankversion',
        `Die Datenbankversion ${loginResponse.handwerkDBVersion} Ihres Handwerksmandanten ${userInfo.mandant} passt nicht zur ${product}.` +
          ` Sie benötigen mindestens eine Version ${baseFunctionality.minDBString} .` +
          ` Bitte aktualisieren Sie Ihre Handwerksdatenbank bzw, laden Sie ggf. die aktuelle Version Ihrer Handwerkersoftware, um eine fehlerfreie Nutzung der ${product} zu gewährleisten.`
      );
      return { isOkay: false, response: loginResponse };
    }
    return { isOkay: true, response: loginResponse };
  }
}
