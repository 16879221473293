import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Formeln } from 'apps/handwerkPWA/src/app/config/Formeln';
import { Aufmass } from 'apps/handwerkPWA/src/app/entities';
import { AufmassFormel } from 'apps/handwerkPWA/src/app/entities/models/aufmass/AufmassFormel';
import { ValueNamePair } from 'apps/handwerkPWA/src/app/entities/models/aufmass/ValueNamePair';
import { RaumbuchService } from 'apps/handwerkPWA/src/app/services/dataServices/raumbuch.service';
import { validateFormula } from 'libs/shared/src/lib/helper/formelHelper';
import { isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-aufmass-formel',
  templateUrl: './aufmass-formel.component.html',
  styleUrls: ['./aufmass-formel.component.scss'],
})
export class AufmassFormelComponent implements OnInit {
  @Output() generatedFormel = new EventEmitter<string>();
  @Input() aufmass: Aufmass;
  freeInput = false;
  formeln: AufmassFormel[];
  selectedFormel: AufmassFormel;
  formelString: string;
  allMeasured = false;
  originalInput: string;

  constructor(
    private dialogService: DialogService,
    private routingService: RoutingService,
    private raumbuchService: RaumbuchService
  ) {}

  getDecimalPlaces(pair: ValueNamePair): number {
    return pair.value ? pair.value?.toString().split('.')[1]?.length : 2;
  }

  ngOnInit(): void {
    const massUuid = this.routingService.getRouteParam('massketteid');
    const massKette = this.raumbuchService.findOneRaumbuchBy(this.aufmass, 'Uuid', massUuid);
    if (massKette) {
      this.formelString = massKette.Aufmass;
      this.originalInput = massKette.Aufmass;
    }
    const formelData = Formeln;
    this.formeln = formelData.map(data => new AufmassFormel(data));
  }

  messwertChanged(value: number, displayName: string): void {
    const changedPair = this.selectedFormel.ValueNamePairs.find(pair => pair.displayName === displayName);
    changedPair.value = value;
    const allMeasured = this.selectedFormel?.checkAllPairsMeasured();
    if (allMeasured) {
      this.formelString = this.selectedFormel.generateValuefilledFormula();
      this.formelString = this.formelString.replaceAll('[', '').replaceAll(']', '');
      this.validateFormel(this.formelString);
      this.allMeasured = true;
    }
  }

  validateFormel(formel: string): boolean {
    const valid = validateFormula(formel);
    if (!valid) {
      const errorText = isNullOrUndefinedOrEmptyString(formel)
        ? 'Die Formel darf nicht leer sein. '
        : `Ihre Eingabe ${formel} ist ungültig und kann nicht berechnet werden. Bitte geben Sie nur Zahlen, Rechenzeichen und Klammern ein.`;
      void this.dialogService.openErrorMessage('Eingabefehler', errorText);
    }
    if (valid) this.generatedFormel.emit(formel);
    return valid;
  }

  getImage(base64ImageData: string): string {
    return 'data:image/png;base64,' + base64ImageData;
  }

  /**@description Setzt die gewählte Formel auf null und stellt die ursprüngliche formel wieder her (wenn vorhanden) */
  clearFormelSelection(): void {
    this.selectedFormel = null;
    this.formelString = this.originalInput;
  }
}
