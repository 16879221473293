import { Component, OnInit, OnDestroy } from '@angular/core';
import { delay } from 'q';
import version from 'deployment/version.json';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { Right, EmailconnectionInfo, PositionSettings, Setting, Userinfo } from 'libs/shared/src/lib/entities';
import { DialogService } from '@handwerk-pwa/shared';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { UpdatepwaService } from 'apps/handwerkPWA/src/app/services/globalServices/updatepwa.service';
import { RepairOrderItemService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrderItem.service';
import { AppOnlySettings, HWMonteur, Positionrequest } from 'apps/handwerkPWA/src/app/entities';
import { isUserAgentIOS } from 'libs/shared/src/lib/helper/deviceHelper';
import { MonteurService } from '../../services/dataServices/monteur.service';
import { BackgroundService } from '../../services/globalServices/background.service';
import { Mailservice } from 'libs/shared/src/lib/services/mail.service';
import { allSliders, allSliderSettings, Sliderside } from '../../config/Konstanten';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { removeFromArray } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  version = version;
  settings: Setting;
  rechte: Right;
  mandant: string;
  emailConnectionInfo = new EmailconnectionInfo();
  year = new Date().getFullYear().toString();
  showPricesChangeRight: boolean;
  pwaInstalled = false;
  userInfo: Userinfo;
  positionSettings: PositionSettings;
  canAskOfflineArtikel: boolean;
  canAskOfflineLeistung: boolean;
  hiddenCounter = 0;
  viewFinished = false;
  currentMonteur: HWMonteur;
  appOnlySettings: AppOnlySettings;
  showCheckBox = false;
  possibleSliderSettings: Array<{ name: Sliderside; value: boolean }> = [];
  sliderOrder: Sliderside[];
  hasAufmass: boolean;
  errorDialogsEnabled = false;

  constructor(
    private rechteService: RechteService,
    private globalSettingService: HWGolbalsettingService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private updatePwaService: UpdatepwaService,
    private repairOrderItemService: RepairOrderItemService,
    private monteurService: MonteurService,
    private backgroundService: BackgroundService,
    private mailService: Mailservice,
    private authorisationService: AuthorisationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.emailConnectionInfo = (await this.mailService.getEmailconnectionInfo()) || this.emailConnectionInfo;
    this.settings = await this.rechteService.getEinstellungenFromIDB();

    if (this.settings?.emailConnectionInfo) this.settings.emailConnectionInfo = this.emailConnectionInfo;
    this.rechte = this.rechteService.getCurrentRight();
    this.appOnlySettings = await this.globalSettingService.getAppOnlySettings();

    this.getPossibleSlidersettings(this.appOnlySettings.slidersSetting, this.settings.sliderOrder);
    this.positionSettings = this.rechte.PositionSettings;
    this.showPricesChangeRight = this.rechte.InappChangeRights.showPrices;
    this.userInfo = await this.globalSettingService.getUserinfo();
    const positionRequest = new Positionrequest(this.userInfo.mandant, this.userInfo.monteur);
    positionRequest.addPositionSettingData(this.positionSettings);
    this.canAskOfflineArtikel = this.repairOrderItemService.canAskOffline(positionRequest, 'Artikel');
    this.canAskOfflineLeistung = this.repairOrderItemService.canAskOffline(positionRequest, 'Leistung');
    this.mandant = this.userInfo.mandant;
    this.pwaInstalled =
      this.updatePwaService.isInstalled || isUserAgentIOS() || window.matchMedia('(display-mode: standalone)').matches;
    await this.loadCurrentSignedInMonteur();
    this.hasAufmass = this.authorisationService.current.getValue().featureCheck('Aufmass').available;
    this.errorDialogsEnabled = await this.globalSettingService.getEntity<boolean>('ErrorLoggingEnabled');
    this.viewFinished = true;
  }

  /**@description Filtert nicht mögliche Seiten heraus */
  getPossibleSlidersettings(
    slidersSetting: Array<{ name: Sliderside; value: boolean }>,
    sliderOrder: Sliderside[]
  ): void {
    const wartungsAuftraege = this.authorisationService.current.getValue().featureCheck('Wartung und Service')
      .available;
    const aufmass = this.authorisationService.current.getValue().featureCheck('Aufmass').available;

    const notPossible: Sliderside[] = [];
    if (!wartungsAuftraege) notPossible.push('W&S Aufträge');
    if (!aufmass) notPossible.push('Raumaufmaße');

    // neu hinzugekommen durch "höheres" featurecheck ergebnis
    const newSliders = allSliders.filter(x => !sliderOrder.includes(x));
    const newSliderSettings = allSliderSettings.filter(x => !slidersSetting.map(y => y.name).includes(x.name));

    slidersSetting = slidersSetting.concat(newSliderSettings);
    sliderOrder = sliderOrder.concat(newSliders);

    for (const blackListEntry of notPossible) {
      sliderOrder = removeFromArray(sliderOrder, blackListEntry);
      const blackListSettingEntry = slidersSetting.find(entry => entry.name === blackListEntry);
      slidersSetting = removeFromArray(slidersSetting, blackListSettingEntry);
    }

    this.possibleSliderSettings = slidersSetting;
    this.sliderOrder = sliderOrder;
  }

  async installPwa(): Promise<void> {
    await this.updatePwaService.triggerInstall();
  }

  async getOfflinePosition(art: 'Lohn' | 'Leistung' | 'Artikel'): Promise<void> {
    await this.repairOrderItemService.getOfflinePositionsFromWebservice(this.rechte, this.userInfo, art);
  }

  async ngOnDestroy(): Promise<void> {
    const nextroute = this.routingService.getNextPlannedRoute();
    const isSyncing = nextroute.startsWith('/reload/') === true;
    await this.globalSettingService.setEntity(this.errorDialogsEnabled, 'ErrorLoggingEnabled');
    await this.globalSettingService.setEntity(this.appOnlySettings, 'AppOnlySettings');
    if (isSyncing) return;
    await this.rechteService.saveRightLocally(this.rechte);
    await this.rechteService.saveSettingLocally(this.settings);
  }

  async saveEmailConnectionInfo(): Promise<void> {
    // Validation an dieser Stelle führt dazu, dass man die Daten nicht löschen kann. Laut CS keine Validation nötig...
    await this.mailService.setEmailconnectionInfo(this.emailConnectionInfo);
    this.emailConnectionInfo = await this.mailService.getEmailconnectionInfo();
    this.settings.emailConnectionInfo = this.emailConnectionInfo;
    this.dialogService.openInformDialog('Erfolg', 'Ihre Zugangsdaten wurden erfolgreich gespeichert', 'Ok');
  }

  async getEmailConnectionInfoFromWebservice(): Promise<void> {
    await this.mailService.overrideLocalEmailConnectionInfoWithHandwerkDbData(this.userInfo, true);
    this.emailConnectionInfo = (await this.mailService.getEmailconnectionInfo()) || this.emailConnectionInfo;
  }

  async dragEnd(): Promise<void> {
    this.settings.sliderOrder = this.sliderOrder;
    await delay(50);
    await this.rechteService.saveSettingLocally(this.settings);
  }

  async manualUpdateTrigger(): Promise<void> {
    this.hiddenCounter++;
    if (this.hiddenCounter === 10) {
      this.hiddenCounter = 0;
      await this.updatePwaService.checkManuallyForUpdates();
    }
  }

  /**@description Lädt den aktuell eingeloggten Monteur */
  private async loadCurrentSignedInMonteur() {
    const signedIn = await this.monteurService.userSignedIn();

    if (signedIn) this.currentMonteur = await this.monteurService.getMonteurFromIDB();
  }

  /**@description Nach eingabe eines neuen Wertes des Zeitintervalls wird dieses mit dem neuen Wert neugestartet */
  restartBackgroundSyncTimer(userInfo: Userinfo, newIntervall: number): void {
    this.backgroundService.stopBackgroundSyncIntervall();
    this.backgroundService.startBackgroundSyncIntervall(userInfo, newIntervall);
  }

  async confirmSliders(output: any): Promise<void> {
    this.appOnlySettings.slidersSetting = output as Array<{ name: Sliderside; value: boolean }>;
    await this.globalSettingService.setEntity<AppOnlySettings>(this.appOnlySettings, 'AppOnlySettings');
    this.showCheckBox = false;
  }
}
