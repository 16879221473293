import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { isDate, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import moment from 'moment';

interface DxDateEvent {
  value: string | Date;
  previousValue: string | Date;
}

type DateType = 'date' | 'datetime' | 'time';
@Component({
  selector: 'app-bss-datetime-box',
  templateUrl: './bss-datetime-box.component.html',
  styleUrls: ['./bss-datetime-box.component.scss'],
})
export class BssDatetimeBoxComponent implements OnInit {
  @Input() labelText;
  @Input() value: Date | string;
  @Input() type: DateType = 'date';
  @Input() buttonIcon = 'event';
  @Input() min: Date;
  @Input() max: Date;
  @Input() disabled = false;
  @Output() outValue = new EventEmitter<Date>();
  @Output() outStringValue = new EventEmitter<string>();
  displayFormat = 'dd.MM.yyyy';
  ready = false;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    this.setDisplayFormat(this.type);
    this.value = this.setCorrectDate(this.value, this.type);
    this.ready = true;
  }

  dateChanged(event: DxDateEvent, type: DateType): void {
    const value = event.value;
    const newDate = this.parseValueChange(value, type);
    this.value = newDate;
    let momentString: string;
    switch (type) {
      case 'date':
        momentString = moment(newDate).format('DD.MM.YYYY');
        break;
      case 'datetime':
        momentString = moment(newDate).format('DD.MM.YYYY HH:mm');
        break;
      case 'time':
        momentString = moment(newDate).format('HH:mm');
        break;
    }
    this.outValue.emit(newDate);
    this.outStringValue.emit(momentString);
    this.routingService.dataChanged.next(true);
  }

  /** Private Functions */

  private parseValueChange(value: Date | string, type: DateType): Date {
    if (isDate(value)) return new Date(value);
    return this.setCorrectDate(value, type);
  }

  private setDisplayFormat(type: DateType) {
    switch (type) {
      case 'date':
        this.displayFormat = 'dd.MM.yyyy';
        break;
      case 'datetime':
        this.displayFormat = 'dd.MM.yyyy HH:mm';
        break;
      case 'time':
        this.displayFormat = 'HH:mm';
        break;
    }
  }

  private setCorrectDate(value: string | Date, type: DateType): Date {
    if (isNullOrUndefinedOrEmptyString(value as string) || value === ' ') return null;
    let returnValue: Date;
    switch (type) {
      case 'date':
        returnValue = moment(value, 'DD.MM.YYYY').toDate();
        break;
      case 'datetime':
        returnValue = moment(value, 'DD.MM.YYYY HH:mm').toDate();
        break;
      case 'time':
        returnValue = moment(value, ['DD.MM.YYYY HH:mm', 'HH:mm']).toDate();
        break;
    }
    return returnValue;
  }
}
