<div class="content-block bottom-padding" *ngIf="raumbuchEntry && raumbuchEntry.Raufmid">
  <div class="form-block">
    <h2>Maßkette</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box [labelText]="'Aufmaß'" [value]="aufmass.AufName + ' ' + aufmass?.AufmId">
          </app-bss-plain-text-box>

          <app-bss-plain-text-box [labelText]="'Raum'" [value]="raum.Bezeich"> </app-bss-plain-text-box>
        </div>

        <app-bss-suggestion-field
          [headline]="'Wählen Sie eine Flächenbezeichnung'"
          [dataTypeName]="'Flächenbezeichnung'"
          [(value)]="raumbuchEntry.Bezeich"
          [placeholder]="'Flächenbezeichnung'"
          [inputDataSource]="raumnamen"
          [customDisplayExpression]="'Bezeichng'"
        >
        </app-bss-suggestion-field
        ><br />

        <app-aufmass-formel [aufmass]="aufmass" (generatedFormel)="raumbuchEntry.useFormel($event)"></app-aufmass-formel
        ><br />

        <app-bss-switchbox [labelText]="'Nicht berechnen'" [(value)]="raumbuchEntry.NotCalc"> </app-bss-switchbox><br />

        <app-bss-switchbox [labelText]="'Abzug'" [(value)]="raumbuchEntry.IsAbzug"> </app-bss-switchbox><br />

        <app-bss-selectbox
          *ngIf="raumbuchEntry.IsAbzug"
          [labelText]="'Abzug von'"
          [placeholder]="'Bezugsfläche auswählen'"
          [displayExpr]="'Bezeich'"
          [items]="bezugsFlaechen"
          (selectedValue)="selectedBezugsflaeche($event)"
        >
        </app-bss-selectbox
        ><br />

        <button class="bss-button deleteButton" (click)="deleteEntry(raumbuchEntry, aufmass)">Löschen</button>
      </div>
    </div>
  </div>
</div>

<dx-speed-dial-action
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (onClick)="save(raumbuchEntry, currentRaumbuchEntries, raum, aufmass)"
>
</dx-speed-dial-action>
