import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { PageSetting, pageSettings } from 'apps/handwerkPWA/src/app/config/pageSettings';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { Right } from 'libs/shared/src/lib/entities';
import { DialogService } from '@handwerk-pwa/shared';
import {
  featureUnavailableLong,
  featureUnavailableShort,
  noLicenceLong,
  noLicenceShort,
  noRightLong,
  noRightShort,
} from './config/TextKonstanten';
import { StateService } from './services/globalServices/state.service';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService implements CanActivate {
  private defaultPage = 'startseite';
  private loginPage = 'Login';

  constructor(
    private routingService: RoutingService,
    private rechteService: RechteService,
    private authorisationService: AuthorisationService,
    private dialogService: DialogService,
    private stateService: StateService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const isLoggedIn = this.stateService.loggedIn.value;
    const isAuthPage = [
      'Login/UUID/:uuid/Mandant/:mandant/Employeenumber/:employeeNumber/Branding/:branding',
      'Login',
    ].includes(route.routeConfig.path);
    const isNoLoginNeededPage = ['impressum'].includes(route.routeConfig.path);

    if (isLoggedIn && isAuthPage) {
      await this.routingService.navigateTo(this.defaultPage);
      return false;
    }

    if (!isLoggedIn && !isAuthPage && !isNoLoginNeededPage) {
      await this.routingService.navigateTo(this.loginPage);
      return false;
    }

    const pageSetting = this.getPagesetting(route);
    const currentRights = this.rechteService.getCurrentRightAsync().getValue();

    const hasRight = this.checkRightsForPage(currentRights, pageSetting);

    const hasFeature = this.checkFeatureAvailable(pageSetting);

    const hasLicence = this.checkLicenceForPage(currentRights, pageSetting);

    await this.notifyUserOnError(hasRight, hasFeature, hasLicence);

    const continueNavigation = hasRight && hasFeature && hasLicence;
    if (!continueNavigation) {
      await this.routingService.navigateTo(this.defaultPage);
    }

    return continueNavigation;
  }

  /**@description Benachrichtigt den Nutzer über einen Fehler, bei mehreren nur den schwerwiegensten */
  private async notifyUserOnError(hasRight: boolean, hasFeature: boolean, hasLicence: boolean): Promise<void> {
    const noError = hasRight && hasFeature && hasLicence;
    if (noError) return;
    let errorTitle = noRightShort;
    let errorText = noRightLong;
    if (!hasLicence) {
      errorTitle = noLicenceShort;
      errorText = noLicenceLong;
    }
    if (!hasFeature) {
      errorTitle = featureUnavailableShort;
      errorText = featureUnavailableLong;
    }
    await this.dialogService.openErrorMessage(errorTitle, errorText);
  }

  private getPagesetting(route: ActivatedRouteSnapshot): PageSetting {
    const currentPath = route.routeConfig.path;
    const pageSetting = pageSettings.find(setting => setting.path === currentPath);
    return pageSetting;
  }

  /**@description Sucht nach dem Recht des jeweiligen Pagesettings, anderfalls default true */
  private checkRightsForPage(currentRights: Right, pageSetting: PageSetting): boolean {
    if (pageSetting) return currentRights.hasRightOrSettingEnabled(pageSetting.right_name);
    return true;
  }

  /**@description Prüft ob das Feature möglich ist aufgrund des aktuellen Stands */
  private checkFeatureAvailable(pageSetting: PageSetting): boolean {
    const authorisation = this.authorisationService.current.getValue();
    if (pageSetting) return authorisation.featureCheck(pageSetting.featurename).available;
    return true;
  }

  /**@description Ist die Lizenz an, true, ansonsten wird geprüft ob, sie gebraucht wird*/
  private checkLicenceForPage(currentRights: Right, pageSetting: PageSetting) {
    const licenceActive = currentRights.hasRightOrSettingEnabled('showObjektadressen');
    if (licenceActive) return true;
    if (pageSetting && pageSetting.licenceNeeded) return false;
    return true;
  }
}
