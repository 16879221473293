import { Component, OnDestroy, OnInit } from '@angular/core';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { RepairOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrder.service';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { ServiceauftraegeService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceauftraege.service';
import { BaseAuftrag, BaseDocumentPosition } from 'apps/handwerkPWA/src/app/interfaces';
import {
  Dokumentposition,
  ServiceAuftrag,
  HWRepairOrderItem,
  PositionsArt,
  HWRepairOrder,
} from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { readonlyPosition } from '../../../config/TextKonstanten';
import { Subscription } from 'rxjs';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';

@Component({
  selector: 'app-order-item-edit',
  templateUrl: './order-item-edit.component.html',
  styleUrls: ['./order-item-edit.component.scss'],
})
export class OrderItemEditComponent implements OnInit, OnDestroy {
  currentItem: BaseDocumentPosition;
  order: BaseAuftrag;
  menge: number;
  einzelpreisDescription: string;
  gesamtPreisDescription: string;
  userInfo: Userinfo;
  leistungsPositionen: BaseDocumentPosition[];
  currentType: PositionsArt;
  einzelPreis: number;
  gesamtPreis: number;
  showPrices: boolean;
  orderType: string;
  orderId: string;
  itemId: string;
  editable: boolean;
  readonlyPositionText = readonlyPosition;
  saveSubscription: Subscription;

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private repairOrderService: RepairOrderService,
    private dialogService: DialogService,
    private serviceauftragsService: ServiceauftraegeService,
    private routingService: RoutingService,
    private rightService: RechteService
  ) {}

  async ngOnInit(): Promise<void> {
    this.saveSubscription = this.routingService.save.subscribe(nextRoute => void this.saveChanges(nextRoute));

    this.userInfo = await this.globalSettingService.getUserinfo();

    this.editable = await this.loadOrder(this.userInfo);
    this.loadItem();

    const showPricesRight = this.rightService.hasRightOrSettingEnabled('showPrices');
    this.showPrices = this.order.getShowPrices(showPricesRight);
    this.getPrices(this.currentItem, this.order.getKunde().priceGroup);
    this.currentType = this.currentItem.getLongtype();
    this.einzelpreisDescription = this.currentItem.getEinzelpreisDescription();
    this.gesamtPreisDescription = this.currentItem.getGesamtpreisDescription();
    this.leistungsPositionen = this.order.getUnterpositionenOfLeistung(this.currentItem);
  }

  async loadOrder(userInfo: Userinfo): Promise<boolean> {
    this.orderType = this.routingService.getRouteParam('type');
    this.orderId = this.routingService.getRouteParam('id');

    if (this.orderType === 'reparaturauftrag') {
      this.order = await this.repairOrderService.findOneByGuid(this.orderId);
    } else if (this.orderType === 'wartungsauftrag') {
      this.order = await this.serviceauftragsService.findOneBy('UUID', this.orderId);
    }
    return this.order.isEditable(userInfo);
  }

  loadItem(): void {
    this.itemId = this.routingService.getRouteParam('position_uuid');
    this.currentItem = this.order.findOrderItemByUniqueIdentifier(this.itemId);
  }

  /**@description Wird von alle Änderungen aufgerufen */
  korrektur(
    currentItem: BaseDocumentPosition,
    order: BaseAuftrag,
    neuerWert: number | string,
    changeType: 'Preis' | 'Zeit' | 'Menge' | 'Text',
    isGesamtpreis?: boolean
  ): void {
    const isRepairOrder = order.auftragsArt === 'Reparaturauftrag';
    const currentCastedItem = isRepairOrder ? (currentItem as HWRepairOrderItem) : (currentItem as Dokumentposition);
    const castedItemInOrder = order.findOrderItem(currentCastedItem);
    castedItemInOrder.updateItem(changeType, neuerWert, isGesamtpreis);
    const priceGroup = order.getKunde().priceGroup;
    this.getPrices(castedItemInOrder, priceGroup);
    this.routingService.dataChanged.next(true);
  }

  /**@description Wird durch den Data changed Mechanismus aus dem RoutingService getriggert um einmalig am Ende beim Verlassen bei Änderungen zu speichern */
  async saveChanges(nextRoute: string): Promise<void> {
    const isRepairOrder = this.order.auftragsArt === 'Reparaturauftrag';
    const isServiceauftrag = this.order.auftragsArt === 'Serviceauftrag';
    if (isRepairOrder)
      await this.repairOrderService.sendRepairOrderToWebservice(this.userInfo, this.order as HWRepairOrder);
    if (isServiceauftrag)
      await this.serviceauftragsService.sendServiceorderToWebservice(this.userInfo, this.order as ServiceAuftrag, true);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    void this.routingService.navigateTo(nextRoute);
  }

  private getPrices(castedItemInOrder: BaseDocumentPosition, priceGroup: string) {
    this.einzelPreis = castedItemInOrder.getEinzelDisplayprice(priceGroup);
    this.gesamtPreis = castedItemInOrder.getGesamtDisplayPrice(priceGroup);
  }

  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }
}
