import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { base64Pdf } from 'apps/handwerkPWA/src/app/entities';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-unterschrift',
  templateUrl: './unterschrift.component.html',
  styleUrls: ['./unterschrift.component.scss'],
})
export class UnterschriftComponent {
  signaturePadOptions: any;
  @Input() unterschriftPerson;
  @Input() showPopup = false;
  @Output() stopEvent = new EventEmitter<boolean>();
  @Output() unterschriftBase64 = new EventEmitter<base64Pdf>();
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  width: number;
  height: number;

  constructor() {
    this.width = screen.width;
    this.height = screen.height;
    this.signaturePadOptions = {
      // passed through to szimek/signature_pad constructor
      minWidth: 1,
      canvasWidth: this.width,
      canvasHeight: this.height,
    };
    window.addEventListener('orientationchange', () => {
      this.resizeCanvas();
    });
  }

  /**@description Bei Orientationchange wird das Canvas resized ( kein Tippfehler, die Zuweisungen sind so richtig) */
  private resizeCanvas(): void {
    this.clearCanvas();
    const height = this.height;
    const width = this.width;
    const canvas = this.getCanvas();

    if (isNullOrUndefined(canvas)) return;
    if (window.innerHeight > window.innerWidth) {
      canvas.height = width;
      canvas.width = height;
      this.signaturePadOptions.height = width;
      this.signaturePadOptions.width = height;
    } else {
      canvas.height = height;
      canvas.width = width;
      this.signaturePadOptions.height = height;
      this.signaturePadOptions.width = width;
    }
  }

  back(): void {
    this.clearCanvas();
    this.stopEvent.emit(true);
  }

  createUnterschrift(auftragsPerson: string): void {
    const canvas = this.getCanvas();
    const context = this.getContext();
    const isLandscape = canvas.width > canvas.height;
    this.putUnterschriftPersonIntoCanvas(canvas, context, auftragsPerson, isLandscape);
    const unterschriftBase64 = canvas.toDataURL();
    const base64PdfItem = new base64Pdf();
    base64PdfItem.data = unterschriftBase64;
    base64PdfItem.landScape = isLandscape;
    this.unterschriftBase64.emit(base64PdfItem);
    this.stopEvent.emit(true);
  }

  private getCanvas(): HTMLCanvasElement {
    const canvas = document.getElementsByTagName('canvas')[0];
    return canvas;
  }

  private getContext(): CanvasRenderingContext2D {
    const canvas = this.getCanvas();
    if (!canvas) return null;
    const context = canvas.getContext('2d');
    return context;
  }

  private clearCanvas(): void {
    const canvas = this.getCanvas();
    if (!canvas) return null;
    const context = this.getContext();
    context.clearRect(0, 0, canvas.width, canvas.height);
  }

  private putUnterschriftPersonIntoCanvas(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    auftragsPerson: string,
    isLandscape: boolean
  ): void {
    const canvasHeight = canvas.height;
    const canvasWidth = canvas.width;
    const unterschriftNamePixelSize = isLandscape ? canvasHeight / 7 : canvasWidth / 7;
    context.font = `${unterschriftNamePixelSize}px Arial`;
    const nameSplit = auftragsPerson ? auftragsPerson.split(' ').reverse() : '';
    if (!isLandscape) {
      let index = 1;
      for (const word of nameSplit) {
        context.fillText(word, 1, canvasHeight - unterschriftNamePixelSize * index, canvasWidth);
        index = index + 1;
      }
    } else context.fillText(auftragsPerson, 1, canvasHeight - unterschriftNamePixelSize, canvasWidth);

    context.scale(3, 1);
  }

  stylePopup(e) {
    e.component.content().style.padding = '0';
  }
}
