import { isNullOrUndefined } from './globalHelper';

export function removeArithmethic(toCheck: string): string {
  const numeric = new RegExp('[0-9]', 'g');
  toCheck = toCheck.replaceAll(numeric, '');
  const metaCharacters = ['[', ']', '(', ')', '*', '/', '+', '-', ',', '.'];
  for (const character of metaCharacters) toCheck = toCheck.replaceAll(character, '');
  toCheck = toCheck.replaceAll(' ', '');
  return toCheck;
}

/**@description Prüft ob nur zeichen einer formel enhtalten sind */
function couldBeFormula(toCheck: string): boolean {
  toCheck = removeArithmethic(toCheck);
  return toCheck === '';
}

/**@description Prüft ob eine valide Formel aus der berechnet werden kann vorliegt */
export function validateFormula(toCheck: string): boolean {
  if (isNullOrUndefined(toCheck)) return false;
  if (!couldBeFormula(toCheck)) return false;
  let evaluated = NaN;
  try {
    evaluated = eval(toCheck?.replaceAll(',', '.').replaceAll('[', '').replaceAll(']', '')) as number;
  } catch (err) {
    return false;
  }
  return !isNaN(evaluated);
}
