<div class="content-block bottom-padding" *ngIf="objectaddress">
  <div class="form-block">
    <h2>Objektadresse</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box [labelText]="'Kunde'" [value]="objectaddress.Kunde.NAME"></app-bss-plain-text-box>
        </div>
        <div class="fieldbox">
          <app-bss-textbox [labelText]="'Standort 01'" [(value)]="objectaddress.STANDORT1"></app-bss-textbox>
          <app-bss-textbox [labelText]="'Standort 02'" [(value)]="objectaddress.STANDORT2"></app-bss-textbox>
          <app-bss-textbox [labelText]="'Name'" [(value)]="objectaddress.Name"></app-bss-textbox>
          <app-bss-textbox
            [labelText]="'Straße'"
            [buttonIcon]="'map'"
            [(value)]="objectaddress.STRASSE"
            [buttonEnabled]="objectaddress?.STRASSE !== '' && objectaddress?.ORT !== ''"
            (buttonClick)="openMaps(objectaddress)"
          ></app-bss-textbox>
          <app-bss-textbox [labelText]="'Postleitzahl'" [(value)]="objectaddress.PLZ" [maxLength]="5"></app-bss-textbox>
          <app-bss-textbox [labelText]="'Ort'" [(value)]="objectaddress.ORT"></app-bss-textbox>
          <app-bss-selectbox
            [labelText]="'Land'"
            [items]="laender"
            [value]="objectaddress.LAND"
            (selectedValue)="selectedACountry($event)"
          ></app-bss-selectbox>
          <app-bss-textbox
            [labelText]="'Telefon'"
            [(value)]="objectaddress.Telefon"
            [buttonEnabled]="objectaddress?.Telefon != ''"
            [buttonIcon]="'tel'"
          ></app-bss-textbox>
        </div>
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Weitere Informationen</div>

          <app-bss-toggle-textarea
            [buttonText]="'Bemerkungen'"
            [(value)]="objectaddress.BEMERKUNG"
          ></app-bss-toggle-textarea>

          <app-bss-button-link
            [title]="'Zusatz'"
            [customIcon]="'assets/icons/dreieck.svg'"
            (click)="showZusaetze = !showZusaetze"
            [customIconClass]="showZusaetze ? 'rotated180 dreieck' : ''"
          ></app-bss-button-link>
          <div *ngIf="showZusaetze" style="margin-bottom: 10px">
            <div *ngFor="let zusatz of objectaddress.Zusatz; let i = index">
              <app-bss-plain-text-box
                *ngIf="zusatz != ''"
                [labelText]="objektZusatzBezeichnungen[i]"
                [value]="objectaddress.Zusatz[i]"
              ></app-bss-plain-text-box>
            </div>
          </div>

          <div *ngIf="!isNewObjectAddress()" class="linkBox">
            <app-bss-button-link
              [title]="'Medien'"
              [path]="'medien/ObjectAdress/' + objectaddress.Guid"
              [anzahl]="currentFilesAmount"
            ></app-bss-button-link>
            <app-bss-button-link [title]="'Zum Kunden'" [path]="kundenPath"></app-bss-button-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-speed-dial-action
  [visible]="dataChanged"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (onClick)="save()"
></dx-speed-dial-action>

<dx-speed-dial-action
  [visible]="!dataChanged"
  icon="add"
  class="add-button"
  label="Neuer Reparaturauftrag"
  [index]="2"
  (onClick)="navigateTo('reparaturauftrag/neu/objektadresse/' + objectaddress.Guid)"
></dx-speed-dial-action>
<dx-speed-dial-action
  [visible]="!dataChanged"
  icon="photo"
  class="add-button"
  label="Neues Medium"
  [index]="1"
  (onClick)="newImages()"
></dx-speed-dial-action>
