<div (click)="showPopupClicked(disabled)" [ngClass]="buttonStyle" [ngStyle]="{ opacity: disabled ? '0.3' : '1' }">
  <div *ngIf="!icon">{{ buttonText }}</div>
  <dx-button *ngIf="icon" [icon]="icon"></dx-button>
</div>

<dx-popup
  [width]="'100%'"
  [maxWidth]="'500px'"
  [title]="headline"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
>
  <div *dxTemplate="let data of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="inputDataSource"
      [searchExpr]="customSearchExpression"
      class="popup-card-list"
    >
      <div *dxTemplate="let item of 'item'; let i = index">
        <div class="no-margin-p" (click)="onItemClick(item)">
          <div style="display: grid" *ngIf="datasourceIconPath">
            <b style="margin: auto" *ngIf="getPropertyValue(item, 0)">{{ getPropertyValue(item, 0) }}</b>
            <img style="margin: auto" [src]="item[datasourceIconPath]" width="35" height="35" />
          </div>
          <b *ngIf="!datasourceIconPath && getPropertyValue(item, 0)"
            >{{ firstLineLabel }}{{ getPropertyValue(item, 0) }}</b
          >
          <p *ngIf="getPropertyValue(item, 1)">{{ getPropertyValue(item, 1) }}</p>
          <p *ngIf="getPropertyValue(item, 2)">{{ getPropertyValue(item, 2) }}</p>
          <p *ngIf="getPropertyValue(item, 3)">{{ getPropertyValue(item, 3) }}</p>
          <p *ngIf="getPropertyValue(item, 4)">{{ getPropertyValue(item, 4) }}</p>
        </div>
      </div>
    </dx-list>
  </div>
</dx-popup>
