import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { Sliderside } from '../../config/Konstanten';

export class AppOnlySettings {
  slidersSetting: Array<{ name: Sliderside; value: boolean }> = [
    { name: 'Adressen', value: true },
    { name: 'Reparaturaufträge', value: true },
    { name: 'Termine', value: true },
    { name: 'W&S Anlagen', value: true },
    { name: 'W&S Aufträge', value: true },
    { name: 'Raumaufmaße', value: true },
  ];
  /**Intervall des Background-Syncs in Minuten, 0 = aus */
  backgroundSyncInterval = 5;
  aufmassRasterFactor = 50;
  aufmassAutoFillRectangleRooms = true;
  aufmassGridOnStart = true;
  constructor(previousData: AppOnlySettings) {
    assignIfPropertyExsits(this, previousData);
  }
}
