import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HTMLInputEvent, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BarcodeFormat } from '@zxing/library';
import { readFileAsync, qrImageToString } from 'apps/handwerkPWA/src/app/helper/components/qrcodeHelper';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent {
  constructor(private dialogService: DialogService) {}
  @Input() showHiddenUpload = false;
  // QR Properties für die Funktion des QR-Scanners selbst
  @Output() stopEvent = new EventEmitter<boolean>();
  @Output() scanResult = new EventEmitter<Userinfo>();
  desiredDevice: MediaDeviceInfo;
  nthDevice = 0;
  possibleDevices: MediaDeviceInfo[];
  cameraSwitchUrl = 'assets/icons/camera_switch.svg';
  @ViewChild('uploader', { static: false }) uploader: ElementRef<HTMLInputElement>;
  codeFormats: BarcodeFormat[] = [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128];
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  /**@description Klickt den Uploader */
  triggerFileUpload(): void {
    this.uploader.nativeElement.click();
  }

  /**@description Funktion wenn ein Foto statt eines Video-Streams zum Auswerten eines QRCodes verwendet wird */
  async processQrImage(event: HTMLInputEvent | Event): Promise<void> {
    void this.dialogService.openLoadingDialog('QR-Code', '...Verarbeite aktuellen Qr-Code...');
    const file = (event as HTMLInputEvent).target.files[0];
    if (isNullOrUndefined(file)) {
      this.dialogService.closeLoadingDialog();
      return;
    }
    const fileData = await readFileAsync(file);
    try {
      const qrString = await qrImageToString(fileData);
      this.scanSuccessHandler(qrString);
    } catch {
      this.dialogService.closeLoadingDialog();
      void this.dialogService.openErrorMessage(
        'Fehler',
        'Beim Auslesen des QR-Codes ist ein Fehler aufgetreten. Versuchen Sie es erneut'
      );
    }
  }

  /**
   * @description Togglefunktion für den Button zum Scannen.
   */
  stopScanning(): void {
    this.scanner.scanStop();
    this.stopEvent.emit(false);
  }

  /**
   * @description Funktion für einen erfolgreichen Scan. Hier wird geprüft, ob mit den
   *              gescannten Daten eine Allokation geholt werden kann. Zudem wird hier
   *              geprüft ob mit den gescannten Daten ein Login möglich ist.
   *              Ist dies der Fall, werden die gescannten Daten in globalen Properties
   *              hinterlegt und es wird in den nächsten View weitergeleitet.
   * @param       qrCodeValue: event
   */
  scanSuccessHandler(qrCodeValue: string): void {
    this.dialogService.closeLoadingDialog();
    void this.dialogService.openLoadingDialog('QR-Code', '...Werte aktuellen Qr-Code aus...');
    this.stopScanning();
    const qrAsJson = this.parseQrInJson(qrCodeValue);
    const userInfo = new Userinfo(qrAsJson);
    this.scanResult.emit(userInfo);
    this.dialogService.closeLoadingDialog();
    return;
  }

  /**
   * @description Hier wird geprüft ob die im QR-Code enthaltenen Daten in JSON geparst werden können.
   * @param       qrCodeValue: event
   */
  parseQrInJson(qrCodeValue: string): Userinfo {
    try {
      return JSON.parse(qrCodeValue) as Userinfo;
    } catch (err) {
      return null;
    }
  }

  /**
   * @description   Funktion um Kamera auf der Rückseite des Gerätes auszuwählen und alle möglichen Kameras in einem Array zu speichern.
   * @param cameras:  Kamera-Informationen
   */
  camerasFoundHandler(cameras: MediaDeviceInfo[]): void {
    this.possibleDevices = cameras;
    const backCamera = cameras.find(camera => camera?.label?.toLowerCase()?.includes('back'));
    if (backCamera) this.desiredDevice = backCamera;
    else this.desiredDevice = cameras[0];
  }

  /**
   * @description Wird aufgerufen, wenn der Nutzer den Kamera-Wechsel im Scan betätigt - versucht durch die möglichen Geärte zu iterieren
   * Aktuell ist die Kamera an der Stelle nthDevice, deswegen nthDevice erhöhen, gucken ob es noch Device ist, ansonsten nthDevice auf 0 setzen
   * und Iterierung wieder bei 0 beginnen
   */
  switchBetweenDevices(): void {
    this.nthDevice++;
    if (isNullOrUndefined(this.possibleDevices[this.nthDevice])) this.nthDevice = 0;
    this.desiredDevice = this.possibleDevices[this.nthDevice];
  }
}
