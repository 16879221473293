import moment from 'moment';
import { IndexedDBTypes } from './dbType';
import {
  assignIfPropertyExsits,
  convertToRoundedDisplayString,
  isNullOrUndefined as isNullOrUndefinedOrEmptryString,
  isNullOrUndefinedOrEmptyString,
} from 'libs/shared/src/lib/helper/globalHelper';
import { AuftragsArt, BaseAuftrag, BaseDocumentPosition, UuidEntity } from 'apps/handwerkPWA/src/app/interfaces';
import {
  deDatettimeFormatWithSeconds,
  StuecklistenGlobalPrintSetting,
} from 'apps/handwerkPWA/src/app/config/Konstanten';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { HWAddress } from './HWAddress';
import { HWAnlage } from './HWAnlage';
import { comparePositionen, HWRepairOrderItem } from './HWRepairOrderItem';
import { HWTermin } from './HWTermin';
import { Druckinformationen } from 'apps/handwerkPWA/src/app/entities/models/Druckinformationen';
import { Steuer } from 'apps/handwerkPWA/src/app/entities/models/Steuer';
import { Dokumentposition } from 'apps/handwerkPWA/src/app/entities/models/Dokumentposition';
import { DragEvent } from '../../interfaces/DragEvent';
export class ServiceAuftrag extends IndexedDBTypes.DbType implements BaseAuftrag, UuidEntity {
  @IndexedDBTypes.KlassenName('ServiceAuftrag') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') UUID: string = null;
  @IndexedDBTypes.DataField('string') Auftragsnummer: string = null;
  @IndexedDBTypes.DataField('string') Dokumentid: string = null;
  @IndexedDBTypes.DataField('string') auftragsArt: AuftragsArt = 'Serviceauftrag';
  /**Private damit man zum Aufruf nur den Getter verwendet,die Objekte AUfbaut damit Objektmethoden existieren */
  @IndexedDBTypes.DataField('Dokumentposition[]') private Positionen: Dokumentposition[] = [];
  @IndexedDBTypes.DataField('string') PreiseAnzeigen = true;
  @IndexedDBTypes.DataField('string') objectType: 'HWRepairOrder' | 'HWTermin' | 'ServiceAuftrag' = 'ServiceAuftrag';
  @IndexedDBTypes.DataField('string') private Monteur1: string = null;
  @IndexedDBTypes.DataField('string') private Monteur2: string = null;
  @IndexedDBTypes.DataField('string') private Zusatzmonteurliste: string = null;
  @IndexedDBTypes.DataField('number') private Status: 20 | 40 | 60 | 80 = 20; // eigentlich existieren noch Status 0(nicht mobil),(90 abrechnungsBereit) und (100 abgerechnet)
  @IndexedDBTypes.DataField('string') Terminid: string = null;
  @IndexedDBTypes.DataField('string') Medien: string = null;
  @IndexedDBTypes.DataField('string') Pdf: string = null;
  @IndexedDBTypes.DataField('string') Anlage: string = null;
  @IndexedDBTypes.DataField('string') Projekt: string = null;
  @IndexedDBTypes.DataField('string') Geaendert: string = null;
  @IndexedDBTypes.DataField('boolean') finalizeFailed = false;
  @IndexedDBTypes.DataField('string') Projektuuid: string = null;
  @IndexedDBTypes.DataField('string') Dokumentuuid: string = null;
  @IndexedDBTypes.DataField('string') Projektname: string = null;
  /** True wenn offline und der Auftrag editiert wurde */
  @IndexedDBTypes.DataField('boolean') edited = false;
  Autolohn = null;
  /**Nur zuweisbar, ist nicht teil des Datenbank Objekts */
  AnlageObject: HWAnlage;
  TerminObject: HWTermin;
  /**Objekte aller im Auftrag befindlichen Monteure (Monteur1,Monteur2,Zusatzmonteurliste) */
  MonteurObjects: HWAddress[] = [];

  constructor(data: ServiceAuftrag, monteure: HWAddress[], anlagen: HWAnlage[], termine: HWTermin[]) {
    super();
    assignIfPropertyExsits(this, data);
    this.monteurNumbersToAdresses(monteure);
    this.assignAnlage(anlagen);
    this.assignTermin(termine);
  }
  getUuid(): string {
    return this.UUID;
  }

  getAllMitarbeiternamesInOrder(allAdresses: HWAddress[]): string {
    const monteurIds = isNullOrUndefinedOrEmptyString(this.Monteur2)
      ? `${this.Monteur1},${this.Zusatzmonteurliste}`
      : `${this.Monteur1} ,${this.Monteur2} , ${this.Zusatzmonteurliste}`;
    const mitarbeiter = allAdresses.filter(person => monteurIds.includes(person.KU_NR));
    const mitarbeiterNames = mitarbeiter.map(arbeiter => arbeiter.NAME);
    return mitarbeiterNames.join(', ');
  }

  getSpecificVortext(): string {
    return this.getArbeitsbeschreibung();
  }

  private getArbeitsbeschreibung(): string {
    return this.AnlageObject?.ABESCHR;
  }

  getUnterschriftsperson(): string {
    return this.AnlageObject?.Anlagenstandort?.NAME;
  }

  getTerminId(): string {
    return this.Terminid;
  }

  finalizeHasFailed(): boolean {
    return this.finalizeFailed;
  }

  /**@description Löscht die Position aus dem Array - vergibt anschließend die positionsnummern neu damit keine Lücken entstehen */
  deleteItemFromPositionen(itemToDelete: Dokumentposition): Dokumentposition[] {
    const positionen = this.getPositionen();
    const uniqueItemIdentifier = itemToDelete.getUniqueIdentifier();
    const leistungsId = itemToDelete.getLongtype() === 'Leistung' ? itemToDelete.getLeistungsId() : 'noValidId';
    let deleteIndex = 0;
    while (deleteIndex !== -1) {
      deleteIndex = positionen.findIndex(findPosi =>
        this.isPositionOrUnterposition(uniqueItemIdentifier, findPosi, leistungsId)
      );
      if (deleteIndex !== -1) positionen.splice(deleteIndex, 1);
    }
    const posNrPositionen = positionen.filter(pos => pos.isPostenWithPosNr());
    let newindex = 1;
    posNrPositionen.forEach(posi => {
      posi.PosNr = newindex.toString();
      newindex++;
    });
    return positionen;
  }

  /**@description Gibt true zurück wenn es sich um die gesuchte Position oder eine Unterposition der gesuchten Position handelt */
  private isPositionOrUnterposition(
    uniqueItemIdentifier: string,
    findPosi: Dokumentposition,
    leistungsId: string
  ): unknown {
    return uniqueItemIdentifier === findPosi.getUniqueIdentifier() || findPosi.getLeistungsId() === leistungsId;
  }

  assignPdfDatauri(dataUri: string): void {
    this.Pdf = dataUri;
  }

  findOrderItem(inputItem: BaseDocumentPosition): Dokumentposition {
    const item = inputItem as Dokumentposition;
    const allPositions = this.getPositionen();
    const findItem = allPositions.find(searchItem => searchItem.LineID === item.LineID);
    return findItem;
  }

  findOrderItemByUniqueIdentifier(uniqueIdentifier: string): Dokumentposition {
    const allPositions = this.getPositionen();
    const findItem = allPositions.find(searchItem => searchItem.getUniqueIdentifier() === uniqueIdentifier);
    return findItem;
  }

  addPosition(item: HWRepairOrderItem): void {
    const documentPosition = new Dokumentposition(item);
    documentPosition.PosNr = this.calculateNextPosNumber(this, documentPosition);
    documentPosition.LineID = this.getAndSetNextlineId();
    this.setPositionen(this.getPositionen(), documentPosition);
    if (documentPosition.getLongtype() === 'Leistung') {
      documentPosition.setLeistungsID();
      const leistungsPositionen = item.getTransferredLeistungspositionen();
      this.addLeistungspositionen(leistungsPositionen, documentPosition.getLeistungsId());
    }
  }

  private addLeistungspositionen(leistungsPositionen: HWRepairOrderItem[], leistungsId: string): void {
    for (const position of leistungsPositionen) {
      const documentPosition = new Dokumentposition(position);
      documentPosition.ID = documentPosition.ID.toLocaleLowerCase();
      documentPosition.PosNr = null;
      documentPosition.LineID = this.getAndSetNextlineId();
      documentPosition.setLeistungsID(leistungsId);
      this.setPositionen(this.getPositionen(), documentPosition);
    }
  }

  /**@description Textpositionen haben keine PosNr - ansonsten die nächstgrößere Zahl,beginnend bei 1 falls keine Zahl bisher vorhanden ist */
  private calculateNextPosNumber(auftrag: ServiceAuftrag, newItem: Dokumentposition): string {
    const type = newItem.getLongtype();
    if (type === 'Textposition') return '';
    const currentPositionen = auftrag.getPositionen()?.filter(pos => pos.isPostenWithPosNr());
    const currentPosNumbers = currentPositionen.map(pos => parseInt(pos.PosNr, 10));
    const nextPosNumber = Math.max(...currentPosNumbers) + 1;
    if (nextPosNumber === -Infinity) return '1';
    return nextPosNumber.toString();
  }

  private assignTermin(termine: HWTermin[]) {
    const correctTermin = termine?.find(termin => termin.id === this.Terminid);
    this.TerminObject = correctTermin;
  }

  private assignAnlage(anlagen: HWAnlage[]) {
    const correctAnlage = anlagen?.find(anlage => anlage.ANLAGE === this.Anlage);
    this.AnlageObject = correctAnlage;
  }

  getKunde(): HWAddress {
    return this.AnlageObject.Anlagendaten.Kundenobjekt;
  }

  getSortDate(): Date {
    return moment(this.TerminObject?.start, deDatettimeFormatWithSeconds).toDate();
  }

  getDisplayPositions(withUnterposition?: boolean): Dokumentposition[] {
    const arbeitsbeschreibung = this.getArbeitsbeschreibung();
    const displayPositionen = this.getPositionen()?.filter(pos =>
      pos.isRechnungsposten(withUnterposition, arbeitsbeschreibung)
    );
    return displayPositionen.sort(comparePositionen);
  }

  getDruckinformationen(kunde: HWAddress): Druckinformationen {
    const datum = this.TerminObject?.start?.substring(0, 10);
    return new Druckinformationen(kunde.ORT, kunde.NAME, datum);
  }

  getKundennummer(): string {
    return this.getKunde()?.KU_NR;
  }

  /**@description Guckt was die aktuell höchste Positionsnumemr eines Auftrags ist und zählt danach weiter */
  calculateNextPosNr(): number {
    const positionen = this.getPositionen();
    const artikelPositionen = positionen.filter(searchPosition => searchPosition.ID === 'M');
    let highestPosNr = 0;
    for (const position of artikelPositionen) {
      const currentPosNr = parseInt(position.PosNr, 10);
      if (currentPosNr > highestPosNr) {
        highestPosNr = currentPosNr;
      }
    }
    return highestPosNr + 1;
  }

  getShowPrices(canShow: boolean): boolean {
    return canShow;
  }

  getUnterpositionenOfLeistung(leistung: BaseDocumentPosition): Dokumentposition[] {
    if (leistung.getLongtype() !== 'Leistung') return [];
    const positionen = this.getPositionen();
    const leistungsPositionen = positionen?.filter(position => this.isUnterpositionOfLeistung(position, leistung));
    return leistungsPositionen;
  }

  private isUnterpositionOfLeistung(position: Dokumentposition, leistung: BaseDocumentPosition): boolean {
    const matchingLeistungsIds = position.getLeistungsId() === leistung.getLeistungsId();
    const positionNotLeistung = position.getLongtype() !== 'Leistung';
    return matchingLeistungsIds && positionNotLeistung;
  }

  /**@description Toggled die anzeige des leistungskopfes und rekursiv die der zur leistung gehörenden stücklistenitems */
  toggleStuecklistenprint(leistungsData: Dokumentposition): void {
    if (leistungsData.getLongtype() !== 'Leistung') return;
    const leistung = this.getPositionen().find(pos => leistungsData.LineID === pos.LineID);
    leistung.toggleStuecklistenPrint();
    const leistungsStuecklistenitems = this.getPositionen().filter(position =>
      this.isUnterpositionOfLeistung(position, leistung)
    );
    leistungsStuecklistenitems.forEach(position => position.toggleStuecklistenPrint());
  }

  /**@description Liest die aktuell zu verwendende LineID aus den metadaten und erhöht diese direkt */
  getAndSetNextlineId(): number {
    const metaData = this.getPositionen().find(position => position.PosNr === this.Projektname);
    const nextLineId = metaData.LineID;
    metaData.LineID = nextLineId + 1;
    return nextLineId;
  }

  getPrintPositions(): Dokumentposition[] {
    const displayPositionen = this.getDisplayPositions(true);
    const printPositions = displayPositionen.filter(position => position.shouldBePrinted() === true);
    return printPositions;
  }

  /**@description Berechnet die Nettosumme, die Steuersumme und die Gesammtsumme */
  calculateItemSums(Steuern: Steuer[]): { CompleteSum: string; TaxedSum: string; NettoSum: string } {
    const items = this.getPositionen();
    let nettoSum = 0;
    let taxedSum = 0;
    for (const item of items) {
      if (item.isUnterposition()) continue;
      const { gesammtPreis, steuerPreis } = item.calculateVerkaufspreisSteuerpreis(item, Steuern);
      nettoSum += gesammtPreis;
      taxedSum += steuerPreis;
    }
    const completeSum = nettoSum + taxedSum;
    const completeSumString = convertToRoundedDisplayString(completeSum);
    const taxedSumString = convertToRoundedDisplayString(taxedSum);
    const nettoSumString = convertToRoundedDisplayString(nettoSum);
    return { CompleteSum: completeSumString, TaxedSum: taxedSumString, NettoSum: nettoSumString };
  }

  /**@description Überträgt die Liste der Monteurnummern in ihre Namen */
  getPdfPrintEmployeeNames(employees: HWAddress[]): string {
    let employeeNumbers = [];
    if (!isNullOrUndefinedOrEmptryString(this.Monteur1)) employeeNumbers.push(this.Monteur1);
    if (!isNullOrUndefinedOrEmptryString(this.Monteur2)) employeeNumbers.push(this.Monteur2);
    if (!isNullOrUndefinedOrEmptryString(this.Zusatzmonteurliste)) {
      const employeeListNumberArray = this.Zusatzmonteurliste.split(',');
      employeeNumbers = employeeNumbers.concat(employeeListNumberArray);
    }
    const EmployeeInOrder = employees.filter(employee => employeeNumbers.includes(employee.KU_NR));
    const EmployeeNameArray = EmployeeInOrder.map(employee => employee.NAME);
    return EmployeeNameArray.join(', ');
  }

  getPositionen(): Dokumentposition[] {
    this.Positionen = this.Positionen.map(posData => new Dokumentposition(posData));
    return this.Positionen;
  }

  setPositionen(items: Dokumentposition[], itemToAdd?: Dokumentposition): void {
    this.Positionen = items;
    if (itemToAdd) this.Positionen.push(itemToAdd);
  }

  private getAllLeistungen(): Dokumentposition[] {
    const positionen = this.getPositionen();
    return positionen?.filter(position => position.getLongtype() === 'Leistung');
  }

  setAllStuecklistenPrintsettings(stuecklistenChoiceName: StuecklistenGlobalPrintSetting): void {
    const leistungen = this.getAllLeistungen();
    const currentPrintLeistungenWithStueckliste = leistungen?.filter(
      leistung => leistung.getPrintStueckliste() === true
    );
    const currentPrintLeistungenWithoutStueckliste = leistungen?.filter(
      leistung => leistung.getPrintStueckliste() === false
    );
    switch (stuecklistenChoiceName) {
      case 'Individuell':
        return;
      case 'An': {
        currentPrintLeistungenWithoutStueckliste.forEach(leistung => {
          const orderPosition = this.findOrderItem(leistung);
          this.toggleStuecklistenprint(orderPosition);
        });
        return;
      }
      case 'Aus': {
        currentPrintLeistungenWithStueckliste.forEach(leistung => {
          const orderPosition = this.findOrderItem(leistung);
          this.toggleStuecklistenprint(orderPosition);
        });
        return;
      }
    }
  }

  /**@description Ordnet anhand der Monteurnummern die korrekten Adressobjekte der Monteure zu */
  private monteurNumbersToAdresses(monteure: HWAddress[]) {
    let monteurNumberArray = [];
    if (this.Monteur1) monteurNumberArray.push(this.Monteur1);
    if (this.Monteur2) monteurNumberArray.push(this.Monteur2);
    if (!isNullOrUndefinedOrEmptyString(this.Zusatzmonteurliste)) {
      const zusatzMonteurNumberArray = this.Zusatzmonteurliste.split(',');
      monteurNumberArray = monteurNumberArray.concat(zusatzMonteurNumberArray);
    }
    const correctMonteure = monteure.filter(monteur => monteurNumberArray.includes(monteur.KU_NR));
    this.MonteurObjects = correctMonteure;
  }

  /**@description Schreibt die MonteurNummern eines neuen Adressarrays als neue MonteurNummern */
  updateAdditionalEmployeeList(zusatzMonteure: HWAddress[]): void {
    zusatzMonteure?.forEach(monteur => this.addToMonteurobjectsIfNecessary(monteur));
    const monteurNumbers = zusatzMonteure?.map(monteur => monteur?.KU_NR);
    this.Zusatzmonteurliste = monteurNumbers?.join(',');
  }

  private addToMonteurobjectsIfNecessary(monteur: HWAddress): void {
    if (this.MonteurObjects.includes(monteur)) return;
    this.MonteurObjects.push(monteur);
  }

  getAllMonteureInOrder(): HWAddress[] {
    return this.MonteurObjects;
  }

  getZusatzMonteurliste(): string {
    return this.Zusatzmonteurliste;
  }

  getZusatzMonteure(): HWAddress[] {
    const allMonteure = this.getAllMonteureInOrder();
    const zusatzMonteurNumbers = this.getZusatzMonteurliste()?.split(',');
    return allMonteure?.filter(monteur => zusatzMonteurNumbers?.includes(monteur.KU_NR));
  }

  setAuftragsstatus(status: 'Complete' | 'Open' | 'Begin'): void {
    switch (status) {
      case 'Open':
        this.Status = 20;
        return;
      case 'Begin':
        this.Status = 60;
        return;
      case 'Complete': {
        this.Status = 80;
        this.finalizeFailed = true; // defensiver Ansatz - ist das abschließen erfolgreich, wird der gesamte ServiceAuftrag aus der idb gelöscht
        return;
      }
    }
  }
  /**@description Nimmt den Auftrag an,indem es den mit dem Monteur versieht */
  acceptOrder(userInfo: Userinfo): void {
    this.Monteur1 = userInfo.monteur;
  }

  /**@description Schaut ob der angegebene Monteur der Userinfo Monteur1 des Auftrags ist */
  isUserMainMonteur(userInfo: Userinfo): boolean {
    const hasMainMonteur = userInfo.monteur === this.Monteur1;
    return hasMainMonteur;
  }

  getNummer(): string {
    return this.Auftragsnummer;
  }

  getBetreff(): string {
    const documentId = this.Dokumentid;
    const metaDataPositon = this.Positionen?.find(position => position.Nummer === documentId);
    return metaDataPositon.KURZTEXT;
  }

  /**@description Der Auftrag darf nur vom Hauptmonteur(Monteur 1) bearbeitet werden wenn der Status in Bearbeitung ist */
  isEditable(userInfo: Userinfo): boolean {
    const mainMonteur = this.isUserMainMonteur(userInfo);
    const inProgress = this.Status === 60;
    return mainMonteur && inProgress;
  }

  getStatusText(): 'Verfügbar' | 'Zugewiesen' | 'In Bearbeitung' | 'Fehler' {
    switch (this.Status) {
      case 20:
        return 'Verfügbar';
      case 40:
        return 'Zugewiesen';
      case 60:
        return 'In Bearbeitung';
      default:
        return 'Fehler';
    }
  }

  reorderPositions(dragEvent: DragEvent): void {
    const arbeitsbeschreibung = this.getArbeitsbeschreibung();
    const positionen = this.getPositionen();
    const rechnungsPositionenAmount = positionen?.filter(pos => pos.isRechnungsposten(true, arbeitsbeschreibung))
      ?.length;
    const displayPositionen = positionen?.filter(pos => pos.isRechnungsposten(false, arbeitsbeschreibung));
    displayPositionen.sort(comparePositionen);
    const element = displayPositionen.splice(dragEvent.fromIndex, 1);
    displayPositionen.splice(dragEvent.toIndex, 0, ...element);
    const lineIds = positionen.map(entry => entry.LineID);
    let newLineId = Math.max(...lineIds) - rechnungsPositionenAmount;
    let index = 1;
    for (const position of displayPositionen) {
      position.PosNr = `${index}`;
      const currentUnterpositionen = positionen.filter(
        pos => pos.isUnterposition() && pos.getLeistungsId() === position.getLeistungsId()
      );
      position.LineID = newLineId;
      for (const unterposition of currentUnterpositionen) {
        newLineId++;
        unterposition.LineID = newLineId;
      }
      newLineId++;
      index++;
    }
    displayPositionen.sort(comparePositionen);
    this.setPositionen(positionen);
  }

  static toString(): string {
    return 'ServiceAuftrag';
  }
}
