import { Userinfo } from 'libs/shared/src/lib/entities';
import { HWAnlage, Messwerttabelle } from '../../entities';

/**@description Fügt der Messung metadaten hinzu und entfernt überflüssige daten */
export function transformMessungForHandwerkDb(
  anlage: HWAnlage,
  Messung: Messwerttabelle,
  userInfo: Userinfo
): Messwerttabelle {
  const sendMessung = new Messwerttabelle(anlage.Anlagendaten, Messung);
  sendMessung['mandant'] = userInfo.mandant;
  sendMessung['monteur'] = userInfo.monteur;
  const messwerte = [];
  for (const eintrag of Messung.MesswertTabellenEintraege) {
    const messwert = eintrag.Messwert;
    messwerte.push(messwert);
  }
  sendMessung['messwerte'] = messwerte;
  delete sendMessung.MesswertTabellenEintraege;
  delete sendMessung.notTransferred;
  return sendMessung;
}

export function getUntransferredMessungen(anlagen: HWAnlage[]): { Anlage: HWAnlage; Messungen: Messwerttabelle[] }[] {
  const anlageAndMessung = [];
  for (const anlage of anlagen) {
    const messwertSatz = anlage.Messwertsatz;
    if (!messwertSatz) {
      continue;
    }
    const userMessungen = messwertSatz.Istwerttabellen;
    const untransferredMessungen = userMessungen.filter(messung => messung.notTransferred === true);
    if (untransferredMessungen.length === 0) {
      continue;
    }
    anlageAndMessung.push({ Anlage: anlage, Messungen: untransferredMessungen });
  }

  return anlageAndMessung;
}
