<div class="content-block bottom-padding">
  <h2>Auftragspositionen</h2>

  <app-bss-list
    *ngIf="sortedItems?.length"
    [id]="'documentPosition'"
    [priceRight]="showPrices"
    [inputDatasource]="sortedItems"
    [searchExpressions]="searchExpressions"
    [secondaryDatasource]="order"
    [userInfo]="userInfo"
    [dragAndDrop]="true"
    (itemDragged)="reorderList($event)"
    [emptyInputDataMessage]="'Es sind keine Auftragspositionen vorhanden'"
  ></app-bss-list>
  <br />
  <div style="background-color: #fff">
    <app-bss-button-link
      [title]="'Auftrag abschließen'"
      [path]="'auftragsabschluss/' + orderType + '/' + orderId"
    ></app-bss-button-link>
  </div>
</div>

<dx-speed-dial-action
  icon="add"
  label="Position hinzufügen"
  [index]="1"
  (onClick)="navigateTo('auftragpositionen/' + orderType + '/' + orderId + '/hinzufuegen')"
></dx-speed-dial-action>
