<div class="content-block bottom-padding" *ngIf="loaded">
  <div class="form-block">
    <h2>{{ headline }}</h2>

    <div class="dx-card responsive-paddings">
      <div class="fieldbox">
        <app-bss-textbox [labelText]="'Name'" [placeholder]="'Name'" [(value)]="aufmass.AufName"> </app-bss-textbox>

        <app-bss-textbox [labelText]="'Suchbegriff'" [placeholder]="'Suchbegriff'" [(value)]="aufmass.Such">
        </app-bss-textbox>

        <app-bss-textbox [labelText]="'Betreff'" [placeholder]="'Betreff'" [(value)]="aufmass.Betreff">
        </app-bss-textbox>
      </div>

      <div class="flexContainer project-or-address-box">
        <div class="flexContainerItem">
          <app-bss-search-popup
            [buttonText]="'Projekt wählen'"
            [buttonStyle]="'bss-button primary-button'"
            [headline]="'Wählen Sie ein Projekt aus'"
            [inputDataSource]="projects"
            [customDisplayExpressions]="['Nummer', 'Betreff', 'KdSuch']"
            [firstLineLabel]="'Projekt '"
            (selectedValue)="selectedOne($event)"
          >
          </app-bss-search-popup>
        </div>
        <div class="or-box">oder</div>
        <div class="flexContainerItem">
          <app-bss-search-popup
            [buttonText]="'Kunde wählen'"
            [buttonStyle]="'bss-button primary-button'"
            [headline]="'Wählen Sie einen Kunden aus'"
            [inputDataSource]="customers"
            [customDisplayExpressions]="['NAME', 'STRASSE', 'PLZ', 'ORT']"
            [additionalSearchExpression]="['KU_NR']"
            (selectedValue)="selectedOne($event)"
          >
          </app-bss-search-popup>
        </div>
      </div>

      <br />

      <div *ngIf="selectedProject">
        <b>Projekt:</b><br />
        <app-project-card [project]="selectedProject"></app-project-card>
        <br />
      </div>

      <div *ngIf="selectedAdress">
        <b>Kunde:</b><br />
        <app-address-card [noClickEvents]="true" [address]="selectedAdress" [withContactBar]="false"></app-address-card>
        <br />
      </div>

      <div *ngIf="!isEdit">
        <app-bss-selectbox
          [showClearButton]="true"
          [labelText]="'Raumvorlage'"
          [items]="roomBooks"
          [placeholder]="'Raumvorlage wählen'"
          (selectedValue)="selectedOne($event, true)"
          [displayExpr]="'Bezeich'"
        >
        </app-bss-selectbox>

        <br />
        <div>oder</div>
        <br />

        <app-bss-textbox [labelText]="'Raumvorlage'" [placeholder]="'Freie Raumvorlage'" [(value)]="aufmass.RBezeich">
        </app-bss-textbox>

        <br />
      </div>

      <app-bss-textarea [labelText]="'Bemerkungen'" [(value)]="aufmass.Beschr"></app-bss-textarea>
    </div>
  </div>
</div>

<dx-speed-dial-action
  *ngIf="!isEdit"
  icon="check"
  class="add-button"
  label="erstellen"
  [index]="1"
  (onClick)="createNew(aufmass)"
>
</dx-speed-dial-action>

<dx-speed-dial-action
  *ngIf="isEdit"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (onClick)="saveEdit(aufmass)"
>
</dx-speed-dial-action>
