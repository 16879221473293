import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { delay } from 'q';
import { interval, Subscription } from 'rxjs';
import { DialogService } from '@handwerk-pwa/shared';
import { ControllerService } from './controller.service';
import { HWGolbalsettingService } from './hwgolbalsetting.service';
import { environment } from 'apps/handwerkPWA/src/environments/environment';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { LoginService } from 'apps/handwerkPWA/src/app/pages/login/login.service';
import { NGXLogger } from 'ngx-logger';
import { AppOnlySettings } from '../../entities';
import { FirstTimeService } from './first-time.service';
import { BeforeInstallPromptEvent, DataToConserve } from '../../interfaces';
import { Mailservice } from 'libs/shared/src/lib/services/mail.service';
import { ConnectionDialogues, ConnectionService } from './connection.service';

@Injectable({
  providedIn: 'root',
})
export class UpdatepwaService implements OnDestroy {
  private updateReminder: Subscription;
  private deferredPromptEvent: BeforeInstallPromptEvent = null;
  isInstalled = false;
  private updateTimerSubscription: Subscription;
  private updateActivatedSubscription: Subscription;
  private updateAvailableSubscription: Subscription;

  constructor(
    private updates: SwUpdate,
    private dialogService: DialogService,
    private controllerService: ControllerService,
    private globalSettingService: HWGolbalsettingService,
    private rechteService: RechteService,
    private firstTimeService: FirstTimeService,
    private loginService: LoginService,
    private logger: NGXLogger,
    private mailService: Mailservice,
    private connectionService: ConnectionService
  ) {}
  ngOnDestroy(): void {
    this.updateTimerSubscription?.unsubscribe();
    this.updateReminder?.unsubscribe();
    this.updateAvailableSubscription?.unsubscribe();
    this.updateActivatedSubscription?.unsubscribe();
  }

  /**Registriert die events für A2HS. Die Funktion sollte so früh wie möglich gecallt werden.*/
  public storeInstallEvent(): void {
    this.isInstalled = window.matchMedia('(display-mode: standalone)').matches;
    window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
      this.deferredPromptEvent = event;
    });
    window.addEventListener('appinstalled', () => {
      this.isInstalled = true;
    });
  }

  /*** Triggert die Meldung für die Installation zum HomeScreen. Der User kann akzeptieren oder auch ablehnen.*/
  public async triggerInstall(): Promise<void> {
    if (this.deferredPromptEvent == null) return;
    await this.deferredPromptEvent.prompt();
    const choiceResult = await this.deferredPromptEvent.userChoice;
    this.isInstalled = choiceResult.outcome === 'accepted' ? true : false;
  }

  /**@description Prüft alle N Minuten ob ein Update der PWA vorliegt , löst dann UserInteraktion aus
   * @value timeIntervall ZeitIntervall in Minuten
   */
  async periodicCheck(timeIntervallInMinutes: number): Promise<void> {
    if (!environment.production) return;
    this.logger.log('Registering update check');
    const timeIntervallMilliseconds = timeIntervallInMinutes * 60 * 1000;
    this.registerUpdateListener(timeIntervallMilliseconds);
    await delay(timeIntervallMilliseconds);
    await this.updates.checkForUpdate();
    const everyNHours$ = interval(timeIntervallMilliseconds);
    this.updateTimerSubscription = everyNHours$.subscribe(() => {
      void this.updates.checkForUpdate(); // guckt nach appUpdates
    });
  }

  /**
   * Registriert einen Listener für den Fall das der ServiceWorker ein Update
   * gefunden hat. Um das Update zu nutzen, muss dann ein reload der Seite ausgeführt werden.
   */
  private registerUpdateListener(reminderIntervallMinutes: number) {
    this.updateAvailableSubscription = this.updates.available.subscribe(() => {
      this.logger.log('PWA Update available');
      void this.askUserForUpdate(reminderIntervallMinutes);
    });
    this.updateActivatedSubscription = this.updates.activated.subscribe(() => {
      void this.globalSettingService.setEntity('UpdateDone', 'UpdateDone');
      this.logger.log('Updateroutine aktiviert, Wert in Datenbank geschrieben');
      window.location.reload();
    });
  }

  /**@description Öffnet den Dialog um den User über das Update zu benachrichtigen  */
  public async askUserForUpdate(reminderIntervallMinutes: number) {
    this.logger.log('Asking user for update');
    const htmlString =
      'Es liegt ein Update für Ihre my blue:app® - hand:werk vor. Die Funktionserweiterungen finden' +
      ' Sie <a href="https://myblueapp.de/handwerk/produktinformationen/" target="_blank">hier</a>. Möchten Sie das Update jetzt durchführen?';

    this.dialogService.updateDialogOpen = true;
    const response = await this.dialogService.openConfirmDialog(
      'Update',
      null,
      'Durchführen',
      'Verschieben',
      false,
      htmlString,
      true
    );
    this.dialogService.updateDialogOpen = false;

    let online: boolean;
    if (response === true) {
      online = await this.connectionService.CheckOnline(ConnectionDialogues.Update);
      if (online) {
        await this.doUpdateRoutine();
        return;
      }
    }
    if (response === false || !online) {
      this.openUpdateReminder(reminderIntervallMinutes);
      return;
    }
  }

  /**@description Öffnet periodisch die Erinnerung des UpdateDialogs */
  private openUpdateReminder(reminderIntervallMinutes: number): void {
    const reminderIntervall = interval(reminderIntervallMinutes);
    this.updateReminder = reminderIntervall.subscribe(() => void this.remindUserUntilUpdate());
  }

  private async remindUserUntilUpdate() {
    const htmlString =
      'Es liegt ein Update für Ihre my blue:app® - hand:werk vor. Die Funktionserweiterungen finden' +
      ' Sie <a href="https://myblueapp.de/handwerk/produktinformationen/" target="_blank">hier</a>. Möchten Sie das Update jetzt durchführen?';
    const response = await this.dialogService.openConfirmDialog(
      'Update',
      null,
      'Durchführen',
      'Verschieben',
      false,
      htmlString
    );

    if (response === true) {
      const online = await this.connectionService.CheckOnline(ConnectionDialogues.Update);
      if (online) {
        this.updateReminder?.unsubscribe();
        void this.doUpdateRoutine();
      }
    }
  }

  /**@description Pusht alle zu pushenden Daten, meldete die Geräte für Lizenz und Push ab und führt dann das Update durch */
  public async doUpdateRoutine(): Promise<void> {
    await this.loginService.logOut(true);
    this.logger.log('Logout der Updateroutine abgeschlossen.');
    await this.updates.activateUpdate();
    // Anschließend geht es im event weiter
  }

  /**@description Nach dem Update des PWA-Codes wird der Nutzer zwischengespeichert. Dann wird die indexedDb verworfen und neu angelegt und zum Login geroutet.
   * Dort wird erkannt das Login und Passwort noch vorhanden sind und der letzte User wird eingeloggt.
   */
  public async afterUpdateInitiated(): Promise<Userinfo> {
    void this.dialogService.openLoadingDialog('Update', 'Update wird durchgeführt');
    const relevantData: DataToConserve = await this.getDataToConserve();
    await this.controllerService.upgradeIndexedDB();
    await this.setConservedData(relevantData);
    this.dialogService.closeLoadingDialog();
    return relevantData.Userinfo;
  }

  /**@description Schreibt die gesammelten Daten wieder in die IDB (nur tun falls vorhanden, bspw. kann man berits abgemeldet sein oder war noch nie angemeldet) */
  private async setConservedData(relevantData: DataToConserve) {
    if (relevantData.Userinfo) await this.globalSettingService.setEntity(relevantData.Userinfo, 'Userinfo');
    if (relevantData.EmailConnectionInfo)
      await this.mailService.setEmailconnectionInfo(relevantData.EmailConnectionInfo);
    if (relevantData.Settings) await this.rechteService.saveSettingLocally(relevantData.Settings);
    if (relevantData.FirstTimeArray) await this.firstTimeService.setFirstTimeArray(relevantData.FirstTimeArray);
    if (relevantData.AppOnlySetting)
      await this.globalSettingService.setEntity(relevantData.AppOnlySetting, 'AppOnlySettings');
  }

  /**@description Sammelt Daten die nach dem Update nicht verloren gehen sollen  */
  private async getDataToConserve(): Promise<DataToConserve> {
    const userInfo = await this.globalSettingService.getUserinfo();
    const emailConnectionInfo = await this.mailService.getEmailconnectionInfo();
    const settings = await this.rechteService.getEinstellungenFromIDB();
    const firstTimeArray = await this.firstTimeService.getFirstTimeArray();
    const appOnlySetting: AppOnlySettings = await this.globalSettingService.getAppOnlySettings();
    return {
      Userinfo: userInfo,
      EmailConnectionInfo: emailConnectionInfo,
      Settings: settings,
      FirstTimeArray: firstTimeArray,
      AppOnlySetting: appOnlySetting,
    };
  }

  async checkManuallyForUpdates(): Promise<void> {
    await this.updates.checkForUpdate();
  }
}
