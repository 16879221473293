import { deDateFormat } from 'apps/handwerkPWA/src/app/config/Konstanten';
import {
  HWAddress,
  base64Pdf,
  base64UnterschriftMock,
  HWRepairOrderItem,
  HWRepairOrder,
  HWMonteur,
  Dokumentposition,
  HWAnlage,
  HWTermin,
  ServiceAuftrag,
} from 'apps/handwerkPWA/src/app/entities';
import moment from 'moment';
import { Right } from '../entities';

function createAllExampleEmployees(): HWAddress[] {
  const employee1 = new HWAddress(null);
  employee1.NAME = 'Max Mustermann';
  employee1.KU_NR = '-2';
  const employee2 = new HWAddress(null);
  employee2.NAME = 'Michael Mustermeier';
  employee2.KU_NR = '-3';
  const employees: HWAddress[] = [];
  employees.push(employee1);
  employees.push(employee2);
  return employees;
}

function createExampleSuperRight() {
  const right = new Right(null);
  right.createSuperRight();
  return right;
}

function createExamplePDF() {
  const pdf = new base64Pdf();
  pdf.data = base64UnterschriftMock;
  pdf.landScape = true;
  return pdf;
}

function createExamplePositions(): HWRepairOrderItem[] {
  const positions: HWRepairOrderItem[] = [];
  for (let index = 1; index < 4; index++) {
    const position = new HWRepairOrderItem(null);
    position.PosNr = index;
    position.Menge = 1;
    position.MengenEinheit = 'ST';
    position.Bezeichnung = 'Testposition ' + index;
    position.Ident = 'M';
    position.VK = index * 100;
    position.UstS = 1;
    position.UstS = 19;
    position.EUMATVK1 = index * 100;
    position.LohnVK = 0;
    position.Zeit = 60;
    positions.push(position);
  }
  return positions;
}

export function createMusterauftrag(): HWRepairOrder {
  const repairOrder = new HWRepairOrder(null, null);
  repairOrder.Nummer = 'nnnnn/jj';
  repairOrder.Bericht = 'Hier steht der Arbeitsbericht';
  repairOrder.AusfAnspr = 'Hier steht der Ansprechpartner';
  repairOrder.ArbeitsOrt = 'Hier steht der Arbeitsort';
  repairOrder.Monteur1 = 'Hier steht der Mitarbeiter';
  repairOrder.Arbeitszeiten = [new Date(), new Date()];
  repairOrder.ArbeitDat = moment(new Date()).format(deDateFormat);

  const positions = createExamplePositions();
  repairOrder.setPositionen(positions);
  return repairOrder;
}

function createExampleEmployee() {
  const employee = new HWMonteur(null);
  employee.Name = 'Hier steht der Mitarbeiter';
  employee.Email = 'mail@adresse.de';
  employee.Nummer = '123321';
  employee.Telefon = '01234/56789';
  employee.Fax = '01234/56789';
  employee.Web = 'www.ihre_webseite.de/';
  employee.FirmenAnschrift = 'Hier steht die Firmenanschrift';
  return employee;
}

function createExampleCustomer() {
  const maxMustermann = new HWAddress(null);
  maxMustermann.ADRTYP = 'K';
  maxMustermann.HERRFRAU = 'Kundenanschrift Zeile 1';
  maxMustermann.NAME = 'Kundenanschrift Zeile 2';
  maxMustermann.NAME2 = 'Kundenanschrift Zeile 3';
  maxMustermann.STRASSE = 'Kundenanschrift Zeile 4';
  maxMustermann.PLZ = 'Kundenanschrift Zeile 5';
  maxMustermann.ORT = '';
  maxMustermann.priceGroup = '01';
  return maxMustermann;
}

/**@description Erstellt Mustermonteur, Kunden,pdf und ein AdminRight für die Auswertung */
export function createBaseMusterPDF(): {
  pdf: base64Pdf;
  employee: HWMonteur;
  right: Right;
  customer: HWAddress;
  allEmployees: HWAddress[];
} {
  const customer = createExampleCustomer();
  const employee = createExampleEmployee();
  const pdf = createExamplePDF();
  const right = createExampleSuperRight();
  const allEmployees = createAllExampleEmployees();
  return { pdf, employee, right, customer, allEmployees };
}

export function createExampleDocumentPositions(): Dokumentposition[] {
  const documentPositions: Dokumentposition[] = [];
  const itemPositionen = createExamplePositions();
  let index = 1;
  for (const itemPosition of itemPositionen) {
    const documentPosition = new Dokumentposition(itemPosition);
    documentPosition.PosNr = index.toString();
    documentPosition.MatVK_G = parseInt(documentPosition.PosNr, 10) * 100;
    documentPositions.push(documentPosition);
    index++;
  }
  return documentPositions;
}

function createExampleAnlage(): HWAnlage {
  const anlage = new HWAnlage(null, [], []);
  anlage.ANLAGE = '00000001';
  const standort = anlage.Anlagenstandort;
  standort.NAME = 'Name Anlagenstandort';
  standort.STRASSE = 'Straße Anlagenstandort';
  standort.PLZ = 'Postleitzahl';
  standort.ORT = 'Ort Anlagenstandort';
  standort.STANDORT1 = 'Standort1 Anlagenstandort';
  standort.STANDORT2 = 'Standort1 Anlagenstandort';
  standort.TEL = 'Telefonnummer Anlagenstandort';
  standort.ANFAHRTKM = 'Anfahrtskilometer';
  return anlage;
}

export function createExampleDate(): HWTermin {
  const fakeDate = new Date(2021, 23, 3, 11, 37, 56);
  const ExampleDate = new HWTermin(null, fakeDate);
  ExampleDate.finish = '23.03.2021 12:37:11';
  return ExampleDate;
}

export function createExampleServiceOrder(allMonteure: HWAddress[]): ServiceAuftrag {
  const anlage = createExampleAnlage();
  const Date = createExampleDate();
  const serviceOrder = new ServiceAuftrag(null, allMonteure, [anlage], [Date]);
  serviceOrder.Anlage = anlage.ANLAGE;
  serviceOrder.Projekt = 'WV10003A';
  serviceOrder.Auftragsnummer = '00001A/21';
  return serviceOrder;
}
