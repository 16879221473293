import { Component, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import {
  assignParentIds,
  buildTreeNodes,
  getEtagen,
  getRaumbuchvorlage,
} from 'libs/shared/src/lib/helper/aufmassHelper';
import { calculateDataAmount, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Aufmass, HWAddress, Raumbuch, Raumname, RVorlage } from '../../../entities';
import { RaumbPos } from '../../../entities/models/aufmass/RaumbPos';
import { filterRoomsPerLevel } from '../../../entities/repository/Raumname';
import { AddressService } from '../../../services/dataServices/address.service';
import { AufmassService } from '../../../services/dataServices/aufmass.service';
import { RaumbuchService } from '../../../services/dataServices/raumbuch.service';
import { RaumnameService } from '../../../services/dataServices/raumname.service';
import { RvorlageService } from '../../../services/dataServices/rvorlage.service';
import { Documentservice } from '../../../services/globalServices/document.service';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';

@Component({
  selector: 'app-raumbuch-edit',
  templateUrl: './raumbuch-edit.component.html',
  styleUrls: ['./raumbuch-edit.component.scss'],
})
export class RaumbuchEditComponent implements OnInit {
  aufmass: Aufmass;
  raumbuch: RVorlage;
  raumbuchAsTreeNodes: RaumbPos[];
  selectedRoom: RaumbPos;
  raumNamen: Raumname[];
  possibleRaumnamen: Raumname[];
  showPopup = false;
  userInfo: Userinfo;
  raumbuchPositionen: RaumbPos[];
  selectedRaumbuchAsRoot = false;
  kunde: HWAddress;
  fileUploadPossible: boolean;
  currentFilesAmount: string;
  showRaumVorlagePopup = false;
  raumVorlageExists = false;
  finished = false;

  constructor(
    private routingService: RoutingService,
    private aufmassService: AufmassService,
    private rvorlageService: RvorlageService,
    private raumnameService: RaumnameService,
    private globalSettingService: HWGolbalsettingService,
    private dialogService: DialogService,
    private adressService: AddressService,
    private raumbuchService: RaumbuchService,
    private authorisationService: AuthorisationService,
    private documentService: Documentservice
  ) {}

  async ngOnInit(): Promise<void> {
    this.aufmass = await this.buildUpTree();
    this.userInfo = await this.globalSettingService.getUserinfo();
    await this.findRaumbuch(this.aufmass);
    this.fileUploadPossible = this.authorisationService.current.getValue().featureCheck('Medientabelle1').available;
    this.raumNamen = await this.raumnameService.getAll();
    this.possibleRaumnamen = filterRoomsPerLevel(this.raumNamen, 3);
    if (this.raumVorlageExists) {
      await this.selectRoom(this.raumbuch);
    }
    const files = await this.documentService.getAllBy('Baseuuid', this.aufmass.getUuid());
    this.currentFilesAmount = calculateDataAmount(files);
    this.finished = true;
  }

  private async loadAufmass(): Promise<Aufmass> {
    const aufmassUuid = this.routingService.getRouteParam('uuid');
    const aufmass = await this.aufmassService.findOneBy('Uuid', aufmassUuid);
    this.kunde = await this.adressService.findOneBy('KU_NR', aufmass.Kunde);
    return aufmass;
  }

  /**@description Baut die Baumstruktur der Anzeige aus den Raumbuchpositionen zusammen - gibt das Aufmass zurück,da es eh geladen wird */
  private async buildUpTree(): Promise<Aufmass> {
    const aufmass = await this.loadAufmass();
    this.raumbuchPositionen = aufmass.getRaumbuchpositionen();
    for (const position of this.raumbuchPositionen) {
      position.hasMasskette = await this.raumbuchService.hasRaumMasskette(position);
    }
    this.raumbuchAsTreeNodes = buildTreeNodes(this.raumbuchPositionen);
    assignParentIds(this.raumbuchPositionen, this.raumbuchAsTreeNodes);
    return aufmass;
  }

  private async findRaumbuch(aufmass: Aufmass) {
    if (aufmass.RVorlage === 0 && isNullOrUndefinedOrEmptyString(aufmass.RBezeich)) {
      this.raumVorlageExists = false;
      return;
    }
    this.raumVorlageExists = true;
    if (aufmass.RVorlage === 0) {
      // 0 = Ohne Vorlage,hängt lose drin
      const mockRaumbuch = new RVorlage(null);
      mockRaumbuch.Bezeich = aufmass.RBezeich;
      this.raumbuch = mockRaumbuch;
      return;
    }
    const vorlagen = await this.rvorlageService.getAllRaumbuchvorlagen();
    this.raumbuch = getRaumbuchvorlage(vorlagen, this.aufmass.RVorlage);
  }

  /**
   * @description Öffnet das "neuer Raum"-Popup, außer wenn keine Ebene gewählt wurde. Dann wird der Nutzer gefragt ob an tiefster Ebene eingefügt
   * werden soll
   */
  async addRaum(): Promise<void> {
    if (this.selectedRoom) {
      this.showPopup = true;
      return;
    }
    const insertToRaumbuch = await this.dialogService.openConfirmDialog(
      'Ebene wählen',
      'Sie haben keine Ebene ausgewählt. Möchten Sie eine Position an tiefster Ebene hinzufügen?',
      'An unterster Ebene hinzufügen',
      'Abbrechen',
      false
    );
    if (insertToRaumbuch) {
      await this.selectRoom(this.raumbuch);
      void this.addRaum();
    }
  }

  /**@description Löscht eine Raumbuchposition und alle Unterknoten/Aufmasse davon */
  async deleteRaum(aufmass: Aufmass, roomEntity: RaumbPos, userInfo: Userinfo): Promise<void> {
    const confirmation = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Sind Sie sicher, dass Sie diese Raumbuchposition und alle Unterpositionen löschen wollen?',
      'Löschen',
      'Nicht löschen',
      false
    );
    if (!confirmation) return;
    await this.aufmassService.deleteRoom(aufmass, roomEntity, userInfo);
    await this.buildUpTree();
  }

  selectRaumVorlage() {
    this.showRaumVorlagePopup = true;
  }

  /**@description Wählt eine RaumbPos Entität - handelt es sich um die tiefste Ebene (ein Raum) dann wird zu diesem geroutet */
  async selectRoom(roomEntity: RaumbPos | RVorlage): Promise<void> {
    this.selectedRaumbuchAsRoot = false;
    if (roomEntity instanceof RVorlage) roomEntity = await this.handleRoomEntityIsRaumbuch(roomEntity);
    this.selectedRoom = roomEntity;
    const levelForRoonames = this.selectedRoom.level ? this.selectedRoom.level - 1 : 3;
    this.possibleRaumnamen = filterRoomsPerLevel(this.raumNamen, levelForRoonames);
  }

  gotoRaumaufmass(roomEntity: RaumbPos): void {
    if (!roomEntity.Uuid) {
      void this.dialogService.openErrorMessage('Fehler', 'Für diesen Knoten kann kein Raumaufmaß erstellt werden.');
      return;
    }
    void this.routingService.navigateTo(`aufmass/${this.aufmass.Uuid}/raum/${roomEntity.Uuid}`);
  }

  private async handleRoomEntityIsRaumbuch(roomEntity: RVorlage): Promise<RaumbPos> {
    const previousSelected = this.findPreviousTreeNode();
    if (previousSelected) {
      previousSelected.expanded = false;
      previousSelected.selected = false;
    }
    const etagen = getEtagen(this.raumbuchPositionen);
    const mockParent = new RaumbPos(null);
    mockParent.createRaumbuchAsRaumbPos(roomEntity, etagen);
    this.selectedRaumbuchAsRoot = true;
    await this.buildUpTree();
    return mockParent;
  }

  async addPositionToAufmass(aufmass: Aufmass, raumbuchPosition: RaumbPos, userInfo: Userinfo): Promise<void> {
    await this.aufmassService.addRaum(aufmass, raumbuchPosition, userInfo, false, true);
    await this.buildUpTree();
    await this.reselectPrevoiusSelected();
    this.showPopup = false;
  }

  /**@description Wählt wieder den zuvor benutzen knoten an */
  private async reselectPrevoiusSelected() {
    const previousSelected = this.findPreviousTreeNode();
    if (!previousSelected && this.selectedRaumbuchAsRoot) {
      // letzte knoten war das raumbuch
      await this.selectRoom(this.raumbuch);
      return;
    }
    if (previousSelected?.items?.length > 0) previousSelected.expanded = true;
    if (previousSelected) {
      previousSelected.selected = true;
      await this.selectRoom(previousSelected);
    }
  }

  /**@description Iteriert durch Etagen und Wohnungen um den letzen Knoten zu finden */
  private findPreviousTreeNode(): RaumbPos {
    if (!this.selectedRoom) return null;
    const previousUuid = this.selectedRoom?.Uuid;
    for (const etage of this.raumbuchAsTreeNodes) {
      if (etage.Uuid === previousUuid) return etage;
      const wohnungen = etage?.items as RaumbPos[];
      for (const wohnung of wohnungen) if (wohnung.Uuid === previousUuid) return wohnung;
    }
    return null;
  }

  editAufmass(uuid: string): void {
    void this.routingService.navigateTo(`aufmass/editierung/${uuid}`);
  }

  gotoMasskette(aufmass: Aufmass, raum: RaumbPos, massKette: Raumbuch): void {
    const massketteId = massKette?.Uuid ? massKette.Uuid : 'neu';
    void this.routingService.navigateTo(`aufmass/${aufmass.Uuid}/raum/${raum.Uuid}/masskette/${massketteId}`);
  }

  drawGrund(aufmass: Aufmass, raum: RaumbPos): void {
    void this.routingService.navigateTo(`aufmass/grundriss/${aufmass.Uuid}/${raum.Uuid}`);
  }

  openMaps(strasse: string, plz: string, ort: string): void {
    window.open(`https://www.google.com/maps/search/?api=1&query=${strasse}%2C+${ort}_blank`);
  }

  async newImages(aufmass: Aufmass, raumb: RaumbPos): Promise<void> {
    await this.routingService.navigateTo(`medien/RaumbPos/${aufmass.Uuid}/upload/${raumb.Uuid}`);
  }
}
