import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HWFile } from 'apps/handwerkPWA/src/app/entities';
import { Medien } from '../../entities/repository/Medien';

@Component({
  selector: 'app-bss-picture-popup',
  templateUrl: './bss-picture-popup.component.html',
  styleUrls: ['./bss-picture-popup.component.scss'],
})
export class BssPicturePopupComponent implements OnInit {
  @Input() InputFile: HWFile | Medien;
  @Input() showPopup = false;
  @Input() newVersion: boolean;
  @Output() closePopupEvent = new EventEmitter<boolean>();
  description: string;

  constructor() {}

  ngOnInit(): void {
    this.description = this.newVersion ? (this.InputFile as Medien).Beschreibung : undefined;
  }

  openInFullscreen(InputFile: HWFile | Medien): void {
    const newPicWindow = window.open('');
    setTimeout(() => {
      newPicWindow.document.write(
        '<button type="button" style="height:100px; width:100%; font-size:80px;" onclick="window.close();">Schließen</button>' +
          `<img style="-webkit-user-select: none;max-width: 100%;margin: auto;" src="${InputFile.Data}">`
      );
    }, 0);
  }

  closePopup(): void {
    this.closePopupEvent.emit(true);
  }
}
