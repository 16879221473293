// import { openDB } from 'idb/build/esm/index';
import { IDBPDatabase, IDBPObjectStore, openDB } from 'idb';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import {
  HWGlobalSetting,
  HWObjectaddress,
  HWContactperson,
  HWContact,
  HWBeleg,
  HWOffeneposten,
  HWUmsatz,
  HWTermin,
  HWMonteur,
  HWRepairOrder,
  HWNachricht,
  HWEmailData,
  HWAddress,
  HWFile,
  HWProjekt,
  HWAnlage,
  HWDatenblatt,
  HWRepairOrderItem,
  ServiceAuftrag,
  Raumname,
  RVorlage,
  Projekt,
  Aufmass,
} from 'apps/handwerkPWA/src/app/entities';
import idbReady from 'safari-14-idb-fix';
import { Medien } from '../../entities/repository/Medien';

type AccessMethod = 'readwrite' | 'readonly';
class DBStore {
  Database: IDBPDatabase<unknown>;
  Store: IDBPObjectStore<unknown, [string], string, AccessMethod>;
}

export class Database {
  dbVersion = 1;

  constructor() {}

  /**
   * @description: Funktion um die Version der Datenbank zu ändern. In der Methode 'InitStores' wird das Versioning gehandelt.
   * @returns Promise vom Typ void
   */
  public async setDbVersion(): Promise<void> {
    await this.initStores(this.dbVersion);
  }

  /**@description Storezugriff zentralisiert */
  private async accessStore(storeName: string, accessMethod: AccessMethod): Promise<DBStore> {
    let db: IDBPDatabase<unknown>;
    await idbReady().then(async () => {
      db = await openDB('handwerkpwa', this.dbVersion);
    });
    const tx = db.transaction(storeName, accessMethod);
    const store = tx.objectStore(storeName);
    return { Database: db, Store: store };
  }

  /**
   *
   * @description Methode um in bestimmte Tabelle in der Datenbank zu schreiben
   * @param <storeName: string, list: object[]>
   * @returns void
   */
  public async insert(storeName: string, list: object[]): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    for (const element of list) await dbStore.Store.put(element);
    dbStore.Database.close();
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank alle Einträge auszulesen
   * @param <storeName: string>
   * @returns Promise<object[]>
   */
  public async getAllData<Type>(storeName: string): Promise<Type> {
    const dbStore = await this.accessStore(storeName, 'readonly');
    const getAll = ((await dbStore.Store.getAll()) as unknown) as Type;
    dbStore.Database.close();
    return getAll;
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank bestimme Einträge auszulesen
   * @param <storeName: string, index: string, keyValue: string>
   * @returns Promise<object[]>
   */
  public async getAllSpecificData<Type>(storeName: string, index: string, keyValue: string): Promise<Type> {
    const dbStore = await this.accessStore(storeName, 'readonly');
    const myIndex = dbStore.Store.index(index);
    const getAll = ((await myIndex.getAll(keyValue)) as unknown) as Type;
    dbStore.Database.close();
    return getAll;
  }

  /**
   * @description      Funktion, um aus bestimmter Tabelle in der Datenbank unter Angabe eines Schlüssels alle Einträge auszulesen
   * @param key:       string (Schlüssel für die Abfrage in der Datenbank)
   * @returns          Promise<object[]>
   */
  public async getAllSpecificDataWithKey<Type>(storeName: string, keyValue: string): Promise<Type> {
    const dbStore = await this.accessStore(storeName, 'readonly');
    const data = ((await dbStore.Store.getAll(keyValue)) as unknown) as Type;

    dbStore.Database.close();
    return data;
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank bestimme Einträge zu löschen
   * @param <storeName: string, index: string, keyValue: string>
   * @returns Promise<object[]>
   */
  public async deleteSpecificData(storeName: string, index: string, keyValue: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    const cursor = await dbStore.Store.openCursor();
    while (!isNullOrUndefined(cursor)) {
      try {
        if (cursor.value[index] === keyValue) {
          await cursor.delete();
        }
        await cursor.continue();
      } catch {
        return;
      }
    }
    dbStore.Database.close();
  }

  public async deleteSpecificData2(
    storeName: string,
    index: string,
    keyValue: string,
    index2: string,
    keyValue2: string
  ): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    const cursor = await dbStore.Store.openCursor();
    while (!isNullOrUndefined(cursor)) {
      try {
        if (cursor.value[index] === keyValue && cursor.value[index2] === keyValue2) {
          await cursor.delete();
        }
        await cursor.continue();
      } catch {
        return;
      }
    }
    dbStore.Database.close();
  }

  public async deleteData(storeName: string, keyValue: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    await dbStore.Store.delete(keyValue);
    dbStore.Database.close();
  }

  public async clearStore(storeName: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    await dbStore.Store.clear();
    dbStore.Database.close();
  }

  private async initStores(dbVersion: number) {
    const db = await openDB('handwerkpwa', dbVersion, {
      upgrade(upgradeDB, oldVersion, newVersion, transaction) {
        HWGlobalSetting.InitStore(upgradeDB);
        HWAddress.InitStore(upgradeDB);
        HWObjectaddress.InitStore(upgradeDB);
        HWContactperson.InitStore(upgradeDB);
        HWContact.InitStore(upgradeDB);
        HWBeleg.InitStore(upgradeDB);
        HWOffeneposten.InitStore(upgradeDB);
        HWUmsatz.InitStore(upgradeDB);
        HWTermin.InitStore(upgradeDB);
        HWMonteur.InitStore(upgradeDB);
        HWRepairOrder.InitStore(upgradeDB);
        HWNachricht.InitStore(upgradeDB);
        HWEmailData.InitStore(upgradeDB);
        HWFile.InitStore(upgradeDB);
        HWProjekt.InitStore(upgradeDB);
        HWAnlage.InitStore(upgradeDB);
        HWDatenblatt.InitStore(upgradeDB);
        HWRepairOrderItem.InitStore(upgradeDB);
        ServiceAuftrag.InitStore(upgradeDB);
        Raumname.InitStore(upgradeDB);
        RVorlage.InitStore(upgradeDB);
        Projekt.InitStore(upgradeDB);
        Aufmass.InitStore(upgradeDB);
        Medien.InitStore(upgradeDB);
      },
      blocked() {},
      blocking() {},
      terminated() {},
    });
    db.close();
  }

  public async rebuildDB(dbVersion: number): Promise<void> {
    this.deleteOldIDB();
    const db = await openDB('handwerkpwa', dbVersion, {
      upgrade(upgradeDB, oldVersion, newVersion, transaction) {
        HWGlobalSetting.InitStore(upgradeDB);
        HWAddress.InitStore(upgradeDB);
        HWObjectaddress.InitStore(upgradeDB);
        HWContactperson.InitStore(upgradeDB);
        HWContact.InitStore(upgradeDB);
        HWBeleg.InitStore(upgradeDB);
        HWOffeneposten.InitStore(upgradeDB);
        HWUmsatz.InitStore(upgradeDB);
        HWTermin.InitStore(upgradeDB);
        HWMonteur.InitStore(upgradeDB);
        HWRepairOrder.InitStore(upgradeDB);
        HWNachricht.InitStore(upgradeDB);
        HWEmailData.InitStore(upgradeDB);
        HWFile.InitStore(upgradeDB);
        HWProjekt.InitStore(upgradeDB);
        HWAnlage.InitStore(upgradeDB);
        HWDatenblatt.InitStore(upgradeDB);
        HWRepairOrderItem.InitStore(upgradeDB);
        ServiceAuftrag.InitStore(upgradeDB);
        Raumname.InitStore(upgradeDB);
        RVorlage.InitStore(upgradeDB);
        Projekt.InitStore(upgradeDB);
        Aufmass.InitStore(upgradeDB);
        Medien.InitStore(upgradeDB);
      },
      blocked() {},
      blocking() {},
      terminated() {},
    });
    db.close();
  }
  deleteOldIDB(): void {
    const request = indexedDB.deleteDatabase('handwerkpwa');
    request.onsuccess = function () {};
    request.onerror = function () {};
    request.onblocked = function () {};
  }

  storeNames = [
    'HWAddress',
    'HWBeleg',
    'HWContact',
    'HWContactperson',
    'HWEmailData',
    'HWMonteur',
    'HWNachricht',
    'HWObjectaddress',
    'HWOffeneposten',
    'HWRepairOrder',
    'HWTermin',
    'HWUmsatz',
    'HWFile',
    'HWAnlage',
    'HWDatenblatt',
    'HWRepairOrderItem',
    'ServiceAuftrag',
    'Raumname',
    'Projekt',
    'Aufmass',
    'Medien',
    'HWGlobalSetting',
  ];

  async clearAllStores(): Promise<void> {
    for (const storeName of this.storeNames) {
      await this.clearStore(storeName);
    }
  }
}
