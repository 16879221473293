import { Component, OnInit, NgModule, Input, ViewChild } from '@angular/core';
import {
  SideNavigationMenuComponent,
  SideNavigationMenuModule,
} from '../side-navigation-menu/side-navigation-menu.component';
import { HeaderModule } from '../header/header.component';
import { ScreenService } from 'libs/shared/src/lib/services/screen.service';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule, DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoginService } from '../../../pages/login/login.service';
import { SyncService } from '../../../services/globalServices/sync.service';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { datenschutzpath, hilfepath } from '../../../config/Konstanten';

@Component({
  selector: 'app-header-and-side-navigation',
  templateUrl: './header-and-side-navigation.component.html',
  styleUrls: ['./header-and-side-navigation.component.scss'],
})
export class HeaderAndSideNavigationComponent implements OnInit {
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  @ViewChild('sidenav') sidenav: SideNavigationMenuComponent;
  selectedRoute: Subject<string>;
  menuOpened: BehaviorSubject<boolean>;
  @Input() title: string;
  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;

  constructor(
    private screen: ScreenService,
    private routingService: RoutingService,
    private loginService: LoginService,
    private syncService: SyncService,
    private globalSettingService: HWGolbalsettingService
  ) {}

  ngOnInit(): void {
    this.menuOpened = this.routingService.menuOpen;
    this.screen.changed.subscribe(() => this.updateDrawer());
    this.selectedRoute = this.routingService.currentRoute;
    this.updateDrawer();
  }

  updateDrawer(): void {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  /**
   * @description Pfad(Klick auf Item) kommt an - nun Menü öffnen falls nicht offen, oder beim geöffnetem Menü den Pfad gehen und Menü schließen
   * - außer es ist ein großer bildschirm,dann kann das Menü immer offen bleiben
   */
  async navigationChanged(path: string): Promise<void> {
    if (!path || path === 'module' || path === 'reparatur') return;
    if (path === '/abmelden') {
      this.sidenav?.menu?.instance?.unselectAll();
      void this.loginService.logOut();
      return;
    }
    if (path === '/Synchronisation') {
      this.routingService.nextMenuState(false);
      const userInfo = await this.globalSettingService.getUserinfo();
      await this.syncService.getAllDataFromWebService(userInfo, true, false);
      void this.routingService.reload();
      return;
    }
    if (path === datenschutzpath || path === hilfepath) {
      window.open(path);
      this.routingService.nextMenuState(false);
      return;
    }
    if (this.menuOpened.value === true) {
      void this.routingService.navigateTo(path);
      return;
    }
  }

  OnShaderClick = (): void => {
    if (this.shaderEnabled) this.menuOpened.next(!this.menuOpened);
  };
}

@NgModule({
  imports: [SideNavigationMenuModule, DxDrawerModule, HeaderModule, DxScrollViewModule, CommonModule],
  exports: [HeaderAndSideNavigationComponent],
  declarations: [HeaderAndSideNavigationComponent],
})
export class HeaderAndSideNavigationModule {}
