import { IndexedDBTypes } from './dbType';
import { HWAddress } from './HWAddress';
import { comparePositionen, HWRepairOrderItem } from './HWRepairOrderItem';
import {
  convertToRoundedDisplayString,
  isNullOrUndefined,
  isNullOrUndefinedOrEmptyString,
} from 'libs/shared/src/lib/helper/globalHelper';
import { HWObjectaddress } from './HWObjectaddress';
import {
  deDatettimeFormatWithSeconds,
  deDateFormat,
  deDatettimeFormat,
} from 'apps/handwerkPWA/src/app/config/Konstanten';
import moment from 'moment';
import { UUID } from 'angular2-uuid';
import { BaseDocumentPosition, AuftragsArt, BaseAuftrag, UuidEntity } from 'apps/handwerkPWA/src/app/interfaces';
import { Autolohn } from 'apps/handwerkPWA/src/app/entities/models/Autolohn';
import { Druckinformationen } from 'apps/handwerkPWA/src/app/entities/models/Druckinformationen';
import { RepairOrderStatus } from 'apps/handwerkPWA/src/app/entities/models/RepairOrderStatus';
import { Steuer } from 'apps/handwerkPWA/src/app/entities/models/Steuer';
import { DragEvent } from '../../interfaces/DragEvent';

export class HWRepairOrder extends IndexedDBTypes.DbType implements BaseAuftrag, UuidEntity {
  @IndexedDBTypes.KlassenName('HWRepairOrder') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') Nummer = '-1';
  @IndexedDBTypes.DataField('string') AnsprMobil: string;
  @IndexedDBTypes.DataField('string') AnsprTel: string;
  @IndexedDBTypes.DataField('string') ArbeitDat: string;
  @IndexedDBTypes.DataField('string') ArbeitsOrt: string;
  @IndexedDBTypes.DataField('string') AusfAnspr: string;
  @IndexedDBTypes.DataField('string') Base64PDF: string;
  @IndexedDBTypes.DataField('string') Base64Pic: string;
  @IndexedDBTypes.DataField('string') Bemerkung: string;
  @IndexedDBTypes.DataField('string') Bericht = '';
  @IndexedDBTypes.DataField('string') Beschreibung: string;
  @IndexedDBTypes.DataField('number') Bezahlung = 0;
  @IndexedDBTypes.DataField('string') Eingang: string;
  @IndexedDBTypes.DataField('boolean') Erledigt: boolean;
  @IndexedDBTypes.DataField('boolean') Garantie: boolean;
  @IndexedDBTypes.DataField('string') Guid: string;
  @IndexedDBTypes.DataField('string') KundenName: string;
  @IndexedDBTypes.IndexField('string') KundenNummer: string;
  @IndexedDBTypes.DataField('string') Land: string;
  @IndexedDBTypes.DataField('boolean') LieferscheinMode = false;
  @IndexedDBTypes.DataField('string') Monteur1: string;
  @IndexedDBTypes.DataField('string') Monteur2: string;
  @IndexedDBTypes.DataField('string') ObjektAdrNummer: string;
  /**Private damit man zum Aufruf nur den Getter verwendet,die Objekte AUfbaut damit Objektmethoden existieren */
  @IndexedDBTypes.DataField() private Positionen: HWRepairOrderItem[] = [];
  @IndexedDBTypes.DataField('string') RechNR: string;
  @IndexedDBTypes.DataField('string') Endzeit: string;
  /**0-offen , 10angenommen, 20-abgelehnt, 25-abgebrochen, 30-begonnen, 40-beendet */
  @IndexedDBTypes.DataField('number') Status = 0;
  @IndexedDBTypes.DataField('string') Termin: string;
  @IndexedDBTypes.DataField('string') Standort1: string;
  @IndexedDBTypes.DataField('string') Standort2: string;
  @IndexedDBTypes.DataField('string') Betreff: string;
  @IndexedDBTypes.DataField('boolean') PreiseAnzeigen = false;
  @IndexedDBTypes.DataField('Autolohn') Autolohn = new Autolohn();
  @IndexedDBTypes.DataField() Arbeitszeiten: Date[] = [];
  @IndexedDBTypes.DataField() Kunde: HWAddress;
  @IndexedDBTypes.DataField() StatusObject: RepairOrderStatus;
  @IndexedDBTypes.DataField('string') auftragsArt: AuftragsArt = 'Reparaturauftrag';
  /**Hatte die Topapp als Antwort erwartet, für Kompatibilität wird einfach der Auftrag um  Success erweitert */
  Success: boolean;
  @IndexedDBTypes.DataField('string') objectType: 'HWRepairOrder' | 'HWTermin' | 'ServiceAuftrag' = 'HWRepairOrder';
  @IndexedDBTypes.DataField('string') TerminId: string;

  // Diese Daten beziehen sich auf den Auftragsort
  @IndexedDBTypes.DataField('string') Ort: string;
  @IndexedDBTypes.DataField('string') Plz: string;
  @IndexedDBTypes.DataField('string') Strasse: string;

  @IndexedDBTypes.DataField('string') KundenStrasse: string;
  @IndexedDBTypes.DataField('string') KundenLand: string;
  @IndexedDBTypes.DataField('string') KundenPlz: string;
  @IndexedDBTypes.DataField('string') KundenOrt: string;

  /**HH:mm angabe der arbeitszeiten */
  @IndexedDBTypes.DataField('string') BArbeit: string;
  @IndexedDBTypes.DataField('string') EArbeit: string;

  /** True wenn offline und der Auftrag editiert wurde */
  @IndexedDBTypes.DataField('boolean') edited: boolean;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object, kunde: HWAddress, createdInApp?: boolean) {
    super();
    Object.assign(this, data);
    this.StatusObject = new RepairOrderStatus(this.Status);
    this.addKundeninformation(kunde);
    if (this.Status === 30 && createdInApp) {
      this.addArbeitszeit();
    }
  }

  getUuid(): string {
    return this.Guid;
  }

  getAllMitarbeiternamesInOrder(allAdresses: HWAddress[]): string {
    const monteurIds = isNullOrUndefinedOrEmptyString(this.Monteur2) ? [this.Monteur1] : [this.Monteur1, this.Monteur2];
    const mitarbeiter = allAdresses.filter(person => monteurIds.includes(person.KU_NR));
    const mitarbeiterNames = mitarbeiter.map(arbeiter => arbeiter.NAME);
    return mitarbeiterNames.join(', ');
  }

  getSpecificVortext(): string {
    return this.Bericht;
  }

  getUnterschriftsperson(): string {
    return this.AusfAnspr ? this.AusfAnspr : this.KundenName;
  }

  /**
   * @description
   *  Ordnet die Leistungen wieder korrekt ihre Leistungspositionen zu, wenn diese aus dem Auftrag aus dem Handwerk kommen  */
  assignLeistungsIds(): void {
    const positionen = this.getPositionen();
    const leistungen = positionen?.filter(position => position.getLongtype() === 'Leistung');
    leistungen?.forEach(leistung => leistung.setLeistungsID());
    const unterpositionen = positionen?.filter(position => position.isUnterposition());
    unterpositionen.forEach(unterposition => {
      const leistungsId = leistungen?.find(leistung => leistung.PosNr === unterposition.SORTID)?.getLeistungsId();
      unterposition.setLeistungsID(leistungsId);
    });
  }

  /**
   * @description Löscht ein Item aus der betreffenden RepairOrder
   * @returns Bereinigte Positionsliste
   */
  deleteItemFromPositionen(item: HWRepairOrderItem): HWRepairOrderItem[] {
    const positionen = this.getPositionen();
    const uniqueItemIdentifier = item.getUniqueIdentifier();
    const deleteItem = positionen.find(findItem => uniqueItemIdentifier === findItem.getUniqueIdentifier());
    deleteItem.Deleted = true;
    if (item.getLongtype() === 'Leistung') {
      const unterpositionen = positionen.filter(
        position => item.getLeistungsId() === position.getLeistungsId() && position.getLongtype() !== 'Leistung'
      );
      unterpositionen.forEach(unterposition => (unterposition.Deleted = true));
    }
    this.reNumber(positionen, item.PosNr);
    return positionen;
  }

  private reNumber(positionen: HWRepairOrderItem[], deletedPosNumber: number): void {
    const positionsToRenumber = positionen.filter(position => position.PosNr > deletedPosNumber);
    for (const positionToRenumber of positionsToRenumber) {
      positionToRenumber.PosNr = positionToRenumber.PosNr - 1;
      positionToRenumber.SORTID = positionToRenumber.SORTID - 1;
    }
  }

  assignPdfDatauri(dataUri: string, dataUriUnterschrift?: string): void {
    this.Base64PDF = dataUri;
    if (dataUriUnterschrift) {
      dataUriUnterschrift = dataUriUnterschrift.replace('data:image/png;base64,', '');
      dataUriUnterschrift = dataUriUnterschrift.replace(`"`, '');
    }

    this.Base64Pic = dataUriUnterschrift;
  }
  findOrderItem(item: BaseDocumentPosition): HWRepairOrderItem {
    const allPositions = this.getPositionen();
    const findItem = allPositions.find(this.itemMatches(item));
    return findItem;
  }

  findOrderItemByUniqueIdentifier(uniqueIdentifier: string): HWRepairOrderItem {
    const allPositions = this.getPositionen();
    const findItem = allPositions.find(item => item.getUniqueIdentifier() === uniqueIdentifier);
    return findItem;
  }

  private itemMatches(
    item: BaseDocumentPosition
  ): (value: HWRepairOrderItem, index: number, obj: HWRepairOrderItem[]) => unknown {
    return searchItem => searchItem.UuidInAuftragspositionen === (item as HWRepairOrderItem).UuidInAuftragspositionen;
  }

  /**@description Fügt der Order die Position hinzu */
  addPosition(item: HWRepairOrderItem): void {
    const nextNumber = this.calculateNextPosNr();
    item.PosNr = nextNumber;
    item.SORTID = nextNumber;
    item.AuftrNR = this.Nummer;
    item.UuidInAuftragspositionen = UUID.UUID();
    this.setPositionen(this.getPositionen(), item);
    if (item.getLongtype() === 'Leistung') {
      item.setLeistungsID();
      this.addLeistungspositionen(item);
    }
  }

  /**@description Fügt Leistungspositionen dem Auftrag hinzu */
  addLeistungspositionen(leistung: HWRepairOrderItem): void {
    this.addLeistungsIdIfMissing(leistung);
    const currentPositionen = this.getPositionen();
    const leistungsPositionen = leistung.getTransferredLeistungspositionen();
    leistungsPositionen.forEach(position => {
      this.assignUnterpositionInformation(position, leistung);
      this.setPositionen(currentPositionen, position);
    });
  }

  /**
   * @description Falls die LeistungsId nicht vorhanden ist wird sie generiert,
   * damit leistungspositionen gesichert einer leistung zugeordnet werden können
   */
  private addLeistungsIdIfMissing(leistung: HWRepairOrderItem): void {
    const leistungsIdExists = !isNullOrUndefinedOrEmptyString(leistung.getLeistungsId());
    if (leistungsIdExists) return;
    leistung.setLeistungsID();
  }

  /**@description Weißt spezifische Informationen zur Zuordnung einer Unterleistung zu */
  private assignUnterpositionInformation(position: HWRepairOrderItem, leistung: HWRepairOrderItem) {
    const leistungsID = leistung.getLeistungsId();
    position.setLeistungsID(leistungsID);
    position.AuftrNR = this.Nummer;
    position.UuidInAuftragspositionen = UUID.UUID();
    position.PosNr = leistung.PosNr;
    position.SORTID = leistung.SORTID;
    position.Jumbo1 = 1;
    delete position.GEWERK;
  }

  getNummer(): string {
    return this.Nummer;
  }

  getKunde(): HWAddress {
    return this.Kunde;
  }

  getSortDate(): Date {
    return moment(this.Termin + ':00', deDatettimeFormatWithSeconds).toDate();
  }

  getTerminId(): string {
    return this.TerminId;
  }

  getDisplayPositions(): HWRepairOrderItem[] {
    const displayPositionen = this.getPositionen().filter(
      position => position.Deleted !== true && !position.isUnterposition()
    );
    return displayPositionen.sort(comparePositionen);
  }

  getDruckinformationen(): Druckinformationen {
    return new Druckinformationen(this.ArbeitsOrt, this.AusfAnspr, this.Eingang);
  }

  getUnterpositionenOfLeistung(leistung: BaseDocumentPosition): HWRepairOrderItem[] {
    if (leistung.getLongtype() !== 'Leistung') return [];
    const positionen = this.getPositionen();
    const unterPositionen = positionen.filter(position => this.isUnterpositionOfLeistung(position, leistung));
    return unterPositionen;
  }

  private isUnterpositionOfLeistung(position: HWRepairOrderItem, leistung: BaseDocumentPosition): boolean {
    const positionLeistungsId = position.getLeistungsId();
    const matchingLeistungsIds =
      !isNullOrUndefinedOrEmptyString(positionLeistungsId) && position.getLeistungsId() === leistung.getLeistungsId();
    const positionNotLeistung = position.getLongtype() !== 'Leistung';
    return matchingLeistungsIds && positionNotLeistung;
  }

  /**@description Fügt die Kundeninformationen der RepairOrder hinzu */
  addKundeninformation(kunde: HWAddress): void {
    if (!kunde) return;
    this.KundenNummer = kunde.KU_NR;
    this.KundenLand = kunde.LAND;
    this.KundenOrt = kunde.ORT;
    this.KundenPlz = kunde.PLZ;
    this.KundenStrasse = kunde.STRASSE;
    this.KundenName = kunde.NAME;
    this.AnsprTel = isNullOrUndefinedOrEmptyString(this.AnsprTel) ? kunde.TEL : this.AnsprTel;
    this.AnsprMobil = isNullOrUndefinedOrEmptyString(this.AnsprMobil) ? kunde.FUNK_PRIV : this.AnsprMobil;
    this.AusfAnspr = this.getAnsprechpartner(kunde);
    this.Kunde = kunde;
  }

  /**@description Guckt ob der Kunde den ansprechpartner enthält (in dem fall enthält Ansprech nur nachnamen) - in dem fall wird der kundenname genommen, sonst der abweichende ansprechpartner */
  private getAnsprechpartner(kunde: HWAddress): string {
    if (!isNullOrUndefinedOrEmptyString(this.AusfAnspr)) return this.AusfAnspr;
    const name = kunde.NAME?.toLowerCase();
    const ansprech = kunde.ANSPRECH?.toLowerCase();
    if (name?.includes(ansprech)) return kunde.NAME;
    return kunde.ANSPRECH;
  }

  /**@description Füllt das Feld "Auftragsort" des Handwerks  */
  addAuftragsortInformation(kunde: HWAddress, objectAdresse: HWObjectaddress): void {
    if (isNullOrUndefined(objectAdresse)) {
      this.Plz = kunde.PLZ;
      this.Ort = kunde.ORT;
      this.Strasse = kunde.STRASSE;
      this.Land = kunde.LAND;
      return;
    }
    this.Ort = objectAdresse.ORT;
    this.Plz = objectAdresse.PLZ;
    this.Strasse = objectAdresse.STRASSE;
    this.AnsprTel = objectAdresse.Telefon;
    this.AnsprMobil = undefined;
    this.ObjektAdrNummer = objectAdresse.LfdNr;
    this.AusfAnspr = objectAdresse.Name;
  }

  /**@description Wann immer der Status auf Beginnen Unterbrechen oder Abgeschlossen gesetzt wird, wird eine Arbeitszeit hinzugefügt */
  addArbeitszeit(): void {
    const now = new Date();
    this.Arbeitszeiten.push(now);
  }

  handleAutolohnAllowed(autoLohnPreset: string, autolohnAendern: boolean): void {
    this.Autolohn.isAllowed = true;
    if (autoLohnPreset === 'Aus' && autolohnAendern === false) {
      this.Autolohn.isAllowed = false;
      this.Autolohn.isActive = false;
    }
  }

  handleAutolohnPreset(autoLohnPreset: string): number {
    if (this.Autolohn.alreadyConfigured === true) {
      return this.Autolohn.rundung;
    }
    if (autoLohnPreset === 'Aus') {
      this.Autolohn.isActive = false;
      this.Autolohn.alreadyConfigured = true;
      return this.Autolohn.rundung;
    }
    // Ab hier ist schon mal preset-an
    this.Autolohn.isActive = true;
    if (autoLohnPreset === 'Nicht runden') {
      this.Autolohn.rundung = 0;
      this.Autolohn.alreadyConfigured = true;
      return this.Autolohn.rundung;
    }
    this.Autolohn.rundung = parseInt(autoLohnPreset, 10);
    this.Autolohn.alreadyConfigured = true;
    return this.Autolohn.rundung;
  }

  getAutolohnTimes(arbeitsZeiten: Date[]): number {
    let zeitenIndex = 0;
    const arbeitszeitenAnzahl = arbeitsZeiten.length;
    if (arbeitszeitenAnzahl < 2) return 0;
    const lastStatus = this.Status;
    if ((lastStatus === 25 && arbeitszeitenAnzahl % 2 === 0) || (lastStatus === 30 && arbeitszeitenAnzahl % 2 === 1)) {
      zeitenIndex = 1;
    }
    let addedTimeDifferences = 0;
    for (zeitenIndex; zeitenIndex < arbeitszeitenAnzahl; zeitenIndex = zeitenIndex + 2) {
      const startZeit = Math.floor(arbeitsZeiten[zeitenIndex]?.getTime() / 1000 / 60 || 0);
      const endZeit = Math.floor(arbeitsZeiten[zeitenIndex + 1]?.getTime() / 1000 / 60 || 0);
      if (startZeit !== 0 && endZeit !== 0) addedTimeDifferences += endZeit - startZeit;
    }
    return this.getAutolohnZeitrundung(addedTimeDifferences);
  }

  /**
   * @description Bestimmt den gerundeten Autolohnwert in Minuten
   * @param difference Die Gesamtzeit in Millisekunden
   */
  getAutolohnZeitrundung(difference: number): number {
    if (difference === 0) return 0;
    const rundungsvalue = this.Autolohn.rundung;
    const aufrunden = this.Autolohn.aufrundung;
    if (rundungsvalue === 0) return difference;

    const roundedDownMinutes = Math.floor(difference / rundungsvalue) * rundungsvalue;

    if (aufrunden) {
      return difference % rundungsvalue === 0 ? roundedDownMinutes : roundedDownMinutes + rundungsvalue;
    }

    return roundedDownMinutes;
  }

  setAutolohnEndtimes(): void {
    const arbeitsZeiten = this.Arbeitszeiten;
    for (const position of this.getPositionen()) {
      if (position.isAutolohnActive) {
        const roundedDiffMins = this.getAutolohnTimes(arbeitsZeiten);
        const menge = (roundedDiffMins / 60).toFixed(2);
        position.Menge = parseFloat(menge);
        position.Zeit = roundedDiffMins;
      }
    }
  }

  deleteAutokey(): void {
    delete this.AutoKey;
  }

  getKundennummer(): string {
    return this.KundenNummer;
  }

  /**@description Guckt was die aktuell höchste Positionsnumemr eines Auftrags ist und zählt danach weiter */
  calculateNextPosNr(): number {
    const positionen = this.getPositionen();
    const positionUndeleted = positionen.filter(searchPosition => searchPosition.Deleted !== true);
    let highestPosNr = 0;
    for (const position of positionUndeleted) {
      const currentPosNr = position.PosNr;
      if (currentPosNr > highestPosNr) {
        highestPosNr = currentPosNr;
      }
    }

    return highestPosNr + 1;
  }

  getShowPrices(): boolean {
    return this.PreiseAnzeigen;
  }

  getPrintPositions(): HWRepairOrderItem[] {
    return this.getDisplayPositions();
  }

  /**@description Berechnet die Nettosumme, die Steuersumme und die Gesammtsumme */
  calculateItemSums(Steuern: Steuer[]): { CompleteSum: string; TaxedSum: string; NettoSum: string } {
    const items = this.getPositionen();
    let nettoSum = 0;
    let taxedSum = 0;
    for (const item of items) {
      if (item.isUnterposition()) continue;
      const { gesammtPreis, steuerPreis } = item.calculateVerkaufspreisSteuerpreis(item, Steuern, 'none');
      nettoSum += gesammtPreis;
      taxedSum += steuerPreis;
    }
    const completeSum = nettoSum + taxedSum;
    const completeSumString = convertToRoundedDisplayString(completeSum);
    const taxedSumString = convertToRoundedDisplayString(taxedSum);
    const nettoSumString = convertToRoundedDisplayString(nettoSum);
    return { CompleteSum: completeSumString, TaxedSum: taxedSumString, NettoSum: nettoSumString };
  }

  getPositionen(): HWRepairOrderItem[] {
    this.Positionen = this.Positionen.map(posData => new HWRepairOrderItem(posData));
    return this.Positionen;
  }

  setPositionen(items: HWRepairOrderItem[], itemToAdd?: HWRepairOrderItem): void {
    this.Positionen = items;
    if (itemToAdd) this.Positionen.push(itemToAdd);
  }

  isEditable(): boolean {
    return true;
  }

  /**@description Erstellt Blanko Informationen für jetzt */
  createDefault(monteur: string): void {
    this.Guid = UUID.UUID();
    const now = new Date();
    this.Monteur1 = monteur;
    this.Eingang = moment(now).format(deDateFormat);
    this.Termin = moment(now).format(deDatettimeFormat);
  }

  reorderPositions(dragEvent: DragEvent): void {
    const displayPositionen = this.getDisplayPositions();
    const canBeReordered = displayPositionen.every(pos => !isNaN(pos.SORTID));
    if (!canBeReordered) return;
    const element = displayPositionen.splice(dragEvent.fromIndex, 1);
    displayPositionen.splice(dragEvent.toIndex, 0, ...element);
    let index = 1;
    const unterpositionen: HWRepairOrderItem[] = [];
    for (const position of displayPositionen) {
      const currentUnterpositionen = this.getUnterpositionenOfLeistung(position);
      position.SORTID = index;
      for (const unterposition of currentUnterpositionen) {
        unterposition.SORTID = index;
        unterpositionen.push(unterposition);
      }
      index++;
    }
    const positionen = displayPositionen.concat(...unterpositionen);
    this.setPositionen(positionen);
  }

  //TODO Refactor
  addArbeitEndDateInformations(): void {
    if (this.Status < 40) return;
    this.ArbeitDat = moment(new Date()).format(deDateFormat);
    if (this.Arbeitszeiten?.length < 2) return;
    const start = this.Arbeitszeiten[0];
    if (start) this.BArbeit = moment(start).format('HH:mm');
    const end = this.Arbeitszeiten[this.Arbeitszeiten.length - 1];
    if (end) this.EArbeit = moment(end).format('HH:mm');
  }

  static toString(): string {
    return 'HWRepairOrder';
  }
}
