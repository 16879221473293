import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';
import { HWUmsatz } from 'apps/handwerkPWA/src/app/entities';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyArray } from 'libs/shared/src/lib/helper/globalHelper';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BaseService } from './base.service';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';

@Injectable({
  providedIn: 'root',
})
export class UmsatzService {
  constructor(
    private baseService: BaseService,
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private connectionService: ConnectionService
  ) {}

  /**
   * @description Holt alle Umsätze aus der IDB mit bestimmten Suchkriterien
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async getAllBy(selector: string, value: any): Promise<HWUmsatz[]> {
    return await this.baseService.getAllBy(HWUmsatz, selector, value);
  }

  async getUmsatzByKundennummerByChoice(userInfo: Userinfo, kundenNummer: string): Promise<HWUmsatz[]> {
    const currentUmsatz = await this.getAllBy('Kundennummer', kundenNummer);

    const isOnline = await this.connectionService.CheckOnline(ConnectionDialogues.GetData);
    if (!isOnline) return currentUmsatz;

    const liveUmsatz = await this.getUmsatzByKuNrFromWebservice(userInfo, kundenNummer);
    return liveUmsatz;
  }

  /**@description Holt die jeweiligen Umsaetze.
   *  Schreibt sie in die IDB, löscht zuvor die jeweilige Tabelle in die geschrieben wird */
  async getUmsaetzeFromWebservice(userInfo: Userinfo): Promise<HWUmsatz[]> {
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Umsätze...');
    const umsaetze = await this.restService.returnData<HWUmsatz[]>(
      userInfo,
      'allUmsaetze/mandant/' + userInfo.mandant + '/username/' + userInfo.monteur
    );
    if (isNullOrUndefined(umsaetze)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    await this.writeUmsatzToIDB(umsaetze, true);
    this.dialogService.closeLoadingDialog();
    return umsaetze;
  }

  async getUmsatzByKuNrFromWebservice(userInfo: Userinfo, KuNr: string): Promise<HWUmsatz[]> {
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole Umsätze...');
    const umsatzData = await this.restService.returnData<HWUmsatz[]>(
      userInfo,
      'umsaetze/mandant/' + userInfo.mandant + '/KU_NR/' + KuNr + '/username/' + userInfo.monteur
    );
    if (isNullOrUndefined(umsatzData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const umsaetzeClean: HWUmsatz[] = [];
    for (const data of umsatzData) {
      const umsatz = new HWUmsatz(data);
      umsatz.Kundennummer = KuNr;
      umsaetzeClean.push(umsatz);
    }
    await this.updateUmsatzInIdb(umsaetzeClean);
    this.dialogService.closeLoadingDialog();
    return umsaetzeClean;
  }

  /*******************************************************************************
   *
   *
   *
   * PRIVATE FUNCTIONS
   *
   *
   *
   ******************************************************************************** */

  /**@description Schreibt die Umsaetze in die IDB */
  private async writeUmsatzToIDB(umsaetze: HWUmsatz[], clear: boolean) {
    if (clear) {
      await this.controllerService.clearStore('HWUmsatz');
    }
    await this.controllerService.setData('HWUmsatz', umsaetze);
  }

  private async updateUmsatzInIdb(umsaetze: HWUmsatz[]) {
    if (isNullOrUndefinedOrEmptyArray(umsaetze)) return;
    const kundenNummer = umsaetze[0].Kundennummer;
    await this.controllerService.deleteData('HWUmsatz', 'Kundennummer', kundenNummer);
    await this.controllerService.setData('HWUmsatz', umsaetze);
  }
}
