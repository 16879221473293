import { HWAddress } from 'apps/handwerkPWA/src/app/entities/repository/HWAddress';
import { assignIfPropertyExsits, isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { HWDatenblatt } from '../../repository/HWDatenblatt';

export class Anlagendaten {
  // Propertyname /  Bezeichner im Handwerk
  ANLAGE: string = null; // Nummer
  SUCH: string = null; // Suchbgeriff= null;
  VERTRAG: string = null;
  DATENBLNR: string = null; // "Nummer des Anlagytyps"
  BEZ: string = null; // Name des Anlagentyps
  Anlagentyp: string = null; // Der String wird selbst zusammengebaut aus nummer und name des anlagetyps
  REPORT: string = null; // Messblatt= null;
  SERIENNR: string = null; // Seriennummer= null;
  RECHANSCHR: string = null; // Rechnungsempfänger - Kundennummer des Kunden der Rechnungsanschrift
  Rechnungsanschrift: HWAddress; // RechnungsempfängerObjekt
  LIEFANSCHR: string = null; // Lieferanschrift - Kundennummer des Kunden der Lieferanschrift
  Lieferanschrift: HWAddress; // LieferanschriftObjekt
  KUNDE: string = null; // kundennummer des kunden der eingetragen ist
  Kundenobjekt: HWAddress;
  constructor(kunden: HWAddress[], datenblaetter: HWDatenblatt[], data: any) {
    assignIfPropertyExsits(this, data);
    this.addAdressObjects(kunden);
    this.Anlagentyp = this.buildAnlagetyp(datenblaetter);
  }

  /**@description Fügt basierend auf den vorhandenen kundenummern die eigentlichen Adressobjekte hinzu */
  private addAdressObjects(kunden: HWAddress[]) {
    const mainKunde = kunden.find(searchKunde => searchKunde.KU_NR === this.KUNDE);
    this.Kundenobjekt = mainKunde;
    const rechnungsAnschrift = kunden.find(searchKunde => searchKunde.KU_NR === this.RECHANSCHR);
    this.Rechnungsanschrift = rechnungsAnschrift;
    const lieferanschrift = kunden.find(searchKunde => searchKunde.KU_NR === this.LIEFANSCHR);
    this.Lieferanschrift = lieferanschrift;
  }

  private buildAnlagetyp(datenblaetter: HWDatenblatt[]): string {
    const datenblatt = datenblaetter.find(blatt => blatt.NR === this.DATENBLNR);
    if (!datenblatt) return 'Nicht eingetragen';
    return datenblatt.NR + ' - ' + (datenblatt.BEZ || 'Nicht eingetragen');
  }
}
