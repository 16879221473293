export const environment = {
  production: true,
  configurationname: 'prod',
  firebase: {
    apiKey: 'AIzaSyDE-JBQe89h94OHYZZOv7bihaoF-wpCgn4',
    authDomain: 'api-project-104282284644.firebaseapp.com',
    databaseURL: 'https://api-project-104282284644.firebaseio.com',
    projectId: 'api-project-104282284644',
    storageBucket: 'api-project-104282284644.appspot.com',
    messagingSenderId: '104282284644',
    appId: '1:104282284644:web:9e41a6d6446411f763f571',
    measurementId: 'G-XTJYRQKKNH',
  },
};
