import { IndexedDBTypes } from './dbType';
import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';

export class HWDatenblatt extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWDatenblatt') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.DataField('string') ABESCHR: string = null;
  @IndexedDBTypes.DataField('string') BEZ: string = null;
  @IndexedDBTypes.DataField('string[]') DEFAULTS: string[] = [];
  @IndexedDBTypes.DataField('string[]') EINHEITEN: string[] = [];
  @IndexedDBTypes.DataField('string[]') FELDER: string[] = [];
  @IndexedDBTypes.DataField('string') NR: string = null;
  @IndexedDBTypes.DataField('string') RCIDATE: string = null;
  @IndexedDBTypes.DataField('number') RCIUSER: number = null;
  @IndexedDBTypes.DataField('string') REPORT: string = null;
  @IndexedDBTypes.DataField('string') RTFDATA: string = null;
  @IndexedDBTypes.DataField('string') TXDATA: string = null;
  @IndexedDBTypes.DataField('string') TXFIELDS: string = null;
  @IndexedDBTypes.DataField('string') UUID: string = null;
  @IndexedDBTypes.DataField('string[]') VORGABEN: string[] = [];
  @IndexedDBTypes.DataField('string[]') ZUSAETZE: string[] = [];

  constructor(data: any) {
    super();
    assignIfPropertyExsits(this, data);
  }

  static toString(): string {
    return 'HWDatenblatt';
  }
}
