<dx-popup
  [width]="'100%'"
  [maxWidth]="'500px'"
  [title]="'Manueller Login'"
  [dragEnabled]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
  (onHidden)="closeMask()"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <div class="dx-fieldset loginBox">
        <div>
          <app-bss-textbox [labelText]="'UUID'" [(value)]="userInfo.uuid"></app-bss-textbox>
        </div>
        <div>
          <app-bss-textbox [labelText]="'Mandant'" [(value)]="userInfo.mandant"></app-bss-textbox>
        </div>
        <div>
          <app-bss-textbox [labelText]="'Monteurnummer'" [(value)]="userInfo.monteur"></app-bss-textbox>
        </div>
        <div>
          <app-bss-textbox
            [labelText]="'Passwort'"
            [(value)]="userInfo.pin"
            [mode]="passwordMode"
            [placeholder]="'Passwort hier eingeben'"
            [buttonIcon]="'info'"
            (buttonClick)="togglePasswordShow()"
          ></app-bss-textbox>
        </div>
        <div>
          <app-bss-textarea
            [labelText]="'Login-Url'"
            [(value)]="inputUrl"
            [maxLength]="300"
            (valueChange)="urlToUserinfo($event)"
            [placeholder]="'Alternativ Loginurl hier einfügen, dieser befüllt dann die Felder oben'"
          ></app-bss-textarea>
        </div>

        <div class="bss-button button-block primary-button" (click)="loginLastUser(userInfo)">Login</div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
