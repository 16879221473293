import { PositionSettings, Gewerk, Lieferant } from 'libs/shared/src/lib/entities';
import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';
import { positionType } from 'apps/handwerkPWA/src/app/config/Konstanten';

export class Positionrequest {
  requestType: 'Offlinepositions' | 'Liverequest';
  mandant: string = null;
  monteur: string = null;
  /** Wenn leergelassen, werden alle Positionen zurückgegeben die auf den Gewerks/Lieferantenfilter zutreffen */
  SearchText: string = '';
  Lieferanten: Lieferant[] = null;
  Gewerk: Gewerk[] = null;
  useFilterLieferanten: boolean = false;
  useFilterGewerk: boolean = false;
  handwerkTableName: string = null;
  leistungsPositionsGewerk: string = null;
  leistungsPositionsLeistungsnummer: string = null;
  type: positionType = null;
  kundenPriceGroup: string = null;
  skip?: number;
  take?: number;

  constructor(mandant: string, monteur: string) {
    this.mandant = mandant;
    this.monteur = monteur;
  }

  isManuellePosition(): boolean {
    return (this.type as string) === 'Manuelle Position';
  }

  isKmAllowance(): boolean {
    return (this.type as string) === 'Km Pauschale';
  }

  addPositionSettingData(positionSettingData: PositionSettings) {
    assignIfPropertyExsits(this, positionSettingData);
  }

  changeType(type: positionType) {
    if (type === 'Lohn') {
      this.SearchText = '';
    }
    this.type = type;
    this.handwerkTableName = this.getHandwerkTablename(type);
  }

  getType(): positionType {
    return this.type;
  }

  /**@description Gibt den zugehörigen handwerks tabellennamen zum typ zurück */
  getHandwerkTablename(type: positionType): string {
    switch (type) {
      case 'Artikel': {
        return 'MATERIAL';
      }
      case 'Lohn': {
        return 'LOHNART';
      }
      case 'Leistung': {
        return 'LEISTUNG';
      }
      case 'Kostenart': {
        return 'KOSTART';
      }
      case 'Leistungsposition': {
        return 'LSTPOS';
      }
    }
    return 'UndefeniertP';
  }
}
