import { Component, OnDestroy, OnInit } from '@angular/core';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { calculateDataAmount, isNullOrUndefined, removeFromArray } from 'libs/shared/src/lib/helper/globalHelper';
import { AddressService } from '../../../services/dataServices/address.service';
import { AppointmentService } from 'apps/handwerkPWA/src/app/services/dataServices/appointment.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { OffenepostenService } from 'apps/handwerkPWA/src/app/services/dataServices/offeneposten.service';
import { UmsatzService } from 'apps/handwerkPWA/src/app/services/dataServices/umsatz.service';
import { BelegService } from 'apps/handwerkPWA/src/app/services/dataServices/beleg.service';
import { ContactpersonService } from 'apps/handwerkPWA/src/app/services/dataServices/contactperson.service';
import { ContactsService } from 'apps/handwerkPWA/src/app/services/dataServices/contacts.service';
import { DialogService } from '@handwerk-pwa/shared';
import { ObjectaddressService } from 'apps/handwerkPWA/src/app/services/dataServices/objectAddress.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { HWFile, HWAddress, Zusatzbezeichnungen } from 'apps/handwerkPWA/src/app/entities';
import { getAddressesFullType } from 'apps/handwerkPWA/src/app/helper/entities/HWAddress/addressTypeHelper';
import { AddressShortTypes } from '../../../config/Konstanten';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { Documentservice } from '../../../services/globalServices/document.service';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';

@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss'],
})
export class AddressEditComponent implements OnInit, OnDestroy {
  addressTypes: Array<'Kunden' | 'Freie Adressen'> = ['Kunden', 'Freie Adressen'];
  selectedType: 'Kunden' | 'Freie Adressen' = 'Kunden';
  address: HWAddress;
  addressRight: boolean;
  documentRight: boolean;
  salesRight: boolean;
  vacantPositionsRight: boolean;
  showZusatz = false;
  adressEditRight = true;
  terminAnzahl = '';
  objektAdressenAnzahl = '';
  documentsUploadable = false;
  zusatzBezeichnungen: Zusatzbezeichnungen;
  selectedFile: HWFile;
  userInfo: Userinfo;
  currentPostenStartAmount = '(?)';
  currentUmsatzStartAmount = '(?)';
  currentBelegeStartAmount = '(?)';
  currentKontakteStartAmount = '(?)';
  currentAnsprechpartnerStartAmount = '(?)';
  currentFilesAmount: string;
  laender = [
    'A',
    'B',
    'CH',
    'CZ',
    'D',
    'DK',
    'E',
    'EE',
    'F',
    'FIN',
    'GB',
    'GR',
    'H',
    'I',
    'IE',
    'L',
    'LT',
    'LV',
    'M',
    'NL',
    'NOR',
    'P',
    'PL',
    'RU',
    'S',
    'SI',
    'SK',
  ];
  viewFinished: boolean; // am ende des oninit setzen sonst wurden fälschlicherweise durch nachrendern suggeriert, das neue daten vorliegen
  dataChanged: BehaviorSubject<boolean>;
  subscription: Subscription;
  adressFullType: string;
  hasAufmass: boolean;
  createdForRepairOrder = false;

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private addressService: AddressService,
    private appointmentService: AppointmentService,
    private rechteService: RechteService,
    private offenepostenService: OffenepostenService,
    private umsatzService: UmsatzService,
    private belegService: BelegService,
    private contactpersonService: ContactpersonService,
    private contactService: ContactsService,
    private objectAdressService: ObjectaddressService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private documentService: Documentservice,
    private authorisationService: AuthorisationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.createdForRepairOrder = this.routingService.getCurrentRoute()?.includes('repairOrder');
    this.userInfo = await this.globalSettingService.getUserinfo();
    this.subscription = this.routingService.save.subscribe(() => {
      void this.save(this.createdForRepairOrder);
    });
    this.dataChanged = this.routingService.dataChanged;
    this.address = await this.getAddress();
    const isNew = this.address.KU_NR === '-1';
    if (!isNew) this.adressFullType = getAddressesFullType(this.address.ADRTYP);

    const passedCheck = await this.handleRights(isNew);
    if (!passedCheck) {
      void this.routingService.routeBack();
      return;
    }

    await this.getAdditionalAdressData(this.userInfo);
    this.hasAufmass = this.authorisationService.current.getValue().featureCheck('Aufmass').available;
    this.viewFinished = true;
  }

  private async getAdditionalAdressData(userInfo: Userinfo) {
    const kundenNummer = this.address.KU_NR;
    this.documentsUploadable = ['K', 'M', 'F', 'O'].includes(this.address.ADRTYP);
    const currentPosten = await this.offenepostenService.getOffenePostenByKundennummerFromIDB(kundenNummer);
    this.currentPostenStartAmount = calculateDataAmount(currentPosten);
    const newVersion = this.authorisationService.current.getValue().featureCheck('Medientabelle2').available;
    const files = newVersion
      ? await this.documentService.getAllBy('Baseuuid', this.address.getUuid())
      : this.address.Files;
    this.currentFilesAmount = calculateDataAmount(files);
    const currentUmsatz = await this.umsatzService.getAllBy('Kundennummer', kundenNummer);
    this.currentUmsatzStartAmount = calculateDataAmount(currentUmsatz);
    const currentBelege = await this.belegService.getAllBy('kunde', kundenNummer);
    this.currentBelegeStartAmount = calculateDataAmount(currentBelege);
    const currentContacts = await this.contactService.getAllBy('KU_NR', kundenNummer);
    this.currentKontakteStartAmount = calculateDataAmount(currentContacts);
    const currentAnsprechpartner = await this.contactpersonService.getAllBy('KuNr', kundenNummer);
    this.currentAnsprechpartnerStartAmount = calculateDataAmount(currentAnsprechpartner);
    const currentObjektAdresse = await this.objectAdressService.getAllBy('KuNr', kundenNummer);
    this.objektAdressenAnzahl = calculateDataAmount(currentObjektAdresse);
    this.zusatzBezeichnungen = await this.globalSettingService.getEntity<Zusatzbezeichnungen>('Zusatzbezeichnungen');
    const kundenTermine = await this.appointmentService.getAllTermineFromIDB(userInfo, kundenNummer);
    this.terminAnzahl = `(${kundenTermine.length})`;
  }

  private async getAddress(): Promise<HWAddress> {
    if (this.routingService.getCurrentRoute().includes('neu')) return await this.addressService.createNewAddress();
    const addressGuid = this.routingService.getRouteParam('guid');
    return await this.addressService.findOneBy('Guid', addressGuid);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  selectedNextType(type: 'Kunden' | 'Freie Adressen', address: HWAddress): void {
    if (type === 'Kunden') address.ADRTYP = 'K';
    if (type === 'Freie Adressen') address.ADRTYP = 'F';
  }

  /**@description Bezieht und setzt die Rechte  */
  private async handleRights(isNewAdress: boolean): Promise<boolean> {
    const editCreateFrei = this.hasAdressChangeRight('F');
    const editCreateKunden = this.hasAdressChangeRight('K');
    const creationRight = editCreateFrei || editCreateKunden;
    if (isNewAdress && !creationRight) {
      await this.dialogService.openErrorMessage('Fehler', 'Ihnen fehlt das Recht zur Anlage einer neuen Adresse.');
      return false;
    }
    if (!editCreateFrei) this.addressTypes = removeFromArray(this.addressTypes, 'Freie Adressen');
    if (!editCreateKunden) this.addressTypes = removeFromArray(this.addressTypes, 'Kunden');
    if (isNewAdress) {
      this.selectedType = this.addressTypes[0];
      this.selectedNextType(this.selectedType, this.address);
    }
    this.documentRight = this.rechteService.hasRightOrSettingEnabled('showBelege');
    this.salesRight = this.rechteService.hasRightOrSettingEnabled('showUmsatz');
    this.vacantPositionsRight = this.rechteService.hasRightOrSettingEnabled('showOffenePosten');
    const typ = this.address.ADRTYP;
    this.adressEditRight = isNewAdress ? true : this.hasAdressChangeRight(typ);
    return true;
  }

  hasAdressChangeRight(typ: AddressShortTypes): boolean {
    switch (typ) {
      case 'F':
        return this.rechteService.hasRightOrSettingEnabled('editFreiAdressen');
      case 'K':
        return this.rechteService.hasRightOrSettingEnabled('editKunden');
      case 'L':
        return this.rechteService.hasRightOrSettingEnabled('editLieferanten');
      case 'M':
        return this.rechteService.hasRightOrSettingEnabled('editMitarbeiter');
      case 'O':
        return this.rechteService.hasRightOrSettingEnabled('editKunden');
    }
  }

  async save(createdForRepairOrder: boolean): Promise<void> {
    void this.dialogService.openLoadingDialog('Speichern', 'Speichere Datensatz.');
    if (isNullOrUndefined(this.address.NAME)) {
      this.dialogService.closeLoadingDialog();
      this.dialogService.openInformDialog('Fehler', 'Es wurde noch kein Name eingegeben.', 'Ok');
      return;
    }
    this.address.SUCH = this.address.NAME.toLocaleUpperCase();
    const newAddress = await this.addressService.save(this.userInfo, this.address);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    const customerGuid = newAddress.Guid;
    const nextUrl = createdForRepairOrder
      ? `reparaturauftrag/neu/adresse/${customerGuid}`
      : `adresse/${customerGuid}/edit`;
    await this.routingService.navigateTo(nextUrl);
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  async newAppointment(): Promise<void> {
    const dateInMs = new Date().getTime();
    await this.routingService.navigateTo(`termin/neu/${dateInMs}/${this.address.KU_NR}`);
  }

  async newImages(): Promise<void> {
    await this.routingService.navigateTo(`medien/Address/${this.address.Guid}/upload`);
  }

  openMaps(item: HWAddress): void {
    const ort = item.ORT;
    const strasse = item.STRASSE;
    window.open(`https://www.google.com/maps/search/?api=1&query=${strasse}%2C+${ort}_blank`);
  }
}
