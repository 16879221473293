import { Component, Input } from '@angular/core';
import { HWTermin } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
})
export class AppointmentCardComponent {
  @Input() termin: HWTermin;
  @Input() showDate = false;

  constructor(private routingService: RoutingService) {}

  /**@description Wenn auf einen konkreten Termin geklickt wird */
  async goToTermindetail(): Promise<void> {
    await this.routingService.navigateTo('termin/' + this.termin.id);
  }
}
