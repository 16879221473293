<div class="content-block bottom-padding">
  <app-bss-multiView *ngIf="multiViewSites" [multiViewItems]="multiViewSites"></app-bss-multiView>
</div>

<dx-speed-dial-action
  *ngIf="hasAufmass"
  icon="home"
  label="Neues Raumaufmaß"
  [index]="5"
  (onClick)="navigateTo('aufmass/neu')"
></dx-speed-dial-action>
<dx-speed-dial-action
  icon="columnproperties"
  label="Neuer Reparaturauftrag"
  [index]="4"
  (onClick)="navigateTo('reparaturauftrag/neu')"
></dx-speed-dial-action>
<dx-speed-dial-action
  icon="user"
  label="Neue Adresse"
  [index]="3"
  (onClick)="navigateTo('adresse/neu')"
></dx-speed-dial-action>
<dx-speed-dial-action
  icon="clock"
  label="Neuer Termin"
  [index]="2"
  (onClick)="navigateTo('termin/neu')"
></dx-speed-dial-action>
<dx-speed-dial-action
  icon="message"
  label="Neue Nachricht"
  [index]="1"
  (onClick)="navigateTo('nachricht/neu')"
></dx-speed-dial-action>
