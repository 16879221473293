import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-bss-measuring-field',
  templateUrl: './bss-measuring-field.component.html',
  styleUrls: ['./bss-measuring-field.component.scss'],
})
export class BssMeasuringFieldComponent implements OnInit {
  @Input() minusAllowed = true;
  @Input() messwert: string;
  @Input() label: string;
  @Input() unit: string;
  @Input() fieldIndex: number;
  @Input() sollwert: string;
  @Input() disabled = false;
  @Input() noSollwert = true;
  @Input() title = 'Messwert eintragen';
  @Input() placeholder: string;
  @Input() rtlEnabled = false;
  @Input() beginningEmpty = false;
  @Input() id = 'measureField';
  @Input() useDxTemplate = false;
  @Input() keyboardPosition: string;
  @Input() jumpToNext = true;
  /**Eingabe eines Distanzmessgeräts über Bluetooth */
  @Input() distanceMeterMode = false;
  @Output() messwertChange = new EventEmitter<string>();
  @Output() keyboardOpen = new EventEmitter<boolean>();
  measuringFieldClass = 'measuringField';
  measurementfunc = (): number => parseFloat(this.messwert?.replace(',', '.'));

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    if (isNullOrUndefined(this.messwert) && !this.beginningEmpty) {
      this.measuringFieldClass = 'measuringFieldDisplayonly';
    }
  }

  getDecimalPlacesLength(): number {
    return this.messwert?.replace(',', '.')?.split('.')[1]?.length
      ? this.messwert?.replace(',', '.')?.split('.')[1]?.length
      : 2;
  }

  valueChanged(messwert: string | number): void {
    messwert = messwert.toString();
    this.messwert = messwert;
    this.routingService.dataChanged.next(true);
    this.clickNextMeasuringfield(this.fieldIndex, this.jumpToNext);
    this.messwertChange.emit(messwert);
  }

  /**@description Springt das nächste Messfeld an */
  clickNextMeasuringfield(currentFieldindex: number, jumpToNext: boolean): void {
    const nextFieldId = this.id + (currentFieldindex + 1);
    const nextField = document.getElementById(nextFieldId);
    if (isNullOrUndefined(nextField) || !jumpToNext) return;
    const inputField = nextField.firstElementChild as HTMLElement;
    inputField.click();
  }
}
