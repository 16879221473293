<div class="content-block calendar">
  <div class="form-block">
    <h2>Kalender</h2>

    <div class="dx-card responsive-paddings">
      <div class="flexContainer" style="margin-bottom: 10px">
        <div style="padding-right: 10px">
          <dx-button stylingMode="contained" text="heute" type="normal" (onClick)="goToToday()"> </dx-button>
        </div>
        <div class="flexContainerItem">
          <app-bss-selectbox
            [items]="calendarFilter"
            [value]="calendarFilter[0]"
            (selectedValue)="filterChange($event, selectedDate)"
          ></app-bss-selectbox>
        </div>
        <div>
          <app-bss-search-popup
            [icon]="'search'"
            [headline]="'Geben Sie einen Suchbegriff ein'"
            [inputDataSource]="allAppointments"
            [customDisplayExpressions]="['kundenName', 'caption', 'message', 'mitarbeiterName', 'location']"
            (selectedValue)="onAppointmentInSearchSelected($event)"
          >
          </app-bss-search-popup>
        </div>
      </div>
      <div>
        <dx-calendar
          #calendar
          id="calendar-container"
          [value]="currentValue"
          [zoomLevel]="'month'"
          [maxZoomLevel]="'month'"
          [minZoomLevel]="'year'"
          [showTodayButton]="true"
          (onValueChanged)="onValueChange($event.value)"
          (onOptionChanged)="onOptionChanged($event.name, $event.value, $event.previousValue)"
          [width]="'100%'"
        >
          <span *dxTemplate="let cell of 'custom'">
            {{ cell.text }}
          </span>
        </dx-calendar>
      </div>
    </div>
    <app-bss-list
      [id]="'calender'"
      [inputDatasource]="currentFilteredAppointments"
      [searchEnabled]="false"
    ></app-bss-list>
  </div>
</div>

<dx-speed-dial-action
  icon="add"
  class="add-button"
  label="Neuer Termin"
  [index]="1"
  (onClick)="navigateTo('termin/neu')"
></dx-speed-dial-action>
