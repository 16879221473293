import { Component, OnInit } from '@angular/core';
import { AddressService } from '../../../services/dataServices/address.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { OffenepostenService } from '../../../services/dataServices/offeneposten.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { HWOffeneposten } from '../../../entities';

@Component({
  selector: 'app-address-vacant-positions',
  templateUrl: './address-vacant-positions.component.html',
  styleUrls: ['./address-vacant-positions.component.scss'],
})
export class AddressVacantPositionsComponent implements OnInit {
  searchExpressions = ['betreff', 'datum', 'brutto', 'netto', 'mahnst', 'bezahlt'];
  vacantPositions: HWOffeneposten[] = [];

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private offenepostenService: OffenepostenService,
    private routingService: RoutingService,
    private addressService: AddressService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAddressVacantPositions();
  }

  private async loadAddressVacantPositions(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserinfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    const address = await this.addressService.findOneBy('Guid', addressGuid);
    this.vacantPositions = await this.offenepostenService.getOffenePostenByKundennummerByChoice(
      userInfo,
      address.KU_NR
    );
  }
}
