import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';

export class Adressrights {
  showKunden = true;
  editKunden = true;
  showLieferanten = true;
  editLieferanten = true;
  showMitarbeiter = true;
  editMitarbeiter = true;
  showFreieAdressen = true;
  editFreiAdressen = true;

  constructor(adressRights: Adressrights) {
    assignIfPropertyExsits(this, adressRights);
  }

  restrictEverything() {
    this.showKunden = true; // nach neuem Verhalten immeer an
    this.editKunden = false;
    this.showLieferanten = false;
    this.editLieferanten = false;
    this.showMitarbeiter = false;
    this.editMitarbeiter = false;
    this.showFreieAdressen = false;
    this.editFreiAdressen = false;
  }

  allowEverything() {
    this.showKunden = true;
    this.editKunden = true;
    this.showLieferanten = true;
    this.editLieferanten = true;
    this.showMitarbeiter = true;
    this.editMitarbeiter = true;
    this.showFreieAdressen = true;
    this.editFreiAdressen = true;
  }
}
