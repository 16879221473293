import { assignIfPropertyExsits } from 'libs/shared/src/lib/helper/globalHelper';

export class Anlagentermin {
  INTERVALL: number = null; // Wartungszyklus: string = null ; - scheinbar in Tagen - bei 2 montaten steht hier 60
  ANLAGEBEG: string = null; // Beginn Wartung
  ISTART: string = null; // IntervallBeginntAb: string = null ;
  ANLAGEENDE: string = null; // EndeWartung: string = null ;
  LWARTUNG: string = null; // LetzteWartung: string = null ;
  NWARTUNG: string = null; // NaechsteWartung: string = null ;
  USELWTONW: boolean = null; // Letzte Wartung für Intervall verwenden
  USERTTONW: boolean = null; // LetztenTatsaechlichenTermin
  NOWARTUNG: boolean = null; // KeineWartung: boolean = null ;
  Benachrichtigung: string = null; // ?
  INBETRIEB: string = null; // Inbetriebnahme: string = null ;
  VGARANTIE: string = null; // GarantieVon: string = null ;
  BGARANTIE: string = null; // GarantieBis: string = null ;
  KUENDAM: string = null; // GekündingtAm: string = null ;
  IntervallReadable: string = null; // GekündingtAm: string = null ;
  NACHRICHT: number = null;

  constructor(data: any) {
    assignIfPropertyExsits(this, data);
    this.cleanTimeentrys();
    this.IntervallReadable = this.getReadableIntervall();
    this.Benachrichtigung = this.getBenachrichtigung();
  }

  private getBenachrichtigung(): string {
    const benachrichtigungsNumber = this.NACHRICHT;
    switch (benachrichtigungsNumber) {
      case 0: {
        return 'Telefonisch';
      }
      case 1: {
        return 'Schriftlich';
      }
      case 2: {
        return 'Fax';
      }
      case 3: {
        return 'E-Mail';
      }
      case 4: {
        return 'Keine Benachrichtigung';
      }
      case 5: {
        return 'Ohne Planung';
      }
    }
    return 'UndefiniertN';
  }

  private getReadableIntervall(): string {
    const intervall = this.INTERVALL;
    switch (intervall) {
      case 0: {
        return 'Kein Zyklus';
      }
      case 7: {
        return 'Wöchentlich';
      }
      case 30: {
        return 'Monatlich';
      }
      case 60: {
        return 'Alle 2 Monate';
      }
      case 91: {
        return 'Alle 3 Monate';
      }
      case 121: {
        return 'Alle 4 Monate';
      }
      case 151: {
        return 'Alle 5 Monate';
      }
    }
    return 'Alle ' + intervall + ' Tage';
  }

  cleanTimeentrys() {
    const propertyArray = Object.getOwnPropertyNames(this);
    for (const property of propertyArray) {
      let value = this[property];
      if (typeof value === 'string') {
        value = (value as string).replace(' 00:00:00', '');
        this[property] = value;
      }
    }
  }
}
