<app-bss-search-popup
  [buttonText]="roomEntityType + ' wählen'"
  [buttonStyle]="'bss-button block-button primary-button fullWidth'"
  [headline]="'Wählen Sie einen ' + roomEntityType + '-namen aus'"
  [inputDataSource]="raumNamen"
  [customDisplayExpressions]="['Bezeichng']"
  (selectedValue)="raumbuchPosition.Bezeich = $event.Bezeichng"
>
</app-bss-search-popup>

<br />
<app-bss-textbox [labelText]="roomEntityType + ' - Bezeichnung'" [(value)]="raumbuchPosition.Bezeich"></app-bss-textbox>
<br />
<app-bss-textbox *ngIf="etage" [labelText]="'Stockwerk'" [(value)]="etage.Bezeich" [disabled]="true"></app-bss-textbox>
<br />
<app-bss-textbox *ngIf="wohnung" [labelText]="'Gehört zu'" [(value)]="wohnung.Bezeich" [disabled]="true">
</app-bss-textbox>
<br />

<button (click)="save(raumbuchPosition)" class="bss-button button-block primary-button fullWidth">Speichern</button>
