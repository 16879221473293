import { Component, OnInit, Input } from '@angular/core';
import { HWAnlage, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-service-order-card',
  templateUrl: './service-order-card.component.html',
  styleUrls: ['./service-order-card.component.scss'],
})
export class ServiceOrderCardComponent implements OnInit {
  @Input() serviceAuftrag: ServiceAuftrag;
  anlage: HWAnlage;
  @Input() extended = false;
  contactInformationArrayAnlageAnsprechpartner = ['TEL', null, null, 'StandortAnschrift', null];
  showContactBar = false;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    this.anlage = this.serviceAuftrag.AnlageObject;
    if (isNullOrUndefined(this.anlage)) throw new Error('Anlage fehlt in Serviceauftragsdaten');
  }

  async goToServiceauftragDetail(): Promise<void> {
    await this.routingService.navigateTo('wartungsauftrag/' + this.serviceAuftrag.UUID + '/edit');
  }

  toggleContactBar(): void {
    this.showContactBar = !this.showContactBar;
  }
}
