/**@description Nimmt eine Punktseparierte Version unbd gibt die passende Dezimaldarstzellung */
export function versionStringToDecimalVersion(versionString: string): number {
  const numberArray = versionString.split('.');
  let currentPower = numberArray.length - 1;
  let versionValue = 0;

  for (const numberString of numberArray) {
    const currentValue = parseInt(numberString, 10);
    const currentDecimalValue = currentValue * Math.pow(10, currentPower);
    versionValue = versionValue + currentDecimalValue;
    currentPower = currentPower - 1;
  }

  return versionValue;
}
