<div class="content-block bottom-padding" *ngIf="viewFinished">
  <div class="form-block">
    <h2>Adresse bearbeiten</h2>

    <div *ngIf="address?.LIEFERSPERRE == true" class="bss-alert bss-alert-danger">Liefersperre hinterlegt</div>
    <div *ngIf="!adressEditRight" class="bss-alert bss-alert-warning">Editierrecht nicht vorhanden</div>

    <div *ngIf="address" class="dx-card responsive-paddings">
      <fieldset [disabled]="!adressEditRight">
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Persönliche Daten</div>
          <div class="fieldbox">
            <app-bss-plain-text-box
              [labelText]="'Kundennummer'"
              [value]="address.KU_NR | replace: '-1':'Neu'"
            ></app-bss-plain-text-box>
            <app-bss-selectbox
              *ngIf="address.KU_NR === '-1'"
              [labelText]="'Adressart'"
              [items]="addressTypes"
              [disabled]=""
              [placeholder]="'Adressart'"
              [value]="selectedType"
              (selectedValue)="selectedNextType($event, address)"
            ></app-bss-selectbox>
            <app-bss-plain-text-box
              *ngIf="address.KU_NR !== '-1'"
              [labelText]="'Adressart'"
              [value]="adressFullType"
            ></app-bss-plain-text-box>
            <app-bss-textbox [labelText]="'Suchbegriff'" [(value)]="address.SUCH" [disabled]="true"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Firma / Titel'" [(value)]="address.FA_TITEL"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Name'" [(value)]="address.NAME"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Namenszusatz'" [(value)]="address.NAME2"></app-bss-textbox>
            <app-bss-datetime-box
              [labelText]="'Geburtstag'"
              [value]="address.GEBURTSTAG"
              (outStringValue)="address.GEBURTSTAG = $event"
            ></app-bss-datetime-box>
          </div>
        </div>
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Kontaktdaten</div>
          <div class="fieldbox">
            <app-bss-textbox
              [labelText]="'Telefon'"
              [buttonIcon]="'tel'"
              [(value)]="address.TEL"
              [buttonEnabled]="address?.TEL !== ''"
            ></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'Mobil'"
              [buttonIcon]="'tel'"
              [(value)]="address.FUNK_PRIV"
              [buttonEnabled]="address?.FUNK_PRIV !== ''"
            ></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'Homepage'"
              [buttonIcon]="'globe'"
              [(value)]="address.WWW"
              [buttonEnabled]="address?.WWW !== ''"
            ></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'E-Mail'"
              [buttonIcon]="'message'"
              [(value)]="address.GLOBEMAIL"
              [buttonEnabled]="address?.GLOBEMAIL !== ''"
            ></app-bss-textbox>
          </div>
        </div>
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Adresse</div>
          <div class="fieldbox">
            <app-bss-textbox [labelText]="'Ansprechpartner'" [(value)]="address.ANSPRECH"></app-bss-textbox>
            <app-bss-textbox
              [labelText]="'Straße'"
              [buttonIcon]="'map'"
              [(value)]="address.STRASSE"
              [buttonEnabled]="address?.STRASSE !== '' && address?.ORT !== ''"
              (buttonClick)="openMaps(address)"
            ></app-bss-textbox>
            <app-bss-textbox [labelText]="'Postleitzahl'" [(value)]="address.PLZ" [maxLength]="5"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Ort'" [(value)]="address.ORT"></app-bss-textbox>
            <app-bss-selectbox
              [labelText]="'Land'"
              [items]="laender"
              [value]="address.LAND"
              (selectedValue)="address.LAND = $event"
            ></app-bss-selectbox>
          </div>
        </div>
        <div class="dx-fieldset" *ngIf="address && address.KU_NR != '-1'">
          <div class="dx-fieldset-header">Weitere Informationen</div>

          <app-bss-toggle-textarea [buttonText]="'Bemerkungen'" [(value)]="address.BEMERKUNG"></app-bss-toggle-textarea>
          <app-bss-button-link
            [showHasContent]="address.Zusatz?.length != 0"
            [title]="'Zusatz'"
            [customIcon]="'assets/icons/dreieck.svg'"
            (click)="showZusatz = !showZusatz"
            [customIconClass]="showZusatz ? 'rotated180 dreieck' : ''"
          ></app-bss-button-link>
          <div *ngIf="showZusatz" style="margin-bottom: 10px">
            <div *ngFor="let zusatz of address.Zusatz; let i = index">
              <app-bss-plain-text-box
                *ngIf="zusatz != ''"
                [labelText]="zusatzBezeichnungen.AdressenZusatzBezeichnung[i]"
                [value]="address.Zusatz[i]"
              ></app-bss-plain-text-box>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="linkBox">
        <app-bss-button-link
          *ngIf="documentsUploadable"
          [title]="'Medien'"
          [path]="'medien/Address/' + address.Guid"
          [anzahl]="currentFilesAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          [title]="'Termine / Aufgaben'"
          [path]="'adresse/' + address.Guid + '/termine'"
          [anzahl]="terminAnzahl"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="address.ADRTYP === 'K' || this.address.ADRTYP === 'L'"
          [title]="'Objektadressen'"
          [path]="'adresse/' + address.Guid + '/objektadressen'"
          [anzahl]="objektAdressenAnzahl"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="vacantPositionsRight"
          [title]="'Offene Posten'"
          [path]="'adresse/' + address.Guid + '/offene-posten'"
          [anzahl]="currentPostenStartAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="salesRight"
          [title]="'Umsatz'"
          [path]="'adresse/' + address.Guid + '/umsatz'"
          [anzahl]="currentUmsatzStartAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="documentRight"
          [title]="'Belege'"
          [path]="'adresse/' + address.Guid + '/belege'"
          [anzahl]="currentBelegeStartAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="address.ADRTYP !== 'M'"
          [title]="'Ansprechpartner'"
          [path]="'adresse/' + address.Guid + '/ansprechpartner'"
          [anzahl]="currentAnsprechpartnerStartAmount"
        ></app-bss-button-link>
        <app-bss-button-link
          *ngIf="documentRight"
          [title]="'Kontakte'"
          [path]="'adresse/' + address.Guid + '/kontakte'"
          [anzahl]="currentKontakteStartAmount"
        ></app-bss-button-link>
      </div>
    </div>
  </div>
</div>

<dx-speed-dial-action
  [visible]="dataChanged | async"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (onClick)="save(createdForRepairOrder)"
></dx-speed-dial-action>

<dx-speed-dial-action
  *ngIf="hasAufmass"
  [visible]="!(dataChanged | async) && address?.KU_NR !== '-1'"
  icon="home"
  label="Neues Raumaufmaß"
  [index]="5"
  (onClick)="navigateTo('aufmass/neu/' + address.Guid)"
></dx-speed-dial-action>
<dx-speed-dial-action
  [visible]="!(dataChanged | async) && address?.KU_NR !== '-1'"
  icon="columnproperties"
  class="add-button"
  label="Neuer Reparaturauftrag"
  [index]="4"
  (onClick)="navigateTo('reparaturauftrag/neu/adresse/' + address.Guid)"
></dx-speed-dial-action>
<dx-speed-dial-action
  [visible]="!(dataChanged | async) && address?.KU_NR !== '-1'"
  icon="clock"
  class="add-button"
  label="Neuer Termin"
  [index]="3"
  (onClick)="newAppointment()"
></dx-speed-dial-action>
<dx-speed-dial-action
  [visible]="!(dataChanged | async) && address?.KU_NR !== '-1'"
  icon="add"
  class="add-button"
  label="Neue Objektadresse"
  [index]="2"
  (onClick)="navigateTo('objektadresse/neu/' + this.address.KU_NR)"
></dx-speed-dial-action>
<dx-speed-dial-action
  [visible]="!(dataChanged | async) && address?.KU_NR !== '-1'"
  icon="photo"
  class="add-button"
  label="Neues Medium"
  [index]="1"
  (onClick)="newImages()"
></dx-speed-dial-action>
