import { Injectable } from '@angular/core';
import { isEmptyGuiData, isNullOrUndefined, positionSettingChanged } from 'libs/shared/src/lib/helper/globalHelper';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { DialogService } from './dialog.service';
import { RestService } from './rest.service';
import { RepairOrderItemService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrderItem.service';
import { HWRepairOrderItem, Positionrequest } from 'apps/handwerkPWA/src/app/entities';
import { positionType } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { environment } from 'apps/handwerkPWA/src/environments/environment';
import {
  Role,
  Userinfo,
  PositionSettings,
  Lieferant,
  Gewerk,
  Right,
  Setting,
  rightId,
  EmailconnectionInfo,
} from 'libs/shared/src/lib/entities';
import { BehaviorSubject } from 'rxjs';
import { AuthorisationService } from './authorisation.service';

@Injectable({
  providedIn: 'root',
})
export class RechteService {
  private currentRight = new BehaviorSubject<Right>(null);

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private dialogService: DialogService,
    private restService: RestService,
    private repairOrderItemService: RepairOrderItemService,
    private authorisationService: AuthorisationService
  ) {}

  /**@description Schreibt das Setting in die Handwerksdatenbank */
  async sendSettingToHandwerkDb(setting: Setting, userInfo: Userinfo): Promise<void> {
    const pdfSettingString = isEmptyGuiData(setting.PDFSettings) ? null : JSON.stringify(setting.PDFSettings);
    const briefPapierString = isEmptyGuiData(setting.briefPapier)
      ? null
      : JSON.stringify(setting.briefPapier, Object.keys(setting.briefPapier).sort());
    const emailTextvorlageString = isEmptyGuiData(setting.emailTextvorlage)
      ? null
      : JSON.stringify(setting.emailTextvorlage);
    const textVorlagenString = isEmptyGuiData(setting.textVorlagen) ? null : JSON.stringify(setting.textVorlagen);
    let emailConnectionInfoString: string = null;
    if (!isEmptyGuiData(setting.emailConnectionInfo)) {
      const newMailconnectionInfo = new EmailconnectionInfo();
      Object.assign(newMailconnectionInfo, setting.emailConnectionInfo);
      newMailconnectionInfo.smtpPassword = btoa(newMailconnectionInfo.smtpPassword);
      emailConnectionInfoString = JSON.stringify(newMailconnectionInfo);
    }

    const pdfSettingServiceauftragString = isEmptyGuiData(setting.PDFSettingsServiceAuftrag)
      ? null
      : JSON.stringify(setting.PDFSettingsServiceAuftrag);
    const sendSettings = {
      PDFSettings: pdfSettingString,
      briefPapier: briefPapierString,
      emailTextvorlage: emailTextvorlageString,
      textVorlagen: textVorlagenString,
      mandant: userInfo.mandant,
      emailConnectionInfo: emailConnectionInfoString,
      PDFSettingsServiceAuftrag: pdfSettingServiceauftragString,
    };
    await this.restService.returnData(userInfo, 'WritePWASettings', sendSettings);
  }

  /**@param callFromGui zeigt an, ob das Rechtegui anfragt - dieses hat keine IDB - deswegen die Logiken der PWA hier aushebeln */
  async getSettingFromHandwerkDb(userInfo: Userinfo, callFromGui: boolean, silent: boolean): Promise<Setting> {
    const settingData = await this.restService.returnData<Setting>(
      userInfo,
      'ReadPWASettings',
      userInfo.mandant,
      silent
    );
    const curentSettingData = callFromGui ? null : await this.getEinstellungenFromIDB();
    const wartungServiceForSettingsAvailable =
      callFromGui || this.authorisationService.current.getValue().featureCheck('Wartung und Service').available;
    const aufmassAvailable =
      callFromGui || this.authorisationService.current.getValue().featureCheck('Aufmass').available;
    const setting = new Setting(settingData, wartungServiceForSettingsAvailable, aufmassAvailable);
    // wenn die sliderorder schon vorhanden, dann nicht überschreiben
    if (!callFromGui) {
      if (!isNullOrUndefined(curentSettingData)) setting.sliderOrder = curentSettingData.sliderOrder;
    }
    return setting;
  }

  private async writeRechteEinstellungenToIDB(recht: Right, setting: Setting) {
    await this.globalSettingService.setEntity(recht, 'Rechte');
    await this.globalSettingService.setEntity(setting, 'Einstellungen');
  }

  /**@description True, wenn keine sinnvollen ResponseDaten da sind */
  private noUsableResponseData(responseData: object[]): boolean {
    if (isNullOrUndefined(responseData)) return true;
    if (((responseData as unknown) as string) === '503') return true;
    if (responseData.length === 0) return true;
    return false;
  }

  async getEinstellungenUndRechteFromHandwerk(
    userInfo: Userinfo,
    showDialog: boolean,
    actOnNewPositionsettings: boolean
  ): Promise<Right> {
    if (showDialog)
      void this.dialogService.openLoadingDialog(
        'Synchronisation',
        '...aktualisiere Rechte und Einstellungen des angemeldeten Nutzers...'
      );
    const oldRights = this.getCurrentRight();
    const oldPositionSettings = oldRights?.PositionSettings;
    const right = await this.getEmployeesRightsFromHandwerkdb(userInfo, !showDialog);
    if (isNullOrUndefined(oldRights) || !right.areRightsEqual(oldRights)) this.updateCurrentRight(right);
    const newPositionSettings = right?.PositionSettings;
    const setting = await this.getSettingFromHandwerkDb(userInfo, false, !showDialog);
    await this.writeRechteEinstellungenToIDB(right, setting);
    if (showDialog) this.dialogService.closeLoadingDialog();
    if (actOnNewPositionsettings)
      await this.actOnNewPositionsettings(oldPositionSettings, newPositionSettings, right, userInfo);
    return right;
  }

  private async getEmployeesRightsFromHandwerkdb(userInfo: Userinfo, silent = false): Promise<Right> {
    const role = await this.getEmployeesRoleFromHandwerkdb(userInfo, silent);
    if (environment.configurationname === 'dev') role.Right.Employeerights.showObjektadressen = true; // LIZENZ HARDCODED AN
    return role.Right;
  }

  /**@description Vergleicht die Positionssettings zuvor und aktuell. Ist ein Unterschied festgestellt, wird der Nutzer auf eine aktualisierung seiner Positionen hingewiesen */
  private async actOnNewPositionsettings(
    oldPositionSettings: PositionSettings,
    newPositionSettings: PositionSettings,
    rechte: Right,
    userInfo: Userinfo
  ) {
    if (positionSettingChanged(oldPositionSettings, newPositionSettings, 'Lieferant')) {
      const answer = await this.dialogService.openConfirmDialog(
        'Änderungen',
        'Die Artikel auf Ihrem Gerät sollten aktualisiert werden. Möchten Sie die Artikel jetzt synchronisieren? (Unter Einstellungen können Sie die Synchronisation manuell auslösen.)',
        'Synchronisieren',
        'Nicht synchronisieren',
        false
      );
      if (answer === true) {
        const art = 'Artikel';
        await this.repairOrderItemService.getOfflinePositionsFromWebservice(rechte, userInfo, art);
      }
    }
    if (positionSettingChanged(oldPositionSettings, newPositionSettings, 'Gewerk')) {
      const answer = await this.dialogService.openConfirmDialog(
        'Änderungen',
        'Die Offline-Leistungen auf Ihrem Gerät sollten aktualisiert werden. Möchten Sie die Leistungen jetzt synchronisieren? (Unter Einstellungen können Sie die Synchronisation manuell auslösen)',
        'Synchronisieren',
        'Nicht synchronisieren',
        false
      );
      if (answer === true) {
        const art = 'Leistung';
        await this.repairOrderItemService.getOfflinePositionsFromWebservice(rechte, userInfo, art);
      }
    }
  }

  async getEinstellungenFromIDB(): Promise<Setting> {
    return await this.globalSettingService.getEntity<Setting>('Einstellungen');
  }

  getCurrentRight(): Right {
    return this.currentRight.value;
  }

  getCurrentRightAsync(): BehaviorSubject<Right> {
    return this.currentRight;
  }

  async saveSettingLocally(einstellung: Setting): Promise<void> {
    await this.globalSettingService.setEntity(einstellung, 'Einstellungen');
  }

  async saveRightLocally(rechte: Right): Promise<void> {
    await this.globalSettingService.setEntity(rechte, 'Rechte');
  }

  /**@description Holt alle  Rollen vom Handwerk - gibt default Admin und restrcited mit */
  async getAllRolesFromHandwerkDb(userInfo: Userinfo): Promise<Role[]> {
    const responseData = await this.restService.returnData<Role[]>(userInfo, 'ReadHandwerkPWARoles', userInfo.mandant);
    const newRoles: Role[] = [];
    this.addAdminAndRestrictedRole(newRoles);
    if (this.noUsableResponseData(responseData)) return newRoles;
    for (const role of responseData) {
      const rightData: Right = role.Right;
      const rightStringParsed = JSON.parse((rightData as unknown) as string) as Right;
      const right = new Right(rightStringParsed);
      const roleNameString = role.roleName;
      const newRole = new Role(right, roleNameString);
      newRoles.push(newRole);
    }
    return newRoles;
  }

  /**@description Fügt die beiden Standard Rollen hinzu */
  private addAdminAndRestrictedRole(newRoles: any[]) {
    const adminRight = new Right(null);
    adminRight.createSuperRight();
    const restrictedRight = new Right(null);
    restrictedRight.createRestrictedRight();
    const admin = new Role(adminRight, 'Administrator');
    const restricted = new Role(restrictedRight, 'Restriktiert');
    newRoles.push(admin);
    newRoles.push(restricted);
  }

  /**@description Sendet eine neue Rolle zum Handwerk */
  async sendRoleToHandwerkDb(Role: Role, userInfo: Userinfo): Promise<void> {
    delete Role.Right.Employeerights['showObjektadressen'];
    const rightString = JSON.stringify(Role.Right);
    const sendRole = { roleName: Role.roleName, Right: rightString, mandant: userInfo.mandant };
    await this.restService.returnData(userInfo, 'WriteHandwerkPWARole', sendRole);
  }

  /**@description Löscht eine Rolle in der handwerksDb */
  async deleteRoleInHandwerkDB(role: Role, userInfo: Userinfo): Promise<void> {
    const rightString = JSON.stringify(role.Right);
    const sendRole = { roleName: role.roleName, Right: rightString, mandant: userInfo.mandant };
    await this.restService.returnData(userInfo, 'DeleteHandwerkPWARole', sendRole);
  }

  /**@description Schickt die userinfo zum Webservice - dafür muss die Rolle als String geparsed werden */
  async sendEmployeeRoleToHandwerkdb(userInfo: Userinfo): Promise<void> {
    delete userInfo.Role.Right.Employeerights['showObjektadressen'];
    const roleString = JSON.stringify(userInfo.Role);
    const formatedRoleString = roleString.replaceAll("'", '');
    const sendEmployee = {
      mandant: userInfo.mandant,
      employeeNumber: userInfo.employeeNumber,
      employeeName: userInfo.employeeName,
      Role: formatedRoleString,
    };
    await this.restService.returnData(userInfo, 'WriteHandwerkPWARight', sendEmployee);
  }

  async getEmployeesRoleFromHandwerkdb(userInfo: Userinfo, silent = false): Promise<Role> {
    const employeeRoleData = await this.restService.returnData<string>(
      userInfo,
      'ReadHandwerkPWARight',
      userInfo,
      silent
    );
    const employeeRoleJsonObject: Role = JSON.parse(employeeRoleData) as Role;
    const rightData: Right = employeeRoleJsonObject?.Right;
    const right = !employeeRoleData ? this.getCurrentRight() : new Right(rightData);
    const roleNameString = employeeRoleJsonObject?.roleName;
    const role = new Role(right, roleNameString);
    return role;
  }

  /***@description Holt alle Lieferanten eines Mandanten */
  async getLieferantenFromHandwerkdb(userInfo: Userinfo): Promise<Lieferant[]> {
    const userInfoAsBody = { ...userInfo };
    delete userInfoAsBody.Role; // webservice erwartet string - ist aber für die anfrage unerheblich, daher einfach löschen
    const lieferantenData = await this.restService.returnData<Lieferant[]>(userInfo, 'Lieferanten', userInfoAsBody);
    const lieferanten: Lieferant[] = [];
    for (const lieferantData of lieferantenData) {
      const lieferant = new Lieferant(lieferantData);
      lieferanten.push(lieferant);
    }
    return lieferanten;
  }

  /**@description Ermittelt die Zeit und die grobe Größe der jeweiligen ausgewählten offline Positionen */
  async testOfflinePositionSynctime(
    userInfo: Userinfo,
    positionSettings: PositionSettings,
    art: positionType
  ): Promise<void> {
    const url = 'OfflinePositions';
    const positionRequest = new Positionrequest(userInfo.mandant, userInfo.employeeNumber);
    positionRequest.addPositionSettingData(positionSettings);
    positionRequest.changeType(art);
    positionRequest.requestType = 'Offlinepositions';
    const startTime = new Date();
    const artReadable = art === 'Leistung' ? 'Leistungen' : 'Artikel';
    void this.dialogService.openLoadingDialog(
      'Synchronisation',
      '...Teste Synchronisationszeit Ihrer ausgewählten offline ' + artReadable + '...'
    );
    const offlinePositions = await this.restService.returnData<HWRepairOrderItem[]>(userInfo, url, positionRequest);
    this.dialogService.closeLoadingDialog();
    const endTime = new Date();
    const timeDifferenceInSeconds = (endTime.getTime() - startTime.getTime()) / 1000;
    let timeValue = timeDifferenceInSeconds.toString() + ' Sekunden.';
    if (timeDifferenceInSeconds > 60) {
      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      const seconds = Math.floor(timeDifferenceInSeconds % 60);
      timeValue = minutes + ' Minuten und ' + seconds + ' Sekunden.';
    }
    const roughObjSize = JSON.stringify(offlinePositions)?.length;
    const roughObjSizeInMb = Math.floor(roughObjSize / 1000000);
    const presentObjectSizeInMb = roughObjSizeInMb === 0 ? 1 : roughObjSizeInMb;
    this.dialogService.openInformDialog(
      'Ergebnis',
      'Die Synchronisation Ihrer aktuell ausgewählten offline ' +
        artReadable +
        ' betrug ' +
        timeValue +
        ' Die übertragenen Daten sind ca. ' +
        presentObjectSizeInMb +
        'MB groß.',
      'Ok'
    );
  }

  async getGewerkeFromHandwerkdb(userInfo: Userinfo): Promise<Gewerk[]> {
    const userInfoAsBody = { ...userInfo };
    delete userInfoAsBody.Role; // webservice erwartet string - ist aber für die anfrage unerheblich, daher einfach löschen
    const gewerkeData = await this.restService.returnData<Gewerk[]>(userInfo, 'Gewerke', userInfoAsBody);
    const gewerke: Gewerk[] = [];
    for (const gewerkData of gewerkeData) {
      const gewerk = new Gewerk(gewerkData);
      gewerke.push(gewerk);
    }
    return gewerke;
  }

  /**@description Guckt anhand eines Identifiers ob dieses Recht verfügbar ist, bzw. diese Einstellung an ist */
  hasRightOrSettingEnabled(rightIdentifier: rightId): boolean {
    const userRight = this.getCurrentRight();
    const userHasRightOrSettingEnabled = userRight.hasRightOrSettingEnabled(rightIdentifier);
    return userHasRightOrSettingEnabled;
  }

  updateCurrentRight(right: Right): void {
    this.currentRight.next(right);
  }
}
