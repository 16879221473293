import { AufmassDrawStackelement } from './AufmassDrawStackelement';

export class AufmassDrawStack {
  raumUuid: string;
  AufmassDrawStackElements: AufmassDrawStackelement[] = [];
  gridEnabled: boolean;

  constructor(raumUuid: string, stackElements: AufmassDrawStackelement[], gridEnabled: boolean) {
    this.raumUuid = raumUuid;
    this.AufmassDrawStackElements = stackElements;
    this.gridEnabled = gridEnabled;
  }
}
