import { Component, OnInit } from '@angular/core';
import { ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { ServiceauftraegeService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceauftraege.service';
import { AppointmentService } from 'apps/handwerkPWA/src/app/services/dataServices/appointment.service';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';

@Component({
  selector: 'app-service-orders-index',
  templateUrl: './service-orders-index.component.html',
  styleUrls: ['./service-orders-index.component.scss'],
})
export class ServiceOrdersIndexComponent implements OnInit {
  serviceAuftraege: ServiceAuftrag[] = [];
  searchExpressions = [];

  constructor(
    private serviceAuftraegeService: ServiceauftraegeService,
    private maintenanceAndService: WartungsprojectsService,
    private adressService: AddressService,
    private appointmentService: AppointmentService,
    private globalsettingService: HWGolbalsettingService
  ) {}

  async ngOnInit(): Promise<void> {
    const monteure = await this.adressService.getAllBy('ADRTYP', 'M');
    const allAnlagen = await this.maintenanceAndService.getAnlagenFromIDB();
    const userInfo = await this.globalsettingService.getUserinfo();
    const termine = await this.appointmentService.getAllTermineFromIDB(userInfo);
    this.serviceAuftraege = await this.serviceAuftraegeService.getAllServiceauftraegeFromIDB(
      monteure,
      allAnlagen,
      termine
    );
  }
}
