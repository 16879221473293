import { IndexedDBTypes } from './dbType';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { AddressShortTypes } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { HWFile } from './HWFile';
import { Anschrift } from 'apps/handwerkPWA/src/app/entities/models/Anschrift';
import { Kundenpreis } from '../models/Kundenpreis';
import { BaseAdress, UuidEntity } from '../../interfaces';

export class HWAddress extends IndexedDBTypes.DbType implements BaseAdress, UuidEntity {
  @IndexedDBTypes.KlassenName('HWAddress') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;
  @IndexedDBTypes.IndexField('string') KU_NR: string;
  @IndexedDBTypes.IndexField('string') ADRTYP: AddressShortTypes;
  @IndexedDBTypes.DataField('string') BEMERKUNG: string;
  @IndexedDBTypes.DataField('string') LAND: string;
  @IndexedDBTypes.DataField('string') NAME: string;
  @IndexedDBTypes.DataField('string') NAME2: string;
  @IndexedDBTypes.DataField('string') ORT: string;
  @IndexedDBTypes.DataField('string') TEL: string;
  @IndexedDBTypes.DataField('string') FAX: string;
  @IndexedDBTypes.DataField('string') FUNK_PRIV: string;
  @IndexedDBTypes.DataField('string') ANSPRECH: string;
  @IndexedDBTypes.DataField('string') BRANCHE: string;
  @IndexedDBTypes.DataField('string') WWW: string;
  @IndexedDBTypes.DataField('string') GEBURTSTAG: string;
  @IndexedDBTypes.DataField('string') GLOBEMAIL: string;
  @IndexedDBTypes.DataField('string') PLZ: string;
  @IndexedDBTypes.DataField('string') STRASSE: string;
  @IndexedDBTypes.DataField('string') SUCH: string;
  @IndexedDBTypes.DataField('string') mandant: string;
  @IndexedDBTypes.DataField('string') status: string;
  @IndexedDBTypes.DataField('boolean') LIEFERSPERRE: boolean;
  @IndexedDBTypes.DataField('string') HERRFRAU: string;
  @IndexedDBTypes.DataField('string') FA_TITEL: string;
  @IndexedDBTypes.DataField('string') POSTFACH: string;
  @IndexedDBTypes.DataField('string') Zusatz: string[];
  @IndexedDBTypes.DataField('Files') Files: HWFile[] = [];
  @IndexedDBTypes.DataField('string') ImageNameString = '';
  @IndexedDBTypes.DataField('Date') LeaveDate: Date;
  @IndexedDBTypes.DataField('Anschrift') Anschrift: Anschrift;
  @IndexedDBTypes.DataField('Anschrift')
  ArbeitsortAnschrift: Anschrift; /** Ist ein Objekt, dass nur innerhalb eines Kunden innerhalb eines Auftrags existiert */
  /** Preisgruppe des kunden von 01-09 */
  @IndexedDBTypes.DataField('string') priceGroup: string;
  @IndexedDBTypes.DataField('Kundenpreis[]') Kundenpreise: Kundenpreis[];
  @IndexedDBTypes.IndexField('string') Guid: string;

  public getAutoKey(): string {
    return this.AutoKey;
  }

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: HWAddress) {
    super();
    // Fix for LeaveDate showing up as null in logging
    if (this.LeaveDate) this.LeaveDate = new Date(JSON.parse((this.LeaveDate as unknown) as string));
    Object.assign(this, data);
    this.cleanZusatz();
    if (isNullOrUndefined(this.Files)) {
      this.Files = [];
    }
    if (!isNullOrUndefined(this.priceGroup) && this.priceGroup.startsWith('0')) {
      this.priceGroup = this.priceGroup.replace('0', '');
    }

    this.Anschrift = new Anschrift(this.STRASSE, this.PLZ, this.ORT);
  }

  getUuid(): string {
    return this.Guid;
  }

  getKundennummer(): string {
    return this.KU_NR;
  }

  getLfdNr(): string {
    return undefined;
  }

  /**@description Säubert die Zusatzfelder */
  private cleanZusatz() {
    const cleanZusatz: string[] = [];
    if (isNullOrUndefined(this.Zusatz)) {
      return;
    }
    for (const zusatz of this.Zusatz) {
      if (!isNullOrUndefined(zusatz) && zusatz !== '') {
        cleanZusatz.push(zusatz);
      }
    }
    // falls man iregndwann doch schreiben kann wieder einkommentieren
    // while (cleanZusatz.length < 20) {
    //     cleanZusatz.push('');
    // }
    this.Zusatz = cleanZusatz;
  }

  static toString(): string {
    return 'HWAddress';
  }
}
