import { Component, OnInit } from '@angular/core';
import { AddressService } from '../../../services/dataServices/address.service';
import { HWGolbalsettingService } from '../../../services/globalServices/hwgolbalsetting.service';
import { ContactsService } from '../../../services/dataServices/contacts.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-address-contact',
  templateUrl: './address-contact.component.html',
  styleUrls: ['./address-contact.component.scss'],
})
export class AddressContactComponent implements OnInit {
  searchExpressions = ['ArtReadable', 'BETREFF', 'HERRFRAU', 'ANSPRECH', 'BEMERKUNG', 'NAME', 'VON'];
  contacts = [];

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private contactService: ContactsService,
    private routingService: RoutingService,
    private addressService: AddressService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAddressContacts();
  }

  private async loadAddressContacts() {
    const userInfo = await this.globalSettingService.getUserinfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    const address = await this.addressService.findOneBy('Guid', addressGuid);
    this.contacts = await this.contactService.getKontakteByKundennummerFromWebservice(userInfo, address.KU_NR);
  }
}
