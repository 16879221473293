import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';
import { HWOffeneposten } from 'apps/handwerkPWA/src/app/entities';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { isNullOrUndefinedOrEmptyArray } from 'libs/shared/src/lib/helper/globalHelper';
import { DialogService } from '@handwerk-pwa/shared';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { BaseService } from './base.service';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';

@Injectable({
  providedIn: 'root',
})
export class OffenepostenService {
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private connectionService: ConnectionService
  ) {}

  async getOffenePostenByKundennummerByChoice(userInfo: Userinfo, kundenNummer: string): Promise<HWOffeneposten[]> {
    const currentOffenePosten = await this.getOffenePostenByKundennummerFromIDB(kundenNummer);

    const isOnline = await this.connectionService.CheckOnline(ConnectionDialogues.GetData);
    if (!isOnline) return currentOffenePosten;

    const livePosten = await this.getOffenePostenByKuNrFromWebservice(userInfo, kundenNummer);
    return livePosten;
  }

  async getOffenePostenByKundennummerFromIDB(kundenNummer: string): Promise<HWOffeneposten[]> {
    return await this.baseService.getAllBy(HWOffeneposten, 'Kundennummer', kundenNummer);
  }

  /**@description Holt die jeweiligen offenen Posten.
   *  Schreibt sie in die IDB, löscht zuvor die jeweilige Tabelle in die geschrieben wird */
  async getAllOffenepostenFromWebserivce(userInfo: Userinfo): Promise<HWOffeneposten[]> {
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle offene Posten...');
    const targetUrl = `allOps/mandant/${userInfo.mandant}/username/${userInfo.monteur}/sStart/0/sCount/0`;
    const offenePostenData = await this.restService.returnData<HWOffeneposten[]>(userInfo, targetUrl);
    if (isNullOrUndefinedOrEmptyArray(offenePostenData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const offenePosten = offenePostenData.map(data => new HWOffeneposten(data));
    await this.updateOffenepostenInIdb(offenePosten);
    this.dialogService.closeLoadingDialog();
    return offenePosten;
  }

  async getOffenePostenByKuNrFromWebservice(userInfo: Userinfo, KuNr: string): Promise<HWOffeneposten[]> {
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole offene Posten...');
    const targetUrl = `ops/mandant/${userInfo.mandant}/KU_NR/${KuNr}/username/${userInfo.monteur}`;
    const allPostenData = await this.restService.returnData<HWOffeneposten[]>(userInfo, targetUrl);
    if (isNullOrUndefinedOrEmptyArray(allPostenData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const offenePosten = allPostenData.map(data => new HWOffeneposten(data));
    await this.updateOffenepostenInIdb(offenePosten);
    this.dialogService.closeLoadingDialog();
    return offenePosten;
  }

  /*******************************************************************************
   *
   * PRIVATE FUNCTIONS
   *
   ******************************************************************************** */

  private async updateOffenepostenInIdb(offenePosten: HWOffeneposten[]) {
    const kundenNummer = offenePosten[0].Kundennummer;
    await this.controllerService.deleteData('HWOffeneposten', 'Kundennummer', kundenNummer);
    await this.controllerService.setData('HWOffeneposten', offenePosten);
  }
}
