import { Component, OnInit } from '@angular/core';
import { HWAddress, HWObjectaddress } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { ObjectaddressService } from 'apps/handwerkPWA/src/app/services/dataServices/objectAddress.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-object-addresses-index',
  templateUrl: './object-addresses-index.component.html',
  styleUrls: ['./object-addresses-index.component.scss'],
})
export class ObjectAddressesIndexComponent implements OnInit {
  searchExpressions = ['Name', 'STRASSE', 'PLZ', 'ORT'];
  objectAddresses: HWObjectaddress[] = [];
  address: HWAddress;

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private routingService: RoutingService,
    private addressService: AddressService,
    private objectAdressService: ObjectaddressService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadObjectAddresses();
  }

  private async loadObjectAddresses(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserinfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    this.address = await this.addressService.findOneBy('Guid', addressGuid);
    this.objectAddresses = await this.objectAdressService.getObjektadressesForKundennummerByChoice(
      userInfo,
      this.address.KU_NR
    );
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }
}
