<h2 class="content-block">Belegdetails</h2>

<div class="content-block" *ngIf="viewFinished">
  <div class="dx-card responsive-paddings">
    <p style="font-size: 16px">
      <b>{{ typUndNummer }}</b>
    </p>

    <div class="card-detail">
      <app-address-card *ngIf="address" [address]="address"></app-address-card>
    </div>

    <div>
      <p style="font-size: 16px"><b>Positionen:</b></p>
      <app-bss-list
        [id]="'supportingDocPositions'"
        [priceRight]="priceRight"
        [inputDatasource]="belegPositionen"
        [searchExpressions]="searchExpressions"
        [emptyInputDataMessage]="'Es sind keine Positionen vorhanden'"
      >
      </app-bss-list>
    </div>
  </div>
</div>
