import { Featurename } from './Featurecheck';
export const baseWebserviceVersionString = '3.0.2.25'; // Mindest Webservice Version

export class Feature {
  name: Featurename;
  minWebserviceVersion: string;
  minHandwerkDBVersion: string;
  /**Aufsteigende Nummer um leichter Featurehierachien zu betrachten - 0: Basis, 1: Wartung Service... */
  order: number;
}

export const Basis: Feature = {
  name: 'Basis',
  minWebserviceVersion: baseWebserviceVersionString,
  minHandwerkDBVersion: '68.4',
  order: 0,
};

export const WartungSerivce: Feature = {
  name: 'Wartung und Service',
  minWebserviceVersion: '3.0.2.26',
  minHandwerkDBVersion: '70.0',
  order: 1,
};

export const Aufmass: Feature = {
  name: 'Aufmass',
  minWebserviceVersion: '3.0.2.32',
  minHandwerkDBVersion: '71.01',
  order: 2,
};

export const Medientabelle1: Feature = {
  name: 'Medientabelle1',
  minWebserviceVersion: '3.0.2.32',
  minHandwerkDBVersion: '71.01',
  order: 3,
};

export const Medientabelle2: Feature = {
  name: 'Medientabelle2',
  minWebserviceVersion: '3.0.2.99',
  minHandwerkDBVersion: '70.99',
  order: 4,
};

/**@description Holt das Featureobjekt anhand des Featurenamen */
export function getFeature(featureName: Featurename): Feature {
  switch (featureName) {
    case 'Basis':
      return Basis;
    case 'Wartung und Service':
      return WartungSerivce;
    case 'Aufmass':
      return Aufmass;
    case 'Medientabelle1':
      return Medientabelle1;
    case 'Medientabelle2':
      return Medientabelle2;
  }
}

export const Features: Feature[] = [WartungSerivce, Basis, Aufmass, Medientabelle1, Medientabelle2];
