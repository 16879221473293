import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { DialogService } from '@handwerk-pwa/shared';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RepairOrderStates } from '../../entities/models/RepairOrderStatus';
import { HWAddress, HWRepairOrder, AutolohnInformation } from 'apps/handwerkPWA/src/app/entities';
import { sortDatesortableArray } from 'apps/handwerkPWA/src/app/helper/services/repairOrderHelper';
import { AppointmentService } from './appointment.service';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

/**@description Extrahiert autolohninformationen aus den Aufträgen, die entweder Autolohnpositionen enthalten oder in denen Autolohn an ist */
export function extractAutolohninformations(repairOrders: HWRepairOrder[]): AutolohnInformation[] {
  const autoLohnInformations: AutolohnInformation[] = [];
  for (const order of repairOrders) {
    const autolohnActive =
      order.getPositionen()?.some(position => position.isAutolohnActive === true) || order.Autolohn?.isActive;
    if (!autolohnActive) continue;
    const autolohnPositions = order.getPositionen()?.filter(position => position.isAutolohnActive === true);
    const auftragsNummer = order.Nummer;
    const autolohn = order.Autolohn;
    const arbeitszeiten = order.Arbeitszeiten;
    const autolohnInformation = new AutolohnInformation(autolohn, auftragsNummer, autolohnPositions, arbeitszeiten);
    autoLohnInformations.push(autolohnInformation);
  }
  return autoLohnInformations;
}

/**@description Weist Autolohninformationen wieder zu */
export function assignAutolohnInformation(
  repairOrders: HWRepairOrder[],
  autolohnInformations: AutolohnInformation[]
): void {
  for (const autolohnInformation of autolohnInformations) {
    const orderToAssignTo = repairOrders?.find(order => order.Nummer === autolohnInformation.Auftragsnummer);
    if (isNullOrUndefined(orderToAssignTo)) continue;
    orderToAssignTo.Autolohn = autolohnInformation.Autolohn;
    orderToAssignTo.Arbeitszeiten = autolohnInformation.Arbeitszeiten;
    for (const autolohnPosition of autolohnInformation.AutolohnPositions) {
      const positionInOrder = orderToAssignTo
        .getPositionen()
        ?.find(position => position.UuidInAuftragspositionen === autolohnPosition.UuidInAuftragspositionen);
      if (positionInOrder) positionInOrder.isAutolohnActive = true;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class RepairOrderService {
  constructor(
    private controllerService: ControllerService,
    private appointmentService: AppointmentService,
    private dialogService: DialogService,
    private restService: RestService,
    private rechteService: RechteService,
    private connectionService: ConnectionService,
    private routingService: RoutingService
  ) {}

  /**
   * TODO refactoren
   */
  async findOneByGuid(uuid: string): Promise<HWRepairOrder> {
    const repairOrders: HWRepairOrder[] = [];
    const repairOrderData = await this.controllerService.getData<HWRepairOrder[]>('HWRepairOrder');
    for (const repairOrder of repairOrderData) {
      const orderKunde = new HWAddress(repairOrder.Kunde);
      const currentRepairOrder = new HWRepairOrder(repairOrder, orderKunde);
      repairOrders.push(currentRepairOrder);
    }

    return repairOrders.filter(order => order.Guid === uuid)[0];
  }

  /**@description Holt alle Reparaturaufträge.
   *  Schreibt sie in die IDB, löscht zuvor die jeweilige Tabelle in die geschrieben wird */
  async getAllReparaturauftraegeFromWebservice(
    userInfo: Userinfo,
    addresses: HWAddress[],
    showDialog = true
  ): Promise<HWRepairOrder[]> {
    await this.pushUnpushedRepairOrders(userInfo);
    if (showDialog) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole Aufträge...');
    }
    const url = 'RepAuftraege/mandant/' + userInfo.mandant + '/username/' + userInfo.monteur;
    const repairOrderData = await this.restService.returnData<HWRepairOrder[]>(userInfo, url, null, !showDialog);
    if (isNullOrUndefined(repairOrderData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const repairOrders: HWRepairOrder[] = [];
    const right = this.rechteService.getCurrentRight();
    for (const order of repairOrderData) {
      const auftragsKunde = addresses.find(kunde => kunde.KU_NR === order.KundenNummer);
      const repairOrder = new HWRepairOrder(order, auftragsKunde);
      if (repairOrder.Status === RepairOrderStates.Completed || repairOrder.Status === RepairOrderStates.Done) {
        continue;
      }
      repairOrder.PreiseAnzeigen = right.Employeesettings.showPrices;
      const containsUnterleistung = repairOrder
        ?.getPositionen()
        ?.some(position => position.getLongtype() === 'Unterleistung');
      if (containsUnterleistung) {
        const subject = repairOrder.Betreff || '';
        await this.dialogService.openErrorMessage(
          'Mehrstufige Leistung',
          'Der Auftrag ' +
            subject +
            ' ' +
            repairOrder.Nummer +
            ' enthält mehrstufige Leistung(en) und kann in der my blue:app hand:werk zur Zeit leider nicht verarbeitet werden.'
        );
        continue;
      }
      repairOrder.assignLeistungsIds();
      repairOrders.push(repairOrder);
    }
    const idbRepairOrders = await this.getAllRepairOrdersFromIDB();
    const autolohnInformations = extractAutolohninformations(idbRepairOrders);
    assignAutolohnInformation(repairOrders, autolohnInformations);
    await this.writeRepairOrderToIDB(repairOrders, true);
    this.dialogService.closeLoadingDialog();
    return repairOrders;
  }

  async acceptRepairOrder(repairOrder: HWRepairOrder, userInfo: Userinfo): Promise<HWRepairOrder> {
    repairOrder.Monteur1 = userInfo.monteur;
    void this.dialogService.openLoadingDialog('Zuordnung', '...Versuche Reparaturauftrag zuzuordnen...');
    const targetUrl = 'AcceptRepairOrder';
    const newOrderData = await this.restService.returnData<HWRepairOrder>(userInfo, targetUrl, repairOrder, true);
    const returnedRepairOrder = new HWRepairOrder(newOrderData, repairOrder.Kunde);
    this.dialogService.closeLoadingDialog();
    if (returnedRepairOrder.Monteur1 === '') return null;
    await this.updateRepairOrderInIDB(returnedRepairOrder, repairOrder.TerminId);
    return returnedRepairOrder;
  }

  /**@description Schreibt die Reparaturaufträge in die IDB */
  private async writeRepairOrderToIDB(repairOrders: HWRepairOrder[], clear: boolean) {
    if (clear) {
      await this.controllerService.clearStore('HWRepairOrder');
    }

    await this.controllerService.setData('HWRepairOrder', repairOrders);
  }

  /**@description Löscht den alten Eintrag in der IDB und schreibt einen neuen drüber */

  public async updateRepairOrderInIDB(repairOrder: HWRepairOrder, oldTerminId: string): Promise<void> {
    if (oldTerminId) await this.appointmentService.updateOrdertermin(repairOrder);
    else if (repairOrder.TerminId)
      await this.appointmentService.createLocalTerminForRepairOrder(
        repairOrder,
        repairOrder.getKunde(),
        repairOrder.TerminId
      );

    await this.deleteOrderInIdb(repairOrder);
    await this.controllerService.setData('HWRepairOrder', [repairOrder]);
  }

  private async deleteOrderInIdb(repairOrder: HWRepairOrder) {
    const guid = repairOrder.Guid;
    await this.controllerService.deleteData('HWRepairOrder', 'Guid', guid);
  }

  /**@description Holt einen Auftrag aus der IDB anhand seiner Nummer */
  async getOrderByAuftragsnummer(orderNumber: string): Promise<HWRepairOrder> {
    const repairOrders = await this.getAllRepairOrdersFromIDB(true);
    const repairOrder = repairOrders.find(order => order.Nummer === orderNumber);
    return repairOrder;
  }

  /**
   * @description Holt alle Reparaturaufträge aus der IDB, die nicht abgeschlossen sind
   * @param giveAll Wenn true gesetzt, werden auch abgeschlossene Aufträge zurückgegeben
   */
  async getAllRepairOrdersFromIDB(giveAll?: boolean): Promise<HWRepairOrder[]> {
    let repairOrders: HWRepairOrder[] = [];
    const repairOrderData = await this.controllerService.getData<HWRepairOrder[]>('HWRepairOrder');
    for (const repairOrder of repairOrderData) {
      const orderKunde = new HWAddress(repairOrder.Kunde);
      const currentRepairOrder = new HWRepairOrder(repairOrder, orderKunde);
      const notFinished =
        currentRepairOrder.Status !== RepairOrderStates.Completed &&
        currentRepairOrder.Status !== RepairOrderStates.Done;
      if (notFinished || giveAll)
        // nicht abgeschlossen
        repairOrders.push(currentRepairOrder);
    }
    repairOrders = sortDatesortableArray(repairOrders);
    return repairOrders;
  }

  /**@description Laut TopappQuelltext überschreibt diese Methode wohl bestehende Aufträge im Backend */
  async sendRepairOrderToWebservice(
    userInfo: Userinfo,
    repairOrder: HWRepairOrder,
    silent = false
  ): Promise<HWRepairOrder> {
    //Checks if user is online and saves changes to push them later
    const isOnline = await this.connectionService.CheckOnline(ConnectionDialogues.PushData);
    if (!isOnline) {
      repairOrder.edited = true;
      await this.updateRepairOrderInIDB(repairOrder, repairOrder.TerminId);
      return null;
    }
    if (!silent)
      void this.dialogService.openLoadingDialog('Reparaturauftrag', '...speichere Änderungen am Reparaturauftrag...');
    repairOrder.addArbeitEndDateInformations();
    repairOrder.Termin = repairOrder?.Termin?.substring(0, 16);
    const autolohnPositionen = extractAutolohninformations([repairOrder]);
    const positions = repairOrder.getPositionen();
    positions.forEach(position => (isNullOrUndefined(position.TkID) ? (position.TkID = -1) : position.TkID)); // Enthält keine TKID , dann muss -1 zum neu erzeugen egsetzt werden
    const response = await this.restService.returnData<HWRepairOrder>(userInfo, 'SaveAuftraege', [repairOrder], silent); // Achtung, erwartet array
    const savedOrder = await this.handleSaveResponse(response, repairOrder, autolohnPositionen);
    void this.dialogService.closeLoadingDialog();
    return savedOrder;
  }

  /**@description  Prüft ob das Speichern erfolgreich war und unternimmt ansonsten schritte, Daten zu konservieren weißt dann autolohn  */
  private async handleSaveResponse(
    response: HWRepairOrder,
    oldOrder: HWRepairOrder,
    autolohnPositionen: AutolohnInformation[]
  ): Promise<HWRepairOrder> {
    // Fehlermeldung aus dem WebService
    const alreadyClosed = response?.Betreff === 'ZschonZabgeschlossenZ';
    if (alreadyClosed) {
      await this.dialogService.openErrorMessage(
        'Fehler',
        'Der Auftrag wurde bereits abgeschlossen. Eine Synchronisation ist nicht mehr möglich!'
      );
    }
    const rejectedSuccessfully = response?.Success && response?.Status === RepairOrderStates.Rejected;
    if (alreadyClosed || rejectedSuccessfully) {
      await this.deleteOrderInIdb(oldOrder);
      this.routingService.routeBack();
      return oldOrder;
    }

    const saveFailed = isNullOrUndefined(response) || !response?.Success;
    const finalizeFailed = saveFailed && oldOrder.Status === RepairOrderStates.Completed;
    if (finalizeFailed) oldOrder.edited = true;
    if (saveFailed) {
      const oldPositions = oldOrder.getPositionen();
      for (const pos of oldPositions) pos.notUpdated = true;
      await this.updateRepairOrderInIDB(oldOrder, oldOrder.TerminId);
      return oldOrder;
    }
    const savedOrder = new HWRepairOrder(response, oldOrder.Kunde);
    savedOrder.PreiseAnzeigen = oldOrder.PreiseAnzeigen;
    savedOrder.Arbeitszeiten = oldOrder.Arbeitszeiten;
    savedOrder.assignLeistungsIds();
    assignAutolohnInformation([savedOrder], autolohnPositionen);
    await this.updateRepairOrderInIDB(savedOrder, oldOrder.TerminId);
    return savedOrder;
  }

  /**@description Sendet die AddAuftrag an den Webservice und erhält als Antwort einen Auftrag */
  async addRepairOrder(userInfo: Userinfo, repairOrder: HWRepairOrder, silent = false): Promise<HWRepairOrder> {
    const response = await this.restService.returnData<HWRepairOrder>(userInfo, 'AddAuftrag', repairOrder, silent);
    if (isNullOrUndefined(response)) {
      if (silent) return null;
      await this.dialogService.openConfirmDialog(
        'Fehler',
        'Der Auftrag konnte nicht angelegt werden. Um Aufträge anzulegen wird eine Verbindung zum Webservice benötigt',
        'Ok',
        null,
        false,
        null
      );
      return null;
    }
    response.Arbeitszeiten = repairOrder.Arbeitszeiten;
    const createdOrder = new HWRepairOrder(response, repairOrder.Kunde);
    await this.updateRepairOrderInIDB(createdOrder, null);
    return createdOrder;
  }

  /**
   * @description Überträgt zuerst nicht übertragen items ans handwerk, dann nimmt man die liste der nun zugeordneten und übertragenen items
   * und weißt sie der ursprünglichen liste der nicht übertragenen idbRepairOrders zu um sie dann abzuschließen
   */
  async pushUnpushedRepairOrders(userInfo: Userinfo): Promise<void> {
    const idbRepairOrders = await this.getAllRepairOrdersFromIDB(true);
    const untransferredOrders = idbRepairOrders?.filter(order => order.edited);
    for (const order of untransferredOrders) {
      await this.sendRepairOrderToWebservice(userInfo, order);
    }
  }
}
