import { Component, OnInit } from '@angular/core';
import { HWGolbalsettingService } from '../../services/globalServices/hwgolbalsetting.service';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { AddressService } from '../../services/dataServices/address.service';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import { RepairOrderService } from '../../services/dataServices/repairOrder.service';
import { AppointmentService } from '../../services/dataServices/appointment.service';
import { WartungsprojectsService } from 'apps/handwerkPWA/src/app/services/dataServices/wartungsprojects.service';
import { ServiceauftraegeService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceauftraege.service';
import { ObjectaddressService } from 'apps/handwerkPWA/src/app/services/dataServices/objectAddress.service';
import { ServiceAuftrag, HWObjectaddress, HWAddress, MultiViewSite, Aufmass } from 'apps/handwerkPWA/src/app/entities';
import { getSearchableAnlagen } from 'apps/handwerkPWA/src/app/helper/entities/HWAnlage/anlageSearchHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { AuthorisationService } from 'libs/shared/src/lib/services/authorisation.service';
import { getAllTermineForCustomerToday, termineToMoreSpecificDatasets } from '../../helper/services/terminHelper';
import { Sliderside } from '../../config/Konstanten';
import { compareMultiViewSite } from '../../helper/entities/HWAddress/sortHelper';
import { AufmassService } from '../../services/dataServices/aufmass.service';
import { SyncService } from '../../services/globalServices/sync.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  addressRight: boolean;
  multiViewSites: MultiViewSite[] = [];
  hasAufmass: boolean;

  constructor(
    private globalSettingService: HWGolbalsettingService,
    private rechteService: RechteService,
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private repairOrderService: RepairOrderService,
    private appointmentService: AppointmentService,
    private maintenanceAndService: WartungsprojectsService,
    private serviceAuftraegeService: ServiceauftraegeService,
    private routingService: RoutingService,
    private authorisationService: AuthorisationService,
    private aufmassService: AufmassService,
    private syc: SyncService
  ) {}

  async ngOnInit(): Promise<void> {
    this.hasAufmass = this.authorisationService.current.getValue().featureCheck('Aufmass').available;
    await this.setMultiViewDataInOrder();
    await this.syc.getNewThingsToDiscover();
  }

  /**@description Holt alle Daten die für die MultiView benötigt werden und ihre setzt Reihenfolge */
  private async setMultiViewDataInOrder() {
    const multiViewItems: MultiViewSite[] = [];

    /** Einstellungen und sonstiges */
    const rechte = this.rechteService.getCurrentRight();
    const licence = rechte.Employeerights.showObjektadressen;
    const userInfo = await this.globalSettingService.getUserinfo();
    const einstellung = await this.rechteService.getEinstellungenFromIDB();
    const sliderOrder = einstellung.sliderOrder;
    const appOnlySetting = await this.globalSettingService.getAppOnlySettings();
    const activeSliderNames: Sliderside[] = appOnlySetting.slidersSetting
      .filter(slider => slider.value === true)
      .map(slider => slider.name);

    /** Sachen die an mehreren Stellen genutzt werden */
    const allAddresses = await this.getAllAddresses();
    const alleAuftraege = await this.repairOrderService.getAllRepairOrdersFromIDB();
    const allAnlagen = await this.maintenanceAndService.getAnlagenFromIDB();
    let alleServiceAuftraege: ServiceAuftrag[] = [];

    /** Adressen */
    if (activeSliderNames.includes('Adressen')) {
      const viewAdressen = new MultiViewSite(
        'Adressen',
        allAddresses,
        '/adressen',
        'Adressen',
        'Adressen',
        sliderOrder.findIndex(entry => entry === 'Adressen'),
        'Es sind keine Adressen vorhanden'
      );
      multiViewItems.push(viewAdressen);
    }

    /** Reparaturaufträge */
    const auftragsrecht = rechte.Employeerights.showAuftrag;
    if (licence && auftragsrecht && activeSliderNames.includes('Reparaturaufträge')) {
      const viewAuftraege = new MultiViewSite(
        'Reparaturaufträge',
        alleAuftraege,
        '/reparaturauftraege',
        'Reparaturaufträge',
        'Reparaturaufträge',
        sliderOrder.findIndex(entry => entry === 'Reparaturaufträge'),
        'Es sind keine Reparaturaufträge vorhanden'
      );
      multiViewItems.push(viewAuftraege);
    }

    /** W&S Anlagen */
    if (licence && activeSliderNames.includes('W&S Anlagen')) {
      const searchAnlagen = getSearchableAnlagen(allAnlagen);
      const viewAnlagen = new MultiViewSite(
        'W&S Anlagen',
        searchAnlagen,
        '/wartungsanlagen',
        'W&S Anlagen',
        'W&S Anlagen',
        sliderOrder.findIndex(entry => entry === 'W&S Anlagen'),
        'Es sind keine W&S Anlagen vorhanden'
      );
      multiViewItems.push(viewAnlagen);
    }

    /** W&S Aufträge */
    const serviceAuftragsRecht = rechte.Employeerights.Wartungsrights.handleOrder;
    if (
      licence &&
      serviceAuftragsRecht &&
      this.authorisationService.current.getValue().featureCheck('Wartung und Service').available
    ) {
      const allTermine = await this.appointmentService.getAllTermineFromIDB(userInfo);
      const employees = allAddresses.filter(address => address.ADRTYP === 'M') as HWAddress[];
      alleServiceAuftraege = await this.serviceAuftraegeService.getAllServiceauftraegeFromIDB(
        employees,
        allAnlagen,
        allTermine
      );

      if (activeSliderNames.includes('W&S Aufträge')) {
        const viewWSAuftraege = new MultiViewSite(
          'W&S Aufträge',
          alleServiceAuftraege,
          '/wartungsauftraege',
          'W&S Aufträge',
          'W&S Aufträge',
          sliderOrder.findIndex(entry => entry === 'W&S Aufträge'),
          'Es sind keine W&S Aufträge vorhanden'
        );
        multiViewItems.push(viewWSAuftraege);
      }
    }

    /** Termin */
    if (activeSliderNames.includes('Termine')) {
      const today = dateToDatabaseDate(new Date());
      const kundenTermine = await this.appointmentService.getAllTermineFromIDB(userInfo, undefined);
      const termineForDay = getAllTermineForCustomerToday(kundenTermine);
      const moreSpecificTermineForDay = termineToMoreSpecificDatasets(termineForDay, rechte);
      const viewTermine = new MultiViewSite(
        `Termine ${today}`,
        moreSpecificTermineForDay,
        '/kalender',
        'calender',
        'Termine',
        sliderOrder.findIndex(entry => entry === 'Termine'),
        'Es sind keine Termine für heute vorhanden'
      );
      multiViewItems.push(viewTermine);
    }

    /** Aufmaß */
    if (
      licence &&
      this.authorisationService.current.getValue().featureCheck('Aufmass').available &&
      activeSliderNames.includes('Raumaufmaße')
    ) {
      let alleRaumaufmasse: Aufmass[] = [];
      alleRaumaufmasse = await this.aufmassService.getAll();
      const viewAufmasse = new MultiViewSite(
        'Raumaufmaße',
        alleRaumaufmasse,
        '/aufmasse',
        'Aufmass',
        'Raumaufmaße',
        sliderOrder.findIndex(entry => entry === 'Raumaufmaße'),
        'Es sind keine Raumaufmaße vorhanden'
      );
      multiViewItems.push(viewAufmasse);
    }

    this.multiViewSites = multiViewItems.sort(compareMultiViewSite).reverse();
  }

  private async getAllAddresses(): Promise<(HWAddress | HWObjectaddress)[]> {
    let allAddresses: (HWAddress | HWObjectaddress)[] = [];
    const HWAddresses = await this.addressService.getAllDisplayable();
    allAddresses = HWAddresses.filter(address => address.LeaveDate == null || address.LeaveDate >= new Date());
    const allObjectAdresses = await this.objectAddressService.getAll();
    allAddresses = allAddresses.concat(allObjectAdresses);
    return allAddresses;
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }
}
