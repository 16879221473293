import { Injectable } from '@angular/core';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { PWAErrorMessageAndStackTrace } from '../../entities/models/PWAErrorMessageAndStackTrace';

import { HWGolbalsettingService } from './hwgolbalsetting.service';

@Injectable({
  providedIn: 'root',
})
export class LogErrorToWebserviceService {
  constructor(private restService: RestService, private globalSettingService: HWGolbalsettingService) {}

  public async LogErrorToWebservice(error: PWAErrorMessageAndStackTrace): Promise<void> {
    const userInfo = await this.globalSettingService.getUserinfo();
    await this.restService.returnData(userInfo, 'LogPWAError', error, false);
  }
}
