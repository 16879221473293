import { Component, OnInit } from '@angular/core';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { RepairOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrder.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { RepairOrderStatus, createAllStates, HWRepairOrder } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { RepairOrderStates } from '../../../entities/models/RepairOrderStatus';

@Component({
  selector: 'app-repair-orders-index',
  templateUrl: './repair-orders-index.component.html',
  styleUrls: ['./repair-orders-index.component.scss'],
})
export class RepairOrderIndexComponent implements OnInit {
  statusTypes: RepairOrderStatus[] = createAllStates(true);
  selectedStatusType: RepairOrderStatus;
  alleAuftraege: HWRepairOrder[];
  filteredAuftraege: HWRepairOrder[];
  searchExpression = ['Nummer', 'KundenName', 'Termin', 'ArbeitsOrt', 'Betreff'];

  constructor(
    private repairOrderService: RepairOrderService,
    private globalSettingService: HWGolbalsettingService,
    private routingService: RoutingService
  ) {}

  /**@description Delay sonst sind Daten noch nicht vorhanden*/
  async ngOnInit(): Promise<void> {
    const auftraege = await this.repairOrderService.getAllRepairOrdersFromIDB();
    this.alleAuftraege = auftraege;
    this.filteredAuftraege = auftraege;
    const lastSelectionStatus = this.globalSettingService.lastRepairOrderSelectiontype;
    if (isNullOrUndefined(lastSelectionStatus)) {
      this.filterStatusTrigger(new RepairOrderStatus(RepairOrderStates.All));
    } else {
      this.filterStatusTrigger(lastSelectionStatus);
    }
  }

  filterStatusTrigger(newState: RepairOrderStatus): void {
    const index = this.statusTypes.findIndex(state => state.stateNumber === newState.stateNumber);
    this.selectedStatusType = this.statusTypes[index];
    this.filteredAuftraege = this.alleAuftraege.filter(auftrag => auftrag.Status === newState.stateNumber);
    if (newState.stateName === 'Alle') {
      this.filteredAuftraege = this.alleAuftraege;
    }
    this.globalSettingService.lastRepairOrderSelectiontype = newState;
  }

  async newRepairOrder(): Promise<void> {
    await this.routingService.navigateTo('reparaturauftrag/neu');
  }
}
