import { UUID } from 'angular2-uuid';
import { noPreviewImage } from 'apps/handwerkPWA/src/assets/images/noPreviewImage';
import { isUserAgentIOS } from 'libs/shared/src/lib/helper/deviceHelper';
import { ServiceAuftrag, HWAnlage, Aufmass } from '..';
import { allowedPictureTypes, MediumDataType, SourceOfFilelist } from '../../config/Konstanten';
import {
  readBlobAsDataUrlAsync,
  base64toBlob,
  dataUrlToBase64,
  customCompressIOs,
  customCompress,
  getUuidOfFileSource,
} from '../../helper/services/documentHelper';
import { RaumbPos } from '../models/aufmass/RaumbPos';
import { IndexedDBTypes } from './dbType';
import { HWAddress } from './HWAddress';
import { HWFile } from './HWFile';
import { HWObjectaddress } from './HWObjectaddress';
import { HWRepairOrder } from './HWRepairOrder';

export type MediaTable = 'AUFMMEDIEN' | 'AUFTRMEDIEN' | 'WSMEDIEN';

export class Medien extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('Medien') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') UUID: string;
  @IndexedDBTypes.IndexField('string') Baseuuid: string;
  /**dtText{0}, dtPicture{1}, dtPDF{2}, dtVideo{3}, dtAudio{4} */
  @IndexedDBTypes.DataField('number') Datatype: MediumDataType;
  @IndexedDBTypes.DataField('number') SortIdx: number;
  @IndexedDBTypes.DataField('number') Userid: number;
  @IndexedDBTypes.DataField('string') Beschreibung: string;
  @IndexedDBTypes.DataField('string') Data: string;
  @IndexedDBTypes.DataField('string') Orgname: string;
  @IndexedDBTypes.DataField('string') Bezeichnung: string;
  @IndexedDBTypes.DataField('string') Erstelldatum: string;
  @IndexedDBTypes.DataField('string') Geaendert: string;
  @IndexedDBTypes.DataField('boolean') Send: boolean;
  @IndexedDBTypes.DataField('string') Tablename: MediaTable;
  PreviewPictureData: string;

  constructor(data: Medien | HWFile) {
    super();
    this.UUID = UUID.UUID();
    Object.assign(this, data);
    if (!this.Bezeichnung) this.Bezeichnung = this.Orgname;
  }

  /**@description Weist die Uuid des Basisdatensatzes und somit auch den BasemediumType zu */
  addAssignment(source: SourceOfFilelist): void {
    this.Baseuuid = getUuidOfFileSource(source);
    if (source instanceof HWAddress || source instanceof HWObjectaddress)
      throw new Error('New version of mediahandling not implemented for adresses');

    /**dirty fix - aktuell besteht eine anlage keinen instance of check, da sie nur asserted zurückkommt */
    this.Tablename = 'WSMEDIEN';

    if (source instanceof Aufmass || source instanceof RaumbPos) this.Tablename = 'AUFMMEDIEN';

    if (source instanceof HWRepairOrder) this.Tablename = 'AUFTRMEDIEN';

    if (source instanceof ServiceAuftrag || source instanceof HWAnlage) this.Tablename = 'WSMEDIEN';
  }

  async setDataUrl(documentUrl: string, isText: boolean): Promise<void> {
    this.Data = documentUrl;
    this.PreviewPictureData = isText
      ? await readBlobAsDataUrlAsync(base64toBlob(dataUrlToBase64(noPreviewImage), 'image/png'))
      : documentUrl;
  }

  getOriginalName(): string {
    return this.Orgname;
  }

  static toString(): string {
    return 'Medien';
  }
}

export async function createMedium(
  file: File,
  fileName: string,
  source: SourceOfFilelist,
  description?: string
): Promise<Medien> {
  const previewPicutre = await readBlobAsDataUrlAsync(base64toBlob(dataUrlToBase64(noPreviewImage), 'image/png'));
  const medium = new Medien(null);
  medium.Send = false;
  medium.Datatype = 1;
  if (file.type === 'text/plain') {
    const dataText = await readBlobAsDataUrlAsync(file);
    medium.Data = dataUrlToBase64(dataText);
    medium.Datatype = 0;
  }
  if (file.type === 'application/pdf') {
    const dataUrlPdf = await readBlobAsDataUrlAsync(file);
    medium.Data = dataUrlToBase64(dataUrlPdf);
    medium.PreviewPictureData = previewPicutre;
    medium.Datatype = 2;
  }
  // Medien
  const cleanFileType = file.type.replace('image/', '');
  if (allowedPictureTypes.includes(cleanFileType)) {
    let compressedFile: Blob;
    if (isUserAgentIOS()) compressedFile = (await customCompressIOs(file)) as Blob;
    else compressedFile = await customCompress(file);
    const dataUrlPicture = await readBlobAsDataUrlAsync(compressedFile);
    medium.PreviewPictureData = dataUrlPicture;
    medium.Data = dataUrlPicture;
  }
  medium.Orgname = fileName;
  medium.Beschreibung = description;
  medium.addAssignment(source);
  return medium;
}

export function addMedienSignature(dataType: MediumDataType, data: string): string {
  let prefix = '';
  switch (dataType) {
    case 0: {
      prefix = 'data:text/plain;base64,';
      break;
    }
    case 1: {
      prefix = 'data:image/png;base64,';
      break;
    }
    case 2: {
      prefix = 'data:application/pdf;base64,';
      break;
    }
  }
  return prefix + data;
}
