import { Positionrequest } from 'apps/handwerkPWA/src/app/entities';
import { positionType } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';

/**@description Prüft ob die nötigen Einstellungen vorhanden sind um eine Entscheidung zu treffen */
export function cantUseFilterSettings(positionRequest: Positionrequest, type: positionType): boolean {
  if (isNullOrUndefined(positionRequest)) {
    return true;
  }
  if (type !== 'Artikel' && type !== 'Leistung') {
    return true;
  }
  if (type === 'Artikel' && isNullOrUndefined(positionRequest.Lieferanten)) {
    return true;
  }
  if (type === 'Artikel' && !positionRequest.useFilterLieferanten) {
    return true;
  }
  if (type === 'Leistung' && isNullOrUndefined(positionRequest.Gewerk)) {
    return true;
  }
  if (type === 'Leistung' && !positionRequest.useFilterGewerk) {
    return true;
  }
  return false;
}
