import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QuickButtonIcons } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { isNullOrUndefinedOrEmptyString } from '../../../helper/globalHelper';

@Component({
  selector: 'app-bss-textbox',
  templateUrl: './bss-textbox.component.html',
  styleUrls: ['./bss-textbox.component.scss'],
})
export class BssTextboxComponent {
  @Input() labelText;
  @Input() buttonIcon: QuickButtonIcons;
  @Input() value: string;
  @Input() maxLength = null;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() placeholder = '';
  @Input() mode = 'text';
  @Input() buttonEnabled = true;
  @Input() rtlEnabled = false;
  @Input() width;
  @Output() valueChange = new EventEmitter<string>();
  @Output() buttonClick = new EventEmitter<boolean>();
  @Output() focusOut = new EventEmitter<string>();

  constructor(private routingService: RoutingService) {}

  valueChanged(value: string): void {
    this.routingService.dataChanged.next(true);
    this.valueChange.emit(value);
  }

  btnClicked(icon?: QuickButtonIcons, value?: string): void {
    this.buttonClick.emit(true);
    if (!icon) return;
    switch (icon) {
      case 'globe':
        this.goToHomepage(value);
        break;
      case 'tel':
        this.callPhone(value);
        break;
      case 'message':
        this.mailTo(value);
        break;
      case 'map':
        break;
      case 'info':
        break;
    }
  }

  private callPhone(telNumber: string): void {
    if (isNullOrUndefinedOrEmptyString(telNumber)) return;
    window.open(`tel:${telNumber}`);
  }

  private mailTo(mailAdress: string): void {
    if (isNullOrUndefinedOrEmptyString(mailAdress)) return;
    window.open(`mailto:${mailAdress}`);
  }

  private goToHomepage(pageUrl: string): void {
    if (isNullOrUndefinedOrEmptyString(pageUrl)) return;
    window.open(`https://${pageUrl}`);
  }

  private openMaps(adress: string): void {
    window.open(`https://www.google.com/maps/search/?api=1&query=${adress}_blank`);
  }

  focusWentOut(value: string): void {
    this.focusOut.emit(value);
  }
}
