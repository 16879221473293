import { IndexedDBTypes } from './dbType';
import { HWAddress } from './HWAddress';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { dateToDatabaseDate } from 'libs/shared/src/lib/helper/timeHelper';
import { terminType, terminTypeNumber } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { UUID } from 'angular2-uuid';
import { Datesortable } from 'apps/handwerkPWA/src/app/interfaces';
import moment from 'moment';

export class HWTermin extends IndexedDBTypes.DbType implements Datesortable {
  @IndexedDBTypes.KlassenName('HWTermin') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') idConstruct: string;
  @IndexedDBTypes.IndexField('string') id: string;
  @IndexedDBTypes.IndexField('string') indexDate: string;
  @IndexedDBTypes.IndexField('string') mitarbeiter: string;
  /**adr ist beim termin die jeweilige kundennummer ........ */
  @IndexedDBTypes.IndexField('string') adr: string;
  /**@description Mögliche Referenz zu einem hinterlegten ReparaturAuftrag */
  @IndexedDBTypes.IndexField('string') Referenz: string;
  @IndexedDBTypes.DataField('string') bss_type: terminTypeNumber = '1';
  @IndexedDBTypes.DataField('string') caption: string;
  @IndexedDBTypes.DataField('string') eventtype = '0';
  @IndexedDBTypes.DataField('string') finish: string;
  @IndexedDBTypes.DataField('string') guid: string;
  @IndexedDBTypes.DataField('string') isprivate = 'False';
  @IndexedDBTypes.DataField('string') location: string;
  @IndexedDBTypes.DataField('string') message: string;
  @IndexedDBTypes.DataField('string') start: string;
  @IndexedDBTypes.DataField('string') state = '0';
  @IndexedDBTypes.DataField('string') taskcomplete = '0';
  @IndexedDBTypes.DataField('Date') startDate: Date;
  @IndexedDBTypes.DataField('Date') endDate: Date;
  @IndexedDBTypes.DataField('boolean') existing = false;
  @IndexedDBTypes.DataField('string') kundenName: string;
  @IndexedDBTypes.DataField('string') mitarbeiterName: string;
  @IndexedDBTypes.DataField('boolean') isStartOfSerie: boolean;
  @IndexedDBTypes.DataField('string') idx: string;
  @IndexedDBTypes.DataField('string') startUhrzeit: string;
  @IndexedDBTypes.DataField('string') endUhrzeit: string;
  /** Gibt an ob der Termin sich über mehrere Tage erstreckt (nicht zu verwechseln mit serienterminen) */
  @IndexedDBTypes.DataField('boolean') isTerminOverDays = false;
  @IndexedDBTypes.DataField('string') terminArt: terminType;
  /**@description Mögliche Referenz zu der hinterlegten W&S DokumentId (Zum suchen substring(1)) */
  @IndexedDBTypes.DataField('string') DokIdName: string = null;
  @IndexedDBTypes.DataField('string') objectType: 'HWRepairOrder' | 'HWTermin' | 'ServiceAuftrag' = 'HWTermin';
  @IndexedDBTypes.DataField('string') MA_ID_LIST: string = null;
  @IndexedDBTypes.IndexField('boolean', false, true) send = true;
  @IndexedDBTypes.IndexField('string') temporaryId: string = null;
  /**2 scheinbar "normal" , 3 scheinbar ganztägig */
  @IndexedDBTypes.IndexField('string') Options = 2;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object, date?: Date, kundennummer?: string) {
    super();
    Object.assign(this, data);
    this.setIsSerientermin();
    this.setIdConstruct();
    this.terminArt = this.geTTerminArtFromType(this.bss_type);
    if (!isNullOrUndefined(this.start)) {
      this.startDate = this.DatabaseDateToDate(this.start, true);
      this.indexDate = this.start.substring(0, 10);
      this.startUhrzeit = this.start.substring(11, 16);
    }
    if (!isNullOrUndefined(this.finish)) {
      this.endDate = this.DatabaseDateToDate(this.finish, true);
      this.endUhrzeit = this.finish.substring(11, 16);
    }
    if (isNullOrUndefined(date)) {
      return;
    }
    const dbDate = dateToDatabaseDate(date, true, false);
    this.indexDate = dateToDatabaseDate(date, false, false);
    this.start = dbDate;
    this.finish = dbDate;
    const dateWithoutSeconds = this.DatabaseDateToDate(dbDate, true);
    this.startDate = dateWithoutSeconds;
    this.endDate = dateWithoutSeconds;
    if (kundennummer) {
      this.adr = kundennummer;
    }
    this.indexDate = this.start.substr(0, 10);
  }

  getSortDate(): Date {
    return this.startDate;
  }

  geTTerminArtFromType(type: terminTypeNumber): terminType {
    switch (type) {
      case '0':
        return 'Termin';
      case '1':
        return 'Termin';
      case '2':
        return 'Auftrag';
      case '3':
        return 'Aufgabe';
      case '4':
        return 'Geburtstag';
      case '5':
        return 'Termin';
      case '6':
        return 'Wartungsrechnung';
      case '7':
        return 'Wartungstermin';
    }
  }

  setIdConstruct(newId?: string): void {
    if (newId) this.id = newId;
    if (isNullOrUndefined(this.idx)) {
      const uuid = UUID.UUID();
      const id = newId || this.id || uuid.substring(0, 3);
      this.idConstruct = id + 'Base';
      return;
    }
    this.idConstruct = this.id + 'X' + this.idx;
  }

  setIsSerientermin(): void {
    if (this.bss_type == '1' && this.eventtype == '1') {
      this.isStartOfSerie = true;
      return;
    }
    this.isStartOfSerie = false;
  }

  /**@description Fügt einem Termin die Kundeninformationen zu */
  addKundeninfo(kunde: HWAddress): void {
    this.adr = kunde.KU_NR;
    this.location = kunde.ORT;
    this.kundenName = kunde.NAME;
  }

  /**Nimmt ein formatiertes Datum im Format 31.12.2001 00:00:00 und gibt ein date zurück */
  DatabaseDateToDate(currentDateComplete: string, withMinutes?: boolean): Date {
    const formatString = !withMinutes ? 'DD.MM.YYYY' : 'DD.MM.YYYY hh:mm:ss';
    const dateMomentObject = moment(currentDateComplete, formatString);
    const dateObject = dateMomentObject.toDate();
    return dateObject;
  }

  addNewSerienterminInformation(serienTermin: HWTermin): void {
    this.start = serienTermin.start;
    this.finish = serienTermin.finish;
    this.idx = serienTermin.idx;
    this.setIdConstruct();
    this.startDate = this.DatabaseDateToDate(this.start, true);
    this.indexDate = this.start.substring(0, 10);
    this.endDate = this.DatabaseDateToDate(this.finish, true);
    this.isStartOfSerie = false; // wichtig, sonst werden rekursiv neue gesucht
  }

  /**@description Fügt einem Termin die nötigen Informationen eines Auftragstermins hinzu */
  addAuftragsinformation(monteurPersonalnummer: string, auftragsNummer: string): void {
    this.caption = 'Reparaturauftrag ' + auftragsNummer;
    this.bss_type = '2';
    this.mitarbeiter = monteurPersonalnummer;
    this.Referenz = auftragsNummer;
    this.idConstruct = auftragsNummer;
  }

  /**@description Setzt einen neuen Startzeitpunkt */
  setNewStart(startDateString: string, startTime: string): void {
    const dateParseString = this.createParsableDateString(startDateString, startTime);
    this.indexDate = startDateString;
    this.start = startDateString + ' ' + startTime;
    this.startUhrzeit = startTime;
    this.startDate = new Date(dateParseString);
  }

  /**@description Setzt einen neuen Endzeitpunkt */
  setNewEnd(endDateString: string, endTime: string): void {
    const dateParseString = this.createParsableDateString(endDateString, endTime);
    this.finish = endDateString + ' ' + endTime;
    this.endUhrzeit = endTime;
    this.endDate = new Date(dateParseString);
  }

  private createParsableDateString(startDateString: string, startTime: string) {
    const startDayArray = startDateString.split('.');
    const timePart = startTime.length < 6 ? startTime + ':00' : startTime;
    const dateParseString = startDayArray[2] + '-' + startDayArray[1] + '-' + startDayArray[0] + 'T' + timePart;
    return dateParseString;
  }

  static toString(): string {
    return 'HWTermin';
  }
}

function fullDaysBetween(startDate: Date, endDate: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const daysBetween = Math.floor((utc2 - utc1) / _MS_PER_DAY);
  return daysBetween - 1; // for full days between
}

/**
 * @description Erstreckt sich ein Termin über mehrere Tage, werden aus diesem für die jeweiligen Tage zur Anzeige einzelne Termine gemacht
 * @param skipLastDay Letzten Tag nicht mehr erstellen,da es sich um einen ganztägigen Termin handelt, der Formal bis zum nächsten Tag um 00:01 Uhr geht
 */
export function createOverDaysTermine(baseTermin: HWTermin, skipLastDay: boolean): HWTermin[] {
  const daysBetween = fullDaysBetween(baseTermin.startDate, baseTermin.endDate);
  const dayBegin = '00:01';
  const dayEnd = '23:59';
  const startDay = baseTermin.start.substring(0, 10);
  const startTime = baseTermin.startUhrzeit;
  const endDay = baseTermin.finish.substring(0, 10);
  const endTime = baseTermin.endUhrzeit;
  const termineOverDays: HWTermin[] = [];

  const startDayTermin = new HWTermin(baseTermin);
  startDayTermin.setNewStart(startDay, startTime);
  startDayTermin.setNewEnd(startDay, dayEnd);
  startDayTermin.isTerminOverDays = true;
  termineOverDays.push(startDayTermin);

  for (let index = 0; index < daysBetween; index++) {
    const nextDay = new Date(startDayTermin.startDate);
    const extraDay = startDayTermin.startDate.getDate() + 1 + index;
    nextDay.setDate(extraDay);
    const terminBetween = new HWTermin(baseTermin);
    const currentDateInLoop = dateToDatabaseDate(nextDay, false, false);
    terminBetween.setNewStart(currentDateInLoop, dayBegin);
    terminBetween.setNewEnd(currentDateInLoop, dayEnd);
    terminBetween.isTerminOverDays = true;
    termineOverDays.push(terminBetween);
  }

  const endDayTermin = new HWTermin(baseTermin);
  endDayTermin.setNewStart(endDay, dayBegin);
  endDayTermin.setNewEnd(endDay, endTime);
  endDayTermin.isTerminOverDays = true;
  if (!skipLastDay) termineOverDays.push(endDayTermin);

  return termineOverDays;
}
