<div *ngIf="currentMode === 'drawpoints' && !streckeClosed" class="info-message">Wand platzieren</div>
<div *ngIf="currentMode === 'textinput'" class="info-message">Text platzieren</div>
<div *ngIf="currentBauelement && !currentBauelement.inRaum" class="info-message">in Wand positionieren</div>
<div *ngIf="currentBauelement && currentBauelement.inRaum" class="info-message">in Fläche positionieren</div>

<div class="control-buttons">
  <div class="control-buttons-raw">
    <div class="control-button" (click)="askForNew()">Neu</div>
    <div class="control-button" (click)="drawGrid()">Raster</div>
    <div
      class="control-button"
      (click)="rebuildDrawingFromStack()"
      [ngClass]="{ disabled: drawStackElements.length === 0 }"
    >
      Rückgänging
    </div>
    <div
      class="control-button"
      (click)="createAufmassKonstrukt(aufmass, raum)"
      [ngClass]="{ disabled: !streckeClosed, finish: streckeClosed }"
    >
      Messen
    </div>
  </div>
  <div class="control-buttons-raw">
    <div class="control-button" (click)="setCurrentMode('drawpoints')" [ngClass]="{ disabled: streckeClosed }">
      Wände
    </div>
    <div class="control-button">
      <app-bss-search-popup
        [disabled]="!streckeClosed"
        [buttonText]="'Bauelement'"
        [headline]="'Wählen Sie ein Bauelement'"
        [inputDataSource]="bauelemente"
        [customDisplayExpressions]="['bezeichnung']"
        [datasourceIconPath]="'iconPath'"
        (selectedValue)="setCurrentMode('bauelement', $event)"
      >
      </app-bss-search-popup>
    </div>
    <div class="control-button">
      <app-bss-search-popup
        [disabled]="streckeClosed || currentMode === 'drawpoints'"
        [buttonText]="'Formen'"
        [headline]="'Wählen Sie eine Form'"
        [inputDataSource]="formen"
        [customDisplayExpressions]="['']"
        [datasourceIconPath]="'iconPath'"
        (selectedValue)="drawFromForm($event)"
      >
      </app-bss-search-popup>
    </div>
    <div class="control-button" (click)="setCurrentMode('textinput')">Text</div>
  </div>
</div>

<canvas
  #bssCanvas
  id="bssCanvas"
  (click)="clickOnCanvas($event, gridEnabled, currentMode, currentBauelement)"
  class="fullScreen"
>
</canvas>

<div class="textinput" id="textinput">
  <input class="textInputField" type="text" name="textinput" placeholder="Texteingabe" [(ngModel)]="textInput" /><br />
  <button class="bss-button primary-button" style="width: 50px; padding: 6px 10px" (click)="addTextToCanvas(textInput)">
    OK
  </button>
</div>
