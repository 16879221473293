import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { environment } from 'apps/handwerkPWA/src/environments/environment';
import { Userinfo } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  qrEnabled = false;
  isProduction: boolean;
  manuellLogin = false;
  userInfo: Userinfo;
  showHiddenUpload = false;

  constructor(
    private loginService: LoginService,
    private globalSettingService: HWGolbalsettingService,
    private routingService: RoutingService
  ) {}

  async logOutt(): Promise<void> {
    await this.loginService.logOut();
  }

  async ngOnInit(): Promise<void> {
    const hostname = window.location.hostname;
    const productionUrl =
      hostname.startsWith('192.') || hostname.startsWith('local') || hostname.startsWith('topappdev') ? false : true;
    this.isProduction = environment.production && productionUrl;

    const userinfoFromParams = this.checkForRouteparametersForManuallLogin();
    if (!isNullOrUndefined(userinfoFromParams)) {
      this.userInfo = userinfoFromParams;
      this.manuellLogin = true;
      return;
    }

    const userInfoFromIDB = await this.globalSettingService.getUserinfo();
    this.userInfo = isNullOrUndefined(userInfoFromIDB) ? new Userinfo(null) : userInfoFromIDB;
    if (!isNullOrUndefined(this.userInfo) && !isNullOrUndefined(this.userInfo.pin))
      await this.loginService.loginUser(this.userInfo, this.userInfo.updateDonePwa);
  }

  /**@description Ließt url parameter und wenn zumindest die uuid gefunden wurde, wird ein manueller login damit getriggert */
  private checkForRouteparametersForManuallLogin(): Userinfo {
    const employeeNumberValue = this.routingService.getRouteParam('employeeNumber');
    const brandingValue = this.routingService.getRouteParam('branding');
    const mandantValue = this.routingService.getRouteParam('mandant');
    const uuidValue = this.routingService.getRouteParam('uuid');
    const userInfo = new Userinfo({
      monteur: employeeNumberValue,
      mandant: mandantValue,
      branding: brandingValue,
      uuid: uuidValue,
    });
    if (!mandantValue) return null;
    return userInfo;
  }

  /**@description Logt den Nutzer per Button Login ein */
  createUserinfoFromLogindata(uuidValue: string, monteurString: string, mobilePin: string, mandantValue: string): void {
    const userInfo = new Userinfo({ uuid: uuidValue, monteur: monteurString, pin: mobilePin, mandant: mandantValue });
    void this.loginService.loginUser(userInfo);
  }

  /**@description Logt den Nutzer über seinen gescannten QR-Code ein */
  qrLogin(userInfoData: Userinfo): void {
    const userInfo = new Userinfo(userInfoData);
    void this.loginService.loginUser(userInfo);
  }

  /**@description Wird aufgerufen, wenn in der QRComponent auf 'Abbrechen' geklickt wird */
  scanStop(event: boolean): void {
    this.qrEnabled = event;
  }

  activateQrScan(event: MouseEvent): void {
    this.qrEnabled = true;
    if (event.ctrlKey)
      // wenn strg gedrückt gehalten wird beim klick auf den button, ist im qrscan der upload an
      this.showHiddenUpload = true;
  }
}
