import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDe from '@angular/common/locales/de';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GlobalErrorHandler } from './globalErrorHandler/GlobalErrorHandler';
import {
  DxSelectBoxModule,
  DxListModule,
  DxCheckBoxModule,
  DxNumberBoxModule,
  DxFormModule,
  DxTextAreaModule,
  DxDateBoxModule,
  DxButtonModule,
  DxTextBoxModule,
  DxMultiViewModule,
  DxCalendarModule,
  DxTemplateModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxSwitchModule,
  DxDataGridModule,
  DxBoxModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxGalleryModule,
  DxValidatorModule,
  DxTooltipModule,
  DxSpeedDialActionModule,
  DxAccordionModule,
  DxTabPanelModule,
  DxTreeViewModule,
} from 'devextreme-angular';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ReplacePipe } from '../assets/replace.pipe';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

/** Components */
import { AppComponent } from './app.component';

import {
  QrcodeComponent,
  UnterschriftComponent,
  BssPicturePopupComponent,
  BssContactBarComponent,
  AddressCardComponent,
  RepairOrderCardComponent,
  AppointmentCardComponent,
  MessageCardComponent,
  OrderItemCardComponent,
  SupportingDocumentPositionCardComponent,
  SystemCardComponent,
  ServiceOrderCardComponent,
  BssListComponent,
  ManuellLoginComponent,
  BssSearchPopupComponent,
  ManuellePositionComponent,
  BssRadiobuttonComponent,
  BssArrayOrganizerComponent,
  BssTimeInputComponent,
  BssMeasuringFieldComponent,
  BssNumberPopupComponent,
  HeaderAndSideNavigationModule,
  BssPlainTextBoxComponent,
  BssButtonLinkComponent,
  BssMultiViewComponent,
  BssDatetimeBoxComponent,
  SupportingDocumentCardComponent,
  ContactPersonCardComponent,
  ContactCardComponent,
  VacantPositionCardComponent,
  SaleCardComponent,
  BssToggleTextareaComponent,
  AufmassCardComponent,
  RaumbuchCardComponent,
  RaumvorlageComponent,
  EmailMessageCardComponent,
  BssCheckBoxComponent,
  ChooseRaumvorlageComponent,
} from './components';

import {
  BssSelectboxComponent,
  BssSwitchboxComponent,
  BssTextboxComponent,
  BssNumberboxComponent,
  BssTextareaComponent,
  MailtestComponent,
  ReloadComponent,
} from 'libs/shared/src/lib/components';

/** Pages */
import {
  LoginComponent,
  AddressesIndexComponent,
  AddressEditComponent,
  AddressVacantPositionsComponent,
  AddressSalesComponent,
  AddressContactPersonComponent,
  AddressContactComponent,
  ServiceOrderEditComponent,
  ServiceOrdersIndexComponent,
  ExploreComponent,
  SystemEditComponent,
  SystemLocationComponent,
  SystemAppointmentComponent,
  SystemTechnicalData1Component,
  SystemTechnicalData2Component,
  MeasurementNewComponent,
  MeasurementsIndexComponent,
  SystemsIndexComponent,
  FilesComponent,
  MessagesIndexComponent,
  MessageNewComponent,
  SettingsComponent,
  RepairOrderEditComponent,
  RepairOrderIndexComponent,
  OrderItemsIndexComponent,
  OrderItemEditComponent,
  RepairOrderNewComponent,
  OrderFinalizeComponent,
  OrderItemNewComponent,
  HomeComponent,
  CalendarComponent,
  AppointmentEditComponent,
  ImpressumComponent,
  AppointmentsIndexComponent,
  ObjectAddressEditComponent,
  SupportingDocumentsIndexComponent,
  SupportingDocumentShowComponent,
  ObjectAddressesIndexComponent,
  RaumbuchEditComponent,
  AufmassIndexComponent,
  RaumEditComponent,
  AufmassNewComponent,
} from './pages';

/** Services */
import { ScreenService } from 'libs/shared/src/lib/services/screen.service';
import { ProjectCardComponent } from './components/cards/project-card/project-card.component';
import { AufmassDrawComponent } from './pages/measurement/aufmass-draw/aufmass-draw.component';
import { AufmassMessungComponent } from './pages/measurement/aufmass-messung/aufmass-messung.component';
import { BssSuggestionFieldComponent } from './components/custom-form-items/bss-suggestion-field/bss-suggestion-field.component';
import { MassketteEditComponent } from './pages/measurement/masskette-edit/masskette-edit.component';
import { AufmassFormelComponent } from './components/aufmass/raumvorlage/aufmass-formel/aufmass-formel.component';
import { KmAllowanceComponent } from './components/forms/km-allowance/km-allowance.component';

@NgModule({
  declarations: [
    ReplacePipe,
    AppComponent,
    LoginComponent,
    QrcodeComponent,
    AddressesIndexComponent,
    AddressEditComponent,
    ObjectAddressesIndexComponent,
    ObjectAddressEditComponent,
    SupportingDocumentsIndexComponent,
    SupportingDocumentShowComponent,
    CalendarComponent,
    AppointmentEditComponent,
    ImpressumComponent,
    AppointmentsIndexComponent,
    ReloadComponent,
    RepairOrderEditComponent,
    RepairOrderIndexComponent,
    OrderItemsIndexComponent,
    OrderItemEditComponent,
    RepairOrderNewComponent,
    OrderFinalizeComponent,
    OrderItemNewComponent,
    UnterschriftComponent,
    HomeComponent,
    MessagesIndexComponent,
    MessageNewComponent,
    SettingsComponent,
    BssNumberPopupComponent,
    BssPicturePopupComponent,
    BssSearchPopupComponent,
    FilesComponent,
    AddressVacantPositionsComponent,
    AddressSalesComponent,
    AddressContactPersonComponent,
    AddressContactComponent,
    BssContactBarComponent,
    AddressCardComponent,
    RepairOrderCardComponent,
    AufmassDrawComponent,
    AufmassMessungComponent,
    AppointmentCardComponent,
    MessageCardComponent,
    OrderItemCardComponent,
    ManuellePositionComponent,
    SupportingDocumentPositionCardComponent,
    ProjectCardComponent,
    SystemsIndexComponent,
    SystemCardComponent,
    SystemEditComponent,
    SystemLocationComponent,
    SystemAppointmentComponent,
    SystemTechnicalData1Component,
    SystemTechnicalData2Component,
    MeasurementNewComponent,
    BssMeasuringFieldComponent,
    MeasurementsIndexComponent,
    ServiceOrderCardComponent,
    BssListComponent,
    ExploreComponent,
    ManuellLoginComponent,
    MailtestComponent,
    BssRadiobuttonComponent,
    ServiceOrderEditComponent,
    ServiceOrdersIndexComponent,
    BssArrayOrganizerComponent,
    BssTimeInputComponent,
    BssTextboxComponent,
    BssSelectboxComponent,
    BssTextareaComponent,
    BssNumberboxComponent,
    BssPlainTextBoxComponent,
    BssButtonLinkComponent,
    BssMultiViewComponent,
    BssSwitchboxComponent,
    BssDatetimeBoxComponent,
    SupportingDocumentCardComponent,
    ContactPersonCardComponent,
    ContactCardComponent,
    VacantPositionCardComponent,
    SaleCardComponent,
    BssToggleTextareaComponent,
    AufmassCardComponent,
    RaumbuchEditComponent,
    AufmassIndexComponent,
    RaumbuchCardComponent,
    RaumvorlageComponent,
    RaumEditComponent,
    AufmassNewComponent,
    BssSuggestionFieldComponent,
    EmailMessageCardComponent,
    MassketteEditComponent,
    AufmassFormelComponent,
    BssCheckBoxComponent,
    ChooseRaumvorlageComponent,
    KmAllowanceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ZXingScannerModule,
    DxSelectBoxModule,
    DxListModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxFormModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxDateBoxModule,
    DxButtonModule,
    DxTextBoxModule,
    DxMultiViewModule,
    DxCalendarModule,
    DxTemplateModule,
    DxLoadPanelModule,
    DxPopupModule,
    BrowserAnimationsModule,
    DxSwitchModule,
    DxDataGridModule,
    DxNumberBoxModule,
    SignaturePadModule,
    DxRadioGroupModule,
    DxBoxModule,
    DxScrollViewModule,
    DxGalleryModule,
    PinchZoomModule,
    DxTooltipModule,
    DxTreeViewModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true,
    }),
    HeaderAndSideNavigationModule,
    DxSpeedDialActionModule,
    DxAccordionModule,
    DxTabPanelModule,
    DxCheckBoxModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    ScreenService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
