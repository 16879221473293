import { UUID } from 'angular2-uuid';
import {
  createFormelFromAlternatingFormula,
  getEdgeIfOver,
  isBetween,
  isInTolerance,
} from 'libs/shared/src/lib/helper/aufmassHelper';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { Raumbuch } from '../../repository/Raumbuch';
import { Bauelement } from './Bauelement';
import { Punkt } from './Punkt';
import { RaumbPos } from './RaumbPos';

export class AufmassStrecke {
  startPunkt: Punkt = null;
  endPunkt: Punkt = null;
  mittelPunkt: Punkt = null;
  laengeGemessen: number = null;
  laengeGemessenInput = '';
  einheit = 'm';
  bezeichnung: string = null;
  bezeichnungLang: string = null;
  erfasst = false;
  steigung: number = null;
  yAchsenSchnitt: number = null;
  uuid: string = null;
  formel?: string = null;
  isBoden = false;
  isDecke = false;
  pointDistance: number = null;

  constructor(startPunkt: Punkt, endPunkt: Punkt, index: number) {
    if (!startPunkt) return;
    this.uuid = UUID.UUID();
    this.startPunkt = startPunkt;
    this.endPunkt = endPunkt;
    this.bezeichnung = 'w' + index;
    this.bezeichnungLang = 'Wand' + index;
    this.steigung = this.calculateSteigung(startPunkt, endPunkt);
    this.yAchsenSchnitt = this.calculateYAchsenSchnitt(this.startPunkt, this.steigung);
    this.mittelPunkt = this.calculateMittelpunkt(startPunkt, endPunkt);
    this.pointDistance = this.calculatePointDistance(startPunkt, endPunkt);
  }

  /**@default Berechnet die Länge der Strecke der gezeichneten Punkte */
  calculatePointDistance(startPunkt: Punkt, endPunkt: Punkt): number {
    const x1 = startPunkt.xKoordinate;
    const x2 = endPunkt.xKoordinate;
    const xAbsoulte = Math.abs(x2 - x1);
    const y1 = startPunkt.yKoordinate;
    const y2 = endPunkt.yKoordinate;
    const yAbsoulte = Math.abs(y2 - y1);
    return Math.hypot(xAbsoulte, yAbsoulte);
  }

  /**@description Gibt für einen Punkt den nächstliegenden Punkt innerhalb der Strecke zurück */
  getPointOnStrecke(clickedPoint: Punkt): Punkt {
    const xClicked = getEdgeIfOver(clickedPoint.xKoordinate, this.startPunkt.xKoordinate, this.endPunkt.xKoordinate);
    const yClicked = getEdgeIfOver(clickedPoint.yKoordinate, this.startPunkt.yKoordinate, this.endPunkt.yKoordinate);
    const yCalculatedFromX = xClicked * this.steigung + this.yAchsenSchnitt;
    if (this.steigung === 0) return new Punkt(xClicked, yCalculatedFromX);
    if (this.steigung === Infinity || this.steigung === -Infinity) {
      const xOfVerticalLine = this.startPunkt.xKoordinate;
      const pointOnVerticalLine = new Punkt(xOfVerticalLine, yClicked);
      return pointOnVerticalLine;
    }
    const xCalculatedFromY = (yCalculatedFromX - this.yAchsenSchnitt) / this.steigung;
    const calculatedPoint = new Punkt(xCalculatedFromY, yCalculatedFromX);
    return calculatedPoint;
  }

  /**@description Berechnet den yAchsenSchnitt für die Geradengleichung */
  calculateYAchsenSchnitt(startPunkt: Punkt, steigung: number): number {
    const yKoordinate = startPunkt.yKoordinate;
    const xKoordinate = startPunkt.xKoordinate;
    const yAchsenSchnitt = yKoordinate - steigung * xKoordinate;
    return yAchsenSchnitt;
  }

  /**@description Guckt anhand der standard Geradengleichung ob der berechnete yWert des Punkts innerhalb der Toleranz des Wertes liegt */
  isPointOnAufmassStrecken(punkt: Punkt, tolerance: number): boolean {
    const xKoordinate = punkt.xKoordinate;
    const yKoordinate = punkt.yKoordinate;
    if (this.steigung === Infinity || this.steigung === -Infinity) {
      // Steigung Unendlich => Vertical, Nur eine X-Koordinate
      const xOfLine = this.startPunkt.xKoordinate;
      const yOfLineStart = this.startPunkt.yKoordinate;
      const yOfLineEnd = this.endPunkt.yKoordinate;
      const nearHorizontalReal = isInTolerance(xOfLine, xKoordinate, tolerance);
      const nearVerticalLine =
        isInTolerance(yOfLineStart, yKoordinate, tolerance) ||
        isInTolerance(yOfLineEnd, yKoordinate, tolerance) ||
        isBetween(yKoordinate, yOfLineStart, yOfLineEnd);
      return nearHorizontalReal && nearVerticalLine;
    }
    if (this.steigung === 0) {
      // Steigung 0 => Waagerecht, Nur eine Y-Koordinate
      const yOfLine = this.startPunkt.yKoordinate;
      const xOfLineStart = this.startPunkt.xKoordinate;
      const xOfLineEnd = this.endPunkt.xKoordinate;
      const nearHorizontalReal = isInTolerance(yOfLine, yKoordinate, tolerance);
      const nearVerticalLine =
        isInTolerance(xOfLineStart, xKoordinate, tolerance) ||
        isInTolerance(xOfLineEnd, xKoordinate, tolerance) ||
        isBetween(xKoordinate, xOfLineStart, xOfLineEnd);
      return nearHorizontalReal && nearVerticalLine;
    }
    const calculatedYValue = xKoordinate * this.steigung + this.yAchsenSchnitt;
    const pointOnStrecke = yKoordinate - tolerance < calculatedYValue && calculatedYValue < yKoordinate + tolerance;
    if (pointOnStrecke) {
      return true;
    }
    return false;
  }

  /**@description Allgemeine geradengleichung zur berechnung der Steigung */
  calculateSteigung(startPunkt: Punkt, endPunkt: Punkt): number {
    const deltaY = endPunkt.yKoordinate - startPunkt.yKoordinate;
    const deltaX = endPunkt.xKoordinate - startPunkt.xKoordinate;
    return deltaY / deltaX;
  }

  calculateMittelpunkt(startPunkt: Punkt, endPunkt: Punkt): Punkt {
    const xStart = startPunkt.xKoordinate;
    const xEnde = endPunkt.xKoordinate;
    const yStart = startPunkt.yKoordinate;
    const yEnde = endPunkt.yKoordinate;
    const mitteX = (xStart + xEnde) / 2;
    const mitteY = (yStart + yEnde) / 2;
    const mittelPunkt = new Punkt(mitteX, mitteY);
    return mittelPunkt;
  }

  /**@description Konvertiert die Messungen eines Distanzmessgeräts in die korrekten Werte (splittet Einheiten ab) */
  setInputsClean(aufmassStrecke: AufmassStrecke): void {
    if (aufmassStrecke.erfasst) {
      return;
    }
    const laengeGemessen = aufmassStrecke.laengeGemessenInput;
    let numberPart = '';
    let unitPart = '';
    for (const char of laengeGemessen) {
      const parsedInt = parseInt(char, 10);
      const isANumber = isNaN(parsedInt) === false;
      if (isANumber || char === '.' || char === ',') {
        numberPart = numberPart + char;
        continue;
      }
      unitPart = unitPart + char;
    }
    if (isNullOrUndefined(unitPart) || unitPart === '') {
      unitPart = 'm';
    }
    const strecke = parseFloat(numberPart);
    aufmassStrecke.einheit = unitPart;
    aufmassStrecke.laengeGemessen = strecke;
    aufmassStrecke.laengeGemessenInput = strecke.toString();
    aufmassStrecke.erfasst = true;
  }

  toRaumbuchEntry(currentEntires: Raumbuch[], raum: RaumbPos, raumHoehe: number, formel?: string): Raumbuch {
    const entry = new Raumbuch(null, raum);
    const secondDimension = this instanceof Bauelement ? this.Hoehe : raumHoehe; // Bei einer Fläche ist die Zweite Dimension die Raumhöhe, bei einem Bauelement der jeweilige MEsswert
    if (this instanceof Bauelement && this.vorlage.alternatingFormula)
      formel = createFormelFromAlternatingFormula(this, this.vorlage.alternatingFormula);
    entry.fillFromMessung(currentEntires, raum, this, secondDimension, formel);
    return entry;
  }
}

function isSamePunkt(punkt1: Punkt, punk2: Punkt): boolean {
  return punkt1.xKoordinate === punk2.xKoordinate && punkt1.yKoordinate === punk2.yKoordinate;
}

/**@description Eine Strecke ist dann geschlossen,wenn sie mehr als 2 teilstücke enthält und die letze Strecke ihren Endpunkt im Startpunkt der ersten strecke hat */
export function isStreckeClosed(strecken: AufmassStrecke[]): boolean {
  if (strecken?.length < 3) return false;
  const startStrecke = strecken[0];
  const endStrecke = strecken[strecken.length - 1];
  if (
    isSamePunkt(startStrecke.startPunkt, endStrecke.endPunkt) &&
    !isSamePunkt(startStrecke.mittelPunkt, endStrecke.mittelPunkt)
  )
    return true;
  return false;
}
