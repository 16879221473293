export type PositionsId = 'D' | 'F' | 'O' | 'd' | 'f' | 'o' | 'S' | 's' | 'M' | 'm' | 'T' | 't' | 'J' | 'l';
export type PositionsArt =
  | 'Artikel'
  | 'Lohn'
  | 'Gerät'
  | 'Fremdleistung'
  | 'Sonstiges'
  | 'Leistung'
  | 'Textposition'
  | 'Unterleistung'
  | 'Undefiniert';

import { IndexedDBTypes } from './dbType';
import {
  convertToRoundedDisplayString,
  isNullOrUndefined,
  isNullOrUndefinedOrEmptyString,
} from 'libs/shared/src/lib/helper/globalHelper';
import { getStundenMinutenString } from 'libs/shared/src/lib/helper/timeHelper';
import { BaseDocumentPosition, DokumentPositionsTyp } from 'apps/handwerkPWA/src/app/interfaces';
import { UUID } from 'angular2-uuid';
import { getLongtypeFromId } from 'apps/handwerkPWA/src/app/helper/entities/HWRepairOrderItem/longTypeHelper';
import { Dokumentposition } from 'apps/handwerkPWA/src/app/entities/models/Dokumentposition';
import { Autolohn } from 'apps/handwerkPWA/src/app/entities/models/Autolohn';
import { ManuellePosition } from 'apps/handwerkPWA/src/app/entities/models/ManuellePosition';
import { findSteuerInUse, Steuer } from 'apps/handwerkPWA/src/app/entities/models/Steuer';
import { checkForKundenprice } from '../models/Kundenpreis';
import { HWAddress } from './HWAddress';

export class HWRepairOrderItem extends IndexedDBTypes.DbType implements BaseDocumentPosition {
  @IndexedDBTypes.KlassenName('HWRepairOrderItem') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') ArtikelstammUuid: string;
  @IndexedDBTypes.IndexField('string') UuidInAuftragspositionen: string;
  @IndexedDBTypes.IndexField('string') Ident: PositionsId;
  @IndexedDBTypes.IndexField('string') AuftrNR: string;
  /** Nach Absprache mit CS: Als "Bezeichner" versteht man allgemein den Kurztext eines Artikels */
  @IndexedDBTypes.DataField('string') Bezeichnung = '';
  @IndexedDBTypes.DataField('string') MatNr: string; // artikel
  @IndexedDBTypes.DataField('string') ID: string; // lohn
  @IndexedDBTypes.DataField('string') LSTNR: string; // leistung
  @IndexedDBTypes.DataField('string') Nr: string; // kostenart
  @IndexedDBTypes.DataField('boolean') Deleted = false;
  @IndexedDBTypes.DataField('number') EK: number;
  @IndexedDBTypes.DataField('number') Ebene: number;
  @IndexedDBTypes.DataField('boolean') EinmalPos: boolean;
  @IndexedDBTypes.DataField('string') ErloesKonto: string;
  @IndexedDBTypes.DataField('boolean') FestPreis: boolean;
  @IndexedDBTypes.DataField('number') FreEK: number;
  @IndexedDBTypes.DataField('number') FreVK: number;
  @IndexedDBTypes.DataField('number') GerEK: number;
  @IndexedDBTypes.DataField('number') GerVK: number;
  @IndexedDBTypes.DataField('string') GEWERK: string;
  @IndexedDBTypes.DataField('number') Jumbo1: number;
  @IndexedDBTypes.DataField('number') Jumbo2: number;
  @IndexedDBTypes.DataField('boolean') KundenPreis = false;
  @IndexedDBTypes.DataField('string') LangText = '';
  @IndexedDBTypes.DataField('number') LohnEK: number;
  @IndexedDBTypes.DataField('number') LohnVK: number;
  @IndexedDBTypes.DataField('number') Menge = 1;
  @IndexedDBTypes.DataField('string') MengenEinheit: string;
  @IndexedDBTypes.DataField('string') Nummer: string;
  @IndexedDBTypes.DataField('number') PosNr: number;
  @IndexedDBTypes.DataField('number') Rabatt: number;
  @IndexedDBTypes.DataField('number') RohstoffEK: number;
  @IndexedDBTypes.DataField('number') RohstoffVK: number;
  @IndexedDBTypes.DataField('string') RohstoffXML: string;
  @IndexedDBTypes.DataField('number') SonEK: number;
  @IndexedDBTypes.DataField('number') SonVK: number;
  @IndexedDBTypes.DataField('boolean') SonderPreis: boolean;
  @IndexedDBTypes.DataField('boolean') StaffelPreis: boolean;
  @IndexedDBTypes.DataField('number') TkID: number;
  @IndexedDBTypes.DataField('number') Typ: string; // Wird bei Leistungspositionen statt Ident verwendet...
  /** Der Wert der Umsatzsteuer, also aktuell bspw 16 (Corona-Minderung von 19) */
  @IndexedDBTypes.DataField('number') UstS: number;
  @IndexedDBTypes.DataField('string') UstSchl = '01';
  @IndexedDBTypes.DataField('number') SteuerSatz: number; // scheinbar bei Leistungen aus aufträgen
  @IndexedDBTypes.DataField('number') VK: number;
  @IndexedDBTypes.DataField('number') Zeit: number;
  @IndexedDBTypes.DataField('boolean') isAutolohnActive = false;
  @IndexedDBTypes.DataField('string') KurzText = ''; // bisher noch nicht vorhanden gewesen, kommt aber von der antwort
  @IndexedDBTypes.DataField('string') Such = ''; // bisher noch nicht vorhanden gewesen, kommt aber von der antwort
  @IndexedDBTypes.DataField('HWRepairOrderItem[]') Leistungspositionen: HWRepairOrderItem[] = [];
  @IndexedDBTypes.DataField('number') EULOHNS1: number;
  @IndexedDBTypes.DataField('number') EULOHNS2: number;
  @IndexedDBTypes.DataField('number') EULOHNS3: number;
  @IndexedDBTypes.DataField('number') EULOHNS4: number;
  @IndexedDBTypes.DataField('number') EULOHNS5: number;
  @IndexedDBTypes.DataField('number') EULOHNS6: number;
  @IndexedDBTypes.DataField('number') EULOHNS7: number;
  @IndexedDBTypes.DataField('number') EULOHNS8: number;
  @IndexedDBTypes.DataField('number') EULOHNS9: number;
  @IndexedDBTypes.DataField('number') EULOHNS10: number;
  @IndexedDBTypes.DataField('number') EULOHNVK1: number;
  @IndexedDBTypes.DataField('number') EULOHNVK2: number;
  @IndexedDBTypes.DataField('number') EULOHNVK3: number;
  @IndexedDBTypes.DataField('number') EULOHNVK4: number;
  @IndexedDBTypes.DataField('number') EULOHNVK5: number;
  @IndexedDBTypes.DataField('number') EULOHNVK6: number;
  @IndexedDBTypes.DataField('number') EULOHNVK7: number;
  @IndexedDBTypes.DataField('number') EULOHNVK8: number;
  @IndexedDBTypes.DataField('number') EULOHNVK9: number;
  @IndexedDBTypes.DataField('number') EULOHNVK10: number;
  @IndexedDBTypes.DataField('number') EUMATVK: number;
  @IndexedDBTypes.DataField('number') EUMATVK1: number;
  @IndexedDBTypes.DataField('number') EUMATVK2: number;
  @IndexedDBTypes.DataField('number') EUMATVK3: number;
  @IndexedDBTypes.DataField('number') EUMATVK4: number;
  @IndexedDBTypes.DataField('number') EUMATVK5: number;
  @IndexedDBTypes.DataField('number') EUMATVK6: number;
  @IndexedDBTypes.DataField('number') EUMATVK7: number;
  @IndexedDBTypes.DataField('number') EUMATVK8: number;
  @IndexedDBTypes.DataField('number') EUMATVK9: number;
  @IndexedDBTypes.DataField('number') EUMATVK10: number;
  @IndexedDBTypes.DataField('number') EUGEREK: number;
  @IndexedDBTypes.DataField('number') EUGERVK: number;
  @IndexedDBTypes.DataField('number') EUGERVK1: number;
  @IndexedDBTypes.DataField('number') EUGERVK2: number;
  @IndexedDBTypes.DataField('number') EUGERVK3: number;
  @IndexedDBTypes.DataField('number') EUGERVK4: number;
  @IndexedDBTypes.DataField('number') EUGERVK5: number;
  @IndexedDBTypes.DataField('number') EUGERVK6: number;
  @IndexedDBTypes.DataField('number') EUGERVK7: number;
  @IndexedDBTypes.DataField('number') EUGERVK8: number;
  @IndexedDBTypes.DataField('number') EUGERVK9: number;
  @IndexedDBTypes.DataField('number') EUGERVK10: number;
  @IndexedDBTypes.DataField('number') EUFREEK: number;
  @IndexedDBTypes.DataField('number') EUFREVK: number;
  @IndexedDBTypes.DataField('number') EUFREVK1: number;
  @IndexedDBTypes.DataField('number') EUFREVK2: number;
  @IndexedDBTypes.DataField('number') EUFREVK3: number;
  @IndexedDBTypes.DataField('number') EUFREVK4: number;
  @IndexedDBTypes.DataField('number') EUFREVK5: number;
  @IndexedDBTypes.DataField('number') EUFREVK6: number;
  @IndexedDBTypes.DataField('number') EUFREVK7: number;
  @IndexedDBTypes.DataField('number') EUFREVK8: number;
  @IndexedDBTypes.DataField('number') EUFREVK9: number;
  @IndexedDBTypes.DataField('number') EUFREVK10: number;
  @IndexedDBTypes.DataField('number') EUSONVK1: number;
  @IndexedDBTypes.DataField('number') EUSONVK2: number;
  @IndexedDBTypes.DataField('number') EUSONVK3: number;
  @IndexedDBTypes.DataField('number') EUSONVK4: number;
  @IndexedDBTypes.DataField('number') EUSONVK5: number;
  @IndexedDBTypes.DataField('number') EUSONVK6: number;
  @IndexedDBTypes.DataField('number') EUSONVK7: number;
  @IndexedDBTypes.DataField('number') EUSONVK8: number;
  @IndexedDBTypes.DataField('number') EUSONVK9: number;
  @IndexedDBTypes.DataField('number') EUSONVK10: number;
  // Fremdleistung/ Gerätepreise (innerhalb einer leistung sind es die anderen)
  @IndexedDBTypes.DataField('number') VK1: number;
  @IndexedDBTypes.DataField('number') VK2: number;
  @IndexedDBTypes.DataField('number') VK3: number;
  @IndexedDBTypes.DataField('number') VK4: number;
  @IndexedDBTypes.DataField('number') VK5: number;
  @IndexedDBTypes.DataField('number') VK6: number;
  @IndexedDBTypes.DataField('number') VK7: number;
  @IndexedDBTypes.DataField('number') VK8: number;
  @IndexedDBTypes.DataField('number') VK9: number;
  @IndexedDBTypes.DataField('number') VK10: number;
  @IndexedDBTypes.DataField('boolean') offlinePosition: boolean;
  @IndexedDBTypes.DataField('string') Bez: string;
  @IndexedDBTypes.DataField('string') LohnNr: string;
  /**EK einer Lohnposition */
  @IndexedDBTypes.DataField('number') KOSTSATZ: number;
  @IndexedDBTypes.DataField('string') Lieferant: string;
  @IndexedDBTypes.DataField('string') RSDaten: string;
  @IndexedDBTypes.DataField('number') RSPreis = 0;
  @IndexedDBTypes.DataField('number') RSPreisVK = 0;
  /**zur anzeige des korrekten suchtextes */
  @IndexedDBTypes.DataField('string') Suchbegriff: string;
  /**Scheinbar für eine Unterposition um die Leistung zuzuordnen / Reihenfolge des Drucks */
  @IndexedDBTypes.DataField('number') SORTID: number;
  @IndexedDBTypes.DataField('string') LeistungsID: string = null;
  @IndexedDBTypes.DataField('number') PEMENGE = 1;
  @IndexedDBTypes.DataField('string') positionType: DokumentPositionsTyp = 'RepairOrderItem';
  @IndexedDBTypes.DataField('string') RTFData: string;
  @IndexedDBTypes.DataField('boolean') notUpdated: boolean;

  constructor(data: HWRepairOrderItem | ManuellePosition, kunde: HWAddress = null) {
    super();
    Object.assign(this, data);

    if (isNullOrUndefinedOrEmptyString(this.GEWERK)) this.GEWERK = this.Lieferant;

    this.assignLohnProperties(this);

    if (isNullOrUndefined(this.Menge) || isNaN(this.Menge)) this.Menge = 1;

    if (isNullOrUndefined(this.UstS)) this.UstS = this.SteuerSatz;

    if (this.getLongtype() === 'Textposition') this.LangText = this.Bezeichnung;
    if (kunde) this.setInitialPrices(kunde);
  }

  /**@description Setzt je nach Preisgruppe und Typ des Artikels den richtigen Preis und seine Nummer */
  public setInitialPrices(kunde: HWAddress): void {
    const priceGroup = kunde.priceGroup ? kunde.priceGroup : '1';
    const kundenpreise = kunde.Kundenpreise;
    let price = 0.01;
    const longType = this.getLongtype();
    switch (longType) {
      case 'Artikel': {
        price = this['EUMATVK' + priceGroup];
        break;
      }
      case 'Lohn': {
        this.LohnEK = this.KOSTSATZ;
        this.EK = 0;
        price = this['EULOHNS' + priceGroup];
        break;
      }
      case 'Gerät': {
        this.GerEK = this.EK;
        this.EK = 0;
        this.GerVK = this['EUGERVK' + priceGroup];
        price = this['EUGERVK' + priceGroup];
        break;
      }
      case 'Leistung': {
        this.VK = this['EUMATVK' + priceGroup];
        this.LohnVK = this['EULOHNS' + priceGroup];
        this.SonVK = this['EUSONVK' + priceGroup];
        this.FreVK = this['EUFREVK' + priceGroup];
        this.GerVK = this['EUGERVK' + priceGroup];
        price = this.getLeistungspreis(this);
        break;
      }
      case 'Fremdleistung': {
        this.FreEK = this.EK;
        this.EK = 0;
        this.FreVK = this['EUFREVK' + priceGroup];
        price = this['EUFREVK' + priceGroup];
        break;
      }
      case 'Sonstiges': {
        this.SonEK = this.EK;
        this.EK = 0;
        this.SonVK = this['EUSONVK' + priceGroup];
        price = this['EUSONVK' + priceGroup];
        break;
      }
      case 'Textposition': {
        // Textposition
        price = 0;
      }
    }
    this.LohnVK = this['EULOHNVK' + priceGroup];
    const finalPrice = checkForKundenprice(kundenpreise, this, price);
    if (finalPrice !== price) this.KundenPreis = true;
    this.updateCorrectPrice(finalPrice, false);
  }

  getLeistungsId(): string {
    return this.LeistungsID;
  }

  /**
   * @description Setzt die Leistungsid - wenn id mitgegeben wird, kommt diese von der Leistung und wird an die Positionen
   * der Leistung übergeben
   */
  setLeistungsID(leistungsIDOfLeistung?: string): void {
    const leistungsId = leistungsIDOfLeistung ? leistungsIDOfLeistung : UUID.UUID();
    this.LeistungsID = leistungsId;
  }

  getUniqueIdentifier(): string {
    return this.UuidInAuftragspositionen;
  }

  getTransferredLeistungspositionen(kunde: HWAddress = null): HWRepairOrderItem[] {
    const leistungsPositionen = this.Leistungspositionen.map(data => new HWRepairOrderItem(data, kunde));
    if (kunde) this.setInitialPrices(kunde);
    return leistungsPositionen;
  }

  /**@description Updated die Itemproperties korrekt */
  updateItem(changeType: 'Preis' | 'Zeit' | 'Menge' | 'Text', neuerWert: number | string, isGesamtpreis = false): void {
    const neuerNumberWert = typeof neuerWert === 'number' ? neuerWert : NaN;
    const neuerWertString = typeof neuerWert === 'string' ? neuerWert : '';
    switch (changeType) {
      case 'Preis': {
        this.updateCorrectPrice(neuerNumberWert, isGesamtpreis);
        this.KundenPreis = false;
        return;
      }
      case 'Zeit':
      case 'Menge': {
        this.setZeitOrMenge(neuerNumberWert, changeType);
        return;
      }
      case 'Text': {
        this.setBezeichnung(neuerWertString);
        return;
      }
    }
  }

  /**@description Setzt je nach Kontext entweder Zeit order Menge */
  private setZeitOrMenge(neuerNumberWert: number, changeType: 'Zeit' | 'Menge') {
    if (changeType === 'Zeit') {
      this.Zeit = neuerNumberWert;
      this.Menge = 1;
      return;
    }
    if (changeType === 'Menge') this.Menge = neuerNumberWert;
  }

  setBezeichnung(value: string): void {
    this.Bezeichnung = value;
  }

  setLangtext(value: string): void {
    this.LangText = value;
  }

  getLangtext(): string {
    return this.LangText;
  }

  getBezeichnung(): string {
    return this.Bezeichnung;
  }
  getPrintStueckliste(): boolean {
    return false;
  }

  getLongtype(): PositionsArt {
    const ident = isNullOrUndefinedOrEmptyString(this.ID) ? this.Ident : this.ID;
    return getLongtypeFromId(ident);
  }

  getMengenEinheit(): string {
    if (this.getLongtype() === 'Lohn') return 'STD.';
    return this.MengenEinheit;
  }

  /**@description Gibt je nach art den Grundreis zurück */
  getGrundverkaufspreis(priceGroup: string): number {
    if (this.isUnterposition()) {
      const mockAdress = new HWAddress(null);
      mockAdress.priceGroup = priceGroup;
      this.setInitialPrices(mockAdress);
    }

    const art = this.getLongtype();
    switch (art) {
      case 'Textposition': {
        // Textposition
        return 0;
      }
      case 'Artikel': {
        // Normaler artikel
        return this.VK + this.LohnVK * (this.Zeit / 60);
      }
      case 'Leistung': {
        // Leistung
        return this.getLeistungspreis(this);
      }
      case 'Lohn': {
        // Lohn
        return this.LohnVK;
      }
      case 'Gerät': {
        return this.GerVK;
      }
      case 'Sonstiges': {
        return this.SonVK;
      }
      case 'Fremdleistung': {
        return this.FreVK;
      }
    }
    return NaN;
  }

  private getLeistungspreis(position: HWRepairOrderItem): number {
    const zeit = position.Zeit;
    const lohn = zeit === 0 ? zeit : position.LohnVK * (zeit / 60);
    const leistungsPrice = lohn + position.VK + position.SonVK + position.FreVK + position.GerVK;
    return leistungsPrice;
  }

  /**@description Berechnet für jedes einzelne Item den Anhand seines Identiiers und der zugrundeliegenden Formel den jeweiligen Gesammtverkaufspreis des Items */
  calculateVerkaufspreisSteuerpreis(
    item: HWRepairOrderItem | Dokumentposition,
    Steuern: Steuer[],
    priceGroup: string
  ): { gesammtPreis: number; steuerPreis: number } {
    const umsatzSteuer = item.getSteuersatz(Steuern) / 100;
    const gesammtPreisValue = this.getGrundverkaufspreis(priceGroup) * item.getMengeOrStunden();
    const steuerPreisValue = gesammtPreisValue * umsatzSteuer;
    return { gesammtPreis: gesammtPreisValue, steuerPreis: steuerPreisValue };
  }

  getSteuersatz(Steuern: Steuer[]): number {
    const steuerSchluessel = this.UstSchl;
    const steuer = findSteuerInUse(Steuern, steuerSchluessel);
    return steuer.Steuersatz;
  }

  getSteuerSchluessel(Steuern: Steuer[]): string {
    const steuerSchluessel = this.UstSchl;
    const steuer = findSteuerInUse(Steuern, steuerSchluessel);
    return steuer.Id;
  }

  getMengenDescription(): string {
    let description = 'Menge';
    if (this.getLongtype() === 'Lohn') {
      description = 'Zeit';
    }
    return description;
  }

  getMengeAndUnit(): string {
    if (this.isAutolohnActive) {
      return 'wird automatisch ermittelt';
    }
    if (this.getLongtype() === 'Lohn') {
      const mengeAndTimeUnit = getStundenMinutenString(this.Zeit);
      return mengeAndTimeUnit;
    }
    const mengenEinheit = isNullOrUndefined(this.MengenEinheit) ? '' : this.MengenEinheit;
    const mengeAndUnit = this.Menge + ' ' + mengenEinheit;
    return mengeAndUnit;
  }

  getDisplayMengeString(): string {
    const description = this.getMengenDescription();
    const mengeAndUnit = this.getMengeAndUnit();
    return description + ': ' + mengeAndUnit;
  }

  getEinzelpreisDisplayLine(priceGroup: string): string {
    const returnPrice = this.getEinzelDisplayprice(priceGroup);
    const returnPriceGerman = convertToRoundedDisplayString(returnPrice);
    const description = this.getEinzelpreisDescription();
    return description + ': ' + returnPriceGerman + ' €';
  }

  getGesamtpreisDisplayLine(priceGroup: string): string {
    if (this.isAutolohnActive) {
      return 'Wird automatisch ermittelt';
    }
    const returnPrice = this.getGesamtDisplayPrice(priceGroup);
    const returnPriceGerman = convertToRoundedDisplayString(returnPrice);
    const description = this.getGesamtpreisDescription();
    return description + ': ' + returnPriceGerman + ' €';
  }

  getGesamtpreisDescription(): string {
    const autolohn = this.isAutolohnActive;
    let description = 'Gesamtpreis';
    if (this.getLongtype() === 'Lohn') {
      description = 'Verrechnungssatz';
    }
    if (autolohn) {
      description = 'Verrechnungssatz stand jetzt';
    }
    return description;
  }

  getEinzelpreisDescription(): string {
    let description = 'Einzelpreis';
    if (this.getLongtype() === 'Lohn') {
      description = 'Verrechnungssatz pro Stunde';
    }
    return description;
  }

  getEinzelDisplayprice(priceGroup: string): number {
    const basePrice = this.getGrundverkaufspreis(priceGroup);
    const returnPrice = convertToRoundedDisplayString(basePrice);
    const returnPriceNumber = parseFloat(returnPrice.replace(',', '.'));
    return returnPriceNumber;
  }

  /**@description Gibt den Kompletten verkaufspreis formatiert zurück */
  getGesamtDisplayPrice(priceGroup: string): number {
    const basePrice = this.getGrundverkaufspreis(priceGroup) * this.getMengeOrStunden();
    const returnPrice = convertToRoundedDisplayString(basePrice);
    const returnPriceNumber = parseFloat(returnPrice.replace(',', '.'));
    return returnPriceNumber;
  }

  isUnterposition(): boolean {
    const id = this.Ident;
    if (this.getLongtype() === 'Undefiniert') return false;
    if (id === id.toLocaleLowerCase()) return true;
    return false;
  }

  /**@description Auftragspositionstabelle und die einzelnen Tabellen haben unterschiedliche Lohnbezeichnungen, also allen Feldern korrete Werte zuweisen */
  private assignLohnProperties(item: HWRepairOrderItem): void {
    const lohnS1 = item.EULOHNS1;
    const lohnSUndefined = isNullOrUndefined(lohnS1);
    const from = lohnSUndefined ? 'EULOHNVK' : 'EULOHNS';
    const to = lohnSUndefined ? 'EULOHNS' : 'EULOHNVK';
    for (let index = 1; index < 11; index++) item[to + index] = item[from + index];
  }

  /**@description Setzt den Preis je nach Typ an der Korrekten stelle */
  private updateCorrectPrice(neuerPreis: number, isGesamtpreis: boolean): void {
    const longType = this.getLongtype();
    if (isGesamtpreis) {
      neuerPreis = neuerPreis / this.getMengeOrStunden();
    }
    switch (longType) {
      case 'Artikel': {
        this.VK = neuerPreis;
        return;
      }
      case 'Leistung': {
        return;
      }
      case 'Lohn': {
        this.LohnVK = neuerPreis;
        return;
      }
      case 'Gerät': {
        this.GerVK = neuerPreis;
        return;
      }
      case 'Fremdleistung': {
        this.FreVK = neuerPreis;
        return;
      }
      case 'Sonstiges': {
        this.SonVK = neuerPreis;
        return;
      }
    }
  }

  artToPriceIndeces(art: PositionsArt): { general: string; priceInuse: string } {
    switch (art) {
      case 'Artikel':
        return { general: 'EUMATVK', priceInuse: 'VK' };
      case 'Lohn':
        return { general: 'EULOHNVK', priceInuse: 'LohnVK' };
      case 'Gerät':
        return { general: 'EUGERVK', priceInuse: 'GerVK' };
      case 'Fremdleistung':
        return { general: 'EUFREVK', priceInuse: 'FreVK' };
      case 'Sonstiges':
        return { general: 'EUSONVK', priceInuse: 'SonVK' };
    }
    return null;
  }

  getSuchbegriff(): string {
    return this.Suchbegriff;
  }

  isRechnungsposten(): boolean {
    return this.getLongtype() !== 'Undefiniert';
  }

  shouldBePrinted(): boolean {
    return true;
  }

  getPosNr(): string {
    if (isNullOrUndefined(this.SORTID)) return '';
    return this.SORTID.toString();
  }

  getMengeOrStunden(): number {
    const longType = this.getLongtype();
    if (longType === 'Lohn') return this.Zeit / 60;
    return this.Menge;
  }

  static toString(): string {
    return 'HWRepairOrderItem';
  }
}

export class LeistungsAssignInformation {
  LSTNR: string;
  PosNr: number;
  AuftrNR: string;
  LeistungsID: string;

  constructor(item: HWRepairOrderItem) {
    this.LSTNR = item.LSTNR;
    this.PosNr = item.PosNr;
    this.AuftrNR = item.AuftrNR;
    this.LeistungsID = item.getLeistungsId();
  }
}

export class AutolohnInformation {
  Autolohn: Autolohn;
  Auftragsnummer: string;
  AutolohnPositions: HWRepairOrderItem[];
  Arbeitszeiten: Date[];
  constructor(
    autoLohn: Autolohn,
    auftragsNummer: string,
    autolohnPositions: HWRepairOrderItem[],
    arbeitszeiten: Date[]
  ) {
    this.Autolohn = autoLohn;
    this.Auftragsnummer = auftragsNummer;
    this.AutolohnPositions = autolohnPositions;
    this.Arbeitszeiten = arbeitszeiten;
  }
}

export function getShortTypeFromLeistungstype(type: string): string {
  switch (type) {
    case 'Artikel': {
      return 'M';
    }
    case 'Lohn': {
      return 'S';
    }
    case 'Leistung': {
      return 'J';
    }
    case 'Kostenart': {
      return 'D';
    }
  }
  return 'M';
}

/**@description Vergleichsfunktion für die Positionen, sortiert nach Nummer */
export function comparePositionen(a: BaseDocumentPosition, b: BaseDocumentPosition): number {
  const aPosNrString = a.getPosNr();
  const aPosNr = parseInt(aPosNrString, 10);
  const bPosNrString = b.getPosNr();
  const bPosNr = parseInt(bPosNrString, 10);
  if (aPosNr > bPosNr) return 1;
  if (aPosNr < bPosNr) return -1;
  return 0;
}
