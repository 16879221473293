<div class="dx-field">
  <div *ngIf="labelText" class="dx-field-label">{{ labelText }}</div>
  <div class="dx-field-value">
    <dx-select-box
      #selectbox
      [items]="items"
      [value]="value"
      [showClearButton]="showClearButton"
      [searchEnabled]="!!searchMode"
      [displayExpr]="displayExpr"
      [disabled]="disabled"
      [acceptCustomValue]="acceptCustomValue"
      [placeholder]="placeholder"
      (onValueChanged)="valueChanged($event)"
      (onItemClick)="itemWasClicked($event)"
    ></dx-select-box>
  </div>
</div>
