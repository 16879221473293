import { Component, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import {
  Mengeneinheit,
  manualPositionType,
  ManuellePosition,
  HWRepairOrderItem,
} from 'apps/handwerkPWA/src/app/entities';
import { BssNumberPopupComponent } from '../../custom-form-items/bss-number-popup/bss-number-popup.component';
import { parseRepairOrderItem } from '../../../entities/models/ManuellePosition';

@Component({
  selector: 'app-manuelle-position',
  templateUrl: './manuelle-position.component.html',
  styleUrls: ['./manuelle-position.component.scss'],
})
export class ManuellePositionComponent {
  @Input() mengeneinheiten: string[] = [];
  selectedMengeneinheit: Mengeneinheit;
  @Input() isAutolohnActive = false;
  @Output() position = new EventEmitter<HWRepairOrderItem>();
  customTypes: manualPositionType[] = ['Artikel', 'Lohn', 'Textposition'];
  manuellePosition = new ManuellePosition();
  einzelPreisLable = 'Einzelpreis';
  @ViewChild('mengenInput', { static: true }) mengenInput: BssNumberPopupComponent;
  alphaNumericPattern = /^[A-Za-z0-9_]*$/;

  constructor() {}

  /**@description Setzt die Werte der manuellen Positionseingabe zurück */
  resetCustomInput(): void {
    this.manuellePosition = new ManuellePosition();
  }

  customTypeChange(type: manualPositionType): void {
    this.manuellePosition.changeType(type);
    this.einzelPreisLable = 'Einzelpreis';
    if (type === 'Lohn') {
      this.einzelPreisLable = 'Verrechnungssatz pro Stunde';
    }
  }

  /**@description Säubert die customNummer Eingabe falls der Nutzer es nicht getan hat */
  cleanCustomNumberOfSonderzeichen(customNummer: string): string {
    let cleanCustomNummer = '';
    if (isNullOrUndefined(customNummer)) {
      return cleanCustomNummer;
    }
    for (const char of customNummer) {
      if (this.alphaNumericPattern.exec(char)) {
        cleanCustomNummer = cleanCustomNummer + char;
      }
    }
    return cleanCustomNummer;
  }

  /**@description Erstellt eine manuelle Position */
  createCustom(manuellePosition: ManuellePosition): void {
    manuellePosition.Nummer = this.cleanCustomNumberOfSonderzeichen(manuellePosition.Nummer);
    const newItem = parseRepairOrderItem(manuellePosition);
    delete newItem['PriceEK'];
    delete newItem['PriceVK'];
    this.position.emit(newItem);
    this.manuellePosition = new ManuellePosition();
  }

  setMengeOrZeit(menge: number, manuellePositionType: manualPositionType): void {
    if (manuellePositionType === 'Lohn') {
      this.manuellePosition.Menge = 1;
      this.manuellePosition.Zeit = menge * 60;
      this.manuellePosition.MengenEinheit = 'STD';
      return;
    }
    this.manuellePosition.Menge = menge;
  }

  setMengeneinheit(mengenEinheit: string): void {
    this.manuellePosition.MengenEinheit = mengenEinheit;
  }
}
