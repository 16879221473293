import { ServiceName } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { HWFile } from 'apps/handwerkPWA/src/app/entities';
import { OfflinePosition } from 'apps/handwerkPWA/src/app/interfaces';
import { PositionSettings } from '../entities';

/**@description Macht das gleiche wie Object assign, außer, dass nur dinge zugewiesen werden, die vorhanden sind (im zweifel null, nicht undefined) */
export function assignIfPropertyExsits(target: any, source: any): void {
  for (const property in source) {
    if (target[property] === undefined) {
      continue;
    }
    target[property] = source[property];
  }
}

/**@description Selbstgeschriebene null Or Undefined Function */
export function isNullOrUndefined(object: any): boolean {
  return object === null || object === undefined;
}

/**@description Selbstgeschriebene null Or Undefined Function */
export function isNullOrUndefinedOrEmptyString(stringValue: string): boolean {
  return stringValue === null || stringValue === undefined || stringValue === '';
}

/**@description Selbstgeschriebene null Or Undefined Function */
export function isNullOrUndefinedOrEmptyArray(object: any[]): boolean {
  return object === null || object === undefined || object.length === 0;
}

/**@description Weißt einen String zu wenn der zuweisende Wert vorhanden ist,ansonsten den Fallback (default leer) */
export function assignString(stringToAssign: string, fallBack: string = ''): string {
  if (isNullOrUndefined(stringToAssign)) return fallBack;
  return stringToAssign;
}

/**@description Überprüft, ob der angegebene wert vom typ boolean ist */
export function isBoolean(object: any): boolean {
  return typeof object === 'boolean';
}

/**@description Prüft ob das übergebene Object eine Instanz von Date ist */
export function isDate(object: any): boolean {
  return object instanceof Date;
}

/**@description Nimmt einen String einer Zahl oder eine Zahl und gibt einen Preis(Zahl, mit Komma und Zweinachkommastellen, gerundet auf die zweite Nachkommastelle) zurück */
export function convertToRoundedDisplayString(input: string | number, decimalPlaces: number = 2): string {
  const inputNumber = parseFloat(input.toString());
  const outputString = inputNumber.toFixed(decimalPlaces).replace('.', ',');
  return outputString;
}

/**@description Speichert etwas in den LocalStorage */
export function saveToLocalstorage(identifier: string, value: unknown): void {
  const stringifiedObject = JSON.stringify(value);
  localStorage.setItem(identifier, stringifiedObject);
}

/**
 * @description Liest etwas aus dem LocalStorage
 * @param className Optional um Konstruktor des Objekts zu durchlaufen - ist Klassenname des jeweiligen Objekts
 */
export function readFromLocalstorage<Type>(identifier: string, className?: new (data) => Type): Type {
  const stringifiedObject = localStorage.getItem(identifier);
  const rebuildObject = JSON.parse(stringifiedObject) as Type;
  if (className) return new className(rebuildObject);
  return rebuildObject;
}

/**@description Durch die Umwandlung in Strings kann es sein, dass eine Version bspw. "null" als String in die DB schrieb- diese Fälle werden erkannt */
export function isEmptyGuiData(objectToCheck: any): boolean {
  if (objectToCheck === null) return true;
  if (objectToCheck === undefined) return true;
  if (objectToCheck === 'null') return true;
  if (objectToCheck === '') return true;
  if (objectToCheck === '{}') return true;
  if (objectToCheck === '[]') return true;
  return false;
}

export function getUniques<T, TField>(listA: T[], listB: T[], idFunc: (t: T) => TField): T[] {
  const uniqueMap = new Map<TField, T>(listA.map(element => [idFunc(element), element]));

  listB.forEach(element => {
    if (!uniqueMap.has(idFunc(element))) uniqueMap.set(idFunc(element), element);
  });

  return Array.from(uniqueMap.values());
}

export function urlToSyncserviceName(
  syncAbleUrl:
    | 'adressen'
    | 'belege'
    | 'kalender'
    | 'nachrichten'
    | 'reparaturauftraege'
    | 'wartungsanlagen'
    | 'wartungsauftraege'
    | 'aufmasse'
    | string
): ServiceName {
  if (syncAbleUrl.startsWith('/')) syncAbleUrl = syncAbleUrl.substring(1);
  switch (syncAbleUrl) {
    case 'kalender':
      return 'terminservice';
    case 'adressen':
      return 'adressservice';
    case 'belege':
      return 'belegservice';
    case 'nachrichten':
      return 'nachrichtenservice';
    case 'reparaturauftraege':
      return 'auftragsservice';
    case 'wartungsanlagen':
      return 'wartungsservice';
    case 'wartungsauftraege':
      return 'wartungsservice';
    case 'aufmasse':
      return 'aufmassservice';
  }
  return null;
}

export function removeControlCharacters(inputString: string): string {
  return inputString.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

/**@description Berechnet die grobe grö0e eines elements für speicherbetrachtungen */
export function estimatedObjectSize(objectToEstimate: unknown): number {
  const stringifiedObject = JSON.stringify(objectToEstimate);
  const roughObjSize = stringifiedObject?.length;
  return roughObjSize;
}

/**
 * @description Gibt die nächste Nummer eines unsortierten number arrays
 * @param firstValue Erste Nummer dieses Typs, als Rückgabe falls keine höchste zahl gefunden wird (default 0) */
export function getNextNumber(numberArray: number[], firstValue = 0): number {
  if (!numberArray || numberArray.length === 0) return firstValue;
  const maxNumber = Math.max(...numberArray);
  return maxNumber + 1;
}

/**
 * @description Holt alle einzigartigen strings, die zwischen den Begrenzern liegen -
 * Beispiel: "ab[h]gf[c]-öp?[e]fdfd[c]fdfd" mit startDelimiter "[" und endDelimiter "]" gibt ['h','c','e'] zurück
 */
export function getAllElementsBetween(inputString: string, startDelimiter: string, endDelimiter: string): string[] {
  const elements: string[] = [];
  let index = 0;
  let currentStartIndex = -1;
  let currentEndIndex = -1;
  let currentElement: string;
  for (const char of inputString) {
    if (char === startDelimiter) currentStartIndex = index;
    if (char === endDelimiter) currentEndIndex = index;
    if (currentStartIndex !== -1 && currentEndIndex !== -1) {
      currentElement = inputString.substring(currentStartIndex + 1, currentEndIndex);
      if (!elements.includes(currentElement)) elements.push(currentElement);
      currentStartIndex = -1;
      currentEndIndex = -1;
    }
    index++;
  }
  return elements;
}

/**
 * @description Entfernt ein Element aus einem Array
 * @param comparePropertyName Name der Property, die zum Vergleich verwendet werden soll bei nicht trivialen arrays
 */
export function removeFromArray<T>(inputArray: T[], elementToRemove: T, comparePropertyName?: string): T[] {
  if (isNullOrUndefined(elementToRemove)) return inputArray;
  let index = inputArray.findIndex(entry => entry === elementToRemove);
  if (comparePropertyName)
    index = inputArray.findIndex(entry => entry[comparePropertyName] === elementToRemove[comparePropertyName]);
  if (index === -1) return inputArray;
  inputArray.splice(index, 1);
  return inputArray;
}

/**@default Parsed die Eingabe eines Distanzmessgeräts und gibt wie definiert 3 Nachkommastellen an */
export function parseDistanceMeterInput(distanceMeterInput: string): string {
  distanceMeterInput = distanceMeterInput.replace(',', '.');
  distanceMeterInput = distanceMeterInput.replace(/^[a-zA-Z]+/gi, '');
  const distanceMeterInputNumber = parseFloat(distanceMeterInput);
  if (isNaN(distanceMeterInputNumber)) return '';
  if (distanceMeterInputNumber < 1) return distanceMeterInputNumber.toPrecision(3).replace('.', ',');
  return distanceMeterInputNumber.toPrecision(4).replace('.', ',');
}

function getPropertynamesOfObject(data: any): string[] {
  const propertyNames: string[] = [];
  if (isNullOrUndefined(data)) return propertyNames;
  // eslint-disable-next-line guard-for-in
  for (const property in data) propertyNames.push(property);
  return propertyNames;
}

/**@description Bezieht die SearchExpressions anhand der enthaltenen Properties des Objekts (der Objekte) */
export function getSearchexpressionsBasedOnProperties(dataSource: any[]): string[] {
  let searchExpressions: string[] = [];
  const allObjectLength = dataSource.map(source => Object.keys(source).length);
  const uniqueObjectLength: Set<number> = new Set(allObjectLength);
  for (const objectLength of uniqueObjectLength) {
    const baseObject: unknown = dataSource.find(objec => Object.keys(objec).length === objectLength);
    const newSearchExpressions = getPropertynamesOfObject(baseObject);
    searchExpressions = searchExpressions.concat(newSearchExpressions);
  }
  const setExpressions = new Set(searchExpressions);
  const uniqueExpressions = Array.from(setExpressions);
  return uniqueExpressions;
}

// clipCanvasInPoints(canvas: HTMLCanvasElement, aufmassPunkte: Punkt[]) {
//     const xCoordinates = aufmassPunkte.map(point => point.xKoordinate);
//     const yCoordinates = aufmassPunkte.map(point => point.yKoordinate);
//     const xMin = Math.min(...xCoordinates);
//     const xMax = Math.max(...xCoordinates);
//     const xDistance = xMax - xMin;
//     const yMin = Math.min(...yCoordinates);
//     const yMax = Math.max(...yCoordinates);
//     const yDistance = yMax - yMin;
//     //clip Canvas
//   }

export function calculateDataAmount(arrayData: any[]): string {
  const unknown = '(?)';
  if (isNullOrUndefined(arrayData)) return unknown;
  const length = arrayData.length;
  return `(${length})`;
}

/**@description Guckt ob die LieferantenSettings eines Lieferanten sich im Vergleich zum letzten mal geändert haben */
function isChangeInSettingset(element: OfflinePosition, elements: OfflinePosition[]): boolean {
  const elementInArray = elements.find(findElement => findElement.UUID === element.UUID);
  const onlineAvailableEqual = element?.synchronize === elementInArray?.synchronize;
  const offlineAvailableEqual = element?.offlineAvailable === elementInArray?.offlineAvailable;
  return !(offlineAvailableEqual && onlineAvailableEqual);
}

export function positionSettingChanged(
  oldPositionSettings: PositionSettings,
  newPositionSettings: PositionSettings,
  kind: 'Gewerk' | 'Lieferant'
): boolean {
  const currentSettings = (newPositionSettings[kind] as OfflinePosition[]) || [];
  const oldSettings = (oldPositionSettings[kind] as OfflinePosition[]) || [];
  const settingsChanged = currentSettings.some(setting => isChangeInSettingset(setting, oldSettings));
  if (settingsChanged || currentSettings?.length !== oldSettings?.length) return true;
  return false;
}

export function splitFilenameAndExtension(fileNameWithExtension: string): { name: string; extension: string } {
  const dotPosition = fileNameWithExtension.lastIndexOf('.');
  const fileName = fileNameWithExtension.substring(0, dotPosition);
  const lowerCaseFileEnding =
    dotPosition !== -1 ? fileNameWithExtension.substring(dotPosition + 1)?.toLocaleLowerCase() : '';
  return { name: fileName, extension: lowerCaseFileEnding };
}

export function base64To64Raw(base64string: string): string {
  const removeImage = base64string?.replace(/^data:image\/[a-z]+;base64,/, '');
  const removeText = removeImage?.replace(/^data:text\/[a-z]+;base64,/, '');
  return removeText;
}

export function HWFileArrayGetUniques(fileArray: HWFile[]): HWFile[] {
  // weil im Handwerk die Bildnamen doppelt gespeichert werden und das dort auch auf Hinweis nicht geändert wird,
  // müssen wir uns quasi auf einen Bug anpassen... -.-
  const filesForAddress: HWFile[] = [];
  if (fileArray)
    for (const file of fileArray) {
      if (!filesForAddress.find(f => f.NameWithoutEnding === file.NameWithoutEnding)) filesForAddress.push(file);
    }
  return filesForAddress;
}
