import { Component, OnInit } from '@angular/core';
import { RepairOrderItemService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrderItem.service';
import { HWGolbalsettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwgolbalsetting.service';
import { RepairOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrder.service';
import { DialogService } from '@handwerk-pwa/shared';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { isBoolean, isNullOrUndefinedOrEmptyArray } from 'libs/shared/src/lib/helper/globalHelper';
import { Userinfo, Right } from 'libs/shared/src/lib/entities';
import { RechteService } from 'libs/shared/src/lib/services/rechte.service';
import { positionType } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { MonteurService } from 'apps/handwerkPWA/src/app/services/dataServices/monteur.service';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { ServiceauftraegeService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceauftraege.service';
import {
  ServiceAuftrag,
  HWRepairOrder,
  HWRepairOrderItem,
  Textposition,
  Positionrequest,
  HWAnlage,
} from 'apps/handwerkPWA/src/app/entities';
import { editImpossible, noEditServiceoderReason } from '../../../config/TextKonstanten';

@Component({
  selector: 'app-order-item-new',
  templateUrl: './order-item-new.component.html',
  styleUrls: ['./order-item-new.component.scss'],
})
export class OrderItemNewComponent implements OnInit {
  popupTitle = 'Mengenauswahl';
  types: string[] = ['Artikel', 'Lohn', 'Leistung', 'Kostenart', 'Manuelle Position', 'Textposition'];
  selectedObject: HWRepairOrderItem;
  showPopup = false;
  searchEnabled = false;
  autolohnActive: boolean;
  order: HWRepairOrder | ServiceAuftrag;
  MaintananceSystem: HWAnlage;
  itemCountBadgeNumber = 0;
  searchExpressionForResponseList = ['KurzText', 'MatNr', 'Such', 'Bezeichnung'];
  positionRequest: Positionrequest;
  mengeneinheiten: string[] = [];
  responsePositions: HWRepairOrderItem[] | Textposition[];
  rechte: Right;
  textVorlagen: Textposition[];
  userInfo: Userinfo;
  requestType: positionType = 'Artikel';
  orderType: string;
  orderId: string;

  constructor(
    private monteurService: MonteurService,
    private repairOrderItemService: RepairOrderItemService,
    private globalSettingService: HWGolbalsettingService,
    private repairOrderService: RepairOrderService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private rechteService: RechteService,
    private adressService: AddressService,
    private serviceAuftragService: ServiceauftraegeService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.globalSettingService.getUserinfo();
    this.rechte = this.rechteService.getCurrentRight();
    const isEditable = await this.loadOrder(this.userInfo);
    if (!isEditable) {
      await this.dialogService.openErrorMessage(editImpossible, noEditServiceoderReason);
      this.routingService.routeBack();
      return;
    }
    const kundenNummer = this.order.getKundennummer();
    const kunde = await this.adressService.findOneBy('KU_NR', kundenNummer);
    this.textVorlagen = await this.globalSettingService.getEntity('Textvorlagen');
    this.positionRequest = new Positionrequest(this.userInfo.mandant, this.userInfo.monteur);
    this.positionRequest.addPositionSettingData(this.rechte.PositionSettings);
    this.positionRequest.kundenPriceGroup = kunde.priceGroup;
    this.positionRequest.changeType('Artikel');
    const monteur = await this.monteurService.getMonteurFromIDB();
    this.mengeneinheiten = monteur.Mengeneinheiten?.map(mengeneinheit => mengeneinheit.Bez);
    this.setBadge(this.order);
  }

  async loadOrder(userInfo: Userinfo): Promise<boolean> {
    this.orderType = this.routingService.getRouteParam('type');
    this.orderId = this.routingService.getRouteParam('id');

    if (this.orderType === 'reparaturauftrag') {
      this.order = await this.repairOrderService.findOneByGuid(this.orderId);
    } else if (this.orderType === 'wartungsauftrag') {
      this.order = await this.serviceAuftragService.findOneBy('UUID', this.orderId);
      this.types.push('Km Pauschale');
      this.MaintananceSystem = this.order.AnlageObject;
    }
    return this.order.isEditable(userInfo);
  }

  setBadge(order: ServiceAuftrag | HWRepairOrder): void {
    const positionen = order.getDisplayPositions();
    this.itemCountBadgeNumber = positionen.length;
  }

  async searchItems(positionRequest: Positionrequest): Promise<void> {
    const type = positionRequest.getType();
    const emptySearch = this.checkForEmptySearch(positionRequest, type);
    if (emptySearch) return;
    void this.dialogService.openLoadingDialog('Laden', 'Lade ' + type + '.');
    this.responsePositions = await this.repairOrderItemService.getItemsByChoice(this.userInfo, positionRequest);
    if (isNullOrUndefinedOrEmptyArray(this.responsePositions))
      return this.dialogService.openInformDialog('Leeres Ergebnis', 'Leider ergab Ihre Suche keine Treffer.', 'Ok');
    this.dialogService.closeLoadingDialog();
  }

  private checkForEmptySearch(positionRequest: Positionrequest, type: string): boolean {
    const searchText = positionRequest?.SearchText?.trim();
    if (searchText === '' && type !== 'Lohn') {
      this.dialogService.openInformDialog('Fehler', 'Sie müssen einen Suchbegriff angeben.', 'Ok');
      return true;
    }
    return false;
  }

  postenArtChange(selectedType: positionType): void {
    this.positionRequest.changeType(selectedType);
    this.positionRequest.SearchText = '';
    this.responsePositions = [];
    this.searchEnabled = false;
    this.requestType = this.positionRequest.getType();
    if (selectedType === 'Lohn') {
      this.searchEnabled = true;
      void this.searchItems(this.positionRequest);
      return;
    }
    if (selectedType === 'Textposition') {
      this.responsePositions = this.textVorlagen;
      return;
    }
  }

  /**@description Sobald ein konkretes Item aus der Liste der Sucheregebnisse angeklickt wird */
  async selectItem(itemData: HWRepairOrderItem): Promise<void> {
    if (this.requestType === 'Textposition') {
      this.selectedObject = itemData;
      const textposition = new Textposition(this.selectedObject);
      await this.setMengeOrZeit(textposition, 1, this.positionRequest);
      return;
    }
    const kundenNummer = this.order.getKundennummer();
    const kunde = await this.adressService.findOneBy('KU_NR', kundenNummer);
    const leistungsPositionen = itemData?.getTransferredLeistungspositionen(kunde);
    if (itemData) itemData.Leistungspositionen = leistungsPositionen;
    const containsUnterleistung = leistungsPositionen?.some(position => position.getLongtype() === 'Unterleistung');
    if (containsUnterleistung) {
      await this.dialogService.openErrorMessage(
        'Mehrstufige Leistung',
        'Die Verarbeitung von mehrstufigen Leistungen ist in der my blue:app hand:werk zur Zeit noch nicht vorgesehen. '
      );
      return;
    }
    const proceed = await this.warnOnRohstoffartikel(itemData);
    if (!proceed) return;
    this.selectedObject = itemData;
    this.showPopup = true;
  }

  /**@description Warnt vor der (inkorrekten) Nutzung eines Rohstoffartikels - gibt dem Nutzer die Möglichkeit den Artikel zu verwerfen */
  private async warnOnRohstoffartikel(itemData: HWRepairOrderItem): Promise<boolean> {
    const rohstoffEnthalten = itemData.RSDaten?.length > 0;
    let proceed = true;
    if (rohstoffEnthalten) {
      proceed = await this.dialogService.openConfirmDialog(
        'Achtung',
        'Für die Verwendung von Rohstoffartikeln ist die my blue:app hand:werk zur Zeit nicht vorgesehen. Wenn Sie den Artikel einfügen,' +
          ' gehen die Rohstoffwerte dieses Artikels verloren. Wollen Sie den Artikel verwenden?',
        'Ja',
        'Nein',
        false
      );
    }
    return proceed;
  }

  /**@description Item wird der RepairOrder hinzugefügt */
  async addItemToOrder(item: HWRepairOrderItem): Promise<void> {
    this.order.addPosition(item);
    if (this.order instanceof HWRepairOrder)
      await this.repairOrderService.sendRepairOrderToWebservice(this.userInfo, this.order, false);
    if (this.order instanceof ServiceAuftrag)
      await this.serviceAuftragService.sendServiceorderToWebservice(this.userInfo, this.order, false);
    this.setBadge(this.order);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
  }

  async goToRepairOrderItems(): Promise<void> {
    await this.routingService.navigateTo('auftragpositionen/' + this.orderType + '/' + this.orderId);
  }

  /**@description Setzt die Menge, bzw. Zeit bei einer Lohnposition */
  async setMengeOrZeit(
    position: HWRepairOrderItem,
    popupOutput: number | boolean,
    positionRequest: Positionrequest
  ): Promise<void> {
    this.showPopup = false;
    const type = positionRequest.getType();
    const autolohnClicked = isBoolean(popupOutput);
    position.isAutolohnActive = autolohnClicked;
    const popupOutputValue = autolohnClicked ? 1 : (popupOutput as number);
    position.Menge = popupOutputValue;
    if (type === 'Lohn') {
      position.Zeit = popupOutputValue * 60;
      position.Menge = 1;
      position.MengenEinheit = 'Std.';
    }
    await this.addItemToOrder(position);
  }

  getPopupTitle(selectedObject: HWRepairOrderItem): string {
    return selectedObject?.Suchbegriff || '';
  }

  getPopupContent(selectedObject: HWRepairOrderItem): string {
    return selectedObject?.KurzText || '';
  }
}
