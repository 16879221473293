import { Component, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { calculateBrutoNettoSums } from 'libs/shared/src/lib/helper/aufmassHelper';
import { isNullOrUndefined } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Aufmass, AufmassKonstrukt, Raumbuch } from '../../../entities';
import { RaumbPos } from '../../../entities/models/aufmass/RaumbPos';
import { AufmassService } from '../../../services/dataServices/aufmass.service';
import { RaumbuchService } from '../../../services/dataServices/raumbuch.service';

@Component({
  selector: 'app-raum-edit',
  templateUrl: './raum-edit.component.html',
  styleUrls: ['./raum-edit.component.scss'],
})
export class RaumEditComponent implements OnInit {
  aufmass: Aufmass;
  raum: RaumbPos;
  AufmassKonstrukt: AufmassKonstrukt;
  raumbuchEntrys: Raumbuch[];
  entrysExists: boolean;
  sums: {
    bruto: number;
    netto: number;
    difference: number;
    brutoWalls: number;
    nettoWalls: number;
    differenceWalls: number;
  };
  aufmassPath: string;

  constructor(
    private routingService: RoutingService,
    private aufmassService: AufmassService,
    private dialogSerivce: DialogService,
    private raumbuchService: RaumbuchService
  ) {}

  async ngOnInit(): Promise<void> {
    const uuid = this.routingService.getRouteParam('uuid');
    const raumid = this.routingService.getRouteParam('raumid');
    this.aufmass = await this.aufmassService.findOneBy('Uuid', uuid);
    this.aufmassPath = `aufmass/${this.aufmass.Uuid}/raumbuch`;
    const raumbuchPositionen = this.aufmass.getRaumbuchpositionen();
    this.raum = raumbuchPositionen.find(position => position.Uuid === raumid);
    this.AufmassKonstrukt = this.raum.reconstructAufmassKonstrukt();
    this.raumbuchEntrys = await this.raumbuchService.getAllForRaum(this.raum, this.aufmass);
    this.entrysExists = !isNullOrUndefined(this.AufmassKonstrukt) || this.raumbuchEntrys?.length > 0;
    if (this.raumbuchEntrys?.length > 0) this.sums = calculateBrutoNettoSums(this.raumbuchEntrys);
  }

  async drawGrund(aufmass: Aufmass, raum: RaumbPos): Promise<void> {
    if (this.entrysExists) {
      const confirmation = await this.dialogSerivce.openConfirmDialog(
        'Achtung',
        'Es existieren bereits Positionen. Wollen Sie wirklich einen neuen Grundriss erstellen? Vorhandene Daten werden überschrieben.',
        'Erstellen',
        'Abbrechen',
        false
      );
      if (!confirmation) return;
    }

    void this.routingService.navigateTo(`aufmass/grundriss/${aufmass.Uuid}/${raum.Uuid}`);
  }

  async editMessung(aufmass: Aufmass, raum: RaumbPos): Promise<void> {
    const confirmation = await this.dialogSerivce.openConfirmDialog(
      'Achtung',
      'Einzelaufmaße die nicht Teil des ursprünglichen Grundrisses waren, gehen hierbei verloren.',
      'Editieren',
      'Abbrechen',
      false
    );
    if (!confirmation) return;
    void this.routingService.navigateTo(`aufmass/messung/${aufmass.Uuid}/${raum.Uuid}`);
  }

  gotoMasskette(aufmass: Aufmass, raum: RaumbPos, massKette: Raumbuch): void {
    const massketteId = massKette?.Uuid ? massKette.Uuid : 'neu';
    void this.routingService.navigateTo(`aufmass/${aufmass.Uuid}/raum/${raum.Uuid}/masskette/${massketteId}`);
  }
}
