import { Injectable } from '@angular/core';
import jsPDF, { Font } from 'jspdf';
import moment from 'moment';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from 'libs/shared/src/lib/helper/globalHelper';
import { deDateFormat } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { BaseAuftrag, BaseDocumentPosition } from 'apps/handwerkPWA/src/app/interfaces';
import {
  ServiceAuftrag,
  HWAddress,
  HWMonteur,
  HWRepairOrder,
  base64Pdf,
  Anlagenstandort,
  HWAnlage,
} from 'apps/handwerkPWA/src/app/entities';
import {
  Briefpapier,
  PDFSettings,
  BlockSetting,
  Employeesettings,
  Right,
  getSettingFromOrdertype,
  Setting,
  PDFSettingsServiceAuftrag,
} from 'libs/shared/src/lib/entities';
import autoTable, { CellDef, CellHookData, Table } from 'jspdf-autotable';
import {
  createBaseMusterPDF,
  createMusterauftrag,
  createExampleDocumentPositions,
  createExampleServiceOrder,
  createExampleDate,
} from '../helper/exampleHelper';
import {
  addUnterpositionenLines,
  createSummenBlockAsPrintPositions,
  PrintPosition,
} from 'apps/handwerkPWA/src/app/entities/models/PrintPosition';

const newline = 5;
const neutralBlockSetting: BlockSetting = {
  labelText: 'NeutralSetting',
  startPositionX: null,
  startPositionY: null,
  show: true,
  fontsize: 10,
  fontFamily: 'times',
  fontType: 'normal',
  fontColor: 'black',
};

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  /**
   * @description Methode um Auftragsabschluss PDf zu verwalten
   */
  printAuftragsabschluss(
    order: BaseAuftrag,
    base64Unterschrift: base64Pdf,
    printPrices: boolean,
    employee: HWMonteur,
    settings: Setting,
    rights: Right,
    customer: HWAddress,
    allEmployees: HWAddress[]
  ): { DataUri: string; Doc: jsPDF } {
    const employeeSettings = rights.Employeesettings;
    const pdfSettings = getSettingFromOrdertype(order, settings);
    const briefpapier = settings?.briefPapier;
    const doc = this.createNewPDF(briefpapier);
    const endOfBriefkopf = this.createBriefkopf(
      order,
      pdfSettings,
      employeeSettings,
      doc,
      employee,
      customer,
      allEmployees
    );
    const printPositionen = order.getPrintPositions();
    const positionBlockEnd = this.writeInformationBlockListToPDF(
      printPositionen,
      doc,
      printPrices,
      pdfSettings,
      order,
      employeeSettings,
      employee,
      customer,
      briefpapier,
      allEmployees,
      endOfBriefkopf
    );
    const nachtextEnd = this.printFormatted(
      doc,
      'text',
      pdfSettings.nachtext.Text,
      pdfSettings.Auftragspositionssettings,
      null,
      positionBlockEnd,
      pdfSettings?.showNachtext
    );
    if (!isNullOrUndefined(base64Unterschrift))
      this.addUnterschriftToPDF(base64Unterschrift, doc, pdfSettings, nachtextEnd);
    let datUri: string = doc.output('datauristring');
    // Backend erwartet scheinbar reine nutzdaten, daher entfernen
    datUri = datUri.replace('data:application/pdf;filename=generated.pdf;base64,', '');
    return { DataUri: datUri, Doc: doc };
  }

  /**
   * @description Erstellt den Kopf eines Dokuments (Firmenanschrift, Kundenanschrift, Kommunikationsinformationen und
   * ggf. Auftragsinformationen) */
  private createBriefkopf(
    order: BaseAuftrag,
    pdfSettings: PDFSettings,
    employeeSettings: Employeesettings,
    doc: jsPDF,
    employee: HWMonteur,
    customer: HWAddress,
    allEmployees: HWAddress[]
  ): number {
    this.printFormatted(doc, 'text', employee.FirmenAnschrift, pdfSettings.Firmensettings);
    const customerFields = [
      customer.FA_TITEL,
      customer.NAME,
      customer.NAME2,
      customer.STRASSE,
      `${customer.PLZ} ${customer.ORT}`.trim(),
    ];
    this.printFormatted(doc, 'text', customerFields, pdfSettings.Anschriftsettings);
    const communicationFields = [
      `Telefon: ${employee.Telefon}`,
      `Fax: ${employee.Fax}`,
      `E-Mail: ${employee.Email}`,
      `Web: ${employee.Web}`,
    ];
    this.printFormatted(doc, 'text', communicationFields, pdfSettings.Kommunikationssettings);

    let endOfText = -1;
    const endTitle = this.printOrderTitle(pdfSettings, order, doc);
    if (order instanceof HWRepairOrder) {
      endOfText = this.printRepairOrderData(doc, pdfSettings, allEmployees, order, employeeSettings, endTitle);
    } else if (order instanceof ServiceAuftrag) {
      //Benötigt endTitle nicht, da jeder der W&S Auftrag spezifischen Informationen fest über die globalen Einstellungen einstellbar ist
      endOfText = this.printServiceOrderData(doc, order, pdfSettings as PDFSettingsServiceAuftrag, allEmployees);
    }
    return endOfText;
  }

  /**@description Schreibt die Informationen zum Auftrag in die PDF ( Mitarbeiter, Ansprechpartner, Arbeitsort, Arbeitszeiten) */
  private printRepairOrderData(
    doc: jsPDF,
    settings: PDFSettings,
    allEmployees: HWAddress[],
    order: HWRepairOrder,
    employeeSettings: Employeesettings,
    endTitle: number
  ): number {
    const orderFields = [];
    const employee = order.getAllMitarbeiternamesInOrder(allEmployees);
    const printInformation = order.getDruckinformationen();
    const additionalOrderFields = [
      `Auftragsdatum: ${printInformation.Datum?.substring(0, 10)}`,
      `Arbeitsort: ${printInformation.Kundenbezug} , ${printInformation.Ort}`,
      `Mitarbeiter: ${employee}`,
    ];
    orderFields.push(...additionalOrderFields);
    const workingTimesArray = order.Arbeitszeiten?.map(dat => moment(dat).format('DD.MM.YYYY HH:mm'));
    const missingEventually = workingTimesArray?.length === 1 ? ' -fehlt- - ' : '';
    const workingTimesString = 'Arbeitszeiten: ' + missingEventually + workingTimesArray.join(' - ');
    if (employeeSettings.addWorkingHoursToPDF) orderFields.push(workingTimesString);

    // Anzeige unterhalb des Titels machen und anschließend die Starthöhe wieder auf titelhöhe setzen
    const initialHeight = settings.Auftragssettings.startPositionY;
    settings.Auftragssettings.startPositionY = endTitle;
    const endOfBriefkopf = this.printFormatted(doc, 'text', orderFields, settings.Auftragssettings);
    settings.Auftragssettings.startPositionY = initialHeight;
    return endOfBriefkopf;
  }

  private printOrderTitle(settings: PDFSettings, order: BaseAuftrag, doc: jsPDF) {
    const orderFields = [`${settings?.titel} ${order.getNummer()}`];
    settings.Auftragssettings.fontType = 'bold';
    const lineEnd = this.printFormatted(doc, 'text', orderFields, settings.Auftragssettings);
    settings.Auftragssettings.fontType = 'normal';
    return lineEnd;
  }

  /**@description Schreibt die Vortext, Arbeitsbericht, Positionen, Bezeichnung, Menge und Preis in eine Tabellenauflistung - gefolgt von Nettopreis, Umsatzsteuer und Gesamtpreis
   * Gibt anschließend die Endposition zurück um danach sauber weiterzuschreiben - wenn vorhanden auch vor und nachText
   */
  private writeInformationBlockListToPDF(
    items: BaseDocumentPosition[],
    doc: jsPDF,
    showPrices: boolean,
    pdfSettings: PDFSettings,
    order: BaseAuftrag,
    employeeSettings: Employeesettings,
    employee: HWMonteur,
    customer: HWAddress,
    briefpapier: Briefpapier,
    allEmployees: HWAddress[],
    endOfBriefkopf: number
  ) {
    const blockSetting = pdfSettings.Auftragspositionssettings;
    let nextYPosition = blockSetting.startPositionY;
    nextYPosition = this.printFormatted(
      doc,
      'text',
      pdfSettings?.vortext?.Text,
      blockSetting,
      null,
      null,
      pdfSettings?.showVortext
    );
    nextYPosition = this.printFormatted(doc, 'text', order.getSpecificVortext(), blockSetting, null, nextYPosition);

    const priceGroup = customer.priceGroup;
    let printItems = items.map(item => new PrintPosition(item, true, priceGroup));
    printItems = addUnterpositionenLines(printItems, items, order, priceGroup);

    const dontPrintSumOnServiceorder =
      order.auftragsArt === 'Serviceauftrag' && (pdfSettings as PDFSettingsServiceAuftrag).SumBlock.show === false;

    let summenBlock: PrintPosition[];
    if (showPrices && !dontPrintSumOnServiceorder) {
      summenBlock = createSummenBlockAsPrintPositions(employee, order, items, priceGroup);
      printItems = printItems.concat(summenBlock);
    }

    const tableEnd = this.printPositionsAsTable(
      showPrices,
      printItems,
      doc,
      nextYPosition,
      briefpapier,
      order,
      pdfSettings,
      employeeSettings,
      employee,
      customer,
      allEmployees,
      endOfBriefkopf
    );
    return tableEnd;
  }

  /**@description Druckt ein Array von PrintPositions und gibt das Table ende zurück */
  private printPositionsAsTable(
    showPrices: boolean,
    printItems: PrintPosition[],
    doc: jsPDF,
    nextYPosition: number,
    writingPaper: Briefpapier,
    order: BaseAuftrag,
    pdfSettings: PDFSettings,
    employeeSettings: Employeesettings,
    employee: HWMonteur,
    customer: HWAddress,
    allEmployees: HWAddress[],
    endOfBriefkopf: number
  ): number {
    const setting = pdfSettings.Auftragspositionssettings;
    if (!setting.show) return nextYPosition;

    const positionWithPrices = printItems.map(item => [
      item.posNr,
      item.menge,
      item.einheit,
      item.bezeichnung,
      item.epreis,
      item.gpreis,
    ]);
    const positionWithoutPrices = printItems.map(item => [item.posNr, item.menge, item.einheit, item.bezeichnung]);
    const printItemStringArray = showPrices ? positionWithPrices : positionWithoutPrices;

    const tableHeaderDescription: CellDef = { content: 'Bezeichnung', styles: { halign: 'left' } };
    const tableHeaders = ['Pos.', 'Menge', 'ME', tableHeaderDescription, 'E-Preis', 'G-Preis'];
    const tableHeaderBase = ['Pos.', 'Menge', 'ME', tableHeaderDescription];
    const headline = showPrices ? [tableHeaders] : [tableHeaderBase];

    let tableMeta: CellHookData = null;
    const priceCellLength = 21;
    let newPageAt = 2;
    autoTable(doc, {
      headStyles: { halign: 'right', fillColor: 'white', textColor: setting.fontColor, fontStyle: 'bold' },
      bodyStyles: { halign: 'right', textColor: setting.fontColor },
      columnStyles: {
        0: { cellWidth: 11 },
        1: { cellWidth: 15 },
        2: { cellWidth: 18 },
        3: { cellWidth: 'auto', halign: 'left' },
        4: { cellWidth: showPrices ? priceCellLength : 0 },
        5: { cellWidth: showPrices ? priceCellLength : 0 },
      }, // Bezeichnungen als einziges linksbündig
      head: headline,
      body: printItemStringArray,
      startY: nextYPosition,
      margin: { top: endOfBriefkopf, left: setting.startPositionX, bottom: 60 },
      rowPageBreak: 'avoid',
      willDrawCell: data => {
        if (doc.internal.pages?.length > newPageAt) {
          newPageAt++;
          const { font, fontSize, textColor, fillColor } = this.saveFontsBeforeBraking(doc);
          this.printBriefkopfAndBriefpapier(
            doc,
            writingPaper,
            order,
            pdfSettings,
            employeeSettings,
            employee,
            customer,
            allEmployees
          );
          this.restoreBrokenFont(doc, font, fontSize, textColor, fillColor);
        }
        tableMeta = data;
      },
      didDrawCell: data => {
        this.underlineTableRow(data.table, 1, data.doc, setting);
      },
    });
    const tableEndY = tableMeta.cell.y + 3 * newline;
    return tableEndY;
  }

  private underlineTableRow(table: Table, rowNumber: number, doc: jsPDF, setting: BlockSetting) {
    let tableWidth = 0;
    table.columns.forEach(column => {
      tableWidth += column.width;
    });
    const rows = table.allRows();
    doc.setDrawColor(0, 0, 0);
    let height = 0;
    for (let index = 0; index < rowNumber; index++) {
      height += rows[index].height;
    }
    const startingYPosition = table.allRows()[0]?.cells[0]?.y;
    doc.line(
      setting.startPositionX,
      startingYPosition + height,
      setting.startPositionX + tableWidth,
      startingYPosition + height
    );
  }

  /**Library verliert font style und würde sonst ein schwarzes Kästchen drucken */
  private saveFontsBeforeBraking(doc: jsPDF) {
    const fillColor = doc.getFillColor();
    const textColor = doc.getTextColor();
    const font = doc.getFont();
    const fontSize = doc.getFontSize();
    return { font, fontSize, textColor, fillColor };
  }

  private restoreBrokenFont(doc: jsPDF, font: Font, fontSize: number, textColor: string, fillColor: string) {
    doc.setFont(font.fontName, font.fontStyle);
    doc.setFontSize(fontSize);
    doc.setTextColor(fontSize);
    doc.setTextColor(textColor);
    doc.setFillColor(fillColor);
  }

  private printBriefkopfAndBriefpapier(
    doc: jsPDF,
    briefpapier: Briefpapier,
    order: BaseAuftrag,
    pdfSettings: PDFSettings,
    employeeSettings: Employeesettings,
    employee: HWMonteur,
    customer: HWAddress,
    allEmployees: HWAddress[]
  ) {
    this.addWritingPaper(briefpapier, doc);
    this.createBriefkopf(order, pdfSettings, employeeSettings, doc, employee, customer, allEmployees);
  }

  /**
   * @description
   *  Druckt formatierten text, wenn keine Formatierung dabei ist, wird eine standard verwendet
   * @returns Neue Y-Position des Dokument Pointers */
  private printFormatted(
    doc: jsPDF,
    mode: 'text',
    text: string | string[],
    setting = neutralBlockSetting,
    customX?: number,
    customY?: number,
    customShow?: boolean,
    alignment: 'right' | 'left' = 'left'
  ): number {
    const startX = isNullOrUndefined(customX) ? setting.startPositionX : customX;
    const startY = isNullOrUndefined(customY) ? setting.startPositionY : customY;
    if (isNullOrUndefined(text)) return startY;
    let textAsArray = Array.isArray(text) ? text : (doc.splitTextToSize(text, 210 - startX - 10) as string[]);
    textAsArray = textAsArray?.filter(line => !isNullOrUndefinedOrEmptyString(line));
    const show = isNullOrUndefined(customShow) ? setting.show : customShow;
    if (!show || isNullOrUndefined(textAsArray)) return startY;
    this.setTextStyle(doc, setting);
    switch (mode) {
      case 'text': {
        doc.text(textAsArray, startX, startY, { align: alignment });
        break;
      }
    }
    const currentLinePositionY = startY + textAsArray.length * newline;
    return currentLinePositionY;
  }

  private setTextStyle(doc: jsPDF, styleSetting: BlockSetting) {
    const fontSize = styleSetting.fontsize;
    const fontStyle = styleSetting.fontFamily;
    const fontType = styleSetting.fontType;
    const fontColor = styleSetting.fontColor;
    doc.setFontSize(fontSize);
    doc.setFont(fontStyle, fontType);
    doc.setTextColor(fontColor);
    doc.setDrawColor(fontColor);
  }

  private printServiceOrderData(
    doc: jsPDF,
    order: ServiceAuftrag,
    pdfSettings: PDFSettingsServiceAuftrag,
    allEmployees: HWAddress[]
  ): number {
    const standort = new Anlagenstandort(order.AnlageObject.Anlagenstandort);
    const anlagenPrintInformationen = standort.getAnlageninformationenForPdfPrint();
    this.printFormatted(doc, 'text', anlagenPrintInformationen, pdfSettings.AddressAndContact);
    this.printFormatted(
      doc,
      'text',
      ['Monteure: ' + order.getPdfPrintEmployeeNames(allEmployees)],
      pdfSettings.Monteure
    );
    this.printFormatted(
      doc,
      'text',
      ['Beginn: ' + (order?.TerminObject?.start?.substring(0, 10) || '')],
      pdfSettings.DateBeginning
    );
    this.printFormatted(doc, 'text', [order?.TerminObject?.start?.substring(10) || ''], pdfSettings.TimeBeginning);
    this.printFormatted(
      doc,
      'text',
      ['Ende: ' + (order?.TerminObject?.finish?.substring(0, 10) || '')],
      pdfSettings.DateEnd
    );
    const endOfBlock = this.printFormatted(
      doc,
      'text',
      [order?.TerminObject?.finish?.substring(10) || ''],
      pdfSettings.TimeEnd
    );
    return endOfBlock;
  }

  /**@description Speichert ein PDF (zeigt es damit auch an) */
  public savePDF(doc: jsPDF, contractNumber: string): void {
    doc.save(contractNumber + '.pdf');
  }

  /**@description Fügt die Unterschrift dem PDF hinzu */
  private addUnterschriftToPDF(
    base64Unterschrift: base64Pdf,
    doc: jsPDF,
    pdfSettings: PDFSettings,
    positionslistenEnde: number
  ) {
    // X und Y eventuell hier verwirrend, da beim rotieren sich das ganze gedanklich ändert.
    const unterschriftSettings = pdfSettings.UnterschriftSettings;
    const unterschriftPositionWidth = unterschriftSettings?.startPositionX;
    const imageSizeX = 45;

    const img = new Image(imageSizeX);
    img.src = base64Unterschrift.data;
    const imageSizeY = img.height;
    const unterschriftPositionX = unterschriftPositionWidth + imageSizeX;
    const unterschriftPositionY = positionslistenEnde - imageSizeY;
    if (unterschriftSettings.show) {
      if (base64Unterschrift.landScape) {
        doc.addImage(
          img.src,
          'JPEG',
          unterschriftPositionX - imageSizeX,
          unterschriftPositionY + 2 * imageSizeY,
          imageSizeX,
          imageSizeY,
          'unterschrift',
          'SLOW'
        );
      } else {
        doc.addImage(
          img.src,
          'JPEG',
          unterschriftPositionX - imageSizeX,
          unterschriftPositionY + newline,
          imageSizeY,
          imageSizeX,
          'unterschrift',
          'SLOW'
        );
      }
    }
    const showDate = pdfSettings.DatumAuftragsabschluss?.show || false;
    if (!showDate) return;
    const settingsDatumAuftragsabschluss = pdfSettings.DatumAuftragsabschluss;
    this.setTextStyle(doc, settingsDatumAuftragsabschluss);
    const DatumAuftragsabschlussX = settingsDatumAuftragsabschluss.startPositionX;
    const abschlussDatum = moment(new Date()).format(deDateFormat);
    doc.text(abschlussDatum, DatumAuftragsabschlussX, unterschriftPositionY + imageSizeX);
  }

  private createNewPDF(briefpapier: Briefpapier) {
    const doc = new jsPDF('portrait', 'mm', 'a4');
    this.addWritingPaper(briefpapier, doc);
    this.setTextStyle(doc, neutralBlockSetting);
    return doc;
  }

  private addWritingPaper(briefpapier: Briefpapier, doc: jsPDF) {
    if (isNullOrUndefinedOrEmptyString(briefpapier?.base64Data)) return;
    const maxWidth = doc.internal.pageSize.getWidth();
    const maxHeight = doc.internal.pageSize.getHeight();
    const paperImage = new Image();
    const base64Header = 'data:image/jpeg;base64,';
    const headerNeeded = !briefpapier.base64Data.startsWith(base64Header);
    const writingPaperSource = headerNeeded ? base64Header + briefpapier.base64Data : briefpapier.base64Data;
    paperImage.src = writingPaperSource;
    doc.addImage(paperImage, 'JPEG', 0, 0, maxWidth, maxHeight, 'SLOW');
  }

  public printExampleServiceAuftrag(setting: Setting): void {
    const { pdf, employee, right, customer, allEmployees } = createBaseMusterPDF();
    const serviceOrder = createExampleServiceOrder(allEmployees);
    const examplePositions = createExampleDocumentPositions();
    const exampleDate = createExampleDate();
    serviceOrder.setPositionen(examplePositions);
    serviceOrder.updateAdditionalEmployeeList(allEmployees);
    serviceOrder.TerminObject = exampleDate;
    serviceOrder.AnlageObject = new HWAnlage(null, [customer], []);
    const print = this.printAuftragsabschluss(
      serviceOrder,
      pdf,
      true,
      employee,
      setting,
      right,
      customer,
      allEmployees
    );
    this.savePDF(print.Doc, serviceOrder.getNummer());
  }

  public printExampleReparaturAuftrag(setting: Setting): void {
    const { pdf, employee, right, customer, allEmployees } = createBaseMusterPDF();
    const repairOrder = createMusterauftrag();
    const print = this.printAuftragsabschluss(repairOrder, pdf, true, employee, setting, right, customer, allEmployees);
    this.savePDF(print.Doc, repairOrder.Nummer);
  }
}
