import { Injectable } from '@angular/core';
import { ControllerService } from './controller.service';
import { environment } from 'apps/handwerkPWA/src/environments/environment';
import { AddressShortTypes } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { RepairOrderStatus, AppOnlySettings } from 'apps/handwerkPWA/src/app/entities';
import { Mobiledevices, Userinfo } from 'libs/shared/src/lib/entities';
import { GlobalSettingsEntity } from 'libs/shared/src/lib/entities/models/Typedstrings';

@Injectable({
  providedIn: 'root',
})
export class HWGolbalsettingService {
  lastAddressSelectionType: AddressShortTypes;
  letzterStartseitenIndex: number;
  lastRepairOrderSelectiontype: RepairOrderStatus;
  askForFileSync = true;
  globalIgnoreChanges = false;

  constructor(private controllerService: ControllerService) {}

  async getUserinfo(): Promise<Userinfo> {
    const userInfoData = await this.getEntity<Userinfo>('Userinfo');
    if (userInfoData) {
      const userInfo = new Userinfo(userInfoData);
      userInfo.Device = new Mobiledevices(userInfo.Device);
      return userInfo;
    }
    return null;
  }

  async deleteEntry(key: GlobalSettingsEntity): Promise<void> {
    await this.controllerService.deleteData('HWGlobalSetting', key);
  }

  getIsProductionAndProductionUrl(): boolean {
    const hostname = window.location.hostname;
    let productionUrl = true;
    if (
      hostname.startsWith('192.') ||
      hostname.startsWith('local') ||
      hostname.startsWith('topappdev') ||
      hostname.startsWith('topappqs')
    ) {
      productionUrl = false;
    }
    return environment.production && productionUrl;
  }

  async setEntity<T>(entity: T, key: GlobalSettingsEntity): Promise<void> {
    await this.controllerService.setData('HWGlobalSetting', [{ key, value: entity }]);
  }

  /**@description Holt jeden Wert aus der GlobalSettingsTabelle, der keinen Constructor durchlaufen muss */
  async getEntity<T>(key: GlobalSettingsEntity): Promise<T> {
    const data = await this.controllerService.getData<T[]>('HWGlobalSetting', key);
    if (data.length === 0) return null;
    return data[0]['value'] as T;
  }

  async getAppOnlySettings(): Promise<AppOnlySettings> {
    const previousSettings = await this.getEntity<AppOnlySettings>('AppOnlySettings');
    const appOnlySettingsWithNewDefaults = new AppOnlySettings(previousSettings);
    return appOnlySettingsWithNewDefaults;
  }
}
