import { HWNachricht } from '../../entities';
import { HWMessageType } from '../../entities/repository/HWNachricht';

/**@description Sortiert die Nachrichten nach Absender */
export function SortBySender(messages: HWNachricht[]): { sendFromPWA: HWNachricht[]; sendFromHW: HWNachricht[] } {
  const sendFromPWA: HWNachricht[] = [];
  const sendFromHW: HWNachricht[] = [];

  for (const message of messages) {
    const type = message.TYP;
    if (type === HWMessageType.Incoming) {
      sendFromPWA.push(message);
      continue;
    }
    sendFromHW.push(message);
  }

  return { sendFromPWA, sendFromHW };
}
