import { Component, OnInit, Input } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { HWAddress, HWObjectaddress } from 'apps/handwerkPWA/src/app/entities';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
})
export class AddressCardComponent implements OnInit {
  @Input() address: HWAddress | HWObjectaddress;
  @Input() noClickEvents = false;
  @Input() withContactBar = true;
  contactInformationArray = [];
  showContactBar = false;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    this.setContactInformationArray();
  }

  async goToAddressDetail(kunde: HWAddress | HWObjectaddress, noClickEvents: boolean): Promise<void> {
    if (noClickEvents) {
      return;
    }

    if (kunde instanceof HWObjectaddress) {
      await this.routingService.navigateTo('objektadresse/' + kunde.Guid + '/edit');
    } else {
      await this.routingService.navigateTo('adresse/' + kunde.Guid + '/edit');
    }
  }

  getSearchExpression(): string {
    return this.address instanceof HWObjectaddress ? this.address.Such : this.address.SUCH;
  }

  getAddressName(): string {
    return this.address instanceof HWObjectaddress ? this.address.Name : this.address.NAME;
  }

  private setContactInformationArray() {
    if (this.address instanceof HWObjectaddress) {
      this.contactInformationArray = ['Telefon', null, null, 'Anschrift', null];
      return;
    }

    this.contactInformationArray = ['TEL', 'FUNK_PRIV', 'GLOBEMAIL', 'Anschrift', 'WWW'];
  }

  toggleContactBar(): void {
    this.showContactBar = !this.showContactBar;
  }
}
