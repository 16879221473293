import { Component, Input, OnInit } from '@angular/core';
import { Aufmass } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-aufmass-card',
  templateUrl: './aufmass-card.component.html',
  styleUrls: ['./aufmass-card.component.scss'],
})
export class AufmassCardComponent implements OnInit {
  @Input() aufmass: Aufmass;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {}

  async goToAufmassDetail(): Promise<void> {
    await this.routingService.navigateTo(`aufmass/${this.aufmass.Uuid}/raumbuch`);
  }
}
