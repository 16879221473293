import { Sliderside } from '../../config/Konstanten';

export class MultiViewSite {
  title: string;
  dataArray: any[];
  path: string;
  cardName: string;
  id: Sliderside;
  index: number;
  emptyInputDataMessage: string;

  constructor(
    title: string,
    dataArray: any[],
    path: string,
    cardName: string,
    id: Sliderside,
    index: number,
    emptyInputDataMessage: string
  ) {
    this.title = title;
    this.dataArray = dataArray;
    this.path = path;
    this.cardName = cardName;
    this.id = id;
    this.index = index;
    this.emptyInputDataMessage = emptyInputDataMessage;
  }
}
